/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DatainputDi } from '../models/datainput-di';
@Injectable({
  providedIn: 'root'
})
class DatainputService extends __BaseService {
  static readonly getDataInputPath = '/api/Datainput/{diid}';
  static readonly listFunctionsPath = '/api/Datainput/org/{orid}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param diid undefined
   * @return Success
   */
  getDataInputResponse(diid: number): __Observable<__StrictHttpResponse<DatainputDi>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Datainput/${encodeURIComponent(String(diid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DatainputDi>;
      })
    );
  }
  /**
   * @param diid undefined
   * @return Success
   */
  getDataInput(diid: number): __Observable<DatainputDi> {
    return this.getDataInputResponse(diid).pipe(__map((_r) => _r.body as DatainputDi));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  listFunctionsResponse(orid: number): __Observable<__StrictHttpResponse<Array<DatainputDi>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Datainput/org/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DatainputDi>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  listFunctions(orid: number): __Observable<Array<DatainputDi>> {
    return this.listFunctionsResponse(orid).pipe(__map((_r) => _r.body as Array<DatainputDi>));
  }
}

module DatainputService {}

export { DatainputService };
