<section class="qa-log page-padding">
  <p-tabMenu [model]="qaLogTabs" [activeItem]="activeTab">
    <ng-template pTemplate="item" let-item>
      <a [routerLink]="item.routerLink" class="p-menuitem-link flex justify-content-between align-items-center p-3">
        <div class="ui-menuitem-text">
          {{ item.label | translate }}
        </div>
      </a>
    </ng-template>
  </p-tabMenu>

  <div class="qa-log__content">
    <router-outlet></router-outlet>
  </div>
</section>
