/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AuthResponse } from '../models/auth-response';
import { LoginUser } from '../models/login-user';
import { UserUsDTO } from '../models/user-us-dto';
@Injectable({
  providedIn: 'root'
})
class AuthService extends __BaseService {
  static readonly authenticatePath = '/api/Auth/authenticate';
  static readonly getUserAuthPath = '/api/Auth/ad/userauth';
  static readonly getAllUserDetailsPath = '/api/Auth/getUsersByUsid/{usid}/{orid}';
  static readonly updateUserSettingsPath = '/api/Auth/updateUserSettings/{orid}';
  static readonly changeUserPasswordPath = '/api/Auth/{orid}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  authenticateResponse(body?: LoginUser): __Observable<__StrictHttpResponse<AuthResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Auth/authenticate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  authenticate(body?: LoginUser): __Observable<AuthResponse> {
    return this.authenticateResponse(body).pipe(__map((_r) => _r.body as AuthResponse));
  }

  /**
   * @return Success
   */
  getUserAuthResponse(): __Observable<__StrictHttpResponse<AuthResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Auth/ad/userauth`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  getUserAuth(): __Observable<AuthResponse> {
    return this.getUserAuthResponse().pipe(__map((_r) => _r.body as AuthResponse));
  }

  /**
   * @param params The `AuthService.GetAllUserDetailsParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getAllUserDetailsResponse(params: AuthService.GetAllUserDetailsParams): __Observable<__StrictHttpResponse<Array<UserUsDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Auth/getUsersByUsid/${encodeURIComponent(String(params.usid))}/${encodeURIComponent(String(params.orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserUsDTO>>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAllUserDetailsParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getAllUserDetails(params: AuthService.GetAllUserDetailsParams): __Observable<Array<UserUsDTO>> {
    return this.getAllUserDetailsResponse(params).pipe(__map((_r) => _r.body as Array<UserUsDTO>));
  }

  /**
   * @param params The `AuthService.UpdateUserSettingsParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateUserSettingsResponse(params: AuthService.UpdateUserSettingsParams): __Observable<__StrictHttpResponse<UserUsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Auth/updateUserSettings/${encodeURIComponent(String(params.orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserUsDTO>;
      })
    );
  }
  /**
   * @param params The `AuthService.UpdateUserSettingsParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateUserSettings(params: AuthService.UpdateUserSettingsParams): __Observable<UserUsDTO> {
    return this.updateUserSettingsResponse(params).pipe(__map((_r) => _r.body as UserUsDTO));
  }

  /**
   * @param params The `AuthService.ChangeUserPasswordParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `usid`:
   *
   * - `password`:
   *
   * @return Success
   */
  changeUserPasswordResponse(params: AuthService.ChangeUserPasswordParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.usid != null) __params = __params.set('usid', params.usid.toString());
    if (params.password != null) __params = __params.set('password', params.password.toString());
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Auth/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `AuthService.ChangeUserPasswordParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `usid`:
   *
   * - `password`:
   *
   * @return Success
   */
  changeUserPassword(params: AuthService.ChangeUserPasswordParams): __Observable<number> {
    return this.changeUserPasswordResponse(params).pipe(__map((_r) => _r.body as number));
  }
}

module AuthService {
  /**
   * Parameters for GetAllUserDetails
   */
  export interface GetAllUserDetailsParams {
    usid: number;
    orid: number;
  }

  /**
   * Parameters for UpdateUserSettings
   */
  export interface UpdateUserSettingsParams {
    orid: number;
    body?: UserUsDTO;
  }

  /**
   * Parameters for ChangeUserPassword
   */
  export interface ChangeUserPasswordParams {
    orid: number;
    usid?: number;
    password?: string;
  }
}

export { AuthService };
