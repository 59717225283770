<!-- to be deleted -->

<div class="warehouse-stock-modal">
  <div class="material-dropdown" *ngIf="!preselectedMaterialFilter">
    <lib-material-status
      *ngIf="preselectedArticle; else articleDropdown"
      [condensed]="true"
      [title]="'MOUNTING.ACTIVE_MATERIAL' | translate"
      [article]="preselectedArticle"
    ></lib-material-status>
    <ng-template #articleDropdown>
      <label class="material-dropdown__label">{{ 'MOUNTING.MATERIAL' | translate }}</label>
      <lib-dropdown
        [inlineStyle]="{ width: '350px' }"
        [options]="dropDownOptions"
        [(ngModel)]="selectedOption"
        (ngModelChange)="filterWarehouseData()"
      ></lib-dropdown>
    </ng-template>
  </div>
  <app-material-table
    [tableColumns]="columns"
    [tableData]="warehouseFilteredData"
    [selectedRow]="selectedMaterial"
    (selectedRowChanged)="selectedMaterialChange($event)"
    [highlightAllRows]="consumeAllRows"
  ></app-material-table>

  <div *ngIf="isMaterialQuantityRequired" class="consume-quantity">
    <div *ngIf="isConsumeAllEnabled" class="consume-quantity__consume-all">
      <p-checkbox
        [(ngModel)]="consumeAllRows"
        (ngModelChange)="consumeAllCheckChange()"
        binary="true"
        [label]="'MOUNTING.CONSUME_ALL' | translate"
      ></p-checkbox>
    </div>
    <div class="consume-quantity__wrapper">
      <span class="consume-quantity__label">{{ 'MOUNTING.QUANTITY' | translate }}</span>
      <lib-input-number
        [(ngModel)]="selectedMaterialQuantity"
        [inputStyle]="{ width: '80px', textAlign: 'right' }"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        (touched)="onBlurInputQuantity()"
        [disabled]="consumeAllRows"
      ></lib-input-number>
      <span class="consume-quantity__label">
        <span *ngIf="selectedMaterialQuantityUnitId; else elseEmpty">{{ selectedMaterialQuantityUnitId }}</span>
        <ng-template #elseEmpty>‒</ng-template>
      </span>
    </div>
  </div>
</div>
<lib-modal-footer
  [submitLabel]="submitButtonLabel"
  (submitClicked)="onSubmitClick()"
  [submitDisabled]="!isSubmitEnabled()"
  (declineClicked)="onDeclineClick()"
  [whenLoadingTopic]="whenLoadingTopic"
></lib-modal-footer>
