<button
  [libLoadingTopic]="loadingTopic"
  (loadingEvent)="loading = $event"
  [loadingIndicator]="loadingIndicator"
  [label]="label | translate"
  [disabled]="disabled || loading"
  [autofocus]="isAutoFocus"
  (click)="onClick()"
  [id]="buttonId"
  [ngClass]="['button', typeClass, sizeClass]"
  pButton
></button>
