/* tslint:disable */
type ScannedIdentificationCodeType = 'None' | 'SsccCode' | 'ExternalSerialId';
module ScannedIdentificationCodeType {
  export const NONE: ScannedIdentificationCodeType = 'None';
  export const SSCC_CODE: ScannedIdentificationCodeType = 'SsccCode';
  export const EXTERNAL_SERIAL_ID: ScannedIdentificationCodeType = 'ExternalSerialId';
  export function values(): ScannedIdentificationCodeType[] {
    return [NONE, SSCC_CODE, EXTERNAL_SERIAL_ID];
  }
}

export { ScannedIdentificationCodeType };
