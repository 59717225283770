import { Injectable } from '@angular/core';
import { ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { WorkCenterService } from 'chronos-core-client';

@Injectable()
export class OutputService {
  constructor(private workcenterService: WorkCenterService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public getReasonOptions(): Observable<ListValue[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    return this.workcenterService.getWasteReasons(workCenterId);
  }
}
