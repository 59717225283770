/* tslint:disable */
type InfoEntryType = 'ProductionOrder' | 'ProductionOrderKpi' | 'Shift' | 'Downtime' | 'ContainerLabelInfo' | 'CheckResult';
module InfoEntryType {
  export const PRODUCTION_ORDER: InfoEntryType = 'ProductionOrder';
  export const PRODUCTION_ORDER_KPI: InfoEntryType = 'ProductionOrderKpi';
  export const SHIFT: InfoEntryType = 'Shift';
  export const DOWNTIME: InfoEntryType = 'Downtime';
  export const CONTAINER_LABEL_INFO: InfoEntryType = 'ContainerLabelInfo';
  export const CHECK_RESULT: InfoEntryType = 'CheckResult';
  export function values(): InfoEntryType[] {
    return [PRODUCTION_ORDER, PRODUCTION_ORDER_KPI, SHIFT, DOWNTIME, CONTAINER_LABEL_INFO, CHECK_RESULT];
  }
}

export { InfoEntryType };
