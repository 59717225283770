/* tslint:disable */
type WorkCenterProductionMode = 'Automation' | 'Manual';
module WorkCenterProductionMode {
  export const AUTOMATION: WorkCenterProductionMode = 'Automation';
  export const MANUAL: WorkCenterProductionMode = 'Manual';
  export function values(): WorkCenterProductionMode[] {
    return [AUTOMATION, MANUAL];
  }
}

export { WorkCenterProductionMode };
