import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { KpiValue } from 'chronos-core-client';
import { groupBy } from 'ramda';
import { map } from 'rxjs/operators';
import { KpiState, KpiStore } from './kpi.store';

@Injectable()
export class KpiQuery extends QueryEntity<KpiState, KpiValue> {
  constructor(protected store: KpiStore) {
    super(store);
  }

  public kpisByGroup$ = this.selectAll().pipe(map((kpis) => groupBy((kpi: KpiValue) => kpi.category, kpis)));

  public warningKpis$ = this.selectAll({
    filterBy: (kpi) => kpi.hasViolation
  });
}
