import { Injectable } from '@angular/core';
import { ActiveOrderDsService, OutputUnitDsService } from '@app/core/data-services';
import { Observable } from 'rxjs';
import { Outer } from 'chronos-core-client';

@Injectable()
export class OutputUnitListService {
  constructor(private activeOrderDsService: ActiveOrderDsService, private outputUnitDsService: OutputUnitDsService) {}

  public getOutputUnitWithNotifications(): Observable<Outer> {
    const productionOrderId: number = this.activeOrderDsService.getActiveOrderId();
    return this.outputUnitDsService.getOutputUnitWithNotifications(productionOrderId);
  }
}
