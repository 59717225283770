<div class="date-picker">
  <div class="date-picker__content">
    <div class="date-picker__label">{{ 'ACTIVE_ORDER.PERIODS_START_DATE' | translate }}</div>
    <lib-calendar
      [(ngModel)]="selectedStartTime"
      [maxDate]="maxSelectableDate"
      [inputStyle]="INPUT_STYLE"
      (ngModelChange)="checkValidity()"
    ></lib-calendar>
  </div>
  <div class="date-picker__empty checkbox-container">
    <span class="confirm-checkbox--regular">
      <lib-input-check
        class="confirm-checkbox__item"
        [label]="checkBoxLabel"
        [(ngModel)]="setInAllWorkCenters"
        ngDefaultControl
      ></lib-input-check>
    </span>
  </div>
  <lib-modal-footer
    [submitDisabled]="!isDateValid"
    (submitClicked)="startOrder()"
    (declineClicked)="closeModal()"
    [submitLabel]="'MODAL_FOOTER.OK'"
  ></lib-modal-footer>
</div>
