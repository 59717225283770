<div class="machine-input">
  <app-container-collapse [marginRight]="80" [collapseGroup]="consumedContainerGroup" [isExpanded]="expanded">
    <ng-container *ngFor="let consumedPallet of consumedPallets$ | async; trackBy: trackByFunction">
      <app-input-container [inputPallet]="consumedPallet" [isExpanded]="expanded"></app-input-container>
    </ng-container>
    <div *ngIf="(consumedPallets$ | async).length > 0" class="separator-line"></div>
  </app-container-collapse>

  <ng-container *ngIf="activePallets$ | async as activePallets">
    <ng-container *ngIf="inputQuantityPool$ | async as inputQuantityPool">
      <app-input-quantity-pool
        *ngIf="isQuantityPoolShouldBeShown(activePallets, inputQuantityPool)"
        [amount]="inputQuantityPool"
      ></app-input-quantity-pool>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let activePallet of activePallets$ | async; trackBy: trackByFunction">
    <app-input-container [inputPallet]="activePallet" [isExpanded]="expanded"></app-input-container>
  </ng-container>

  <app-container-collapse [marginRight]="80" [collapseGroup]="mountedContainerGroup" [isExpanded]="expanded">
    <ng-container *ngFor="let mountedPallet of mountedPallets$ | async; trackBy: trackByFunction">
      <app-input-container [inputPallet]="mountedPallet" [isExpanded]="expanded"></app-input-container>
    </ng-container>
  </app-container-collapse>
</div>
