import { Injectable } from '@angular/core';
import { isNil } from 'ramda';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor() {}

  public getItem(storageKey: string): string {
    return sessionStorage.getItem(storageKey) || localStorage.getItem(storageKey);
  }

  public setItem(storageKey: string, value: string): void {
    if (isNil(value)) {
      throw new Error('incorrect value set');
    }

    sessionStorage.setItem(storageKey, value);
    localStorage.setItem(storageKey, value);
  }
}
