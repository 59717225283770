/* tslint:disable */
type ProducedMaterialStatus = 'None' | 'Seed' | 'Active' | 'Open' | 'Finished';
module ProducedMaterialStatus {
  export const NONE: ProducedMaterialStatus = 'None';
  export const SEED: ProducedMaterialStatus = 'Seed';
  export const ACTIVE: ProducedMaterialStatus = 'Active';
  export const OPEN: ProducedMaterialStatus = 'Open';
  export const FINISHED: ProducedMaterialStatus = 'Finished';
  export function values(): ProducedMaterialStatus[] {
    return [NONE, SEED, ACTIVE, OPEN, FINISHED];
  }
}

export { ProducedMaterialStatus };
