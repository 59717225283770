import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  MaterialAvailableAtLocation,
  MaterialAvailableAtLocationItem,
  ArticleDescription,
  Quantity,
  ScanContainerResponse,
  ScannedIdentificationCodeType,
  MaterialType
} from 'chronos-core-client';
import { TranslateService } from '@ngx-translate/core';
import * as R from 'ramda';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { PrimaryMountedMaterialService } from '@app/modules/mounting/state';
import { MaterialTableColumns, TabNameEnum } from 'projects/chronos-shared/src/lib/models/modal-main-material-table';
import { TabOverviewService } from '@app/modules/mounting/services';

@Component({
  selector: 'app-modal-main-material',
  templateUrl: './modal-main-material.component.html',
  styleUrls: ['./modal-main-material.component.scss']
})
export class ModalMainMaterialComponent implements OnInit, OnDestroy {
  @Input() public availableMountingMaterial: MaterialAvailableAtLocationItem[];
  @Input() public billOfMaterialArticleList: ArticleDescription[];
  @Input() public suggestedQuantity: Quantity;
  @Input() public submitButtonLabel = '';
  @Input() public whenLoadingTopic = '';
  @Input() public isConsumeAllEnabled = false;
  @Output() public mountingMaterialSubmit = new EventEmitter<[MaterialAvailableAtLocationItem | null, number, number, boolean]>();
  @Output() public emitSelectedRow = new EventEmitter<MaterialAvailableAtLocationItem>();
  @Input() public tabName = '';
  @Input() public isDropDownOptionDisabled = false;

  public readonly LOADING_TOPIC = notificationTopic.modalMachineLocation;
  public readonly MINIMUM_MATERIAL_QUANTITY = 0;

  public dropDownOptions: any[] = [];
  public selectedOption?: number;
  public preselectedMaterialFilter?: ScanContainerResponse;
  public isMaterialQuantityRequired = true;
  public materialBlockId = 0;
  public consumeAllRows = false;
  public isWarehouse: boolean;
  public showDecline = true;
  public warehouseData: MaterialAvailableAtLocationItem[] = [];
  public warehouseFilteredData: MaterialAvailableAtLocationItem[] = [];
  public selectedMaterial: MaterialAvailableAtLocationItem | null = null;
  public selectedMaterialQuantity = this.MINIMUM_MATERIAL_QUANTITY;
  public selectedMaterialQuantityUnitId: string;
  public columns: MaterialTableColumns[] = [];
  public isQuantityShown = false;
  private maxQuantity?: Quantity;
  private subscriptions = new Subscription();
  private materialType?: MaterialType;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private translateService: TranslateService,
    private primaryMountedMaterialService: PrimaryMountedMaterialService,
    private tabOverviewService: TabOverviewService
  ) {}

  public ngOnInit(): void {
    if (!R.isNil(this.config?.data)) {
      if (!R.isNil(this.config.data?.tabName)) {
        this.tabName = this.config.data?.tabName;
      }

      this.createBaseColumns();

      switch (this.tabName) {
        case TabNameEnum.Overview:
          this.getOverviewModalData(true);
          this.materialType = null; // materialType = null for overview page: variable mounting on primary and secondary materials
          break;

        case TabNameEnum.Primary:
          this.getPrimaryModalData();
          this.materialType = MaterialType.PRIMARY;
          break;

        case TabNameEnum.Secondary:
          this.getSecondaryModalData();
          this.materialType = MaterialType.SECONDARY;
          break;

        case TabNameEnum.Trace:
        case TabNameEnum.ToolSetup:
          this.getSecondaryModalData();
          this.materialType = MaterialType.TRACE;
          break;
      }

      if (this.config.data?.submitLabel) {
        this.submitButtonLabel = this.translateService.instant(this.config.data?.submitLabel);
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.resetSelectedMaterial();
  }

  public filterWarehouseData(): void {
    this.resetSelectedMaterial();

    let wareHouseData = this.warehouseData;

    if (this.selectedOption) {
      wareHouseData = this.filterByArticleId(this.selectedOption);
    } else if (this.preselectedMaterialFilter) {
      wareHouseData = this.filterByMaterialScanResponse(this.preselectedMaterialFilter);
    }

    this.fillDetailsOfTableData(wareHouseData);
  }

  public selectedMaterialChange(material: MaterialAvailableAtLocationItem): void {
    this.selectedMaterial = material;
    this.consumeAllRows = false;
    this.updateMaterialMaxQuantity();
    this.updateMaterialQuantity();
    this.emitSelectedRow.emit(material);
  }

  public consumeAllCheckChange(): void {
    if (this.consumeAllRows) {
      this.selectedMaterial = null;
      this.selectedMaterialQuantity = this.warehouseFilteredData.map((material) => material.bomQuantity.value).reduce((a, b) => a + b);
    }
  }

  public onCloseModal(): void {
    this.ref.close(false); // bool used for refreshing sidebar.
  }

  public onSubmitClick(): void {
    if (this.isWarehouse) {
      this.onCloseModal();
    } else {
      if (this.tabName === TabNameEnum.Overview || this.tabName === TabNameEnum.Primary) {
        this.onMountClick();
      } else {
        if (!this.isSubmitDisabled()) {
          this.mountingMaterialSubmit.emit([
            this.selectedMaterial,
            this.selectedMaterialQuantity,
            this.materialBlockId,
            this.consumeAllRows
          ]);
        }
      }
    }
  }

  public isSubmitDisabled(): boolean {
    let isDisabled = true;
    if (!R.isNil(this.selectedMaterial) || this.isWarehouse) {
      isDisabled = false;
    }
    if (this.consumeAllRows || (!R.isNil(this.selectedMaterial) && this.isQuantityValid() && this.selectedMaterial.isMountable)) {
      isDisabled = false;
    }
    return isDisabled;
  }

  public onBlurInputQuantity(): void {
    if (!this.selectedMaterialQuantity || this.selectedMaterialQuantity < 0) {
      this.updateMaterialQuantity();
    }
  }

  private getOverviewModalData(isOverviewTab?: boolean): void {
    const productionOrderId: number = this.config.data?.productionOrderId;
    this.subscriptions.add(
      this.primaryMountedMaterialService.getAvailableContainers(productionOrderId, isOverviewTab).subscribe((data) => {
        this.mapTableData(data);
      })
    );
  }

  private getPrimaryModalData(): void {
    this.isWarehouse = this.config.data?.asWarehouse;
    if (this.isWarehouse) {
      this.showDecline = false;
      this.subscriptions.add(
        this.primaryMountedMaterialService.getWarehouseContainers().subscribe((data) => {
          this.mapTableData(data);
        })
      );
      this.submitButtonLabel = this.translateService.instant('MOUNTING.CLOSE');
    } else {
      this.getOverviewModalData(false);
    }
  }

  private getSecondaryModalData(): void {
    this.isQuantityShown = true;

    if (!R.isNil(this.config.data?.articleForConsumption)) {
      this.selectedOption = this.config.data.articleForConsumption.id;
    }

    if (!R.isNil(this.config.data?.isMaterialQuantityRequired)) {
      this.isMaterialQuantityRequired = this.config.data.isMaterialQuantityRequired;
    }

    this.preselectedMaterialFilter = this.config.data?.filterFromScan;
    this.materialBlockId = this.config.data?.materialBlockId;

    this.warehouseData = this.availableMountingMaterial;
    this.fillDetailsOfTableData(this.warehouseData);

    this.filterWarehouseData();

    if (this.billOfMaterialArticleList) {
      if (this.billOfMaterialArticleList.length <= 1) {
        this.isDropDownOptionDisabled = true;
      }
      if (this.billOfMaterialArticleList.length > 1) {
        this.isDropDownOptionDisabled = false;
      }
      this.setDropDownOptions();
    }

    if (this.suggestedQuantity) {
      this.updateMaterialQuantity();
    }
  }

  private createBaseColumns(): void {
    this.columns.push(
      { field: 'isBlocked', type: 'status', header: 'MOUNTING.STATUS', width: 155 },
      { field: 'externalArticleId', header: 'MOUNTING.ARTICLE_ID', width: 120 },
      { field: 'identificationCode', type: 'sscc', header: 'MOUNTING.IDENTIFICATION', width: 210 },
      { field: 'internalBatchId', header: 'MOUNTING.BATCH_INTERNAL', width: 150 },
      { field: 'externalBatchId', header: 'MOUNTING.BATCH_EXTERNAL', width: 155 },
      { field: 'bomQuantity', type: 'quantity', header: 'MOUNTING.QUANTITY', style: 'text-right', width: 115 },
      { field: 'inventoryQuantity', type: 'quantity', header: 'MOUNTING.ON_HAND', style: 'text-right', width: 115 },
      { field: 'warehouseName', header: 'MOUNTING.WAREHOUSE', width: 140 },
      { field: 'warehouseLocationName', header: 'MOUNTING.LOCATION', width: 120 },
      { field: 'dateTimeAvailable', type: 'datetime', header: 'MOUNTING.MADE_AVAILABLE', width: 170 }
    );
  }

  private mapTableData(data: MaterialAvailableAtLocation): void {
    this.warehouseData = data.materials;
    this.fillDetailsOfTableData(this.warehouseData);

    if (data.articles?.length > 0) {
      if (data.articles?.length <= 1) {
        this.isDropDownOptionDisabled = true;
      }
      this.billOfMaterialArticleList = data.articles;
      this.setDropDownOptions();
    } else {
      this.billOfMaterialArticleList = [];
    }
  }

  private fillDetailsOfTableData(warehouseData?: MaterialAvailableAtLocationItem[]): void {
    this.warehouseFilteredData = warehouseData.map((filteredDataRow) => ({
      ...filteredDataRow,
      externalArticleId: filteredDataRow.article.externalArticleId
    }));
  }

  private resetSelectedMaterial(): void {
    this.maxQuantity = null;
    this.selectedMaterial = null;
    this.updateMaterialQuantity();
  }

  private filterByArticleId(articleId: number): MaterialAvailableAtLocationItem[] {
    return this.warehouseData.filter((data) => data.article.id === articleId);
  }

  private filterByMaterialScanResponse(materialScanResponse: ScanContainerResponse): MaterialAvailableAtLocationItem[] {
    let identificationTypeLookup;

    switch (materialScanResponse.scannedIdentificationCodeType) {
      case ScannedIdentificationCodeType.EXTERNAL_SERIAL_ID:
        identificationTypeLookup = 'externalSerialId';
        break;
      case ScannedIdentificationCodeType.SSCC_CODE:
        identificationTypeLookup = 'ssccCode';
        break;
    }

    return this.warehouseData.filter((data) => R.prop(identificationTypeLookup, data) === materialScanResponse.scannedValue);
  }

  private setDropDownOptions(): void {
    let options: any[] = [];
    this.dropDownOptions = [];

    if (!R.isNil(this.billOfMaterialArticleList)) {
      options = R.pipe(
        R.map((article: ArticleDescription) => ({
          label: `${article.externalArticleId} ${article.externalConfigurationId} ${article.articleName}`,
          value: article.id
        })),
        R.uniq
      )(this.billOfMaterialArticleList).concat({
        label: this.translateService.instant('DROPDOWN_ALL_LABEL'),
        value: null
      });
    }

    this.dropDownOptions = options;
  }

  private updateMaterialQuantity(): void {
    if (this.suggestedQuantity) {
      if (this.selectedMaterial && this.maxQuantity?.value < this.suggestedQuantity.value) {
        this.selectedMaterialQuantity = this.maxQuantity.value;
      } else {
        this.selectedMaterialQuantity = this.suggestedQuantity.value;
      }
      this.selectedMaterialQuantityUnitId = this.suggestedQuantity.unitId;
    } else {
      this.selectedMaterialQuantity = this.selectedMaterial ? this.maxQuantity.value : this.MINIMUM_MATERIAL_QUANTITY;
      this.selectedMaterialQuantityUnitId = this.selectedMaterial ? this.maxQuantity?.unitId : '';
    }
  }

  private updateMaterialMaxQuantity(): void {
    if (this.selectedMaterial) {
      this.maxQuantity = this.selectedMaterial.bomQuantity;
    }
  }

  private isQuantityValid(): boolean {
    const isAboveMinimum = this.selectedMaterialQuantity >= this.MINIMUM_MATERIAL_QUANTITY;
    const isBelowMaximum = this.selectedMaterialQuantity <= this.maxQuantity?.value;

    return isAboveMinimum && isBelowMaximum;
  }

  private onMountClick(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    const dummyContainerId = this.config.data?.dummyContainerId;
    const mountedMaterialId = this.config.data?.mountedMaterialId;
    let containerMountObservable;

    if (dummyContainerId) {
      containerMountObservable = this.primaryMountedMaterialService.replaceContainer(
        mountedMaterialId,
        dummyContainerId,
        this.selectedMaterial.containerId
      );
    } else {
      const productionOrderId: number = this.config.data?.productionOrderId;
      containerMountObservable = this.tabOverviewService.mountContainer(
        productionOrderId,
        this.materialType,
        null,
        this.selectedMaterial.containerId
      );
    }

    containerMountObservable
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(
        () => {
          LogService.success('SUCCESS_MESSAGE.MATERIAL_MOUNTED');
          this.ref.close(true);
        },
        () => {
          this.ref.close(true);
        }
      );
  }
}
