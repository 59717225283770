import { Injectable } from '@angular/core';
import {
  ApprovalEntry,
  ApprovalReport,
  ApprovalReportConsumption,
  ApprovalReportService,
  ApprovalWorkCenter,
  InfoList,
  ApprovalReportDates,
  SetProductionQuantitiesEntry,
  SetSetupParameterEntry,
  SetSetupParameterKindEntry,
  SetupParameterKind,
  SetupParameters,
  ShiftInfoList,
  WebSocketClientService,
  WorkCenterGroup,
  WorkCenterGroupService,
  InterruptProductionOrderEntry,
  EditProductionOrderSetupPhaseEntry
} from 'chronos-core-client';
import { concat, Observable, of } from 'rxjs';
import { HubUrlsService } from '@app/core/utils';
import { switchMap } from 'rxjs/operators';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class ApproveDsService {
  constructor(
    private workCenterGroupService: WorkCenterGroupService,
    private approvalReportService: ApprovalReportService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public getWorkCenterGroupsForActiveMachines(): Observable<WorkCenterGroup[]> {
    return this.workCenterGroupService.getWorkCenterGroupsForActiveMachines();
  }

  public setFinishedInterruptedOrder(
    workCenterId: number,
    isInterrupted: boolean,
    approvalReportDate: string,
    approvalReportLineId: number
  ): Observable<null> {
    const entry: InterruptProductionOrderEntry = {
      isInterrupted
    };
    const params: ApprovalReportService.InterruptProductionOrderParams = {
      workCenterId,
      entry,
      approvalReportDate,
      approvalReportLineId
    };
    return this.approvalReportService.interruptProductionOrder(params);
  }

  public getApprovalWorkCenters(workCenterGroupId: number, approvalReportDate: string): Observable<ApprovalWorkCenter[]> {
    const params: ApprovalReportService.GetApprovalWorkCentersParams = {
      workCenterGroupId,
      approvalReportDate
    };
    return this.approvalReportService.getApprovalWorkCenters(params);
  }

  public getApprovalReportWithNotifications(workCenterId: number, approvalReportDate: string): Observable<ApprovalReport> {
    return this.getApprovalReport(workCenterId, approvalReportDate).pipe(
      switchMap((report) => concat(of(report), this.getApprovalReportNotifications(workCenterId, approvalReportDate)))
    );
  }

  public getApprovalConsumptionData(
    workCenterId: number,
    approvalReportDate: string,
    approvalReportLineId: number
  ): Observable<ApprovalReportConsumption> {
    const params: ApprovalReportService.GetProductionOrderConsumptionParams = {
      workCenterId,
      approvalReportDate,
      approvalReportLineId
    };
    return this.approvalReportService.getProductionOrderConsumption(params);
  }

  public confirmApprovalReport(workCenterId: number, approvalReportDate: string): Observable<null> {
    const approvalEntry: ApprovalEntry = { isConfirmed: true };
    const params: ApprovalReportService.ConfirmApprovalReportParams = {
      workCenterId,
      approvalReportDate,
      approvalEntry
    };
    return this.approvalReportService.confirmApprovalReport(params);
  }

  public putApprovalReportProductionQuantities(
    workCenterId: number,
    approvalReportDate: string,
    approvalReportLineId: number,
    goodQuantity: number,
    wasteQuantity: number,
    maculatureQuantity: number
  ): Observable<null> {
    const entry: SetProductionQuantitiesEntry = {
      goodQuantity,
      wasteQuantity,
      maculatureQuantity
    };
    const params: ApprovalReportService.PutApprovalReportProductionQuantitiesParams = {
      workCenterId,
      approvalReportDate,
      approvalReportLineId,
      entry
    };
    return this.approvalReportService.putApprovalReportProductionQuantities(params);
  }

  public getOpenApprovalReportDates(workCenterId: number): Observable<ApprovalReportDates> {
    return this.approvalReportService.getOpenApprovalReportDates(workCenterId);
  }

  public getApprovalSetupParameterKinds(
    workCenterId: number,
    approvalReportLineId: number,
    approvalReportDate: string
  ): Observable<SetupParameterKind> {
    const params: ApprovalReportService.GetApprovalSetupParameterKindsParams = {
      workCenterId,
      approvalReportLineId,
      approvalReportDate
    };
    return this.approvalReportService.getApprovalSetupParameterKinds(params);
  }

  public setApprovalReportSetupParameterKind(
    workCenterId: number,
    approvalReportLineId: number,
    approvalReportDate: string,
    setupParameterKindId: number
  ): Observable<null> {
    const entry: SetSetupParameterKindEntry = {
      setupParameterKindId,
      workCenterId
    };
    const params: ApprovalReportService.PutApprovalReportSetupParameterKindParams = {
      workCenterId,
      entry,
      approvalReportLineId,
      approvalReportDate
    };
    return this.approvalReportService.putApprovalReportSetupParameterKind(params);
  }

  public getApprovalSetupParameters(
    workCenterId: number,
    approvalReportLineId: number,
    approvalReportDate: string
  ): Observable<SetupParameters> {
    const params: ApprovalReportService.GetApprovalSetupParametersParams = {
      workCenterId,
      approvalReportLineId,
      approvalReportDate
    };
    return this.approvalReportService.getApprovalSetupParameters(params);
  }

  public setApprovalReportParameter(
    workCenterId: number,
    setupParameterId: number,
    entry: SetSetupParameterEntry,
    approvalReportLineId: number,
    approvalReportDate: string
  ): Observable<null> {
    const params: ApprovalReportService.PutApprovalReportSetupParameterParams = {
      workCenterId,
      setupParameterId,
      entry,
      approvalReportLineId,
      approvalReportDate
    };
    return this.approvalReportService.putApprovalReportSetupParameter(params);
  }

  public getShiftInfo(workCenterId: number, approvalReportDate: string): Observable<ShiftInfoList> {
    const params: ApprovalReportService.GetShiftInfosParams = {
      workCenterId,
      approvalReportDate
    };
    return this.approvalReportService.getShiftInfos(params);
  }

  public getProductionOrderInfo(workCenterId: number, approvalReportDate: string, productionOrderId: number): Observable<InfoList> {
    const params: ApprovalReportService.GetProductionOrderInfosParams = {
      workCenterId,
      productionOrderId,
      approvalReportDate
    };
    return this.approvalReportService.getProductionOrderInfos(params);
  }

  public getDowntimeInfo(workCenterId: number, approvalReportDate: string, downtimeId: number): Observable<InfoList> {
    const params: ApprovalReportService.GetDowntimeInfosParams = {
      workCenterId,
      downtimeId,
      approvalReportDate
    };
    return this.approvalReportService.getDowntimeInfos(params);
  }

  private getApprovalReport(workCenterId: number, approvalReportDate: string): Observable<ApprovalReport> {
    const params: ApprovalReportService.GetApprovalReportParams = {
      workCenterId,
      approvalReportDate
    };
    return this.approvalReportService.getApprovalReport(params);
  }

  private getApprovalReportNotifications(workCenterId: number, approvalReportDate: string): Observable<ApprovalReport> {
    return this.webSocketClientService.getNotificationsForTopic<ApprovalReport>(
      this.hubUrlsService.getApprovalReport(workCenterId, approvalReportDate)
    );
  }

  public editApprovalReportSetupPhase(
    setupStartTime: string,
    setupEndTime: string,
    setupQuantity: Quantity,
    wasteQuantity: Quantity,
    maculatureQuantity: Quantity,
    setupStartCounter: number,
    setupEndCounter: number,
    approvalReportLineId: number,
    approvalReportDate: string,
    selectedWorkCenterId: number
  ): Observable<null> {
    const workCenterId = selectedWorkCenterId;
    const editSetUpPhaseEntry: EditProductionOrderSetupPhaseEntry = {
      workCenterId,
      setupStartTime,
      setupStartCounter,
      setupEndTime,
      setupEndCounter,
      setupQuantity,
      wasteQuantity,
      maculatureQuantity
    };

    const params: ApprovalReportService.EditApprovalReportSetupPhaseParams = {
      workCenterId,
      editSetupPhaseEntry: editSetUpPhaseEntry,
      approvalReportLineId,
      approvalReportDate
    };

    return this.approvalReportService.editApprovalReportSetupPhase(params);
  }
}
