import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Quantity } from 'chronos-core-client';

@Component({
  selector: 'app-counter-header',
  templateUrl: './counter-header.component.html',
  styleUrls: ['./counter-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterHeaderComponent {
  @Input() public counter: Quantity;
}
