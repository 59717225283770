import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import { CreateAndAssignTraceContainerEntry, MaterialAvailableAtLocationItem } from 'chronos-core-client';
import { map } from 'rxjs/operators';
import { TraceMaterialDsService } from '@app/core/data-services';

@Injectable()
export class TabTraceService {
  constructor(private traceMaterialDsService: TraceMaterialDsService) {}

  public getTraceMaterial(): Observable<MaterialTableTreeNode[]> {
    return this.traceMaterialDsService.getTraceMaterial();
  }

  public unassignTraceMaterial(mountedMaterialId: number): Observable<MaterialTableTreeNode[]> {
    return this.traceMaterialDsService.unassignTraceMaterial(mountedMaterialId);
  }

  public createAndAssignTraceMaterial(entry: CreateAndAssignTraceContainerEntry): Observable<MaterialTableTreeNode[]> {
    return this.traceMaterialDsService.createAndAssignTraceMaterial(entry);
  }

  public getAvailableTraceMaterial(articleId: number): Observable<MaterialAvailableAtLocationItem[]> {
    return this.traceMaterialDsService.getAvailableTraceMaterial(articleId).pipe(map((traceMaterialList) => traceMaterialList.materials));
  }

  public assignTraceMaterial(containerId: number, materialBlockId: number): Observable<MaterialTableTreeNode[]> {
    return this.traceMaterialDsService.assignTraceMaterial(containerId, materialBlockId);
  }
}
