import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-attention-badge',
  templateUrl: './attention-badge.component.html',
  styleUrls: ['./attention-badge.component.scss']
})
export class AttentionBadgeComponent {
  @Input() public counter = 0;
  @Input() public warningThreshold = 1;
  @Input() public errorThreshold = 20;

  public isBadgeWarned(badgeValue: number): boolean {
    return badgeValue >= this.warningThreshold && badgeValue <= this.errorThreshold;
  }

  public isBadgeDangered(badgeValue: number): boolean {
    return badgeValue > this.errorThreshold;
  }
}
