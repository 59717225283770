<div class="kpi-tile" [ngClass]="kpi.hasViolation ? 'kpi-tile--warning' : 'kpi-tile--success'">
  <div class="p-grid p-nogutter kpi-tile__grid">
    <div class="p-col-9">
      <div class="kpi-tile__title">{{ kpiTitle | translate }}</div>
      <ng-container *ngIf="kpi.valueTime; then elapsedTime; else measurementValue"></ng-container>
    </div>
    <div class="p-col-3">
      <div class="tools">
        <i [ngClass]="kpi.hasViolation ? 'icon-warning-solid' : 'icon-checked-solid'"></i>
        <div class="tools__actions">
          <lib-dismount-button
            *ngIf="isDismountButtonVisible"
            class="tools__dismount"
            [dismountableCount]="kpi.toBeDismountedKpi?.containerCount"
            [disabled]="dismountDisabled"
            (buttonClicked)="onClickDismount()"
          ></lib-dismount-button>
          <app-edit-button
            *ngIf="isActionButtonVisible"
            class="tools__edit"
            [disabled]="executeDisabled"
            (buttonClicked)="onClickExecute()"
          ></app-edit-button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #elapsedTime>
  <div class="kpi-tile__measurement">
    {{ kpi.valueTime | elapsedTime }}
  </div>
  <div *ngIf="kpi.targetTime" class="kpi-tile__target">{{ 'KPI_TILE.TARGET' | translate }} {{ kpi.targetTime | elapsedTime }}</div>
</ng-template>

<ng-template #measurementValue>
  <ng-container *ngIf="isQuantityNumber; then quantityNumber; else quantityObject"></ng-container>
</ng-template>

<ng-template #quantityObject>
  <div [title]="kpi.value.value | number" class="kpi-tile__measurement">
    {{ kpi.value | formatQuantity: false }}
    <span class="kpi-tile__measurement--units">{{ kpi.value.unitId }}</span>
  </div>
  <div *ngIf="kpi.target" [title]="kpi.target.value | number" class="kpi-tile__target">
    {{ 'KPI_TILE.TARGET' | translate }} {{ kpi.target | formatQuantity }}
  </div>
  <div *ngIf="kpi.maximum" [title]="kpi.maximum.value | number" class="kpi-tile__maximum">Max. {{ kpi.maximum | formatQuantity }}</div>
  <div *ngIf="kpi.deviationPercentage" class="kpi-tile__deviation">
    {{ 'KPI_TILE.DEVIATION' | translate }} {{ kpi.deviationPercentage | number: '1.0-2' }}%
  </div>
</ng-template>

<ng-template #quantityNumber>
  <div *ngIf="kpi.target; else singleValue" class="kpi-tile__measurement">{{ kpi.value.value }} / {{ kpi.target.value }}</div>
</ng-template>

<ng-template #singleValue>
  <div class="kpi-tile__measurement">{{ kpi.value.value }}</div>
</ng-template>
