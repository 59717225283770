import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimaryMaterialMounted } from 'chronos-core-client';

@Component({
  selector: 'app-consumption-pallet-dummy',
  templateUrl: './consumption-pallet-dummy.component.html',
  styleUrls: ['./consumption-pallet-dummy.component.scss']
})
export class ConsumptionPalletDummyComponent {
  @Input() public consumedContainer: PrimaryMaterialMounted;

  @Output() public infoButtonClicked = new EventEmitter<void>();
  @Output() public replaceButtonClicked = new EventEmitter<void>();
  @Output() public dismountIconClicked = new EventEmitter<PrimaryMaterialMounted>();

  public onInfoButtonClick(): void {
    this.infoButtonClicked.emit();
  }

  public onReplaceClick(): void {
    this.replaceButtonClicked.emit();
  }

  public onDismountClick(consumed: PrimaryMaterialMounted) {
    this.dismountIconClicked.emit(consumed);
  }
}
