import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ParametersFactoryService } from '@app/core/services/parameters-factory';
import { Quantity } from 'chronos-core-client';
import { isNil } from 'ramda';
import { Subscription } from 'rxjs';

export const BOBBIN_QUANTITY_VALUE_PROVIDER: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PalletBobbinQuantityParameterComponent),
  multi: true
};

@Component({
  selector: 'app-pallet-bobbin-quantity-parameter',
  templateUrl: './pallet-bobbin-quantity-parameter.component.html',
  styleUrls: ['./pallet-bobbin-quantity-parameter.component.scss'],
  providers: [BOBBIN_QUANTITY_VALUE_PROVIDER]
})
export class PalletBobbinQuantityParameterComponent implements ControlValueAccessor, OnInit {
  @Input() public totalOrderQuantity: Quantity;
  @Input() public required = false;
  @Input() public inputId: string = null;
  @Input() public unit;
  @Input() public disabled = false;
  @Input() public slitCount = 0;
  @Input() public defaultValue: number;
  @Output() public touched = new EventEmitter<void>();

  public readonly INPUT_STYLE = { textAlign: 'right' };
  public bobbinQuantity: number;
  public palletQuantity: number;
  private subscriptions = new Subscription();
  private propagateChanged: (result: number) => void;
  private propagateTouched: () => void;
  constructor(private parametersFactoryService: ParametersFactoryService) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.parametersFactoryService.palletQuantitySubject$.subscribe((quantity) => {
        this.palletQuantity = quantity;
        this.bobbinQuantity = this.defaultValue ?? 0;
        this.getBobbinPerPallet();
        this.getBobbinEstimation();
      })
    );
  }

  public writeValue(value: any): void {
    if (!isNil(value)) {
      this.bobbinQuantity = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public onBobbinQuantityChange(quantity: number): void {
    this.propagateChanged(quantity);
    this.parametersFactoryService.bobbinQuantitySubject.next(quantity);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onBobbinTouched(): void {
    this.propagateTouched();
    this.touched.emit();
  }

  public getBobbinPerPallet(): number {
    const bobbinQuantity = this.bobbinQuantity ?? 0;
    const palletQty = this.palletQuantity ?? 0;

    if (palletQty === 0 || bobbinQuantity === 0) {
      return 0;
    }

    return Math.ceil(this.palletQuantity / bobbinQuantity) * this.slitCount;
  }

  public getBobbinEstimation(): number {
    const totalOrderQuantityValue = this.totalOrderQuantity?.value ?? 0;
    const bobbinQuantity = this.bobbinQuantity ?? 0;
    const palletQty = this.palletQuantity ?? 0;
    let lastPalletQty = 0;
    let lastPalletBobbinCount = 0;
    let totalPallets = 0;
    let bobbinCount = 0;
    if (palletQty === 0 || totalOrderQuantityValue === 0 || bobbinQuantity === 0) {
      return 0;
    }

    totalPallets = Math.ceil(this.totalOrderQuantity.value / palletQty);

    if (totalOrderQuantityValue % palletQty !== 0) {
      lastPalletQty = totalOrderQuantityValue % palletQty;
      lastPalletBobbinCount = Math.ceil(lastPalletQty / bobbinQuantity) * this.slitCount;
      totalPallets = totalPallets - 1;
    }

    bobbinCount = Math.ceil(palletQty / bobbinQuantity) * this.slitCount * totalPallets;

    return bobbinCount + lastPalletBobbinCount;
  }
}
