<div class="product-order">
  <div class="order">
    <div *ngIf="isItemExpandable" class="arrow">
      <i *ngIf="isItemCollapsed" class="icon-arrow-right-open"></i>
      <i *ngIf="!isItemCollapsed" class="icon-arrow-down-open"></i>
    </div>
    <div class="order-id">{{ orderItem.externalProductionOrderId }}</div>
    <div class="start-time">
      <ng-container *ngIf="!orderItem.isCombinationOrder">
        <span>{{ orderItem.customers[0].customerName }}</span>
      </ng-container>
      <span>
        | {{ orderItem.cadNumber }} | {{ orderItem.productionOrderReferenceType }} | {{ orderItem.estimatedQuantity | formatQuantity }}
      </span>
    </div>
  </div>
</div>
<div *ngIf="!isItemCollapsed" class="description">
  <ng-container *ngIf="orderItem.isCombinationOrder; else orderArticle">
    <div class="combination" *ngFor="let origin of orderItem.combinationOrderOrigins">
      <span>{{ origin.salesOrderIdAndPosition }}</span>
      <br />
      {{ origin.externalProductionOrderId }} | {{ origin.customerName }} | {{ origin.finishedGoodExternalArticleId }}
      /
      {{ origin.finishedGoodConfigurationId | emptyTextPlaceholder }} |
      <br />
      {{ origin.finishedGoodArticleName }} / {{ origin.finishedGoodConfigurationName | emptyTextPlaceholder }}
    </div>
  </ng-container>
</div>

<ng-template #orderArticle>
  <ng-container *ngFor="let article of orderItem.finishedGoodArticles">
    <span>{{ orderItem.salesOrderIdAndPosition }}</span>
    <span *ngIf="orderItem.combinationOrderIdentifier">{{ ' |' }} {{ orderItem.combinationOrderIdentifier }}</span>
    <br />
    {{ article.externalArticleId }} / {{ article.configurationId | emptyTextPlaceholder }} |
    <br />
    {{ article.articleName }} / {{ article.configurationName | emptyTextPlaceholder }}
    <br />
  </ng-container>
</ng-template>
