<div class="print-label">
  <div class="row no-gutters">
    <div class="print-label__printing-parameters col" [ngClass]="{ 'print-label__printing-parameters--collapsible': collapsible }">
      <app-print-label-header
        [isPalletFinished]="isPalletFinished"
        [printerName]="printerName"
        [printedLabels]="printedLabels"
        [labelsTotal]="labelsTotal"
        [quantityPerOuter]="quantityPerOuter"
        [defaultQuantityPerOuter]="defaultQuantityPerOuter"
        [collapsible]="collapsible"
        [labelType]="labelType"
        [numberOfCopies]="numberOfCopies"
        [isQuantityPerOuterValid]="isQuantityPerOuterValid"
        [producedMaterialId]="producedMaterialId"
        [palletQuantity]="palletQuantity"
        [isBobbin]="isBobbin"
        (toggleButtonClicked)="toggleContent($event)"
        (quantityPerOuterChanged)="onQuantityPerOuterChange($event)"
        (numberOfCopiesChanged)="onNumberOfCopiesChanged($event)"
      ></app-print-label-header>

      <div *ngIf="!isCollapsed" class="printing-parameters">
        <app-print-label-range
          [isPalletFinished]="isPalletFinished"
          [collapsible]="collapsible"
          [labelsTotal]="labelsTotal"
          [printedLabels]="printedLabels"
          [title]="isBobbin === true ? 'OUTPUT_UNIT.PRINT_BOBBIN' : 'OUTPUT_UNIT.PRE_PRINT'"
          [labelType]="labelType"
          [isQuantityPerOuterValid]="isQuantityPerOuterValid"
          (okButtonClicked)="printLabel($event)"
          (okBobbinButtonClicked)="printBobbinLabel($event)"
          [isBobbin]="isBobbin"
          [producedMaterialId]="producedMaterialId"
        ></app-print-label-range>
        <app-print-label-range
          [isPalletFinished]="isPalletFinished"
          [collapsible]="collapsible"
          [labelsTotal]="labelsTotal"
          [printedLabels]="printedLabels"
          [isReprint]="true"
          [title]="'OUTPUT_UNIT.RE_PRINT'"
          [labelType]="labelType"
          [isQuantityPerOuterValid]="isQuantityPerOuterValid"
          (okButtonClicked)="printLabel($event)"
          (okBobbinButtonClicked)="printBobbinLabel($event)"
          [isBobbin]="isBobbin"
          [producedMaterialId]="producedMaterialId"
        ></app-print-label-range>
      </div>
    </div>
  </div>
</div>
