import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gluing-documents',
  templateUrl: './gluing-documents.component.html',
  styleUrls: ['./gluing-documents.component.scss']
})
export class GluingDocumentsComponent {
  @Input() public title = '';
  @Input() public disabled = false;

  public isExpanded = false;

  public toggleDocuments(): void {
    if (this.disabled) {
      return;
    }

    this.isExpanded = !this.isExpanded;
  }
}
