/* tslint:disable */
type MaterialBlockType = 'None' | 'Article' | 'Bom';
module MaterialBlockType {
  export const NONE: MaterialBlockType = 'None';
  export const ARTICLE: MaterialBlockType = 'Article';
  export const BOM: MaterialBlockType = 'Bom';
  export function values(): MaterialBlockType[] {
    return [NONE, ARTICLE, BOM];
  }
}

export { MaterialBlockType };
