import { Component, Input } from '@angular/core';
import { ProductionOrder } from 'chronos-core-client';

@Component({
  selector: 'app-product-order',
  templateUrl: './product-order.component.html',
  styleUrls: ['./product-order.component.scss']
})
export class ProductOrderComponent {
  @Input() public isItemCollapsed: boolean;
  @Input() public isItemExpandable = true;
  @Input() public orderItem: ProductionOrder;
}
