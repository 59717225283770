<div class="dismount-panel">
  <div class="dismount-panel__label">
    {{ label | translate }}
  </div>
  <div class="dismount-panel__wrapper">
    <lib-dismount-button
      [dismountableCount]="count"
      [loadingTopic]="loadingTopic"
      [disabled]="count <= 0"
      (buttonClicked)="onClick()"
    ></lib-dismount-button>
  </div>
</div>
