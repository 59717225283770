import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { LoadingTopicComponent } from './loading-topic.component';
import { LoadingNotificationService } from '../../services';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[libLoadingTopic]'
})
export class LoadingTopicDirective implements OnInit, OnDestroy {
  @Input('libLoadingTopic') public set loadingTopic(topic: string) {
    this.notification?.unsubscribe();
    this.notification = this.getNotification(topic);
  }
  @Input() public loadingIndicator = true;

  @Output() public loadingEvent = new EventEmitter<boolean>();

  private componentFactory: ComponentFactory<LoadingTopicComponent>;
  private componentRef?: ComponentRef<LoadingTopicComponent>;
  private notification: Subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private loadingNotificationService: LoadingNotificationService
  ) {}

  public ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingTopicComponent);
  }

  private getNotification(topic: string): Subscription {
    return this.loadingNotificationService.getNotifications(topic).subscribe((isLoading) => {
      this.onLoadingChange(isLoading);
    });
  }

  public ngOnDestroy(): void {
    this.componentRef?.destroy();
    this.notification.unsubscribe();
  }

  private onLoadingChange(isLoading: boolean): void {
    this.componentRef?.destroy();
    this.viewContainerRef.clear();
    this.loadingEvent.emit(isLoading);

    if (isLoading && this.loadingIndicator) {
      this.componentRef = this.viewContainerRef.createComponent(this.componentFactory);

      if (this.isButton()) {
        this.viewContainerRef.element.nativeElement.appendChild(this.componentRef.location.nativeElement);
      }
    }
  }

  private isButton(): boolean {
    return this.viewContainerRef.element.nativeElement.tagName === 'BUTTON';
  }
}
