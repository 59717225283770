<section class="chronos-dialog--size-lg">
  <div class="p-grid p-nogutter kpi">
    <div class="kpi__container">
      <div class="kpi__container-values" *ngIf="periodicQaCheck">
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'RUN_PHASE.FREQUENCY_TYPE' | translate"
          [kpiValue]="formatFrequencyType(periodicQaCheck.frequencyType) | translate"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'RUN_PHASE.FREQUENCY' | translate"
          [kpiValue]="'RUN_PHASE.MANUAL' | translate"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'RUN_PHASE.TIME_STAMP' | translate"
          [kpiValue]="periodicQaCheck.creationTime ? (periodicQaCheck.creationTime | utcToLocal | date : 'EEEE, d MMMM y, HH:mm') : ''"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
      </div>
    </div>
  </div>
  <app-qa-check-list-in-run
    *ngIf="periodicQaCheck"
    [fields]="periodicQaCheck.productionQaChecks"
    (formValidationChange)="setConfirmButtonStatus($event)"
    [isManualPeriodicQaCheck]="true"
    [isAutomaticPeriodicQaCheck]="false"
  ></app-qa-check-list-in-run>
  <lib-modal-footer
    [submitLabel]="'PALLET_CARD.QA_CONFIRM'"
    (submitClicked)="submitModal()"
    [submitDisabled]="!isConfirmButtonEnabled"
    [showDeclineButton]="false"
  ></lib-modal-footer>
</section>
