/* tslint:disable */
type TimeLeftIcon = 'None' | 'Warning' | 'Error';
module TimeLeftIcon {
  export const NONE: TimeLeftIcon = 'None';
  export const WARNING: TimeLeftIcon = 'Warning';
  export const ERROR: TimeLeftIcon = 'Error';
  export function values(): TimeLeftIcon[] {
    return [NONE, WARNING, ERROR];
  }
}

export { TimeLeftIcon };
