import { MountedContainerQaCheckComponent } from '@app/modules/run-phase/containers/input/components/mounted-container-qa-check/mounted-container-qa-check.component';
import { Component, Input, OnInit } from '@angular/core';
import { MountedMaterialStatus, PrimaryMaterialMounted } from 'chronos-core-client';
import { StatusParameter, palletQualtityStatusFlags } from '@app/shared/models';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss']
})
export class InputContainerComponent implements OnInit {
  public readonly MOUNTED_MATERIAL_STATUS = MountedMaterialStatus;
  public palletQualityStatus: StatusParameter;
  private qaText = 'NotApplicable';
  public showStatus = true;

  @Input() public inputPallet: PrimaryMaterialMounted;
  @Input() public isExpanded = false;

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.palletQualityStatus = this.getPalletQualityStatus();
  }

  public toggleContainer(): void {
    this.isExpanded = !this.isExpanded;
  }

  public getPalletQualityStatus(): StatusParameter {
    if (this.inputPallet.qaCheckResult) {
      if (this.inputPallet.qaCheckResult === this.qaText) {
        this.showStatus = false;
      }

      return palletQualtityStatusFlags[this.inputPallet.qaCheckResult];
    }
  }

  public openQACheckModalDialog(): void {
    this.dialogService.open(MountedContainerQaCheckComponent, {
      header: this.translateService.instant('QA_LOG.QA_CHECKS'),
      data: {
        container: this.inputPallet.container
      }
    });
  }
}
