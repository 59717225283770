import { Injectable } from '@angular/core';
import { ApproveDsService } from '@app/core/data-services/approve-ds/approve-ds.service';
import { Observable, Subject } from 'rxjs';
import { ApprovalReportConsumption, Downtime, ApprovalReportDates } from 'chronos-core-client';
import moment from 'moment-mini';
import { ListValue, LogService, ModalConfirmComponent } from 'chronos-shared';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SelectItem, TreeNode } from 'primeng/api';
import { ConfirmInformationCommand, ConfirmSendApproveReportCommand, SendApproveReportCommand } from '@app/modules/approve/commands';
import { ApprovalReportData, ApprovalReportParams } from '@app/modules/approve/models';
import { notificationTopic } from '@app/shared/utils';
import { ApproveMappingService } from '../approve-mapping/approve-mapping.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { TaskDsService } from '@app/core/data-services';
import { ApproveCommandCallback } from '@app/shared/components';

@Injectable({
  providedIn: 'root'
})
export class ApproveService {
  public readonly LOADING_TOPIC = notificationTopic.modalApproveChangeQuantity;

  public sendApproveReportCommand: SendApproveReportCommand;
  public confirmInformationCommand: ConfirmInformationCommand;
  public confirmSendApproveReportCommand: ConfirmSendApproveReportCommand;

  private approveReportParamsSubject = new Subject<ApprovalReportParams>();
  private approveReportParams$ = this.approveReportParamsSubject.asObservable();
  public approveReportData$ = this.approveReportParams$.pipe(
    switchMap((params) => this.getApprovalReportWithNotifications(params.workCenterId, params.date))
  );
  public downtimeViewData$ = this.approveReportParams$.pipe(
    switchMap((params) => this.taskDsService.getCombinedDowntimeData(params.workCenterId))
  );
  public approveReportOpenDates$ = this.approveReportParams$.pipe(
    switchMap((params) => this.approveDsService.getOpenApprovalReportDates(params.workCenterId)),
    map((data: ApprovalReportDates) => [data.openDates.map((date) => new Date(date)), data.approvedDates.map((date) => new Date(date))])
  );

  private approveReportWorCenterId: number;
  private approveReportDate: string;

  constructor(
    private approveDsService: ApproveDsService,
    private taskDsService: TaskDsService,
    private approveMappingService: ApproveMappingService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  public getTechnologyOptions(): Observable<ListValue[]> {
    return this.approveDsService
      .getWorkCenterGroupsForActiveMachines()
      .pipe(map((workCenterGroups) => this.approveMappingService.constructListValue(workCenterGroups)));
  }

  public getApprovalConsumptionData(
    workCenterId: number,
    approvalReportDate: string,
    approvalReportLineId: number
  ): Observable<ApprovalReportConsumption> {
    return this.approveDsService.getApprovalConsumptionData(workCenterId, approvalReportDate, approvalReportLineId).pipe();
  }

  public getApprovalWorkCenterOptions(workCenterGroupId: number, approvalReportDate: Date): Observable<SelectItem[]> {
    return this.approveDsService
      .getApprovalWorkCenters(workCenterGroupId, this.extractApprovalDate(approvalReportDate))
      .pipe(map((approvalWorkCenters) => this.approveMappingService.constructSelectItemList(approvalWorkCenters)));
  }

  public updateApprovalReportParams(workCenterId: number, approvalReportDate: Date): void {
    this.approveReportWorCenterId = workCenterId;
    this.approveReportDate = this.extractApprovalDate(approvalReportDate);
    this.refreshApprovalReport();
  }

  private refreshApprovalReport(): void {
    this.approveReportParamsSubject.next({ workCenterId: this.approveReportWorCenterId, date: this.approveReportDate });
  }

  public getConsumptionToApprovalData(root: TreeNode[], consumption: ApprovalReportConsumption): TreeNode[] {
    return this.approveMappingService.mapConsumptionToApprovalData(root, consumption);
  }

  public confirmApprovalReport(isConfirmRequired: boolean, approveCommandCallback: ApproveCommandCallback): void {
    if (isConfirmRequired) {
      const modalHeader = 'APPROVE.CONFIRM_MODAL_HEADER';
      const modalQuestion = 'APPROVE.CONFIRM_MODAL_CONTENT';

      this.openConfirmModal(modalHeader, modalQuestion).onClose.subscribe((accepted) => {
        if (accepted) {
          this.sendApprovalReport(approveCommandCallback);
        }
      });
    } else {
      this.sendApprovalReport(approveCommandCallback);
    }
  }

  public getDowntime(downtimeId: number): Observable<Downtime> {
    return this.taskDsService.getDowntime(downtimeId);
  }

  public setCommands(
    confirmInformationCommand: ConfirmInformationCommand,
    sendApproveReportCommand: SendApproveReportCommand,
    confirmSendApproveReportCommand: ConfirmSendApproveReportCommand
  ): void {
    this.confirmInformationCommand = confirmInformationCommand;
    this.sendApproveReportCommand = sendApproveReportCommand;
    this.confirmSendApproveReportCommand = confirmSendApproveReportCommand;
  }

  public changeConfirmInfoCommand(checked: boolean): void {
    this.confirmInformationCommand.enabled = checked;
    this.confirmInformationCommand.required = !checked;
    this.sendApproveReportCommand.enabled = checked;
    this.confirmSendApproveReportCommand.enabled = checked;
  }

  public extractApprovalDate(approvalReportDate: Date): string {
    return moment(approvalReportDate).format('YYYY-MM-DD');
  }

  public putApprovalReportProductionQuantities(
    workCenterId: number,
    approvalReportDate: string,
    approvalReportLineId: number,
    goodQuantity: number,
    wasteQuantity: number,
    maculatureQuantity: number
  ): Observable<null> {
    return this.approveDsService.putApprovalReportProductionQuantities(
      workCenterId,
      approvalReportDate,
      approvalReportLineId,
      goodQuantity,
      wasteQuantity,
      maculatureQuantity
    );
  }

  public setFinishedInterruptedOrder(isFinished: boolean, approvalReportLineId: number): void {
    this.openConfirmModalForFinishInterruptOrder(isFinished)
      .onClose.pipe(
        filter((accepted) => !!accepted),
        switchMap(() =>
          this.approveDsService.setFinishedInterruptedOrder(
            this.approveReportWorCenterId,
            isFinished,
            this.approveReportDate,
            approvalReportLineId
          )
        ),
        tap(() => {
          this.refreshApprovalReport();
        })
      )
      .subscribe();
  }

  private openConfirmModalForFinishInterruptOrder(isFinished: boolean): DynamicDialogRef {
    let modalHeader = 'APPROVE.CLOSE_ORDER';
    let modalQuestion = 'APPROVE.CLOSE_ORDER_QUESTION';

    if (isFinished) {
      modalHeader = 'APPROVE.OPEN_ORDER';
      modalQuestion = 'APPROVE.OPEN_ORDER_QUESTION';
    }

    return this.openConfirmModal(modalHeader, modalQuestion);
  }

  private getApprovalReportWithNotifications(workCenterId: number, approvalReportDate: string): Observable<ApprovalReportData> {
    return this.approveDsService
      .getApprovalReportWithNotifications(workCenterId, approvalReportDate)
      .pipe(map((approvalReport) => this.approveMappingService.mapApprovalReportToData(approvalReport)));
  }

  private openConfirmModal(header: string, question: string): DynamicDialogRef {
    return this.dialogService.open(ModalConfirmComponent, {
      header: this.translateService.instant(header),
      data: {
        question: this.translateService.instant(question),
        acceptable: true
      }
    });
  }

  private sendApprovalReport(callback: ApproveCommandCallback): void {
    this.approveDsService
      .confirmApprovalReport(this.approveReportWorCenterId, this.approveReportDate)
      .pipe(
        tap(() => {
          this.refreshApprovalReport();
        })
      )
      .subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.APPROVAL_REPORT_CONFIRMED');
        callback(true);
      });
  }
}
