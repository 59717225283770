import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { mergeMap } from 'rxjs/operators';
import { isSameSiteUrl } from './api-authorization.utils';

export type AuthorizeInterceptorMatcher = (req: HttpRequest<any>) => boolean;

export const AUTHORIZE_INTERCEPTOR_MATCHER = new InjectionToken<AuthorizeInterceptorMatcher[]>('AUTHORIZE_INTERCEPTOR_MATCHER');

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {
  constructor(
    private authorize: AuthorizeService,
    @Inject(AUTHORIZE_INTERCEPTOR_MATCHER) private interceptorMatchers: ((req: HttpRequest<any>) => boolean)[]
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authorize.getAccessToken().pipe(mergeMap((token) => this.processRequestWithToken(token, req, next)));
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(token: string, req: HttpRequest<any>, next: HttpHandler) {
    if (!!token && this.interceptorMatchers.some((m) => m(req))) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req);
  }
}

export const sameOriginMatcher: AuthorizeInterceptorMatcher = (req: HttpRequest<any>) => isSameSiteUrl(req.url);

export function createPrefixMatcher(urlPrefix: string) {
  return (req: HttpRequest<any>) => req.url.startsWith(urlPrefix);
}
