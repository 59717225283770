import { EmployeeRegistrationDetailsService } from '@app/modules/employee-registration/services';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveEmployeeRegistration, EmployeeRole } from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
@Component({
  selector: 'app-loggedin-employee-table',
  templateUrl: './loggedin-employee-table.component.html',
  styleUrls: ['./loggedin-employee-table.component.scss']
})
export class LoggedinEmployeeTableComponent {
  constructor(private translateService: TranslateService, private employeeRegistrationDetailsService: EmployeeRegistrationDetailsService) {}

  @Input() public loggedInData: ActiveEmployeeRegistration[];
  @Output() public loggedInEmpoyeeBlur = new EventEmitter<ActiveEmployeeRegistration>();
  @Output() public logOut = new EventEmitter<ActiveEmployeeRegistration>();

  public roles: ListValue[] = [
    { label: this.translateService.instant('EMPLOYEE_ROLE.OPERATOR'), value: EmployeeRole.OPERATOR },
    { label: this.translateService.instant('EMPLOYEE_ROLE.HELPER'), value: EmployeeRole.HELPER }
  ];

  public onLoggedInEmpoyeeBlur(note: string, activeEmployee: ActiveEmployeeRegistration) {
    this.loggedInEmpoyeeBlur.emit(this.setEmployeeDetails(activeEmployee, null, note));
  }

  public onLogOutClick(activeEmployee: ActiveEmployeeRegistration) {
    this.logOut.emit(activeEmployee);
  }

  public onRoleChange(newRole: EmployeeRole, activeEmployee: ActiveEmployeeRegistration) {
    this.loggedInEmpoyeeBlur.emit(this.setEmployeeDetails(activeEmployee, newRole, null));
  }

  private setEmployeeDetails(
    activeEmployee: ActiveEmployeeRegistration,
    newRole?: EmployeeRole,
    note?: string
  ): ActiveEmployeeRegistration {
    const emp = {
      id: activeEmployee.id,
      employeeId: activeEmployee.employeeId,
      externalEmployeeId: activeEmployee.externalEmployeeId,
      loginTime: activeEmployee.loginTime,
      name: activeEmployee.name,
      note: note ?? activeEmployee.note,
      role: newRole ?? activeEmployee.role,
      registrationPurpose: activeEmployee.registrationPurpose
    };
    return emp;
  }
}
