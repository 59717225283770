<section class="mounting page-padding">
  <p-tabMenu [model]="mountingTabs" [activeItem]="getActiveTab()">
    <ng-template pTemplate="item" let-item>
      <a [routerLink]="item.routerLink" class="p-menuitem-link flex justify-content-between align-items-center p-3">
        <div class="ui-menuitem-text">
          <span>
            {{ item.label | translate }}
          </span>
        </div>
      </a>
    </ng-template>
  </p-tabMenu>

  <div class="mounting__content">
    <router-outlet></router-outlet>
  </div>
</section>
