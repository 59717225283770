import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveOrderDsService, ActiveOrderPhaseDsService } from '@app/core/data-services';
import { OutputService } from '@app/modules/run-phase/services';
import { ListValue, LoadingNotificationService, LogService } from 'chronos-shared';
import { Observable, Subscription } from 'rxjs';
import { ActiveProductionOrder, ProducedMaterialType, Quantity } from 'chronos-core-client';
import { ActiveOrderDataQuery } from '@app/core/global-state';
import { nav } from '@app/shared/utils';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { OutputFinish, OutputFinishState } from '@app/modules/run-phase/models';

@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss']
})
export class OutputComponent implements OnInit, OnDestroy {
  public readonly ORDER_TYPE = ProducedMaterialType;
  public reasonOptions$: Observable<ListValue[]>;
  public activeProductionOrder: ActiveProductionOrder;
  public grossQuantity: Quantity;

  private subscriptions = new Subscription();

  constructor(
    private activeOrderDsService: ActiveOrderDsService,
    private activeOrderDataQuery: ActiveOrderDataQuery,
    private activeOrderPhaseDsService: ActiveOrderPhaseDsService,
    private outputService: OutputService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.activeProductionOrder = this.activeOrderDsService.getActiveOrder();
    this.reasonOptions$ = this.outputService.getReasonOptions();
    this.subscriptions.add(this.activeOrderDataQuery.outputQuantityPool$.subscribe((quantity) => (this.grossQuantity = quantity)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public navigateToFinishPhase(outputFinish: OutputFinish): void {
    LoadingNotificationService.publish(outputFinish.loadingTopic, true);
    this.activeOrderPhaseDsService
      .endOrderInAutomaticMode(outputFinish.finishState === OutputFinishState.Interrupt)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(outputFinish.loadingTopic, false);
        })
      )
      .subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.JOB_FINISHED');
        this.router.navigate([nav.finish]);
      });
  }
}
