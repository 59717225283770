import { Component, OnInit } from '@angular/core';
import { ProducedMaterial, ProductionQaCheck, Quantity } from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-qa-checks',
  templateUrl: './qa-checks-modal.component.html',
  styleUrls: ['./qa-checks-modal.component.scss']
})
export class QaChecksModalComponent implements OnInit {
  public fields: ProductionQaCheck[];
  public currentPallet: ProducedMaterial;
  public waste: Quantity;
  public isConfirmButtonEnabled = false;

  constructor(private ref: DynamicDialogRef, private conf: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.currentPallet = this.conf.data.pallet;
    this.fields = this.currentPallet.qaChecks;
    this.waste = this.getWasteQuantity(this.currentPallet);
  }

  public submitModal(): void {
    this.ref.close();
  }

  public setConfirmButtonStatus(status: boolean): void {
    this.isConfirmButtonEnabled = status;
  }

  private getWasteQuantity(pallet: ProducedMaterial): Quantity {
    const value = pallet.wasteAssignments.reduce((result, item) => item.waste.value + result, 0);
    const unitId = pallet.containerQuantity.unitId;

    return {
      value,
      unitId
    };
  }
}
