import { ListValue } from 'chronos-shared';
import { SystemParameterType } from 'chronos-core-client';
import { UntypedFormControl } from '@angular/forms';

export interface ParameterFormField {
  parameter: ParameterBase;
  control: UntypedFormControl;
}

export interface ParameterControlConfiguration {
  value: string | number;
  defaultValue?: string | number;
  id: number;
  label: string;
  required?: boolean;
  minValue?: number;
  maxValue?: number;
  minLength?: number;
  maxLength?: number;
  readonly: boolean;
  order: number;
  controlType: ParameterFieldType;
  type: string;
  name?: string;
  options?: ListValue[];
  systemParameterType: SystemParameterType;
}

export enum ParameterFieldType {
  Dropdown = 'DropDown',
  // eslint-disable-next-line id-blacklist
  Number = 'Number',
  Text = 'Text',
  Check = 'Check',
  Radio = 'Radio',
  PalletQuantity = 'PalletQuantity',
  BobbinQuantity = 'BobbinQuantity'
}

export abstract class ParameterBase {
  public value: string | number;
  public defaultValue: string | number;
  public id: number;
  public label: string;
  public required: boolean;
  public minValue: number;
  public maxValue: number;
  public minLength: number;
  public maxLength: number;
  public readonly: boolean;
  public order: number;
  public controlType: ParameterFieldType;
  public type: string;
  public name: string;
  public systemParameterType: SystemParameterType;
  public options: ListValue[];

  protected constructor(options: ParameterControlConfiguration) {
    this.value = options.value;
    this.defaultValue = options.defaultValue;
    this.id = options.id;
    this.label = options.label || '';
    this.required = !!options.required;
    this.minValue = options.minValue;
    this.maxValue = options.maxValue;
    this.minLength = options.minLength;
    this.maxLength = options.maxLength;
    this.readonly = !!options.readonly;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType;
    this.type = options.type;
    this.name = options.name;
    this.systemParameterType = options.systemParameterType;
    this.options = options.options || [];
  }
}

export class ParameterDropdown extends ParameterBase {
  public controlType = ParameterFieldType.Dropdown;

  constructor(options: ParameterControlConfiguration) {
    super(options);
  }
}

export class ParameterTextbox extends ParameterBase {
  public controlType = ParameterFieldType.Text;

  constructor(options: ParameterControlConfiguration) {
    super(options);
  }
}

export class ParameterCheck extends ParameterBase {
  public controlType = ParameterFieldType.Check;

  constructor(options: ParameterControlConfiguration) {
    super(options);
  }
}

export class ParameterRadio extends ParameterBase {
  public controlType = ParameterFieldType.Radio;

  constructor(options: ParameterControlConfiguration) {
    super(options);
  }
}

export class ParameterQuantityPerPallet extends ParameterBase {
  public controlType = ParameterFieldType.PalletQuantity;

  constructor(options: ParameterControlConfiguration) {
    super(options);
  }
}

export class ParameterBobbinQuantityPerPallet extends ParameterBase {
  public controlType = ParameterFieldType.BobbinQuantity;

  constructor(options: ParameterControlConfiguration) {
    super(options);
  }
}
