/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserUsDTO } from '../models/user-us-dto';
import { UserWorkcentersDetail } from '../models/user-workcenters-detail';
import { UsersettingUe } from '../models/usersetting-ue';
import { WcSettings } from '../models/wc-settings';
import { LocaleLc } from '../models/locale-lc';
import { TimezoneTz } from '../models/timezone-tz';
import { UsergrpUg } from '../models/usergrp-ug';
import { AclroleAr } from '../models/aclrole-ar';
import { ValidUserParam } from '../models/valid-user-param';
@Injectable({
  providedIn: 'root'
})
class UserService extends __BaseService {
  static readonly getUsersPath = '/getusers/{orid}';
  static readonly getUserPath = '/getuserdetail/{orid}/{usid}';
  static readonly getUsersWithWorkcenterIdPath = '/getuserswithworkcenterid/{orid}';
  static readonly getUserSettingPath = '/getusersettingdetail/{orid}/{usid}';
  static readonly addUserPath = '/adduser/{orid}';
  static readonly addUserSettingsPath = '/addusersettings/{orid}';
  static readonly updateUserPath = '/updateuser/{orid}';
  static readonly updateUserSettingPath = '/updateusersettings/{ueid}';
  static readonly removeUserPath = '/removeuser/{orid}/{usid}';
  static readonly activeInactiveUserPath = '/active-inactive/{orid}';
  static readonly getLangaugesPath = '/getlangauges/{orid}';
  static readonly getTimeZonesPath = '/gettimezones/{orid}';
  static readonly getUserGroupsPath = '/getusergroups/{orid}';
  static readonly getUserRolesPath = '/getuserroles/{orid}';
  static readonly isValidUserEmailPath = '/validuseremail/{orid}';
  static readonly isValidUserNamePath = '/validusername/{orid}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getUsersResponse(orid: number): __Observable<__StrictHttpResponse<Array<UserUsDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/getusers/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserUsDTO>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getUsers(orid: number): __Observable<Array<UserUsDTO>> {
    return this.getUsersResponse(orid).pipe(__map((_r) => _r.body as Array<UserUsDTO>));
  }

  /**
   * @param params The `UserService.GetUserParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getUserResponse(params: UserService.GetUserParams): __Observable<__StrictHttpResponse<UserUsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getuserdetail/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.usid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserUsDTO>;
      })
    );
  }
  /**
   * @param params The `UserService.GetUserParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getUser(params: UserService.GetUserParams): __Observable<UserUsDTO> {
    return this.getUserResponse(params).pipe(__map((_r) => _r.body as UserUsDTO));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getUsersWithWorkcenterIdResponse(orid: number): __Observable<__StrictHttpResponse<Array<UserWorkcentersDetail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/getuserswithworkcenterid/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserWorkcentersDetail>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getUsersWithWorkcenterId(orid: number): __Observable<Array<UserWorkcentersDetail>> {
    return this.getUsersWithWorkcenterIdResponse(orid).pipe(__map((_r) => _r.body as Array<UserWorkcentersDetail>));
  }

  /**
   * @param params The `UserService.GetUserSettingParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getUserSettingResponse(params: UserService.GetUserSettingParams): __Observable<__StrictHttpResponse<UsersettingUe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getusersettingdetail/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.usid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersettingUe>;
      })
    );
  }
  /**
   * @param params The `UserService.GetUserSettingParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getUserSetting(params: UserService.GetUserSettingParams): __Observable<UsersettingUe> {
    return this.getUserSettingResponse(params).pipe(__map((_r) => _r.body as UsersettingUe));
  }

  /**
   * @param params The `UserService.AddUserParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addUserResponse(params: UserService.AddUserParams): __Observable<__StrictHttpResponse<UserUsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/adduser/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserUsDTO>;
      })
    );
  }
  /**
   * @param params The `UserService.AddUserParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addUser(params: UserService.AddUserParams): __Observable<UserUsDTO> {
    return this.addUserResponse(params).pipe(__map((_r) => _r.body as UserUsDTO));
  }

  /**
   * @param params The `UserService.AddUserSettingsParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `usid`:
   *
   * - `ueid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addUserSettingsResponse(params: UserService.AddUserSettingsParams): __Observable<__StrictHttpResponse<UsersettingUe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.usid != null) __params = __params.set('usid', params.usid.toString());
    if (params.ueid != null) __params = __params.set('ueid', params.ueid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/addusersettings/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersettingUe>;
      })
    );
  }
  /**
   * @param params The `UserService.AddUserSettingsParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `usid`:
   *
   * - `ueid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addUserSettings(params: UserService.AddUserSettingsParams): __Observable<UsersettingUe> {
    return this.addUserSettingsResponse(params).pipe(__map((_r) => _r.body as UsersettingUe));
  }

  /**
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateUserResponse(params: UserService.UpdateUserParams): __Observable<__StrictHttpResponse<UserUsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/updateuser/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserUsDTO>;
      })
    );
  }
  /**
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateUser(params: UserService.UpdateUserParams): __Observable<UserUsDTO> {
    return this.updateUserResponse(params).pipe(__map((_r) => _r.body as UserUsDTO));
  }

  /**
   * @param params The `UserService.UpdateUserSettingParams` containing the following parameters:
   *
   * - `ueid`:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateUserSettingResponse(params: UserService.UpdateUserSettingParams): __Observable<__StrictHttpResponse<UsersettingUe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.usid != null) __params = __params.set('usid', params.usid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/updateusersettings/${encodeURIComponent(String(params.ueid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersettingUe>;
      })
    );
  }
  /**
   * @param params The `UserService.UpdateUserSettingParams` containing the following parameters:
   *
   * - `ueid`:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateUserSetting(params: UserService.UpdateUserSettingParams): __Observable<UsersettingUe> {
    return this.updateUserSettingResponse(params).pipe(__map((_r) => _r.body as UsersettingUe));
  }

  /**
   * @param params The `UserService.RemoveUserParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  removeUserResponse(params: UserService.RemoveUserParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/removeuser/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.usid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `UserService.RemoveUserParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  removeUser(params: UserService.RemoveUserParams): __Observable<number> {
    return this.removeUserResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `UserService.ActiveInactiveUserParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  activeInactiveUserResponse(params: UserService.ActiveInactiveUserParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/active-inactive/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `UserService.ActiveInactiveUserParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  activeInactiveUser(params: UserService.ActiveInactiveUserParams): __Observable<number> {
    return this.activeInactiveUserResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getLangaugesResponse(orid: number): __Observable<__StrictHttpResponse<Array<LocaleLc>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/getlangauges/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocaleLc>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getLangauges(orid: number): __Observable<Array<LocaleLc>> {
    return this.getLangaugesResponse(orid).pipe(__map((_r) => _r.body as Array<LocaleLc>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getTimeZonesResponse(orid: number): __Observable<__StrictHttpResponse<Array<TimezoneTz>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/gettimezones/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TimezoneTz>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getTimeZones(orid: number): __Observable<Array<TimezoneTz>> {
    return this.getTimeZonesResponse(orid).pipe(__map((_r) => _r.body as Array<TimezoneTz>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getUserGroupsResponse(orid: number): __Observable<__StrictHttpResponse<Array<UsergrpUg>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/getusergroups/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UsergrpUg>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getUserGroups(orid: number): __Observable<Array<UsergrpUg>> {
    return this.getUserGroupsResponse(orid).pipe(__map((_r) => _r.body as Array<UsergrpUg>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getUserRolesResponse(orid: number): __Observable<__StrictHttpResponse<Array<AclroleAr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/getuserroles/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AclroleAr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getUserRoles(orid: number): __Observable<Array<AclroleAr>> {
    return this.getUserRolesResponse(orid).pipe(__map((_r) => _r.body as Array<AclroleAr>));
  }

  /**
   * @param params The `UserService.IsValidUserEmailParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  isValidUserEmailResponse(params: UserService.IsValidUserEmailParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/validuseremail/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param params The `UserService.IsValidUserEmailParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  isValidUserEmail(params: UserService.IsValidUserEmailParams): __Observable<boolean> {
    return this.isValidUserEmailResponse(params).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param params The `UserService.IsValidUserNameParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  isValidUserNameResponse(params: UserService.IsValidUserNameParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/validusername/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param params The `UserService.IsValidUserNameParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  isValidUserName(params: UserService.IsValidUserNameParams): __Observable<boolean> {
    return this.isValidUserNameResponse(params).pipe(__map((_r) => _r.body as boolean));
  }
}

module UserService {
  /**
   * Parameters for GetUser
   */
  export interface GetUserParams {
    usid: number;
    orid: number;
  }

  /**
   * Parameters for GetUserSetting
   */
  export interface GetUserSettingParams {
    usid: number;
    orid: number;
  }

  /**
   * Parameters for AddUser
   */
  export interface AddUserParams {
    orid: number;
    body?: UserUsDTO;
  }

  /**
   * Parameters for AddUserSettings
   */
  export interface AddUserSettingsParams {
    orid: number;
    usid?: number;
    ueid?: number;
    body?: WcSettings;
  }

  /**
   * Parameters for UpdateUser
   */
  export interface UpdateUserParams {
    orid: number;
    body?: UserUsDTO;
  }

  /**
   * Parameters for UpdateUserSetting
   */
  export interface UpdateUserSettingParams {
    ueid: number;
    usid?: number;
    orid?: number;
    body?: WcSettings;
  }

  /**
   * Parameters for RemoveUser
   */
  export interface RemoveUserParams {
    usid: number;
    orid: number;
  }

  /**
   * Parameters for ActiveInactiveUser
   */
  export interface ActiveInactiveUserParams {
    orid: number;
    body?: UserUsDTO;
  }

  /**
   * Parameters for IsValidUserEmail
   */
  export interface IsValidUserEmailParams {
    orid: number;
    body?: ValidUserParam;
  }

  /**
   * Parameters for IsValidUserName
   */
  export interface IsValidUserNameParams {
    orid: number;
    body?: ValidUserParam;
  }
}

export { UserService };
