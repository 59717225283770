import { NgModule } from '@angular/core';
import { DocumentEditModalComponent } from '@app/modules/documents/containers/documents/components/document-edit-modal/document-edit-modal.component';
import { RemarkActionDirective } from '@app/modules/documents/directives';
import { SharedModule } from '@app/shared/shared.module';
import { DocumentsComponent } from './containers/documents/documents.component';
import { DocumentsService } from './services';
import { TabInformationComponent } from './containers/tab-information/tab-information.component';
import { TabDocumentComponent } from './containers/tab-document/tab-document.component';
import { TabLabelComponent } from './containers/tab-label/tab-label.component';
import { MaterialLabelTableComponent } from './components/material-label-table/material-label-table.component';
import { RemarkListComponent } from './containers/documents/components/remark-list/remark-list.component';
import { RemarkContainerComponent } from './containers/documents/components/remark-container/remark-container.component';
import { ModalPrintLabelComponent } from './components/modal-print-label/modal-print-label.component';

@NgModule({
  declarations: [
    DocumentsComponent,
    DocumentEditModalComponent,
    RemarkActionDirective,
    TabInformationComponent,
    TabDocumentComponent,
    TabLabelComponent,
    MaterialLabelTableComponent,
    RemarkListComponent,
    RemarkContainerComponent,
    ModalPrintLabelComponent
  ],
  imports: [SharedModule],
  providers: [DocumentsService]
})
export class DocumentsModule {}
