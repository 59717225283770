<p-dropdown
  [options]="mappedArticleItems"
  [(ngModel)]="selectedArticleItem"
  [style]="{ width: '100%' }"
  [disabled]="isDisabled"
  (onChange)="changeSelectedArticle()"
>
  <ng-template pTemplate="selectedItem">
    <div class="item">
      <div class="item__main-info">
        {{ selectedArticleItem.customerName }} | {{ selectedArticleItem.articleExternalId }} | {{ selectedArticleItem.configurationId }}
      </div>
      <div class="item__secondary-info">{{ selectedArticleItem.articleName }} / {{ selectedArticleItem.description }}</div>
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class="item">
      <div class="item__main-info">
        {{ item.value.customerName }} | {{ item.value.articleExternalId }} | {{ item.value.configurationId }}
      </div>
      <div class="item__secondary-info">{{ item.value.articleName }} / {{ item.value.description }}</div>
    </div>
  </ng-template>
</p-dropdown>
