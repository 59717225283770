<lib-change-order-quantity
  [goodQuantity]="goodQuantity"
  [waste]="waste"
  [maculature]="maculature"
  [goodQuantitLabel]="goodQuantitLabel"
  [wasteLabel]="wasteLabel"
  [maculatureLabel]="maculatureLabel"
  (maculatureChanged)="updateMaculature($event)"
  (wasteChanged)="updateWaste($event)"
  (goodQuantityChanged)="updateGoodQuantity($event)"
  (isValid)="(isEnabled)"
></lib-change-order-quantity>

<lib-modal-footer [submitLabel]="'MODAL_FOOTER.YES'" (submitClicked)="acceptModal()" (declineClicked)="rejectModal()"></lib-modal-footer>
