<div
  *ngIf="item; else noItemTemplate"
  class="scrollable-item"
  [ngClass]="{
    'scrollable-item--active': isItemActive,
    'scrollable-item--preliminary': isItemPreliminary
  }"
  (click)="changeSelectedItem()"
>
  <div class="scrollable-item__labels">
    <lib-status-flag
      *ngIf="NC_STATUS_FLAGS[item.nonConformanceType] as ncStatus"
      class="status__item"
      [statusText]="ncStatus.text | translate"
      [styleClass]="ncStatus.labelClass"
      size="small"
    ></lib-status-flag>
  </div>
  <div class="scrollable-item__field">{{ item.nonConformanceDate | utcToLocal | date : 'dd.MM.yyyy' }}</div>
  <div class="scrollable-item__field">{{ item.externalNonConformanceId }}</div>
  <div class="scrollable-item__batch">{{ item.batchId }}</div>
  <div class="scrollable-item__field">{{ item.issueType }}</div>
</div>

<ng-template #noItemTemplate>
  <div class="scrollable-item">
    <div class="scrollable-item__field">{{ noItemLabel }}</div>
  </div>
</ng-template>
