import { NgModule } from '@angular/core';
import { ChronosInputDirective } from './input/chronos-input.directive';
import { DummyContainerDirective } from './mounting-containers/dummy-container/dummy-container.directive';
import { EmptyContainerDirective } from './mounting-containers/empty-container/empty-container.directive';
import { ItemContainerDirective } from './mounting-containers/item-container/item-container.directive';
import { MaterialBlockDirective } from './mounting-containers/material-block/material-block.directive';
import { ChronosTextareaDirective } from './textarea/chronos-textarea.directive';
import { WhenLoadingElementDirective } from './when-loading/when-loading-element/when-loading-element.directive';
import { EnterKeyHandlerDirective } from './enter-handler/enter-key-handler/enter-key-handler.directive';
import { FormControlExtensionDirective } from './enter-handler/form-control-extension/form-control-extension.directive';
import { LoadingTopicDirective } from './loading-topic/loading-topic.directive';
import { LoadingTopicComponent } from './loading-topic/loading-topic.component';

@NgModule({
  declarations: [
    ChronosInputDirective,
    ChronosTextareaDirective,
    DummyContainerDirective,
    ItemContainerDirective,
    EmptyContainerDirective,
    MaterialBlockDirective,
    WhenLoadingElementDirective,
    EnterKeyHandlerDirective,
    FormControlExtensionDirective,
    LoadingTopicDirective,
    LoadingTopicComponent
  ],
  exports: [
    ChronosInputDirective,
    ChronosTextareaDirective,
    DummyContainerDirective,
    ItemContainerDirective,
    EmptyContainerDirective,
    MaterialBlockDirective,
    WhenLoadingElementDirective,
    EnterKeyHandlerDirective,
    FormControlExtensionDirective,
    LoadingTopicDirective
  ]
})
export class DirectivesModule {}
