import { Injectable } from '@angular/core';
import { OverviewMaterialDsService, SecondaryMaterialDsService, BillOfMaterialDsService } from '@app/core/data-services';
import { MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SplitBillOfMaterialData, SplitBillOfMaterialRowEntry } from 'chronos-core-client';

@Injectable()
export class TabSecondaryService {
  private secondaryMaterialSubject = new ReplaySubject<MaterialTableTreeNode[]>(1);
  private secondaryMountedMaterialSubject = new ReplaySubject<MaterialTableTreeNode[]>(1);

  public secondaryMaterial$: Observable<MaterialTableTreeNode[]> = this.secondaryMaterialSubject.asObservable();
  public secondaryMountedMaterial$: Observable<MaterialTableTreeNode[]> = this.secondaryMountedMaterialSubject.asObservable();

  constructor(
    private overviewMaterialDsService: OverviewMaterialDsService,
    private secondaryMaterialDsService: SecondaryMaterialDsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private billOfMaterialDsService: BillOfMaterialDsService
  ) {}

  public getSecondaryMaterialOverviewData(): Observable<MaterialTableTreeNode[]> {
    return this.secondaryMaterialDsService.getSecondaryMaterialOverviewData().pipe(
      tap((secondaryMaterial) => {
        this.secondaryMaterialSubject.next(secondaryMaterial);
      })
    );
  }

  public getSecondaryMountedMaterialData(): Observable<MaterialTableTreeNode[]> {
    return this.secondaryMaterialDsService.getSecondaryMountedMaterialData().pipe(
      tap((secondaryMaterial) => {
        this.secondaryMountedMaterialSubject.next(secondaryMaterial);
      })
    );
  }

  public renewSecondaryMaterial(): void {
    this.getSecondaryMaterialOverviewData().subscribe();
    this.getSecondaryMountedMaterialData().subscribe();
  }

  public getScannedSecondaryContainerInfo(identificationCode: string): Observable<any> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.overviewMaterialDsService.scanIdentificationCode(workCenterId, identificationCode);
  }

  public dismountDummy(containerId: number, mountedMaterialId: number): Observable<null> {
    return this.secondaryMaterialDsService.dismount(containerId, 0, false, mountedMaterialId);
  }

  public getMaterialList(): Observable<any> {
    return this.secondaryMaterialDsService.getMaterialList();
  }

  public getBillOfMaterialSplitData(productionOrderId: number, billOfMaterialId: number): Observable<SplitBillOfMaterialData> {
    return this.billOfMaterialDsService.getSplitBillOfMaterialDetails(productionOrderId, billOfMaterialId);
  }

  public splitBillOfMaterialRow(entry: SplitBillOfMaterialRowEntry, productionOrderId: number): Observable<SplitBillOfMaterialData> {
    return this.billOfMaterialDsService.splitBillOfMaterialRow(entry, productionOrderId);
  }
}
