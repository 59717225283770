/* tslint:disable */
type ContainerStatus = 'None' | 'Seed' | 'Available' | 'Active' | 'Mounted' | 'Consumed';
module ContainerStatus {
  export const NONE: ContainerStatus = 'None';
  export const SEED: ContainerStatus = 'Seed';
  export const AVAILABLE: ContainerStatus = 'Available';
  export const ACTIVE: ContainerStatus = 'Active';
  export const MOUNTED: ContainerStatus = 'Mounted';
  export const CONSUMED: ContainerStatus = 'Consumed';
  export function values(): ContainerStatus[] {
    return [NONE, SEED, AVAILABLE, ACTIVE, MOUNTED, CONSUMED];
  }
}

export { ContainerStatus };
