import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDirection } from '../../../models';

@Component({
  selector: 'lib-order-by-button',
  templateUrl: './order-by-button.component.html',
  styleUrls: ['./order-by-button.component.scss']
})
export class OrderByButtonComponent {
  public readonly SortDirection = SortDirection;

  @Input() public order: SortDirection = SortDirection.ASC;

  @Output() public orderChanged = new EventEmitter<SortDirection>();

  public onOrderByClick(): void {
    const newOrder = this.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    this.orderChanged.emit(newOrder);
  }
}
