import { Injectable } from '@angular/core';
import {
  QuantityPerPalletViewModel,
  BobbinQuantityViewModel,
  SetSetupParameterKindEntry,
  SetupParameterKind,
  SetupParameters,
  SetupParameterService,
  SimpleValueEntry,
  SystemParameterType
} from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';
import { ActiveWorkCenterService } from '@app/core/workcenter';
@Injectable({
  providedIn: 'root'
})
export class ParametersDsService {
  constructor(private setupParameterService: SetupParameterService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public getSetupParameterKinds(productionOrderId: number): Observable<SetupParameterKind> {
    return this.setupParameterService.getSetupParameterKinds(productionOrderId);
  }

  public setSetupParameterKind(setupParameterKindId: number, productionOrderId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: SetSetupParameterKindEntry = {
      setupParameterKindId,
      workCenterId
    };
    const params: SetupParameterService.PutSetupParameterKindParams = {
      productionOrderId,
      entry
    };
    return this.setupParameterService.putSetupParameterKind(params);
  }

  public getSetupParameters(productionOrderId: number): Observable<SetupParameters> {
    return this.setupParameterService.getSetupParameters(productionOrderId);
  }

  public setSetupParameter(
    setupParameterId: number,
    paramNumericValue: number,
    paramTextValue: string,
    systemParameterType: SystemParameterType,
    productionOrderId: number
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: SimpleValueEntry = {
      numericValue: paramNumericValue,
      setupParameterId,
      systemParameterType,
      textValue: paramTextValue,
      workCenterId
    };
    const params: SetupParameterService.PutSetupParameterParams = {
      productionOrderId,
      entry
    };
    return this.setupParameterService.putSetupParameter(params);
  }

  public getBobbinQuantity(productionOrderId: number): Observable<BobbinQuantityViewModel> {
    return this.setupParameterService.getBobbinQuantity(productionOrderId);
  }

  public getPalletQuantity(productionOrderId: number): Observable<QuantityPerPalletViewModel> {
    return this.setupParameterService.getQuantityPerPallet(productionOrderId);
  }
}
