<app-modal-main-material
  *ngIf="secondaryMaterial$ | async as traceMaterial"
  [availableMountingMaterial]="traceMaterial.materials"
  [billOfMaterialArticleList]="traceMaterial.articles"
  [suggestedQuantity]="suggestedQuantity"
  [submitButtonLabel]="submitButtonLabel"
  [whenLoadingTopic]="LOADING_TOPIC"
  (mountingMaterialSubmit)="onConsumeClick($event)"
  (emitSelectedRow)="onSelectRow($event)"
  [tabName]="tabName"
  [isDropDownOptionDisabled]="isDropDownOptionDisabled"
></app-modal-main-material>
