import { Injectable } from '@angular/core';
import { MaterialTableDataRowDisplayMode, MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import {
  ArticleDescription,
  SecondaryMaterialBlock,
  SecondaryMaterialMounted,
  TraceMaterialBlock,
  TraceMaterialMounted,
  FlushingPrinciple
} from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class MountingMaterialServiceDsService {
  public mapSecondaryMaterial(materialBlocks: SecondaryMaterialBlock[]): MaterialTableTreeNode[] {
    return materialBlocks.map((materialBlock) => ({
      data: { ...materialBlock, displayMode: MaterialTableDataRowDisplayMode.BillOfMaterial },
      children: materialBlock.mountedMaterials.map((mountedMaterial) =>
        this.mapChild(mountedMaterial, mountedMaterial.article, materialBlock.flushingPrinciple)
      )
    }));
  }

  public mapTraceMaterial(materialBlocks: TraceMaterialBlock[]): MaterialTableTreeNode[] {
    return materialBlocks
      .map<MaterialTableTreeNode>((materialBlock) => ({
        data: { ...materialBlock, displayMode: MaterialTableDataRowDisplayMode.BillOfMaterial },
        children: materialBlock.mountedMaterials.map((mountedMaterial) => this.mapChild(mountedMaterial, materialBlock.article))
      }))
      .sort((a, b) => a.data.article.externalArticleId.localeCompare(b.data.article.externalArticleId));
  }

  private mapChild(
    mountedMaterial: SecondaryMaterialMounted | TraceMaterialMounted,
    article: ArticleDescription,
    flushingPrinciple?: FlushingPrinciple
  ): MaterialTableTreeNode {
    return {
      data: {
        ...mountedMaterial,
        mountedTime: new Date(mountedMaterial.mountedTime),
        batchInternalTitle: 'MOUNTING.BATCH_INTERNAL',
        batchExternalTitle: 'MOUNTING.BATCH_EXTERNAL',
        infoTitle: 'MOUNTING.INFO',
        article,
        displayMode: MaterialTableDataRowDisplayMode.Container,
        flushingPrinciple
      }
    };
  }
}
