import { SetupFooterCommandBase } from '@app/shared/components';
import { ApproveService } from '@app/modules/approve/services';

export class ConfirmInformationCommand implements SetupFooterCommandBase {
  public enabled = false;
  public buttonText: string;
  public required = true;

  constructor(private approveService: ApproveService) {}

  public execute(): void {
    this.approveService.changeConfirmInfoCommand(this.enabled);
  }
}
