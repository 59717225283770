<div class="printing-parameters__item" [ngClass]="{ 'printing-parameters__item--collapsible': collapsible }">
  <div class="printing-parameters__item--col">{{ title | translate }}</div>
  <div class="printing-parameters__item--col">
    <lib-input-number
      [(ngModel)]="model[MODEL_KEY.FROM]"
      (ngModelChange)="validateInput(MODEL_KEY.FROM)"
      [min]="INPUT_MIN_QUANTITY"
      [max]="maxQuantity"
      [inputStyle]="INPUT_STYLE"
      [disabled]="isFromDisabled || isModelDisabled"
      class="printing-parameters__item-value"
    ></lib-input-number>
    <span>{{ 'OUTPUT_UNIT.FROM' | translate }}</span>
  </div>
  <div class="printing-parameters__item--col">
    <lib-input-number
      [(ngModel)]="model[MODEL_KEY.TO]"
      (ngModelChange)="validateInput(MODEL_KEY.TO)"
      [min]="INPUT_MIN_QUANTITY"
      [max]="maxQuantity"
      [inputStyle]="INPUT_STYLE"
      [disabled]="isModelDisabled"
      class="printing-parameters__item-value"
    ></lib-input-number>
    <span class="printing-parameters__item-lable">{{ 'OUTPUT_UNIT.TO' | translate }}</span>
    <lib-button
      [loadingTopic]="loadingTopic"
      [disabled]="disablePrintButton || !(this.isQuantityPerOuterValid && !this.isModelInvalid && !this.isModelDisabled)"
      [label]="'OUTPUT_UNIT.OK'"
      (clicked)="isBobbin === false ? printLabel() : printBobbinLabel()"
      [loadingIndicator]="false"
      class="printing-parameters__submit-button"
    ></lib-button>
  </div>
</div>
