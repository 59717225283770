import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DeviationReason, ProductionOrder, ProductionOrderProductionLoyalty } from 'chronos-core-client';
import { ModalProductionLoyaltyService } from '@app/modules/orders/services/modal-production-loyalty/modal-production-loyalty.service';
import { finalize } from 'rxjs/operators';
import { notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService } from 'chronos-shared';
import { getCombinationIcon } from '@app/modules/orders/utils';

@Component({
  selector: 'app-modal-production-loyalty',
  templateUrl: './modal-production-loyalty.component.html',
  styleUrls: ['./modal-production-loyalty.component.scss']
})
export class ModalProductionLoyaltyComponent implements OnInit {
  public loyaltyReasons: ProductionOrderProductionLoyalty;
  public selectedLoyaltyReason: DeviationReason;
  public productionOrderComment: string;
  public productionOrder: ProductionOrder;
  public itemCollapsed = true;
  public combinationIcon: string;

  public readonly LOADING_TOPIC = notificationTopic.modalProductionLoyalty;

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private modalProductionLoyaltyService: ModalProductionLoyaltyService
  ) {}

  public ngOnInit(): void {
    this.loyaltyReasons = this.config.data?.productionLoyalty;
    this.productionOrder = this.config.data?.productionOrder;
    this.combinationIcon = getCombinationIcon(this.productionOrder);
  }

  public toggleContent(): void {
    this.itemCollapsed = !this.itemCollapsed;
  }

  public submitLoyaltyReason(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    const deviationReasonId = this.selectedLoyaltyReason?.deviationReasonId;
    const isShowingAllOrderItems = this.config.data?.isShowingAllOrderItems;

    this.modalProductionLoyaltyService
      .setNextProductionOrder(
        this.productionOrder.productionOrderId,
        deviationReasonId,
        this.productionOrderComment,
        isShowingAllOrderItems
      )
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close(true);
      });
  }

  public cancelLoyaltyReason(): void {
    this.ref.close(false);
  }

  public setSelectedLoyaltyReason(selectedLoyaltyReason: DeviationReason): void {
    this.selectedLoyaltyReason = selectedLoyaltyReason;
  }
}
