import { Component, OnDestroy, OnInit } from '@angular/core';
import { PeriodicQaCheck, CreateManualQaChecksEntry, CreateManualQaCheckEntry } from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QaCheckService } from '@app/core/services';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import moment from 'moment-mini';
@Component({
  selector: 'app-periodic-qa-check-modal',
  templateUrl: './periodic-qa-check-modal.component.html',
  styleUrls: ['./periodic-qa-check-modal.component.scss']
})
export class PeriodicQaCheckModalComponent implements OnInit, OnDestroy {
  public periodicQaCheck: PeriodicQaCheck = null;
  public isConfirmButtonEnabled = false;
  public manualQaChecksEntry: CreateManualQaChecksEntry;
  private subscriptions = new Subscription();

  constructor(private ref: DynamicDialogRef, private conf: DynamicDialogConfig, public qaCheckService: QaCheckService) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.qaCheckService.getManualProductionQaChecks(this.conf.data.productionOrderId).subscribe((data) => (this.periodicQaCheck = data))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public submitModal(): void {
    this.subscriptions.add(
      this.qaCheckService.manualQaCheckParamsSubject$
        .pipe(
          tap((manualQaChekEntry: CreateManualQaChecksEntry) => {
            this.manualQaChecksEntry = manualQaChekEntry;
          })
        )
        .subscribe()
    );

    if (!this.manualQaChecksEntry) {
      const productionQaChecks: CreateManualQaCheckEntry[] = [];
      this.periodicQaCheck.productionQaChecks.every((qaCheckResult) =>
        productionQaChecks.push({
          productionQaCheckId: qaCheckResult.id,
          checkResult: qaCheckResult.checkResult,
          comment: qaCheckResult.comment,
          measurementResult: qaCheckResult.measurementResult
        })
      );
      this.qaCheckService.manualQaCheckParamsSubject.next({
        creationTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]'),
        productionQaChecks
      });
    }

    this.qaCheckService.createManualQaChecks(this.manualQaChecksEntry).subscribe();
    this.ref.close();
  }

  public setConfirmButtonStatus(status: boolean): void {
    this.isConfirmButtonEnabled = status;
  }

  public formatFrequencyType(data: string): string {
    return `FREQUENCY_TYPE.${data.toUpperCase()}`;
  }
}
