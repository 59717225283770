import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LineClearanceService } from '@app/modules/setup-phase/services/line-clearance/line-clearance.service';
import { NAButtonValidator, QaCheckField } from '@app/shared/models';
import { QaCheckService } from '@app/core/services';
import { QaCheckResult } from 'chronos-core-client';

@Component({
  selector: 'app-line-clearance-item',
  templateUrl: './line-clearance-item.component.html',
  styleUrls: ['./line-clearance-item.component.scss']
})
export class LineClearanceItemComponent implements OnInit, AfterViewInit {
  @ViewChild('infoInput', { static: true }) public infoInput: ElementRef;
  @Input() public parentForm: UntypedFormGroup;
  @Input() public field: QaCheckField;
  @Input() public index: number;

  public infoText: string;

  constructor(
    private lineClearanceService: LineClearanceService,
    private changeDetector: ChangeDetectorRef,
    public qaCheckService: QaCheckService
  ) {}

  public isCollapsed = true;
  public isInfoRequired = false;
  public isInValid = false;

  public ngOnInit(): void {
    if (this.parentForm.get('check').value === QaCheckResult.NOT_APPLICABLE) {
      this.isInfoRequired = true;
      this.validateModel({ isRequired: true, button: QaCheckResult.NOT_APPLICABLE });
    }
  }

  public ngAfterViewInit(): void {
    const eventHandler = (event: KeyboardEvent | MouseEvent) => {
      if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
        return;
      }
      this.lineClearanceService.checkCapsLockSubject.next(event);

      this.changeDetector.markForCheck();
    };

    this.infoInput?.nativeElement?.addEventListener('keyup', eventHandler);
    this.infoInput?.nativeElement?.addEventListener('mousedown', eventHandler);
  }

  public toggleContent(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public validateModel(buttonValidator: NAButtonValidator) {
    this.isInfoRequired = buttonValidator.isRequired;
    const commentControl = this.parentForm.get('comment');
    this.isInValid = !this.validateText(commentControl.value);

    if (this.isInfoRequired) {
      if (this.isInValid) {
        this.setValidation(buttonValidator);
        this.setFocusInfoText();
      }
    } else {
      this.lineClearanceService.setFocusSubject.next(true);
      this.deleteValidator(this.index);
      this.isInValid = false;
    }
  }

  public onInfoInputBlur() {
    this.lineClearanceService.setFocusSubject.next(true);
  }

  public onInfoInputClick(value: string) {
    this.isInValid = !this.validateText(value) && this.isInfoRequired;

    if (!this.isInValid) {
      this.deleteValidator(this.index);
    } else {
      this.insertValidator({ isRequired: true, button: QaCheckResult.NOT_APPLICABLE });
    }

    this.infoInput.nativeElement.focus();
  }

  private setFocusInfoText(): void {
    this.infoInput.nativeElement.focus();
  }

  private setValidation(buttonValidator: NAButtonValidator) {
    this.insertValidator(buttonValidator);
  }

  private validateText(text: string): boolean {
    text = text?.trim();
    return text?.length > 3;
  }

  private insertValidator(validator: NAButtonValidator): void {
    const validators = this.lineClearanceService.validationSubject.value;
    validator.index = this.index;

    const existingValidator = validators.find((v) => v.index === validator.index);

    if (!existingValidator) {
      validators.push(validator);
    }

    this.lineClearanceService.validationSubject.next(validators);
  }

  private deleteValidator(index: number): void {
    const validators = this.lineClearanceService.validationSubject.value;
    const validatorIndex = validators.findIndex((validator) => validator.index === index);
    if (validatorIndex !== -1) {
      validators.splice(validatorIndex, 1);
      this.lineClearanceService.validationSubject.next(validators);
    }
  }
}
