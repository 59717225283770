<section>
  <div class="p-row">
    <h1 class="page-header">{{ 'MOUNTING.TRACE_MATERIAL_TITLE' | translate }}</h1>
    <app-material-trace-table [buttonTemplate]="buttonTemplate" [tableData]="tableData"></app-material-trace-table>
  </div>
</section>

<ng-template #buttonTemplate let-rowData>
  <ng-container *ngIf="rowData.displayMode === MaterialTableDataRowDisplayMode.BillOfMaterial; else batchButton">
    <app-split-button class="overview__action" [items]="constructButtons(rowData)"></app-split-button>
  </ng-container>
  <ng-template #batchButton>
    <lib-dismount-button
      [loadingTopic]="LOADING_TOPIC + rowData.mountedMaterialId"
      (buttonClicked)="unassignTraceMaterial(rowData.mountedMaterialId)"
    ></lib-dismount-button>
  </ng-template>
</ng-template>
