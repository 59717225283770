<div class="downtime-top-background-color upper-part">
  <label class="labelText">{{ 'SPEED_MONITOR.DOWNTIME' | translate }}</label>
  <div
    class="value"
    *ngIf="valuePropertyBag.duration !== null && valuePropertyBag.duration !== undefined"
    [ngClass.xs]="{ value: false, 'value-mobile': true }"
    [ngClass.sm]="{ value: false, 'value-mobile': true }"
  >
    {{ valuePropertyBag.duration | millisecondsToDuration }}
  </div>
  <div class="kpi">
    <div *ngIf="valuePropertyBag.mode === 'FullScreen'" class="kpi-label">
      {{ 'SPEED_MONITOR.DOWNTIME_CODE' | translate }}
    </div>
    <div class="kpi-value ellipsis">
      <label *ngIf="valuePropertyBag.reasonCode">({{ valuePropertyBag.reasonCode }})</label>
      &nbsp;
      <label *ngIf="valuePropertyBag.mode === 'FullScreen'">{{ valuePropertyBag.reasonText | ellipsis }}</label>
    </div>
  </div>
</div>

<div class="bottom-background-color lower-part">
  <div class="labelText hidden">
    {{ this.targetText }}
  </div>
  <div
    class="target-value"
    [ngClass.xs]="{ 'target-value': false, 'target-value-mobile': true }"
    [ngClass.sm]="{ 'target-value': false, 'target-value-mobile': true }"
  >
    {{ valuePropertyBag.estimatedDuration ? valuePropertyBag.estimatedDuration : targetDowntime }}
  </div>
  <div *ngIf="currentKpi && currentKpi.name && valuePropertyBag.mode === 'FullScreen'" class="kpi">
    <div class="lower-kpi-label">
      {{ currentKpi.name }}
    </div>
    <div class="lower-kpi-value">
      <label *ngIf="currentKpi">{{ currentKpi.value }}{{ currentKpi.valueTime }}</label>
    </div>
  </div>
</div>
