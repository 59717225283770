import { Injectable } from '@angular/core';
import { ContainerCounts } from '@app/modules/mounting/models';
import { Query } from '@datorama/akita';
import { ArticleDescription, PrimaryMaterial, PrimaryMaterialBlock, PrimaryMaterialStatus, Quantity } from 'chronos-core-client';
import { head, nth } from 'ramda';
import { Observable } from 'rxjs';
import { PrimaryMountedMaterialStore } from './primary-mounted-material.store';

@Injectable()
export class PrimaryMountedMaterialQuery extends Query<PrimaryMaterial> {
  private readonly NEXT_MATERIAL_INDEX = 1;

  public primaryMountedMaterial$: Observable<PrimaryMaterial> = this.select();
  public mountedMaterialKpis$: Observable<PrimaryMaterialStatus> = this.select((store) => store.materialStatus);
  public containerCounts$: Observable<ContainerCounts> = this.select((store) => ({
    machineLocation: store.materialStatus?.machineLocationContainerCount,
    warehouse: store.materialStatus?.warehouseContainerCount
  }));
  public consumedVirtualContainerCount$: Observable<Quantity> = this.select(
    (store) => store.materialStatus?.consumedVirtualContainer.quantity
  );

  constructor(protected store: PrimaryMountedMaterialStore) {
    super(store);
  }

  public getActiveMaterialBlock(): Observable<PrimaryMaterialBlock> {
    return this.select((store) => head(store.materialBlocks) ?? null);
  }

  public getNextMaterialArticle(): Observable<ArticleDescription> {
    return this.select((store) => {
      const materialBlock = nth(this.NEXT_MATERIAL_INDEX, store.materialBlocks);
      return materialBlock ? materialBlock.article : null;
    });
  }

  public getPlannedStartTime(): Observable<Date> {
    return this.select((store) => {
      const materialBlock = nth(this.NEXT_MATERIAL_INDEX, store.materialBlocks);
      return materialBlock ? new Date(materialBlock.plannedStartTime) : null;
    });
  }

  public getActiveMaterial(articleId: number): Observable<ArticleDescription> {
    return this.select((store) => store.materialBlocks.find((materialBlock) => materialBlock.article.id === articleId).article);
  }
}
