/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrganizationOr } from '../models/organization-or';
import { UserUsDTO } from '../models/user-us-dto';
import { WidgetSettings } from '../models/widget-settings';
import { Tile } from '../models/tile';
import { WidgetList } from '../models/widget-list';
import { UxwidgetUw } from '../models/uxwidget-uw';
import { UwSettings } from '../models/uw-settings';
import { UxwidgetconfigWc } from '../models/uxwidgetconfig-wc';
@Injectable({
  providedIn: 'root'
})
class DashboardManagementService extends __BaseService {
  static readonly getOrganizationsPath = '/api/DashboardManagement/getOrganizations';
  static readonly getUsersPath = '/api/DashboardManagement/getUsers';
  static readonly getWidgetConfigurationPath = '/api/DashboardManagement/getWidgetConfiguration';
  static readonly getWidgetTilesPath = '/api/DashboardManagement/getWidgetTiles';
  static readonly addWidgetConfigurationPath = '/api/DashboardManagement/addWidgetConfiguration';
  static readonly updateWidgetConfigurationPath = '/api/DashboardManagement/updateWidgetConfiguration';
  static readonly updateWidgetConfigurationRangePath = '/api/DashboardManagement/update/widgetconfigurationrange';
  static readonly deleteWidgetConfigurationPath = '/api/DashboardManagement/deleteWidgetConfiguration';
  static readonly getWidgetsPath = '/api/DashboardManagement/getWidgets';
  static readonly getWidgetConfigDetailsPath = '/api/DashboardManagement/getWidgetconfigs';
  static readonly getWidgetAttributesPath = '/api/DashboardManagement/getWidgetAttributes';
  static readonly addNewWidgetPath = '/api/DashboardManagement/addNewWidget';
  static readonly updateWidgetPath = '/api/DashboardManagement/updateWidget';
  static readonly deleteWidgetPath = '/api/DashboardManagement/deleteWidget';
  static readonly getWidgetAttributeswithvaluesPath = '/api/DashboardManagement/GetWidgetAttributeswithvalues';
  static readonly getWidgetConfigurationDetailsPath = '/api/DashboardManagement/GetWidgetConfigurationDetails';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationsResponse(orid?: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/getOrganizations`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizations(orid?: number): __Observable<Array<OrganizationOr>> {
    return this.getOrganizationsResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getUsersResponse(orid?: number): __Observable<__StrictHttpResponse<Array<UserUsDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/getUsers`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserUsDTO>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getUsers(orid?: number): __Observable<Array<UserUsDTO>> {
    return this.getUsersResponse(orid).pipe(__map((_r) => _r.body as Array<UserUsDTO>));
  }

  /**
   * @param params The `DashboardManagementService.GetWidgetConfigurationParams` containing the following parameters:
   *
   * - `wcid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getWidgetConfigurationResponse(
    params: DashboardManagementService.GetWidgetConfigurationParams
  ): __Observable<__StrictHttpResponse<WidgetSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wcid != null) __params = __params.set('wcid', params.wcid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/getWidgetConfiguration`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WidgetSettings>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.GetWidgetConfigurationParams` containing the following parameters:
   *
   * - `wcid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getWidgetConfiguration(params: DashboardManagementService.GetWidgetConfigurationParams): __Observable<WidgetSettings> {
    return this.getWidgetConfigurationResponse(params).pipe(__map((_r) => _r.body as WidgetSettings));
  }

  /**
   * @param params The `DashboardManagementService.GetWidgetTilesParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getWidgetTilesResponse(params: DashboardManagementService.GetWidgetTilesParams): __Observable<__StrictHttpResponse<Array<Tile>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.usid != null) __params = __params.set('usid', params.usid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/getWidgetTiles`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Tile>>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.GetWidgetTilesParams` containing the following parameters:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getWidgetTiles(params: DashboardManagementService.GetWidgetTilesParams): __Observable<Array<Tile>> {
    return this.getWidgetTilesResponse(params).pipe(__map((_r) => _r.body as Array<Tile>));
  }

  /**
   * @param params The `DashboardManagementService.AddWidgetConfigurationParams` containing the following parameters:
   *
   * - `uwid`:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addWidgetConfigurationResponse(
    params: DashboardManagementService.AddWidgetConfigurationParams
  ): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uwid != null) __params = __params.set('uwid', params.uwid.toString());
    if (params.usid != null) __params = __params.set('usid', params.usid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/DashboardManagement/addWidgetConfiguration`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.AddWidgetConfigurationParams` containing the following parameters:
   *
   * - `uwid`:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addWidgetConfiguration(params: DashboardManagementService.AddWidgetConfigurationParams): __Observable<string> {
    return this.addWidgetConfigurationResponse(params).pipe(__map((_r) => _r.body as string));
  }

  /**
   * @param params The `DashboardManagementService.UpdateWidgetConfigurationParams` containing the following parameters:
   *
   * - `wcid`:
   *
   * - `uwid`:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateWidgetConfigurationResponse(
    params: DashboardManagementService.UpdateWidgetConfigurationParams
  ): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wcid != null) __params = __params.set('wcid', params.wcid.toString());
    if (params.uwid != null) __params = __params.set('uwid', params.uwid.toString());
    if (params.usid != null) __params = __params.set('usid', params.usid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/DashboardManagement/updateWidgetConfiguration`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.UpdateWidgetConfigurationParams` containing the following parameters:
   *
   * - `wcid`:
   *
   * - `uwid`:
   *
   * - `usid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateWidgetConfiguration(params: DashboardManagementService.UpdateWidgetConfigurationParams): __Observable<number> {
    return this.updateWidgetConfigurationResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateWidgetConfigurationRangeResponse(body?: Array<WidgetList>): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/DashboardManagement/update/widgetconfigurationrange`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateWidgetConfigurationRange(body?: Array<WidgetList>): __Observable<number> {
    return this.updateWidgetConfigurationRangeResponse(body).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `DashboardManagementService.DeleteWidgetConfigurationParams` containing the following parameters:
   *
   * - `wcid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteWidgetConfigurationResponse(
    params: DashboardManagementService.DeleteWidgetConfigurationParams
  ): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wcid != null) __params = __params.set('wcid', params.wcid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/DashboardManagement/deleteWidgetConfiguration`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.DeleteWidgetConfigurationParams` containing the following parameters:
   *
   * - `wcid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteWidgetConfiguration(params: DashboardManagementService.DeleteWidgetConfigurationParams): __Observable<number> {
    return this.deleteWidgetConfigurationResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getWidgetsResponse(orid?: number): __Observable<__StrictHttpResponse<Array<UxwidgetUw>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/getWidgets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UxwidgetUw>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getWidgets(orid?: number): __Observable<Array<UxwidgetUw>> {
    return this.getWidgetsResponse(orid).pipe(__map((_r) => _r.body as Array<UxwidgetUw>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getWidgetConfigDetailsResponse(orid?: number): __Observable<__StrictHttpResponse<Array<WidgetList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/getWidgetconfigs`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WidgetList>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getWidgetConfigDetails(orid?: number): __Observable<Array<WidgetList>> {
    return this.getWidgetConfigDetailsResponse(orid).pipe(__map((_r) => _r.body as Array<WidgetList>));
  }

  /**
   * @param params The `DashboardManagementService.GetWidgetAttributesParams` containing the following parameters:
   *
   * - `uwid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getWidgetAttributesResponse(
    params: DashboardManagementService.GetWidgetAttributesParams
  ): __Observable<__StrictHttpResponse<Array<UwSettings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uwid != null) __params = __params.set('uwid', params.uwid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/getWidgetAttributes`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UwSettings>>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.GetWidgetAttributesParams` containing the following parameters:
   *
   * - `uwid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getWidgetAttributes(params: DashboardManagementService.GetWidgetAttributesParams): __Observable<Array<UwSettings>> {
    return this.getWidgetAttributesResponse(params).pipe(__map((_r) => _r.body as Array<UwSettings>));
  }

  /**
   * @param params The `DashboardManagementService.AddNewWidgetParams` containing the following parameters:
   *
   * - `uwname`:
   *
   * - `uwicon`:
   *
   * - `ptid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addNewWidgetResponse(params: DashboardManagementService.AddNewWidgetParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uwname != null) __params = __params.set('uwname', params.uwname.toString());
    if (params.uwicon != null) __params = __params.set('uwicon', params.uwicon.toString());
    if (params.ptid != null) __params = __params.set('ptid', params.ptid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/DashboardManagement/addNewWidget`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.AddNewWidgetParams` containing the following parameters:
   *
   * - `uwname`:
   *
   * - `uwicon`:
   *
   * - `ptid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addNewWidget(params: DashboardManagementService.AddNewWidgetParams): __Observable<number> {
    return this.addNewWidgetResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `DashboardManagementService.UpdateWidgetParams` containing the following parameters:
   *
   * - `uwname`:
   *
   * - `uwid`:
   *
   * - `uwicon`:
   *
   * - `ptid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateWidgetResponse(params: DashboardManagementService.UpdateWidgetParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uwname != null) __params = __params.set('uwname', params.uwname.toString());
    if (params.uwid != null) __params = __params.set('uwid', params.uwid.toString());
    if (params.uwicon != null) __params = __params.set('uwicon', params.uwicon.toString());
    if (params.ptid != null) __params = __params.set('ptid', params.ptid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/DashboardManagement/updateWidget`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.UpdateWidgetParams` containing the following parameters:
   *
   * - `uwname`:
   *
   * - `uwid`:
   *
   * - `uwicon`:
   *
   * - `ptid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateWidget(params: DashboardManagementService.UpdateWidgetParams): __Observable<number> {
    return this.updateWidgetResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `DashboardManagementService.DeleteWidgetParams` containing the following parameters:
   *
   * - `uwid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteWidgetResponse(params: DashboardManagementService.DeleteWidgetParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uwid != null) __params = __params.set('uwid', params.uwid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/DashboardManagement/deleteWidget`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.DeleteWidgetParams` containing the following parameters:
   *
   * - `uwid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteWidget(params: DashboardManagementService.DeleteWidgetParams): __Observable<number> {
    return this.deleteWidgetResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `DashboardManagementService.GetWidgetAttributeswithvaluesParams` containing the following parameters:
   *
   * - `wcid`:
   *
   * - `uwid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getWidgetAttributeswithvaluesResponse(
    params: DashboardManagementService.GetWidgetAttributeswithvaluesParams
  ): __Observable<__StrictHttpResponse<Array<UwSettings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.wcid != null) __params = __params.set('wcid', params.wcid.toString());
    if (params.uwid != null) __params = __params.set('uwid', params.uwid.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/GetWidgetAttributeswithvalues`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UwSettings>>;
      })
    );
  }
  /**
   * @param params The `DashboardManagementService.GetWidgetAttributeswithvaluesParams` containing the following parameters:
   *
   * - `wcid`:
   *
   * - `uwid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getWidgetAttributeswithvalues(params: DashboardManagementService.GetWidgetAttributeswithvaluesParams): __Observable<Array<UwSettings>> {
    return this.getWidgetAttributeswithvaluesResponse(params).pipe(__map((_r) => _r.body as Array<UwSettings>));
  }

  /**
   * @param wcid undefined
   * @return Success
   */
  getWidgetConfigurationDetailsResponse(wcid?: number): __Observable<__StrictHttpResponse<Array<UxwidgetconfigWc>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (wcid != null) __params = __params.set('wcid', wcid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DashboardManagement/GetWidgetConfigurationDetails`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UxwidgetconfigWc>>;
      })
    );
  }
  /**
   * @param wcid undefined
   * @return Success
   */
  getWidgetConfigurationDetails(wcid?: number): __Observable<Array<UxwidgetconfigWc>> {
    return this.getWidgetConfigurationDetailsResponse(wcid).pipe(__map((_r) => _r.body as Array<UxwidgetconfigWc>));
  }
}

module DashboardManagementService {
  /**
   * Parameters for GetWidgetConfiguration
   */
  export interface GetWidgetConfigurationParams {
    wcid?: number;
    orid?: number;
  }

  /**
   * Parameters for GetWidgetTiles
   */
  export interface GetWidgetTilesParams {
    usid?: number;
    orid?: number;
  }

  /**
   * Parameters for AddWidgetConfiguration
   */
  export interface AddWidgetConfigurationParams {
    uwid?: number;
    usid?: number;
    orid?: number;
    body?: WidgetSettings;
  }

  /**
   * Parameters for UpdateWidgetConfiguration
   */
  export interface UpdateWidgetConfigurationParams {
    wcid?: number;
    uwid?: number;
    usid?: number;
    orid?: number;
    body?: WidgetSettings;
  }

  /**
   * Parameters for DeleteWidgetConfiguration
   */
  export interface DeleteWidgetConfigurationParams {
    wcid?: number;
    orid?: number;
  }

  /**
   * Parameters for GetWidgetAttributes
   */
  export interface GetWidgetAttributesParams {
    uwid?: number;
    orid?: number;
  }

  /**
   * Parameters for AddNewWidget
   */
  export interface AddNewWidgetParams {
    uwname?: string;
    uwicon?: string;
    ptid?: string;
    orid?: number;
    body?: Array<UwSettings>;
  }

  /**
   * Parameters for UpdateWidget
   */
  export interface UpdateWidgetParams {
    uwname?: string;
    uwid?: number;
    uwicon?: string;
    ptid?: string;
    orid?: number;
    body?: Array<UwSettings>;
  }

  /**
   * Parameters for DeleteWidget
   */
  export interface DeleteWidgetParams {
    uwid?: number;
    orid?: number;
  }

  /**
   * Parameters for GetWidgetAttributeswithvalues
   */
  export interface GetWidgetAttributeswithvaluesParams {
    wcid?: number;
    uwid?: number;
    orid?: number;
  }
}

export { DashboardManagementService };
