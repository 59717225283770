<div class="setting-list-container">
  <div class="setting-category">{{ categoryName }}</div>
  <div class="setting-list">
    <ng-container *ngFor="let settingItem of settingData.settingValues" class="row">
      <app-resource-setting
        [defaultSettingList]="defaultSettingList"
        [setting]="settingItem"
        [isAdmin]="isAdmin"
        [parentForm]="parentForm"
        [enableEditSetting$]="enableEditSetting$"
        (viewSettingHistory)="viewSettingHistory($event)"
        (updateSetting)="updateSetting($event)"
        (formValidityChange)="emitFormValidityChange($event)"
      ></app-resource-setting>
    </ng-container>
  </div>
</div>
