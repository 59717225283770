import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { OrderByItemsDirective } from '@app/modules/mounting/directives/order-by-items.directive';
import { ArticleDescription } from 'chronos-core-client';
import { MountMaterialStatus } from 'chronos-shared';

@Component({
  selector: 'app-mount-pallet-list-template',
  templateUrl: './mount-pallet-list-template.component.html',
  styleUrls: ['./mount-pallet-list-template.component.scss']
})
export class MountPalletListTemplateComponent {
  @Input() public externalWorkCenterId: string;
  @Input() public activeMaterial: ArticleDescription;
  @Input() public canCreateDummyPallets = true;
  @Input() public nextMaterial: ArticleDescription;
  @Input() public plannedStartTime: Date;

  @Output() public newDummyPalletClicked = new EventEmitter<number>();

  @ContentChild(OrderByItemsDirective, { read: TemplateRef }) public orderByTemplate: TemplateRef<any>;

  public readonly MOUNT_MATERIAL_STATUS = MountMaterialStatus;

  public onDummyPalletButtonClick(articleId: number): void {
    this.newDummyPalletClicked.emit(articleId);
  }
}
