import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { MachineCounterStore } from './machine-counter.store';
import { CounterInfo } from 'chronos-core-client';

@Injectable({ providedIn: 'root' })
export class MachineCounterQuery extends Query<CounterInfo> {
  constructor(protected store: MachineCounterStore) {
    super(store);
  }

  public getMachineCounter(): Observable<CounterInfo> {
    return this.select();
  }
}
