/* tslint:disable */
type ApprovalReportProductionOrderPhaseType = 'Setup' | 'Run' | 'Downtime' | 'Consumption';
module ApprovalReportProductionOrderPhaseType {
  export const SETUP: ApprovalReportProductionOrderPhaseType = 'Setup';
  export const RUN: ApprovalReportProductionOrderPhaseType = 'Run';
  export const DOWNTIME: ApprovalReportProductionOrderPhaseType = 'Downtime';
  export const CONSUMPTION: ApprovalReportProductionOrderPhaseType = 'Consumption';
  export function values(): ApprovalReportProductionOrderPhaseType[] {
    return [SETUP, RUN, DOWNTIME, CONSUMPTION];
  }
}

export { ApprovalReportProductionOrderPhaseType };
