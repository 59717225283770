import { SetupFooterCommandBase } from '@app/shared/components';
import { SetupPhaseService } from '../services/setup-phase/setup-phase.service';
import { finalize } from 'rxjs/operators';
import { LoadingNotificationService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';

export class CancelSetupCommandService implements SetupFooterCommandBase {
  public enabled = true;
  public buttonText: string;
  public required = false;

  public loadingTopic = notificationTopic.footerCancelSetupPhase;

  constructor(private setupPhaseService: SetupPhaseService) {}

  public execute(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.setupPhaseService
      .cancelSetupPhase()
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe();
  }
}
