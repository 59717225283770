<footer class="modal-footer" [libLoadingTopic]="whenLoadingTopic" (loadingEvent)="onLoading($event)" [loadingIndicator]="false">
  <div *ngIf="checkBoxCommand" class="checkbox-container">
    <span [ngClass]="checkBox === true ? 'confirm-checkbox--regular' : 'confirm-checkbox--attention'">
      <lib-input-check
        class="confirm-checkbox__item"
        [label]="checkBoxLabel"
        [(ngModel)]="checkBox"
        (ngModelChange)="checkBoxClicked()"
      ></lib-input-check>
    </span>
  </div>

  <div class="modal-footer__wrapper" *ngIf="!isFooterButtonSwapped; else swapButtons">
    <lib-button
      *ngIf="showDeclineButton"
      [typeClass]="'button--secondary'"
      [sizeClass]="'button--large'"
      [loadingTopic]="whenLoadingTopic"
      [label]="declineLabel"
      (clicked)="onDecline()"
      [buttonId]="'decline_modal'"
    ></lib-button>
    <div class="modal-footer__empty-space"></div>
    <div *ngIf="isResetVisible" class="modal-footer__reset-button">
      <lib-button
        [typeClass]="'button--secondary'"
        [sizeClass]="'button--large'"
        [loadingTopic]="resetButtonLoadingTopic"
        [label]="resetLabel"
        [isAutoFocus]="!isAutoFocusOnSubmitButton"
        (clicked)="onReset()"
        [buttonId]="'reset_modal'"
      ></lib-button>
    </div>
    <lib-button
      [typeClass]="'button--large'"
      [loadingTopic]="whenLoadingTopic"
      [label]="submitLabel"
      [isAutoFocus]="isAutoFocusOnSubmitButton"
      (clicked)="onSubmit()"
      [buttonId]="submitId"
      [disabled]="checkBoxCommand ? submitDisabled || !checkBox : submitDisabled"
    ></lib-button>
  </div>

  <ng-template #swapButtons>
    <div class="modal-footer__wrapper">
      <lib-button
        [typeClass]="'button--secondary'"
        [sizeClass]="'button--large'"
        [loadingTopic]="whenLoadingTopic"
        [label]="submitLabel"
        (clicked)="onSubmit()"
        [buttonId]="submitId"
        [disabled]="checkBoxCommand ? submitDisabled || !checkBox : submitDisabled"
      ></lib-button>
      <div class="modal-footer__empty-space"></div>
      <lib-button
        *ngIf="showDeclineButton"
        [typeClass]="'button--large'"
        [loadingTopic]="whenLoadingTopic"
        [label]="declineLabel"
        [isAutoFocus]="isAutoFocusOnSubmitButton"
        (clicked)="onDecline()"
        [buttonId]="'decline_modal'"
      ></lib-button>
    </div>
  </ng-template>
</footer>
