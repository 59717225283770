<section class="chronos-dialog--size-md">
  <label class="quantity">
    <span class="quantity__label">{{ firstProductionOrder }}</span>
    <lib-input-number
      [ngModel]="goodQuantityFirstProductionOrder"
      [size]="5"
      [format]="true"
      [step]="1"
      [min]="0"
      [max]="maxQuantity"
      (ngModelChange)="onFirstProductionOrderChange($event)"
    ></lib-input-number>
  </label>
  <label class="quanity">
    <span class="quantity__label">{{ secondProductionOrder }}</span>
    <lib-input-number
      [ngModel]="goodQuantitySecondProductionOrder"
      [step]="1"
      [size]="5"
      [format]="true"
      [min]="0"
      [max]="maxQuantity"
      (ngModelChange)="onSecondProductionOrderChange($event)"
    ></lib-input-number>
  </label>
  <lib-modal-footer [submitLabel]="'MODAL_FOOTER.YES'" (submitClicked)="acceptModal()" (declineClicked)="rejectModal()"></lib-modal-footer>
</section>
