<div class="modal-production-periods page-padding chronos-dialog--size-lg">
  <section class="production-periods-data-table">
    <p-table [value]="openPeriods" selectionMode="single" [(selection)]="selectedPeriod">
      <ng-template pTemplate="colgroup">
        <colgroup>
          <col class="work-center-col" />
          <col class="other-col" />
          <col class="other-col" />
          <col class="other-col" />
          <col class="other-col" />
        </colgroup>
      </ng-template>

      <ng-template *ngFor="let col of productionPeriodsTableColumns" pTemplate="header">
        <tr>
          <th rowspan="3">{{ 'OPEN_PERIODS_MODAL.WORK_CENTER_STATUS' | translate }}</th>
        </tr>
        <tr>
          <th colspan="2" *ngFor="let col of productionPeriodsTableColumns">{{ col.header | translate }}</th>
        </tr>
        <tr>
          <th *ngFor="let col of productionPeriodsTableSubColumns">{{ col.header | translate }}</th>
          <th *ngFor="let col of productionPeriodsTableSubColumns">{{ col.header | translate }}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-period>
        <tr>
          <td [attr.colspan]="productionPeriodsTableColumns.length">
            <ng-container *ngIf="loadingData; else noPeriods">
              <i class="pi pi-spinner pi-spin"></i>
              <span class="loading-text">{{ 'OPEN_PERIODS_MODAL.LOADING_PERIODS' | translate }}</span>
            </ng-container>
            <ng-template #noPeriods>
              <div>{{ 'OPEN_PERIODS_MODAL.NO_PERIODS_AVAILABLE' | translate }}</div>
              <p-checkbox
                binary="true"
                [(ngModel)]="processWithoutPeriod"
                [label]="'OPEN_PERIODS_MODAL.PROCESS_WITHOUT_PERIOD' | translate"
              ></p-checkbox>
            </ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-period>
        <tr [pSelectableRow]="period">
          <td>{{ period.workCenterStatus }}</td>
          <td>
            {{ period.periodStart | utcToLocal | date: 'EEEE, d MMMM y, HH:mm':'locale':this.defaultLanguage }}
          </td>
          <td>{{ period.shiftTypeNameStart }}</td>
          <td>
            {{ period.periodEnd ? (period.periodEnd | utcToLocal | date: 'EEEE, d MMMM y, HH:mm':'locale':this.defaultLanguage) : '' }}
          </td>
          <td>
            <span *ngIf="period.periodEnd">{{ period.shiftTypeNameEnd }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </section>
</div>
<div class="manualtransport-checkbox" *ngIf="allowTransportProcessAfterGluingManualOverride">
  <lib-input-switch
    [loadingTopic]="loadingTopic"
    [(ngModel)]="manualTransport"
    [label]="'OPEN_PERIODS_MODAL.MANUAL_TRANSPORT' | translate"
  ></lib-input-switch>
</div>
<lib-modal-footer
  [submitDisabled]="isSubmitDisabled()"
  [submitLabel]="'OPEN_PERIODS_MODAL.SUBMIT_BUTTON'"
  (submitClicked)="submitModal()"
  (declineClicked)="onCloseModal()"
  [whenLoadingTopic]="LOADING_TOPIC"
></lib-modal-footer>
