import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { TaskCategoryState, TaskCategoryStore } from './task-category.store';
import { WorkCenterTasksByCategory } from 'chronos-core-client';

@Injectable({ providedIn: 'root' })
export class TaskCategoryQuery extends QueryEntity<TaskCategoryState, WorkCenterTasksByCategory> {
  public taskCategories$: Observable<WorkCenterTasksByCategory[]> = this.selectAll();
  public taskCounter$: Observable<number> = this.selectAll().pipe(
    map((taskCategory) => taskCategory.reduce((acc, current) => acc + current.count, 0)),
    share()
  );

  constructor(protected store: TaskCategoryStore) {
    super(store);
  }
}
