/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WasteAssignment } from '../models/waste-assignment';
@Injectable({
  providedIn: 'root'
})
class WasteAssignmentService extends __BaseService {
  static readonly getWasteAssignmentPath = '/api/v1/WasteAssignment/{productionOrderId}/{wasteAssignmentType}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `WasteAssignmentService.GetWasteAssignmentParams` containing the following parameters:
   *
   * - `wasteAssignmentType`:
   *
   * - `productionOrderId`:
   */
  getWasteAssignmentResponse(
    params: WasteAssignmentService.GetWasteAssignmentParams
  ): __Observable<__StrictHttpResponse<Array<WasteAssignment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/WasteAssignment/${encodeURIComponent(String(params.productionOrderId))}/${encodeURIComponent(
          String(params.wasteAssignmentType)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WasteAssignment>>;
      })
    );
  }
  /**
   * @param params The `WasteAssignmentService.GetWasteAssignmentParams` containing the following parameters:
   *
   * - `wasteAssignmentType`:
   *
   * - `productionOrderId`:
   */
  getWasteAssignment(params: WasteAssignmentService.GetWasteAssignmentParams): __Observable<Array<WasteAssignment>> {
    return this.getWasteAssignmentResponse(params).pipe(__map((_r) => _r.body as Array<WasteAssignment>));
  }
}

module WasteAssignmentService {
  /**
   * Parameters for GetWasteAssignment
   */
  export interface GetWasteAssignmentParams {
    wasteAssignmentType: 'SetupWaste' | 'ProductionOrderWaste' | 'ContainerWaste';
    productionOrderId: number;
  }
}

export { WasteAssignmentService };
