import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { InfoLogComponent } from './info-log/info-log.component';
import { MultiLanguageComponent } from './multi-language/multi-language.component';
import { TitleComponent } from './title/title.component';
import { UserComponent } from './user/user.component';
import { VersionNumberComponent } from './version-number/version-number.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { EnvironmentComponent } from './environment/environment.component';

@NgModule({
  declarations: [
    HeaderLayoutComponent,
    InfoLogComponent,
    MultiLanguageComponent,
    TitleComponent,
    UserComponent,
    VersionNumberComponent,
    EnvironmentComponent
  ],
  exports: [
    HeaderLayoutComponent,
    InfoLogComponent,
    MultiLanguageComponent,
    TitleComponent,
    UserComponent,
    VersionNumberComponent,
    EnvironmentComponent
  ],
  imports: [CommonModule, MenuModule, ButtonModule, TranslateModule.forChild()]
})
export class ApplicationHeaderModule {}
