/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SetToolCheckoutResultEntry } from '../models/set-tool-checkout-result-entry';
import { ToolCheckoutInfo } from '../models/tool-checkout-info';
@Injectable({
  providedIn: 'root'
})
class ToolService extends __BaseService {
  static readonly setToolCheckoutResultPath = '/api/v1/Tool/{productionOrderId}/SetToolCheckoutResult';
  static readonly getToolCheckoutInfoPath = '/api/v1/Tool/{productionOrderId}/GetToolCheckoutInfo';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `ToolService.SetToolCheckoutResultParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setToolCheckoutResultResponse(params: ToolService.SetToolCheckoutResultParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Tool/${encodeURIComponent(String(params.productionOrderId))}/SetToolCheckoutResult`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ToolService.SetToolCheckoutResultParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setToolCheckoutResult(params: ToolService.SetToolCheckoutResultParams): __Observable<null> {
    return this.setToolCheckoutResultResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param productionOrderId undefined
   */
  getToolCheckoutInfoResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<ToolCheckoutInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Tool/${encodeURIComponent(String(productionOrderId))}/GetToolCheckoutInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ToolCheckoutInfo>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getToolCheckoutInfo(productionOrderId: number): __Observable<Array<ToolCheckoutInfo>> {
    return this.getToolCheckoutInfoResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<ToolCheckoutInfo>));
  }
}

module ToolService {
  /**
   * Parameters for SetToolCheckoutResult
   */
  export interface SetToolCheckoutResultParams {
    productionOrderId: number;
    entry: SetToolCheckoutResultEntry;
  }
}

export { ToolService };
