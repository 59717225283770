<p-checkbox
  [binary]="true"
  [label]="label | translate"
  [(ngModel)]="checked"
  (onChange)="onCheckChange()"
  [disabled]="disabled || loading"
  [libLoadingTopic]="loadingTopic"
  (loadingEvent)="loading = $event"
  [loadingIndicator]="false"
></p-checkbox>
