import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ProducedMaterial } from 'chronos-core-client';

export interface OutputPalletsState extends EntityState<ProducedMaterial>, ActiveState {
  filter: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'output-pallets', idKey: 'producedMaterialId', resettable: true })
export class OutputPalletsStore extends EntityStore<OutputPalletsState, ProducedMaterial> {
  constructor() {
    super();
  }
}
