import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { MachineScheduleDsService, ProductionOrderDsService } from '@app/core/data-services';
import {
  DefaultTargetContainerQuantityInfo,
  MachineSchedule,
  ManualMachineMode,
  ShiftFilter,
  WorkCenterProductionMode
} from 'chronos-core-client';
import { ShiftFilterType } from '@app/shared/models';
import { map, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsQuery } from 'chronos-shared';

@Injectable()
export class OrderListService {
  constructor(
    private productionOrderDsService: ProductionOrderDsService,
    private machineScheduleDsService: MachineScheduleDsService,
    private appSettingsQuery: AppSettingsQuery,
    private translateService: TranslateService
  ) {}

  public showInactiveElement(): boolean {
    return this.appSettingsQuery.showInactiveElement();
  }

  public getProductionOrderListByShift(showFinishedOrders: boolean, shiftFilterType?: ShiftFilterType): Observable<MachineSchedule[]> {
    return this.productionOrderDsService.getProductionOrderListByShift(showFinishedOrders, shiftFilterType);
  }

  public getShiftFilters(): Observable<ShiftFilter[]> {
    return combineLatest([
      this.machineScheduleDsService.getShiftFilters(),
      this.translateService.onLangChange.pipe(startWith(this.translateService.getDefaultLang()))
    ]).pipe(
      map(([filters]) =>
        filters.map((filter) => {
          const translation = this.translateService.instant(`ORDER_LIST.${filter.label}`);
          return { value: filter.value, label: translation };
        })
      )
    );
  }

  public getWorkCenterProductionMode(): Observable<WorkCenterProductionMode> {
    return this.appSettingsQuery.workCenterProductionMode$;
  }

  public getManualMachineMode(): Observable<ManualMachineMode> {
    return this.appSettingsQuery.manualMachineMode$;
  }

  public automaticPeriodSelectionOnProductionOrderStart(): Observable<boolean> {
    return this.appSettingsQuery.automaticPeriodSelectionOnProductionOrderStart$;
  }

  public getDefaultTargetContainerQuantityInfo(productionOrderId: number): Observable<DefaultTargetContainerQuantityInfo> {
    return this.productionOrderDsService.getDefaultTargetContainerQuantityInfo(productionOrderId);
  }
}
