import { NgModule } from '@angular/core';
import { DummyToolModalComponent, ToolStockModalComponent } from '@app/modules/tools/components';
import { ToolsService } from '@app/modules/tools/services';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [DummyToolModalComponent, ToolStockModalComponent],
  imports: [SharedModule],
  providers: [ToolsService],
  exports: [DummyToolModalComponent]
})
export class ToolsModule {}
