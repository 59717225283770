import { Injectable } from '@angular/core';
import { WebSocketClientService } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TaskService } from '@app/core/global-state';
import { HubUrlsService } from '@app/core/utils';

@Injectable()
export class TaskListService {
  constructor(
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private taskService: TaskService
  ) {}

  public getTaskListNotifications(): Observable<any> {
    return this.webSocketClientService.getNotificationsForTopic(this.hubUrlsService.getTaskList()).pipe(
      tap((data) => {
        this.taskService.updateTasks(data);
      })
    );
  }
}
