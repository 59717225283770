<div *ngIf="RESULT_VALUES" class="result-select-button">
  <div *ngFor="let resultValue of RESULT_VALUES">
    <label>
      <input
        type="radio"
        [name]="name"
        [disabled]="isDisabled"
        [checked]="resultValue.value === value"
        (click)="setValue(resultValue.value)"
        [value]="resultValue.value"
      />
      <div [ngClass]="getResultSelectButtonStyle(resultValue.value)" class="result-select-button__item">
        {{ resultValue.label | uppercase }}
      </div>
    </label>
  </div>
</div>
