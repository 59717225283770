/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateAndAssignTraceContainerEntry } from '../models/create-and-assign-trace-container-entry';
import { AssignTraceContainerEntry } from '../models/assign-trace-container-entry';
import { WorkCenterIdEntry } from '../models/work-center-id-entry';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { TraceMaterial } from '../models/trace-material';
@Injectable({
  providedIn: 'root'
})
class TraceMaterialService extends __BaseService {
  static readonly createAndAssignPath = '/api/v1/TraceMaterial/{workCenterId}/CreateAndAssign';
  static readonly assignPath = '/api/v1/TraceMaterial/{workCenterId}/Assign';
  static readonly unassignPath = '/api/v1/TraceMaterial/{mountedMaterialId}/Unassign';
  static readonly getMaterialAvailableForArticle2Path = '/api/v1/TraceMaterial/{workCenterId}/materialAvailable/articles/{articleId}';
  static readonly getTraceMaterialAssignedPath = '/api/v1/TraceMaterial/{workCenterId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `TraceMaterialService.CreateAndAssignParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  createAndAssignResponse(params: TraceMaterialService.CreateAndAssignParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/TraceMaterial/${encodeURIComponent(String(params.workCenterId))}/CreateAndAssign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TraceMaterialService.CreateAndAssignParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  createAndAssign(params: TraceMaterialService.CreateAndAssignParams): __Observable<null> {
    return this.createAndAssignResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `TraceMaterialService.AssignParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  assignResponse(params: TraceMaterialService.AssignParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/TraceMaterial/${encodeURIComponent(String(params.workCenterId))}/Assign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TraceMaterialService.AssignParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  assign(params: TraceMaterialService.AssignParams): __Observable<null> {
    return this.assignResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `TraceMaterialService.UnassignParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  unassignResponse(params: TraceMaterialService.UnassignParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/TraceMaterial/${encodeURIComponent(String(params.mountedMaterialId))}/Unassign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TraceMaterialService.UnassignParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  unassign(params: TraceMaterialService.UnassignParams): __Observable<null> {
    return this.unassignResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `TraceMaterialService.GetMaterialAvailableForArticle2Params` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `articleId`:
   */
  getMaterialAvailableForArticle2Response(
    params: TraceMaterialService.GetMaterialAvailableForArticle2Params
  ): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/TraceMaterial/${encodeURIComponent(String(params.workCenterId))}/materialAvailable/articles/${encodeURIComponent(
          String(params.articleId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param params The `TraceMaterialService.GetMaterialAvailableForArticle2Params` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `articleId`:
   */
  getMaterialAvailableForArticle2(
    params: TraceMaterialService.GetMaterialAvailableForArticle2Params
  ): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForArticle2Response(params).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param workCenterId undefined
   */
  getTraceMaterialAssignedResponse(workCenterId: number): __Observable<__StrictHttpResponse<TraceMaterial>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/TraceMaterial/${encodeURIComponent(String(workCenterId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TraceMaterial>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getTraceMaterialAssigned(workCenterId: number): __Observable<TraceMaterial> {
    return this.getTraceMaterialAssignedResponse(workCenterId).pipe(__map((_r) => _r.body as TraceMaterial));
  }
}

module TraceMaterialService {
  /**
   * Parameters for CreateAndAssign
   */
  export interface CreateAndAssignParams {
    workCenterId: number;
    entry: CreateAndAssignTraceContainerEntry;
  }

  /**
   * Parameters for Assign
   */
  export interface AssignParams {
    workCenterId: number;
    entry: AssignTraceContainerEntry;
  }

  /**
   * Parameters for Unassign
   */
  export interface UnassignParams {
    mountedMaterialId: number;
    entry: WorkCenterIdEntry;
  }

  /**
   * Parameters for GetMaterialAvailableForArticle2
   */
  export interface GetMaterialAvailableForArticle2Params {
    workCenterId: number;
    articleId: number;
  }
}

export { TraceMaterialService };
