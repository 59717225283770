<div class="output-container-list">
  <app-output-quantity-pool
    [manualMachineMode]="manualMachineMode"
    [manualCreation]="isManualModeForContainer$ | async"
    [grossQuantity]="grossQuantity"
    (createPalletClicked)="createPallet()"
    (changePalletQuantityClicked)="changeContainerQuantity()"
    (cancelProductionOrderClicked)="cancelProductionOrder()"
    (openPeriodicQaCheckClicked)="openPeriodicQACheckDialog()"
    (openChangeBobbinQuantityClicked)="openChangeBobbinQuantityDialog()"
    [isCancelProductionOrder]="isCancelProductionOrder"
    [isManualCheckAvailable]="isManualCheckAvailable"
    [slitCount]="slitCount"
  ></app-output-quantity-pool>

  <ng-container *ngFor="let activePallet of visiblePallets$ | async; trackBy: trackByFunction">
    <app-pallet
      [manualMode]="manualMode"
      [outputPallet]="activePallet"
      [reasonOptions]="reasonOptions"
      [grossQuantity]="grossQuantity"
      (finishedCheck)="handleFinishedCheckChange($event)"
      (finishClicked)="onFinishClicked($event)"
      (changeContainerQuantityClicked)="changePalletQuantity($event)"
      (changePalletBobbinQuantityClicked)="openChangeBobbinQuantityDialog($event)"
      [isExpanded]="getIsExpandedWithDefaultValue()"
      (lastClicked)="onLastClick($event)"
      [slitCount]="slitCount"
    ></app-pallet>
  </ng-container>

  <app-container-collapse [marginLeft]="45" [collapseGroup]="otherPalletGroups$ | async" [isExpanded]="arePalletsExpanded">
    <ng-container *ngFor="let otherPallet of otherPallets$ | async; trackBy: trackByFunction">
      <app-pallet
        [manualMode]="manualMode"
        [outputPallet]="otherPallet"
        [reasonOptions]="reasonOptions"
        [grossQuantity]="grossQuantity"
        (finishedCheck)="handleFinishedCheckChange($event)"
        (finishClicked)="onFinishClicked($event)"
        (changeContainerQuantityClicked)="changePalletQuantity($event)"
        (changePalletBobbinQuantityClicked)="openChangeBobbinQuantityDialog($event)"
        [isExpanded]="arePalletsExpanded"
        (lastClicked)="onLastClick($event)"
        [slitCount]="slitCount"
      ></app-pallet>
    </ng-container>
  </app-container-collapse>
</div>
