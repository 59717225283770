import { Component, Input } from '@angular/core';
import { PatternInfo } from 'projects/chronos-core-client/src/public-api';

@Component({
  selector: 'app-pattern-trace-table',
  templateUrl: './pattern-trace-table.component.html',
  styleUrls: ['./pattern-trace-table.component.scss']
})

export class PatternTraceTableComponent {
  public tracePatternData: PatternInfo[];

  @Input()
  public set tableData(tableData: PatternInfo[]) {
    this.tracePatternData = tableData;
  }

  constructor() {}
}
