import { Component, Input } from '@angular/core';
import { ContainerCollapseGroup } from './container-collapse-group';

@Component({
  selector: 'app-container-collapse',
  templateUrl: './container-collapse.component.html',
  styleUrls: ['./container-collapse.component.scss']
})
export class ContainerCollapseComponent {
  @Input() public marginLeft: number;
  @Input() public marginRight: number;
  @Input() public collapseGroup: ContainerCollapseGroup;
  @Input() public isExpanded = false;

  public toggleContent(): void {
    this.isExpanded = !this.isExpanded;
  }
}
