import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QaCheckService } from '@app/core/services';
import { QaCheckValueChange } from '@app/shared/models';
import { ProductionQaCheck, QaCheckResult, SetQaCheckResultEntry, CreateManualQaCheckEntry } from 'chronos-core-client';
import { sort } from 'ramda';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingNotificationService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import moment from 'moment-mini';

@Component({
  selector: 'app-qa-check-list',
  templateUrl: './qa-check-list.component.html',
  styleUrls: ['./qa-check-list.component.scss']
})
export class QaCheckListComponent implements OnInit, OnDestroy {
  @Input() public fields: ProductionQaCheck[];
  @Input() public form: UntypedFormGroup;
  @Input() public materialId?: number;
  @Input() public scrollHeight = '400px';
  @Input() public isManualPeriodicQaCheck? = false;
  @Input() public isAutomaticPeriodicQaCheck? = false;
  @Input() public showAllQACheckButton? = true;
  @Input() public isReadOnly? = false;
  @Input() public periodicQaCheckId?: number;
  public allOk: boolean;
  public info: string;

  private previousForm: QaCheckValueChange[];
  private subscriptions = new Subscription();
  private productionQaChecks: CreateManualQaCheckEntry[] = [];
  constructor(public qaCheckService: QaCheckService) {}

  private static sortCheckResult(checkResult: ProductionQaCheck[]): ProductionQaCheck[] {
    const diff = (a: ProductionQaCheck, b: ProductionQaCheck) => a.checkSequenceNumber - b.checkSequenceNumber;
    return sort(diff, checkResult);
  }

  public ngOnInit(): void {
    this.updateAllOkCheck();
    this.subscriptions.add(
      this.qaCheckService.controlsEmittersInit(this.form).subscribe((qaCheckResult) => {
        this.setCheckResult(qaCheckResult);
      })
    );
    this.fields = QaCheckListComponent.sortCheckResult(this.fields);
    if (this.isManualPeriodicQaCheck) {
      this.setAllFieldsForManualQaChaeck();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public setFormFieldsToOk(): void {
    const qaCheckEntry = this.qaCheckService.getQaCheckOkEntry(this.allOk);
    if (this.isManualPeriodicQaCheck) {
      this.qaCheckService.setFormFieldsToOk(qaCheckEntry?.checkResult, this.form);
      this.qaCheckService.setManualQACheckeCollection(this.form);
    } else {
      this.palletSetFormFieldsToOk(qaCheckEntry);
    }
  }

  private palletSetFormFieldsToOk(qaCheckEntry: SetQaCheckResultEntry): void {
    if (this.materialId) {
      this.subscriptions.add(
        this.qaCheckService.setAllQaChecksForProducedMaterial(this.materialId, qaCheckEntry).subscribe(
          () => {
            this.setAllFormFields(qaCheckEntry?.checkResult);
          },
          () => (this.allOk = !this.allOk)
        )
      );
    } else if (this.isAutomaticPeriodicQaCheck) {
      LoadingNotificationService.publish(notificationTopic.footerNextSetupPhase, true);
      this.subscriptions.add(
        this.qaCheckService
          .setAllQaChecksForPeriodic(this.periodicQaCheckId, qaCheckEntry)
          .pipe(
            finalize(() => {
              LoadingNotificationService.publish(notificationTopic.footerNextSetupPhase, false);
            })
          )
          .subscribe(
            () => {
              this.setAllFormFields(qaCheckEntry?.checkResult);
            },
            () => (this.allOk = !this.allOk)
          )
      );
    } else {
      LoadingNotificationService.publish(notificationTopic.footerNextSetupPhase, true);
      this.subscriptions.add(
        this.qaCheckService
          .setAllQaChecksForSetup(qaCheckEntry)
          .pipe(
            finalize(() => {
              LoadingNotificationService.publish(notificationTopic.footerNextSetupPhase, false);
            })
          )
          .subscribe(
            () => {
              this.setAllFormFields(qaCheckEntry?.checkResult);
            },
            () => (this.allOk = !this.allOk)
          )
      );
    }
  }

  private setCheckResult(qaCheckResult: QaCheckValueChange) {
    const qaCheckControl = this.qaCheckService.getCheckControl(qaCheckResult.id, this.form, this.fields);
    const fieldIndex = this.fields.findIndex((x) => x.id === qaCheckResult.id);

    if (qaCheckResult.measurementResult !== this.fields[fieldIndex].measurementResult) {
      const field = this.fields[fieldIndex];
      const measurementResult = qaCheckResult.measurementResult;
      const max = field.measurementResultMax;
      const min = field.measurementResultMin;
      this.fields[fieldIndex].measurementResult = measurementResult;
      if (max > 0 && min >= 0) {
        if (measurementResult > max || measurementResult < min) {
          qaCheckResult.check = QaCheckResult.NOT_OK;
        } else if (max >= measurementResult && measurementResult >= min) {
          qaCheckResult.check = QaCheckResult.OK;
        }
        this.form.controls[field.qaCheckId].get('check').patchValue(qaCheckResult.check, { emitEvent: false });
      }
    }

    if (this.isManualPeriodicQaCheck) {
      const createManualQaCheckEntry = this.productionQaChecks.findIndex((obj) => obj.productionQaCheckId === qaCheckResult.id);

      if (createManualQaCheckEntry !== -1) {
        this.productionQaChecks[createManualQaCheckEntry].checkResult = qaCheckResult.check;
        this.productionQaChecks[createManualQaCheckEntry].comment = qaCheckResult.comment;
        this.productionQaChecks[createManualQaCheckEntry].measurementResult = qaCheckResult.measurementResult;
      } else {
        this.productionQaChecks.push({
          productionQaCheckId: qaCheckResult.id,
          checkResult: qaCheckResult.check,
          comment: qaCheckResult.comment,
          measurementResult: qaCheckResult.measurementResult
        });
      }

      this.qaCheckService.manualQaCheckParamsSubject.next({
        creationTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]'),
        productionQaChecks: this.productionQaChecks
      });
    } else {
      qaCheckControl.disable({ emitEvent: false });

      this.qaCheckService
        .setCheckResult(qaCheckResult.id, qaCheckResult.check, qaCheckResult.comment, qaCheckResult.measurementResult)
        .pipe(
          finalize(() => {
            qaCheckControl.enable({ emitEvent: false });
          })
        )
        .subscribe(
          () => {
            this.updateAllOkCheck();
          },
          () => {
            this.resetFormToPreviousState();
          }
        );
    }
  }

  private setAllFormFields(qaCheckResult: QaCheckResult): void {
    this.qaCheckService.setFormFieldsToOk(qaCheckResult, this.form);
    this.updateAllOkCheck();
  }

  private updateAllOkCheck(): void {
    this.allOk = this.qaCheckService.isAllChecksOk(this.form);
    this.previousForm = this.form.value;
  }

  private resetFormToPreviousState(): void {
    this.form.reset(this.previousForm, { onlySelf: false, emitEvent: false });
    this.form.updateValueAndValidity();
  }

  private setAllFieldsForManualQaChaeck(): void {
    this.fields.every((qaCheckResult) =>
      this.productionQaChecks.push({
        productionQaCheckId: qaCheckResult.id,
        checkResult: qaCheckResult.checkResult,
        comment: qaCheckResult.comment,
        measurementResult: qaCheckResult.measurementResult
      })
    );
  }
}
