import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiStateQuery, UiStateService } from '@app/core/global-state';
import { DocumentsService } from '@app/modules/documents/services';
import { MachineSchedule, ProductionOrderStatus } from 'chronos-core-client';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { map, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { nav } from '@app/shared/utils';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  private readonly SEARCH_FILTER_CHAR_LENGTH = 3;
  public productionOrders$: Observable<MachineSchedule[]>;
  public selectedProductionOrder: MachineSchedule;
  public initialProductionOrderIndex: number;
  public documentTabs: MenuItem[];
  public activeTab: MenuItem;

  private subscriptions = new Subscription();
  private searchListFilterSubject = new BehaviorSubject<string>('');
  private searchListFilter$: Observable<string> = this.searchListFilterSubject.asObservable();

  constructor(
    public documentsService: DocumentsService,
    private router: Router,
    private uiStateService: UiStateService,
    private uiStateQuery: UiStateQuery
  ) {}

  public ngOnInit(): void {
    this.productionOrders$ = combineLatest([this.documentsService.getAllProductionOrders(), this.searchListFilter$]).pipe(
      map(([orders, filtervalue]) => this.getFilteredList(orders, filtervalue)),
      tap((orders) => {
        this.selectActiveOrder(orders);
      })
    );

    this.documentTabs = [
      {
        label: 'DOCUMENTS.INFORMATION',
        routerLink: nav.routes.information
      },
      {
        label: 'DOCUMENTS.DOCUMENTS',
        routerLink: nav.routes.files
      },
      {
        label: 'DOCUMENTS.LABELS',
        routerLink: nav.routes.labels
      }
    ];

    this.subscriptions.add(
      this.router.events.subscribe((event: NavigationEnd) => {
        this.initStateRoute(event);
      })
    );
    this.activeTab = this.getActiveTab(this.documentTabs);
  }

  private initStateRoute(event: RouterEvent) {
    const documentTab = this.documentTabs.find((tab) => tab.routerLink === event.url);

    if (documentTab) {
      this.uiStateService.setDocumentStateSelectedRoute(event.url);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onOrderItemSelect(productionOrder: MachineSchedule): void {
    this.selectedProductionOrder = productionOrder;
    this.documentsService.setProductionOrderId(productionOrder.productionOrderId);
    this.uiStateService.setDocumentStateSelectedOrderId(productionOrder.productionOrderId);
  }

  public isItemActive(item: MachineSchedule): boolean {
    return item.productionOrderId === this.selectedProductionOrder?.productionOrderId;
  }

  public searchBarValueChanged(filterValue: string) {
    this.searchListFilterSubject.next(filterValue);
  }

  private getFilteredList(orders: MachineSchedule[], filterValue: string): MachineSchedule[] {
    if (filterValue.length >= this.SEARCH_FILTER_CHAR_LENGTH) {
      return orders.filter((x) => x.externalProductionOrderId.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      return orders;
    }
  }

  private selectActiveOrder(orders: MachineSchedule[]): void {
    const initialProductionOrderId = this.uiStateQuery.getDocumentOrderId();
    const activeOrder = initialProductionOrderId
      ? orders.find((order) => order.productionOrderId === initialProductionOrderId)
      : orders.find((order) => order.status === ProductionOrderStatus.ACTIVE);
    const selectedOrder = activeOrder || R.head(orders);

    if (selectedOrder) {
      this.initialProductionOrderIndex = orders.findIndex((item) => item.productionOrderId === selectedOrder.productionOrderId);
      this.onOrderItemSelect(selectedOrder);
    }
  }

  private getActiveTab(tabs: MenuItem[]): MenuItem {
    return tabs.find((tab) =>
      this.router.isActive(tab.routerLink, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' })
    );
  }
}
