<div class="p-grid">
  <div class="p-col-8">
    <h2 class="page-header">{{ 'SETUP_WASTE_ASSIGNMENT.QUANTITY_ALLOCATION' | translate }}</h2>
    <app-quantity-allocation (validationChanged)="onWasteValidationChange($event)"></app-quantity-allocation>
  </div>
  <ng-container *ngIf="showInactive">
    <div class="p-col-4">
      <h2 class="page-header">{{ 'SETUP_WASTE_ASSIGNMENT.SCALE' | translate }}</h2>
    </div>
  </ng-container>
</div>

<h2 class="page-header">{{ 'SETUP_WASTE_ASSIGNMENT.MOUNTING_STATUS' | translate }}</h2>
<app-bill-of-material
  [showInactive]="showInactive"
  [colSizes]="bomColSizes"
  [billOfMaterials]="billOfMaterials$ | async"
></app-bill-of-material>
