import { Injectable } from '@angular/core';
import { ActiveOrderPhaseDsService } from '@app/core/data-services';
import { MachineCounterDsService } from '@app/core/data-services/machine-counter-ds/machine-counter-ds.service';
import { concat, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CounterInfo, QuantityPool, WebSocketClientService } from 'chronos-core-client';
import { HubUrlsService } from '@app/core/utils';
import { ActiveOrderDataService } from '@app/core/global-state';

@Injectable({
  providedIn: 'root'
})
export class RunPhaseService {
  constructor(
    private activeOrderPhaseDsService: ActiveOrderPhaseDsService,
    private activeOrderDataService: ActiveOrderDataService,
    private machineCounterDsService: MachineCounterDsService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService
  ) {}

  public enterFinishingPhase(): Observable<any> {
    return this.activeOrderPhaseDsService.enterFinishingPhase();
  }

  public renewMachineCounter(): void {
    this.machineCounterDsService.renewMachineCounter();
  }

  public getMachineCounterFromStore(): Observable<number> {
    return this.machineCounterDsService.getMachineCounterFromStore();
  }

  public getQuantityPoolWithNotifications(): Observable<QuantityPool> {
    return this.activeOrderDataService
      .getQuantityPool()
      .pipe(switchMap((quantityPool) => concat(of(quantityPool), this.getQuantityPoolNotifications())));
  }

  private getQuantityPoolNotifications(): Observable<QuantityPool> {
    return this.webSocketClientService.getNotificationsForTopic<QuantityPool>(this.hubUrlsService.getQuantityPool()).pipe(
      tap((data) => {
        this.activeOrderDataService.updateQuantityPool(data);
      })
    );
  }

  public getCounterNotifications(): Observable<CounterInfo> {
    return this.machineCounterDsService.getCounterNotifications();
  }
}
