import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveOrderDataQuery } from '@app/core/global-state';
import { InputService } from '@app/modules/run-phase/containers/input/input.service';
import { CollapseExpandNotificationService } from '@app/modules/run-phase/services';
import { PrimaryMaterialMounted, Quantity } from 'chronos-core-client';
import { Observable, Subscription } from 'rxjs';
import { ContainerCollapseGroup } from '@app/modules/run-phase/components';
import { AppSettingsQuery } from 'chronos-shared';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnDestroy {
  public consumedContainerGroup: ContainerCollapseGroup = {
    name: 'COLLAPSE.CONSUMED',
    value: 0
  };

  public mountedContainerGroup: ContainerCollapseGroup = {
    name: 'COLLAPSE.MOUNTED',
    value: 0
  };

  public activePallets$: Observable<PrimaryMaterialMounted[]>;
  public mountedPallets$: Observable<PrimaryMaterialMounted[]>;
  public consumedPallets$: Observable<PrimaryMaterialMounted[]>;
  public inputQuantityPool$: Observable<Quantity>;
  public expanded = false;

  private subscriptions = new Subscription();
  private isManual = false;

  constructor(
    private inputService: InputService,
    private activeOrderQuery: ActiveOrderDataQuery,
    private appSettingsQuery: AppSettingsQuery,
    private collapseExpandNotificationService: CollapseExpandNotificationService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(this.inputService.getInputListWithNotifications().subscribe());

    this.inputQuantityPool$ = this.activeOrderQuery.inputQuantityPool$;
    this.activePallets$ = this.inputService.activePallets$;
    this.mountedPallets$ = this.inputService.mountedPallets$;
    this.consumedPallets$ = this.inputService.consumedPallets$;

    this.subscriptions.add(
      this.inputService.consumedPalletsCount$.subscribe((consumedPalletsCount) => {
        this.consumedContainerGroup.value = consumedPalletsCount;
      })
    );
    this.subscriptions.add(
      this.inputService.mountedPalletsCount$.subscribe((mountedPalletsCount) => {
        this.mountedContainerGroup.value = mountedPalletsCount;
      })
    );
    this.subscriptions.add(this.collapseExpandNotificationService.eventInput$.subscribe((expanded: boolean) => (this.expanded = expanded)));
    this.isManual = this.appSettingsQuery.isWorkCenterModeManual();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public trackByFunction(index: number, material: PrimaryMaterialMounted): number {
    return material.mountedMaterialId;
  }

  public isQuantityPoolShouldBeShown(pallets: PrimaryMaterialMounted[], quantityPool: Quantity): boolean {
    return (
      (this.isManual && pallets.length === 0 && quantityPool.value <= 0) ||
      (!this.isManual && (pallets.length === 0 || quantityPool.value < 0))
    );
  }
}
