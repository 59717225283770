<section class="chronos-dialog--size-sm">
  <lib-container-pallet-template>
    <lib-material-status [article]="article" [title]="'CREATE_PALLET_MODAL.ACTIVE_MATERIAL' | translate"></lib-material-status>
    <ng-container [formGroup]="form">
      <div
        class="row row--form"
        *libContainerPalletForm
        [ngClass]="isInvalidEmployees ? 'row--required' : violatedEmployeeLimit ? 'row--violated' : ''"
      >
        <div class="col-5 label">{{ 'MANUAL_MODE.NUMBER_OF_EMPLOYEES' | translate }}</div>
        <div class="col-7">
          <lib-input-number formControlName="employees" [min]="minValue" (ngModelChange)="onEmployeeChange($event)"></lib-input-number>
        </div>
      </div>
      <div
        class="row row--form row--extended"
        *libContainerPalletForm
        [ngClass]="isInvalidTime ? 'row--required' : violatedShiftDuration ? 'row--violated' : ''"
      >
        <div class="col-5 label">{{ 'MANUAL_MODE.DURATION' | translate }}</div>
        <div class="col-7 input">
          <lib-input-number
            formControlName="hours"
            unit="h"
            [min]="minValue"
            [max]="maxHours"
            (ngModelChange)="onHoursOrMinutesChange()"
          ></lib-input-number>
          <lib-input-number
            formControlName="minutes"
            unit="min"
            [min]="minValue"
            [max]="maxMinutes"
            (ngModelChange)="onHoursOrMinutesChange()"
          ></lib-input-number>
        </div>
      </div>
      <div class="row" *libContainerPalletForm>
        <div class="col-5 label">{{ 'MANUAL_MODE.SUM' | translate }}</div>
        <div class="col-2">{{ hours }} h {{ minutes }} min</div>
        <div class="col-5 row">
          <div class="col-12 row--padding-extended" [ngClass]="'row--violated'" *ngIf="violatedEmployeeLimit && !violatedShiftDuration">
            {{ 'MANUAL_MODE.EMPLOYEE_KPI_VIOLATION' | translate }}
          </div>

          <div class="col-12 row--padding-extended" [ngClass]="'row--violated'" *ngIf="violatedShiftDuration">
            {{ 'MANUAL_MODE.DURATION_KPI_VIOLATION' | translate }}
          </div>
        </div>
      </div>
    </ng-container>
  </lib-container-pallet-template>
  <lib-modal-footer
    [submitLabel]="submitLabel"
    [whenLoadingTopic]="LOADING_TOPIC"
    [submitDisabled]="isInvalidForm"
    (submitClicked)="submitButton()"
    (declineClicked)="cancelButton()"
  ></lib-modal-footer>
</section>
