/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PandaApiConfiguration } from '../panda-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActionResult } from '../models/action-result';
import { SubscribePerSecondQueriesDto } from '../models/subscribe-per-second-queries-dto';
import { SubscribePerSecondQueryDto } from '../models/subscribe-per-second-query-dto';

@Injectable({
  providedIn: 'root'
})
export class SignalService extends BaseService {
  constructor(config: PandaApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiSignalSubscribePost
   */
  static readonly ApiSignalSubscribePostPath = '/api/Signal/Subscribe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSignalSubscribePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSignalSubscribePost$Plain$Response(params?: { body?: SubscribePerSecondQueryDto }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SignalService.ApiSignalSubscribePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSignalSubscribePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSignalSubscribePost$Plain(params?: { body?: SubscribePerSecondQueryDto }): Observable<string> {
    return this.apiSignalSubscribePost$Plain$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSignalSubscribePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSignalSubscribePost$Response(params?: { body?: SubscribePerSecondQueryDto }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SignalService.ApiSignalSubscribePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSignalSubscribePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSignalSubscribePost(params?: { body?: SubscribePerSecondQueryDto }): Observable<string> {
    return this.apiSignalSubscribePost$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation apiSignalBulkSubscribePost
   */
  static readonly ApiSignalBulkSubscribePostPath = '/api/Signal/BulkSubscribe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSignalBulkSubscribePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSignalBulkSubscribePost$Plain$Response(params?: { body?: SubscribePerSecondQueriesDto }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SignalService.ApiSignalBulkSubscribePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSignalBulkSubscribePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSignalBulkSubscribePost$Plain(params?: { body?: SubscribePerSecondQueriesDto }): Observable<string> {
    return this.apiSignalBulkSubscribePost$Plain$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSignalBulkSubscribePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSignalBulkSubscribePost$Response(params?: { body?: SubscribePerSecondQueriesDto }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SignalService.ApiSignalBulkSubscribePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSignalBulkSubscribePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSignalBulkSubscribePost(params?: { body?: SubscribePerSecondQueriesDto }): Observable<string> {
    return this.apiSignalBulkSubscribePost$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation apiSignalUnSubscribePost
   */
  static readonly ApiSignalUnSubscribePostPath = '/api/Signal/UnSubscribe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSignalUnSubscribePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSignalUnSubscribePost$Plain$Response(params?: { scheduleId?: string }): Observable<StrictHttpResponse<ActionResult>> {
    const rb = new RequestBuilder(this.rootUrl, SignalService.ApiSignalUnSubscribePostPath, 'post');
    if (params) {
      rb.query('scheduleId', params.scheduleId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ActionResult>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSignalUnSubscribePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSignalUnSubscribePost$Plain(params?: { scheduleId?: string }): Observable<ActionResult> {
    return this.apiSignalUnSubscribePost$Plain$Response(params).pipe(map((r: StrictHttpResponse<ActionResult>) => r.body as ActionResult));
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSignalUnSubscribePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSignalUnSubscribePost$Response(params?: { scheduleId?: string }): Observable<StrictHttpResponse<ActionResult>> {
    const rb = new RequestBuilder(this.rootUrl, SignalService.ApiSignalUnSubscribePostPath, 'post');
    if (params) {
      rb.query('scheduleId', params.scheduleId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ActionResult>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSignalUnSubscribePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSignalUnSubscribePost(params?: { scheduleId?: string }): Observable<ActionResult> {
    return this.apiSignalUnSubscribePost$Response(params).pipe(map((r: StrictHttpResponse<ActionResult>) => r.body as ActionResult));
  }
}
