<section class="traceability-table">
  <p-treeTable
    [styleClass]="'traceability-tree-table'"
    [value]="tableData"
    [loading]="isNodeLoading"
    (onNodeExpand)="loadNode($event)"
    [scrollable]="true"
    [scrollHeight]="'flex'"
  >
    <ng-template pTemplate="header">
      <tr class="traceability-table__header-row">
        <th class="toggle-cell"></th>
        <th
          [class]="col.styleClass"
          [ngClass]="{
            highlighted: col.highlighted
          }"
          *ngFor="let col of columns"
        >
          {{ col.label | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ngSwitch]="rowData.itemType" class="traceability-table__order-row" [ngClass]="rowData.rowStyleClass">
        <td class="toggle-cell">
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        </td>
        <ng-container *ngSwitchCase="ITEM_TYPE.ProductionOrderDetails">
          <td class="medium-cell">{{ rowData.itemName | translate }}</td>
          <td class="qty-cell"></td>
          <td class="tiny-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
        </ng-container>
        <ng-container *ngSwitchCase="ITEM_TYPE.Input">
          <td class="medium-cell">{{ rowData.itemName | translate }}</td>
          <td class="qty-cell"></td>
          <td class="tiny-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
        </ng-container>
        <ng-container *ngSwitchCase="ITEM_TYPE.FinishedGood">
          <td class="medium-cell">{{ rowData.itemName | translate }}</td>
          <td class="qty-cell"></td>
          <td class="tiny-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
        </ng-container>
        <ng-container *ngSwitchCase="ITEM_TYPE.InputDetail">
          <td class="medium-cell">
            <div *ngIf="rowData.itemNameIsSscc; else displayItemName">
              <span [innerHtml]="rowData.externalContainerId | sscc"></span>
            </div>
            <ng-template #displayItemName>
              <span>{{ rowData.itemName | translate }}</span>
            </ng-template>
          </td>
          <td class="qty-cell qty-cell-value">{{ rowData.consumedBomQuantity | formatQuantity }}</td>
          <td class="tiny-cell"></td>
          <td class="small-cell">
            <div>{{ rowData.articleDescriptionViewModel?.externalArticleId | emptyTextPlaceholder }}</div>
            <div>{{ rowData.articleDescriptionViewModel?.externalConfigurationId | emptyTextPlaceholder }}</div>
          </td>
          <td class="small-cell">{{ rowData?.containerCreationTime | utcToLocal | date: 'dd.MM.YYYY HH:mm' }}</td>
          <td class="small-cell">{{ rowData.articleDescriptionViewModel?.classification }}</td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
        </ng-container>
        <ng-container *ngSwitchCase="ITEM_TYPE.FinishedGoodDetail">
          <td class="medium-cell">
            <div *ngIf="rowData.itemNameIsSscc; else displayItemName">
              <span [innerHtml]="rowData.externalContainerId | sscc"></span>
            </div>
            <ng-template #displayItemName>
              <span>{{ rowData.itemName | translate }}</span>
            </ng-template>
          </td>
          <td class="qty-cell qty-cell-value">{{ rowData.goodQuantity | formatQuantity }}</td>
          <td class="tiny-cell">{{ rowData.containerSequence }}</td>
          <td class="small-cell">
            <div>{{ rowData.articleDescriptionViewModel?.externalArticleId | emptyTextPlaceholder }}</div>
            <div>{{ rowData.articleDescriptionViewModel?.externalConfigurationId | emptyTextPlaceholder }}</div>
          </td>
          <td class="small-cell">{{ rowData?.containerCreationTime | utcToLocal | date: 'dd.MM.YYYY HH:mm' }}</td>
          <td class="small-cell">{{ rowData.articleDescriptionViewModel?.classification }}</td>
          <td class="small-cell">{{ rowData.externalProductionOrderId }}</td>
          <td class="small-cell">{{ rowData.externalWarehouseId }} / {{ rowData.externalWarehouseLocationId }}</td>
        </ng-container>
        <ng-container *ngSwitchCase="ITEM_TYPE.ProductionOrderDetailsTrace">
          <td class="medium-cell">
            <div *ngIf="rowData.itemNameIsSscc; else displayItemName">
              <span [innerHtml]="rowData.externalContainerId | sscc"></span>
            </div>
            <ng-template #displayItemName>
              <span>{{ rowData.itemName | translate }}</span>
            </ng-template>
          </td>
          <td class="qty-cell qty-cell-value">{{ rowData.consumedQuantity | formatQuantity }}</td>
          <td class="tiny-cell"></td>
          <td class="small-cell">
            <div>{{ rowData.articleDescriptionViewModel?.externalArticleId | emptyTextPlaceholder }}</div>
            <div>{{ rowData.articleDescriptionViewModel?.externalConfigurationId | emptyTextPlaceholder }}</div>
          </td>
          <td class="small-cell">{{ rowData?.containerCreationTime | utcToLocal | date: 'dd.MM.YYYY HH:mm' }}</td>
          <td class="small-cell">{{ rowData.articleDescriptionViewModel?.classification }}</td>
          <td class="small-cell">{{ rowData.externalProductionOrderId }}</td>
          <td class="small-cell"></td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <td class="medium-cell">{{ rowData.externalProductionOrderId }}</td>
          <td class="qty-cell">{{ rowData.externalSalesOrderId }}</td>
          <td class="tiny-cell">{{ rowData.externalSalesOrderPositionId }}</td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
        </ng-container>
      </tr>
    </ng-template>
  </p-treeTable>
</section>
