<section class="modal-dialog-box">
  <div class="employeetabs">
    <p-tabView (onChange)="tabSelectionChanged($event)">
      <p-tabPanel header="{{ employeeRegistrationTabs[0].label | translate }}" [selected]="!isLoginDisabled" [disabled]="isLoginDisabled">
        <app-employee-registration-login></app-employee-registration-login>
      </p-tabPanel>
      <p-tabPanel header="{{ employeeRegistrationTabs[1].label | translate }}" [selected]="isLoginDisabled">
        <app-employee-registration-history
          [inputShiftId]="selectedShiftId"
          [approvalReportDate]="approvalReportDate"
          [approvalWorkCenterId]="approvalWorkCenterId"
        ></app-employee-registration-history>
      </p-tabPanel>
    </p-tabView>
  </div>
</section>

<lib-modal-footer
  [showDeclineButton]="false"
  [submitLabel]="'SHIFT_REPORT.CLOSE'"
  [submitDisabled]="
    !(
      enableSubmit ||
      !appSettingsQuery.isEmployeeRegistrationMandatory() ||
      !appSettingsQuery.enforceEmployeeRegistrationLogin() ||
      (employeeRegistrationDetailsService.enableCancelSubject$ | async)
    )
  "
  (submitClicked)="closeModal()"
></lib-modal-footer>
