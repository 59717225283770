import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListValue } from '../../../models';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true
};

@Component({
  selector: 'lib-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class MultiSelectComponent implements ControlValueAccessor {
  @Input() public showDefaultValue: boolean;
  @Input() public set defaultValueLabel(newLabel: string) {
    this.defaultOption.label = newLabel;
  }
  @Input() public set options(options: ListValue[]) {
    let finalOptions = options;
    if (this.showDefaultValue) {
      finalOptions = [this.defaultOption].concat(options);
    }

    this.currentOptions = finalOptions;
  }
  @Input() public inlineStyle: any;
  @Input() public panelStyle: any;
  @Input() public disabled = false;
  @Input() public grouped = false;
  @Input() public inputId: string = null;
  @Input() public loading = false;
  @Input() public filter = true;
  @Input() public virtualScroll? = true;
  @Input() public virtualScrollItemSize = 40;
  @Output() public touched = new EventEmitter<null>();

  public currentOptions: ListValue[] = [];
  public selectedValues: Array<string> = [];

  private propagateChange: (value: any) => void;
  private propagateTouched: () => void;

  private defaultOption: ListValue = {
    label: 'Please select',
    value: null
  };

  constructor() {}

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.selectedValues = value?.split(',') ?? [];
  }

  public onModelChange(): void {
    this.propagateChange(this.selectedValues);
  }

  public onBlur(): void {
    this.propagateTouched();
    this.touched.emit();
  }
}
