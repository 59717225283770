<div *ngIf="splitTime$ | async as splitTime" class="row no-gutters time-define">
  <div class="col first-downtime-part">
    <div *ngIf="showControls" class="time-define__change-time-button">
      <button pButton type="button" icon="pi pi-chevron-left" (click)="decreaseSplitTimeByMinute(splitTime)"></button>
    </div>

    <div class="time-define__time">
      <lib-input-number
        #hours
        [format]="false"
        [maxlength]="2"
        [size]="2"
        [min]="0"
        [max]="23"
        [ngModel]="splitTime | date : 'HH'"
        (touched)="setSplitTime(splitTime, hours.value, 'h'); onInputBlur()"
        [inputStyle]="getTimeInputStyles(splitTime)"
        [disabled]="disabled"
      ></lib-input-number>
    </div>
  </div>
  <div class="col">
    <span class="time-define__separator">:</span>
  </div>
  <div class="col last-downtime-part">
    <div class="time-define__time">
      <lib-input-number
        #minutes
        [format]="false"
        [maxlength]="2"
        [size]="2"
        [min]="0"
        [max]="59"
        [ngModel]="splitTime | date : 'mm'"
        (touched)="setSplitTime(splitTime, minutes.value, 'm'); onInputBlur()"
        [inputStyle]="getTimeInputStyles(splitTime)"
        [disabled]="disabled"
      ></lib-input-number>
    </div>
    <div *ngIf="showControls" class="time-define__change-time-button">
      <button pButton type="button" icon="pi pi-chevron-right" (click)="increaseSplitTimeByMinute(splitTime)"></button>
    </div>
  </div>
</div>
