<div class="setup-parameters-modal page-padding">
  <form [formGroup]="form" libEnterKeyHandler="submit_modal" class="setup-parameters-modal__container">
    <app-parameters [form]="form"></app-parameters>
  </form>
  <lib-modal-footer
    [submitLabel]="'ORDER_ITEM.CONFIRM_DATA'"
    (submitClicked)="onSubmitClick()"
    [showDeclineButton]="false"
  ></lib-modal-footer>
</div>
