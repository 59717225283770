import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EditRemarkEntry, Remark, RemarkService } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class RemarkDsService {
  constructor(private remarkService: RemarkService) {}

  public getRemark(remarkId: number): Observable<Remark> {
    return this.remarkService.getRemark(remarkId);
  }

  public setRemark(remarkId: number, text: string): Observable<null> {
    const params = {
      remarkId,
      entry: {
        remarkText: text
      } as EditRemarkEntry
    } as RemarkService.EditRemarkParams;

    return this.remarkService.editRemark(params);
  }
}
