import { ChangeDetectionStrategy, Component, Host, Input, OnInit, SkipSelf } from '@angular/core';
import { PhaseNavigationComponent } from '../phase-navigation/phase-navigation.component';

@Component({
  selector: 'lib-sub-phase-navigation-item',
  templateUrl: './sub-phase-navigation-item.component.html',
  styleUrls: ['./sub-phase-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubPhaseNavigationItemComponent implements OnInit {
  @Input() public link = '';
  @Input() public title = '';
  @Input() public isActive = false;
  @Input() public isSubPhaseActive = false;
  @Input() public isCompleted = false;

  public isReadOnly = false;

  constructor(
    @Host()
    @SkipSelf()
    private phaseNavigationComponent: PhaseNavigationComponent
  ) {}

  public ngOnInit(): void {
    this.isReadOnly = this.phaseNavigationComponent.isReadOnly;
  }
}
