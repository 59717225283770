<div class="modal-production-loyalty">
  <div class="p-d-flex">
    <span *ngIf="combinationIcon" class="combination-icon"><i [ngClass]="combinationIcon"></i></span>
    <app-product-order (click)="toggleContent()" [orderItem]="productionOrder" [isItemCollapsed]="itemCollapsed"></app-product-order>
  </div>
  <div class="modal-production-loyalty__items">
    <app-loyalty-reasons
      [loyaltyReasons]="loyaltyReasons?.deviationReasons"
      (loyaltyReasonSelected)="setSelectedLoyaltyReason($event)"
    ></app-loyalty-reasons>
    <div class="comment">
      <div class="comment__label">
        <span>{{ 'PRODUCTION_LOYALTY_MODAL.COMMENT' | translate }}</span>
      </div>
      <div class="comment__value">
        <input libChronosInput pInputText type="text" [(ngModel)]="productionOrderComment" />
      </div>
    </div>
  </div>
</div>
<lib-modal-footer
  (submitClicked)="submitLoyaltyReason()"
  (declineClicked)="cancelLoyaltyReason()"
  [whenLoadingTopic]="LOADING_TOPIC"
  [submitDisabled]="!selectedLoyaltyReason?.deviationReasonId"
></lib-modal-footer>
