import { SetupFooterCommandBase } from '@app/shared/components';
import { ShiftReportService } from '@app/modules/shift-report/services';

export class ConfirmInformationCommand implements SetupFooterCommandBase {
  public enabled = false;
  public buttonText: string;
  public required = true;

  constructor(private shiftReportService: ShiftReportService) {}

  public execute(): void {
    this.shiftReportService.changeConfirmInfoCommand(this.enabled);
  }
}
