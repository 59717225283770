import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PrimaryMaterialMounted } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { RecentlyConsumedMaterialState, RecentlyConsumedMaterialStore } from './recently-consumed-material.store';

@Injectable()
export class RecentlyConsumedMaterialQuery extends QueryEntity<RecentlyConsumedMaterialState, PrimaryMaterialMounted> {
  constructor(protected store: RecentlyConsumedMaterialStore) {
    super(store);
  }

  public consumedMaterial$: Observable<PrimaryMaterialMounted[]> = this.selectAll();
}
