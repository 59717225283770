import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { MachineSchedule } from 'chronos-core-client';

export type ProductionOrderListState = EntityState<MachineSchedule>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'production-order-list', idKey: 'productionOrderId', resettable: true })
export class ProductionOrderListStore extends EntityStore<ProductionOrderListState, MachineSchedule> {
  constructor() {
    super();
  }
}
