import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ForwardSearchResultChildItems, TraceabilityTableRowStyleClass } from '../../models/traceability-searchmode-option';
import {
  ForwardSearchMountedMaterial,
  MountedMaterialForwardSearch,
  MountedMaterialForwardSearchTrace,
  ProductionOrderBackwardSearch,
  ProductionOrderBackwardSearchTrace,
  WarehouseDimension
} from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class TraceabilityMappingService {
  private readonly ROW_STYLE_CLASS = TraceabilityTableRowStyleClass;
  private readonly ForwardSearchResultChildItems = ForwardSearchResultChildItems;

  public mapForwardSearchtoData(items: MountedMaterialForwardSearch[]): TreeNode[] {
    return this.mapItems(items);
  }

  private mapItems(items: MountedMaterialForwardSearch[]): TreeNode[] {
    const loadingLine: ForwardSearchMountedMaterial = {
      consumedBomQuantity: undefined,
      externalContainerId: undefined
    };
    return items.map<TreeNode>((item) => ({
      data: {
        ...item,
        itemName: item.externalProductionOrderId,
        itemNameIsSscc: false,
        itemType: this.ForwardSearchResultChildItems.ProductionOrder,
        rowStyleClass: this.ROW_STYLE_CLASS.FIRST_LEVEL
      },
      children: [
        {
          data: {
            itemName: 'TRACEABILITY.INPUT',
            itemNameIsSscc: false,
            itemType: this.ForwardSearchResultChildItems.Input,
            rowStyleClass: this.ROW_STYLE_CLASS.SECOND_LEVEL
          },
          children: this.mapChildItems(item.mountedMaterials)
        },
        {
          data: {
            itemName: 'TRACEABILITY.FINISHED_GOODS',
            itemNameIsSscc: false,
            itemType: this.ForwardSearchResultChildItems.FinishedGood,
            rowStyleClass: this.ROW_STYLE_CLASS.SECOND_LEVEL
          },
          children: [
            {
              data: {
                ...loadingLine,
                itemName: loadingLine.externalContainerId,
                itemNameIsSscc: loadingLine.articleDescriptionViewModel?.warehouseDimension === WarehouseDimension.PALLET ?? false,
                itemType: this.ForwardSearchResultChildItems.FinishedGoodDetail,
                rowStyleClass: this.ROW_STYLE_CLASS.THIRD_LEVEL
              }
            }
          ]
        }
      ]
    }));
  }

  private mapChildItems(childItem: ForwardSearchMountedMaterial[]): TreeNode[] {
    return childItem.map<TreeNode>((item) => ({
      data: {
        ...item,
        itemName: item.externalContainerId,
        itemNameIsSscc: item.articleDescriptionViewModel.warehouseDimension === WarehouseDimension.PALLET,
        itemType: this.ForwardSearchResultChildItems.InputDetail,
        rowStyleClass: this.ROW_STYLE_CLASS.THIRD_LEVEL
      }
    }));
  }

  public mapBackwardSearchtoData(items: ProductionOrderBackwardSearch[]): TreeNode[] {
    return this.mapBackwardSearchItems(items);
  }

  private mapBackwardSearchItems(items: ProductionOrderBackwardSearch[]): TreeNode[] {
    const loadingLine: ForwardSearchMountedMaterial = {
      consumedBomQuantity: undefined,
      externalContainerId: undefined
    };
    return items.map<TreeNode>((item) => ({
      data: {
        ...item,
        itemName: item.productionOrderId,
        itemNameIsSscc: false,
        itemType: this.ForwardSearchResultChildItems.ProductionOrder,
        rowStyleClass: this.ROW_STYLE_CLASS.FIRST_LEVEL
      },
      children: [
        {
          data: {
            itemName: 'TRACEABILITY.INPUT',
            itemNameIsSscc: false,
            itemType: this.ForwardSearchResultChildItems.ProductionOrderDetails,
            rowStyleClass: this.ROW_STYLE_CLASS.SECOND_LEVEL
          },
          children: [
            {
              data: {
                ...loadingLine,
                itemName: loadingLine.externalContainerId,
                itemNameIsSscc: loadingLine.articleDescriptionViewModel?.warehouseDimension === WarehouseDimension.PALLET ?? false,
                itemType: this.ForwardSearchResultChildItems.ProductionOrderDetailsTrace,
                rowStyleClass: this.ROW_STYLE_CLASS.THIRD_LEVEL
              }
            }
          ]
        }
      ]
    }));
  }

  public mapForwardDetailsDataToTraceabilityData(
    treeData: TreeNode[],
    data: MountedMaterialForwardSearchTrace[],
    productionOrderId: number
  ): TreeNode[] {
    const row = treeData.find((rows) => rows.data.productionOrderId === productionOrderId);
    const finishedGoodsRow = row?.children?.find((child) => child.data.itemType === this.ForwardSearchResultChildItems.FinishedGood);
    if (finishedGoodsRow) {
      const children = this.remapForwardDetailsData(data);
      finishedGoodsRow.children = children;
    }

    return [...treeData];
  }

  private remapForwardDetailsData(data: MountedMaterialForwardSearchTrace[]): TreeNode[] {
    return data.map<TreeNode>((item) => ({
      data: {
        ...item,
        itemName: item.externalContainerId,
        itemNameIsSscc: item.articleDescriptionViewModel.warehouseDimension === WarehouseDimension.PALLET,
        itemType: this.ForwardSearchResultChildItems.FinishedGoodDetail,
        rowStyleClass: this.ROW_STYLE_CLASS.THIRD_LEVEL
      }
    }));
  }

  public mapBackwardDetailsDataToTraceabilityData(
    treeData: TreeNode[],
    data: ProductionOrderBackwardSearchTrace[],
    productionOrderId: number
  ): TreeNode[] {
    const row = treeData.find((rows) => rows.data.productionOrderId === productionOrderId);
    const productionOrderDetailRow = row?.children?.find(
      (child) => child.data.itemType === this.ForwardSearchResultChildItems.ProductionOrderDetails
    );
    if (productionOrderDetailRow) {
      const children = this.remapBackwardDetailsData(data);
      productionOrderDetailRow.children = children;
    }

    return [...treeData];
  }

  private remapBackwardDetailsData(data: ProductionOrderBackwardSearchTrace[]): TreeNode[] {
    return data.map<TreeNode>((item) => ({
      data: {
        ...item,
        itemName: item.externalContainerId,
        itemNameIsSscc: item.articleDescriptionViewModel.warehouseDimension === WarehouseDimension.PALLET,
        itemType: this.ForwardSearchResultChildItems.ProductionOrderDetailsTrace,
        rowStyleClass: this.ROW_STYLE_CLASS.THIRD_LEVEL
      }
    }));
  }
}
