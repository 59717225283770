<section class="material-table">
  <p-treeTable
    [rowTrackBy]="trackByFunction"
    [value]="secondaryMaterialData"
    (onNodeExpand)="onNodeExpand($event)"
    (onNodeCollapse)="onNodeCollapse($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="material-table__toggle-cell">
          <i
            class="toggle-cell__icon cursor-clickable"
            [ngClass]="tableExpanded ? 'icon-collapse-all' : 'icon-expand-all'"
            (click)="expandCollapseTable()"
          ></i>
        </th>
        <th class="material-table__tiny-cell"></th>

        <th
          [class]="col.styleClass"
          [colSpan]="col.colSpan"
          [ngClass]="{
            highlighted: col.highlighted
          }"
          *ngFor="let col of AllAvailableColumns()"
        >
          {{ col.label | translate }}
        </th>
        <th *ngIf="buttonTemplate" [style]="buttonTemplateStyle"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr
        [ngClass]="{
          'second-level-row': rowData.displayMode === MaterialTableDataRowDisplayMode.Container,
          'first-level-row': rowData.displayMode === MaterialTableDataRowDisplayMode.BillOfMaterial,
          'material-table__dummy-row': rowData.isVirtualContainer
        }"
      >
        <td class="material-table__toggle-cell">
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        </td>
        <td class="material-table__tiny-cell"></td>

        <ng-container *ngIf="rowData.displayMode === MaterialTableDataRowDisplayMode.BillOfMaterial; else batchInfo">
          <td colSpan="2" class="material-table__text-cell">
            <div class="material-table__article-icon-layout">
              <lib-article-classification
                [classification]="rowData.article.classification"
                [size]="ArticleClassificationSize.Small"
              ></lib-article-classification>
              <div class="material-table__article-layout">
                <div>{{ rowData.article.externalArticleId }}</div>
                <div>{{ rowData.article.externalConfigurationId }}</div>
              </div>
            </div>
          </td>
          <td colSpan="2" class="material-table__text-cell">{{ rowData.article.articleName }}</td>
          <td class="material-table__text-cell">
            <div *ngIf="rowData.containerCount">
              <i class="icon-container material-table__icon"></i>
              {{ rowData.containerCount | number }}
            </div>
          </td>
          <td class="material-table__number-cell">
            <div>{{ calculateMountedQuantity(rowData) | formatQuantity }}</div>
          </td>
          <td class="material-table__number-cell">{{ rowData.consumedQuantity | formatQuantity }}</td>
        </ng-container>

        <ng-template #batchInfo>
          <td colspan="2" class="material-table__text-cell">
            <div>{{ 'MOUNTING.IDENTIFICATION' | translate }}</div>
            <div><span [innerHTML]="rowData.identificationCode | sscc"></span></div>
          </td>

          <td colspan="2" class="material-table__text-cell material-table__less-important-text">
            <div>{{ rowData.batchExternalTitle | translate }}</div>
            <div>{{ rowData.externalBatchId }}</div>
          </td>

          <td class="material-table__number-cell"></td>

          <td class="material-table__number-cell">{{ rowData.mountedQuantity | formatQuantity }}</td>
          <td class="material-table__number-cell">{{ rowData.consumedQuantity | formatQuantity }}</td>
        </ng-template>

        <td *ngIf="buttonTemplate" class="material-table__last-cell" [style]="buttonTemplateStyle">
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { $implicit: rowData }"></ng-container>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</section>
