/* tslint:disable */
type MountByType = 'ProductionOrder' | 'MaterialBlock';
module MountByType {
  export const PRODUCTION_ORDER: MountByType = 'ProductionOrder';
  export const MATERIAL_BLOCK: MountByType = 'MaterialBlock';
  export function values(): MountByType[] {
    return [PRODUCTION_ORDER, MATERIAL_BLOCK];
  }
}

export { MountByType };
