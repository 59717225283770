/* tslint:disable */
type IssueType = 'Info' | 'Warning' | 'Rejection' | 'Derogation' | 'Default';
module IssueType {
  export const INFO: IssueType = 'Info';
  export const WARNING: IssueType = 'Warning';
  export const REJECTION: IssueType = 'Rejection';
  export const DEROGATION: IssueType = 'Derogation';
  export const DEFAULT: IssueType = 'Default';
  export function values(): IssueType[] {
    return [INFO, WARNING, REJECTION, DEROGATION, DEFAULT];
  }
}

export { IssueType };
