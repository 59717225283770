import { Component, OnInit } from '@angular/core';
import { notificationTopic } from '@app/shared/utils';
import { ApprovalReportService, ChangeApprovalReportQuantityEntry } from 'chronos-core-client';
import { LoadingNotificationService } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-change-quantity-between-orders.component',
  templateUrl: './modal-change-quantity-between-orders.component.html',
  styleUrls: ['./modal-change-quantity-between-orders.component.scss']
})
export class ModalChangeQuantityBetweenOrdersComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalApproveChangeQuantity;

  public workCenterId: number;
  public approvalReportDate: string;
  public approvalReportLineId: number;
  public firstProductionOrder: string;
  public secondProductionOrder: string;
  public goodQuantityFirstProductionOrder: number;
  public goodQuantitySecondProductionOrder: number;
  public secondApproveReportLineId: number;

  public maxQuantity = 0;
  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig, private approvalReportService: ApprovalReportService) {}

  public ngOnInit(): void {
    this.workCenterId = this.config.data?.workCenterId;
    this.approvalReportDate = this.config.data?.approvalReportDate;
    this.approvalReportLineId = this.config.data?.approvalReportLineId;
    this.firstProductionOrder = this.config.data?.firstProductionOrder;
    this.secondProductionOrder = this.config.data?.secondProductionOrder;
    this.goodQuantityFirstProductionOrder = this.config.data?.goodQuantityFirstProductionOrder.value;
    this.goodQuantitySecondProductionOrder = this.config.data?.goodQuantitySecondProductionOrder.value;
    this.secondApproveReportLineId = this.config.data?.secondApproveReportLineId;

    this.maxQuantity = this.goodQuantityFirstProductionOrder + this.goodQuantitySecondProductionOrder;
  }

  public onFirstProductionOrderChange(value: number) {
    value = value > this.maxQuantity ? this.maxQuantity : value;
    this.goodQuantityFirstProductionOrder = value;
    this.goodQuantitySecondProductionOrder = this.maxQuantity - value;
  }

  public onSecondProductionOrderChange(value: number) {
    value = value > this.maxQuantity ? this.maxQuantity : value;
    this.goodQuantityFirstProductionOrder = this.maxQuantity - value;
    this.goodQuantitySecondProductionOrder = value;
  }

  public acceptModal(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    const reportQtyEntry: ChangeApprovalReportQuantityEntry = {
      currentApprovalReportItemGoodQuantity: this.goodQuantityFirstProductionOrder,
      nextApprovalReportItemGoodQuantity: this.goodQuantitySecondProductionOrder,
      nextApprovalReportLineId: this.secondApproveReportLineId
    };
    const changeQuantitiesParams: ApprovalReportService.ChangeQuantityParams = {
      workCenterId: this.workCenterId,
      entry: reportQtyEntry,
      approvalReportLineId: this.approvalReportLineId,
      approvalReportDate: this.approvalReportDate
    };

    this.approvalReportService.changeQuantity(changeQuantitiesParams).subscribe();
    this.ref.close();
  }

  public rejectModal(): void {
    this.ref.close(false);
  }
}
