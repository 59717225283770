import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TaskItemComponent } from './containers/task-list/components/task-item/task-item.component';
import { TaskListComponent } from './containers/task-list/task-list.component';
import { TaskListService } from './services/task-list.service';

@NgModule({
  declarations: [TaskListComponent, TaskItemComponent],
  imports: [SharedModule],
  providers: [TaskListService]
})
export class TaskListModule {}
