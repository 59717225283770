import { Injectable } from '@angular/core';
import { isNil } from 'ramda';
import { BehaviorSubject } from 'rxjs';
import { StorageKey, StorageService } from '../storage';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private languageSubject = new BehaviorSubject<string>('');
  public language$ = this.languageSubject.asObservable();

  private readonly STORAGE_KEY = StorageKey.defaultLanguage;

  constructor(private storageService: StorageService) {}

  public setDefaultLanguage(newLanguage: string): void {
    this.storageService.setItem(this.STORAGE_KEY, newLanguage);

    this.languageSubject.next(newLanguage);
  }

  private getDefaultLanguage(languageFromEnvironment: string): string {
    const defaultLanguage = this.storageService.getItem(this.STORAGE_KEY);

    if (!isNil(defaultLanguage)) {
      return defaultLanguage;
    }

    return languageFromEnvironment;
  }

  public initCurrentDefaultLanguage(languageFromEnvironment: string): void {
    const currentLanguage = this.getDefaultLanguage(languageFromEnvironment);
    this.languageSubject.next(currentLanguage);
  }
}
