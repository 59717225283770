import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentItem } from 'chronos-core-client';

@Component({
  selector: 'app-nc-defective-items-carousel',
  templateUrl: './nc-defective-items-carousel.component.html',
  styleUrls: ['./nc-defective-items-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NcDefectiveItemsCarouselComponent {
  @Input() public defectiveItems: DocumentItem[];
  @Input() public isFullscreen = false;
  @Input() public isImageClickable = false;
  @Input() public isCreateNCMode: boolean;

  @Output() public imageClicked = new EventEmitter<null>();

  public selectedItemIndex = 0;

  public areDocumentsAvailable(): boolean {
    return this.isCreateNCMode ? false : this.defectiveItems?.length > 0;
  }

  public updateSelectedDocument(selectedIndex: any): void {
    this.selectedItemIndex = selectedIndex.page;
  }

  public isDocumentsSelected(document: DocumentItem): boolean {
    return document.downloadLink === this.defectiveItems[this.selectedItemIndex].downloadLink;
  }

  public openDefectiveItemsModal() {
    this.imageClicked.emit();
  }
}
