import { Component, OnInit } from '@angular/core';
import { MountingService } from '@app/modules/mounting/services';
import { Dummy, DummyMapper, ListValue, LoadingNotificationService, LogService } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { notificationTopic } from '@app/shared/utils';
import { finalize } from 'rxjs/operators';
import { PrimaryMountedMaterialQuery } from '@app/modules/mounting/state';
import { ActiveOrderQuery } from '@app/core/global-state';
import { ArticleDescription, CreatePrimaryVirtualContainerData, PrimaryMaterialBlock } from 'chronos-core-client';
import * as lodash from 'lodash';

export interface ArticleDescriptionElement {
  articleName: string;
  productType: any;
  articleClassification: any;
  bomUnitId: string;
  configurationName?: string;
  externalArticleId: string;
  externalConfigurationId?: string;
  grammage?: number;
  id: number;
  inventoryUnitId: string;
  labelAutoPrint: boolean;
  thickness?: number;
  label: string;
  value: number | string;
}
@Component({
  selector: 'app-dummy-pallet-creating-form',
  templateUrl: './dummy-pallet-creating-form.component.html',
  styleUrls: ['./dummy-pallet-creating-form.component.scss']
})
export class DummyPalletCreatingFormComponent implements OnInit {
  public reasonsOptions$: Observable<ListValue[]>;
  public virtualContainerDetails$: Observable<CreatePrimaryVirtualContainerData>;
  public articleOptionsList: ArticleDescriptionElement[];
  public dummyPallet: Dummy;

  private readonly lowestMountingQuantity = 1;
  private readonly highestMountingQuantity = 50000;
  public readonly LOADING_TOPIC = notificationTopic.modalCreateDummy;
  public activeMaterial: ArticleDescription;
  public activeMaterialBlock: PrimaryMaterialBlock;
  public materialBlock: any;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private mountingService: MountingService,
    private primaryMountedMaterialQuery: PrimaryMountedMaterialQuery,
    private activeOrderQuery: ActiveOrderQuery
  ) {
    this.articleOptionsList = new Array<ArticleDescriptionElement>();
    this.primaryMountedMaterialQuery.getActiveMaterialBlock().subscribe((materialBlock) => {
      this.materialBlock = materialBlock;
    });
  }

  public ngOnInit(): void {
    const productionOrderId = this.activeOrderQuery.getActiveOrderId();

    this.virtualContainerDetails$ = this.mountingService.getPrimaryVirtualContainerCreationData(productionOrderId);

    this.dummyPallet = {
      ...this.config.data.dummyPallet,
      lowestMountingQuantity: this.lowestMountingQuantity,
      highestMountingQuantity: this.highestMountingQuantity
    };
  }

  public getPrimaryVirtualContainerCreationData(): void {}

  public getAlternativeArticleOptions() {
    const primaryArticleOptions = [];
    if (this.materialBlock?.alternativeArticles?.length > 0) {
      this.materialBlock.alternativeArticles?.forEach((alternativeArticles) => {
        primaryArticleOptions.push(alternativeArticles);
      });
    }
    primaryArticleOptions.push(this.materialBlock?.article);

    this.articleOptionsList = lodash.cloneDeep(primaryArticleOptions);
    this.articleOptionsList?.forEach((x) => {
      x.label = `${x?.externalArticleId} ${x?.externalConfigurationId}`;
      x.value = x?.id;
    });
    return this.articleOptionsList;
  }

  public addNewVirtualInputContainer(dummyPallet: Dummy): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.mountingService
      .addNewVirtualInputContainer(DummyMapper.toVirtualContainerEntry(dummyPallet))
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(
        () => {
          LogService.success('SUCCESS_MESSAGE.DUMMY_CONTAINER_MOUNTED');
          this.ref.close();
        },
        () => {
          this.ref.close();
        }
      );
  }

  public updateVirtualContainerReasonInfo(dummyPallet: Dummy): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.mountingService
      .changeInfoForVirtualContainer(this.dummyPallet.containerId, dummyPallet.reasonInfo)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close();
      });
  }
}
