import { QaHistoryService } from '@app/modules/qa-history/services/qa-history/qa-history.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActiveOrderDsService,
  ActiveOrderPhaseDsService,
  PrimaryMaterialDsService,
  ProductionOrderDsService,
  ToolCheckoutDsService
} from '@app/core/data-services';
import { ProductionOrderListService } from '@app/core/global-state';
import { HubUrlsService } from '@app/core/utils';
import { SetupFooterCommandBase } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import {
  FinishProductionOrderEntry,
  KpiValue,
  ProductionOrderFinishedInfos,
  ProductionOrderService,
  RunPhaseType,
  SetProductionOrderFinishInfoEntry,
  WebSocketClientService,
  ToolService,
  ToolCheckoutInfo,
  SetToolCheckoutResultEntry
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { KpiService } from '../state/kpi/kpi.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { AppSettingsQuery } from 'chronos-shared';

@Injectable()
export class FinishPhaseService {
  public backCommand: SetupFooterCommandBase;
  public nextCommand: SetupFooterCommandBase;
  public confirmCheckboxCommand: SetupFooterCommandBase;

  constructor(
    private productionOrderListService: ProductionOrderListService,
    private productionOrderService: ProductionOrderService,
    private activeOrderDsService: ActiveOrderDsService,
    private activeOrderPhaseDsService: ActiveOrderPhaseDsService,
    private kpiService: KpiService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private router: Router,
    private primaryMaterialDsService: PrimaryMaterialDsService,
    private appSettingsQuery: AppSettingsQuery,
    private activeWorkCenterService: ActiveWorkCenterService,
    private toolCheckoutService: ToolCheckoutDsService,
    private productionOrderDsService: ProductionOrderDsService,
    private qaHistoryService: QaHistoryService
  ) {}

  public getKpiNotifications(): Observable<any> {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    return this.webSocketClientService
      .getNotificationsForTopic<KpiValue[]>(this.hubUrlsService.getProductionOrderKpiList(activeOrderId))
      .pipe(
        tap((data) => {
          this.kpiService.updateKpis(data);
        })
      );
  }

  public initKpis(): void {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    this.productionOrderService
      .getAllKpis(activeOrderId)
      .pipe(
        tap((kpis) => {
          this.kpiService.updateKpis(kpis);
        })
      )
      .subscribe();
  }

  public setCommands(
    backCommand: SetupFooterCommandBase,
    nextCommand: SetupFooterCommandBase,
    confirmCheckboxCommand: SetupFooterCommandBase = null
  ): void {
    this.backCommand = backCommand;
    this.nextCommand = nextCommand;
    this.confirmCheckboxCommand = confirmCheckboxCommand;
  }

  public finishOrder(): Observable<null> {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: FinishProductionOrderEntry = {
      workCenterId
    };
    const params: ProductionOrderService.FinishProductionOrderParams = {
      entry,
      productionOrderId: activeOrderId
    };

    return this.productionOrderService.finishProductionOrder(params).pipe(
      tap(() => {
        this.productionOrderListService.endProductionOrder(activeOrderId);
      }),
      tap(() => {
        this.activeOrderPhaseDsService.removeActiveOrder();
      }),
      tap(() => this.router.navigate([nav.routes.ordersList]))
    );
  }

  public changeKpiConfirmation(checked: boolean): Observable<null> {
    return this.activeOrderDsService.changeKpiConfirmation(checked).pipe(tap(() => (this.confirmCheckboxCommand.required = !checked)));
  }

  public getFinishedInfo(): Observable<ProductionOrderFinishedInfos> {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    return this.productionOrderService.getFinishedInfos(activeOrderId);
  }

  public setFinishedInfos(productionOrderInfo: string, kpiInfo: string): Observable<null> {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: SetProductionOrderFinishInfoEntry = {
      kpiInfo,
      productionOrderInfo,
      workCenterId
    };
    const params: ProductionOrderService.SetProductionOrderFinishedInfoParams = {
      productionOrderId: activeOrderId,
      entry
    };
    return this.productionOrderService.setProductionOrderFinishedInfo(params);
  }

  public isFinishingPhase(): Observable<boolean> {
    return this.activeOrderPhaseDsService.selectActiveOrderPhase().pipe(map((activePhase) => activePhase === RunPhaseType.FINISHING));
  }

  public dismountContainer(
    containerId: number,
    mountedMaterialId: number,
    remainingQuantity: number,
    isLabelPrinted: boolean,
    runId: number = null,
    workCenterId: number = null
  ): Observable<any> {
    return this.primaryMaterialDsService.dismountContainer(
      containerId,
      mountedMaterialId,
      remainingQuantity,
      isLabelPrinted,
      runId,
      workCenterId
    );
  }

  public getKpisConfirmation(): Observable<boolean> {
    return this.activeOrderDsService.getKpisConfirmation();
  }

  public isAutomatedMode(): Observable<boolean> {
    return this.appSettingsQuery.isAutomatedMode$;
  }

  public isToolCheckoutEnable(): Observable<boolean> {
    return this.appSettingsQuery.isToolCheckoutEnable$;
  }

  public getProductionOrderToolCheckoutInfo(): Observable<ToolCheckoutInfo[]> {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    return this.toolCheckoutService.getProductionOrderToolCheckoutInfo(activeOrderId);
  }

  public setToolCheckoutResult(entry: SetToolCheckoutResultEntry): Observable<null> {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    const params: ToolService.SetToolCheckoutResultParams = {
      productionOrderId: activeOrderId,
      entry
    };
    return this.toolCheckoutService.setToolCheckoutResult(params);
  }

  public cancelEndProduction(): Observable<null> {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    return this.productionOrderDsService.cancelEndProduction(activeOrderId);
  }

  public setQaToolCheckoutConfirm(isChecked: boolean): Observable<null> {
    return this.qaHistoryService.setQaToolCheckoutConfirmCheck(isChecked);
  }

  public isQaToolCheckoutConfirmed(): Observable<boolean> {
    return this.qaHistoryService.isQaToolCheckoutConfirmChecked();
  }
}
