/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ChangeVirtualContainerInfoEntry } from '../models/change-virtual-container-info-entry';
import { VirtualContainerReason } from '../models/virtual-container-reason';
@Injectable({
  providedIn: 'root'
})
class VirtualContainerService extends __BaseService {
  static readonly changeInfoPath = '/api/v1/VirtualContainer/{containerId}/ChangeInfo';
  static readonly getVirtualContainerReasonsPath = '/api/v1/VirtualContainer/Reasons';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `VirtualContainerService.ChangeInfoParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `containerId`:
   */
  changeInfoResponse(params: VirtualContainerService.ChangeInfoParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/VirtualContainer/${encodeURIComponent(String(params.containerId))}/ChangeInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `VirtualContainerService.ChangeInfoParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `containerId`:
   */
  changeInfo(params: VirtualContainerService.ChangeInfoParams): __Observable<null> {
    return this.changeInfoResponse(params).pipe(__map((_r) => _r.body as null));
  }
  getVirtualContainerReasonsResponse(): __Observable<__StrictHttpResponse<Array<VirtualContainerReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/VirtualContainer/Reasons`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VirtualContainerReason>>;
      })
    );
  }
  getVirtualContainerReasons(): __Observable<Array<VirtualContainerReason>> {
    return this.getVirtualContainerReasonsResponse().pipe(__map((_r) => _r.body as Array<VirtualContainerReason>));
  }
}

module VirtualContainerService {
  /**
   * Parameters for ChangeInfo
   */
  export interface ChangeInfoParams {
    entry: ChangeVirtualContainerInfoEntry;
    containerId: number;
  }
}

export { VirtualContainerService };
