import { Component, Input, TemplateRef, OnInit } from '@angular/core';
import { MaterialTableDataRow, MaterialTableDataRowDisplayMode, MaterialTableTreeNode } from '@app/modules/mounting/models';
import { MountingService } from '@app/modules/mounting/services';
import { UiStateQuery, UiStateService } from '@app/core/global-state';
import { ArticleClassification, Quantity, FlushingPrinciple } from 'chronos-core-client';
import { MountedMaterialTableColumn } from './mounted-material-table-column';
import { ArticleClassificationSize } from 'chronos-shared';

@Component({
  selector: 'app-mounted-material-table',
  templateUrl: './mounted-material-table.component.html',
  styleUrls: ['./mounted-material-table.component.scss', '../../../../assets/styles/material-table.scss']
})
export class MountedMaterialTableComponent implements OnInit {
  @Input() public set tableData(tableData: MaterialTableTreeNode[]) {
    this.secondaryMaterialData = this.mountingService.setTableRowsExpandingStatus(tableData, this.secondaryMaterialData);
    this.uiStateQuery.getMountingSecondaryMountedExpandedArticles().forEach((materialBlockId) => {
      const tableRow = this.secondaryMaterialData.find((x) => x.data.materialBlockId === materialBlockId);
      if (tableRow) {
        tableRow.expanded = true;
      }
    });
  }
  @Input() public buttonTemplate: TemplateRef<any>;
  @Input() public buttonTemplateStyle: string | object | null = null;

  public columns: MountedMaterialTableColumn[];
  public tableExpanded = false;
  public secondaryMaterialData: MaterialTableTreeNode[];

  MaterialTableDataRowDisplayMode = MaterialTableDataRowDisplayMode;
  ArticleClassificationSize = ArticleClassificationSize;

  constructor(private mountingService: MountingService, private uiStateService: UiStateService, private uiStateQuery: UiStateQuery) {}

  public ngOnInit(): void {
    this.CreateMountedMaterialColumns();
  }

  public expandCollapseTable(): void {
    const { tableData, tableExpandingStatus } = this.mountingService.expandCollapseTable(this.secondaryMaterialData, this.tableExpanded);

    this.tableExpanded = tableExpandingStatus;
    this.secondaryMaterialData = tableData;
  }

  public trackByFunction(index: number, item: MaterialTableTreeNode): number {
    return item.data?.displayMode === MaterialTableDataRowDisplayMode.Container ? item.data?.mountedMaterialId : item.data?.materialBlockId;
  }

  public onNodeExpand(data: any): void {
    this.uiStateService.setMountingSecondaryMountedExpandedArticleVisible(data.node.data.materialBlockId);
  }
  public onNodeCollapse(data: any): void {
    this.uiStateService.setMountingSecondaryMountedExpandedArticleHide(data.node.data.materialBlockId);
  }

  public isToolType(rowData: MaterialTableDataRow): boolean {
    return rowData.article.classification === ArticleClassification.TOOL;
  }

  public isFlushingPrincipleManual(rowData: MaterialTableDataRow): boolean {
    return rowData.flushingPrinciple === FlushingPrinciple.MANUAL;
  }

  public calculateMountedQuantity(rowData: MaterialTableDataRow): Quantity {
    return {
      value: rowData.mountedQuantity.value + rowData.inputQuantityPool.value,
      unitId: rowData.mountedQuantity.unitId
    };
  }

  public AllAvailableColumns(): MountedMaterialTableColumn[] {
    return this.columns.filter((x) => x.visible === true);
  }

  private CreateMountedMaterialColumns() {
    this.columns = [
      { label: 'MOUNTING.ARTICLE', styleClass: 'material-table__text-cell', highlighted: false, visible: true, colSpan: 2 },
      { label: 'MOUNTING.NAME', styleClass: 'material-table__text-cell', highlighted: false, visible: true, colSpan: 2 },
      { label: 'MOUNTING.QUANTITY', styleClass: 'material-table__text-cell', highlighted: false, visible: true, colSpan: 1 },
      { label: 'MOUNTING.MOUNTED', styleClass: 'material-table__number-cell', highlighted: false, visible: true, colSpan: 1 },
      { label: 'MOUNTING.CONSUMED', styleClass: 'material-table__number-cell', highlighted: false, visible: true, colSpan: 1 }
    ];
  }
}
