<section class="chronos-dialog--size-lg">
  <div class="p-grid p-nogutter kpi">
    <div class="kpi__container">
      <div>{{ 'MOUNTING.PRIMARY_CONTAINER_QA' | translate }}</div>
      <div class="kpi__container-values">
        <lib-pallet-number
          class="kpi__item"
          [palletNumber]="sequenceNumber"
          [classification]="container.article.classification"
        ></lib-pallet-number>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'MOUNTING.SSCC' | translate"
          [kpiValue]="container.ssccCode"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
          [isSsccCodeShort]="true"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'KPI_TILE.QUANTITY_ON_PALLET' | translate"
          [kpiValue]="container.bomGoodQuantity | formatQuantity: false"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'KPI_TILE.WASTE' | translate"
          [kpiValue]="waste"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
        ></lib-pallet-kpi>
      </div>
    </div>
  </div>
  <app-qa-check-list [fields]="qaChecks" [form]="form" [showAllQACheckButton]="false" [isReadOnly]="true"></app-qa-check-list>
  <lib-modal-footer
    [submitDisabled]="false"
    [showDeclineButton]="false"
    [submitLabel]="'MOUNTING.CLOSE'"
    (submitClicked)="onSubmitClick()"
  ></lib-modal-footer>
</section>
