import { Pipe, PipeTransform } from '@angular/core';
/*
 * Shows a empty text placeholder ('-').
 * It shows the last 5 characters of the code and an elipsis character in front.
 * Usage:
 *   value | emptyTextPlaceholder
 * Example:
 *   {{ 2 | emptyTextPlaceholder }}
 *   formats to: 2
 *   {{ "2" | emptyTextPlaceholder }}
 *   formats to: –
 */
@Pipe({ name: 'emptyTextPlaceholder' })
export class EmptyTextPlaceholderPipe implements PipeTransform {
  public transform(value: string): string {
    if (value === undefined || value === null || value.length === 0) {
      return '–';
    }
    return value;
  }
}
