import { Injectable } from '@angular/core';
import { LoadingNotificationService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export abstract class KpiActionBase {
  public shouldShowEdit = true;
  public pendingExecute = false;
  public pendingDismount = false;
  public loadingDismountTopic = '';
  public loadingExecuteTopic = '';

  public execute(): void {}

  protected publishLoadingExecuteTopic(state: boolean) {
    this.pendingExecute = state;
    LoadingNotificationService.publish(this.loadingExecuteTopic, this.pendingExecute);
  }

  public dismount(): void {}

  protected publishLoadingDismountTopic(state: boolean) {
    this.pendingDismount = state;
    LoadingNotificationService.publish(this.loadingDismountTopic, this.pendingDismount);
  }
}
