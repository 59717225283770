<div class="dummy-pallet-modal">
  <label class="sscccode">
    <span class="sscccode__label">{{ 'DISMOUNT_DUMMY_PALLET_FORM.SSCC' | translate }}</span>
    <span [innerHTML]="dismountingPalletInformation?.identificationCode | sscc"></span>
  </label>
  <label class="mounted">
    <span class="mounted__label">{{ 'DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT' | translate }}</span>
    <span>{{ dismountingPalletInformation?.mounted?.value | number }}</span>
    <span class="mounted__unit_value">{{ dismountingPalletInformation?.article?.bomUnitId }}</span>
  </label>
  <label class="info">
    <span class="info__label">{{ 'DISMOUNT_DUMMY_PALLET_FORM.MATERIAL_INFORMATION' | translate }}</span>
    <span class="info__value">
      <span class="info__details">{{ dismountingPalletInformation?.article?.articleName }}</span>
      <span class="info__details">{{ dismountingPalletInformation?.article?.configurationName }}</span>
      <span class="info__details">{{ dismountingPalletInformation?.article?.externalArticleId }}</span>
      <span class="info__details">{{ dismountingPalletInformation?.article?.externalConfigurationId }}</span>
    </span>
  </label>
  <label class="reason">
    <span class="reason__label">{{ 'DISMOUNT_DUMMY_PALLET_FORM.REASON' | translate }}</span>
    <span class="reason__value">{{ dismountingPalletInformation?.reason }}</span>
  </label>

  <label class="info">
    <span class="info__label">{{ 'DISMOUNT_DUMMY_PALLET_FORM.INFO_FIELD' | translate }}</span>
    <span class="info__value">{{ dismountingPalletInformation?.virtualContainerInfo }}</span>
  </label>
</div>
