import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ArticleDescription, Quantity } from 'chronos-core-client';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import { Observable } from 'rxjs';
import { notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService, UnitConverter } from 'chronos-shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-pallet-modal',
  templateUrl: './create-pallet-modal.component.html',
  styleUrls: ['./create-pallet-modal.component.scss']
})
export class CreatePalletModalComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalCreatePallet;

  public sheetForm: UntypedFormControl;
  public article: ArticleDescription;
  public quantity: Quantity;
  public ssccCode: string;
  public sequenceNumber: number;
  public submitButtonLabel: string;

  private productionOrderId: number;
  private producedMaterialId: number;
  private maxQuantity: Quantity;

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef, private manualModeService: ManualModeService) {}

  public ngOnInit(): void {
    this.article = this.config.data?.article;
    this.quantity = this.config.data?.quantity;
    this.maxQuantity = this.config.data?.maxQuantity;
    this.ssccCode = this.config.data?.ssccCode;
    this.productionOrderId = this.config.data?.productionOrderId;
    this.sequenceNumber = this.config.data?.sequenceNumber;
    this.producedMaterialId = this.config.data?.producedMaterialId;
    this.submitButtonLabel = this.config.data?.submitButtonLabel;

    this.sheetForm = new UntypedFormControl(this.quantity?.value, [
      Validators.required,
      Validators.min(UnitConverter.minimalQuantity(this.quantity?.unitId)),
      Validators.max(this.maxQuantity?.value)
    ]);
  }

  public submitButton(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.manageCreationRequest()
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close();
      });
  }

  public cancelButton(): void {
    this.ref.close();
  }

  private manageCreationRequest(): Observable<null> {
    return this.producedMaterialId ? this.changePalletQuantity() : this.createPalletQuantity();
  }

  private createPalletQuantity(): Observable<null> {
    return this.manualModeService.createOutputPallet(this.productionOrderId, this.sheetForm.value);
  }

  private changePalletQuantity(): Observable<null> {
    return this.manualModeService.changeOutputPalletQuantity(this.producedMaterialId, {
      value: this.sheetForm.value,
      unitId: this.quantity.unitId
    });
  }
}
