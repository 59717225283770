<div class="row">
  <span class="col-5">
    <span class="label">{{ formUnitLabel | translate }}</span>
    <i class="icon-reset icon icon--clickable" (click)="resetQuantities()"></i>
  </span>
  <div class="col-7">
    <div class="input">
      <lib-input-number
        [ngModel]="formUnitM"
        (ngModelChange)="countMeters($event)"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        [min]="0"
        [inputStyle]="INPUT_STYLE"
        [unit]="containerUnit"
        [disabled]="!allowQuantityChange"
      ></lib-input-number>
    </div>
    <div class="input" *ngIf="hasWeightEnabled">
      <lib-input-number
        [ngModel]="formUnitKg"
        (ngModelChange)="countKilograms($event)"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        [min]="0"
        [inputStyle]="INPUT_STYLE"
        [unit]="inventoryUnitId"
        [disabled]="!allowQuantityChange"
      ></lib-input-number>
    </div>
  </div>
</div>
