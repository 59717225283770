import { SetupFooterCommandBase } from '@app/shared/components';
import { QaHistoryService } from '@app/modules/qa-history/services/qa-history/qa-history.service';

export class SubmitCreateNCCommandService implements SetupFooterCommandBase {
  public enabled = false;
  public buttonText = 'SETUP_PHASE_FOOTER.CONFIRM_CHECK';
  public required = true;

  public submitNcTopic = 'notificationTopic.CreatedNCsuccesfully';
  constructor(private qaHistoryService: QaHistoryService) {}

  public execute(): void {
    this.qaHistoryService.createNonConformance().subscribe();
  }
}
