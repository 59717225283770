import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ActiveOrderPhaseDsService } from '@app/core/data-services/active-order-phase-ds/active-order-phase-ds.service';
import { GuardsUtils } from '@app/shared/guards/guards-utils';
import { finishPhaseFooterRoutes } from '@app/shared/utils';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export const finishingEndSubPhaseNavigationGuard = (next: ActivatedRouteSnapshot) => {
  const guardsUtils: GuardsUtils = inject(GuardsUtils);
  const activeOrderPhaseDsService: ActiveOrderPhaseDsService = inject(ActiveOrderPhaseDsService);
  {
    combineLatest([activeOrderPhaseDsService.getKpisConfirmed(), guardsUtils.currentSubPhaseIsActive(next, finishPhaseFooterRoutes)]).pipe(
      map(([areKpisConfirmed, currentSubPhaseIsActive]) => {
        if (!areKpisConfirmed) return false;
        return currentSubPhaseIsActive;
      })
    );
  }
};
