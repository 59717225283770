/* tslint:disable */
type FlushingPrinciple = 'Blank' | 'Manual' | 'Start' | 'Finish';
module FlushingPrinciple {
  export const BLANK: FlushingPrinciple = 'Blank';
  export const MANUAL: FlushingPrinciple = 'Manual';
  export const START: FlushingPrinciple = 'Start';
  export const FINISH: FlushingPrinciple = 'Finish';
  export function values(): FlushingPrinciple[] {
    return [BLANK, MANUAL, START, FINISH];
  }
}

export { FlushingPrinciple };
