<div class="consumption-indicator" [ngClass]="{ 'consumption-indicator--warning': showConsumptionWarning() }">
  <div class="consumption-indicator__title">
    {{ 'MOUNTING.CONSUMPTION' | translate }}
  </div>
  <div class="consumption-indicator__wrapper">
    <div class="consumption-indicator__icon">
      <i [ngClass]="showConsumptionWarning() ? 'icon-warning-solid' : 'icon-checked-solid'"></i>
    </div>
    <div *ngIf="events?.value" class="consumption-indicator__events">{{ events.value }}</div>
  </div>
</div>
