import { Injectable } from '@angular/core';
import {
  ChangeVirtualContainerInfoEntry,
  CreatePrimaryVirtualContainerData,
  PrimaryMaterialService,
  SecondaryMaterialService,
  VirtualContainerData,
  VirtualContainerService
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ListValue } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class VirtualContainerDsService {
  constructor(
    private virtualContainerService: VirtualContainerService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private secondaryMaterialService: SecondaryMaterialService,
    private primaryMaterialService: PrimaryMaterialService
  ) {}

  public changeVirtualContainerInfo(containerId: number, info: string): Observable<null> {
    const infoChangeEntry: ChangeVirtualContainerInfoEntry = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      info
    };
    const params: VirtualContainerService.ChangeInfoParams = {
      entry: infoChangeEntry,
      containerId
    };

    return this.virtualContainerService.changeInfo(params);
  }

  public getVirtualContainerReasonsList(): Observable<ListValue[]> {
    return this.virtualContainerService.getVirtualContainerReasons();
  }

  public getPrimaryVirtualContainerCreationData(productionOrderId: number): Observable<CreatePrimaryVirtualContainerData> {
    return this.primaryMaterialService.getPrimaryVirtualContainerCreationData(productionOrderId);
  }

  public getSuggestedQuantityForMounting(ssccCode: string): Observable<VirtualContainerData> {
    return this.secondaryMaterialService.getSecondaryVirtualContainerData(ssccCode);
  }
}
