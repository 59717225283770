/* tslint:disable */
type EmployeeRole = 'Operator' | 'Helper';
module EmployeeRole {
  export const OPERATOR: EmployeeRole = 'Operator';
  export const HELPER: EmployeeRole = 'Helper';
  export function values(): EmployeeRole[] {
    return [OPERATOR, HELPER];
  }
}

export { EmployeeRole };
