import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QaCheckService } from '@app/core/services';
import { ProducedMaterial, ProductionQaCheck } from 'chronos-core-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qa-check-list-in-run',
  templateUrl: './qa-check-list-in-run.component.html',
  styleUrls: ['./qa-check-list-in-run.component.scss']
})
export class QaCheckListInRunComponent implements OnInit, OnDestroy {
  @Input() public fields: ProductionQaCheck[];
  @Input() public pallet?: ProducedMaterial;
  @Input() public isManualPeriodicQaCheck? = false;
  @Input() public isAutomaticPeriodicQaCheck? = false;
  @Input() public periodicQaCheckId?: number;
  @Output() public formValidationChange? = new EventEmitter<boolean>();

  public form: UntypedFormGroup;
  private formChangesSubscription: Subscription;

  constructor(public qaCheckService: QaCheckService) {}

  public ngOnInit(): void {
    this.form = this.qaCheckService.qaChecksFormControlsInit(this.fields);
    this.formChangesSubscription = this.form.valueChanges.subscribe(() => {
      this.formValidationChange.emit(this.form.valid);
    });

    this.formValidationChange.emit(this.form.valid);
  }

  public ngOnDestroy(): void {
    this.formChangesSubscription.unsubscribe();
  }
}
