import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogEntryParameters, LogService } from '../../services/logging';
import { StorageKey, StorageService } from '../../services';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly WARNING_ERROR_CODE = 400;
  private readonly PERMISSION_ERROR_CODE = 403;
  private readonly PILE_TURNER_STORAGE_KEY = StorageKey.pileTurnerId;
  private readonly WORK_CENTER_STORAGE_KEY = StorageKey.workCenterId;
  private readonly WORK_CENTER_LOG_KEY = LogEntryParameters.internalWorkCenterId;
  private readonly PILE_TURNER_LOG_KEY = LogEntryParameters.internalPileTurnerId;
  private readonly API_URL_LOG_KEY = LogEntryParameters.apiUrl;

  constructor(private storageService: StorageService, private translateService: TranslateService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const pileTurnerIdObj = { [this.PILE_TURNER_LOG_KEY]: this.storageService.getItem(this.PILE_TURNER_STORAGE_KEY) };
    const workCenterIdObj = { [this.WORK_CENTER_LOG_KEY]: this.storageService.getItem(this.WORK_CENTER_STORAGE_KEY) };

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.isClientSideError(error?.error)) {
          // client-side error
          LogService.error(error.error.message);
        } else {
          // server-side error
          if (error.status === this.WARNING_ERROR_CODE) {
            LogService.warn(`Error Code: ${error.status}; Message: ${error.message}`, error, workCenterIdObj, pileTurnerIdObj, {
              [this.API_URL_LOG_KEY]: error.url
            });
          } else {
            // We are only handling 403 here, application infrastructure already hadles 401.
            if (error.status === this.PERMISSION_ERROR_CODE) {
              LogService.warn(
                `Error Code: ${error.status};
              Message: ${this.translateService.instant('INFO_LOG.ACCESS_DENIED_MESSAGE')}`,
                error
              );
              return throwError('');
            }

            LogService.error(`Error Code: ${error.status}; Message: ${error.message}`, error, workCenterIdObj, pileTurnerIdObj, {
              [this.API_URL_LOG_KEY]: error.url
            });
          }
        }

        return throwError(error);
      })
    );
  }

  private isClientSideError = (error: any): error is ErrorEvent => error instanceof ErrorEvent;
}
