<section class="pallet-list-view">
  <div class="pallet-list-view__status-panel">
    <div class="pallet-list-order">
      <ng-container [ngTemplateOutlet]="orderByTemplate"></ng-container>
    </div>
    <div class="pallet-list-status">
      <span class="pallet-list-status__label">{{ 'MOUNTING.MACHINE' | translate }}</span>
      <span class="pallet-list-status__value">{{ externalWorkCenterId }}</span>
    </div>
  </div>
  <div class="pallet-list-view__content">
    <div class="pallet-list-view__col">
      <ng-content></ng-content>
      <div class="pallet-list-view__materials">
        <div class="pallet-list-view__material_block">
          <lib-mount-material-status class="pallet-list-view__material_status" [title]="'MOUNTING.ACTIVE_MATERIAL'">
            <div>{{ activeMaterial.articleName }}</div>
            <div>{{ activeMaterial.externalArticleId }}</div>
          </lib-mount-material-status>
        </div>
        <div *ngIf="activeMaterial && canCreateDummyPallets" class="pallet-list-view__material_block">
          <div class="dummy_link dummy_link--list_view" (click)="onDummyPalletButtonClick(activeMaterial.id)">
            <i class="pi pi-plus-circle dummy_link__icon"></i>
            {{ 'MOUNTING.DUMMY' | translate }}
          </div>
        </div>
        <ng-container *ngIf="nextMaterial">
          <div class="pallet-list-view__material_block--grow"></div>
          <div class="pallet-list-view__material_block">
            <lib-mount-material-status
              [styleClass]="MOUNT_MATERIAL_STATUS.NEXT"
              [title]="'MOUNTING.MATERIAL_CHANGE'"
              [plannedStartTime]="plannedStartTime"
            >
              <div>{{ nextMaterial.articleName }}</div>
              <div>{{ nextMaterial.externalArticleId }}</div>
              <div>{{ nextMaterial.configurationName }}</div>
            </lib-mount-material-status>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
