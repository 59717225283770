/* tslint:disable */
type SettingValueType = 'Boolean' | 'Integer' | 'String' | 'DateTime' | 'TimeSpan' | 'Enum' | 'EnumMultiSelect';
module SettingValueType {
  export const BOOLEAN: SettingValueType = 'Boolean';
  export const INTEGER: SettingValueType = 'Integer';
  export const STRING: SettingValueType = 'String';
  export const DATE_TIME: SettingValueType = 'DateTime';
  export const TIME_SPAN: SettingValueType = 'TimeSpan';
  export const ENUM: SettingValueType = 'Enum';
  export const ENUM_MULTI_SELECT: SettingValueType = 'EnumMultiSelect';
  export function values(): SettingValueType[] {
    return [BOOLEAN, INTEGER, STRING, DATE_TIME, TIME_SPAN, ENUM, ENUM_MULTI_SELECT];
  }
}

export { SettingValueType };
