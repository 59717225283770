import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleDescription, MountedMaterialStatus } from 'chronos-core-client';
import { Subscription } from 'rxjs';
import { MaterialStatus } from '../../../models';
import { DismountingPalletInformation } from '../models/dismouting-pallet-information.model';

@Component({
  selector: 'lib-dismount-container-form',
  templateUrl: './dismount-container-form.component.html',
  styleUrls: ['./dismount-container-form.component.scss']
})
export class DismountContainerFormComponent implements OnInit, OnDestroy {
  @Input() public dismountingPalletInformation: DismountingPalletInformation;
  @Input() public article: ArticleDescription;
  @Input() public allowQuantityChange: boolean;
  @Output() public returnSheetChanged = new EventEmitter<number>();
  @Output() public printLabelChanged = new EventEmitter<boolean>();
  @Output() public isValid = new EventEmitter<boolean>();

  public readonly MATERIAL_STATUS = MaterialStatus;
  public dismountForm: UntypedFormGroup;

  private readonly LOWEST_RETURN_QUANTITY_VALUE = 0;
  private subscriptions = new Subscription();

  constructor(private fb: UntypedFormBuilder) {}

  public ngOnInit(): void {
    const maxQuantity = this.getMaxQuantity();
    const defaultReturnQuantity = this.getDefaultQuantity();

    this.dismountForm = this.fb.group({
      returnSheets: [
        defaultReturnQuantity,
        [Validators.required, Validators.min(this.LOWEST_RETURN_QUANTITY_VALUE), Validators.max(maxQuantity)]
      ],
      printLabel: this.dismountingPalletInformation?.printLabel
    });

    this.article = this.dismountingPalletInformation?.article;
    this.subscriptions.add(
      this.returnSheets.valueChanges.subscribe((value) => {
        this.resetPrintLabel(value);
      })
    );
    this.subscriptions.add(
      this.dismountForm.statusChanges.subscribe(() => {
        this.isValid.emit(this.dismountForm.valid);
      })
    );
    this.subscriptions.add(
      this.dismountForm.valueChanges.subscribe(() => {
        this.emitFormValues();
      })
    );
    this.dismountForm.updateValueAndValidity();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private get returnSheets(): AbstractControl {
    return this.dismountForm.controls.returnSheets;
  }

  private get printLabel(): AbstractControl {
    return this.dismountForm.controls.printLabel;
  }

  private emitFormValues(): void {
    this.returnSheetChanged.emit(this.returnSheets.value);
    this.printLabelChanged.emit(this.printLabel.value);
  }

  private getDefaultQuantity(): number {
    return this.dismountingPalletInformation?.mountedMaterialStatus === MountedMaterialStatus.CONSUMED
      ? this.dismountingPalletInformation?.consumed?.value
      : this.dismountingPalletInformation?.mounted?.value;
  }

  private getMaxQuantity(): number {
    return this.dismountingPalletInformation?.mountedMaterialStatus === MountedMaterialStatus.CONSUMED
      ? this.dismountingPalletInformation?.consumed?.value
      : this.dismountingPalletInformation?.initialQuantity?.value;
  }

  private resetPrintLabel(value: number): void {
    if (value && value > 0) {
      this.dismountForm.patchValue({ printLabel: this.dismountingPalletInformation?.printLabel });
    } else {
      this.dismountForm.patchValue({ printLabel: false });
    }
  }
}
