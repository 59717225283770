<app-modal-main-material
  *ngIf="materialStock"
  [availableMountingMaterial]="materialStock.materials"
  [billOfMaterialArticleList]="materialStock.articles"
  (mountingMaterialSubmit)="onSubmitClick($event)"
  [whenLoadingTopic]="LOADING_TOPIC"
  [isConsumeAllEnabled]="isArticleWip"
  submitButtonLabel="APPROVE.CONSUME"
  [tabName]="tabName"
></app-modal-main-material>
