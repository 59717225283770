/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AttributeGroupsAg } from '../models/attribute-groups-ag';
import { AttributeGroupsList } from '../models/attribute-groups-list';
@Injectable({
  providedIn: 'root'
})
class AttributeGroupsService extends __BaseService {
  static readonly getAttributeGroupsPath = '/api/AttributeGroups/getAttributeGroups/{orid}';
  static readonly getAttributeGroupsWithOrgNamePath = '/api/AttributeGroups/{orid}';
  static readonly getAttributeGroupsByIdPath = '/api/AttributeGroups/attributeGroupsById/{orid}/{agid}';
  static readonly addAttributeGroupsPath = '/api/AttributeGroups';
  static readonly updateAttributeGroupsPath = '/api/AttributeGroups';
  static readonly deleteAttributeGroupsPath = '/api/AttributeGroups/{orid}/{agid}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getAttributeGroupsResponse(orid: number): __Observable<__StrictHttpResponse<Array<AttributeGroupsAg>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AttributeGroups/getAttributeGroups/${encodeURIComponent(String(orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AttributeGroupsAg>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getAttributeGroups(orid: number): __Observable<Array<AttributeGroupsAg>> {
    return this.getAttributeGroupsResponse(orid).pipe(__map((_r) => _r.body as Array<AttributeGroupsAg>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getAttributeGroupsWithOrgNameResponse(orid: number): __Observable<__StrictHttpResponse<Array<AttributeGroupsList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AttributeGroups/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AttributeGroupsList>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getAttributeGroupsWithOrgName(orid: number): __Observable<Array<AttributeGroupsList>> {
    return this.getAttributeGroupsWithOrgNameResponse(orid).pipe(__map((_r) => _r.body as Array<AttributeGroupsList>));
  }

  /**
   * @param params The `AttributeGroupsService.GetAttributeGroupsByIdParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `agid`:
   *
   * @return Success
   */
  getAttributeGroupsByIdResponse(
    params: AttributeGroupsService.GetAttributeGroupsByIdParams
  ): __Observable<__StrictHttpResponse<AttributeGroupsAg>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/AttributeGroups/attributeGroupsById/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.agid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttributeGroupsAg>;
      })
    );
  }
  /**
   * @param params The `AttributeGroupsService.GetAttributeGroupsByIdParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `agid`:
   *
   * @return Success
   */
  getAttributeGroupsById(params: AttributeGroupsService.GetAttributeGroupsByIdParams): __Observable<AttributeGroupsAg> {
    return this.getAttributeGroupsByIdResponse(params).pipe(__map((_r) => _r.body as AttributeGroupsAg));
  }

  /**
   * @param body undefined
   * @return Success
   */
  addAttributeGroupsResponse(body?: AttributeGroupsAg): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AttributeGroups`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  addAttributeGroups(body?: AttributeGroupsAg): __Observable<number> {
    return this.addAttributeGroupsResponse(body).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateAttributeGroupsResponse(body?: AttributeGroupsAg): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/AttributeGroups`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateAttributeGroups(body?: AttributeGroupsAg): __Observable<number> {
    return this.updateAttributeGroupsResponse(body).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `AttributeGroupsService.DeleteAttributeGroupsParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `agid`:
   *
   * @return Success
   */
  deleteAttributeGroupsResponse(params: AttributeGroupsService.DeleteAttributeGroupsParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/AttributeGroups/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.agid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `AttributeGroupsService.DeleteAttributeGroupsParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `agid`:
   *
   * @return Success
   */
  deleteAttributeGroups(params: AttributeGroupsService.DeleteAttributeGroupsParams): __Observable<number> {
    return this.deleteAttributeGroupsResponse(params).pipe(__map((_r) => _r.body as number));
  }
}

module AttributeGroupsService {
  /**
   * Parameters for GetAttributeGroupsById
   */
  export interface GetAttributeGroupsByIdParams {
    orid: number;
    agid: number;
  }

  /**
   * Parameters for DeleteAttributeGroups
   */
  export interface DeleteAttributeGroupsParams {
    orid: number;
    agid: number;
  }
}

export { AttributeGroupsService };
