import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { GuardsUtils } from './guards-utils';
import { nav } from '../utils';
import { map, tap } from 'rxjs/operators';

export const phaseNavigationGuard = (next: ActivatedRouteSnapshot) => {
  const guardsUtils: GuardsUtils = inject(GuardsUtils);
  const router: Router = inject(Router);

  const storageVal = localStorage.getItem('workCenterChange');

  return guardsUtils.navigateToPhase(next).pipe(
    map((isPhaseActive) => !!isPhaseActive),
    tap((isPhaseActive) => {
      if (!isPhaseActive && storageVal === 'true') {
        localStorage.removeItem('workCenterChange');
        return router.navigate([nav.ordersList]);
      } else {
        localStorage.removeItem('workCenterChange');
      }
    })
  );
};
