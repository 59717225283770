<div
  [ngClass]="{ 'switch-button--required': required }"
  class="{{ isMarginPaddingRequired ? 'switch-button switch-button--pb5-mar15' : 'switch-button' }}"
  [libLoadingTopic]="loadingTopic"
  [loadingIndicator]="loadingIndicator"
  (loadingEvent)="loading = $event"
>
  <div *ngIf="label" class="switch-button__label">
    {{ label }}
  </div>
  <p-inputSwitch [(ngModel)]="value" [disabled]="disabled || loading" (onChange)="onChanged($event)"></p-inputSwitch>
</div>
