import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { WorkCenterSelectionComponent } from './containers/work-center-selection/work-center-selection.component';

@NgModule({
  declarations: [WorkCenterSelectionComponent],
  exports: [WorkCenterSelectionComponent],
  imports: [SharedModule]
})
export class WorkCenterSelectionModule {}
