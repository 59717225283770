<section class="active-order section-padding">
  <div class="section-header">{{ 'TASKS.TITLE' | translate }}</div>

  <div *ngIf="taskCategories$ | async as taskCategories">
    <ul *ngIf="!isEmpty(taskCategories); else emptyTaskList" class="task-list">
      <li *ngFor="let taskCategory of taskCategories; trackBy: trackByFunction">
        <a (click)="navigateToTasks(taskCategory.type)" class="task-list__item cursor-clickable">
          <span class="task-list__item-badge">{{ taskCategory.count | number }}</span>
          <span class="task-list__item-title">{{ 'TASKS.' + taskCategory.type | uppercase | translate }}</span>
          <i class="icon-arrow-right-open task-list__item-arrow"></i>
        </a>
      </li>
    </ul>

    <ng-template #emptyTaskList>
      <div class="empty-task-list">
        <div class="empty-task-list__title">
          {{ 'TASKS.EMPTY_LIST_TITLE' | translate }}
        </div>
        <div>
          {{ 'TASKS.EMPTY_LIST_CONTENT' | translate }}
        </div>
      </div>
    </ng-template>
  </div>
</section>
