import { Injectable } from '@angular/core';
import { PrimaryMountedMaterialService, RecentlyConsumedMaterialService } from '@app/modules/mounting/state';
import { HubUrlsService } from '@app/core/utils';
import { PrimaryMaterial, PrimaryMaterialBlock, WebSocketClientService } from 'chronos-core-client';
import { MountedMaterial } from 'chronos-shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TabPrimaryService {
  constructor(
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private primaryMountedMaterialService: PrimaryMountedMaterialService,
    private recentlyConsumedMaterialService: RecentlyConsumedMaterialService
  ) {}

  public getPrimaryMounterMaterialNotifications(): Observable<any> {
    return this.webSocketClientService.getNotificationsForTopic(this.hubUrlsService.getPrimaryMountedMaterial()).pipe(
      tap((primaryMountedMaterial: PrimaryMaterial) => {
        this.primaryMountedMaterialService.updatePrimaryMountedMaterial(primaryMountedMaterial);
      }),
      tap((primaryMountedMaterial: PrimaryMaterial) => {
        this.cleanConsumedMaterialStore(primaryMountedMaterial.materialBlocks);
      })
    );
  }

  public getConsumedMaterialNotifications(): Observable<any> {
    return this.webSocketClientService.getNotificationsForTopic<MountedMaterial>(this.hubUrlsService.getConsumedMaterial()).pipe(
      tap((data) => {
        this.recentlyConsumedMaterialService.updateConsumedMaterial(data);
      })
    );
  }

  private cleanConsumedMaterialStore(materialBlocks: PrimaryMaterialBlock[]): void {
    materialBlocks
      .filter(({ mountedMaterials }) => mountedMaterials.length)
      .map(({ mountedMaterials }) => {
        const mountedMaterialIds = mountedMaterials.map((mountedMaterial) => mountedMaterial.mountedMaterialId);
        this.recentlyConsumedMaterialService.removePallet(mountedMaterialIds);
      });
  }
}
