export class SerializedBackendError {
  public details: string;
  public innerException: SerializedBackendError;
  public message: string;
  public source: string;
  public stackTrace: string;
  public type: string;

  constructor(message: BackendError) {
    this.details = message.details;
    this.message = message.message;
    this.source = message.source;
    this.stackTrace = message.stackTrace;
    this.type = message.type;
    if (message.innerException) {
      this.innerException = new SerializedBackendError({ ...message.innerException });
    }
  }
}

export interface BackendError {
  details: string;
  innerException: any;
  message: string;
  source: string;
  stackTrace: string;
  type: string;
}
