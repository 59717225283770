/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ForwardTraceOrder } from '../models/forward-trace-order';
import { BackwardTraceBomLine } from '../models/backward-trace-bom-line';
import { MountedMaterialForwardSearch } from '../models/mounted-material-forward-search';
import { ProductionOrderBackwardSearch } from '../models/production-order-backward-search';
import { MountedMaterialForwardSearchTrace } from '../models/mounted-material-forward-search-trace';
import { ProductionOrderBackwardSearchTrace } from '../models/production-order-backward-search-trace';
import { ForwardTraceExport } from '../models/forward-trace-export';
import { BackwardTraceExport } from '../models/backward-trace-export';
@Injectable({
  providedIn: 'root'
})
class TraceabilityService extends __BaseService {
  static readonly createTraceabilityDataPath = '/api/v1/{workCenterId}/{runId}/CreateTraceabilityData';
  static readonly getTraceabilityForwardSearchPath = '/api/v1/getTraceabilityForwardSearch';
  static readonly getTraceabilityBackwardSearchPath = '/api/v1/getTraceabilityBackwardSearch';
  static readonly getMountedMaterialsForForwardSearchPath = '/api/v1/getMountedMaterialsForForwardSearch';
  static readonly getProductionOrdersForBackwardSearchPath = '/api/v1/getProductionOrdersForBackwardSearch';
  static readonly getMountedMaterialTraceForForwardSearchPath = '/api/v1/getMountedMaterialTraceForForwardSearch/{productionOrderId}';
  static readonly getProductionOrderTraceForBackwardSearchPath = '/api/v1/getProductionOrderTraceForBackwardSearch/{productionOrderId}';
  static readonly getSimpleForwardSearchPath = '/api/v1/getSimpleForwardSearch';
  static readonly getTraceabilityForwardSearchExportPath = '/api/v1/getTraceabilityForwardSearchExport';
  static readonly getTraceabilityBackwardSearchExportPath = '/api/v1/getTraceabilityBackwardSearchExport';
  static readonly getSimpleBackwardSearchPath = '/api/v1/getSimpleBackwardSearch';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `TraceabilityService.CreateTraceabilityDataParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `runId`:
   */
  createTraceabilityDataResponse(params: TraceabilityService.CreateTraceabilityDataParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/${encodeURIComponent(String(params.workCenterId))}/${encodeURIComponent(String(params.runId))}/CreateTraceabilityData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.CreateTraceabilityDataParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `runId`:
   */
  createTraceabilityData(params: TraceabilityService.CreateTraceabilityDataParams): __Observable<null> {
    return this.createTraceabilityDataResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `TraceabilityService.GetTraceabilityForwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getTraceabilityForwardSearchResponse(
    params: TraceabilityService.GetTraceabilityForwardSearchParams
  ): __Observable<__StrictHttpResponse<Array<ForwardTraceOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/getTraceabilityForwardSearch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ForwardTraceOrder>>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetTraceabilityForwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getTraceabilityForwardSearch(params: TraceabilityService.GetTraceabilityForwardSearchParams): __Observable<Array<ForwardTraceOrder>> {
    return this.getTraceabilityForwardSearchResponse(params).pipe(__map((_r) => _r.body as Array<ForwardTraceOrder>));
  }

  /**
   * @param params The `TraceabilityService.GetTraceabilityBackwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getTraceabilityBackwardSearchResponse(
    params: TraceabilityService.GetTraceabilityBackwardSearchParams
  ): __Observable<__StrictHttpResponse<Array<BackwardTraceBomLine>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/getTraceabilityBackwardSearch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BackwardTraceBomLine>>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetTraceabilityBackwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getTraceabilityBackwardSearch(
    params: TraceabilityService.GetTraceabilityBackwardSearchParams
  ): __Observable<Array<BackwardTraceBomLine>> {
    return this.getTraceabilityBackwardSearchResponse(params).pipe(__map((_r) => _r.body as Array<BackwardTraceBomLine>));
  }

  /**
   * @param params The `TraceabilityService.GetMountedMaterialsForForwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getMountedMaterialsForForwardSearchResponse(
    params: TraceabilityService.GetMountedMaterialsForForwardSearchParams
  ): __Observable<__StrictHttpResponse<Array<MountedMaterialForwardSearch>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/getMountedMaterialsForForwardSearch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MountedMaterialForwardSearch>>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetMountedMaterialsForForwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getMountedMaterialsForForwardSearch(
    params: TraceabilityService.GetMountedMaterialsForForwardSearchParams
  ): __Observable<Array<MountedMaterialForwardSearch>> {
    return this.getMountedMaterialsForForwardSearchResponse(params).pipe(__map((_r) => _r.body as Array<MountedMaterialForwardSearch>));
  }

  /**
   * @param params The `TraceabilityService.GetProductionOrdersForBackwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getProductionOrdersForBackwardSearchResponse(
    params: TraceabilityService.GetProductionOrdersForBackwardSearchParams
  ): __Observable<__StrictHttpResponse<Array<ProductionOrderBackwardSearch>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/getProductionOrdersForBackwardSearch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionOrderBackwardSearch>>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetProductionOrdersForBackwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getProductionOrdersForBackwardSearch(
    params: TraceabilityService.GetProductionOrdersForBackwardSearchParams
  ): __Observable<Array<ProductionOrderBackwardSearch>> {
    return this.getProductionOrdersForBackwardSearchResponse(params).pipe(__map((_r) => _r.body as Array<ProductionOrderBackwardSearch>));
  }

  /**
   * @param params The `TraceabilityService.GetMountedMaterialTraceForForwardSearchParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `viewMode`:
   */
  getMountedMaterialTraceForForwardSearchResponse(
    params: TraceabilityService.GetMountedMaterialTraceForForwardSearchParams
  ): __Observable<__StrictHttpResponse<Array<MountedMaterialForwardSearchTrace>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewMode != null) __params = __params.set('viewMode', params.viewMode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/getMountedMaterialTraceForForwardSearch/${encodeURIComponent(String(params.productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MountedMaterialForwardSearchTrace>>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetMountedMaterialTraceForForwardSearchParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `viewMode`:
   */
  getMountedMaterialTraceForForwardSearch(
    params: TraceabilityService.GetMountedMaterialTraceForForwardSearchParams
  ): __Observable<Array<MountedMaterialForwardSearchTrace>> {
    return this.getMountedMaterialTraceForForwardSearchResponse(params).pipe(
      __map((_r) => _r.body as Array<MountedMaterialForwardSearchTrace>)
    );
  }

  /**
   * @param params The `TraceabilityService.GetProductionOrderTraceForBackwardSearchParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `viewMode`:
   */
  getProductionOrderTraceForBackwardSearchResponse(
    params: TraceabilityService.GetProductionOrderTraceForBackwardSearchParams
  ): __Observable<__StrictHttpResponse<Array<ProductionOrderBackwardSearchTrace>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewMode != null) __params = __params.set('viewMode', params.viewMode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/getProductionOrderTraceForBackwardSearch/${encodeURIComponent(String(params.productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionOrderBackwardSearchTrace>>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetProductionOrderTraceForBackwardSearchParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `viewMode`:
   */
  getProductionOrderTraceForBackwardSearch(
    params: TraceabilityService.GetProductionOrderTraceForBackwardSearchParams
  ): __Observable<Array<ProductionOrderBackwardSearchTrace>> {
    return this.getProductionOrderTraceForBackwardSearchResponse(params).pipe(
      __map((_r) => _r.body as Array<ProductionOrderBackwardSearchTrace>)
    );
  }

  /**
   * @param params The `TraceabilityService.GetSimpleForwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getSimpleForwardSearchResponse(
    params: TraceabilityService.GetSimpleForwardSearchParams
  ): __Observable<__StrictHttpResponse<Array<ForwardTraceOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/getSimpleForwardSearch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ForwardTraceOrder>>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetSimpleForwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getSimpleForwardSearch(params: TraceabilityService.GetSimpleForwardSearchParams): __Observable<Array<ForwardTraceOrder>> {
    return this.getSimpleForwardSearchResponse(params).pipe(__map((_r) => _r.body as Array<ForwardTraceOrder>));
  }

  /**
   * @param params The `TraceabilityService.GetTraceabilityForwardSearchExportParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getTraceabilityForwardSearchExportResponse(
    params: TraceabilityService.GetTraceabilityForwardSearchExportParams
  ): __Observable<__StrictHttpResponse<ForwardTraceExport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/getTraceabilityForwardSearchExport`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ForwardTraceExport>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetTraceabilityForwardSearchExportParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getTraceabilityForwardSearchExport(
    params: TraceabilityService.GetTraceabilityForwardSearchExportParams
  ): __Observable<ForwardTraceExport> {
    return this.getTraceabilityForwardSearchExportResponse(params).pipe(__map((_r) => _r.body as ForwardTraceExport));
  }

  /**
   * @param params The `TraceabilityService.GetTraceabilityBackwardSearchExportParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getTraceabilityBackwardSearchExportResponse(
    params: TraceabilityService.GetTraceabilityBackwardSearchExportParams
  ): __Observable<__StrictHttpResponse<BackwardTraceExport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/getTraceabilityBackwardSearchExport`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BackwardTraceExport>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetTraceabilityBackwardSearchExportParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getTraceabilityBackwardSearchExport(
    params: TraceabilityService.GetTraceabilityBackwardSearchExportParams
  ): __Observable<BackwardTraceExport> {
    return this.getTraceabilityBackwardSearchExportResponse(params).pipe(__map((_r) => _r.body as BackwardTraceExport));
  }

  /**
   * @param params The `TraceabilityService.GetSimpleBackwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getSimpleBackwardSearchResponse(
    params: TraceabilityService.GetSimpleBackwardSearchParams
  ): __Observable<__StrictHttpResponse<Array<BackwardTraceBomLine>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/getSimpleBackwardSearch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BackwardTraceBomLine>>;
      })
    );
  }
  /**
   * @param params The `TraceabilityService.GetSimpleBackwardSearchParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `searchText`:
   *
   * - `endDate`:
   */
  getSimpleBackwardSearch(params: TraceabilityService.GetSimpleBackwardSearchParams): __Observable<Array<BackwardTraceBomLine>> {
    return this.getSimpleBackwardSearchResponse(params).pipe(__map((_r) => _r.body as Array<BackwardTraceBomLine>));
  }
}

module TraceabilityService {
  /**
   * Parameters for CreateTraceabilityData
   */
  export interface CreateTraceabilityDataParams {
    workCenterId: number;
    runId: number;
  }

  /**
   * Parameters for GetTraceabilityForwardSearch
   */
  export interface GetTraceabilityForwardSearchParams {
    startDate?: string;
    searchText?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetTraceabilityBackwardSearch
   */
  export interface GetTraceabilityBackwardSearchParams {
    startDate?: string;
    searchText?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetMountedMaterialsForForwardSearch
   */
  export interface GetMountedMaterialsForForwardSearchParams {
    startDate?: string;
    searchText?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetProductionOrdersForBackwardSearch
   */
  export interface GetProductionOrdersForBackwardSearchParams {
    startDate?: string;
    searchText?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetMountedMaterialTraceForForwardSearch
   */
  export interface GetMountedMaterialTraceForForwardSearchParams {
    productionOrderId: number;
    viewMode?: string;
  }

  /**
   * Parameters for GetProductionOrderTraceForBackwardSearch
   */
  export interface GetProductionOrderTraceForBackwardSearchParams {
    productionOrderId: number;
    viewMode?: string;
  }

  /**
   * Parameters for GetSimpleForwardSearch
   */
  export interface GetSimpleForwardSearchParams {
    startDate?: string;
    searchText?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetTraceabilityForwardSearchExport
   */
  export interface GetTraceabilityForwardSearchExportParams {
    startDate?: string;
    searchText?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetTraceabilityBackwardSearchExport
   */
  export interface GetTraceabilityBackwardSearchExportParams {
    startDate?: string;
    searchText?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetSimpleBackwardSearch
   */
  export interface GetSimpleBackwardSearchParams {
    startDate?: string;
    searchText?: string;
    endDate?: string;
  }
}

export { TraceabilityService };
