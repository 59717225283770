import { Component } from '@angular/core';
import { DocumentsService } from '@app/modules/documents/services';
import { ButtonItem } from '@app/shared/components';

@Component({
  selector: 'app-tab-document',
  templateUrl: './tab-document.component.html',
  styleUrls: ['./tab-document.component.scss']
})
export class TabDocumentComponent {
  public documents$ = this.documentsService.getAllDocumentsForOrder();

  constructor(public documentsService: DocumentsService) {}

  public createOpenButton(downloadLink: string, documentId: number): ButtonItem[] {
    return [
      {
        command: () => {
          this.openDocument(downloadLink, documentId);
        },
        label: 'DOCUMENTS.OPEN'
      }
    ];
  }

  public openDocument(downloadLink: string, documentId: number): void {
    this.documentsService.markAsDisplayed(documentId).subscribe(() => (this.documents$ = this.documentsService.getAllDocumentsForOrder()));
    window.open(downloadLink, '_blank');
  }
}
