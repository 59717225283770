/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WorkCenterTask } from '../models/work-center-task';
import { WorkCenterTasksByCategory } from '../models/work-center-tasks-by-category';
import { WorkCenterTaskAffectedTimes } from '../models/work-center-task-affected-times';
@Injectable({
  providedIn: 'root'
})
class TasksService extends __BaseService {
  static readonly getByWorkCenterIdPath = '/api/v1/Tasks/{workCenterId}';
  static readonly getByWorkCenterTaskIdPath = '/api/v1/Tasks/{workCenterTaskId}/ByWorkCenterTaskId';
  static readonly getOpenWorkCenterTasksByCategoryPath = '/api/v1/Tasks/{workCenterId}/ByCategory';
  static readonly getAffectedTimesPath = '/api/v1/Tasks/{workCenterTaskId}/AffectedTimes';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `TasksService.GetByWorkCenterIdParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `TaskType`:
   *
   * - `TaskStatus`:
   *
   * - `ShowDoneTasks`:
   *
   * - `PageSize`:
   *
   * - `PageIndex`:
   */
  getByWorkCenterIdResponse(params: TasksService.GetByWorkCenterIdParams): __Observable<__StrictHttpResponse<Array<WorkCenterTask>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.TaskType != null) __params = __params.set('TaskType', params.TaskType.toString());
    if (params.TaskStatus != null) __params = __params.set('TaskStatus', params.TaskStatus.toString());
    if (params.ShowDoneTasks != null) __params = __params.set('ShowDoneTasks', params.ShowDoneTasks.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageIndex != null) __params = __params.set('PageIndex', params.PageIndex.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Tasks/${encodeURIComponent(String(params.workCenterId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkCenterTask>>;
      })
    );
  }
  /**
   * @param params The `TasksService.GetByWorkCenterIdParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `TaskType`:
   *
   * - `TaskStatus`:
   *
   * - `ShowDoneTasks`:
   *
   * - `PageSize`:
   *
   * - `PageIndex`:
   */
  getByWorkCenterId(params: TasksService.GetByWorkCenterIdParams): __Observable<Array<WorkCenterTask>> {
    return this.getByWorkCenterIdResponse(params).pipe(__map((_r) => _r.body as Array<WorkCenterTask>));
  }

  /**
   * @param workCenterTaskId undefined
   */
  getByWorkCenterTaskIdResponse(workCenterTaskId: number): __Observable<__StrictHttpResponse<WorkCenterTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Tasks/${encodeURIComponent(String(workCenterTaskId))}/ByWorkCenterTaskId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenterTask>;
      })
    );
  }
  /**
   * @param workCenterTaskId undefined
   */
  getByWorkCenterTaskId(workCenterTaskId: number): __Observable<WorkCenterTask> {
    return this.getByWorkCenterTaskIdResponse(workCenterTaskId).pipe(__map((_r) => _r.body as WorkCenterTask));
  }

  /**
   * @param workCenterId undefined
   */
  getOpenWorkCenterTasksByCategoryResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<WorkCenterTasksByCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Tasks/${encodeURIComponent(String(workCenterId))}/ByCategory`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkCenterTasksByCategory>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getOpenWorkCenterTasksByCategory(workCenterId: number): __Observable<Array<WorkCenterTasksByCategory>> {
    return this.getOpenWorkCenterTasksByCategoryResponse(workCenterId).pipe(__map((_r) => _r.body as Array<WorkCenterTasksByCategory>));
  }

  /**
   * @param workCenterTaskId undefined
   */
  getAffectedTimesResponse(workCenterTaskId: number): __Observable<__StrictHttpResponse<WorkCenterTaskAffectedTimes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Tasks/${encodeURIComponent(String(workCenterTaskId))}/AffectedTimes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenterTaskAffectedTimes>;
      })
    );
  }
  /**
   * @param workCenterTaskId undefined
   */
  getAffectedTimes(workCenterTaskId: number): __Observable<WorkCenterTaskAffectedTimes> {
    return this.getAffectedTimesResponse(workCenterTaskId).pipe(__map((_r) => _r.body as WorkCenterTaskAffectedTimes));
  }
}

module TasksService {
  /**
   * Parameters for GetByWorkCenterId
   */
  export interface GetByWorkCenterIdParams {
    workCenterId: number;
    TaskType?: null | 'Downtime' | 'QaCheck';
    TaskStatus?: null | 'Ok' | 'NotOk' | 'AutoOk';
    ShowDoneTasks?: boolean;
    PageSize?: null | number;
    PageIndex?: null | number;
  }
}

export { TasksService };
