<div
  *ngIf="collapseGroup"
  class="collapse-header"
  (click)="toggleContent()"
  [ngStyle]="{ 'margin-left.px': marginLeft, 'margin-right.px': marginRight }"
>
  <div class="arrow">
    <i [ngClass]="isExpanded ? 'icon-arrow-down-open' : 'icon-arrow-right-open'"></i>
  </div>
  <div class="collapse-header__title">
    <span class="container-color" *ngIf="collapseGroup.of !== undefined && collapseGroup.of !== null; else singleGroup">
      {{
        'COLLAPSE.VALUE_OF'
          | translate
            : {
                current: collapseGroup.value,
                total: collapseGroup.of
              }
      }}
      {{ collapseGroup.name | translate }}
    </span>
    <ng-template #singleGroup>
      <span class="container-color">{{ collapseGroup.value }} {{ collapseGroup.name | translate }}</span>
    </ng-template>
  </div>
  <div class="toggle-content-transparent-layer"></div>
</div>

<div class="collapse-container" *ngIf="isExpanded">
  <ng-content></ng-content>
</div>
