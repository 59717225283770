import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PrimaryMaterialMounted } from 'chronos-core-client';
import { ConsumptionListFilters, ConsumptionListSorting, SortDirection } from 'chronos-shared';

export interface ConsumedMaterialState extends EntityState<PrimaryMaterialMounted> {
  filters: ConsumptionListFilters;
  sorting: ConsumptionListSorting;
}

const initialState = {
  filters: {
    showOnlyDummy: false
  },
  sorting: {
    palletSequence: SortDirection.ASC
  }
};

@Injectable()
@StoreConfig({ name: 'consumed-material', idKey: 'mountedMaterialId', resettable: true })
export class ConsumedMaterialStore extends EntityStore<ConsumedMaterialState, PrimaryMaterialMounted> {
  constructor() {
    super(initialState);
  }

  public updateFilters(filters: Partial<ConsumptionListFilters>): void {
    const currentFilters = this.getValue().filters;
    this.update({ filters: { ...currentFilters, ...filters } });
  }

  public updateSorting(sorting: Partial<ConsumptionListSorting>): void {
    const currentSorting = this.getValue().sorting;
    this.update({ sorting: { ...currentSorting, ...sorting } });
  }
}
