import { Component, Input } from '@angular/core';
import { MountMaterialStatus } from '../../../models';

@Component({
  selector: 'lib-mount-material-status',
  templateUrl: './mount-material-status.component.html',
  styleUrls: ['./mount-material-status.component.scss']
})
export class MountMaterialStatusComponent {
  @Input() public title: string;
  @Input() public plannedStartTime: Date;
  @Input() public styleClass = MountMaterialStatus.ACTIVE;
}
