import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { notificationTopic } from '@app/shared/utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { isNil } from 'ramda';
import { ModalProductionPeriodsService } from './modal-production-periods.service';
import { finalize } from 'rxjs/operators';
import { LoadingNotificationService, StorageKey, StorageService } from 'chronos-shared';
import { ProductionOrder, ProductionPeriod } from 'chronos-core-client';
import { elastic } from '@app/shared/utils/elastic';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/ro';
import '@angular/common/locales/global/fr';

@Component({
  selector: 'app-modal-production-periods',
  templateUrl: './modal-production-periods.component.html',
  styleUrls: ['./modal-production-periods.component.scss']
})
export class ModalProductionPeriodsComponent implements OnInit {
  public openPeriods: ProductionPeriod[];
  public selectedPeriod: ProductionPeriod;
  public productionPeriodsTableColumns: any[];
  public productionPeriodsTableSubColumns: any[];
  public productionOrder: ProductionOrder;
  public processWithoutPeriod = true;
  public loadingData: boolean;
  public defaultLanguage: string;
  public allowTransportProcessAfterGluingManualOverride: boolean;
  public manualTransport = false;
  public loadingTopic: string;

  public readonly LOADING_TOPIC = notificationTopic.modalProductionPeriods;
  private readonly STORAGE_KEY = StorageKey.defaultLanguage;
  constructor(
    private modalProductionPeriodsService: ModalProductionPeriodsService,
    private storageService: StorageService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.loadingData = true;
    this.modalProductionPeriodsService
      .getOpenPeriods()
      .pipe(finalize(() => (this.loadingData = false)))
      .subscribe((periods) => {
        this.setPeriods(periods);
      });

    this.productionPeriodsTableColumns = [
      { field: 'periodStart', header: 'OPEN_PERIODS_MODAL.PERIOD_START' },
      { field: 'periodEnd', header: 'OPEN_PERIODS_MODAL.PERIOD_END' }
    ];

    this.productionPeriodsTableSubColumns = [
      { field: 'time', header: 'OPEN_PERIODS_MODAL.TIME' },
      { field: 'shift', header: 'OPEN_PERIODS_MODAL.SHIFT' }
    ];

    this.productionOrder = this.config.data.productionOrder;
    this.allowTransportProcessAfterGluingManualOverride = this.config.data.allowTransportProcessAfterGluingManualOverride;
    this.defaultLanguage = this.storageService.getItem(this.STORAGE_KEY);
  }

  private setPeriods(periods: ProductionPeriod[]): void {
    this.openPeriods = periods;
    this.selectedPeriod = periods[0];
  }

  public isSubmitDisabled(): boolean {
    return this.loadingData || (this.openPeriods?.length > 0 && isNil(this.selectedPeriod)) || !this.processWithoutPeriod;
  }

  public submitModal(): void {
    if (this.isSubmitDisabled()) {
      return;
    }

    this.startProductionOrder();
  }

  private startProductionOrder(): void {
    const trace = elastic.traceUiActivity('ModalProductionPeriodsComponent.startProductionOrder');

    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.modalProductionPeriodsService
      .startProductionOrderWithRoute(this.productionOrder, this.selectedPeriod, this.manualTransport)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          trace.end();
        })
      )
      .subscribe((route) => {
        this.navigateToRoute(route);
      });
  }

  private navigateToRoute(route: string): void {
    this.router.navigate([route]).then();
    this.ref.close(true);
  }

  public onCloseModal(): void {
    this.ref.close(false);
  }
}
