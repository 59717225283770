<div
  *ngIf="settingModel as setting"
  class="row setting"
  [ngClass]="{
    'setting__item--required': !isEnableEditSetting() && isSettingValueInvalid('required'),
    'setting__item--error': !isEnableEditSetting() && isSettingValueInvalid() && !isSettingValueInvalid('required')
  }"
  [formGroup]="form"
>
  <div (click)="toggleContent()" class="col-4 setting__item pad0">
    <i
      [ngClass]="{ 'icon-arrow-right-filled-open': isCollapsed, 'icon-arrows-right-filled-close': !isCollapsed }"
      class="collapse-icon"
    ></i>
    <span class="progress__item-title">{{ setting.settingName }}</span>
  </div>
  <div class="col-1"></div>
  <div class="col-4 pad0">
    <div class="setting__item" [ngClass]="{ 'freeze-element': isEnableEditSetting() }">
      <ng-container [ngSwitch]="setting.settingValueType">
        <lib-input-switch
          *ngSwitchCase="SETTING_FIELD_TYPES.BooleanType"
          [isMarginPaddingRequired]="true"
          (ngModelChange)="onChange($event)"
          formControlName="settingControl"
        ></lib-input-switch>
        <input
          *ngSwitchCase="SETTING_FIELD_TYPES.TimeSpanType"
          pInputText
          class="setting__item--text-right"
          formControlName="settingControl"
          (ngModelChange)="onChange($event)"
        />

        <lib-input-number
          *ngSwitchCase="SETTING_FIELD_TYPES.IntegerType"
          [inputStyle]="INPUT_STYLE"
          formControlName="settingControl"
          (ngModelChange)="onChange($event)"
        ></lib-input-number>

        <input
          *ngSwitchCase="SETTING_FIELD_TYPES.StringType"
          type="text"
          class="setting-element__item--text"
          formControlName="settingControl"
          (ngModelChange)="onChange($event)"
          pInputText
        />

        <input
          *ngSwitchCase="SETTING_FIELD_TYPES.DateTimeType"
          type="text"
          class="setting-element__item--text"
          formControlName="settingControl"
          (ngModelChange)="onChange($event)"
          pInputText
        />

        <lib-dropdown
          *ngSwitchCase="SETTING_FIELD_TYPES.EnumType"
          [options]="setting.enumerationValues"
          [inlineStyle]="INPUT_STYLE_DROPDOWN"
          formControlName="settingControl"
          (ngModelChange)="onChange($event)"
        ></lib-dropdown>

        <lib-multi-select
          *ngSwitchCase="SETTING_FIELD_TYPES.EnumMultiSelectType"
          [options]="setting.enumerationValues"
          [inlineStyle]="INPUT_STYLE_DROPDOWN"
          [filter]="false"
          (ngModelChange)="onChange($event)"
          formControlName="settingControl"
          [virtualScroll]="true"
        ></lib-multi-select>
      </ng-container>
    </div>
  </div>
  <div class="col-1"></div>
  <div class="row col-2">
    <div class="col-6"></div>
    <div class="col-6 setting__item result-component pad0">
      <div *ngIf="isAdmin && (enableEditSetting$ | async) ">
        <lib-button
          (clicked)="showHistory(setting)"
          [label]="'RESOURCE_CONFIGURATION.HISTORY' | translate"
          [typeClass]="'button--primary'"
          [sizeClass]="'button--small'"
        ></lib-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isCollapsed" class="row no-gutters setting">
  <div class="col-12 setting__item setting__item--expanded">
    {{ setting.description }}
  </div>
</div>
