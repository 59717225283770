<lib-container-pallet-template [textLabel]="'DISMOUNT_CONTAINER_FORM.LABEL' | translate">
  <!-- <lib-material-status
    [condensed]="true"
    [title]="'MOUNTING.ACTIVE_MATERIAL' | translate"
    [article]="selectedMaterial"
  ></lib-material-status> -->
  <ng-container *libContainerPalletKpi>
    <div class="kpi">
      <div>
        <i *ngIf="palletType === 'pallet'" class="icon-pallet kpi-icon"></i>
        <i *ngIf="palletType === 'roll'" class="icon-roll kpi-icon"></i>
        <i *ngIf="palletType === 'container'" class="icon-container kpi-icon"></i>
      </div>
      <div>
        <div class="kpi-label">{{ containerStatusLabel | translate }}</div>
        <div class="kpi-value">
          {{ quantity | formatQuantity }}
        </div>
      </div>
    </div>
  </ng-container>
  <lib-dismounting-details [dismountingInfo]="reduceConsumptionInfo"></lib-dismounting-details>
  <ng-container *libContainerPalletForm [formGroup]="reduceConsumptionForm">
    <app-secondary-pallet-quantity-calculation
      [formUnitLabel]="'DISMOUNTING.RETURN_QUANTITY'"
      [formControlName]="'returnQuantity'"
      [containerUnit]="quantity.unitId"
      [hasWeightEnabled]="isPaper"
      [bomUnitFactor]="bomUnitFactor"
      [inventoryUnitId]="inventoryUnitId"
      [allowQuantityChange]="allowQuantityChange$ | async"
    ></app-secondary-pallet-quantity-calculation>
  </ng-container>
  <ng-container *libContainerPalletForm>
    <div class="row" [formGroup]="reduceConsumptionForm">
      <div class="col-5 label">
        {{ 'DISMOUNT_CONTAINER_FORM.PRINT_LABEL' | translate }}
      </div>
      <div class="col-7">
        <p-checkbox binary="true" formControlName="printLabel"></p-checkbox>
      </div>
    </div>
  </ng-container>
</lib-container-pallet-template>
<lib-modal-footer
  [submitLabel]="submitLabel"
  [showDeclineButton]="false"
  [whenLoadingTopic]="LOADING_TOPIC"
  (submitClicked)="dismountSecondaryContainer()"
  [submitDisabled]="reduceConsumptionForm.invalid"
></lib-modal-footer>
