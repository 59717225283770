import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MaterialTableColumns, MaterialTableDataRow } from 'projects/chronos-shared/src/lib/models/modal-main-material-table';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['./material-table.component.scss']
})
export class MaterialTableComponent {
  @ViewChild('dt') private dataTable: Table;

  @Input() public tableColumns: MaterialTableColumns[] = [];
  @Input() public tableData: MaterialTableDataRow[] = [];
  @Input() public selectedRow;
  @Input() public set highlightAllRows(highlightAllRows: boolean) {
    this.highlightedRows = highlightAllRows;
    if (highlightAllRows) {
      this.searchBar = '';
      this.dataTable.filterGlobal('', 'contains');
    }
  }
  @Output() public selectedRowChanged: EventEmitter<any> = new EventEmitter();

  public searchBar = '';
  public highlightedRows = false;

  public emitSelectedRow(): void {
    this.selectedRowChanged.emit(this.selectedRow);
  }
}
