/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MountSecondaryContainerEntry } from '../models/mount-secondary-container-entry';
import { MountAndConsumeSecondaryContainerEntry } from '../models/mount-and-consume-secondary-container-entry';
import { DismountSecondaryContainerEntry } from '../models/dismount-secondary-container-entry';
import { SecondaryContainerBackToWarehouseEntry } from '../models/secondary-container-back-to-warehouse-entry';
import { CreateAndMountVirtualSecondaryContainerEntry } from '../models/create-and-mount-virtual-secondary-container-entry';
import { ReplaceVirtualSecondaryContainerEntry } from '../models/replace-virtual-secondary-container-entry';
import { SetPunchingPressureEntry } from '../models/set-punching-pressure-entry';
import { SecondaryMaterial } from '../models/secondary-material';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { VirtualContainerReason } from '../models/virtual-container-reason';
import { SecondaryMaterialMounted } from '../models/secondary-material-mounted';
import { VirtualContainerData } from '../models/virtual-container-data';
import { SecondaryMaterialBlock } from '../models/secondary-material-block';
import { MountingKpiValue } from '../models/mounting-kpi-value';
@Injectable({
  providedIn: 'root'
})
class SecondaryMaterialService extends __BaseService {
  static readonly mountSecondaryContainerPath = '/api/v1/SecondaryMaterial/{workCenterId}/Mount';
  static readonly consumePath = '/api/v1/SecondaryMaterial/{workCenterId}/Consume';
  static readonly dismountPath = '/api/v1/SecondaryMaterial/{mountedMaterialId}/Dismount';
  static readonly backToWarehousePath = '/api/v1/SecondaryMaterial/{mountedMaterialId}/BackToWarehouse';
  static readonly createAndMountVirtualSecondaryContainerPath =
    '/api/v1/SecondaryMaterial/{workCenterId}/CreateAndMountVirtualSecondaryContainer';
  static readonly replaceVirtualSecondaryContainerPath = '/api/v1/SecondaryMaterial/{mountedMaterialId}/ReplaceVirtualSecondaryContainer';
  static readonly setPunchingPressurePath = '/api/v1/SecondaryMaterial/{articleId}/SetPunchingPressure';
  static readonly getSecondaryMaterialPath = '/api/v1/SecondaryMaterial/{workCenterId}';
  static readonly getMaterialAvailablePath = '/api/v1/SecondaryMaterial/{workCenterId}/materialAvailable';
  static readonly getMaterialAvailableForArticlePath = '/api/v1/SecondaryMaterial/{workCenterId}/materialAvailable/articles/{articleId}';
  static readonly getMaterialAvailableForProductionOrder2Path =
    '/api/v1/SecondaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailable';
  static readonly getMaterialAvailableForProductionOrderAndArticlePath =
    '/api/v1/SecondaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailable/articles/{articleId}';
  static readonly getVirtualSecondaryContainerReasonsPath = '/api/v1/SecondaryMaterial/VirtualSecondaryContainerReasons';
  static readonly getNextSecondaryContainerForDismountPath = '/api/v1/SecondaryMaterial/{workCenterId}/NextSecondaryContainerForDismount';
  static readonly getSecondaryVirtualContainerDataPath = '/api/v1/SecondaryMaterial/virtualContainer/{ssccCode}';
  static readonly getMountedMaterials2Path = '/api/v1/SecondaryMaterial/{workCenterId}/MountedMaterials';
  static readonly toBeDismountedKpiPath = '/api/v1/SecondaryMaterial/{workCenterId}/ToBeDismountedKpi';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `SecondaryMaterialService.MountSecondaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  mountSecondaryContainerResponse(
    params: SecondaryMaterialService.MountSecondaryContainerParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.workCenterId))}/Mount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.MountSecondaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  mountSecondaryContainer(params: SecondaryMaterialService.MountSecondaryContainerParams): __Observable<null> {
    return this.mountSecondaryContainerResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SecondaryMaterialService.ConsumeParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  consumeResponse(params: SecondaryMaterialService.ConsumeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.workCenterId))}/Consume`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.ConsumeParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  consume(params: SecondaryMaterialService.ConsumeParams): __Observable<null> {
    return this.consumeResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SecondaryMaterialService.DismountParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  dismountResponse(params: SecondaryMaterialService.DismountParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.mountedMaterialId))}/Dismount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.DismountParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  dismount(params: SecondaryMaterialService.DismountParams): __Observable<null> {
    return this.dismountResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SecondaryMaterialService.BackToWarehouseParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  backToWarehouseResponse(params: SecondaryMaterialService.BackToWarehouseParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.mountedMaterialId))}/BackToWarehouse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.BackToWarehouseParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  backToWarehouse(params: SecondaryMaterialService.BackToWarehouseParams): __Observable<null> {
    return this.backToWarehouseResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SecondaryMaterialService.CreateAndMountVirtualSecondaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  createAndMountVirtualSecondaryContainerResponse(
    params: SecondaryMaterialService.CreateAndMountVirtualSecondaryContainerParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.workCenterId))}/CreateAndMountVirtualSecondaryContainer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.CreateAndMountVirtualSecondaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  createAndMountVirtualSecondaryContainer(
    params: SecondaryMaterialService.CreateAndMountVirtualSecondaryContainerParams
  ): __Observable<null> {
    return this.createAndMountVirtualSecondaryContainerResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SecondaryMaterialService.ReplaceVirtualSecondaryContainerParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  replaceVirtualSecondaryContainerResponse(
    params: SecondaryMaterialService.ReplaceVirtualSecondaryContainerParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.mountedMaterialId))}/ReplaceVirtualSecondaryContainer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.ReplaceVirtualSecondaryContainerParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  replaceVirtualSecondaryContainer(params: SecondaryMaterialService.ReplaceVirtualSecondaryContainerParams): __Observable<null> {
    return this.replaceVirtualSecondaryContainerResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SecondaryMaterialService.SetPunchingPressureParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `articleId`:
   */
  setPunchingPressureResponse(params: SecondaryMaterialService.SetPunchingPressureParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.articleId))}/SetPunchingPressure`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.SetPunchingPressureParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `articleId`:
   */
  setPunchingPressure(params: SecondaryMaterialService.SetPunchingPressureParams): __Observable<null> {
    return this.setPunchingPressureResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  getSecondaryMaterialResponse(workCenterId: number): __Observable<__StrictHttpResponse<SecondaryMaterial>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(workCenterId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecondaryMaterial>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getSecondaryMaterial(workCenterId: number): __Observable<SecondaryMaterial> {
    return this.getSecondaryMaterialResponse(workCenterId).pipe(__map((_r) => _r.body as SecondaryMaterial));
  }

  /**
   * @param workCenterId undefined
   */
  getMaterialAvailableResponse(workCenterId: number): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(workCenterId))}/materialAvailable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getMaterialAvailable(workCenterId: number): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableResponse(workCenterId).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param params The `SecondaryMaterialService.GetMaterialAvailableForArticleParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `articleId`:
   */
  getMaterialAvailableForArticleResponse(
    params: SecondaryMaterialService.GetMaterialAvailableForArticleParams
  ): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.workCenterId))}/materialAvailable/articles/${encodeURIComponent(
          String(params.articleId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.GetMaterialAvailableForArticleParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `articleId`:
   */
  getMaterialAvailableForArticle(
    params: SecondaryMaterialService.GetMaterialAvailableForArticleParams
  ): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForArticleResponse(params).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param params The `SecondaryMaterialService.GetMaterialAvailableForProductionOrder2Params` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   */
  getMaterialAvailableForProductionOrder2Response(
    params: SecondaryMaterialService.GetMaterialAvailableForProductionOrder2Params
  ): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.workCenterId))}/productionOrders/${encodeURIComponent(
          String(params.productionOrderId)
        )}/materialAvailable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.GetMaterialAvailableForProductionOrder2Params` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   */
  getMaterialAvailableForProductionOrder2(
    params: SecondaryMaterialService.GetMaterialAvailableForProductionOrder2Params
  ): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForProductionOrder2Response(params).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param params The `SecondaryMaterialService.GetMaterialAvailableForProductionOrderAndArticleParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   *
   * - `articleId`:
   */
  getMaterialAvailableForProductionOrderAndArticleResponse(
    params: SecondaryMaterialService.GetMaterialAvailableForProductionOrderAndArticleParams
  ): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/SecondaryMaterial/${encodeURIComponent(String(params.workCenterId))}/productionOrders/${encodeURIComponent(
          String(params.productionOrderId)
        )}/materialAvailable/articles/${encodeURIComponent(String(params.articleId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param params The `SecondaryMaterialService.GetMaterialAvailableForProductionOrderAndArticleParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   *
   * - `articleId`:
   */
  getMaterialAvailableForProductionOrderAndArticle(
    params: SecondaryMaterialService.GetMaterialAvailableForProductionOrderAndArticleParams
  ): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForProductionOrderAndArticleResponse(params).pipe(
      __map((_r) => _r.body as MaterialAvailableAtLocation)
    );
  }
  getVirtualSecondaryContainerReasonsResponse(): __Observable<__StrictHttpResponse<Array<VirtualContainerReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/SecondaryMaterial/VirtualSecondaryContainerReasons`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VirtualContainerReason>>;
      })
    );
  }
  getVirtualSecondaryContainerReasons(): __Observable<Array<VirtualContainerReason>> {
    return this.getVirtualSecondaryContainerReasonsResponse().pipe(__map((_r) => _r.body as Array<VirtualContainerReason>));
  }

  /**
   * @param workCenterId undefined
   */
  getNextSecondaryContainerForDismountResponse(workCenterId: number): __Observable<__StrictHttpResponse<SecondaryMaterialMounted>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(workCenterId))}/NextSecondaryContainerForDismount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecondaryMaterialMounted>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getNextSecondaryContainerForDismount(workCenterId: number): __Observable<SecondaryMaterialMounted> {
    return this.getNextSecondaryContainerForDismountResponse(workCenterId).pipe(__map((_r) => _r.body as SecondaryMaterialMounted));
  }

  /**
   * @param ssccCode undefined
   */
  getSecondaryVirtualContainerDataResponse(ssccCode: string): __Observable<__StrictHttpResponse<VirtualContainerData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SecondaryMaterial/virtualContainer/${encodeURIComponent(String(ssccCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VirtualContainerData>;
      })
    );
  }
  /**
   * @param ssccCode undefined
   */
  getSecondaryVirtualContainerData(ssccCode: string): __Observable<VirtualContainerData> {
    return this.getSecondaryVirtualContainerDataResponse(ssccCode).pipe(__map((_r) => _r.body as VirtualContainerData));
  }

  /**
   * @param workCenterId undefined
   */
  getMountedMaterials2Response(workCenterId: number): __Observable<__StrictHttpResponse<Array<SecondaryMaterialBlock>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(workCenterId))}/MountedMaterials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SecondaryMaterialBlock>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getMountedMaterials2(workCenterId: number): __Observable<Array<SecondaryMaterialBlock>> {
    return this.getMountedMaterials2Response(workCenterId).pipe(__map((_r) => _r.body as Array<SecondaryMaterialBlock>));
  }

  /**
   * @param workCenterId undefined
   */
  toBeDismountedKpiResponse(workCenterId: number): __Observable<__StrictHttpResponse<MountingKpiValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SecondaryMaterial/${encodeURIComponent(String(workCenterId))}/ToBeDismountedKpi`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MountingKpiValue>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  toBeDismountedKpi(workCenterId: number): __Observable<MountingKpiValue> {
    return this.toBeDismountedKpiResponse(workCenterId).pipe(__map((_r) => _r.body as MountingKpiValue));
  }
}

module SecondaryMaterialService {
  /**
   * Parameters for MountSecondaryContainer
   */
  export interface MountSecondaryContainerParams {
    workCenterId: number;
    entry: MountSecondaryContainerEntry;
  }

  /**
   * Parameters for Consume
   */
  export interface ConsumeParams {
    workCenterId: number;
    entry: MountAndConsumeSecondaryContainerEntry;
  }

  /**
   * Parameters for Dismount
   */
  export interface DismountParams {
    mountedMaterialId: number;
    entry: DismountSecondaryContainerEntry;
  }

  /**
   * Parameters for BackToWarehouse
   */
  export interface BackToWarehouseParams {
    mountedMaterialId: number;
    entry: SecondaryContainerBackToWarehouseEntry;
  }

  /**
   * Parameters for CreateAndMountVirtualSecondaryContainer
   */
  export interface CreateAndMountVirtualSecondaryContainerParams {
    workCenterId: number;
    entry: CreateAndMountVirtualSecondaryContainerEntry;
  }

  /**
   * Parameters for ReplaceVirtualSecondaryContainer
   */
  export interface ReplaceVirtualSecondaryContainerParams {
    mountedMaterialId: number;
    entry: ReplaceVirtualSecondaryContainerEntry;
  }

  /**
   * Parameters for SetPunchingPressure
   */
  export interface SetPunchingPressureParams {
    entry: SetPunchingPressureEntry;
    articleId: number;
  }

  /**
   * Parameters for GetMaterialAvailableForArticle
   */
  export interface GetMaterialAvailableForArticleParams {
    workCenterId: number;
    articleId: number;
  }

  /**
   * Parameters for GetMaterialAvailableForProductionOrder2
   */
  export interface GetMaterialAvailableForProductionOrder2Params {
    workCenterId: number;
    productionOrderId: number;
  }

  /**
   * Parameters for GetMaterialAvailableForProductionOrderAndArticle
   */
  export interface GetMaterialAvailableForProductionOrderAndArticleParams {
    workCenterId: number;
    productionOrderId: number;
    articleId: number;
  }
}

export { SecondaryMaterialService };
