<div *ngIf="outputUnit" class="gluing">
  <div class="pallet-card pallet-card--active">
    <div class="pallet-card__body">
      <div class="card-start-controls">
        <div class="pallet-card__labels">
          <ng-container *ngIf="containerStatus">
            <lib-status-flag
              class="status__item"
              [statusText]="containerStatus.text | translate"
              [styleClass]="containerStatus.labelClass"
            ></lib-status-flag>
          </ng-container>
          <div (click)="toggleContainer()" class="collapse-button">
            <span>
              <i [ngClass]="isExpanded ? 'icon-arrows-right-filled-close' : 'icon-arrow-right-filled-open'"></i>
            </span>
          </div>
        </div>
        <lib-pallet-number class="pallet-card__number" [useContainer]="true"></lib-pallet-number>
        <div class="quantity">
          <div class="quantity__wrapper">
            <div class="quantity__label">
              {{ 'PALLET_CARD.GOOD_QUANTITY' | translate }}
            </div>
            <div class="quantity__value">
              {{ outputUnit.goodQuantity.value | number }}
            </div>
          </div>
        </div>
        <div class="quantity">
          <div class="quantity__wrapper">
            <div class="quantity__label">&nbsp;</div>
            <div class="quantity__value">/</div>
          </div>
        </div>
        <div class="quantity quantity--left">
          <div class="quantity__wrapper">
            <div class="quantity__label">
              {{ 'PALLET_CARD.MAX_QUANTITY' | translate }}
            </div>
            <div class="quantity__value quantity-value-align">
              {{ outputUnit.containerQuantity.value | number }}
              <span class="quantity__units">{{ outputUnit.containerQuantity.unitId }}</span>
            </div>
          </div>
        </div>

        <div class="outers">
          <div class="quantity quantity--left">
            <div class="quantity__wrapper">
              <div class="quantity__label">
                {{ 'PALLET_CARD.OUTERS' | translate }}
              </div>
              <div class="outers__value quantity__value">
                {{ outputUnit.outerCurrentQuantity.value }} / {{ outputUnit.outerTotalQuantity.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="pallets">
          <div class="quantity quantity--left">
            <div class="quantity__wrapper">
              <div class="quantity__label">
                {{ 'PALLET_CARD.TARGET_QUANTITY' | translate }}
              </div>
              <div class="outers__value quantity__value">
                {{ outputUnit.palletCurrentQuantity.value }} / {{ outputUnit.palletTotalQuantity.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isExpanded" class="card-end-controls">
        <div class="quantity quantity--last">
          <div class="quantity__wrapper quantity--right">
            <app-split-button [collapsed]="true" [items]="gluingButtons"></app-split-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isExpanded">
      <div class="pallet-card__table">
        <div class="icons icons__info">
          <i class="icon-trash-small"></i>
        </div>
        <div class="table-container">
          <app-waste-assignment-table
            (rowAdded)="onRowAdded(outputUnit.productionOrderId, $event)"
            (rowRemoved)="onRowRemoved(outputUnit.productionOrderId, $event)"
            [counter]="outputUnit.goodQuantity"
            [reasonOptions]="wasteReasons"
            [wasteAndMaculatureValue]="outputUnit.wasteAssignments"
            [producedMaterialWasteStatus]="wasteStatus"
            [isSetupWastePhase]="false"
          ></app-waste-assignment-table>
        </div>
        <div class="icons icons__status"></div>
      </div>

      <app-gluing-documents
        [title]="
          'PALLET_CARD.PIECES_PER_OUTER'
            | translate
              : {
                  pieces: outputUnit.piecesPerOuter | formatQuantity
                }
        "
        [disabled]="true"
      ></app-gluing-documents>

      <div class="pallet-card__table">
        <div class="icons icons__info">
          <i class="icon-print-outline"></i>
        </div>
        <div class="table-container">
          <app-print-label
            [printerName]="outputUnit.printInfo.printerName"
            [labelsTotal]="outputUnit.printInfo.totalOutputUnits"
            [printedLabels]="outputUnit.printInfo.printedOutputUnits"
            [quantityPerOuter]="outputUnit.printInfo.quantityPerOuter"
            [defaultQuantityPerOuter]="outputUnit.printInfo.defaultQuantityPerOuter"
            (labelPrintButtonClicked)="printLabel(outputUnit.productionOrderId, $event)"
            (quantityPerOuterChanged)="quantityPerOuterChange(outputUnit.productionOrderId, $event)"
            [collapsible]="true"
          ></app-print-label>
        </div>
        <div class="icons icons__status"></div>
      </div>

      <div class="pallet-card__actions">
        <app-split-button class="pallet-card__button" [loadingTopic]="LOADING_TOPIC" [items]="gluingButtons"></app-split-button>
      </div>
    </div>
  </div>
</div>
