import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentItem } from 'chronos-core-client';

@Component({
  selector: 'app-nc-defective-item',
  templateUrl: './nc-defective-item.component.html',
  styleUrls: ['./nc-defective-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NcDefectiveItemComponent {
  @Input() public documentItem: DocumentItem;
  @Input()
  public set isActive(shouldLoad: boolean) {
    if (shouldLoad) {
      this.shouldLoad = true;
    }
  }
  @Input() public isFullscreen = false;
  @Input() public isImageClickable = false;
  @Output() public imageClicked = new EventEmitter<null>();

  public shouldLoad = false;
  public isLoading = true;
  public isError = false;

  public hideLoader(): void {
    this.isLoading = false;
  }

  public setError(): void {
    this.isError = true;
    this.hideLoader();
  }

  public isPreviewAvailable(): boolean {
    return !this.isError;
  }

  public onImageClick() {
    this.imageClicked.emit();
  }
}
