import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QaCheckService } from '@app/core/services';
import { Container, ProductionQaCheck } from 'chronos-core-client';

@Component({
  selector: 'app-mounted-container-qa-check',
  templateUrl: './mounted-container-qa-check.component.html',
  styleUrls: ['./mounted-container-qa-check.component.scss']
})
export class MountedContainerQaCheckComponent implements OnInit {
  public qaChecks: ProductionQaCheck[] = null;
  public container: Container;
  public form: UntypedFormGroup;
  public waste = '-';
  public sequenceNumber;

  constructor(private conf: DynamicDialogConfig, private ref: DynamicDialogRef, public qaCheckService: QaCheckService) {}

  public ngOnInit(): void {
    this.container = this.conf.data.container;
    this.qaChecks = this.container.qaChecks;
    this.sequenceNumber = this.conf.data.sequenceNumber;
    this.form = this.qaCheckService.qaChecksFormControlsInit(this.qaChecks);
  }

  public onSubmitClick(): void {
    this.ref.close(false);
  }
}
