import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TabOverviewService } from '@app/modules/mounting/services';
import {
  BillOfMaterial,
  KpiIdentifier,
  MachineSchedule,
  MaterialType,
  MountContainerResponse,
  MountPrimaryContainerResponse
} from 'chronos-core-client';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ActiveOrderQuery } from '@app/core/global-state';
import { KpiActionBase, KpiActionFactoryService } from '@app/core/services';
import { AppSettingsQuery, LoadingNotificationService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { ModalMainMaterialComponent } from '@app/shared/components/modal-main-material/modal-main-material.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @Input() public orderItem: MachineSchedule;
  @Input() public billOfMaterials: BillOfMaterial[];

  @Output() public scanCompleted = new EventEmitter<number>();
  @Output() public refreshSidebar = new EventEmitter<boolean>();

  private subscriptions = new Subscription();
  private scanValidationSubject = new BehaviorSubject(true);
  public scanValidationChanges$: Observable<boolean> = this.scanValidationSubject.asObservable();

  public showInactive: boolean;
  public secondaryMaterialAction: KpiActionBase;
  public secondaryMaterialToDismountCount$: Observable<number>;
  public isSecondaryMaterialDisplayEnabled = false;
  public totalContainers: number;
  private submitLabel = 'MOUNTING.MOUNT';

  constructor(
    private tabOverviewService: TabOverviewService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private appSettingsQuery: AppSettingsQuery,
    private kpiActionFactoryService: KpiActionFactoryService,
    public loadingService: LoadingNotificationService,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  public ngOnInit(): void {
    this.showInactive = this.appSettingsQuery.showInactiveElement();
    this.tabOverviewService.totalContainerCountSubject$.subscribe((containerCount) => {
      this.totalContainers = containerCount;
    });
    this.subscriptions.add(
      this.getSecondaryMaterialDisplayEnabled().subscribe((isEnabled) => {
        this.isSecondaryMaterialDisplayEnabled = isEnabled;
        this.initSecondaryMaterialDismount();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onDismountSecondaryMaterial(): void {
    this.secondaryMaterialAction.dismount();
  }

  public mountContainer(identificationCode: string): void {
    this.tabOverviewService
      .scanIdentificationCode(identificationCode)
      .pipe(switchMap(() => this.mountPrimaryOrSecondary(identificationCode, null))) // materialType = null for overview page: variable mounting on primary and secondary materials
      .subscribe(
        (container) => {
          this.scanComplete(container);
        },
        () => {
          this.scanValidation(false);
        }
      );
  }

  public openMachineLocationModal(): void {
    const tabName = 'Overview';
    if (this.orderItem) {
      this.dialogService
        .open(ModalMainMaterialComponent, {
          header: this.translateService.instant('MOUNTING.MACHINE_LOCATION_MODAL', {
            workCenter: this.appSettingsQuery.getWorkCenterName()
          }),
          data: {
            tabName,
            productionOrderId: this.orderItem.productionOrderId,
            submitLabel: this.submitLabel
          }
        })
        .onClose.subscribe((mounted: boolean) => {
          this.refreshSidebar.emit(mounted);
        });
    }
  }

  private mountPrimaryOrSecondary(identificationCode: string, materialType?: MaterialType): Observable<MountContainerResponse> {
    return this.tabOverviewService.mountContainer(this.orderItem.productionOrderId, materialType, identificationCode);
  }

  private scanComplete(container?: MountPrimaryContainerResponse): void {
    this.scanCompleted.emit(container?.productionOrderId);
    this.scanValidation(true);
  }

  private scanValidation(isValid: boolean): void {
    this.scanValidationSubject.next(isValid);
  }

  private initSecondaryMaterialDismount(): void {
    if (!this.isSecondaryMaterialDisplayEnabled) {
      return;
    }

    this.secondaryMaterialAction = this.kpiActionFactoryService.createKpiAction(KpiIdentifier.CONSUMPTION_SECONDARY_LAMINATION);
    this.renewSecondaryMaterialToDismount();

    this.subscriptions.add(
      this.loadingService.getNotifications(notificationTopic.modalReduceConsumption).subscribe((isLoading) => {
        if (!isLoading) {
          this.renewSecondaryMaterialToDismount();
        }
      })
    );
  }

  private renewSecondaryMaterialToDismount(): void {
    this.secondaryMaterialToDismountCount$ = this.tabOverviewService.getSecondaryToDismount();
  }

  private getSecondaryMaterialDisplayEnabled(): Observable<boolean> {
    return this.activeOrderQuery.activeOrder$.pipe(map(() => this.appSettingsQuery.isSecondaryMaterialDisplayEnabled()));
  }
}
