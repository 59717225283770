<header class="header">
  <div class="header__section">
    <ng-content select="lib-title"></ng-content>
  </div>

  <div class="header__section header__section--log">
    <ng-content select="lib-info-log"></ng-content>
  </div>

  <div class="header__section">
    <ng-content select="lib-environment"></ng-content>
  </div>

  <div class="header__section">
    <ng-content select="lib-multi-language"></ng-content>
  </div>

  <div class="header__section">
    <ng-content select="lib-user"></ng-content>
  </div>

  <div class="header__section">
    <ng-content select="lib-version-number"></ng-content>
  </div>
</header>
