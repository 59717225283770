import { Component, OnInit } from '@angular/core';
import { ToolDsService, VirtualContainerDsService } from '@app/core/data-services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Dummy, ListValue, LoadingNotificationService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { finalize } from 'rxjs/operators';
import { PrimaryMaterialBlock } from 'chronos-core-client';
export interface ArticleDescriptionElement {
  articleName: string;
  productType: any;
  articleClassification: any;
  bomUnitId: string;
  configurationName?: string;
  externalArticleId: string;
  externalConfigurationId?: string;
  grammage?: number;
  id: number;
  inventoryUnitId: string;
  labelAutoPrint: boolean;
  thickness?: number;
  label: string;
  value: number | string;
}

@Component({
  selector: 'app-dummy-tool-modal',
  templateUrl: './dummy-tool-modal.component.html',
  styleUrls: ['./dummy-tool-modal.component.scss']
})
export class DummyToolModalComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalCreateDummy;

  public reasonsOptions$: Observable<ListValue[]>;
  public dummyTool: Dummy = this.config.data.dummyTool;
  public showQuantity: boolean = this.config.data.showQuantity;

  public activeMaterialBlock: PrimaryMaterialBlock;
  public articleOptions: ArticleDescriptionElement[];
  public currentWorkCenterId: number;
  public articleId: number;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private toolDsService: ToolDsService,
    private virtualContainerDsService: VirtualContainerDsService
  ) {}

  public ngOnInit(): void {
    this.reasonsOptions$ = this.toolDsService.getVirtualToolReasons();
    this.articleOptions = this.config.data?.secondaryAlternativeArticles;
  }

  public mountDummyTool(dummyTool: Dummy): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.toolDsService
      .createVirtualTool({
        virtualContainerReasonId: dummyTool.reasonId,
        materialBlockId: dummyTool.materialBlockId,
        identificationCode: dummyTool.code,
        info: dummyTool.reasonInfo,
        containerQuantity: dummyTool.mounted
      })
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close();
      });
  }

  public updateDummyTool(dummyTool: Dummy): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.virtualContainerDsService
      .changeVirtualContainerInfo(this.dummyTool.containerId, dummyTool.reasonInfo)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close();
      });
  }

  public updateDummyQuantity(ssccCode: string): void {
    if (ssccCode) {
      this.virtualContainerDsService.getSuggestedQuantityForMounting(ssccCode).subscribe((data) => {
        const bomGoodQuantity = data.bomGoodQuantity?.value;
        const reasonInfo = data.article
          ? `${data.article.externalArticleId} ${data.article.articleName} ` +
            `${data.article.externalConfigurationId} ${data.article.configurationName}`
          : undefined;

        if (bomGoodQuantity) {
          this.dummyTool = { ...this.dummyTool, mounted: bomGoodQuantity, reasonInfo };
        }
      });
    }
  }
}
