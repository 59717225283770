import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RouterModule } from '@angular/router';
import { applicationPaths } from './api-authorization.constants';
import { HttpClientModule } from '@angular/common/http';
import { AuthorizeService } from './authorize.service';
import { ApiAuthorizationConfig } from './api-authorization.config';

export interface ApiAuthorizationModuleConfiguration {
  authority: string;
  clientId: string;
  scope?: string;
  responseType?: string;
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forChild([
      { path: applicationPaths.Login, component: LoginComponent },
      { path: applicationPaths.LoginFailed, component: LoginComponent },
      { path: applicationPaths.LoginCallback, component: LoginComponent },
      { path: applicationPaths.LogOut, component: LogoutComponent },
      { path: applicationPaths.LoggedOut, component: LogoutComponent },
      { path: applicationPaths.LogOutCallback, component: LogoutComponent }
    ])
  ],
  declarations: [LoginComponent, LogoutComponent],
  exports: [LoginComponent, LogoutComponent]
})
export class ApiAuthorizationModule {
  public static forRoot(configuration: ApiAuthorizationModuleConfiguration): ModuleWithProviders<ApiAuthorizationModule> {
    return {
      ngModule: ApiAuthorizationModule,
      providers: [
        AuthorizeService,
        {
          provide: ApiAuthorizationConfig,
          useValue: {
            authority: configuration.authority,
            clientId: configuration.clientId,
            responseType: configuration.responseType ?? 'code',
            scope: configuration.scope ?? 'openid profile email roles'
          } as ApiAuthorizationConfig
        }
      ]
    };
  }
}
