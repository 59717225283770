import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-active-pallet-animation',
  templateUrl: './active-pallet-animation.component.html',
  styleUrls: ['./active-pallet-animation.component.scss']
})
export class ActivePalletAnimationComponent {
  @Input() public isAnimationPlay: boolean;
}
