import { finalize } from 'rxjs/operators';
import { MountedMaterialForwardSearchTrace, ProductionOrderBackwardSearchTrace } from 'chronos-core-client';
import { TreeNode } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import moment from 'moment-mini';
import { SelectItem } from 'primeng/api';
import { ForwardSearchResultChildItems, TraceabilitySearchModeOption } from '../../../models/traceability-searchmode-option';
import { TraceabilityDetailsService } from '@app/modules/operation-support/services/Traceability/Traceability.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-traceability',
  templateUrl: './traceability.component.html',
  styleUrls: ['./traceability.component.scss']
})
export class TraceabilityComponent implements OnInit {
  public traceabilitySearchModeOption: SelectItem[];
  public selectedSearchMode: SelectItem;
  public setupStartTime: Date;
  public setupEndTime: Date;
  public textQuery? = '';
  public searchPlaceHolder = '';
  public maxDate: Date;
  public treeTableData: TreeNode[];
  public isNotLoading = true;
  public isNodeLoadingSubject = new BehaviorSubject<boolean>(false);
  public isNodeLoading$ = this.isNodeLoadingSubject.asObservable();

  constructor(private translateService: TranslateService, private traceabilityDetailsService: TraceabilityDetailsService) {}

  public ngOnInit(): void {
    this.traceabilitySearchModeOption = [
      { label: this.translateService.instant('TRACEABILITY.FORWARD_SEARCH'), value: TraceabilitySearchModeOption.ForwardSearch },
      { label: this.translateService.instant('TRACEABILITY.BACKWARD_SEARCH'), value: TraceabilitySearchModeOption.BackwardSearch }
    ];
    this.searchPlaceHolder = 'TRACEABILITY.FORWARD_SEARCH_PLACEHOLDER';
    this.setupStartTime = moment(new Date()).subtract(1, 'month').toDate();
    this.setupEndTime = this.maxDate = new Date();
    this.selectedSearchMode = this.traceabilitySearchModeOption[0].value;
  }

  public onFilterChange(): void {
    this.searchPlaceHolder =
      this.selectedSearchMode === this.traceabilitySearchModeOption[0].value
        ? 'TRACEABILITY.FORWARD_SEARCH_PLACEHOLDER'
        : 'TRACEABILITY.BACKWARD_SEARCH_PLACEHOLDER';
    this.treeTableData = null;
  }

  public onStartDateChange(): void {
    this.setupStartTime = this.setupStartTime > this.setupEndTime ? this.setupEndTime : this.setupStartTime;
  }

  public onEndDateChange(): void {
    this.setupEndTime =
      this.setupStartTime < this.setupEndTime ? (this.setupEndTime > this.maxDate ? this.maxDate : this.setupEndTime) : this.setupStartTime;
  }

  public submitFilters(): void {
    this.isNotLoading = false;
    if (this.selectedSearchMode === this.traceabilitySearchModeOption[0].value) {
      this.traceabilityDetailsService
        .getMountedMaterialsForForwardSearch(this.setupStartTime.toISOString(), this.textQuery, this.setupEndTime.toISOString())
        .pipe(finalize(() => (this.isNotLoading = true)))
        .subscribe((data) => (this.treeTableData = data));
    } else {
      this.traceabilityDetailsService
        .getProductionOrdersForBackwardSearch(this.setupStartTime.toISOString(), this.textQuery, this.setupEndTime.toISOString())
        .pipe(finalize(() => (this.isNotLoading = true)))
        .subscribe((data) => (this.treeTableData = data));
    }
  }

  public loadNode(event: any): void {
    if (event?.node?.data?.itemType === ForwardSearchResultChildItems.FinishedGood) {
      this.isNodeLoadingSubject.next(true);
      this.traceabilityDetailsService
        .getMountedMaterialTraceForForwardSearch(event?.node?.parent?.data?.productionOrderId, 'Overview')
        .pipe(
          finalize(() => {
            this.isNodeLoadingSubject.next(false);
          })
        )
        .subscribe((data) => {
          this.addForwardDetailsDataToTraceabilityData(event?.node?.parent?.data?.productionOrderId, data);
        });
    }
    if (event?.node?.data?.itemType === ForwardSearchResultChildItems.ProductionOrderDetails) {
      this.isNodeLoadingSubject.next(true);
      this.traceabilityDetailsService
        .getProductionOrderTraceForBackwardSearch(event?.node?.parent?.data?.productionOrderId, 'Overview')
        .pipe(
          finalize(() => {
            this.isNodeLoadingSubject.next(false);
          })
        )
        .subscribe((data) => {
          this.addBackwardDetailsDataToTraceabilityData(event?.node?.parent?.data?.productionOrderId, data);
        });
    }
  }

  private addForwardDetailsDataToTraceabilityData(productionOrderId: number, data: MountedMaterialForwardSearchTrace[]): void {
    this.treeTableData = this.traceabilityDetailsService.mapForwardDetailsDataToTraceabilityData(
      this.treeTableData,
      data,
      productionOrderId
    );
  }

  private addBackwardDetailsDataToTraceabilityData(productionOrderId: number, data: ProductionOrderBackwardSearchTrace[]): void {
    this.treeTableData = this.traceabilityDetailsService.mapBackwardDetailsDataToTraceabilityData(
      this.treeTableData,
      data,
      productionOrderId
    );
  }
}
