import { Injectable } from '@angular/core';
import { KpiActionBase } from './kpi-action-base';
import { nav } from '@app/shared/utils';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class KpiActionConsumptionSecondaryService extends KpiActionBase {
  constructor(private router: Router) {
    super();
  }

  public execute(): void {
    this.router.navigate([nav.routes.mountingSecondary]);
  }
}
