<div
  class="status"
  [ngClass]="{ 'status--small': size === 'small', 'status--tiny': size === 'tiny', 'status--tiny-plain': size === 'tiny-plain' }"
>
  <div [ngClass]="styleClass || 'status__label--active'" class="status__label">
    <div class="status__label--grow">{{ statusText }}</div>
    <div class="status__icon" *ngIf="isAutomated">
      <i class="icon-automated"></i>
    </div>
  </div>
</div>
