import { Status } from '@app/shared/models/status';
import { NonConformanceType, ProducedMaterialStatus, RemarkType, QaCheckResult } from 'chronos-core-client';

export const LAST_FLAG_STATUS = 'lastFlagStatus';
export interface StatusParameter {
  labelClass: string;
  stripClass?: string;
  size?: string;
  text: string;
}

export interface StatusParameters {
  [statusName: string]: StatusParameter;
}

export const productionOrderStatusFlags: StatusParameters = {
  [Status.Active]: {
    labelClass: 'status--active',
    text: 'STATUS.ACTIVE'
  },
  [Status.Checked]: {
    labelClass: 'status--checked',
    text: 'STATUS.CHECKED'
  },
  [Status.Open]: {
    labelClass: 'status--open',
    text: 'STATUS.RELEASED'
  },
  [Status.Reopened]: {
    labelClass: 'status--open',
    text: 'STATUS.REOPENED'
  },
  [Status.Next]: {
    labelClass: 'status--next',
    text: 'STATUS.NEXT'
  },
  [Status.Finished]: {
    labelClass: 'status--ended',
    text: 'STATUS.ENDED'
  },
  [Status.Interrupted]: {
    labelClass: 'status--ended',
    text: 'STATUS.INTERRUPTED'
  },
  [Status.None]: {
    labelClass: 'status--ended',
    text: 'STATUS.NONE'
  }
};

export const producedMaterialStatusFlags: StatusParameters = {
  [ProducedMaterialStatus.ACTIVE]: {
    labelClass: 'status--active',
    text: 'PRODUCED_MATERIAL_STATUS.ACTIVE'
  },
  [ProducedMaterialStatus.FINISHED]: {
    labelClass: 'status--finished',
    text: 'PRODUCED_MATERIAL_STATUS.FINISHED'
  },
  [ProducedMaterialStatus.NONE]: {
    labelClass: 'status--none',
    text: 'PRODUCED_MATERIAL_STATUS.NONE'
  },
  [ProducedMaterialStatus.OPEN]: {
    labelClass: 'status--open',
    text: 'PRODUCED_MATERIAL_STATUS.OPEN'
  },
  [LAST_FLAG_STATUS]: {
    labelClass: 'status--last',
    text: 'PRODUCED_MATERIAL_STATUS.LAST',
    stripClass: 'status__strip--last'
  }
};

export const taskStatusFlags: StatusParameters = {
  [Status.Downtime]: {
    labelClass: 'status--downtime',
    text: 'STATUS.DOWNTIME'
  }
};

export const shopFloorRemarkFlag = {
  labelClass: 'status--production-order-info-dc',
  translation: 'REMARK_STATUS.PRODUCTION_ORDER_INFO_SHOP_FLOOR'
};

export const remarkStatusFlags: StatusParameters = {
  [RemarkType.PRODUCTION_ORDER_INFO_SHOP_FLOOR]: {
    labelClass: 'status--production-order-info-dc',
    text: 'REMARK_STATUS.PRODUCTION_JOB_INFO_DC'
  },
  [RemarkType.PRODUCTION_JOB_INFO_PL]: {
    labelClass: 'status--production-order-info-pl',
    text: 'REMARK_STATUS.PRODUCTION_JOB_INFO_PL'
  },
  [RemarkType.PRODUCTION_KPI_INFO]: {
    labelClass: 'status--production-order-info-dc',
    text: 'REMARK_STATUS.PRODUCTION_KPI_INFO'
  },
  [RemarkType.PRODUCTION_ORDER_INFO_PL]: {
    labelClass: 'status--production-order-info-pl',
    text: 'REMARK_STATUS.PRODUCTION_ORDER_INFO_PL'
  },
  [RemarkType.VIRTUAL_CONTAINER_INFO]: {
    labelClass: 'status--virtual-container-info',
    text: 'REMARK_STATUS.VIRTUAL_CONTAINER_INFO'
  }
};

export const ncStatusFlags: StatusParameters = {
  [NonConformanceType.DEBITOR]: {
    labelClass: 'status--client',
    text: 'NC_TYPE.DEBITOR'
  },
  [NonConformanceType.KREDITOR]: {
    labelClass: 'status--client',
    text: 'NC_TYPE.KREDITOR'
  },
  [NonConformanceType.PRODUCTION]: {
    labelClass: 'status--production',
    text: 'NC_TYPE.PRODUCTION'
  }
};

export const palletQualtityStatusFlags: StatusParameters = {
  [QaCheckResult.OK]: {
    labelClass: 'status__label--ok-active',
    text: 'OK'
  },
  [QaCheckResult.NOT_OK]: {
    labelClass: 'status__label--nok-active',
    text: 'NOK'
  },
  [QaCheckResult.NOT_APPLICABLE]: {
    labelClass: 'status__label--na-active',
    text: 'NA'
  },
  [QaCheckResult.WARNING_OK]: {
    labelClass: 'status__label--wok-active',
    text: 'WOK'
  }
};
