/* tslint:disable */
type ShutDownTimeType = 'None' | 'ByLastItem' | 'ByNow';
module ShutDownTimeType {
  export const NONE: ShutDownTimeType = 'None';
  export const BY_LAST_ITEM: ShutDownTimeType = 'ByLastItem';
  export const BY_NOW: ShutDownTimeType = 'ByNow';
  export function values(): ShutDownTimeType[] {
    return [NONE, BY_LAST_ITEM, BY_NOW];
  }
}

export { ShutDownTimeType };
