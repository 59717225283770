import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NonConformanceModel } from 'chronos-core-client';

@Component({
  selector: 'app-nc-defective-quantity',
  templateUrl: './nc-defective-quantity.component.html',
  styleUrls: ['./nc-defective-quantity.component.scss']
})
export class NcDefectiveQuantityComponent {
  @Input() public nonConformanceModel: NonConformanceModel;

  @Input() public isCreateNCMode: boolean;

  @Output() public quantityChanged = new EventEmitter<number>();

  public kpiValueChanged(kpiValueChanged: number): void {
    this.quantityChanged.emit(kpiValueChanged);
  }
}
