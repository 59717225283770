import { Component, OnInit, Input, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import {
  SpeedMonitorViewMode,
  WorkCenterDetails,
  ValuePropertyBag,
  StateTypes,
  WorkCenter,
  KPIInfoWithOrder,
  KPI,
  KPIInfo,
  KPIValueBag
} from './models/speed-monitor.model';
import { Subscription } from 'rxjs';
import { SpeedMonitorService } from './services/speed-monitor.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AvailableKpisResult,
  KpiIdentifier,
  MachineData,
  MachineDowntimeData,
  MachineIdleData,
  MachineRunData,
  MachineSetupData,
  WorkCenterService
} from 'chronos-core-client';
import { forEach } from 'lodash';

@Component({
  selector: 'lib-speed-monitor',
  templateUrl: './speed-monitor.component.html',
  styleUrls: ['./speed-monitor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpeedMonitorComponent implements OnInit, OnDestroy {
  @ViewChild('SpeedMonitor') public speedMonitor;
  @Input() public mode: SpeedMonitorViewMode = SpeedMonitorViewMode.FullScreen;
  @Input() public workCenterId: number;
  @Input() public kpisList: KPIInfoWithOrder[];
  @Input() public displayTime: number;
  public kpisListTest: KPIInfo[] = [];
  public kpisListWithOrder: KPIInfoWithOrder[] = [];
  public maxOrder: number;
  public noOfTasks: number;
  public noOfStoppers: number;
  public kpis: KPI[] = [];
  private activeSubscriptions: Subscription[] = [];
  public isRunMode = false;
  public isDowntimeMode = false;
  public isSetupMode = false;
  public isIdleMode = false;
  public workCenter: WorkCenterDetails = {};
  public isDisplayInfo = false;
  public timeoutHandler: any;
  public timeoutHandlerOne: any;
  public valuePropertyBag: ValuePropertyBag = {};
  public kpiValueBag: KPIValueBag = {};
  public milliseconds: number;
  public deltaTime: number;
  public speed: number;
  public activeWorkcenters: WorkCenter[] = [];
  public kpiIdentifierResult: AvailableKpisResult;
  public externalWorkCenterId: string;
  public machineData: any;
  public isTvMode = false;
  private selectedWorkCenter: WorkCenter;
  private kpiIdentifierList: string[];
  private currentIndex = 0;
  private intervalId: any;
  private readonly KPI_DISPLAY_TIME: number = 30000; // 30 seconds
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/fullscreenchange_event
  public get isFullScreen(): boolean {
    return document.fullscreenElement ? true : false;
  }

  constructor(
    private speedMonitorService: SpeedMonitorService,
    private workCenterService: WorkCenterService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.getWorkCenterDetails();
  }

  /* Initialize the work center that is shown */
  public async getWorkCenterDetails() {
    try {
      // TV mode is a full screen mode that showns only the speed monitor of a single workcenter, no other components.
      this.isTvMode = !!this.activatedRoute.snapshot.data.isTvMode;

      // load data necessary for the the input parameter validation
      const workCenterList = await this.speedMonitorService.getWorkCenterList().toPromise();

      // validate the input: make sure that the given work center id is actually available in Chronos
      // determine the workcenter to show. If the workcenter id is provided from the route, use that one
      const externalWorkCenterIdFromRoute = this.activatedRoute.snapshot.params.externalWorkCenterId;
      this.selectedWorkCenter = this.FindWorkCenterByExternalId(workCenterList, externalWorkCenterIdFromRoute);

      // determine the workcenter to show. If it is given as parameter, use it TODO: is that field filled from external?
      if (this.externalWorkCenterId) {
        this.selectedWorkCenter = this.FindWorkCenterByExternalId(workCenterList, this.externalWorkCenterId);
      }

      // otherwise: use the last work center the user has selected
      if (!this.selectedWorkCenter && history.state.flag) {
        const workCenterId = history.state.workCenterId;
        this.selectedWorkCenter = this.FindWorkCenterById(workCenterList, workCenterId);
      }

      // otherwise: use the work center id set in this.workCenterId
      if (!this.selectedWorkCenter && this.workCenterId) {
        this.selectedWorkCenter = this.FindWorkCenterById(workCenterList, this.workCenterId);
      }

      // TODO: there is a default work center configuration in Live! Is that used anywhere?
    } catch (error) {
      console.error('Error while preparing speed monitor', error);
    }

    if (!this.selectedWorkCenter) {
      console.info('Speed montior: No work center selected, quitting');
      return;
    }

    this.workCenterId = this.workCenterId ?? this.selectedWorkCenter.id;
    this.externalWorkCenterId = this.selectedWorkCenter.externalWorkCenterId;

    this.setKPIListDetails(); // prepares KPI identifiers.
    this.setMachineCounterValues();

    const activeSubscription = this.speedMonitorService.getWorkCenter(this.workCenterId).subscribe((workCenterDetails) => {
      if (workCenterDetails) {
        this.workCenter.workCenterId = workCenterDetails.externalWorkCenterId;
        this.workCenter.workCenterName = workCenterDetails.name;

        const subscription = this.speedMonitorService.getWorkCenterDetails(this.workCenterId).subscribe((workCenterData) => {
          if (workCenterData) {
            this.setWorkCenterDetails(workCenterData);
          }
        });
        this.activeSubscriptions.push(subscription);
      }
    });
    this.activeSubscriptions.push(activeSubscription);
  }

  /* Set Machine Counter Values */
  public setMachineCounterValues() {
    // subscribe to the counter changed notifications and update the UI in run mode
    const counterSubscription = this.speedMonitorService.getCounterNotifications(this.workCenterId).subscribe((result) => {
      if (result) {
        this.speed = result.speed;
        if (this.isRunMode) {
          this.valuePropertyBag = {
            speed: { value: result.speed, unitId: this.valuePropertyBag.unit },
            targetSpeed: { value: result.targetValue ?? this.valuePropertyBag.targetSpeed.value, unitId: this.valuePropertyBag.unit },
            unit: this.valuePropertyBag.unit,
            mode: this.mode
          };
        }
      }
    });

    // the KPIs are only visible in fullscreenmode or TV mode, therefore call the backend only when the KPIs are indeed shown.
    if (this.isTvMode || this.isFullScreen) {
      this.startProcessingKPI(); // fetches data for KPI in interval.
    }

    this.activeSubscriptions.push(counterSubscription);

    const stateSubscription = this.speedMonitorService.getStateNotifications(this.workCenterId).subscribe((result) => {
      if (result && this.workCenter.state !== result.state) {
        this.setWorkCenterDetails(result);
      }
    });
    this.activeSubscriptions.push(stateSubscription);
  }

  /* Set Value property Bag */
  public setValuePropertyBag(modewiseData?: MachineData) {
    this.isDowntimeMode = this.isSetupMode = this.isIdleMode = false;
    if (this.workCenter.state) {
      switch (this.workCenter.state.toLowerCase()) {
        case StateTypes.Run.toLowerCase():
          this.setRunData(modewiseData.runData);
          break;
        case StateTypes.Downtime.toLowerCase():
          this.setDowntimeData(modewiseData.downtimeData);
          break;
        case StateTypes.Setup.toLowerCase():
          this.setSetupData(modewiseData.setupData);
          break;
        case StateTypes.Idle.toLowerCase():
          this.setIdleData();
          break;
      }
    }
  }

  private setRunData(runData: MachineRunData) {
    this.valuePropertyBag = {
      unit: runData.unit,
      speed: { value: runData.speed, unitId: runData.unit },
      targetSpeed: { value: Math.ceil(runData.targetSpeed), unitId: runData.unit },
      mode: this.mode
    };
  }

  private setDowntimeData(downtimeData: MachineDowntimeData) {
    this.valuePropertyBag = {
      reasonCode: downtimeData.code,
      reasonText: downtimeData.description,
      duration: this.milliseconds,
      estimatedDuration: this.formatTimespan(downtimeData.estimatedDuration),
      mode: this.mode
    };
  }

  private setSetupData(setupData: MachineSetupData) {
    this.valuePropertyBag = {
      duration: this.milliseconds,
      estimatedDuration: this.formatTimespan(setupData.estimatedDuration),
      mode: this.mode
    };
  }

  private setIdleData() {
    this.valuePropertyBag = {
      duration: this.milliseconds,
      mode: this.mode
    };
  }

  /* Set WorkCenter Details */
  public setWorkCenterDetails(workCenterData: MachineData) {
    this.workCenter.state = workCenterData.state;
    this.workCenter.serverUtcTime = workCenterData.serverUtcTime;
    const requestTimeBrowser = this.speedMonitorService.requestTimeBrowser;
    const deltaTime = this.speedMonitorService.deltaTimeCalc(requestTimeBrowser, this.workCenter.serverUtcTime);
    this.deltaTime = deltaTime > 0 ? deltaTime : 0;
    if (workCenterData.infoData && this.mode === SpeedMonitorViewMode.FullScreen) {
      this.workCenter.infoData = {
        message: workCenterData.infoData.message,
        displayDurationSeconds: workCenterData.infoData.displayDurationSeconds || 0,
        displayIntervalSeconds: workCenterData.infoData.displayIntervalSeconds || 0
      };
      this.setDisplayInformation(this.workCenter.infoData);
      this.speedMonitorService.workCenterDetails.next(this.workCenter);
    }
    if (this.workCenter.state) {
      switch (this.workCenter.state.toLowerCase()) {
        case StateTypes.Run.toLowerCase():
          this.isRunMode = true;
          this.isDowntimeMode = this.isSetupMode = this.isIdleMode = false;
          this.setRunData(workCenterData.runData);
          break;
        case StateTypes.Downtime.toLowerCase():
          this.setDowntimeModeWorkCenterDetails(workCenterData.downtimeData);
          break;
        case StateTypes.Setup.toLowerCase():
          this.setSetupModeWorkCenterDetails(workCenterData.setupData);
          break;
        case StateTypes.Idle.toLowerCase():
          this.setIdleModeWorkCenterDetails(workCenterData.idleData);
          break;
      }
    }
  }

  public setDisplayInformation(displayInfoData: any): void {
    if (displayInfoData && displayInfoData.message && displayInfoData.displayDurationSeconds > 0) {
      this.timeoutHandler = setTimeout(() => {
        this.isDisplayInfo = true;
        this.timeoutHandlerOne = setTimeout(() => {
          this.isDisplayInfo = false;
          this.setDisplayInformation(displayInfoData);
        }, displayInfoData.displayDurationSeconds * 1000);
      }, displayInfoData.displayIntervalSeconds * 1000);
    } else {
      this.isDisplayInfo = false;
    }
  }

  /* Set Run Mode Data */
  public setKPIListDetails(): void {
    if (this.kpisList === null || this.kpisList === undefined) {
      this.prepareKpiListObject();
    }
    this.prepareKpiIdentifiers();
  }

  public startProcessingKPI() {
    this.intervalId = setInterval(() => {
      this.getKpiValues(this.kpiIdentifierList[this.currentIndex]);
      if (this.currentIndex >= this.kpiIdentifierList.length) {
        this.currentIndex = 0; // Reset the interval when all values have been processed.
      }
    }, this.displayTime ?? this.KPI_DISPLAY_TIME);
  }

  public getListofKpis(kpilisttest: KPIInfoWithOrder[]) {
    this.maxOrder = Math.max(...kpilisttest.map((o) => o.order ?? -1));
    this.kpisList = kpilisttest.map((kpi) => {
      kpi.hidden = false;
      kpi.order = kpi.order ?? ++this.maxOrder;
      return kpi;
    });
  }

  public prepareKpiListObject() {
    let i = 0;
    Object.keys(KpiIdentifier).forEach((key) => {
      if (key !== 'values') {
        this.kpisListTest[i] = { id: key, name: KpiIdentifier[key] };
        i++;
      }
    });
    this.getListofKpis(this.kpisListTest);
  }

  // returns list of KPI's
  public prepareKpiIdentifiers() {
    const kpiIdentifiers: string[] = [];
    if (this.kpisList) {
      const kpiNames = this.kpisList.map((kpi) => {
        if (kpi.id === Object.keys(KpiIdentifier).find((key) => kpi.id === key && key !== 'values')) {
          return kpi.name;
        }
      });
      forEach(kpiNames, (kpiName) => {
        kpiIdentifiers.push(kpiName);
      });
    }

    this.kpiIdentifierList = kpiIdentifiers;
  }

  /* KPI endpoint call */
  public getKpiValues(kpiIdentifiers: any) {
    const params: WorkCenterService.GetKpisParams = {
      workCenterId: this.workCenterId,
      kpiIdentifiers: [kpiIdentifiers] as KpiIdentifier[]
    };

    this.workCenterService.getKpis(params).subscribe((result) => {
      if (result && result.length > 0) {
        this.currentIndex++;
        let kpiWithValue: KPI;
        this.kpis = [];
        result.forEach((kpivalue) => {
          this.kpisList.forEach((kpi) => {
            if (kpivalue.kpiIdentifier === kpi.name) {
              kpiWithValue = {
                id: kpi.id,
                name: kpiIdentifiers,
                value: kpivalue.value?.value,
                valueTime: this.formatTimespan(kpivalue.valueTime),
                order: kpi.order
              };
            }
          });
          this.kpis.push(kpiWithValue);
        });

        this.kpiValueBag = {
          kpiValue: this.kpis
        };
      } else {
        this.currentIndex++;
        if (this.currentIndex >= this.kpiIdentifierList.length) {
          this.currentIndex = 0;
        }
        this.getKpiValues(this.kpiIdentifierList[this.currentIndex]);
      }
    });
  }

  /* Set Downtime Mode Data */
  public setDowntimeModeWorkCenterDetails(downtimeModeData: MachineDowntimeData): void {
    this.isDowntimeMode = true;
    this.isRunMode = false;
    this.isSetupMode = false;
    this.isIdleMode = false;

    this.machineData = downtimeModeData;
    this.milliseconds = this.speedMonitorService.getDuration(this.machineData.startTime, this.deltaTime);
    this.setDowntimeData(downtimeModeData);
  }

  /* Set Setup Mode Data */
  public setSetupModeWorkCenterDetails(setupModeData: MachineSetupData): void {
    this.isSetupMode = true;
    this.isRunMode = false;
    this.isDowntimeMode = false;
    this.isIdleMode = false;

    this.machineData = setupModeData;
    this.milliseconds = this.speedMonitorService.getDuration(this.machineData.startTime, this.deltaTime);
    this.setSetupData(setupModeData);
  }

  /* Set Idle Mode Data */
  public setIdleModeWorkCenterDetails(idleModeData: MachineIdleData): void {
    this.isIdleMode = true;
    this.isSetupMode = false;
    this.isRunMode = false;
    this.isDowntimeMode = false;

    this.machineData = idleModeData;
    this.milliseconds = this.speedMonitorService.getDuration(this.machineData.startTime, this.deltaTime);
    this.setIdleData();
  }

  public redirectToFullscreen() {
    this.router.navigateByUrl(`/speedmonitor/${this.externalWorkCenterId}`, {
      state: {
        workCenterId: this.workCenterId,
        flag: true,
        isFullViewMode: true,
        kpiList: this.kpiValueBag.kpiValue,
        displayTime: this.displayTime
      }
    });
  }

  public fullscreen(): void {
    const element = this.speedMonitor.nativeElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
    this.mode = SpeedMonitorViewMode.FullScreen;
    this.valuePropertyBag.mode = SpeedMonitorViewMode.FullScreen;

    clearInterval(this.intervalId);
    this.startProcessingKPI(); // fetches data for KPI in interval.
  }

  public ngOnDestroy() {
    this.activeSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    clearTimeout(this.timeoutHandler);
    clearTimeout(this.timeoutHandlerOne);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  private formatTimespan(timeStamp: string): string {
    if (!timeStamp) {
      return null;
    }
    const timeStampParts = timeStamp.split('.');

    // we need to determine if the first part contains the hourse / minutes / seconds pattern. If not it is the days
    let timeStampWithoutMilliseconds;
    let days = null;

    if (timeStampParts[0].includes(':')) {
      timeStampWithoutMilliseconds = timeStampParts[0];
    } else if (timeStampParts.length > 1 && timeStampParts[1].includes(':')) {
      days = timeStampParts[0];
      timeStampWithoutMilliseconds = timeStampParts[1];
    } else {
      return null;
    }

    const numbers = timeStampWithoutMilliseconds.split(':');
    const hours = numbers[0];
    const minutes = numbers[1];
    const seconds = Math.ceil(parseInt(numbers[2], 10)).toString().padStart(2, '0');
    return `${days ? `${days}.` : ''}${hours}:${minutes}:${seconds}`;
  }

  private FindWorkCenterByExternalId(workCenters: WorkCenter[], externalWorkCenterId: string): WorkCenter {
    if (!externalWorkCenterId) {
      return null;
    }
    const filteredWorkCenters = (this.activeWorkcenters = workCenters.filter((wc) => wc.externalWorkCenterId === externalWorkCenterId));
    if (filteredWorkCenters.length === 0) {
      return null;
    } else {
      return filteredWorkCenters[0];
    }
  }

  private FindWorkCenterById(workCenters: WorkCenter[], workCenterId: number): WorkCenter {
    if (!workCenterId) {
      return null;
    }
    const filteredWorkCenters = (this.activeWorkcenters = workCenters.filter((wc) => wc.id === workCenterId));
    if (filteredWorkCenters.length === 0) {
      return null;
    } else {
      return filteredWorkCenters[0];
    }
  }
}
