import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Quantity } from 'chronos-core-client';
import { knownUnits } from '../../../models/knownUnits.model';

@Component({
  selector: 'lib-change-order-quantity',
  templateUrl: './change-order-quantity.component.html',
  styleUrls: ['./change-order-quantity.component.scss']
})
export class ChangeOrderQuantityComponent implements OnChanges {
  @Input() public goodQuantitLabel = 'APPROVE.CHANGE_QUANTITY_MODAL.GOOD_QUANTITY';
  @Input() public wasteLabel = 'APPROVE.CHANGE_QUANTITY_MODAL.WASTE';
  @Input() public maculatureLabel = 'APPROVE.CHANGE_QUANTITY_MODAL.MACULATURE';

  @Input() public goodQuantity: Quantity = { value: 0, unitId: '' };
  @Input() public waste: Quantity = { value: 0, unitId: '' };
  @Input() public maculature: Quantity = { value: 0, unitId: '' };

  private initGoodQuantity = 0;
  private initWaste = 0;
  private initMaculature = 0;
  public maxQuantity = 0;

  public required = true;
  public readonly INPUT_STYLE = { textAlign: 'end' };

  @Output() public goodQuantityChanged = new EventEmitter<number>();
  @Output() public wasteChanged = new EventEmitter<number>();
  @Output() public maculatureChanged = new EventEmitter<number>();

  public minFractionDigits: number = null;
  public maxFractionDigits: number = null;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.goodQuantity?.currentValue && changes.goodQuantity.firstChange) {
      this.initGoodQuantity = changes.goodQuantity?.currentValue.value;
    }
    if (changes.waste?.currentValue && changes.waste.firstChange) {
      this.initWaste = changes.waste?.currentValue.value;
    }
    if (changes.maculature?.currentValue && changes.maculature.firstChange) {
      this.initMaculature = changes.maculature?.currentValue.value;
    }
    this.setDecimalInput();
    this.maxQuantity = this.initGoodQuantity + this.initWaste + this.initMaculature;
  }

  public onQuantityChange(value: number): void {
    const availableQuantity = this.maxQuantity;
    const quantityChange = value - this.goodQuantity.value;

    let newWaste = this.waste.value;
    let newMaculature = this.maculature.value;
    const newGoodQuantity =
      this.goodQuantity.value + quantityChange > availableQuantity ? availableQuantity : this.goodQuantity.value + quantityChange;

    if (quantityChange > 0) {
      newWaste -= quantityChange;
      if (newWaste < 0) {
        newMaculature += newWaste;
        newWaste = 0;
      }
    } else {
      newWaste = newWaste - quantityChange;
    }
    if (newGoodQuantity === availableQuantity) {
      newWaste = 0;
      newMaculature = 0;
    }
    this.waste.value = newWaste;
    this.maculature.value = newMaculature;
    this.goodQuantity.value = newGoodQuantity;

    this.goodQuantityChanged.emit(newGoodQuantity);
    this.maculatureChanged.emit(newMaculature);
    this.wasteChanged.emit(newWaste);
  }

  public onWasteChange(newWaste: number): void {
    const availableWaste = this.maxQuantity - this.maculature.value;
    newWaste = newWaste > availableWaste ? availableWaste : newWaste;
    const wasteChange = newWaste - this.waste.value;

    let newGoodQuantity = this.goodQuantity.value - wasteChange;
    newGoodQuantity = newGoodQuantity < 0 ? 0 : newGoodQuantity;

    this.goodQuantity.value = newGoodQuantity;
    this.waste.value = newWaste;

    this.goodQuantityChanged.emit(newGoodQuantity);
    this.wasteChanged.emit(newWaste);
  }

  public onMaculatureChange(newMaculature: number): void {
    const availableMaculature = this.maxQuantity - this.waste.value;
    newMaculature = newMaculature > availableMaculature ? availableMaculature : newMaculature;
    const maculatureChange = newMaculature - this.maculature.value;

    let newGoodQuantity = this.goodQuantity.value - maculatureChange;
    newGoodQuantity = newGoodQuantity < 0 ? 0 : newGoodQuantity;

    this.goodQuantity.value = newGoodQuantity;
    this.maculature.value = newMaculature;

    this.goodQuantityChanged.emit(newGoodQuantity);
    this.maculatureChanged.emit(newMaculature);
  }

  private setDecimalInput() {
    if (this.waste.unitId || this.maculature.unitId) {
      const unitId = this.waste.unitId.toUpperCase() || this.maculature.unitId.toUpperCase();
      this.minFractionDigits = !knownUnits.WholeUnits.includes(unitId) ? 2 : null;
      this.maxFractionDigits = !knownUnits.WholeUnits.includes(unitId) ? 2 : null;
    }
  }
}
