/* tslint:disable */
type WorkCenterTaskType = 'Downtime' | 'QaCheck';
module WorkCenterTaskType {
  export const DOWNTIME: WorkCenterTaskType = 'Downtime';
  export const QA_CHECK: WorkCenterTaskType = 'QaCheck';
  export function values(): WorkCenterTaskType[] {
    return [DOWNTIME, QA_CHECK];
  }
}

export { WorkCenterTaskType };
