import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PrimaryMaterialMounted } from 'chronos-core-client';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConsumedMaterialState, ConsumedMaterialStore } from './consumed-material.store';
import { SortDirection, ConsumptionListFilters, ConsumptionListSorting } from 'chronos-shared';

@Injectable()
export class ConsumedMaterialQuery extends QueryEntity<ConsumedMaterialState, PrimaryMaterialMounted> {
  public sorting$: Observable<ConsumptionListSorting> = this.select((state) => state.sorting);
  public filters$: Observable<ConsumptionListFilters> = this.select((state) => state.filters);
  public palletSequence$: Observable<SortDirection> = this.select((state) => state.sorting.palletSequence);

  public consumedMaterial$: Observable<PrimaryMaterialMounted[]> = combineLatest([this.filters$, this.sorting$]).pipe(
    switchMap(() =>
      this.selectAll({
        filterBy: this.filterByContainerType,
        sortBy: this.sortBySequenceNumber
      })
    )
  );

  constructor(protected store: ConsumedMaterialStore) {
    super(store);
  }

  private filterByContainerType = (consumedMaterial: PrimaryMaterialMounted): boolean =>
    this.getValue().filters.showOnlyDummy ? consumedMaterial.container.isVirtualContainer : true;

  private sortBySequenceNumber = (firstContainer: PrimaryMaterialMounted, secondContainer: PrimaryMaterialMounted): number => {
    const comparison = firstContainer.materialSequence - secondContainer.materialSequence;

    return this.getValue().sorting.palletSequence === SortDirection.ASC ? comparison : -comparison;
  };
}
