import { Injectable } from '@angular/core';
import { ShiftReportHeaderKpisStore } from './shift-report-header-kpis.store';
import { KpiValue } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportHeaderKpisService {
  constructor(private shiftReportHeaderKpisStore: ShiftReportHeaderKpisStore) {}

  public setShiftReportHeaderKpis(shiftReportHeaderKpis: KpiValue[]): void {
    this.shiftReportHeaderKpisStore.set(shiftReportHeaderKpis);
  }

  public updateShiftReportHeaderKpis(shiftReportHeaderKpis: KpiValue[]): void {
    this.shiftReportHeaderKpisStore.upsertMany(shiftReportHeaderKpis);
  }

  public resetShiftReportHeaderKpis(): void {
    this.shiftReportHeaderKpisStore.reset();
  }
}
