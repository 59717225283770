import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ConfirmCheckboxQaHistoryCommandService,
  NextSetupSubphaseCommandService,
  PreviousSetupSubphaseCommandService
} from '@app/modules/setup-phase/commands';
import { SetupPhaseService } from '@app/modules/setup-phase/services/setup-phase/setup-phase.service';
import { SetupFooterCommandBase, SetupFooterService } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import { QaHistoryService } from '@app/modules/qa-history/services/qa-history/qa-history.service';
import { Subscription } from 'rxjs';
import { RunSubPhaseType } from 'chronos-core-client';
import { QaHistoryDsService, PatternInfoDsService, ActiveOrderDsService } from '@app/core/data-services';

@Component({
  selector: 'app-setup-qa-history',
  templateUrl: './setup-qa-history.component.html',
  styleUrls: ['./setup-qa-history.component.scss']
})
export class SetupQaHistoryComponent implements OnInit, OnDestroy {
  private previousCommandService: PreviousSetupSubphaseCommandService;
  private nextCommandService: NextSetupSubphaseCommandService;
  private firstCommandService: SetupFooterCommandBase;
  private subscriptions = new Subscription();

  constructor(
    private setupPhaseService: SetupPhaseService,
    private qaHistoryDsService: QaHistoryDsService,
    private setupFooterService: SetupFooterService,
    private qaHistoryService: QaHistoryService,
    private activeOrderDsService: ActiveOrderDsService,
    private patternInfoDsService: PatternInfoDsService
  ) {}

  public ngOnInit(): void {
    const productionOrderId = this.activeOrderDsService.getActiveOrderId();
    this.subscriptions.add(
      this.qaHistoryDsService.isLineClearanceEnableForSetup().subscribe((isLineClearanceEnable) => {
        this.setBackCommand(isLineClearanceEnable);
      })
    );
    this.subscriptions.add(
      this.patternInfoDsService.isPatternAvailableForProductionOrder(productionOrderId).subscribe((isPatternAvailable) => {
        this.setNextCommands(isPatternAvailable);
        this.initFooterCheckboxSubs();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setNextCommands(isPatternAvailable: boolean): void {
    this.nextCommandService = new NextSetupSubphaseCommandService(this.setupFooterService);
    if (isPatternAvailable) {
      this.nextCommandService.setNavigationParams(RunSubPhaseType.SETUP_PATTERN_INFO, nav.routes.setupPatternInfo);
    } else {
      this.nextCommandService.setNavigationParams(RunSubPhaseType.SETUP_PARAMETER, nav.routes.setupParameters);
    }
    this.firstCommandService = new ConfirmCheckboxQaHistoryCommandService(this.qaHistoryService);

    this.setupPhaseService.setSetupCommands(this.previousCommandService, this.nextCommandService, this.firstCommandService);
  }

  private setBackCommand(isLineClearanceAvailable: boolean): void {
    this.previousCommandService = new PreviousSetupSubphaseCommandService(this.setupFooterService);

    if (isLineClearanceAvailable) {
      this.previousCommandService.setNavigationParams(nav.routes.setupLineClearance);
      this.previousCommandService.enabled = true;
    } else {
      this.previousCommandService.enabled = false;
    }
  }

  private initFooterCheckboxSubs(): void {
    this.subscriptions.add(
      this.qaHistoryService.isActiveOrderQaHistoryChecked().subscribe((isChecked) => {
        this.updateFooterButtons(isChecked);
      })
    );
  }

  private updateFooterButtons(isChecked: boolean): void {
    this.firstCommandService.enabled = isChecked;
    this.firstCommandService.required = !isChecked;
    this.nextCommandService.enabled = isChecked;
  }
}
