import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormControlExtension, FormElement } from '../models';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControl], [formControlName]'
})
export class FormControlExtensionDirective implements OnInit {
  constructor(private element: ElementRef, private model: NgControl) {}

  public ngOnInit(): void {
    (this.model.control as FormControlExtension).element = this.getElement(this.element.nativeElement);
  }

  private getElement(native: HTMLElement): FormElement | null {
    return native.querySelector('input') || native.querySelector('textarea') || this.isElementSupported(native);
  }

  private isElementSupported(native: HTMLElement): FormElement | null {
    if (native.tagName === 'INPUT' || native.tagName === 'TEXTAREA') {
      return native as FormElement;
    } else {
      return null;
    }
  }
}
