import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveProductionOrder, ProductionOrder, SetupParameterKind, SystemParameterType } from 'chronos-core-client';
import { ParametersFields } from '@app/shared/models';
import { map } from 'rxjs/operators';
import { ParametersBaseService } from './parameters-base.service';
import { ParametersDsService } from '@app/core/data-services';

@Injectable({
  providedIn: 'root'
})
export class SetupParametersService extends ParametersBaseService {
  public productionOrder: ProductionOrder | ActiveProductionOrder;

  constructor(private parametersDsService: ParametersDsService) {
    super();
  }

  public getSetupParameterKinds(): Observable<SetupParameterKind> {
    return this.parametersDsService.getSetupParameterKinds(this.productionOrder.productionOrderId);
  }

  public setSetupParameterKind(parameterKindId: number): Observable<null> {
    return this.parametersDsService.setSetupParameterKind(parameterKindId, this.productionOrder.productionOrderId);
  }

  public getSetupParameters(): Observable<ParametersFields> {
    return this.parametersDsService
      .getSetupParameters(this.productionOrder.productionOrderId)
      .pipe()
      .pipe(map((parametersFields) => ParametersBaseService.mapParameters(parametersFields)));
  }

  public setSetupParameter(parameterId: number, paramValue: string, systemParameterType: SystemParameterType): Observable<null> {
    const numericParamValue = Number(paramValue);
    return this.parametersDsService.setSetupParameter(
      parameterId,
      numericParamValue,
      paramValue,
      systemParameterType,
      this.productionOrder.productionOrderId
    );
  }
}
