import { Component, Input } from '@angular/core';
import { MaterialStatus } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { DismountingPalletInformation } from '../dismounting/models/dismouting-pallet-information.model';

@Component({
  selector: 'lib-dismounting-dummy-pallet',
  templateUrl: './dismounting-dummy-pallet.component.html',
  styleUrls: ['./dismounting-dummy-pallet.component.scss']
})
export class DismountingDummyPalletComponent {
  @Input() public dismountingPalletInformation: DismountingPalletInformation;

  public readonly MATERIAL_STATUS = MaterialStatus;

  constructor(private translateService: TranslateService) {}

  public readonly INPUT_STYLE = { width: '200px' };
}
