import { ApproveCommandCallback, SetupFooterCommandBase } from '@app/shared/components';
import { ShiftReportModalService } from '@app/modules/shift-report/services';
import { notificationTopic } from '@app/shared/utils';

export class SendCompleteReportCommand implements SetupFooterCommandBase {
  public enabled = false;
  public buttonText: string;
  public required = false;
  public commandCallback: ApproveCommandCallback;

  public loadingTopic = notificationTopic.modalSendShiftReport;
  constructor(private shiftReportModalService: ShiftReportModalService) {}

  public execute(): void {
    this.shiftReportModalService.openSendReportModal(false, this.commandCallback);
  }
}
