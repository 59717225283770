<div
  class="status-bar-item"
  [ngClass]="{
    'status-bar-item--required': isMandatory,
    'status-bar-item--disabled': isDisabled
  }"
>
  <i [ngClass]="itemIcon" class="status-bar-item__top"></i>
  <i [ngClass]="{ 'icon-checked-solid': isDone }" class="status-bar-item__bottom"></i>
</div>
