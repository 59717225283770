import { Injectable } from '@angular/core';
import { PrimaryMaterialMounted } from 'chronos-core-client';
import { Quantity } from 'projects/chronos-core-client/src/public-api';
import { Subject } from 'rxjs';

@Injectable()
export class MountPalletItemService {
  private infoButtonClicked = new Subject<PrimaryMaterialMounted>();
  private replaceClicked = new Subject<[number, number]>();
  private dismountClicked = new Subject<PrimaryMaterialMounted>();
  private timeoutEnded = new Subject<number>();
  private whiteWasteAdded = new Subject<[number, Quantity]>();
  private whiteWasteReset = new Subject<number>();
  private endAnimation = new Subject<number>();

  public infoButtonClick$ = this.infoButtonClicked.asObservable();
  public replaceClick$ = this.replaceClicked.asObservable();
  public dismountClick$ = this.dismountClicked.asObservable();
  public timeoutEnd$ = this.timeoutEnded.asObservable();
  public whiteWasteAdd$ = this.whiteWasteAdded.asObservable();
  public whiteWasteReset$ = this.whiteWasteReset.asObservable();
  public endAnimation$ = this.endAnimation.asObservable();

  public onInfoButtonClick(mountedMaterial: PrimaryMaterialMounted): void {
    this.infoButtonClicked.next(mountedMaterial);
  }

  public onReplaceClicked(articleId: number, dummyContainerId: number): void {
    this.replaceClicked.next([articleId, dummyContainerId]);
  }

  public onDismountClick(mountedMaterial: PrimaryMaterialMounted): void {
    this.dismountClicked.next(mountedMaterial);
  }

  public onTimeoutEnded(consumedMaterialId: number): void {
    this.timeoutEnded.next(consumedMaterialId);
  }

  public onAddContainerWaste(mountedMaterialId: number, whiteWaste: Quantity): void {
    this.whiteWasteAdded.next([mountedMaterialId, whiteWaste]);
  }

  public onResetContainerWhiteWaste(mountedMaterialId: number): void {
    this.whiteWasteReset.next(mountedMaterialId);
  }

  public onEndAnimation(mountedMaterialId: number): void {
    this.endAnimation.next(mountedMaterialId);
  }
}
