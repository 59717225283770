import { Component, OnInit } from '@angular/core';
import { SpeedMonitorService } from '../../services/speed-monitor.service';

@Component({
  selector: 'lib-display-information',
  templateUrl: './display-information.component.html',
  styleUrls: ['./display-information.component.scss']
})
export class DisplayInformationComponent implements OnInit {
  public displayMessage: string;
  public fontSize = 4.2;

  constructor(private speedMonitorService: SpeedMonitorService) {}

  public ngOnInit() {
    this.speedMonitorService.workCenterDetails.subscribe((result) => {
      if (result) {
        this.displayMessage = result.infoData.message;
      }
    });
    // TODO maybe class ?
    if (this.displayMessage.length >= 100) {
      this.fontSize = 4.2;
    } else if (this.displayMessage.length >= 80 && this.displayMessage.length <= 99) {
      this.fontSize = 5.8;
    } else if (this.displayMessage.length >= 40 && this.displayMessage.length <= 79) {
      this.fontSize = 7.2;
    } else {
      this.fontSize = 11.3;
    }
  }
}
