import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ncStatusFlags } from '@app/shared/models';
import { NonConformanceItem } from 'chronos-core-client';

@Component({
  selector: 'app-nc-sidebar-item',
  templateUrl: './nc-sidebar-item.component.html',
  styleUrls: ['./nc-sidebar-item.component.scss']
})
export class NcSidebarItemComponent {
  @Input() public item: NonConformanceItem;
  @Input() public isItemActive = false;
  @Input() public noItemLabel = '';
  @Input() public isItemPreliminary = false;

  @Output() public itemSelected: EventEmitter<NonConformanceItem> = new EventEmitter();

  public readonly NC_STATUS_FLAGS = ncStatusFlags;

  public changeSelectedItem(): void {
    this.itemSelected.emit(this.item);
  }
}
