import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ContainerPalletTemplateComponent } from './container-pallet-template/container-pallet-template.component';
import { ContainerPalletFormDirective } from './container-pallet-form/container-pallet-form.directive';
import { ContainerPalletKpiDirective } from './container-pallet-kpi/container-pallet-kpi.directive';

@NgModule({
  declarations: [ContainerPalletTemplateComponent, ContainerPalletFormDirective, ContainerPalletKpiDirective],
  exports: [ContainerPalletTemplateComponent, ContainerPalletFormDirective, ContainerPalletKpiDirective],
  imports: [CommonModule, TranslateModule]
})
export class ContainerPalletModule {}
