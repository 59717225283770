<li
  class="sub-phase"
  [ngClass]="{
    'sub-phase--active': isActive,
    'sub-phase--active-phase': isSubPhaseActive,
    'sub-phase--read-only': isReadOnly
  }"
  [routerLink]="isReadOnly ? null : link"
>
  <span class="sub-phase__label">{{ title }}</span>
</li>
