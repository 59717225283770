/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MountContainerResponse } from '../models/mount-container-response';
import { MountContainerEntry } from '../models/mount-container-entry';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
@Injectable({
  providedIn: 'root'
})
class MaterialService extends __BaseService {
  static readonly mountContainerPath = '/api/v1/Material/{workCenterId}/Mount';
  static readonly getAvailableMaterialsPath = '/api/v1/Material/{workCenterId}/GetAvailableMaterials';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `MaterialService.MountContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  mountContainerResponse(params: MaterialService.MountContainerParams): __Observable<__StrictHttpResponse<MountContainerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Material/${encodeURIComponent(String(params.workCenterId))}/Mount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MountContainerResponse>;
      })
    );
  }
  /**
   * @param params The `MaterialService.MountContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  mountContainer(params: MaterialService.MountContainerParams): __Observable<MountContainerResponse> {
    return this.mountContainerResponse(params).pipe(__map((_r) => _r.body as MountContainerResponse));
  }

  /**
   * @param params The `MaterialService.GetAvailableMaterialsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `ProductionOrderId`:
   *
   * - `MaterialLocationsSelectedValueMode`:
   *
   * - `MaterialLocationsMode`:
   *
   * - `MaterialFilterCriteria`:
   *
   * - `ArticleId`:
   */
  getAvailableMaterialsResponse(
    params: MaterialService.GetAvailableMaterialsParams
  ): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ProductionOrderId != null) __params = __params.set('ProductionOrderId', params.ProductionOrderId.toString());
    if (params.MaterialLocationsSelectedValueMode != null)
      __params = __params.set('MaterialLocationsSelectedValueMode', params.MaterialLocationsSelectedValueMode.toString());
    if (params.MaterialLocationsMode != null) __params = __params.set('MaterialLocationsMode', params.MaterialLocationsMode.toString());
    if (params.MaterialFilterCriteria != null) __params = __params.set('MaterialFilterCriteria', params.MaterialFilterCriteria.toString());
    if (params.ArticleId != null) __params = __params.set('ArticleId', params.ArticleId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Material/${encodeURIComponent(String(params.workCenterId))}/GetAvailableMaterials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param params The `MaterialService.GetAvailableMaterialsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `ProductionOrderId`:
   *
   * - `MaterialLocationsSelectedValueMode`:
   *
   * - `MaterialLocationsMode`:
   *
   * - `MaterialFilterCriteria`:
   *
   * - `ArticleId`:
   */
  getAvailableMaterials(params: MaterialService.GetAvailableMaterialsParams): __Observable<MaterialAvailableAtLocation> {
    return this.getAvailableMaterialsResponse(params).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }
}

module MaterialService {
  /**
   * Parameters for MountContainer
   */
  export interface MountContainerParams {
    workCenterId: number;
    entry: MountContainerEntry;
  }

  /**
   * Parameters for GetAvailableMaterials
   */
  export interface GetAvailableMaterialsParams {
    workCenterId: number;
    ProductionOrderId?: null | number;
    MaterialLocationsSelectedValueMode?: 'CountOnly' | 'CountAndSum' | 'CountAndSumAndContainers';
    MaterialLocationsMode?: 'Ignore' | 'MachineLocation' | 'WarehouseLocation';
    MaterialFilterCriteria?: 'None' | 'Primary' | 'Secondary' | 'Trace' | 'PrimaryAndSecondary' | 'All';
    ArticleId?: null | number;
  }
}

export { MaterialService };
