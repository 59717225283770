/* tslint:disable */
type ProducedMaterialCheckState = 'None' | 'Enabled' | 'Disabled' | 'Mandatory';
module ProducedMaterialCheckState {
  export const NONE: ProducedMaterialCheckState = 'None';
  export const ENABLED: ProducedMaterialCheckState = 'Enabled';
  export const DISABLED: ProducedMaterialCheckState = 'Disabled';
  export const MANDATORY: ProducedMaterialCheckState = 'Mandatory';
  export function values(): ProducedMaterialCheckState[] {
    return [NONE, ENABLED, DISABLED, MANDATORY];
  }
}

export { ProducedMaterialCheckState };
