<div *ngIf="productionType" class="remark__type">{{ productionType }}</div>
<ng-container *ngIf="remarkList?.length > 0; else emptyList">
  <ng-container *ngFor="let remark of remarkList">
    <div class="remark">
      <div class="remark__flag" *ngIf="remark.remarkSource === REMARK_TYPE.PRODUCTION_ORDER_INFO_SHOP_FLOOR; else noShopFloor">
        <lib-status-flag
          [statusText]="SHOP_FLOOR_REMARK_FLAG.translation | translate : { shopFloor: externalOperationType | uppercase }"
          [styleClass]="SHOP_FLOOR_REMARK_FLAG.labelClass"
        ></lib-status-flag>
      </div>
      <ng-template #noShopFloor>
        <lib-status-flag
          *ngIf="REMARK_STATUS_FLAGS[remark.remarkSource] as remarkStatus"
          [statusText]="remarkStatus.text | translate"
          [styleClass]="remarkStatus.labelClass"
        ></lib-status-flag>
      </ng-template>
      <div class="remark__field">
        {{ remark.remarkText }}
      </div>
      <div *ngIf="actions" class="remark__action">
        <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{ $implicit: remark }"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyList>
  <div class="remark">
    <div class="remark__field">
      {{ 'DOCUMENTS.NO_INFORMATION' | translate }}
    </div>
    <div *ngIf="actions" class="remark__action">
      <ng-container [ngTemplateOutlet]="actions"></ng-container>
    </div>
  </div>
</ng-template>
