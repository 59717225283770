import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';
import {
  ClockInFilterDateEntry,
  MaintenanceService,
  RecreatePeriodsEntry,
  SimulationService,
  SimulationSpeedEntry
} from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class SimulationDsService {
  constructor(
    private simulationService: SimulationService,
    private maintenanceService: MaintenanceService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public changeMachineSpeed(speed: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: SimulationSpeedEntry = {
      speed
    };
    const params: SimulationService.SetSpeedParams = {
      workCenterId,
      entry
    };

    return this.simulationService.setSpeed(params);
  }

  public recreatePeriods(startTime: Date): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: RecreatePeriodsEntry = {
      startTime: startTime.toISOString()
    };
    const params: SimulationService.RecreatePeriodsParams = {
      workCenterId,
      entry
    };

    return this.simulationService.recreatePeriods(params);
  }

  public clockInFilterDate(startTime: Date, setInAllWorkCenters: boolean): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ClockInFilterDateEntry = {
      startTime: startTime.toISOString(),
      setInAllWorkCenters
    };
    const params: MaintenanceService.ClockInFilterDateParams = {
      workCenterId,
      entry
    };

    return this.maintenanceService.clockInFilterDate(params);
  }
}
