import { Injectable } from '@angular/core';
import { ApproveParametersService, ParametersBaseService, SetupParametersService } from '@app/core/services/parameters-factory/parameters';
import { ActiveOrderDsService } from '@app/core/data-services';
import { ActiveProductionOrder, ProductionOrder } from 'chronos-core-client';
import { ParametersServiceType } from '@app/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParametersFactoryService {
  private parametersServiceType: string;
  private productionOrder: ProductionOrder | ActiveProductionOrder;
  private workCenterId: number;
  private approvalReportLineId: number;
  private approvalReportDate: string;

  public palletQuantitySubject = new BehaviorSubject<number>(null);
  public palletQuantitySubject$: Observable<number> = this.palletQuantitySubject.asObservable();
  public bobbinQuantitySubject = new BehaviorSubject<number>(null);
  public bobbinQuantitySubject$: Observable<number> = this.palletQuantitySubject.asObservable();

  constructor(
    private approveParametersService: ApproveParametersService,
    private setupParametersService: SetupParametersService,
    private activeOrderDsService: ActiveOrderDsService
  ) {}

  public setValuesForApprove(
    workCenterId: number,
    approvalReportLineId: number,
    approvalReportDate: string,
    productionOrder: ProductionOrder
  ): void {
    this.parametersServiceType = ParametersServiceType.APPROVE;
    this.workCenterId = workCenterId;
    this.approvalReportLineId = approvalReportLineId;
    this.approvalReportDate = approvalReportDate;
    this.productionOrder = productionOrder;
  }

  public setValuesForSetup(productionOrder?: ProductionOrder): void {
    this.parametersServiceType = ParametersServiceType.SETUP;
    this.productionOrder = productionOrder ?? this.activeOrderDsService.getActiveOrder();
  }

  public initParametersService(): ParametersBaseService {
    switch (this.parametersServiceType) {
      case ParametersServiceType.APPROVE: {
        this.approveParametersService.workCenterId = this.workCenterId;
        this.approveParametersService.approvalReportLineId = this.approvalReportLineId;
        this.approveParametersService.approvalReportDate = this.approvalReportDate;
        this.setupParametersService.productionOrder = this.productionOrder;
        return this.approveParametersService;
      }
      case ParametersServiceType.SETUP: {
        this.setupParametersService.productionOrder = this.productionOrder;
        return this.setupParametersService;
      }
    }
  }

  public cleanParametersFactoryValues(): void {
    this.parametersServiceType = null;
    this.productionOrder = null;
    this.workCenterId = null;
    this.approvalReportLineId = null;
    this.approvalReportDate = null;
  }
}
