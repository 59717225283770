import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveOrderDsService } from '@app/core/data-services';
import { map, tap } from 'rxjs/operators';
import { nav } from '../utils/nav';

export const activeOrderPresentGuard = () => {
  const activeOrderDsService: ActiveOrderDsService = inject(ActiveOrderDsService);
  const router: Router = inject(Router);
  return activeOrderDsService.selectActiveOrder().pipe(
    map((activeOrder$) => !!activeOrder$),
    tap((isOrderActive) => {
      if (!isOrderActive) {
        return router.navigate([nav.ordersList]);
      }
    })
  );
};
