<section class="qa-history">
  <div class="qa-history__sidebar-container">
    <app-scrollable-sidebar
      [itemList]="filteredNonConformances"
      (searchBarValueChanged)="searchBarValueChanged($event)"
      (createNCButtonClicked)="createNCButtonClicked()"
      [enableCreateNC]="enableCreateNC"
    >
      <app-nc-sidebar-item
        *appListItem="let item"
        [item]="item"
        [isItemActive]="isItemActive(item)"
        [isItemPreliminary]="isItemPreliminary(item)"
        (itemSelected)="changeSelectedNonConformance($event)"
      ></app-nc-sidebar-item>
      <app-nc-sidebar-item [noItemLabel]="'QA_HISTORY.NO_NC' | translate"></app-nc-sidebar-item>
    </app-scrollable-sidebar>
  </div>
  <div class="qa-history__main-container">
    <div class="qa-history__dropdown">
      <section *ngIf="articleItems?.length > 0; else emptyPage">
        <app-article-dropdown
          [articleItemsSubjectInput]="articleItemListSubject"
          [selectedArticleItem]="selectedArticleItem"
          [isDisabled]="showOneOrderOnly"
          (articleChanged)="changeSelectedArticle($event)"
        ></app-article-dropdown>
        <div *ngIf="nonConformanceModel" class="qa-history__grid-container">
          <div class="qa-history__reasons">
            <div class="qa-history__item">
              <app-nc-reason [nonConformanceModel]="nonConformanceModel" [isCreateNCMode]="isCreateNCMode"></app-nc-reason>
            </div>
            <div class="qa-history__item">
              <app-nc-measure [nonConformanceModel]="nonConformanceModel" [isCreateNCMode]="isCreateNCMode"></app-nc-measure>
            </div>
          </div>
          <div class="qa-history__defectives">
            <app-nc-defective-quantity
              [nonConformanceModel]="nonConformanceModel"
              [isCreateNCMode]="isCreateNCMode"
              (quantityChanged)="quantityChanged($event)"
            ></app-nc-defective-quantity>
            <app-nc-defective-items-carousel
              *ngIf="!updatingNonConformanceModel"
              [defectiveItems]="nonConformanceModel?.documents"
              [isCreateNCMode]="isCreateNCMode"
              (imageClicked)="openDefectiveItemsModal()"
              [isImageClickable]="true"
            ></app-nc-defective-items-carousel>
          </div>
          <div class="qa-history__material"></div>
          <div class="qa-history__information">
            <app-nc-info
              [nonConformanceModel]="nonConformanceModel"
              [isCreateNCMode]="isCreateNCMode"
              (internalMemoUpdated)="infoUpdated($event)"
            ></app-nc-info>
          </div>
          <div class="qa-history__documents">
            <ng-container *ngIf="showInactive">
              <app-nc-document-list></app-nc-document-list>
            </ng-container>
          </div>
        </div>
      </section>
    </div>
    <ng-template #emptyPage>
      <div class="text-center">{{ 'QA_HISTORY.NO_INFORMATION' | translate }}</div>
    </ng-template>
    <footer class="qa-history-section__footer" *ngIf="isCreateNCMode">
      <app-setup-footer [cancelCommand]="cancelCommand" [nextCommand]="submitCommand"></app-setup-footer>
    </footer>
  </div>
</section>
