import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-pallet-indicator',
  templateUrl: './pallet-indicator.component.html',
  styleUrls: ['./pallet-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalletIndicatorComponent {
  @Input() public title = '';
  @Input() public palletCount = 0;
  @Input() public large = false;
  @Input() public borderless = false;
  @Input() public disabled = false;
  @Input() public titleLongText = false;

  @Output() public clicked = new EventEmitter<null>();

  public onClick(): void {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }
}
