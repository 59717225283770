import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveEmployeeRegistration } from 'chronos-core-client';
import { MenuItem } from 'primeng/api';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { applicationPaths } from '../../../api-authorization/api-authorization.constants';
import { AuthorizeService, IUser } from '../../../api-authorization/authorize.service';

@Component({
  selector: 'lib-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent implements OnInit {
  @Input() public isEmployeeRegistrationEnabled: boolean;

  @Input() public employeeName: ActiveEmployeeRegistration;

  @Output() public userLoginClicked = new EventEmitter<null>();

  public activeEmployee: string;
  public userMenuItems$: Observable<MenuItem[]>;
  public menuName$: Observable<string>;

  constructor(private translateService: TranslateService, private authorizeService: AuthorizeService) {}

  public ngOnInit(): void {
    this.initUserMenuItemsObservable();
    this.initMenuNameObservable();
  }

  private initMenuNameObservable() {
    this.menuName$ = this.authorizeService.getUser().pipe(
      map((user) => (user ? this.displayTextForUser(user) : null)),
      switchMap((name) => (name ? of(name) : this.translateService.get('USER.ACCOUNT')))
    );
  }

  private displayTextForUser(user: IUser) {
    return user.name || user.username || user.email || user.sub;
  }

  private initUserMenuItemsObservable() {
    this.userMenuItems$ = combineLatest([
      this.authorizeService.isAuthenticated(),
      this.translateService.get(['USER.LOGIN', 'USER.LOGOUT'])
    ]).pipe(
      map(([isAuthenticated, translations]) => {
        const menuItems: MenuItem[] = [];
        if (isAuthenticated) {
          menuItems.push({
            label: translations['USER.LOGOUT'],
            routerLink: ['/', ...applicationPaths.LogOutPathComponents],
            queryParams: { returnUrl: '/' },
            state: { local: true }
          });
        } else {
          menuItems.push({
            label: translations['USER.LOGIN'],
            routerLink: ['/', ...applicationPaths.LoginPathComponents]
          });
        }
        return menuItems;
      })
    );
  }

  public onUserClick() {
    this.userLoginClicked.emit();
  }
}
