import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SimulationDsService } from '@app/core/data-services';
import { notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-date-picker-modal',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss']
})
export class DatePickerModalComponent implements OnInit {
  public selectedStartTime: Date;
  public maxSelectableDate: Date;
  public isDateValid = false;
  public setInAllWorkCenters = false;
  public checkBoxLabel = 'MODAL_FOOTER.CLOCKIN_WARNING';

  public readonly INPUT_STYLE = { width: '270px' };
  public readonly LOADING_TOPIC = notificationTopic.modalProductionPeriods;

  constructor(private ref: DynamicDialogRef, private simulationDsService: SimulationDsService) {}

  public ngOnInit(): void {
    this.maxSelectableDate = new Date();
  }

  public startOrder(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.simulationDsService
      .clockInFilterDate(this.selectedStartTime, this.setInAllWorkCenters)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close();
      });
  }

  public closeModal(): void {
    this.ref.close();
  }

  public checkValidity(): void {
    this.isDateValid = this.selectedStartTime <= this.maxSelectableDate;
  }
}
