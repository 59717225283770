import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MountPalletCardsService } from '@app/modules/mounting/services/mount-pallet-cards/mount-pallet-cards.service';
import { MountingService } from '@app/modules/mounting/services/mounting/mounting.service';
import { ArticleDescription, MachineState, MountedMaterialStatus } from 'chronos-core-client';
import { MountedMaterial, MaterialStatus } from 'chronos-shared';

@Component({
  selector: 'app-mount-pallet-cards',
  templateUrl: './mount-pallet-cards.component.html',
  styleUrls: ['./mount-pallet-cards.component.scss']
})
export class MountPalletCardsComponent {
  public readonly MOUNTED_MATERIAL_STATUS = MountedMaterialStatus;
  public readonly MACHINE_STATE = MachineState;
  public readonly MATERIAL_STATUS = MaterialStatus;
  private readonly CARDS_VISIBLE_IN_VIEW = 4;

  @Input() public mountedMaterials: MountedMaterial[];
  @Input() public activeMaterial: ArticleDescription;
  @Input() public nextMaterial: ArticleDescription;
  @Input() public plannedStartTime: Date;
  @Input() public externalWorkCenterId: string;
  @Input() public machineState: MachineState;

  @Output() public newDummyPalletClicked = new EventEmitter<number>();

  constructor(private mountingService: MountingService, private mountPalletCardsService: MountPalletCardsService) {}

  public getMountedContainersForCardView(): MountedMaterial[] {
    return this.mountingService.getMountedContainers(this.CARDS_VISIBLE_IN_VIEW, this.mountedMaterials).reverse();
  }

  public trackByFunction(index: number, mountedMaterial: MountedMaterial): number {
    return mountedMaterial.mountedMaterialId;
  }

  public onDummyPalletButtonClick(articleId: number): void {
    this.newDummyPalletClicked.emit(articleId);
  }

  public changeCardViewContainerListSequence(event: CdkDragDrop<string[]>): void {
    this.mountedMaterials = this.mountPalletCardsService.changeCardViewContainerListSequence(
      { currentIndex: event.currentIndex, previousIndex: event.previousIndex },
      this.CARDS_VISIBLE_IN_VIEW,
      this.mountedMaterials
    );
  }
}
