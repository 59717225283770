import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillOfMaterial, ProductionOrderService, SplitBillOfMaterialData, SplitBillOfMaterialRowEntry } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class BillOfMaterialDsService {
  constructor(private productionOrderService: ProductionOrderService) {}

  public getBillOfMaterials(productionOrderId: number): Observable<BillOfMaterial[]> {
    return this.productionOrderService.getBillOfMaterials(productionOrderId);
  }

  public getSplitBillOfMaterialDetails(productionOrderId: number, billOfMaterialId: number): Observable<SplitBillOfMaterialData> {
    const param: ProductionOrderService.SplitBillOfMaterialDataParams = {
      productionOrderId,
      billOfMaterialId
    };

    return this.productionOrderService.splitBillOfMaterialData(param);
  }

  public splitBillOfMaterialRow(entry: SplitBillOfMaterialRowEntry, productionOrderId: number): Observable<SplitBillOfMaterialData> {
    const param: ProductionOrderService.SplitBillOfMaterialRowParams = {
      productionOrderId,
      entry
    };

    return this.productionOrderService.splitBillOfMaterialRow(param);
  }
}
