import { Component, Input } from '@angular/core';
import { Quantity } from 'chronos-core-client';

@Component({
  selector: 'app-header-waste-display',
  templateUrl: './header-waste-display.component.html',
  styleUrls: ['./header-waste-display.component.scss']
})
export class HeaderWasteDisplayComponent {
  @Input() public waste?: Quantity;
  @Input() public maculature?: Quantity;
}
