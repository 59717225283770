import { ParameterBase, ParameterFieldType } from '@app/shared/models/parameter';
import { BaseList, SystemParameterType } from 'chronos-core-client';

export interface ParametersFields {
  setup: ParameterBase[];
  optional: ParameterBase[];
  toolSetup: ToolSetup[];
}

export interface SetupField {
  id: number;
  order: number;
  type: ParameterFieldType;
  name: string;
  canEdit: boolean;
  required: boolean;
  defaultValue: string;
  configuredValue: string;
  minimumValue: number;
  maximumValue: number;
  textMinimumLength: number;
  textMaximumLength: number;
  listValue: BaseList[];
  helpText: string;
  systemParameterType: SystemParameterType;
  configuredTextValue: string;
  configuredNumericValue: number;
  defaultTextValue: string;
  defaultNumericValue: number;
}

export interface ToolSetup {
  punchingPressure: number;
  articleId: number;
  materialBlockId: number;
  identificationCode: string;
  mountedMaterialId: number;
  isVirtualTool: boolean;
  virtualContainerInfo: string;
  containerId: number;
  toolCreationReasonId: number;
  bomUnitId: string;
}

export enum ParametersServiceType {
  SETUP = 'setup',
  APPROVE = 'approve'
}
