<div class="dismounting-details">
  <div class="dismounting-details__title">{{ 'DISMOUNT_CONTAINER_FORM.DETAILS' | translate }}</div>
  <div class="dismounting-details__info">
    <div class="dismounting-details__info-titles">
      <div>{{ 'DISMOUNT_CONTAINER_FORM.INITIAL' | translate }}:</div>
      <div>{{ 'DISMOUNT_CONTAINER_FORM.SCANNED' | translate }}:</div>
      <div>{{ 'DISMOUNT_CONTAINER_FORM.BATCH' | translate }}:</div>
      <div></div>
      <div>{{ 'DISMOUNT_CONTAINER_FORM.SSCC' | translate }}:</div>
      <div>{{ 'DISMOUNT_CONTAINER_FORM.ARTICLE_ID' | translate }}:</div>
      <div>{{ 'DISMOUNT_CONTAINER_FORM.CONFIGURATION_ID' | translate }}:</div>
      <div>{{ 'DISMOUNT_CONTAINER_FORM.MOUNTED_TO' | translate }}:</div>
    </div>
    <div class="dismounting-details__info-values">
      <div *ngIf="dismountingInfo.initialQuantity; else ValueNotExist">
        {{ dismountingInfo.initialQuantity | formatQuantity }}
      </div>
      <div *ngIf="dismountingInfo.mountedTime; else ValueNotExist">
        {{
          'DISMOUNT_CONTAINER_FORM.TIME_AND_USER'
            | translate
              : {
                  time: dismountingInfo.mountedTime | utcToLocal | date : 'HH:mm',
                  user: dismountingInfo.mountedUser
                }
        }}
      </div>
      <div *ngIf="dismountingInfo.internalBatchId; else ValueNotExist">
        {{ 'DISMOUNT_CONTAINER_FORM.INTERNAL' | translate : { batchId: dismountingInfo.internalBatchId } }}
      </div>
      <div *ngIf="dismountingInfo.externalBatchId; else ValueNotExist">
        {{ 'DISMOUNT_CONTAINER_FORM.EXTERNAL' | translate : { batchId: dismountingInfo.externalBatchId } }}
      </div>
      <div
        *ngIf="
          dismountingInfo.container?.identificationCode || dismountingInfo.identificationCode as identificationCode;
          else ValueNotExist
        "
      >
        {{ identificationCode }}
      </div>
      <div
        *ngIf="
          dismountingInfo.article?.externalArticleId || dismountingInfo.article?.externalArticleId as externalArticleId;
          else ValueNotExist
        "
      >
        {{ externalArticleId }}
      </div>
      <div
        *ngIf="
          dismountingInfo.article?.externalConfigurationId || dismountingInfo.article?.externalConfigurationId as externalConfigurationId;
          else ValueNotExist
        "
      >
        {{ externalConfigurationId }}
      </div>
      <div *ngIf="dismountingInfo.mountedTo as mountedTo; else ValueNotExist">
        {{ mountedTo }}
      </div>
      <ng-template #ValueNotExist>
        <div>-</div>
      </ng-template>
    </div>
  </div>
</div>
