import { Injectable } from '@angular/core';
import {
  ActiveOrderDsService,
  ActiveOrderPhaseDsService,
  MachineCounterDsService,
  QaHistoryDsService,
  SimulationDsService
} from '@app/core/data-services';
import { ActiveProductionOrder, CounterInfo } from 'chronos-core-client';
import { combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { nav } from '@app/shared/utils';
import { AppSettingsQuery } from 'chronos-shared';

export interface ActiveOrderPanelData {
  activeProductionOrder: ActiveProductionOrder;
  showQaHistory: boolean;
  machineSpeed: number;
  isManualMode: boolean;
  showQaChecks: boolean;
  firstSetupRoute: string;
  enableMachineSpeedSimulation: boolean;
  showInactive: boolean;
  showRecalculatePeriods: boolean;
  showLineClearance: boolean;
  isPhasesSet: boolean;
  isPatternInfoAvailableForOrder: boolean;
  isToolCheckoutAvailable: boolean;
}

@Injectable({ providedIn: 'root' })
export class ActiveOrderPanelService {
  public isQaHistoryConfirmedAutomaticallySubject = new BehaviorSubject<boolean>(false);
  public isQaHistoryConfirmedAutomaticallySubject$: Observable<boolean> = this.isQaHistoryConfirmedAutomaticallySubject.asObservable();

  constructor(
    private machineCounterDsService: MachineCounterDsService,
    private activeOrderDsService: ActiveOrderDsService,
    private simulationDsService: SimulationDsService,
    private qaHistoryDsService: QaHistoryDsService,
    private appSettingsQuery: AppSettingsQuery,
    private activeOrderPhaseDsService: ActiveOrderPhaseDsService
  ) {}

  public selectActiveOrderPanelData(): Observable<ActiveOrderPanelData> {
    return combineLatest([
      this.isMachineSimulationEnabled(),
      this.getMachineSpeedFromStore(),
      this.getActiveProductionOrder(),
      this.setActiveOrderPhase(),
      this.isQaHistoryAvailableForSetup(),
      this.isLineClearanceAvailableForSetup(),
      this.isQaChecksAvailableForSetup(),
      this.isClockInEnabled(),
      this.isPatternInfoAvailableForOrder(),
      this.isToolCheckoutAvailable()
    ]).pipe(
      startWith([false, 0, null, null, false, false, false, false, false, false]),
      map(
        ([
          enableMachineSpeedSimulation,
          machineSpeed,
          activeProductionOrder,
          isPhasesSet,
          showQaHistory,
          showLineClearance,
          showQaChecks,
          showRecalculatePeriods,
          isPatternInfoAvailableForOrder,
          isToolCheckoutAvailable
        ]) =>
          ({
            enableMachineSpeedSimulation,
            activeProductionOrder,
            showQaHistory,
            isPhasesSet,
            showLineClearance,
            machineSpeed,
            showQaChecks,
            showRecalculatePeriods,
            isManualMode: this.appSettingsQuery.isWorkCenterModeManual(),
            firstSetupRoute: this.getFirstSetupRoute(showQaHistory as boolean, showLineClearance as boolean),
            showInactive: this.appSettingsQuery.showInactiveElement(),
            isPatternInfoAvailableForOrder,
            isToolCheckoutAvailable
          } as ActiveOrderPanelData)
      )
    );
  }

  public changeSpeed(speed: number): Observable<null> {
    return this.simulationDsService.changeMachineSpeed(speed);
  }

  public isMachineSimulationEnabled(): Observable<boolean> {
    return this.appSettingsQuery.isMachineSpeedSimulationEnabled$;
  }

  public renewMachineSpeed(): Observable<number> {
    return this.machineCounterDsService.renewMachineSpeed();
  }

  public getCounterNotifications(): Observable<CounterInfo> {
    return this.machineCounterDsService.getCounterNotifications();
  }

  private getMachineSpeedFromStore(): Observable<number> {
    return this.machineCounterDsService.getMachineSpeedFromStore();
  }

  public getActiveProductionOrder(): Observable<ActiveProductionOrder> {
    return this.activeOrderDsService.selectActiveOrder();
  }

  public isQaHistoryAvailableForSetup(): Observable<boolean> {
    return this.qaHistoryDsService.isQaHistoryAvailableForSetup();
  }

  public setActiveOrderPhase(): Observable<boolean> {
    return this.activeOrderDsService.selectactiveOrderId().pipe(
      filter((activeOrderId) => !!activeOrderId),
      switchMap((activeOrderId) => this.activeOrderPhaseDsService.getFirstSetupPhase(activeOrderId).pipe(map(() => true)))
    );
  }

  public isQaChecksAvailableForSetup(): Observable<boolean> {
    return this.appSettingsQuery.isQaChecksEnabledForSetup$;
  }

  public isLineClearanceAvailableForSetup(): Observable<boolean> {
    return this.appSettingsQuery.isLineClearanceEnabledForSetup$;
  }

  public isToolCheckoutAvailable(): Observable<boolean> {
    return this.appSettingsQuery.isToolCheckoutEnable$;
  }

  public isPatternInfoAvailableForOrder(): Observable<boolean> {
    return this.appSettingsQuery.isPatternInfoEnabledForSetup$;
  }

  private getFirstSetupRoute(isQaHistory: boolean, isShowLineClearance: boolean): string {
    if (isShowLineClearance) {
      return nav.routes.setupLineClearance;
    }

    if (isQaHistory) {
      return nav.routes.setupQaHistory;
    }

    return nav.routes.setupParameters;
  }

  private isClockInEnabled(): Observable<boolean> {
    return this.appSettingsQuery.isClockInEnabled$;
  }
}
