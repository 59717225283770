<div class="setup-footer p-grid p-nogutter">
  <div class="setup-footer__confirmation-wrapper">
    <lib-button
      *ngIf="cancelCommand"
      class="setup-footer__navigation-wrapper--item"
      (clicked)="onCancelClick()"
      [disabled]="!cancelCommand.enabled"
      [label]="cancelCommand.buttonText || 'SETUP_PHASE_FOOTER.CANCEL'"
      [buttonId]="'cancel_command'"
      [loadingTopic]="cancelCommand?.loadingTopic"
      [typeClass]="'button--secondary'"
      [sizeClass]="'button--small'"
    ></lib-button>
    <div
      *ngIf="confirmFirstCheckboxCommand"
      class="confirm-checkbox"
      [ngClass]="{
        'confirm-checkbox--attention': confirmFirstCheckboxCommand.required
      }"
    >
      <lib-input-check
        class="confirm-checkbox__item"
        [label]="confirmFirstCheckboxCommand.buttonText"
        [(ngModel)]="confirmFirstCheckboxCommand.enabled"
        (ngModelChange)="onFirstCheckboxChange()"
        [loadingTopic]="confirmFirstCheckboxCommand?.loadingTopic"
      ></lib-input-check>
      <div *ngIf="confirmFirstCheckboxCommand && isQaHistoryConfirmedAutomatically" class="qa-automatic-confirm ng-star-inserted p-checkbox-label p-checkbox-label-active">
        {{ 'SETUP_PHASE_FOOTER.QA_HISTORYALREADYCONFIRMED' | translate }}
      </div>
    </div>
    <div
      *ngIf="confirmSecondCheckboxCommand"
      class="confirm-checkbox"
      [ngClass]="{ 'confirm-checkbox--attention': confirmSecondCheckboxCommand.required }"
    >
      <lib-input-check
        class="confirm-checkbox__item"
        [label]="confirmSecondCheckboxCommand.buttonText"
        [(ngModel)]="confirmSecondCheckboxCommand.enabled"
        (ngModelChange)="onSecondCheckboxChange()"
        [loadingTopic]="confirmSecondCheckboxCommand?.loadingTopic"
      ></lib-input-check>     
    </div>
    
  </div>

  <div class="setup-footer__navigation-wrapper">
    <lib-button
      *ngIf="backCommand"
      class="setup-footer__navigation-wrapper--item"
      (clicked)="onBackClick()"
      [disabled]="!backCommand.enabled"
      [label]="backCommand.buttonText || 'SETUP_PHASE_FOOTER.BACK'"
      [buttonId]="'back_command'"
      [loadingTopic]="backCommand?.loadingTopic"
      [sizeClass]="'button--small'"
    ></lib-button>
    <lib-button
      *ngIf="nextCommand"
      class="setup-footer__navigation-wrapper--item"
      (clicked)="onNextClick()"
      [disabled]="!nextCommand.enabled"
      [label]="nextCommand.buttonText || 'SETUP_PHASE_FOOTER.NEXT'"
      [buttonId]="'next_command'"
      [loadingTopic]="nextCommand?.loadingTopic"
      [sizeClass]="'button--small'"
    ></lib-button>
  </div>
</div>
