<div *ngIf="slicedList; else emptySidebar" class="scrollable-sidebar">
  <div *ngIf="enableCreateNC" class="scrollable-sidebar__button-div">
    <button
      id="createNCId"
      [label]="'QA_HISTORY.CREATE_NC' | translate"
      class="scrollable-sidebar__button"
      (click)="onClickCreateNC()"
      [disabled]="checkCreateNCDisabled()"
      pButton
    ></button>
  </div>
  <app-search-bar
    class="label-section__search"
    (searchBarValueChanged)="searchBarFilterList($event)"
    [selectInputOnEnter]="true"
    [searchBarPlaceholder]="'SEARCHBAR_COMMON.SEARCH_BAR_PLACEHOLDER' | translate"
  ></app-search-bar>
  <!-- <div class="scrollable-sidebar__nav-size" [title]="listSize"></div> -->
  <div class="scrollable-sidebar__nav-placeholder">
    <i *ngIf="isArrowUpVisible()" class="scrollable-sidebar__nav icon-arrow-up-close" (click)="scrollUp()"></i>
  </div>

  <div class="scrollable-sidebar__content">
    <div *ngFor="let item of slicedList[scrollIndex]" class="scrollable-sidebar__item">
      <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </div>

  <div class="scrollable-sidebar__nav-placeholder">
    <i *ngIf="isArrowDownVisible()" class="scrollable-sidebar__nav icon-arrow-down-open" (click)="scrollDown()"></i>
  </div>
</div>

<ng-template #emptySidebar>
  <div class="scrollable-sidebar">
    <ng-content></ng-content>
  </div>
</ng-template>
