import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviationReason } from 'chronos-core-client';

@Component({
  selector: 'app-loyalty-reasons',
  templateUrl: './loyalty-reasons.component.html',
  styleUrls: ['./loyalty-reasons.component.scss']
})
export class LoyaltyReasonsComponent implements OnInit {
  @Input() public loyaltyReasons: DeviationReason[];
  @Output() public loyaltyReasonSelected = new EventEmitter<DeviationReason>();

  public selectedLoyaltyReason: DeviationReason = { description: '--', deviationReasonExternalId: null, deviationReasonId: null };

  public ngOnInit(): void {
    this.loyaltyReasons = [this.selectedLoyaltyReason, ...this.loyaltyReasons];
  }

  public onLoyaltyReasonSelect(): void {
    this.loyaltyReasonSelected.emit(this.selectedLoyaltyReason);
  }
}
