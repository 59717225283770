/* tslint:disable */
type RunSubPhaseType =
  | 'None'
  | 'SetupLineClearance'
  | 'SetupQaHistory'
  | 'SetupParameter'
  | 'SetupPatternInfo'
  | 'SetupMaterialWaste'
  | 'SetupInProgressFinishingCheck'
  | 'Run'
  | 'FinishingOverview'
  | 'FinishingToolCheckout'
  | 'FinishingEnd';
module RunSubPhaseType {
  export const NONE: RunSubPhaseType = 'None';
  export const SETUP_LINE_CLEARANCE: RunSubPhaseType = 'SetupLineClearance';
  export const SETUP_QA_HISTORY: RunSubPhaseType = 'SetupQaHistory';
  export const SETUP_PARAMETER: RunSubPhaseType = 'SetupParameter';
  export const SETUP_PATTERN_INFO: RunSubPhaseType = 'SetupPatternInfo';
  export const SETUP_MATERIAL_WASTE: RunSubPhaseType = 'SetupMaterialWaste';
  export const SETUP_IN_PROGRESS_FINISHING_CHECK: RunSubPhaseType = 'SetupInProgressFinishingCheck';
  export const RUN: RunSubPhaseType = 'Run';
  export const FINISHING_OVERVIEW: RunSubPhaseType = 'FinishingOverview';
  export const FINISHING_TOOL_CHECKOUT: RunSubPhaseType = 'FinishingToolCheckout';
  export const FINISHING_END: RunSubPhaseType = 'FinishingEnd';
  export function values(): RunSubPhaseType[] {
    return [
      NONE,
      SETUP_LINE_CLEARANCE,
      SETUP_QA_HISTORY,
      SETUP_PARAMETER,
      SETUP_PATTERN_INFO,
      SETUP_MATERIAL_WASTE,
      SETUP_IN_PROGRESS_FINISHING_CHECK,
      RUN,
      FINISHING_OVERVIEW,
      FINISHING_TOOL_CHECKOUT,
      FINISHING_END
    ];
  }
}

export { RunSubPhaseType };
