<input
  #scanInput
  type="text"
  [(ngModel)]="scanInputText"
  class="scan-clearance-input"
  (input)="onInput($event.target.value)"
  (keyup.enter)="onEnter($event.target.value)"
  pInputText
/>

<div class="p-grid" (click)="onClick($event)">
  <div class="p-col" *ngIf="fields$ | async as fields">
    <p class="scan-warning-text" *ngIf="isCapsLockOn">{{ 'SETUP_LINE_CLEARANCE.CAPSLOCK_ON_WARNING' | translate }}</p>
    <app-line-clearance-list-in-setup [fields]="fields" (formValid)="onFormValidityChange($event)"></app-line-clearance-list-in-setup>
  </div>
</div>
