import { Injectable } from '@angular/core';
import { LogEntry } from './log-entry';
import { LogEntryParameters, LogLevel } from './models';
import { LogConsole, LogHeaderMessageArea, LogPublisher } from './log-publisher';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  public static level: LogLevel = LogLevel.All;
  public static logWithDate = true;

  private static publishersBackingField;
  private static get publishers() {
    if (!this.publishersBackingField) {
      this.publishersBackingField = LogService.setPublishers();
    }
    return this.publishersBackingField;
  }

  public static debug(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  public static info(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  public static success(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Success, optionalParams);
  }

  public static warn(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  public static error(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  public static log(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  private static writeToLog(msg: string, level: LogLevel, params: any[]): void {
    if (this.shouldLog(level)) {
      const entry: LogEntry = new LogEntry();
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;
      entry.serializedBackendWarning = entry.serializeBackendWarning(params);
      entry.serializedBackendError = entry.serializeBackendError(params);
      entry.internalWorkCenterId = Number(entry.extractParameter(params, LogEntryParameters.internalWorkCenterId));
      entry.internalPileTurnerId = Number(entry.extractParameter(params, LogEntryParameters.internalPileTurnerId));
      entry.apiUrl = entry.extractParameter(params, LogEntryParameters.apiUrl);
      for (const logger of this.publishers) {
        logger.log(entry);
      }
    }
  }

  private static shouldLog(level: LogLevel): boolean {
    let shouldLog = false;
    if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      shouldLog = true;
    }
    return shouldLog;
  }

  private static setPublishers(): LogPublisher[] {
    return [new LogConsole(), new LogHeaderMessageArea()];
  }
}
