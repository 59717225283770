<i
  class="pi pi-copy cursor-clickable log-message__icon"
  (click)="copyToClipboard()"
  [pTooltip]="'HEADER_MESSAGE.COPY_TO_CLIPBOARD' | translate"
  tooltipPosition="left"
></i>

<ng-container [ngSwitch]="messageType">
  <ng-container *ngSwitchCase="MESSAGE_TYPE.SerializedBackendWarning">
    <ng-container *ngFor="let serializedMessage of message?.serializedBackendWarning.serializedWarning">
      <ng-container *ngIf="hasTranslation; else messageWithoutTranslation">
        <div class="log-message__header">{{ serializedMessage.errorCode | translate : serializedMessage.parameters }}</div>
        <div>{{ serializedMessage.value }}</div>
        <div>{{ serializedMessage.errorCode }}</div>
      </ng-container>
      <ng-template #messageWithoutTranslation>
        <div class="log-message__header">{{ serializedMessage.value }}</div>
        <div>{{ serializedMessage.errorCode }}</div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="MESSAGE_TYPE.SerializedBackendError">
    <ng-container *ngIf="message?.serializedBackendError as errorMessage">
      <div class="log-message__header">{{ 'HEADER_MESSAGE.DEFAULT_BACKEND_ERROR' | translate }}</div>
      <div class="log-message__grid">
        <div>{{ 'ERROR.TYPE' | translate }}</div>
        <div>{{ errorMessage.type }}</div>
        <div>{{ 'ERROR.MESSAGE' | translate }}</div>
        <div>{{ errorMessage.message }}</div>
        <div>{{ 'ERROR.DETAILS' | translate }}</div>
        <div>{{ errorMessage.details }}</div>
        <div>{{ 'ERROR.STACKTRACE' | translate }}</div>
        <div>{{ errorMessage.stackTrace }}</div>
        <div>{{ 'ERROR.SOURCE' | translate }}</div>
        <div>{{ errorMessage.source }}</div>
        <div>{{ 'ERROR.INNER' | translate }}</div>
        <div>{{ errorMessage.innerException | json }}</div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="MESSAGE_TYPE.OtherError">
    <div class="log-message__header">{{ 'HEADER_MESSAGE.DEFAULT_BACKEND_ERROR' | translate }}</div>
    <div>{{ message?.message }}</div>
    <div class="log-message__json" *ngIf="message?.extraInfo?.length > 0">{{ formatMessage(message?.extraInfo) }}</div>
  </ng-container>

  <ng-container *ngSwitchCase="MESSAGE_TYPE.Success">
    <div class="log-message__header">{{ message?.message | translate : message?.extraInfo[0] }}</div>
  </ng-container>

  <ng-container *ngSwitchCase="MESSAGE_TYPE.DefaultMessage">
    <div class="log-message__header">{{ message?.message }}</div>
    <div *ngIf="message?.extraInfo?.length > 0">{{ formatMessage(message?.extraInfo) }}</div>
  </ng-container>
</ng-container>
