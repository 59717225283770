/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PandaApiConfiguration } from '../panda-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WnsReportDto } from '../models/wns-report-dto';

@Injectable({
  providedIn: 'root'
})
export class MeasurementService extends BaseService {
  constructor(config: PandaApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiMeasurementListOfWnsByMeasurementPost
   */
  static readonly ApiMeasurementListOfWnsByMeasurementPostPath = '/api/Measurement/ListOfWnsByMeasurement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementListOfWnsByMeasurementPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementListOfWnsByMeasurementPost$Plain$Response(params?: { body?: string }): Observable<StrictHttpResponse<Array<WnsReportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementListOfWnsByMeasurementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<WnsReportDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementListOfWnsByMeasurementPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementListOfWnsByMeasurementPost$Plain(params?: { body?: string }): Observable<Array<WnsReportDto>> {
    return this.apiMeasurementListOfWnsByMeasurementPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WnsReportDto>>) => r.body as Array<WnsReportDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementListOfWnsByMeasurementPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementListOfWnsByMeasurementPost$Response(params?: { body?: string }): Observable<StrictHttpResponse<Array<WnsReportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementListOfWnsByMeasurementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<WnsReportDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementListOfWnsByMeasurementPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementListOfWnsByMeasurementPost(params?: { body?: string }): Observable<Array<WnsReportDto>> {
    return this.apiMeasurementListOfWnsByMeasurementPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WnsReportDto>>) => r.body as Array<WnsReportDto>)
    );
  }

  /**
   * Path part for operation apiMeasurementListOfWnsByResourcePost
   */
  static readonly ApiMeasurementListOfWnsByResourcePostPath = '/api/Measurement/ListOfWnsByResource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementListOfWnsByResourcePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementListOfWnsByResourcePost$Plain$Response(params?: { body?: string }): Observable<StrictHttpResponse<Array<WnsReportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementListOfWnsByResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<WnsReportDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementListOfWnsByResourcePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementListOfWnsByResourcePost$Plain(params?: { body?: string }): Observable<Array<WnsReportDto>> {
    return this.apiMeasurementListOfWnsByResourcePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WnsReportDto>>) => r.body as Array<WnsReportDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementListOfWnsByResourcePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementListOfWnsByResourcePost$Response(params?: { body?: string }): Observable<StrictHttpResponse<Array<WnsReportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementListOfWnsByResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<WnsReportDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementListOfWnsByResourcePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementListOfWnsByResourcePost(params?: { body?: string }): Observable<Array<WnsReportDto>> {
    return this.apiMeasurementListOfWnsByResourcePost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WnsReportDto>>) => r.body as Array<WnsReportDto>)
    );
  }
}
