import { defaultDropdownValue, DowntimeCodeOptions, TaskFilters } from './task.model';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { WorkCenterTask } from 'chronos-core-client';

export interface TaskState extends EntityState<WorkCenterTask>, ActiveState {
  filters: TaskFilters;
}

const initialState = {
  filters: {
    downtimeCodeAssignation: DowntimeCodeOptions.Open,
    taskType: defaultDropdownValue,
    productionOrder: defaultDropdownValue,
    textQuery: ''
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tasks', idKey: 'id' })
export class TaskStore extends EntityStore<TaskState, WorkCenterTask> {
  constructor() {
    super(initialState);
  }

  public updateFilters(filters: TaskFilters): void {
    this.update({ filters });
  }

  public resetFilters(): void {
    this.update(initialState);
  }
}
