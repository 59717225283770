import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ParametersFactoryService } from '@app/core/services/parameters-factory';
import { Quantity } from 'chronos-core-client';
import { isNil } from 'ramda';

export const PALLET_QUANTITY_VALUE_PROVIDER: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PalletQuantityParameterComponent),
  multi: true
};

@Component({
  selector: 'app-pallet-quantity-parameter',
  templateUrl: './pallet-quantity-parameter.component.html',
  styleUrls: ['./pallet-quantity-parameter.component.scss'],
  providers: [PALLET_QUANTITY_VALUE_PROVIDER]
})
export class PalletQuantityParameterComponent implements ControlValueAccessor, AfterViewInit {
  @Input() public totalOrderQuantity: Quantity;
  @Input() public required = false;
  @Input() public inputId: string = null;
  @Input() public disabled = false;
  @Output() public touched = new EventEmitter<void>();

  public palletQuantity: number;
  public readonly INPUT_STYLE = { textAlign: 'right' };

  private propagateChanged: (result: number) => void;
  private propagateTouched: () => void;

  constructor(private parametersFactoryService: ParametersFactoryService) {}

  public ngAfterViewInit(): void {
    this.parametersFactoryService.palletQuantitySubject.next(this.palletQuantity);
  }

  public writeValue(value: any): void {
    if (!isNil(value)) {
      this.palletQuantity = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public onQuantityChange(quantity: number): void {
    this.propagateChanged(quantity);
    this.parametersFactoryService.palletQuantitySubject.next(quantity);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onTouched(): void {
    this.propagateTouched();
    this.touched.emit();
  }

  public getPalletEstimation(): number {
    const totalOrderQuantityValue = this.totalOrderQuantity?.value ?? 0;
    const palletQuantity = this.palletQuantity ?? 0;

    if (palletQuantity === 0 || totalOrderQuantityValue === 0) {
      return 0;
    }

    return Math.ceil(totalOrderQuantityValue / palletQuantity);
  }
}
