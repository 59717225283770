import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ArticleDescription, SplitBillOfMaterialRowEntry } from 'chronos-core-client';
import { notificationTopic } from '@app/shared/utils';
import { finalize } from 'rxjs/operators';
import { AppSettingsQuery, LoadingNotificationService, LogService } from 'chronos-shared';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TabSecondaryService } from '@app/modules/mounting/services';
import { ActiveOrderQuery } from '@app/core/global-state';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-parallel-consumption-modal',
  templateUrl: './parallel-consumption-modal.component.html',
  styleUrls: ['./parallel-consumption-modal.component.scss', '../../../../assets/styles/material-table.scss']
})
export class ParallelConsumptionModalComponent implements OnInit, OnDestroy {
  public readonly LOADING_TOPIC: string = notificationTopic.modalTraceAssign;

  public modalForm: UntypedFormGroup;
  public article: ArticleDescription;
  public showInactive: boolean;
  private billOfMaterialId: number;
  private productionOrderId: number;
  private workCenterId: number;
  private subscriptions = new Subscription();

  constructor(
    private tabSecondaryService: TabSecondaryService,
    private appSettingsQuery: AppSettingsQuery,
    private activeOrderQuery: ActiveOrderQuery,
    private activeWorkCenterService: ActiveWorkCenterService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  public ngOnInit(): void {
    this.showInactive = this.appSettingsQuery.showInactiveElement();
    this.productionOrderId = this.activeOrderQuery.getActiveOrderId();
    this.billOfMaterialId = this.config.data.billOfMaterialId;
    this.workCenterId = this.activeWorkCenterService.getWorkCenterId();

    this.subscriptions.add(
      this.tabSecondaryService.getBillOfMaterialSplitData(this.productionOrderId, this.billOfMaterialId).subscribe((result) => {
        this.article = result.article;
        this.modalForm.get('currentNumberOfDuplicatedBoms').setValue(result.currentNumberOfDuplicatedBoms);
      })
    );

    this.modalForm = new UntypedFormGroup({
      currentNumberOfDuplicatedBoms: new UntypedFormControl(1, [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.min(2),
        Validators.max(100)
      ])
    });
  }

  public splitBillOfMaterial(): void {
    if (this.modalForm.invalid || this.modalForm.disabled) {
      return;
    }

    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const entry: SplitBillOfMaterialRowEntry = {
      billOfMaterialId: this.billOfMaterialId,
      numberOfDuplicatedBoms: this.modalForm.value.currentNumberOfDuplicatedBoms,
      workCenterId: this.workCenterId
    };

    this.tabSecondaryService
      .splitBillOfMaterialRow(entry, this.productionOrderId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.closeAssignModal(true);
        LogService.success('MOUNTING.SPLIT_SUCCESS_MESSAGE');
      });
  }

  public closeAssignModal(isSubmit: boolean): void {
    this.ref.close(isSubmit);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
