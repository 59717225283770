/* tslint:disable */
type ProducedMaterialType = 'None' | 'ProducedMaterial' | 'OutputUnit';
module ProducedMaterialType {
  export const NONE: ProducedMaterialType = 'None';
  export const PRODUCED_MATERIAL: ProducedMaterialType = 'ProducedMaterial';
  export const OUTPUT_UNIT: ProducedMaterialType = 'OutputUnit';
  export function values(): ProducedMaterialType[] {
    return [NONE, PRODUCED_MATERIAL, OUTPUT_UNIT];
  }
}

export { ProducedMaterialType };
