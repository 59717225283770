<section class="primary-section status-panels">
  <div class="row" *ngIf="kpiMountedView$ | async as kpiView">
    <div class="col-3">
      <app-status-panel
        [label]="'MOUNTING.MOUNTED' | translate"
        [value]="kpiView.material.mounted.quantity | formatQuantity: false"
        [unitId]="kpiView.material.mounted.quantity.unitId"
        [status]="kpiView.mountedWarningStatus"
        [containers]="kpiView.material.mounted.containerCount | number"
      ></app-status-panel>
    </div>
    <div class="col-3">
      <ng-container *ngIf="showInactive; else emptyTimeLeft">
        <app-status-panel
          [label]="'MOUNTING.TIME_LEFT' | translate"
          [value]="kpiView.material.timeLeft | elapsedTime"
          [status]="kpiView.timeLeftWarningStatus"
        ></app-status-panel>
      </ng-container>
      <ng-template #emptyTimeLeft>
        <app-status-panel></app-status-panel>
      </ng-template>
    </div>
    <div class="col-3">
      <app-status-panel
        [label]="'MOUNTING.OPEN' | translate"
        [value]="kpiView.material.open.quantity | formatQuantity: false"
        [unitId]="kpiView.material.open.quantity.unitId"
        [containers]="kpiView.material.open.containerCount | number"
      ></app-status-panel>
    </div>
    <div class="col-3">
      <app-status-panel
        [label]="'MOUNTING.CONSUMED' | translate"
        [value]="kpiView.material.consumed.quantity | formatQuantity: false"
        [unitId]="kpiView.material.consumed.quantity.unitId"
        [containers]="kpiView.material.consumed.containerCount | number"
      ></app-status-panel>
    </div>
  </div>
</section>
<section class="primary-section scan">
  <div class="row">
    <div class="col-6">
      <div class="scan__container">
        <lib-scan-container
          [validationChanges$]="scanValidationChanges$"
          (mountButtonClicked)="mountContainer($event)"
          [scanPlaceholderText]="'MOUNTING.SCAN_INPUT_PLACEHOLDER' | translate"
          [buttonLabel]="'MOUNTING.MOUNT' | translate"
          [selectInputOnEnter]="true"
          [capsLockWarningMessage]="'MOUNTING.CAPSLOCK_ON_WARNING' | translate"
        ></lib-scan-container>
      </div>
    </div>
    <div class="col-5 offset-1">
      <div class="row" *ngIf="primaryMountedMaterialQuery.containerCounts$ | async as containerCounts">
        <div class="col-5">
          <lib-pallet-indicator
            [title]="'MOUNTING.MACHINE_LOCATION' | translate"
            [palletCount]="containerCounts.machineLocation"
            [large]="true"
            [titleLongText]="true"
            (clicked)="openMachineLocationModal()"
          ></lib-pallet-indicator>
        </div>
        <div class="col-4">
          <lib-pallet-indicator
            [title]="'MOUNTING.WAREHOUSE' | translate"
            [palletCount]="containerCounts.warehouse"
            (clicked)="openWarehouseLocationModal()"
            [large]="true"
            [borderless]="true"
          ></lib-pallet-indicator>
        </div>
        <div class="col-3 d-flex justify-content-end">
          <app-consumption-indicator
            (click)="openConsumptionModal()"
            [events]="primaryMountedMaterialQuery.consumedVirtualContainerCount$ | async"
          ></app-consumption-indicator>
        </div>
      </div>
    </div>
  </div>
</section>
<app-container-material-view></app-container-material-view>
