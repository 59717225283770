import { Pipe, PipeTransform } from '@angular/core';
import { KpiIdentifier, KpiValue } from 'chronos-core-client';

@Pipe({
  name: 'kpi'
})
export class KpiPipe implements PipeTransform {
  public transform(kpiList: KpiValue[], identifier: KpiIdentifier): KpiValue | undefined {
    return kpiList.find((kpi) => kpi.kpiIdentifier === identifier);
  }
}
