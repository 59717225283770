<form libEnterKeyHandler="submit_modal_dummy" [formGroup]="dummyForm" class="dummy-pallet-modal">
  <label class="scan" [ngClass]="{ validations__required: palletCode.invalid && palletCode.errors.required }">
    <div>
      <i class="scan__barcode icon-mounting"></i>
    </div>
    <input
      type="text"
      class="scan__pallet-code"
      [placeholder]="inputPlaceholder"
      formControlName="code"
      libChronosInput
      pInputText
      (blur)="checkSsccCode()"
    />
    <div>
      <i class="scan__info icon-info-outline"></i>
    </div>
  </label>

  <label
    *ngIf="showQuantity"
    class="mounted"
    [ngClass]="{
      validations__required: mounted.invalid && mounted.errors.required,
      validations__error: mounted.invalid && !mounted.errors.required
    }"
  >
    <span class="mounted__label">{{ mountedLabel }}</span>
    <lib-input-number [inputStyle]="INPUT_STYLE" formControlName="mounted" [unit]="dummy.bomUnitId" [showUnit]="true"></lib-input-number>
  </label>

  <label class="reason" [ngClass]="{ validations__required: reasons.invalid && reasons.errors.required }">
    <span class="reason__label">{{ reasonsLabel }}</span>
    <lib-dropdown
      [inlineStyle]="INPUT_STYLE"
      [showDefaultValue]="true"
      [defaultValueLabel]="reasonsDefaultValue"
      [options]="reasonsOptions"
      formControlName="reasonId"
    ></lib-dropdown>
  </label>

  <label
    *ngIf="askForProductionOrder"
    class="productionOrderString"
    [ngClass]="{
      validations__required: productionOrderString.invalid && productionOrderString.errors.required,
      validations__error: productionOrderString.invalid && !productionOrderString.errors.required
    }"
  >
    <span class="productionOrderString__label">{{ productionOrderCodeLabel }}</span>
    <div>
      <i class="productionOrderString__barcode icon-mounting"></i>
    </div>
    <input
      type="text"
      pInputText
      size="30"
      formControlName="productionOrderString"
      placeholder="{{ 'MOUNTING.PRODUCTIONORDERCODE_LABEL' | translate }}"
    />
  </label>

  <label
    *ngIf="articleOptions?.length > 1"
    class="reason"
    [ngClass]="{ validations__required: reasons.invalid && reasons.errors.required }"
  >
    <span class="reason__label">{{ articleLabel }}</span>
    <lib-dropdown
      [inlineStyle]="INPUT_STYLE"
      [showDefaultValue]="true"
      [defaultValueLabel]="reasonsDefaultValue"
      [options]="articleOptions"
      formControlName="articleId"
      (ngModelChange)="changeArticle($event)"
    ></lib-dropdown>
  </label>

  <label class="info">
    <span class="info__label">{{ infoLabel }}</span>
    <textarea
      (keydown.enter)="preventLineBreak($event)"
      [rows]="5"
      libChronosTextarea
      class="info__textarea"
      formControlName="reasonInfo"
      pInputTextarea
    ></textarea>
  </label>
</form>
<lib-modal-footer
  [submitId]="'submit_modal_dummy'"
  [whenLoadingTopic]="loadingTopic"
  [submitLabel]="submitButtonLabel"
  (submitClicked)="onSubmitModal()"
  (declineClicked)="onCloseModal()"
  [submitDisabled]="dummyForm.invalid"
></lib-modal-footer>
