import { SetupFooterCommandBase } from '@app/shared/components';
import { QaHistoryService } from '../services/qa-history/qa-history.service';

export class CancelCreateNCCommandService implements SetupFooterCommandBase {
  public enabled = true;
  public buttonText: string;
  public required = false;

  constructor(private qaHistoryService: QaHistoryService) {}

  public execute(): void {
    this.qaHistoryService.disableNCCreateMode();
  }
}
