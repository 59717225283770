import { CreateAndMountVirtualPrimaryContainerEntry } from 'chronos-core-client';
import { MountedMaterial } from '../models/container-mount-list.model';

export interface Dummy {
  code: string;
  mounted: number;
  lowestMountingQuantity?: number;
  highestMountingQuantity?: number;
  reasonId: number;
  reasonInfo: string;
  articleId: number;
  containerId: number;
  materialBlockId: number;
  bomUnitId: string;
  productionOrderString?: string;
  productionOrderId?: number;
}

export class DummyMapper {
  public static toVirtualContainerEntry(dummy: Dummy): CreateAndMountVirtualPrimaryContainerEntry {
    return {
      ssccCode: dummy.code,
      containerCreationReasonId: dummy.reasonId,
      containerQuantity: dummy.mounted,
      info: dummy.reasonInfo,
      materialBlockId: dummy.materialBlockId,
      productionOrderString: dummy.productionOrderString
    };
  }

  public static fromMountedMaterial(m: MountedMaterial): Dummy {
    return {
      code: m.identificationCode,
      mounted: m.mountedQuantity.value,
      reasonInfo: m.container.virtualContainerInfo,
      reasonId: m.container.virtualContainerReasonId,
      containerId: m.container.containerId,
      articleId: m.article.id,
      materialBlockId: m.materialBlockId,
      bomUnitId: m.article.bomUnitId
    };
  }
}
