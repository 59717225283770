import { Component, Input, OnInit } from '@angular/core';
import { ApproveCommandCallback, SetupFooterCommandBase } from '@app/shared/components';
import { ActiveOrderPanelService } from '@app/core/services';

@Component({
  selector: 'app-setup-footer',
  templateUrl: './setup-footer.component.html',
  styleUrls: ['./setup-footer.component.scss']
})
export class SetupFooterComponent implements OnInit {
  @Input() public cancelCommand: SetupFooterCommandBase;
  @Input() public backCommand: SetupFooterCommandBase;
  @Input() public nextCommand: SetupFooterCommandBase;
  @Input() public confirmFirstCheckboxCommand: SetupFooterCommandBase;
  @Input() public confirmSecondCheckboxCommand: SetupFooterCommandBase;
  public isQaHistoryConfirmedAutomatically: boolean;

  constructor(private activeOrderPanelService: ActiveOrderPanelService) {}

  public ngOnInit(): void {
    this.activeOrderPanelService.isQaHistoryConfirmedAutomaticallySubject$.subscribe(
      (data) => (this.isQaHistoryConfirmedAutomatically = data)
    );

    if (this.isQaHistoryConfirmedAutomatically) {
      this.confirmFirstCheckboxCommand.enabled = true;
      this.executeCommand(this.confirmFirstCheckboxCommand, false);
    }
  }

  public onCancelClick(): void {
    this.executeCommand(this.cancelCommand, true);
  }

  public onBackClick(): void {
    this.executeCommand(this.backCommand, true);
  }

  public onNextClick(): void {
    this.executeApproveCommand(this.nextCommand, true);
  }

  public onFirstCheckboxChange(): void {
    this.executeCommand(this.confirmFirstCheckboxCommand, false);
  }

  public onSecondCheckboxChange(): void {
    this.executeCommand(this.confirmSecondCheckboxCommand, false);
  }

  private executeCommand(command: SetupFooterCommandBase, isButton: boolean): void {
    if (isButton && !command.enabled) {
      return;
    }
    command.execute();
  }

  private executeApproveCommand(command: SetupFooterCommandBase, isButton: boolean): void {
    if (isButton && !command.enabled) {
      return;
    }
    command.commandCallback = this.uncheckCheckbox(this.confirmFirstCheckboxCommand, this.nextCommand);
    command.execute();
  }

  private uncheckCheckbox(footerCommand: SetupFooterCommandBase, nextCommand: SetupFooterCommandBase): ApproveCommandCallback {
    return (flag) => {
      if (flag) {
        footerCommand.enabled = nextCommand.enabled = false;
        footerCommand.required = true;
      }
    };
  }
}
