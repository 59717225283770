import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AdditionalDataComponent,
  BillOfMaterialComponent,
  BlockContentComponent,
  EditButtonComponent,
  HeaderWasteDisplayComponent,
  KpiColumnComponent,
  KpiTileComponent,
  MachineChartDialogComponent,
  MaterialTableComponent,
  MountingMaterialComponent,
  OrderSidebarItemComponent,
  PalletQaCheckComponent,
  QaCheckElementComponent,
  QaCheckListComponent,
  ResultSelectButtonComponent,
  ScrollableSidebarComponent,
  SearchBarComponent,
  SetupFooterComponent,
  SetupHeaderComponent,
  SplitButtonComponent,
  StatusPanelComponent,
  TimeInputComponent,
  WasteAssignmentTableComponent
} from '@app/shared/components';
import { GuardsUtils } from '@app/shared/guards';
import { ElapsedTimePipe } from '@app/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { ChronosInputDirective, ChronosSharedModule, ChronosTextareaDirective } from 'chronos-shared';
import { PrintLabelHeaderComponent } from './components/print-label-header/print-label-header.component';
import { PrintLabelRangeComponent } from './components/print-label-range/print-label-range.component';
import { PrintLabelComponent } from './components/print-label/print-label.component';
import { ListItemDirective } from '@app/shared/directives/list-item/list-item.directive';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ReduceConsumptionModalComponent, SecondaryPalletQuantityCalculationComponent, TaskAssignModalComponent } from '@app/shared/modals';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CarouselModule } from 'primeng/carousel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BlockUIModule } from 'primeng/blockui';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputNumberModule } from 'primeng/inputnumber';
import { TreeTableModule } from 'primeng/treetable';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DismountDummyPalletModalComponent } from './components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';
import { ModalMainMaterialComponent } from './components/modal-main-material/modal-main-material.component';
import { LineClearanceListComponent } from './components/line-clearance-list/line-clearance-list.component';
import { LineClearanceItemComponent } from './components/line-clearance-item/line-clearance-item.component';
import { LineClearanceSelectButtonComponent } from './components/line-clearance-select-button/line-clearance-select-button.component';
import { ChangeBobbinQuantityComponent } from './modals/change-bobbin-quantity/change-bobbin-quantity.component';
@NgModule({
  declarations: [
    SetupHeaderComponent,
    HeaderWasteDisplayComponent,
    WasteAssignmentTableComponent,
    SetupFooterComponent,
    SplitButtonComponent,
    SearchBarComponent,
    ResultSelectButtonComponent,
    BillOfMaterialComponent,
    QaCheckElementComponent,
    AdditionalDataComponent,
    ElapsedTimePipe,
    EditButtonComponent,
    PalletQaCheckComponent,
    QaCheckListComponent,
    StatusPanelComponent,
    KpiColumnComponent,
    KpiTileComponent,
    BlockContentComponent,
    TaskAssignModalComponent,
    MountingMaterialComponent,
    MaterialTableComponent,
    PrintLabelComponent,
    PrintLabelHeaderComponent,
    PrintLabelRangeComponent,
    TimeInputComponent,
    ScrollableSidebarComponent,
    ListItemDirective,
    OrderSidebarItemComponent,
    ProgressSpinnerComponent,
    ReduceConsumptionModalComponent,
    MachineChartDialogComponent,
    SecondaryPalletQuantityCalculationComponent,
    DismountDummyPalletModalComponent,
    ModalMainMaterialComponent,
    LineClearanceListComponent,
    LineClearanceItemComponent,
    LineClearanceSelectButtonComponent,
    ChangeBobbinQuantityComponent
  ],
  imports: [
    // Angular Modules
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // PrimeNg Modules
    TabViewModule,
    ButtonModule,
    DynamicDialogModule,
    InputTextModule,
    DropdownModule,
    KeyFilterModule,
    SplitButtonModule,
    DialogModule,
    SelectButtonModule,
    CheckboxModule,
    TableModule,
    InputTextareaModule,
    InputNumberModule,
    TreeTableModule,
    DragDropModule,
    TabMenuModule,
    CardModule,
    TooltipModule,
    RadioButtonModule,
    AutoCompleteModule,
    BlockUIModule,
    ProgressSpinnerModule,
    CarouselModule,

    // 3rd party Modules
    ChronosSharedModule,
    TranslateModule.forChild()
  ],
  exports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // PrimeNG
    TabViewModule,
    ButtonModule,
    DynamicDialogModule,
    InputTextModule,
    DropdownModule,
    KeyFilterModule,
    SplitButtonModule,
    DialogModule,
    SelectButtonModule,
    CheckboxModule,
    TableModule,
    InputTextareaModule,
    InputNumberModule,
    TreeTableModule,
    DragDropModule,
    TabMenuModule,
    CardModule,
    TooltipModule,
    RadioButtonModule,
    AutoCompleteModule,
    ListboxModule,
    CarouselModule,

    // 3rd party Modules
    TranslateModule,
    ChronosSharedModule,

    // Shared Components
    SetupHeaderComponent,
    HeaderWasteDisplayComponent,
    WasteAssignmentTableComponent,
    SetupFooterComponent,
    SplitButtonComponent,
    SearchBarComponent,
    ResultSelectButtonComponent,
    BillOfMaterialComponent,
    QaCheckElementComponent,
    AdditionalDataComponent,
    PalletQaCheckComponent,
    EditButtonComponent,
    QaCheckListComponent,
    KpiColumnComponent,
    KpiTileComponent,
    StatusPanelComponent,
    BlockContentComponent,
    MountingMaterialComponent,
    MaterialTableComponent,
    TimeInputComponent,
    ScrollableSidebarComponent,
    PrintLabelComponent,
    OrderSidebarItemComponent,
    ProgressSpinnerComponent,
    ReduceConsumptionModalComponent,
    MachineChartDialogComponent,
    ModalMainMaterialComponent,
    LineClearanceListComponent,
    LineClearanceItemComponent,
    LineClearanceSelectButtonComponent,
    ChangeBobbinQuantityComponent,
    // Shared Pipes
    ElapsedTimePipe,

    // Shared Directives
    ChronosInputDirective,
    ChronosTextareaDirective,
    ListItemDirective
  ],
  providers: [GuardsUtils, DialogService, DynamicDialogConfig]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
