<div *ngIf="!isDisplayChart && (chartMode === ChartMode.SplitDowntime || chartMode === ChartMode.EditDowntime)">
  {{ 'MACHINE_CHART.SPLIT_DOWNTIME_MESSAGE' | translate }}
</div>

<div *ngIf="isDisplayChart">
  <div class="adjust_info" style="color: blue" *ngIf="!!editedExternalOrderId">
    {{ editedExternalOrderId }}
  </div>
  <div class="p-grid adjust_info">
    <div
      class="p-col-10"
      *ngIf="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove || chartMode === ChartMode.EditDowntime"
    >
      <span *ngIf="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove">
        {{ 'MACHINE_CHART.EDIT_SETUP_HEADER' | translate }}
      </span>
      <span *ngIf="chartMode === ChartMode.EditDowntime">
        {{ 'MACHINE_CHART.EDIT_DOWNTIME_SETUP_HEADER' | translate }}
      </span>
    </div>
  </div>
  <br />

  <form [formGroup]="formGroup" *ngIf="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove">
    <!-- Start time edit controls-->
    <div class="edit_setup-phase-duration" *ngIf="!!orderSetupPhaseState; else loader">
      <span *ngIf="isStartTimeEditable" class="edit_setup-phase-duration__col-form-label-start">
        {{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }}
        <lib-calendar
          [showTime]="false"
          [ngModel]="startTime"
          (ngModelChange)="startTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [inputStyle]="{ width: '100px' }"
          [disabled]="false"
        ></lib-calendar>
      </span>
      <app-time-input
        *ngIf="isStartTimeEditable"
        class="split_time"
        [showControls]="false"
        [ngModel]="startTime"
        (ngModelChange)="startTimeChanged($event, false)"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        [disabled]="false"
      ></app-time-input>

      <span
        *ngIf="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove"
        class="edit_setup-phase-duration__col-form-label-end"
      >
        {{ 'MACHINE_CHART.SETUP_QUANTITY' | translate }}
        <lib-input-number
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="quantity.value"
          [min]="0"
          [size]="5"
          (focusout)="setupQuantityChanged()"
          formControlName="setupQuantity"
          [inputStyle]="INPUT_STYLE"
          [unit]="unitId"
        ></lib-input-number>
      </span>

      <span *ngIf="chartMode === ChartMode.EditSetupApprove" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'MACHINE_CHART.WASTE' | translate }}
        <lib-input-number
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="waste.value"
          [min]="0"
          [size]="5"
          [max]="quantity"
          (focusout)="onWasteChanged()"
          formControlName="waste"
          [inputStyle]="INPUT_STYLE"
          [unit]="unitId"
        ></lib-input-number>
      </span>
      <span *ngIf="chartMode === ChartMode.EditSetupApprove" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'MACHINE_CHART.MACULATURE' | translate }}
        <lib-input-number
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="maculature.value"
          [min]="0"
          [size]="5"
          [max]="quantity"
          (focusout)="onMaculatureChanged()"
          formControlName="maculature"
          [inputStyle]="INPUT_STYLE"
          [unit]="unitId"
        ></lib-input-number>
      </span>

      <!-- End time edit controls-->
      <span *ngIf="isEndTimeEditable" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }}
        <lib-calendar
          [showTime]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, true)"
          [inputStyle]="{ width: '100px' }"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
      </span>
      <app-time-input
        *ngIf="isEndTimeEditable"
        class="split_time"
        [showControls]="false"
        [ngModel]="endTime"
        (ngModelChange)="endTimeChanged($event, false)"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      ></app-time-input>
    </div>
  </form>

  <form [formGroup]="formGroup" *ngIf="!(chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove)">
    <!-- Start time edit controls-->
    <div class="edit_setup-phase-duration">
      <span *ngIf="isStartTimeEditable" class="edit_setup-phase-duration__col-form-label-start">
        {{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }}
        <lib-calendar
          [showTime]="false"
          [ngModel]="startTime"
          (ngModelChange)="startTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [inputStyle]="{ width: '100px' }"
          [disabled]="chartMode === ChartMode.EditDowntime"
        ></lib-calendar>
      </span>
      <app-time-input
        *ngIf="isStartTimeEditable"
        class="split_time"
        [showControls]="false"
        [ngModel]="startTime"
        (ngModelChange)="startTimeChanged($event, false)"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        [disabled]="chartMode === ChartMode.EditDowntime"
      ></app-time-input>
      <span *ngIf="!isStartTimeEditable" class="edit_setup-phase-duration__col-form-label-start split-downtime-label">
        {{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }}
        {{ startTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}
      </span>
      <span class="waste-quantity" *ngIf="chartMode === ChartMode.EditDowntime">
        {{ 'MACHINE_CHART.EDIT_DOWNTIME_WASTE' | translate }}
        <p-inputNumber
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="quantity.value"
          [min]="0"
          [size]="5"
          [max]="quantity"
          (focusout)="setupQuantityChanged()"
          formControlName="setupQuantity"
          [locale]="locale"
          [minFractionDigits]="minFractionDigits"
          [maxFractionDigits]="maxFractionDigits"
          [inputStyle]="INPUT_STYLE"
        ></p-inputNumber>
      </span>

      <span *ngIf="chartMode === ChartMode.SplitDowntime" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'MACHINE_CHART.SPLIT_TIME' | translate }}
        <lib-calendar
          [showTime]="false"
          [ngModel]="splitTime"
          (ngModelChange)="splitTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
      </span>
      <app-time-input
        class="split_time"
        *ngIf="chartMode === ChartMode.SplitDowntime"
        [showControls]="false"
        [ngModel]="splitTime"
        (ngModelChange)="splitTimeChanged($event, false)"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      ></app-time-input>

      <!-- End time edit controls-->
      <span *ngIf="isEndTimeEditable" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }}
        <lib-calendar
          [showTime]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, true)"
          [inputStyle]="{ width: '100px' }"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
      </span>
      <app-time-input
        *ngIf="isEndTimeEditable"
        class="split_time"
        [showControls]="false"
        [ngModel]="endTime"
        (ngModelChange)="endTimeChanged($event, false)"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      ></app-time-input>
      <span *ngIf="!isEndTimeEditable" class="edit_setup-phase-duration__col-form-label-end split-downtime-label">
        {{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }}
        {{ endTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}
      </span>
    </div>
  </form>

  <ng-container *ngIf="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove; else otherChart">
    <lib-machine-chart
      *ngIf="!!orderSetupPhaseState"
      [workCenterId]="workCenterId"
      [isMachineChartInSetupPhase]="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove"
      [setupStartTime]="startTime"
      [setupEndTime]="endTime"
      [splitTime]="splitTime"
      [isChartIsInSplitDowntime]="false"
      [earlistDateLimit]="earliestLimit"
      [latestDateLimit]="latestLimit"
      [orderSetupPhaseState]="orderSetupPhaseState"
      (setupStartTimeChanged)="startTimeChanged($event, false)"
      (splitTimeChanged)="splitTimeChanged($event, false)"
      (setupEndTimeChanged)="endTimeChanged($event, false)"
      [chartMode]="chartName"
    ></lib-machine-chart>
  </ng-container>

  <ng-template #otherChart>
    <lib-machine-chart
      [workCenterId]="workCenterId"
      [isMachineChartInSetupPhase]="chartMode === ChartMode.EditSetup || chartMode === ChartMode.EditSetupApprove"
      [setupStartTime]="startTime"
      [setupEndTime]="endTime"
      [splitTime]="splitTime"
      [isChartIsInSplitDowntime]="chartMode === ChartMode.SplitDowntime"
      [earlistDateLimit]="earliestLimit"
      [latestDateLimit]="latestLimit"
      (setupStartTimeChanged)="startTimeChanged($event, false)"
      (splitTimeChanged)="splitTimeChanged($event, false)"
      (setupEndTimeChanged)="endTimeChanged($event, false)"
      [chartMode]="chartName"
    ></lib-machine-chart>
  </ng-template>
</div>

<ng-template #loader>
  <div class="loader">
    <lib-loading-indicator></lib-loading-indicator>
  </div>
</ng-template>

<lib-modal-footer
  [submitLabel]="'MODAL_FOOTER.CONFIRM'"
  (submitClicked)="onSubmit()"
  (declineClicked)="onDecline()"
  (resetClicked)="editDowntimeReset()"
  [submitDisabled]="submitDisabled"
  [whenLoadingTopic]="LOADING_TOPIC"
  [resetButtonLoadingTopic]="LOADING_TOPIC_EDITDOWNTIME"
  [isResetVisible]="chartMode === ChartMode.EditDowntime"
></lib-modal-footer>
