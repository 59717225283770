import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { EmployeeState, EmployeeStore } from './employee.store';
import { EmployeeFilters } from './employee.model';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmployeeQuery extends Query<EmployeeState> {
  public filters$: Observable<EmployeeFilters> = this.select((state) => state.filters);

  public loggedInEmployees$ = this.select((state) => state.activeEmployeeRegistrations);

  public availableEmployees$ = this.select((state) => state.availableEmployees);

  public availableEmployeesFiltered$ = this.filters$.pipe(
    switchMap((filters) => {
      if (!filters.textQuery && !filters.rfid && !filters.isRFIDEnabled) {
        return this.availableEmployees$;
      }

      if (filters.rfid || filters.isRFIDEnabled) {
        return this.availableEmployees$.pipe(
          map((record) => record?.filter((emp) => emp.externalEmployeeId?.toLowerCase() === filters.rfid?.toLowerCase()))
        );
      }

      if (filters.textQuery) {
        return this.availableEmployees$.pipe(
          map((record) =>
            record.filter(
              (emp) =>
                emp.externalEmployeeId?.toLowerCase().includes(filters.textQuery.toLowerCase()) ||
                emp.name?.toLowerCase().includes(filters.textQuery.toLowerCase()) ||
                emp.loggedOnExternalWorkCenterId?.toString().includes(filters.textQuery)
            )
          )
        );
      }
    })
  );

  constructor(protected store: EmployeeStore) {
    super(store);
  }
}
