<section class="registration-table">
  <p-table [value]="employeeHistory">
    <ng-template pTemplate="header">
      <tr>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.PROFILEDAY' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.SHIFT' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ROLE' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.NAME' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ID' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ACTIVEFROM' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ACTIVETO' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.NOTE' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.REGISTRATION_PURPOSE' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="registration-table__text-cell">
          {{ rowData.profileDay | utcToLocal | date: 'dd.MM.yyyy' }}
        </td>
        <td class="registration-table__text-cell">
          {{ rowData.shiftTypeName }}
        </td>
        <td class="registration-table__text-cell">
          {{ 'EMPLOYEE_ROLE.' + rowData.role | uppercase | translate }}
        </td>
        <td class="registration-table__text-cell">
          {{ rowData.name }}
        </td>
        <td class="registration-table__text-cell">
          {{ rowData.externalEmployeeId }}
        </td>
        <td class="registration-table__text-cell">
          {{ rowData.activeFrom | utcToLocal | date: 'dd.MM.YYYY HH:mm' }}
        </td>
        <td class="registration-table__text-cell">
          <span *ngIf="rowData.activeTo; else noData">
            {{ rowData.activeTo | utcToLocal | date: 'dd.MM.YYYY HH:mm' }}
          </span>
        </td>
        <td class="registration-table__text-cell">
          <span *ngIf="rowData.note; else noData">
            {{ rowData.note }}
          </span>
        </td>
        <td class="registration-table__text-cell">
          <span *ngIf="rowData.registrationPurpose; else noData">
            {{ rowData.registrationPurpose }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template #noData>
    <span>-</span>
  </ng-template>
</section>
