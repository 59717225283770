import { Injectable } from '@angular/core';
import { TaskCategoryStore } from './task-category.store';
import { WorkCenterTasksByCategory } from 'chronos-core-client';

@Injectable({ providedIn: 'root' })
export class TaskCategoryService {
  constructor(private taskCategoryStore: TaskCategoryStore) {}

  public updateTaskCategories(tasks: WorkCenterTasksByCategory[]): void {
    this.taskCategoryStore.set(tasks);
  }
}
