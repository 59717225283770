<div
  class="status-panel"
  [ngClass]="{
    'status-panel--warning': status === 'warning',
    'status-panel--negative': status === 'negative',
    'status-panel--small': height === 'small',
    'status-panel--tiny': height === 'tiny'
  }"
>
  <div class="status-panel__label">
    {{ label }}
  </div>
  <div class="status-panel__wrapper">
    <div>
      <span
        class="status-panel__amount"
        [ngClass]="{
          'status-panel__amount--xs': textStyle === 'xs',
          'status-panel__amount--tiny': textStyle === 'tiny',
          'status-panel__amount--small': textStyle === 'small',
          'status-panel__amount--large': textStyle === 'large'
        }"
      >
        {{ value }}
      </span>
      <span
        class="status-panel__unit"
        [ngClass]="{
          'status-panel__unit--xs': textStyle === 'xs',
          'status-panel__unit--tiny': textStyle === 'tiny',
          'status-panel__unit--small': textStyle === 'small',
          'status-panel__unit--large': textStyle === 'large'
        }"
      >
        {{ unitId }}
      </span>
    </div>

    <div *ngIf="status" class="status-panel__icon-wrapper">
      <i *ngIf="status === 'warning'" class="icon icon-warning-solid"></i>
      <i *ngIf="status === 'success'" class="icon icon-checked-solid"></i>
    </div>

    <div class="status-panel__pallet-quantity" *ngIf="containers">
      <span class="value">{{ containers }}</span>
      <i class="background-icon icon-pallet"></i>
    </div>
  </div>
</div>
