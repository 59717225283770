<div *ngIf="fields" class="qa-check">
  <div *ngFor="let qaCheck of qaCheckService.getQaCheckFormControls(qaCheckForm)" [formGroup]="qaCheck" class="qa-check-item">
    <div [ngClass]="{ required: qaCheckForm.invalid }" class="qa-check-item__label">
      {{ getQaCheckLabel(qaCheck.get('id').value) }}
    </div>
    <div class="qa-check-item__value" [ngClass]="{ 'qa-check-item__value--required': qaCheck.get('check').hasError('required') }">
      <app-result-select-button formControlName="check" [name]="qaCheck.get('id').value"></app-result-select-button>
    </div>
  </div>
</div>
