import { Component, NgZone } from '@angular/core';
import { akitaDevtools, enableAkitaProdMode } from '@datorama/akita';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'chronos-shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService, private languageService: LanguageService, private ngZone: NgZone) {
    this.setTranslationLanguage();

    if (!environment.production) {
      akitaDevtools(ngZone);
    } else {
      enableAkitaProdMode();
    }
  }

  private setTranslationLanguage() {
    this.languageService.initCurrentDefaultLanguage(environment.defaultLanguage);
    this.languageService.language$.subscribe((lang) => {
      this.translate.setDefaultLang('en');
      this.translate.use(lang);

      if (lang === 'dbg') {
        this.translate.setDefaultLang(lang);
      }
    });
  }
}
