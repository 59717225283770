<section class="approve-report-table">
  <p-treeTable
    [styleClass]="'approve-report-tree-table'"
    [value]="tableData"
    (onNodeExpand)="loadNode($event)"
    [scrollable]="true"
    [scrollHeight]="'flex'"
    [style]="{ width: 'calc(100vw - 128px)', height: 'calc(100vh - 350px)' }"
  >
    <ng-template pTemplate="header">
      <tr class="approve-report-table__header-row">
        <th class="toggle-cell">
          <i
            class="toggle-cell__icon cursor-clickable"
            [ngClass]="tableExpanded ? 'icon-collapse-all' : 'icon-expand-all'"
            (click)="expandCollapseTable()"
          ></i>
        </th>
        <th
          (click)="onHighlight(col)"
          [class]="col.styleClass"
          [ngClass]="{
            highlighted: col.highlighted
          }"
          *ngFor="let col of columns"
        >
          {{ col.label | translate }}
        </th>
        <th *ngIf="buttonTemplate" class="action-cell"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ngSwitch]="rowData.itemType" class="approve-report-table__order-row" [ngClass]="rowData.rowStyleClass">
        <td class="toggle-cell">
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        </td>
        <ng-container *ngSwitchCase="ITEM_TYPE.PRODUCTION_ORDER">
          <td class="tiny-cell">
            <i class="icon" [ngClass]="rowData.kpiStatus === KPI_STATUS.OK ? 'icon-checked-solid' : 'icon-warning-solid icon--warning'"></i>
          </td>
          <td class="tiny-cell">
            <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
          </td>
          <td>{{ rowData.productionOrderIdOrDowntimeType }}</td>
          <td class="overflow">{{ rowData.customerOrDowntimeReasonLocalId }}</td>
          <td class="small-cell">
            <div *ngIf="rowData.isFinished; else notFinished">{{ 'APPROVE.FINISHED' | translate }}</div>
            <ng-template #notFinished>
              <div>{{ 'APPROVE.NOT_FINISHED' | translate }}</div>
            </ng-template>
          </td>
          <td class="small-cell">{{ rowData.startTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.endTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.setupDuration | elapsedTime }}</td>
          <td class="small-cell">{{ rowData.runtimeDuration | elapsedTime }}</td>
          <td class="small-cell">{{ rowData.downtimeDuration | elapsedTime }}</td>
          <td [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.wasteQuantity?.value | number">{{ rowData.wasteQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.maculatureQuantity?.value | number">{{ rowData.maculatureQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.plannedQuantity?.value | number">{{ rowData.plannedQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.orderGoodQuantity?.value | number">{{ rowData.orderGoodQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.goodQuantityPredecessors?.value | number">{{ rowData.goodQuantityPredecessors | formatQuantity: false }}</td>
          <td [title]="rowData.consumption?.value | number">{{ rowData.consumption | formatQuantity: false }}</td>
          <td class="small-cell" [title]="rowData.orderGrossQuantity?.value | number">
            {{ rowData.orderGrossQuantity | formatQuantity: false }}
          </td>
          <td class="medium-cell" [title]="rowData.output1?.value | number">{{ rowData.output1 | formatQuantity: false }}</td>
          <td class="medium-cell" [title]="rowData.output2?.value | number">{{ rowData.output2 | formatQuantity: false }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="ITEM_TYPE.DOWNTIME">
          <td class="tiny-cell">
            <i class="icon" [ngClass]="rowData.kpiStatus === KPI_STATUS.OK ? 'icon-checked-solid' : 'icon-warning-solid icon--warning'"></i>
          </td>
          <td class="tiny-cell">
            <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
          </td>
          <td>{{ 'BACKEND_TYPE.APPROVE_DOWNTIME.' + rowData.productionOrderIdOrDowntimeType | uppercase | translate }}</td>
          <td>{{ rowData.customerOrDowntimeReasonLocalId }}</td>
          <td class="small-cell">
            <div *ngIf="rowData.isFinished; else notFinished">{{ 'APPROVE.FINISHED' | translate }}</div>
            <ng-template #notFinished>
              <div>{{ 'APPROVE.NOT_FINISHED' | translate }}</div>
            </ng-template>
          </td>
          <td class="small-cell">{{ rowData.startTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.endTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.setupDuration | elapsedTime }}</td>
          <td class="small-cell">{{ rowData.runtimeDuration | elapsedTime }}</td>
          <td class="small-cell">{{ rowData.downtimeDuration | elapsedTime }}</td>
          <td [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.wasteQuantity?.value | number">{{ rowData.wasteQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.maculatureQuantity?.value | number">{{ rowData.maculatureQuantity | formatQuantity: false }}</td>
          <td colspan="7">{{ rowData.description }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="ITEM_TYPE.PRODUCTION_ORDER_RUN_PHASE">
          <td class="tiny-cell">
            <i
              class="icon"
              [ngClass]="
                rowData.runPhaseType === PHASE_TYPE.CONSUMPTION && rowData.hasViolation
                  ? 'icon-warning-solid icon--warning'
                  : 'icon-checked-solid'
              "
            ></i>
          </td>

          <td class="tiny-cell" *ngIf="rowData.runPhaseType === PHASE_TYPE.CONSUMPTION">
            <span class="primary-material" *ngIf="rowData.hasViolation && rowData.availableItemsCount !== null">
              <i class="icon icon-pallet"></i>
              <span class="pallet-value">{{ rowData.availableItemsCount || 0 }}</span>
            </span>
          </td>
          <td *ngIf="rowData.runPhaseType === PHASE_TYPE.SETUP" class="tiny-cell"></td>
          <td *ngIf="rowData.runPhaseType === PHASE_TYPE.RUN" class="tiny-cell"></td>
          <td *ngIf="rowData.runPhaseType === PHASE_TYPE.DOWNTIME" class="tiny-cell"></td>

          <td>{{ 'BACKEND_TYPE.APPROVE_ORDER_PHASE.' + rowData.runPhaseType | uppercase | translate }}</td>
          <td>{{ rowData.externalSetupParameterKindId }}</td>
          <td class="small-cell"></td>
          <td class="small-cell">{{ rowData.startTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.endTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.runPhaseType === PHASE_TYPE.SETUP ? (rowData.duration | elapsedTime) : '' }}</td>
          <td class="small-cell">{{ rowData.runPhaseType === PHASE_TYPE.RUN ? (rowData.duration | elapsedTime) : '' }}</td>
          <td class="small-cell">{{ rowData.runPhaseType === PHASE_TYPE.DOWNTIME ? (rowData.duration | elapsedTime) : '' }}</td>
          <td [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.wasteQuantity?.value | number">{{ rowData.wasteQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.maculatureQuantity?.value | number">{{ rowData.maculatureQuantity | formatQuantity: false }}</td>
          <td colspan="7"></td>
        </ng-container>

        <ng-container *ngSwitchCase="ITEM_TYPE.CONSUMPTION_ARTICLE">
          <td class="tiny-cell"></td>
          <td class="tiny-cell"></td>
          <td class="tiny-cell overflow">{{ rowData.externalArticleId }}</td>
          <td [colSpan]="3" class="overflow">{{ rowData.articleDescription?.articleName }}</td>
          <td class="small-cell overflow">{{ rowData.articleDescription?.externalConfigurationId }}</td>
          <td [colSpan]="3" class="overflow">{{ rowData.articleDescription?.configurationName }}</td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell" [title]="rowData.totalConsumption?.value | number">
            {{ rowData.totalConsumption | formatQuantity: false }}
          </td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
        </ng-container>

        <ng-container *ngSwitchCase="ITEM_TYPE.CONSUMPTION_MATERIAL">
          <td class="tiny-cell"></td>
          <td class="tiny-cell"></td>
          <td class="tiny-cell">{{ rowData.sequence }}</td>
          <td class="tiny-cell overflow" *ngIf="rowData.ssccCode" colspan="4"><span [innerHtml]="rowData.ssccCode | sscc"></span></td>
          <td class="tiny-cell overflow" *ngIf="rowData.virtualContainerIdentCode" colspan="4">
            <span [innerHtml]="rowData.virtualContainerIdentCode | sscc"></span>
          </td>
          <td class="small-cell">{{ rowData.externalArticleId }}</td>
          <td class="small-cell">{{ rowData.configurationId }}</td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell" [title]="rowData.consumed?.value | number">{{ rowData?.consumed | formatQuantity: false }}</td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
          <td class="small-cell"></td>
        </ng-container>

        <ng-container *ngSwitchCase="ITEM_TYPE.DOWNTIME_DETAIL">
          <td class="tiny-cell overflow">{{ rowData.shiftName }}</td>
          <td class="tiny-cell">
            <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
          </td>
          <td>{{ 'BACKEND_TYPE.APPROVE_DOWNTIME.' + rowData.downtimeType | uppercase | translate }}</td>
          <td>{{ rowData.downtimeReasonLocalId }}</td>
          <td class="small-cell"></td>
          <td class="small-cell">{{ rowData.startTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.endTime | utcToLocal | date: 'HH:mm' }}</td>
          <td colspan="2"></td>
          <td class="small-cell">{{ rowData.duration | elapsedTime }}</td>
          <td [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.wasteQuantity?.value | number">{{ rowData.wasteQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.maculatureQuantity?.value | number">{{ rowData.maculatureQuantity | formatQuantity: false }}</td>
          <td colspan="7">{{ rowData.description }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="ITEM_TYPE.RUN_DETAIL">
          <td class="tiny-cell overflow">{{ rowData.shiftName }}</td>
          <td class="tiny-cell">
            <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
          </td>
          <td>{{ 'BACKEND_TYPE.APPROVE_DOWNTIME.' + rowData.downtimeType | uppercase | translate }}</td>
          <td>{{ rowData.downtimeReasonLocalId }}</td>
          <td class="small-cell"></td>
          <td class="small-cell">{{ rowData.startTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.endTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell"></td>
          <td class="small-cell">{{ rowData.duration | elapsedTime }}</td>
          <td class="small-cell"></td>
          <td [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.wasteQuantity?.value | number">{{ rowData.wasteQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.maculatureQuantity?.value | number">{{ rowData.maculatureQuantity | formatQuantity: false }}</td>
          <td colspan="7">{{ rowData.description }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="ITEM_TYPE.SETUP_DETAIL">
          <td class="tiny-cell overflow">{{ rowData.shiftName }}</td>
          <td class="tiny-cell">
            <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
          </td>
          <td>{{ 'BACKEND_TYPE.APPROVE_DOWNTIME.' + rowData.downtimeType | uppercase | translate }}</td>
          <td>{{ rowData.downtimeReasonLocalId }}</td>
          <td class="small-cell"></td>
          <td class="small-cell">{{ rowData.startTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.endTime | utcToLocal | date: 'HH:mm' }}</td>
          <td class="small-cell">{{ rowData.duration | elapsedTime }}</td>
          <td colspan="2"></td>
          <td [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.wasteQuantity?.value | number">{{ rowData.wasteQuantity | formatQuantity: false }}</td>
          <td [title]="rowData.maculatureQuantity?.value | number">{{ rowData.maculatureQuantity | formatQuantity: false }}</td>
          <td colspan="7">{{ rowData.description }}</td>
        </ng-container>

        <td *ngIf="buttonTemplate" class="action-cell">
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { $implicit: rowData, rowNode: rowNode }"></ng-container>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</section>
