import { Injectable } from '@angular/core';
import { ActiveOrderQuery, ActiveOrderService } from '@app/core/global-state';
import { HubUrlsService } from '@app/core/utils';
import {
  ActiveProductionOrder,
  ConfirmProductionOrderKpiEntry,
  ProducedMaterialType,
  ProductionOrderService,
  UnconfirmProductionOrderKpiEntry,
  WebSocketClientService,
  PeriodicQaCheck
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MachineScheduleDsService } from '@app/core/data-services/machine-schedule-ds';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({
  providedIn: 'root'
})
export class ActiveOrderDsService {
  constructor(
    private machineScheduleDsService: MachineScheduleDsService,
    private activeOrderService: ActiveOrderService,
    private activeOrderQuery: ActiveOrderQuery,
    private productionOrderService: ProductionOrderService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public initActiveProductionOrder(): Observable<ActiveProductionOrder> {
    this.resetActiveOrder();
    return this.getActiveProductionOrder();
  }

  public getActiveProductionOrder(): Observable<ActiveProductionOrder> {
    return this.machineScheduleDsService.getActiveProductionOrder().pipe(this.saveActiveProductionOrderToStore());
  }

  public getActiveOrderNotifications(): Observable<ActiveProductionOrder> {
    return this.webSocketClientService
      .getNotificationsForTopic<ActiveProductionOrder>(this.hubUrlsService.getActiveProductionOrder())
      .pipe(this.saveActiveProductionOrderToStore());
  }

  public getAutomaticQACheckNotifications(activeOrderId: number): Observable<PeriodicQaCheck> {
    return this.webSocketClientService.getNotificationsForTopic<PeriodicQaCheck>(
      this.hubUrlsService.getAutomaticQACheckForProductionOrder(activeOrderId)
    );
  }

  public selectActiveOrder(): Observable<ActiveProductionOrder> {
    return this.activeOrderQuery.activeOrder$;
  }

  public getActiveOrder(): ActiveProductionOrder {
    return this.activeOrderQuery.getActiveOrder();
  }

  public selectactiveOrderId(): Observable<number> {
    return this.activeOrderQuery.activeOrderId$;
  }

  public getActiveOrderId(): number {
    return this.activeOrderQuery.getActiveOrderId();
  }

  public resetActiveOrder(): void {
    this.activeOrderService.reset();
  }

  public getKpisConfirmation(): Observable<boolean> {
    return this.activeOrderQuery.areKpisConfirmed$;
  }

  public changeKpiConfirmation(checked: boolean): Observable<null> {
    const activeOrderId = this.getActiveOrderId();
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    let action;

    if (checked) {
      const entry: ConfirmProductionOrderKpiEntry = {
        workCenterId
      };
      const params: ProductionOrderService.ConfirmProductionOrderKpiParams = {
        entry,
        productionOrderId: activeOrderId
      };
      action = this.productionOrderService.confirmProductionOrderKpi(params);
    } else {
      const entry: UnconfirmProductionOrderKpiEntry = {
        workCenterId
      };
      const params: ProductionOrderService.UnconfirmProductionOrderKpiParams = {
        productionOrderId: activeOrderId,
        entry
      };
      action = this.productionOrderService.unconfirmProductionOrderKpi(params);
    }

    return action.pipe(
      tap(() => {
        this.activeOrderService.setAreKpisConfirmed(checked);
      })
    );
  }

  public selectRunPhaseStartCounter(): Observable<number> {
    return this.activeOrderQuery.selectRunPhaseStartCounter();
  }

  public getActiveOrderType(): ProducedMaterialType {
    return this.activeOrderQuery.getActiveOrderType();
  }

  public setActiveOrderQaHistoryCheck(isChecked: boolean): void {
    this.activeOrderService.setQaHistoryConfirmed(isChecked);
  }

  public isActiveOrderQaHistoryChecked(): Observable<boolean> {
    return this.activeOrderQuery.isQaHistoryConfirmed$;
  }

  public setQaToolCheckoutConfirm(isChecked: boolean): void {
    this.activeOrderService.setQaToolCheckoutConfirm(isChecked);
  }

  public isQaToolCheckoutConfirmed(): Observable<boolean> {
    return this.activeOrderQuery.isQaToolCheckoutConfirmed$;
  }

  private saveActiveProductionOrderToStore<T>(): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
      source.pipe(
        tap((activeProductionOrder) => {
          this.activeOrderService.setActiveProductionOrder(activeProductionOrder);
        })
      );
  }
}
