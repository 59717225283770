import { Component, OnInit } from '@angular/core';
import moment from 'moment-mini';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as R from 'ramda';
import { Router } from '@angular/router';
import { nav, notificationTopic } from '@app/shared/utils';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { ProductionOrder } from 'chronos-core-client';

@Component({
  selector: 'app-order-start-modal',
  templateUrl: './order-start-modal.component.html',
  styleUrls: ['./order-start-modal.component.scss']
})
export class OrderStartModalComponent implements OnInit {
  public modalOpenTime: Date;
  public minSelectableDate: Date;
  public selectedStartTime: Date;
  public quantityPerPallet: number;
  public orderItem: ProductionOrder;

  public unit: string;
  public isInputAvailable: boolean;

  public readonly LOADING_TOPIC = notificationTopic.modalForManualMode;
  private readonly MAX_QUANTITY_PER_PALLET = 50000;
  private readonly DAYS_AVAILABLE = 5;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private manualModeService: ManualModeService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.modalOpenTime = new Date();
    this.selectedStartTime = this.modalOpenTime;
    this.minSelectableDate = moment(this.modalOpenTime).subtract(this.DAYS_AVAILABLE, 'days').toDate();
    this.orderItem = this.config.data?.productionOrder;

    this.unit = this.config.data?.defaultPalletQuantityInfo.quantity?.unitId;
    this.quantityPerPallet = this.config.data?.defaultPalletQuantityInfo.quantity?.value;
  }

  public isQuantityAcceptable(): boolean {
    return this.quantityPerPallet ? this.quantityPerPallet > 0 && this.quantityPerPallet <= this.MAX_QUANTITY_PER_PALLET : true;
  }

  public isModalValid(): boolean {
    return (
      !R.isNil(this.quantityPerPallet) && this.quantityPerPallet > 0 && this.isQuantityAcceptable() && !R.isNil(this.selectedStartTime)
    );
  }

  public startOrder(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.manualModeService
      .startOrderInManualMode(this.orderItem.productionOrderId, this.selectedStartTime, this.quantityPerPallet)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.router.navigate([nav.routes.run]);
        this.ref.close();
      });
  }

  public closeModal(): void {
    this.ref.close();
  }
}
