import { Component, forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QaCheckResult } from 'chronos-core-client';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ResultSelectButtonComponent),
  multi: true
};

@Component({
  selector: 'app-result-select-button',
  templateUrl: './result-select-button.component.html',
  styleUrls: ['./result-select-button.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class ResultSelectButtonComponent implements ControlValueAccessor {
  @Input() public name: string;
  @Input() public set isReadOnly(isReadOnly: boolean) {
    if (isReadOnly) {
      this.setDisabledState(isReadOnly);
    } else {
      this.setDisabledState(isReadOnly);
    }
  }

  public value: any;
  public isDisabled = false;

  private readonly RESULT_SELECT_BUTTON_STYLES: { [result: string]: string } = {
    [QaCheckResult.OK]: 'result-select-button__item--ok-active',
    [QaCheckResult.NOT_OK]: 'result-select-button__item--nok-active',
    [QaCheckResult.WARNING_OK]: 'result-select-button__item--wok-active',
    [QaCheckResult.NOT_APPLICABLE]: 'result-select-button__item--na-active'
  };

  public readonly RESULT_VALUES: { label: string; value: QaCheckResult }[] = [
    { label: 'Ok', value: QaCheckResult.OK },
    { label: 'Nok', value: QaCheckResult.NOT_OK },
    { label: 'Wok', value: QaCheckResult.WARNING_OK },
    { label: 'Na', value: QaCheckResult.NOT_APPLICABLE }
  ];

  private onChange: (result: string) => void;
  private onTouched: () => void;

  public writeValue(value: any): void {
    this.setControlValue(value);
  }

  public setValue(value: string): void {
    if (!this.isDisabled) {
      this.setControlValue(value);
      this.onChange(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public getResultSelectButtonStyle(resultValue: string): string {
    if (resultValue === this.value) {
      return this.RESULT_SELECT_BUTTON_STYLES[resultValue];
    }
  }

  private setControlValue(value: string): void {
    this.value = value;
  }
}
