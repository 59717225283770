<div
  class="task-item section-padding"
  [ngClass]="{
    'task-item--red-flag ': !taskItem.downtime.isDowntimeCodeAssigned,
    'task-item--status-ok': taskItem.status !== TASK_STATUS.NOT_OK,
    highlight: isHighlightTask
  }"
  (click)="onItemSelected()"
>
  <div class="p-grid">
    <div class="p-col-10 cursor-clickable">
      <div class="p-grid">
        <div class="p-col-3">
          <div class="left-indent">
            <div class="status">
              <lib-status-flag
                *ngIf="TASK_STATUS_FLAGS[taskItem.type] as taskStatus"
                class="status__item"
                [statusText]="taskStatus.text | translate"
                [styleClass]="taskStatus.labelClass"
              ></lib-status-flag>
            </div>

            <div class="flag" *ngIf="taskItem.status === TASK_STATUS.NOT_OK">
              <i class="icon-flag-solid"></i>
            </div>

            <div class="flag" *ngIf="taskItem.status !== TASK_STATUS.NOT_OK">
              <i class="icon-checked-solid"></i>
            </div>
            <div
              id="external-system-connection"
              class="flag__label flag__label--android"
              *ngIf="taskItem.downtime.isDowntimeCodeAssignedAuto"
            >
              <i class="icon-automated"></i>
            </div>
            <div class="checkBox">
              <p-checkbox binary="true" [ngModel]="taskItem.isCheck" (click)="onItemChecked()"></p-checkbox>
            </div>
          </div>
        </div>
        <div class="p-col-2">
          <div class="task-details">
            <div class="order">
              <div class="arrow">
                <i *ngIf="isItemCollapsed" class="icon-arrow-right-open"></i>
                <i *ngIf="!isItemCollapsed" class="icon-arrow-down-open"></i>
              </div>
              <div class="order-id">
                {{ taskItem.downtime.startTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}
                <span *ngIf="taskItem.downtime.endTime">- {{ taskItem.downtime.endTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="p-col-2 task-duration">
          <i class="icon-standstill task-duration__icon"></i>
          <span>{{ taskItem.downtime.timeDiff | elapsedTime : false : true }}</span>
        </div>

        <div class="p-col-3">
          <div>{{ taskItem.downtime.downtimeReasonLocalDescription }}</div>
          <div>{{ taskItem.downtime.grossQuantity | formatQuantity }}</div>
        </div>

        <div class="p-col-2">
          {{ taskItem.downtime.externalProductionOrderId }}
          <br />
        </div>
      </div>
    </div>
    <div class="p-col-2 control-button">
      <div>
        <app-split-button class="control-button__action" [items]="taskButtons" [loadingTopic]="loadingTopic"></app-split-button>
      </div>
    </div>
  </div>

  <div [hidden]="isItemCollapsed" class="collapse-container">
    <div class="p-grid">
      <div class="p-col-2"></div>
      <div class="p-col-10">{{ taskItem.downtime.info }}</div>
    </div>
  </div>
</div>
