import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Quantity } from 'projects/chronos-core-client/src/public-api';
import {
  MaterialService,
  MaterialType,
  MountContainerEntry,
  MountContainerResponse,
  ScanContainerEntry,
  ScanContainerResponse,
  WorkCenterService
} from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class OverviewMaterialDsService {
  constructor(private workCenterService: WorkCenterService, private materialService: MaterialService) {}

  public scanIdentificationCode(workCenterId: number, identificationCode: string): Observable<ScanContainerResponse> {
    const entry: ScanContainerEntry = {
      identificationCode
    };
    const params: WorkCenterService.ScanContainerParams = {
      workCenterId,
      entry
    };
    return this.workCenterService.scanContainer(params);
  }

  public mountContainer(
    workCenterId: number,
    productionOrderId: number,
    materialType?: MaterialType,
    identificationCode?: string,
    containerId?: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MountContainerResponse> {
    const containerEntry: MountContainerEntry = {
      containerId,
      identificationCode,
      productionOrderId,
      materialType,
      whiteWasteQuantity
    };
    const params: MaterialService.MountContainerParams = {
      workCenterId,
      entry: containerEntry
    };
    return this.materialService.mountContainer(params);
  }
}
