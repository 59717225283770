<section class="secondary-section overview">
  <ng-container>
    <div class="row">
      <h1 class="page-header">{{ 'EMPLOYEEREGISTRATION.LOGGEDIN_EMPLOYEE' | translate }}</h1>
    </div>
    <app-loggedin-employee-table
      [loggedInData]="activeEmployeeRegistrations"
      (loggedInEmpoyeeBlur)="onLoggedInEmpoyeeBlur($event)"
      (logOut)="onLogOut($event)"
    ></app-loggedin-employee-table>
  </ng-container>
  <ng-container>
    <div class="row">
      <h1 class="page-header">{{ 'EMPLOYEEREGISTRATION.AVAILABLE_EMPLOYEE' | translate }}</h1>
    </div>
    <div class="filters">
      <div class="form-group text-search" *ngIf="!appSettingsQuery.enableLoginWithBadgeId(); else rfidInputBox">
        <input
          id="employeetextSearch"
          type="text"
          class="search-bar"
          [(ngModel)]="textQuery"
          (input)="onFilterChange()"
          #searchBox
          pInputText
          [placeholder]="'EMPLOYEEREGISTRATION.AVAILABLEEMPLOYEE_SEARCH' | translate"
        />
      </div>
    </div>
    <app-employee-table
      [availableEmployees]="employeeQuery.availableEmployeesFiltered$ | async"
      (logIn)="onLogIn($event)"
    ></app-employee-table>
  </ng-container>

  <ng-template #rfidInputBox>
    <input
      id="rfidBox"
      type="password"
      class="text-search search-bar"
      [(ngModel)]="rfid"
      (input)="onFilterChange()"
      pInputText
      #rfidBox
      [placeholder]="'EMPLOYEEREGISTRATION.AVAILABLEEMPLOYEE_RFID_SEARCH' | translate"
    />
  </ng-template>
</section>
