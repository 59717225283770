<div
  (click)="onClick()"
  class="dismount-information"
  [libLoadingTopic]="loadingTopic"
  (loadingEvent)="loading = $event"
  [loadingIndicator]="false"
>
  <lib-attention-badge class="dismount-information__counter" [counter]="dismountableCount"></lib-attention-badge>
  <i [ngClass]="{ 'unmount-icon--disabled': disabled }" class="icon-unmount-pallet unmount-icon"></i>
</div>
