import { SetupFooterCommandBase } from '@app/shared/components';
import { SetupPhaseService } from '../services/setup-phase/setup-phase.service';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { notificationTopic } from '@app/shared/utils';

export class FinishSetupCommandService implements SetupFooterCommandBase {
  public enabled = true;
  public buttonText: string;
  public required = false;
  public route: string;

  public loadingTopic = notificationTopic.footerFinishSetupPhase;

  constructor(private setupPhaseService: SetupPhaseService) {}

  public execute(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.setupPhaseService
      .enterRunPhase(this.route)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe();
  }

  public setNavigationParams(route: string): void {
    this.route = route;
  }
}
