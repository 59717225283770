import { Injectable } from '@angular/core';
import { ToolCheckoutInfo, ToolService } from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ToolCheckoutDsService {
  constructor(private toolService: ToolService) {}

  public getProductionOrderToolCheckoutInfo(productionOrderId: number): Observable<ToolCheckoutInfo[]> {
    return this.toolService.getToolCheckoutInfo(productionOrderId);
  }

  public setToolCheckoutResult(params: ToolService.SetToolCheckoutResultParams): Observable<null> {
    return this.toolService.setToolCheckoutResult(params);
  }
}
