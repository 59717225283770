import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ShiftReportItem, ShiftReportItemFilters } from './shift-report-orders.model';
import { ShiftReport, ShiftReportStatus } from 'chronos-core-client';

export interface ShiftReportOrdersState extends EntityState<ShiftReportItem> {
  filters: ShiftReportItemFilters;
  shiftReportStatus?: ShiftReportStatus;
  shiftReportId?: number;
  shiftReportRemarkId?: number;
}

const initialState = { filters: { productionOrder: '' } };

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'shift-report-orders', idKey: 'id' })
export class ShiftReportOrdersStore extends EntityStore<ShiftReportOrdersState> {
  constructor() {
    super(initialState);
  }

  public updateFilters(filters: ShiftReportItemFilters): void {
    this.update({ filters });
  }

  public updateShiftReport(shiftReport: ShiftReport): void {
    this.update({
      shiftReportStatus: shiftReport?.shiftReportStatus,
      shiftReportId: shiftReport?.shiftReportId,
      shiftReportRemarkId: shiftReport?.shiftReportRemarkId
    });
  }

  public resetFilters(): void {
    this.update(initialState);
  }
}
