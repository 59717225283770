import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { finishPhaseFooterRoutes } from '../utils/nav';
import { GuardsUtils } from './guards-utils';
import { RunPhaseType } from 'chronos-core-client';

export const finishSubPhaseNavigationGuard = (next: ActivatedRouteSnapshot) => {
  const guardsUtils: GuardsUtils = inject(GuardsUtils);
  return guardsUtils.navigateToSubPhase(next, RunPhaseType.FINISHING, finishPhaseFooterRoutes);
};
