<div  *ngIf="isPaternInfoDataLoaded">
  <section class="primary-section status-panels">
    <div class="row">
      <div class="col-4">
        <app-status-panel
          [label]="'SETUP_PATTERN_INFO.PATTERN' | translate"
          [value]="patternDetail.patternLength.value | number"
          [unitId]="patternDetail.patternLength.unitId"          
        ></app-status-panel>
      </div>
      <div class="col-4">        
          <app-status-panel
            [label]="'SETUP_PATTERN_INFO.PATTERNWIDTH' | translate"
            [value]="patternDetail.patternWidth.value  | number"
            [unitId]="patternDetail.patternWidth.unitId" 
          ></app-status-panel>        
      </div>
      <div class="col-4">
        <app-status-panel
          [label]="'SETUP_PATTERN_INFO.PATTERNWEIGTHT' | translate"
          [value]="patternDetail.patternWeight.value | number"
          [unitId]="patternDetail.patternWeight.unitId"
        ></app-status-panel>
      </div>
    </div>
  </section>
<br/>
<app-pattern-trace-table [tableData]="patternDetail.patternInfo"></app-pattern-trace-table>
</div>

