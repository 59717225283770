import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[libChronosTextarea]'
})
export class ChronosTextareaDirective {
  @HostBinding('attr.maxlength')
  @Input()
  public maxtextAreaLength = '250';

  constructor() {}
}
