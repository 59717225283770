export class SerializedBackendWarning {
  public isFailure: boolean;
  public isSuccess: boolean;
  public serializedWarning: SerializedWarning[] = [];

  constructor(message: BackendWarning) {
    this.isFailure = message.IsFailure;
    this.isSuccess = message.IsSuccess;
    const errors = JSON.parse(message.Error);

    if (Object(errors).length) {
      errors.forEach((error: Error) => this.serializedWarning.push(new SerializedWarning(error)));
    } else {
      this.serializedWarning.push(new SerializedWarning(errors));
    }
  }
}

export class SerializedWarning {
  public errorCode: string;
  public parameters: object;
  public type: number;
  public value: string;

  constructor(message: Error) {
    this.errorCode = message.errorCode;
    this.type = message.type;
    this.parameters = this.constructParameters(message.parameters);
    this.value = this.constructValue(message.value || message.message, message.parameters);
  }

  private constructValue(value: string, parameters: Parameter[]): string {
    let newValue = value;

    parameters?.forEach((parameter) => {
      newValue = newValue.replace(`{${parameter.index}}`, parameter.value === undefined ? '' : parameter.value);
    });

    return newValue;
  }

  private constructParameters(parameters: Parameter[]) {
    const newParameters = {};

    parameters?.forEach((parameter) => (newParameters[parameter.index] = parameter.value));

    return newParameters;
  }
}

export interface BackendWarning {
  IsFailure: boolean;
  IsSuccess: boolean;
  Error: string;
}

export interface Error {
  errorCode: string;
  parameters: Parameter[];
  type: number;
  value: string;
  message: string;
}

export interface Parameter {
  index: number;
  value: string;
}
