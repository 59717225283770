import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QaCheckService } from '@app/core/services';
import { ProductionQaCheck } from 'chronos-core-client';
import { finalize } from 'rxjs/operators';
import { QaCheckValueChange } from '@app/shared/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pallet-qa-check',
  templateUrl: './pallet-qa-check.component.html',
  styleUrls: ['./pallet-qa-check.component.scss']
})
export class PalletQaCheckComponent implements OnInit, OnDestroy {
  @Input()
  public set qaFields(currentFields: ProductionQaCheck[]) {
    this.fields = currentFields;
    if (this.qaCheckForm) {
      currentFields.forEach((field) => {
        this.qaCheckForm.get(field.qaCheckId.toString()).get('check').patchValue(field.checkResult, { emitEvent: false });
      });
      this.qaCheckForm.updateValueAndValidity();
    }
  }

  @Input() public qaCheckForm: UntypedFormGroup;
  @Input() public allOkCheck?: UntypedFormControl;

  private subscriptions = new Subscription();
  public fields: ProductionQaCheck[];

  private previousForm: QaCheckValueChange[];

  constructor(public qaCheckService: QaCheckService) {}

  public ngOnInit(): void {
    this.updateOkCheck();
    this.subscriptions.add(
      this.qaCheckService.controlsEmittersInit(this.qaCheckForm).subscribe((qaCheckResult) => {
        this.setCheckResult(qaCheckResult);
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getQaCheckLabel(id: number): string {
    return this.qaCheckService.getQaCheckDescriptionData(id, this.fields).label;
  }

  private updateOkCheck(): void {
    this.allOkCheck?.setValue(this.qaCheckService.isAllChecksOk(this.qaCheckForm), { emitEvent: false });
    this.previousForm = this.qaCheckForm.value;
  }

  private setCheckResult(qaCheckResult: QaCheckValueChange): void {
    const qaCheckControl = this.qaCheckService.getCheckControl(qaCheckResult.id, this.qaCheckForm, this.fields);
    qaCheckControl.disable({ emitEvent: false });

    this.qaCheckService
      .setCheckResult(qaCheckResult.id, qaCheckResult.check, qaCheckResult.comment, qaCheckResult.measurementResult)
      .pipe(
        finalize(() => {
          qaCheckControl.enable({ emitEvent: false });
        })
      )
      .subscribe(
        () => {
          this.updateOkCheck();
        },
        () => {
          this.resetFormToPreviousState();
        }
      );
  }

  private resetFormToPreviousState(): void {
    this.qaCheckForm.reset(this.previousForm, { onlySelf: false, emitEvent: false });
    this.qaCheckForm.updateValueAndValidity();
  }
}
