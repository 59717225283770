import { Pipe, PipeTransform } from '@angular/core';
/*
 * Transforms a string to the short sscc code display.
 * It shows the last 5 characters of the code and an elipsis character in front.
 * Usage:
 *   value | shortSsccCode
 * Example:
 *   {{ 2 | shortSsccCode }}
 *   formats to: 2
 *   {{ 123456789 | shortSsccCode }}
 *   formats to: …56789
 */
@Pipe({ name: 'shortSsccCode' })
export class ShortSsccCodePipe implements PipeTransform {
  public transform(value: string): string {
    const visibleCharacterCount = 6;

    if (value === undefined || value === null) {
      return '';
    } else if (value.length <= visibleCharacterCount) {
      return value;
    }
    return `…${value.substring(value.length - visibleCharacterCount, value.length)}`;
  }
}
