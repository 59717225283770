/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
class ChartsService extends __BaseService {
  static readonly renderBasicChartPath = '/api/Charts/demo/chart/{workcenterId}/render';
  static readonly getBasicChartOptionsPath = '/api/Charts/demo/chart/{workcenterId}/json';
  static readonly renderChartPath = '/api/Charts/demo/render/options';
  static readonly saveMailPath = '/api/Charts/demo/savemail';
  static readonly executeMailJobPath = '/api/Charts/demo/executemailjob';
  static readonly testMailPath = '/api/Charts/demo/testmail';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Renders a highcharts chart.
   *
   * Will be directly visible in Swagger UI.
   * Simple test if highcharts node server is available.
   *
   * Change in Startup, if BasicChartOptionsService or MachineChartOptionsService should be used.
   * @param workcenterId Id of the machine
   */
  renderBasicChartResponse(workcenterId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Charts/demo/chart/${encodeURIComponent(String(workcenterId))}/render`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Renders a highcharts chart.
   *
   * Will be directly visible in Swagger UI.
   * Simple test if highcharts node server is available.
   *
   * Change in Startup, if BasicChartOptionsService or MachineChartOptionsService should be used.
   * @param workcenterId Id of the machine
   */
  renderBasicChart(workcenterId: number): __Observable<null> {
    return this.renderBasicChartResponse(workcenterId).pipe(__map((_r) => _r.body as null));
  }

  /**
   * Get the options for the chart as a json string.
   * @param workcenterId Id of the machine
   * @return Success
   */
  getBasicChartOptionsResponse(workcenterId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Charts/demo/chart/${encodeURIComponent(String(workcenterId))}/json`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get the options for the chart as a json string.
   * @param workcenterId Id of the machine
   * @return Success
   */
  getBasicChartOptions(workcenterId: number): __Observable<string> {
    return this.getBasicChartOptionsResponse(workcenterId).pipe(__map((_r) => _r.body as string));
  }

  /**
   * Renders Highcharts chart based on options.
   * @param body JSON of highcharts chart-options
   */
  renderChartResponse(body?: any): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Charts/demo/render/options`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Renders Highcharts chart based on options.
   * @param body JSON of highcharts chart-options
   */
  renderChart(body?: any): __Observable<null> {
    return this.renderChartResponse(body).pipe(__map((_r) => _r.body as null));
  }

  /**
   * Returns output of email.
   *
   * Click download button (lower right corner of response body) and open in browser.
   */
  saveMailResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Charts/demo/savemail`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Returns output of email.
   *
   * Click download button (lower right corner of response body) and open in browser.
   */
  saveMail(): __Observable<null> {
    return this.saveMailResponse().pipe(__map((_r) => _r.body as null));
  }

  /**
   * Executes mail job manually
   *
   * This job is usally executed by a scheduler.
   */
  executeMailJobResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Charts/demo/executemailjob`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Executes mail job manually
   *
   * This job is usally executed by a scheduler.
   */
  executeMailJob(): __Observable<null> {
    return this.executeMailJobResponse().pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param body undefined
   */
  testMailResponse(body?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Charts/demo/testmail`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  testMail(body?: string): __Observable<null> {
    return this.testMailResponse(body).pipe(__map((_r) => _r.body as null));
  }
}

module ChartsService {}

export { ChartsService };
