<div *ngIf="palletNumber; else noPalletNumber" class="pallet-amount">
  <lib-article-classification [classification]="classification"></lib-article-classification>
  <div class="pallet-amount__amount">{{ palletNumber | number }}</div>
</div>

<ng-template #noPalletNumber>
  <div *ngIf="useContainer; else noPallet" class="pallet-amount">
    <div>
      <i class="pallet-amount__icon icon-container"></i>
    </div>
    <div class="pallet-amount__amount">—</div>
  </div>
  <ng-template #noPallet>
    <div class="pallet-amount">
      <div>
        <i class="pallet-amount__icon icon-running-no-pallet"></i>
      </div>
      <div class="pallet-amount__amount">P</div>
    </div>
  </ng-template>
</ng-template>
