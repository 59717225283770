import { Component, EventEmitter, forwardRef, Input, Output, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LineClearanceService } from '@app/modules/setup-phase/services/line-clearance/line-clearance.service';
import { NAButtonValidator } from '@app/shared/models';
import { QaCheckResult } from 'chronos-core-client';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LineClearanceSelectButtonComponent),
  multi: true
};

@Component({
  selector: 'app-line-clearance-select-button',
  templateUrl: './line-clearance-select-button.component.html',
  styleUrls: ['./line-clearance-select-button.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class LineClearanceSelectButtonComponent implements ControlValueAccessor {
  @Input() public name: string;
  @Output() public naButtonClicked = new EventEmitter<NAButtonValidator>();

  public value: any;

  constructor(private lineClearanceService: LineClearanceService) {}

  private readonly RESULT_SELECT_BUTTON_STYLES: { [result: string]: string } = {
    [QaCheckResult.OK]: 'clearance-select-button__item--ok-active',
    [QaCheckResult.NOT_APPLICABLE]: 'clearance-select-button__item--na-active'
  };

  public readonly RESULT_VALUES: { label: string; value: QaCheckResult }[] = [
    { label: 'Ok', value: QaCheckResult.OK },
    { label: 'Na', value: QaCheckResult.NOT_APPLICABLE }
  ];

  private onChange: (result: string) => void;
  private onTouched: () => void;

  public writeValue(value: any): void {
    this.setControlValue(value);
  }

  public setValue(value: string): void {
    if (QaCheckResult.NOT_APPLICABLE === value) {
      this.naButtonClicked.emit({ isRequired: true, button: QaCheckResult.NOT_APPLICABLE });
      this.setControlValue(value);
      this.onChange(value);
    } else {
      this.lineClearanceService.setFocusSubject.next(true);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getResultSelectButtonStyle(resultValue: string): string {
    if (resultValue === this.value) {
      return this.RESULT_SELECT_BUTTON_STYLES[resultValue];
    }
  }

  private setControlValue(value: string): void {
    this.value = value;
  }
}
