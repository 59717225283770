import { BehaviorSubject, Observable } from 'rxjs';
import { LogEntry } from './log-entry';
import { LogLevel } from './models';

export abstract class LogPublisher {
  public abstract log(record: LogEntry): void;
}

export class LogConsole extends LogPublisher {
  public log(entry: LogEntry): void {
    console.info(entry.buildLogString());
  }
}

export class LogHeaderMessageArea extends LogPublisher {
  private static currentMessageBehaviorSubject = new BehaviorSubject<LogEntry>(null);
  public static currentMessage$: Observable<LogEntry> = LogHeaderMessageArea.currentMessageBehaviorSubject.asObservable();

  public log(entry: LogEntry): void {
    if (entry.level > LogLevel.Debug) {
      LogHeaderMessageArea.currentMessageBehaviorSubject.next(entry);
    }
  }
}
