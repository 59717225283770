import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { AppSettings } from './app-settings.model';

function initialState(): Partial<AppSettings> {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app-settings' })
export class AppSettingsStore extends Store<AppSettings> {
  constructor() {
    super(initialState());
  }
}
