import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { EmployeeRegistrationHistory } from 'chronos-core-client';
import { defaultDropdownValue, HistoryFilters } from './employee-registration-history.model';

export interface HistoryState extends EntityState<EmployeeRegistrationHistory>, ActiveState {
  filters: HistoryFilters;
}

const initialState = {
  filters: {
    shift: defaultDropdownValue,
    textQuery: ''
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'history', idKey: 'id' })
export class EmployeeRegistrationHistoryStore extends EntityStore<HistoryState, EmployeeRegistrationHistory> {
  constructor() {
    super(initialState);
  }

  public updateFilters(filters: HistoryFilters): void {
    this.update({ filters });
  }

  public resetFilters(): void {
    this.update(initialState);
  }
}
