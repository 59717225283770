import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ActiveOrderPhaseDsService } from '@app/core/data-services';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { phasesRoutes } from '../utils/nav';
import { RunPhaseType } from 'chronos-core-client';

interface PhaseFooterRoutes {
  route: string;
  subPhase: string;
}

@Injectable()
export class GuardsUtils {
  constructor(private activeOrderPhaseDsService: ActiveOrderPhaseDsService) {}

  public navigateToSubPhase(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    currentOrderPhase: RunPhaseType,
    phaseFooterRoutes: PhaseFooterRoutes[]
  ): Observable<boolean> {
    return combineLatest([
      this.currentPhaseIsActive(currentOrderPhase),
      this.currentSubPhaseIsActive(activatedRouteSnapshot, phaseFooterRoutes)
    ]).pipe(map(([currentPhaseIsActive, currentSubPhaseIsActive]) => !currentPhaseIsActive || currentSubPhaseIsActive));
  }

  public navigateToPhase(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const fullUrl = `/${activatedRouteSnapshot.url.join('')}`;

    return this.activeOrderPhaseDsService.selectActiveOrderPhase().pipe(
      map((activeOrderPhase) => {
        const activePhaseIndex = phasesRoutes.findIndex((p) => p.phase === activeOrderPhase);

        const currentPhaseIndex = phasesRoutes.findIndex((p) => p.route === fullUrl);

        return currentPhaseIndex <= activePhaseIndex;
      })
    );
  }

  private currentPhaseIsActive(currentOrderPhase: RunPhaseType): Observable<boolean> {
    return this.activeOrderPhaseDsService.selectActiveOrderPhase().pipe(map((activeOrderPhase) => activeOrderPhase === currentOrderPhase));
  }

  public currentSubPhaseIsActive(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    phaseFooterRoutes: PhaseFooterRoutes[]
  ): Observable<boolean> {
    return this.activeOrderPhaseDsService.selectActiveOrderSubPhase().pipe(
      map((activeOrderSubPhase) => {
        const parent = activatedRouteSnapshot.parent.url.join('');
        const child = activatedRouteSnapshot.url.join('');
        const fullUrl = `/${parent}/${child}`;
        const activeSubPhase = phaseFooterRoutes.findIndex((p) => p.subPhase === activeOrderSubPhase);

        const currentSubPhase = phaseFooterRoutes.findIndex((p) => p.route === fullUrl);

        return currentSubPhase <= activeSubPhase;
      })
    );
  }
}
