import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class CollapseExpandNotificationService {

  private inputSubject = new Subject<boolean>();
  private outputSubject = new Subject<boolean>();

  public eventInput$: Observable<boolean> = this.inputSubject.asObservable();
  public eventOutput$: Observable<boolean> = this.outputSubject.asObservable().pipe(share());

  constructor() { }

  public emitInput(expanded: boolean): void {
    this.inputSubject.next(expanded);
  }

  public emitOutput(expanded: boolean): void {
    this.outputSubject.next(expanded);
  }
}
