/* tslint:disable */
type ApprovalReportStatus = 'Open' | 'Approved';
module ApprovalReportStatus {
  export const OPEN: ApprovalReportStatus = 'Open';
  export const APPROVED: ApprovalReportStatus = 'Approved';
  export function values(): ApprovalReportStatus[] {
    return [OPEN, APPROVED];
  }
}

export { ApprovalReportStatus };
