import { FormConstructionService } from '@app/modules/parameters/services';
import { ParametersComponent } from '@app/modules/parameters/components';
import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { ToolsModule } from '@app/modules/tools/tools.module';
import { ParamsFieldComponent } from '@app/modules/parameters/components/params-field/params-field.component';
import { ParamsTableComponent } from '@app/modules/parameters/components/params-table/params-table.component';
import { ToolSetupComponent } from '@app/modules/parameters/components/tool-setup/tool-setup.component';
import { PalletQuantityParameterComponent } from './components/pallet-quantity-parameter/pallet-quantity-parameter.component';
import { PalletBobbinQuantityParameterComponent } from './components/pallet-bobbin-quantity-parameter/pallet-bobbin-quantity-parameter.component';

@NgModule({
  declarations: [
    ParametersComponent,
    ParamsFieldComponent,
    ParamsTableComponent,
    ToolSetupComponent,
    PalletQuantityParameterComponent,
    PalletBobbinQuantityParameterComponent
  ],
  imports: [SharedModule, ToolsModule],
  providers: [FormConstructionService],
  exports: [ParametersComponent]
})
export class ParametersModule {}
