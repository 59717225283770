/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientInfoView } from '../models/client-info-view';
import { TopicClientInfoView } from '../models/topic-client-info-view';
@Injectable({
  providedIn: 'root'
})
class ChangeNotificationsService extends __BaseService {
  static readonly getAllClientsPath = '/api/v1/ChangeNotifications/Clients';
  static readonly getAllTopicsPath = '/api/v1/ChangeNotifications/Topics';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }
  getAllClientsResponse(): __Observable<__StrictHttpResponse<Array<ClientInfoView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/ChangeNotifications/Clients`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientInfoView>>;
      })
    );
  }
  getAllClients(): __Observable<Array<ClientInfoView>> {
    return this.getAllClientsResponse().pipe(__map((_r) => _r.body as Array<ClientInfoView>));
  }
  getAllTopicsResponse(): __Observable<__StrictHttpResponse<Array<TopicClientInfoView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/ChangeNotifications/Topics`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TopicClientInfoView>>;
      })
    );
  }
  getAllTopics(): __Observable<Array<TopicClientInfoView>> {
    return this.getAllTopicsResponse().pipe(__map((_r) => _r.body as Array<TopicClientInfoView>));
  }
}

module ChangeNotificationsService {}

export { ChangeNotificationsService };
