<div class="row">
  <span class="col-5">
    <span class="label">{{ formUnitLabel }}</span>
    <i (click)="resetQuantities()" class="icon-reset icon icon--clickable"></i>
  </span>
  <div class="col-7">
    <div class="input">
      <lib-input-number
        [ngModel]="bomQuantity"
        (ngModelChange)="countSheets($event)"
        [inputStyle]="INPUT_STYLE"
        [unit]="bomUnitId"
        [disabled]="disabled"
      ></lib-input-number>
    </div>
    <div class="input" *ngIf="isInventoryUnitDisplay">
      <lib-input-number
        [ngModel]="formInventoryQuantity"
        (ngModelChange)="countKilograms($event)"
        [disabled]="disabled"
        [inputStyle]="INPUT_STYLE"
        [unit]="inventoryUnitId"
      ></lib-input-number>
    </div>
    <div class="input" *ngIf="isHeightDisplay">
      <lib-input-number
        [ngModel]="height"
        (ngModelChange)="countMillimeters($event)"
        [disabled]="disabled"
        [inputStyle]="INPUT_STYLE"
        [unit]="'mm'"
      ></lib-input-number>
    </div>
  </div>
</div>
