import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Setting, SettingValuesViewModel } from 'chronos-core-client';
import { FormStatus, SettingModel } from '@app/modules/resource-configuration/models';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-setting-category',
  templateUrl: './setting-category.component.html',
  styleUrls: ['./setting-category.component.scss']
})
export class SettingCategoryComponent implements OnInit {
  public categoryName: string;
  @Input() public settingData: SettingValuesViewModel;
  @Input() public defaultSettingList?: Setting[];
  @Input() public isAdmin?: boolean;
  @Input() public enableEditSetting$: Observable<boolean>;
  @Input() public parentForm: UntypedFormGroup;
  @Output() public viewHistory = new EventEmitter<SettingModel>();
  @Output() public emitUpdateSetting = new EventEmitter<SettingModel>();
  @Output() formValidityChange = new EventEmitter<{ status: boolean; settingId: number }>();

  constructor() {}

  public ngOnInit(): void {
    this.categoryName = this.settingData.categoryName;
  }

  public viewSettingHistory(data: any) {
    this.viewHistory.emit(data);
  }

  public updateSetting(data: SettingModel) {
    this.emitUpdateSetting.emit(data);
  }

  public emitFormValidityChange(status: FormStatus) {
    this.formValidityChange.emit({ status: status.status, settingId: status.settingId });
  }
}
