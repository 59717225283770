<p-inputNumber
  [ngModel]="value"
  [maxFractionDigits]="maxFractionDigits"
  [minFractionDigits]="minFractionDigits"
  [min]="min"
  [max]="max"
  [step]="step"
  [size]="size"
  [required]="required"
  [disabled]="disabled"
  [maxlength]="maxlength"
  [inputId]="inputId"
  [inputStyle]="inputStyle"
  [inputStyleClass]="inputStyleClass"
  [format]="format"
  [locale]="locale"
  [autocomplete]="autocomplete"
  (onFocus)="onFocused()"
  (onBlur)="onTouched()"
  (onInput)="onChanged($event)"
></p-inputNumber>
<span *ngIf="showUnit" class="unit">{{ unitValue }}</span>
