import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-additional-data',
  templateUrl: './additional-data.component.html',
  styleUrls: ['./additional-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalDataComponent implements OnChanges {
  @Input() public isExpandable = false;
  @Input() public title = '';

  public isExpanded = false;
  public isContentVisible = true;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpandable) {
      this.isContentVisible = this.isContentEnabled();
    }
  }

  public toggleContent(): void {
    if (this.isExpandable) {
      this.isExpanded = !this.isExpanded;
      this.isContentVisible = this.isContentEnabled();
    }
  }

  private isContentEnabled(): boolean {
    return this.isExpanded || !this.isExpandable;
  }
}
