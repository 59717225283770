import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription, Observable } from 'rxjs';
import { nav } from '@app/shared/utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeRegistrationDetailsService } from '../../services/employee-registration.service';
import { AppSettingsQuery } from 'chronos-shared';

@Component({
  selector: 'app-employee-registration',
  templateUrl: './employee-registration.component.html',
  styleUrls: ['./employee-registration.component.scss']
})
export class EmployeeRegistrationComponent implements OnInit, OnDestroy {
  public employeeRegistrationTabs: MenuItem[];
  private subscriptions = new Subscription();
  public submitDisabled$: Observable<boolean>;
  public enableSubmit: boolean;
  public isLoginDisabled: boolean;
  public selectedShiftId: number;
  public approvalReportDate: Date;
  public approvalWorkCenterId: number;

  constructor(
    public ref: DynamicDialogRef,
    public conf: DynamicDialogConfig,
    private config: DynamicDialogConfig,
    public employeeRegistrationDetailsService: EmployeeRegistrationDetailsService,
    public appSettingsQuery: AppSettingsQuery
  ) {}

  public ngOnInit(): void {
    this.initRegistrationTabs();
    this.selectedShiftId = this.config.data?.selectedShiftId;
    this.enableSubmit = this.config.data?.enableSumbit;
    this.isLoginDisabled = this.config.data?.isLoginDisabled;
    this.approvalReportDate = this.config.data?.approvalReportDate;
    this.approvalWorkCenterId = this.config.data?.approvalWorkCenterId;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initRegistrationTabs(): void {
    this.employeeRegistrationTabs = [
      {
        label: 'EMPLOYEEREGISTRATION.LOGIN',
        routerLink: nav.routes.employeeLogin
      },
      {
        label: 'EMPLOYEEREGISTRATION.HISTORY',
        routerLink: nav.routes.employeeHistory
      }
    ];
  }

  public closeModal(): void {
    this.ref.close();
  }

  public tabSelectionChanged(event) {
    if (event.index === 0) {
      this.employeeRegistrationDetailsService.setFocusSubject.next(true);
    }
  }
}
