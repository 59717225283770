import { Component, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { knownUnits } from '../../../models';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumberComponent),
  multi: true
};

@Component({
  selector: 'lib-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class InputNumberComponent implements ControlValueAccessor {
  @Input()
  public get unit(): string {
    this.unitValue = '';
    return this.unitValue;
  }
  public set unit(unitId: string) {
    if (unitId) {
      this.minFractionDigits = !knownUnits.WholeUnits.includes(unitId) ? 2 : null;
      this.maxFractionDigits = !knownUnits.WholeUnits.includes(unitId) ? 2 : null;
    }
    this.unitValue = unitId;
  }

  @Input() public showUnit = true; // Pass `false` to hide the unit, otherwise, it will be displayed alongside the input box.
  @Input() public minFractionDigits: number = null;
  @Input() public maxFractionDigits: number = null;
  @Input() public min: number = null;
  @Input() public max: number = null;
  @Input() public disabled = false;
  @Input() public format = true;
  @Input() public step = 1;
  @Input() public required = false;
  @Input() public size: number = null;
  @Input() public maxlength: number = null;
  @Input() public inputId: string = null;
  @Input() public inputStyle: object = null;
  @Input() public inputStyleClass: string = null;
  @Input() public autocomplete = 'off';
  @Output() public touched = new EventEmitter<void>();
  @Output() public focused = new EventEmitter<void>();

  public value: number;
  public unitValue: string;
  private propagateChanged: (value: number) => void;
  private propagateTouched: () => void;

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  public writeValue(value: number): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onChanged(event: any): void {
    if (typeof event.value === 'number') {
      this.value = event.value;
    } else {
      this.value = null;
    }

    this.propagateChanged(this.value);
  }

  public onTouched(): void {
    this.propagateTouched();
    this.touched.emit();
  }

  public onFocused(): void {
    this.focused.emit();
  }
}
