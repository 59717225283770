import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-quality-status',
  templateUrl: './quality-status.component.html',
  styleUrls: ['./quality-status.component.scss']
})
export class QualityStatusComponent {
  @Input() public checkText: string;
  @Input() public checkClass: string;
}
