import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { HeaderKpi, ProductionOrderSetupPhaseState, Quantity } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { ActiveOrderData } from './active-order-data.model';
import { ActiveOrderDataStore } from './active-order-data.store';

@Injectable({
  providedIn: 'root'
})
export class ActiveOrderDataQuery extends Query<ActiveOrderData> {
  public wasteAllocations$ = this.select(({ wasteAllocations }) => wasteAllocations);
  public setupPhaseStatus$: Observable<ProductionOrderSetupPhaseState> = this.select(({ setupPhaseStatus }) => setupPhaseStatus);
  public inputQuantityPool$: Observable<Quantity> = this.select(({ inputQuantityPool }) => inputQuantityPool);
  public outputQuantityPool$: Observable<Quantity> = this.select(({ outputQuantityPool }) => outputQuantityPool);
  public headerKpis$: Observable<HeaderKpi> = this.select<HeaderKpi>(({ headerKpi }) => headerKpi);

  constructor(protected store: ActiveOrderDataStore) {
    super(store);
  }
}
