<div class="nc-info">
  <div class="nc-info__title">{{ 'QA_HISTORY.INFO' | translate }}</div>
  <div *ngIf="isCreateNCMode; else notCreate">
    <textarea
      rows="10"
      class="nc-info__textarea"
      pInputTextarea
      autoResize
      [ngModel]="internalMemoInfo"
      (ngModelChange)="onInfoUpdated($event)"
    ></textarea>
  </div>
  <ng-template #notCreate>
    <div class="nc-info__text">
      {{ nonConformanceModel?.internalMemo }}
    </div>
  </ng-template>
</div>
