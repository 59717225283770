import { AccessTokenFactory } from 'chronos-core-client';
import { AuthorizeService } from './authorize.service';

export class AuthorizeAccessTokenFactory implements AccessTokenFactory {
  constructor(private authorizeService: AuthorizeService) {}

  public getAccessToken(): Promise<string> {
    return this.authorizeService.getAccessToken().toPromise();
  }
}
