import { Injectable } from '@angular/core';
import { QaCheckResult, QaCheckService, SetQaCheckResultEntry, PeriodicQaCheck, CreateManualQaChecksEntry } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ActiveOrderQuery } from '@app/core/global-state';

@Injectable({
  providedIn: 'root'
})
export class QaCheckDsService {
  constructor(
    private qaCheckService: QaCheckService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  public setAllQaChecksForSetup(qaCheckResult: SetQaCheckResultEntry): Observable<null> {
    const params: QaCheckService.SetAllQaChecksForSetupParams = {
      productionOrderId: this.activeOrderQuery.getActiveOrderId(),
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      entry: qaCheckResult
    };
    return this.qaCheckService.setAllQaChecksForSetup(params);
  }

  public setAllQaChecksForPeriodic(periodicQaCheckId: number, qaCheckResult: SetQaCheckResultEntry): Observable<null> {
    const params: QaCheckService.SetAllQaChecksForPeriodicParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      periodicQaCheckId,
      entry: qaCheckResult
    };
    return this.qaCheckService.setAllQaChecksForPeriodic(params);
  }

  public setAllQaChecksForProducedMaterial(producedMaterialId: number, qaCheckResult: SetQaCheckResultEntry): Observable<null> {
    const params: QaCheckService.SetAllQaChecksForProducedMaterialParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      producedMaterialId,
      entry: qaCheckResult
    };
    return this.qaCheckService.setAllQaChecksForProducedMaterial(params);
  }

  public setCheckResult(
    productionQaCheckId: number,
    qaCheckResult: QaCheckResult,
    qaCheckComment: string,
    qaMeasurementResult: number
  ): Observable<null> {
    const workCenterId: number = this.activeWorkCenterService.getWorkCenterId();
    const entry: SetQaCheckResultEntry = {
      checkResult: qaCheckResult,
      comment: qaCheckComment,
      workCenterId,
      measurementValue: qaMeasurementResult
    };
    const params: QaCheckService.SetCheckResultParams = {
      workCenterId,
      productionQaCheckId,
      entry
    };
    return this.qaCheckService.setCheckResult(params);
  }

  public getManualProductionQaChecks(productionOrderId: number): Observable<PeriodicQaCheck> {
    return this.qaCheckService.getManualProductionQaChecks(productionOrderId);
  }

  public getActivePeriodiQaChecks(productionOrderId: number): Observable<PeriodicQaCheck> {
    return this.qaCheckService.getActivePeriodicCheck(productionOrderId);
  }

  public createManualQaChecks(manualQACheckItems: CreateManualQaChecksEntry): Observable<null> {
    const params: QaCheckService.CreateManualQaChecksParams = {
      productionOrderId: this.activeOrderQuery.getActiveOrderId(),
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      entry: manualQACheckItems
    };

    return this.qaCheckService.createManualQaChecks(params);
  }
}
