/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FrontendConfig } from '../models/frontend-config';
import { FrontendFeatures } from '../models/frontend-features';
@Injectable({
  providedIn: 'root'
})
class FrontendConfigService extends __BaseService {
  static readonly getConfigPath = '/api/v1/FrontendConfig';
  static readonly getFeaturesPath = '/api/v1/FrontendFeatures';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }
  getConfigResponse(): __Observable<__StrictHttpResponse<FrontendConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/FrontendConfig`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FrontendConfig>;
      })
    );
  }
  getConfig(): __Observable<FrontendConfig> {
    return this.getConfigResponse().pipe(__map((_r) => _r.body as FrontendConfig));
  }
  getFeaturesResponse(): __Observable<__StrictHttpResponse<FrontendFeatures>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/FrontendFeatures`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FrontendFeatures>;
      })
    );
  }
  getFeatures(): __Observable<FrontendFeatures> {
    return this.getFeaturesResponse().pipe(__map((_r) => _r.body as FrontendFeatures));
  }
}

module FrontendConfigService {}

export { FrontendConfigService };
