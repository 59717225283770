import { Component, ContentChildren, Input, TemplateRef } from '@angular/core';
import { ContainerPalletFormDirective } from '../container-pallet-form/container-pallet-form.directive';
import { ContainerPalletKpiDirective } from '../container-pallet-kpi/container-pallet-kpi.directive';

@Component({
  selector: 'lib-container-pallet-template',
  templateUrl: './container-pallet-template.component.html',
  styleUrls: ['./container-pallet-template.component.scss']
})
export class ContainerPalletTemplateComponent {
  @ContentChildren(ContainerPalletFormDirective, { read: TemplateRef }) public palletForm: [];
  @ContentChildren(ContainerPalletKpiDirective, { read: TemplateRef }) public palletKpi: [];

  @Input() public textLabel = '';
  @Input() public showLabel = false;
}
