import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActiveOrderDsService, QaHistoryDsService } from '@app/core/data-services';
import { NextSetupSubphaseCommandService } from '@app/modules/setup-phase/commands';
import { SetupFooterService } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import { Observable, Subject, Subscription } from 'rxjs';
import { SetupPhaseService } from '../../services/setup-phase/setup-phase.service';
import { ProductionQaCheck, RunSubPhaseType } from 'chronos-core-client';
import { LineClearanceService } from '../../services/line-clearance/line-clearance.service';
import { debounceTime } from 'rxjs/operators';
import { QaCheckService } from '@app/core/services';

@Component({
  selector: 'app-line-clearance',
  templateUrl: './line-clearance.component.html',
  styleUrls: ['./line-clearance.component.scss']
})
export class LineClearanceComponent implements OnInit, AfterViewInit {
  @ViewChild('scanInput', { static: true }) public scanInput: ElementRef;
  @ViewChild('clearance') public clearance: ElementRef;

  public fields$: Observable<ProductionQaCheck[]>;
  public nextCommandService: NextSetupSubphaseCommandService;
  public isCapsLockOn = false;
  public scanInputText;
  private input$ = new Subject<string>();
  private productionOrderId: number;
  private subscriptions = new Subscription();

  constructor(
    private lineClearanceService: LineClearanceService,
    private activeOrderDsService: ActiveOrderDsService,
    private setupPhaseService: SetupPhaseService,
    private setupFooterService: SetupFooterService,
    private changeDetector: ChangeDetectorRef,
    private qaCheckService: QaCheckService,
    private qaHistoryDsService: QaHistoryDsService
  ) {
    this.input$.pipe(debounceTime(30000)).subscribe(() => {
      this.scanInputText = '';
    });
  }

  public ngOnInit(): void {
    this.productionOrderId = this.activeOrderDsService.getActiveOrderId();
    this.fields$ = this.lineClearanceService.getQACheckFields(this.productionOrderId);
    this.setCommands();

    this.subscriptions.add(
      this.qaHistoryDsService.isQaHistoryAvailableForSetup().subscribe((isAvailable) => {
        this.updateNextCommand(isAvailable);
      })
    );

    this.lineClearanceService.setFocus$.subscribe(() => {
      this.setFocusScanInput();
    });

    this.lineClearanceService.checkCapsLock$.subscribe((event) => {
      this.manageCapsLock(event);
    });

    this.lineClearanceService.validation$.subscribe((invalidItems) => {
      this.nextCommandService.enabled = invalidItems.length === 0;
    });

    this.SetfocusScanField();
  }

  public ngAfterViewInit(): void {
    this.setFocusScanInput();

    const eventHandler = (event: KeyboardEvent | MouseEvent) => {
      if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
        return;
      }
      this.manageCapsLock(event);
      this.changeDetector.markForCheck();
      this.setFocusScanInput();
    };

    this.scanInput?.nativeElement?.addEventListener('keyup', eventHandler);
    this.scanInput?.nativeElement?.addEventListener('mousedown', eventHandler);
  }

  public onFormValidityChange(isQaCheckValid: boolean): void {
    const customeValidation = this.lineClearanceService.validationSubject.value?.length;
    this.nextCommandService.enabled = isQaCheckValid && customeValidation === 0;
  }

  @HostListener('window:keydown.enter', ['$event'])
  public SetfocusScanField() {
    if (this.isFocusAvailable()) {
      this.setFocusScanInput();
    }

    this.manageCapsLock();
  }

  public onClick(event: KeyboardEvent | MouseEvent) {
    if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
      return;
    }

    this.SetfocusScanField();
    this.manageCapsLock(event);
  }

  public onBlur(event: KeyboardEvent | MouseEvent) {
    if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
      return;
    }

    this.SetfocusScanField();
    this.manageCapsLock(event);
  }

  public onInput(value: string) {
    this.input$.next(value);
  }

  public onEnter(value: string) {
    this.qaCheckService.setCheckResultMultipleRows(this.fields$, value).subscribe((res) => {
      if (res) {
        this.fields$ = this.lineClearanceService.getQACheckFields(this.productionOrderId);
      }
    });

    this.scanInputText = '';
  }

  private isFocusAvailable(): boolean {
    const modal = document.querySelector('p-dynamicdialog');

    if (modal) {
      return false;
    }

    return document.activeElement?.tagName !== 'INPUT' && document.activeElement?.tagName !== 'SELECT';
  }

  private manageCapsLock(event?: KeyboardEvent | MouseEvent) {
    this.isCapsLockOn = event?.getModifierState('CapsLock') ? true : false;
  }

  private setFocusScanInput(): void {
    this.scanInput.nativeElement.focus();
  }

  private setCommands(): void {
    this.nextCommandService = new NextSetupSubphaseCommandService(this.setupFooterService);
    this.setupPhaseService.setSetupCommands(null, this.nextCommandService);
  }

  private updateNextCommand(isQaAvailable: boolean): void {
    if (isQaAvailable) {
      this.nextCommandService.setNavigationParams(RunSubPhaseType.SETUP_QA_HISTORY, nav.routes.setupQaHistory);
    } else {
      this.nextCommandService.setNavigationParams(RunSubPhaseType.SETUP_PARAMETER, nav.routes.setupParameters);
    }
  }
}
