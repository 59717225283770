/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SimulationSpeedEntry } from '../models/simulation-speed-entry';
import { RecreatePeriodsEntry } from '../models/recreate-periods-entry';
@Injectable({
  providedIn: 'root'
})
class SimulationService extends __BaseService {
  static readonly setSpeedPath = '/api/v1/Simulation/{workCenterId}/speed';
  static readonly recreatePeriodsPath = '/api/v1/Simulation/{workCenterId}/RecreatePeriods';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `SimulationService.SetSpeedParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  setSpeedResponse(params: SimulationService.SetSpeedParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Simulation/${encodeURIComponent(String(params.workCenterId))}/speed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SimulationService.SetSpeedParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  setSpeed(params: SimulationService.SetSpeedParams): __Observable<null> {
    return this.setSpeedResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SimulationService.RecreatePeriodsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  recreatePeriodsResponse(params: SimulationService.RecreatePeriodsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Simulation/${encodeURIComponent(String(params.workCenterId))}/RecreatePeriods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SimulationService.RecreatePeriodsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  recreatePeriods(params: SimulationService.RecreatePeriodsParams): __Observable<null> {
    return this.recreatePeriodsResponse(params).pipe(__map((_r) => _r.body as null));
  }
}

module SimulationService {
  /**
   * Parameters for SetSpeed
   */
  export interface SetSpeedParams {
    workCenterId: number;
    entry: SimulationSpeedEntry;
  }

  /**
   * Parameters for RecreatePeriods
   */
  export interface RecreatePeriodsParams {
    workCenterId: number;
    entry: RecreatePeriodsEntry;
  }
}

export { SimulationService };
