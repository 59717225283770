import { Injectable } from '@angular/core';
import { MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import {
  CreateAndAssignTraceContainerEntry,
  MaterialAvailableAtLocation,
  MaterialService,
  MaterialType,
  MountContainerEntry,
  Quantity,
  TraceMaterial,
  TraceMaterialService,
  WorkCenterIdEntry
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MountingMaterialServiceDsService } from '../mounting-material-ds';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({
  providedIn: 'root'
})
export class TraceMaterialDsService {
  constructor(
    private traceMaterialService: TraceMaterialService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private mountingMaterialServiceDsService: MountingMaterialServiceDsService,
    private matrialService: MaterialService
  ) {}

  public getTraceMaterial(): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.traceMaterialService
      .getTraceMaterialAssigned(workCenterId)
      .pipe(map((traceMaterial: TraceMaterial) => this.mountingMaterialServiceDsService.mapTraceMaterial(traceMaterial.materialBlocks)));
  }

  public unassignTraceMaterial(mountedMaterialId: number): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const workCenterIdEntry: WorkCenterIdEntry = {
      workCenterId
    };
    const params: TraceMaterialService.UnassignParams = {
      mountedMaterialId,
      entry: workCenterIdEntry
    };
    return this.traceMaterialService.unassign(params).pipe(switchMap(() => this.getTraceMaterial()));
  }

  public createAndAssignTraceMaterial(entry: CreateAndAssignTraceContainerEntry): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const params: TraceMaterialService.CreateAndAssignParams = {
      workCenterId,
      entry
    };
    return this.traceMaterialService.createAndAssign(params).pipe(switchMap(() => this.getTraceMaterial()));
  }

  public getAvailableTraceMaterial(articleId: number): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const params: TraceMaterialService.GetMaterialAvailableForArticle2Params = {
      workCenterId,
      articleId
    };
    return this.traceMaterialService.getMaterialAvailableForArticle2(params);
  }

  public assignTraceMaterial(
    containerId: number,
    materialBlockId: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: MountContainerEntry = {
      containerId,
      materialBlockId,
      materialType: MaterialType.TRACE,
      whiteWasteQuantity
    };
    const params: MaterialService.MountContainerParams = {
      workCenterId,
      entry
    };
    return this.matrialService.mountContainer(params).pipe(switchMap(() => this.getTraceMaterial()));
  }
}
