<div
  class="mount-pallet-list"
  [ngClass]="{
    'mount-pallet-list--dummy': currentMountedMaterial.container.isVirtualContainer
  }"
>
  <app-active-pallet-animation [isAnimationPlay]="animationState === ANIMATION_STATES.PLAYING"></app-active-pallet-animation>

  <div class="mount-pallet-list__wrapper">
    <div class="mount-pallet-list__amount">
      <lib-pallet-number
        [palletNumber]="currentMountedMaterial.materialSequence"
        [classification]="currentMountedMaterial.article.classification"
      ></lib-pallet-number>
    </div>

    <div class="mount-pallet-list__actions-input">
      <span
        class="mount-pallet-list__actions-article-width"
        *ngIf="
          currentMountedMaterial.container?.article?.externalArticleId ||
            currentMountedMaterial.container?.article?.externalArticleId as externalArticleId;
          else ValueNotExist
        "
      >
        {{ externalArticleId }}
      </span>
      <span
        class="mount-pallet-list__actions-configuration-width"
        *ngIf="
          currentMountedMaterial.container?.article?.externalConfigurationId ||
            currentMountedMaterial.container?.article?.externalConfigurationId as externalConfigurationId;
          else ValueNotExist
        "
      >
        {{ externalConfigurationId }}
      </span>
    </div>

    <ng-template #ValueNotExist>
      <div>-</div>
    </ng-template>

    <div class="mount-pallet-list__info">
      <div *ngIf="currentMountedMaterial.qaCheckResult && this.showStatus" class="qa-check">
        <ng-container>
          <lib-quality-status
            (click)="openQACheckModalDialog()"
            [checkClass]="palletQualityStatus.labelClass"
            [checkText]="palletQualityStatus.text | translate"
          ></lib-quality-status>
        </ng-container>
      </div>
      <lib-pallet-kpi
        [compact]="true"
        [showInfoIcon]="currentMountedMaterial.container.isVirtualContainer"
        (infoButtonClicked)="onInfoButtonClick()"
        [kpiLabel]="'MOUNTING.SSCC' | translate"
        [kpiValue]="currentMountedMaterial.identificationCode"
        [isSsccCodeShort]="true"
      ></lib-pallet-kpi>

      <lib-pallet-kpi
        [compact]="true"
        [kpiLabel]="'MOUNTING.MOUNTED' | translate"
        [kpiValue]="currentMountedMaterial.mountedQuantity | formatQuantity : false"
      ></lib-pallet-kpi>

      <div *ngIf="currentMountedMaterial.container.isVirtualContainer" class="mount-pallet-list__actions">
        <div class="mount-pallet-list__actions-label"></div>
        <div class="mount-pallet-list__waste-actions">
          <div class="mount-pallet-list__actions-button">
            <lib-button
              class="mount-pallet-list__actions-button-replace"
              [label]="'MOUNTING.REPLACE'"
              (clicked)="onReplaceClick(currentMountedMaterial.mountedMaterialId, currentMountedMaterial.container.containerId)"
            ></lib-button>
          </div>
        </div>
        <div class="mount-pallet-list__other-actions">
          <div>
            <i class="icon-warning-solid mount-pallet-list__actions-input-icon"></i>
          </div>
          <div class="mount-pallet-list__actions-label">
            {{ 'MOUNTING.DUMMY' | translate }}
          </div>
        </div>
      </div>

      <div *ngIf="!currentMountedMaterial.container.isVirtualContainer" class="mount-pallet-list__actions">
        <div class="mount-pallet-list__actions-label">
          {{ 'MOUNTING.WHITE_WASTE' | translate }}
        </div>
        <div class="mount-pallet-list__waste-actions">
          <div class="mount-pallet-list__actions-input">
            <lib-input-number
              [inputStyle]="INPUT_STYLE"
              [(ngModel)]="whiteWaste.value"
              (focused)="stopAnimationOnFocus()"
              (touched)="startAnimationOnBlur()"
              [unit]="whiteWasteUnitId | uppercase"
              [showUnit]="false"
            ></lib-input-number>
          </div>
          <div class="mount-pallet-list__actions-button">
            <lib-button [label]="'MOUNTING.OK'" (clicked)="addContainerWhiteWaste(currentMountedMaterial.mountedMaterialId)"></lib-button>
          </div>
        </div>
        <div class="mount-pallet-list__other-actions">
          <div class="mount-pallet-list__actions-total">
            {{ 'MOUNTING.TOTAL' | translate }}
          </div>
          <div class="mount-pallet-list__actions-total-waste">
            {{ currentMountedMaterial.whiteWasteQuantity | formatQuantity : false }}
          </div>
          <i
            class="icon-reset mount-pallet-list__actions-reset-icon cursor-clickable"
            (click)="resetContainerWhiteWaste(currentMountedMaterial.mountedMaterialId)"
          ></i>
        </div>
      </div>
      <div class="mount-pallet-list__actions">
        <i
          (click)="onDismountClick(currentMountedMaterial)"
          class="icon-unmount-pallet mount-pallet-list__actions-mount-status"
          [ngClass]="{ 'mount-pallet-list__actions-mount-status--dismounting-allowed': isDismountingAllowed() }"
        ></i>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
