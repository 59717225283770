import { Component, OnDestroy, OnInit } from '@angular/core';
import { DowntimeCodeOptions, TaskCategoryQuery, TaskService } from '@app/core/global-state';
import { TaskCategoryListDsService } from '@app/core/data-services';
import { nav } from '@app/shared/utils';
import { isEmpty } from 'ramda';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { WorkCenterTasksByCategory } from 'chronos-core-client';

@Component({
  selector: 'app-task-category-list',
  templateUrl: './task-category-list.component.html',
  styleUrls: ['./task-category-list.component.scss']
})
export class TaskCategoryListComponent implements OnInit, OnDestroy {
  public taskCategories$: Observable<WorkCenterTasksByCategory[]>;

  private subscriptions = new Subscription();

  constructor(
    private taskCategoryQuery: TaskCategoryQuery,
    private taskCategoryListService: TaskCategoryListDsService,
    private taskService: TaskService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(this.taskCategoryListService.getTaskCategoryListWithNotifications().subscribe());
    this.taskCategories$ = this.taskCategoryQuery.taskCategories$;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public trackByFunction(index: number, task: WorkCenterTasksByCategory): string {
    return task.type;
  }

  public isEmpty(taskCategories: WorkCenterTasksByCategory[]): boolean {
    return isEmpty(taskCategories);
  }

  public navigateToTasks(taskType: string): void {
    this.taskService.updateFilters({
      downtimeCodeAssignation: DowntimeCodeOptions.Open,
      taskType,
      productionOrder: 'All',
      textQuery: ''
    });

    this.router.navigate([nav.routes.tasks]);
  }
}
