/* tslint:disable */
type RemarkType =
  | 'VirtualContainerInfo'
  | 'ProductionOrderInfoPL'
  | 'ProductionJobInfoPL'
  | 'ProductionOrderInfoShopFloor'
  | 'ProductionKpiInfo'
  | 'ShiftReportInfo';
module RemarkType {
  export const VIRTUAL_CONTAINER_INFO: RemarkType = 'VirtualContainerInfo';
  export const PRODUCTION_ORDER_INFO_PL: RemarkType = 'ProductionOrderInfoPL';
  export const PRODUCTION_JOB_INFO_PL: RemarkType = 'ProductionJobInfoPL';
  export const PRODUCTION_ORDER_INFO_SHOP_FLOOR: RemarkType = 'ProductionOrderInfoShopFloor';
  export const PRODUCTION_KPI_INFO: RemarkType = 'ProductionKpiInfo';
  export const SHIFT_REPORT_INFO: RemarkType = 'ShiftReportInfo';
  export function values(): RemarkType[] {
    return [
      VIRTUAL_CONTAINER_INFO,
      PRODUCTION_ORDER_INFO_PL,
      PRODUCTION_JOB_INFO_PL,
      PRODUCTION_ORDER_INFO_SHOP_FLOOR,
      PRODUCTION_KPI_INFO,
      SHIFT_REPORT_INFO
    ];
  }
}

export { RemarkType };
