import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sscc'
})
export class SsccPipe implements PipeTransform {
  private readonly LAST_SYMBOLS_COUNT = 6;

  public transform(value: string): string {
    if (value?.length > this.LAST_SYMBOLS_COUNT) {
      const firstPart = value.slice(0, value.length - this.LAST_SYMBOLS_COUNT);
      const lastPart = value.slice(value.length - this.LAST_SYMBOLS_COUNT, value.length);
      return `${firstPart}<b>${lastPart}</b>`;
    }
    return value;
  }
}
