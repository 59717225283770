import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ShiftReportOrdersState, ShiftReportOrdersStore } from './shift-report-orders.store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ShiftReportItem, ShiftReportItemFilters } from './shift-report-orders.model';
import { ShiftReportStatus } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportOrdersQuery extends QueryEntity<ShiftReportOrdersState, ShiftReportItem> {
  constructor(protected store: ShiftReportOrdersStore) {
    super(store);
  }

  public filters$: Observable<ShiftReportItemFilters> = this.select((state) => state.filters);
  public status$: Observable<ShiftReportStatus> = this.select((state) => state.shiftReportStatus);

  public shiftReportOrders$ = this.filters$.pipe(
    switchMap((filters) =>
      this.selectAll({
        filterBy: (item: ShiftReportItem) => this.filterByProductionOrder(filters, item)
      })
    )
  );

  public getRemarkId(): number {
    return this.getValue().shiftReportRemarkId;
  }

  private filterByProductionOrder(filters: ShiftReportItemFilters, item: ShiftReportItem): boolean {
    const productionOrder = filters.productionOrder;
    if (productionOrder === '') {
      return true;
    } else if (item.productionOrder) {
      return item.productionOrder.code.toLowerCase().includes(productionOrder.toLowerCase());
    }
    return false;
  }
}
