import { Injectable } from '@angular/core';
import { ProductionPeriodDetailData, WasteAssignmentInTime, WastePerReason, WorkCenterTask } from 'chronos-core-client';
import { TelemetryResultDto } from 'chronos-panda-client';
import moment from 'moment-mini';
import { ChartType } from '../models/chart-type.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MachineChartConfigurationService {
  public runModeText: string;
  public targetSpeedText: string;
  public setupModeText: string;
  public downModeText: string;
  public downtimeCodeText: string;
  public downtimeCommentText: string;
  public noComment: string;
  public start: string;
  public rest: string;
  public shtCounter: string;
  public pcsCounter: string;
  public shift: string;
  public externalShiftType: string;
  public date: string;
  public productionOrderNumber: string;
  public finishGoodArticleNumber: string;
  public finishGoodArticleName: string;
  public confiNameNumber: string;
  public goodQuantityUnit: string;
  public wasteQuantMalQunt: string;
  public dateFormat: string;
  public speed2Unit: string;
  public productionChangeSeries: string;
  public speedSeries: string;
  public speed2Series: string;
  public washSeries: string;
  public downtimeTask: string;
  public newSeriesName: string;
  public fullviewSeriesdata: any[] = [];
  public availableSignalSeries: any[] = [];
  public wasteQuantity: string;
  public maculatureQuantity: string;
  public wasteReason: string;
  public setupKind: string;

  constructor(private translate: TranslateService) {
    this.setChartTranslations();
  }

  public setChartTranslations() {
    this.translate
      .stream([
        'MACHINE_CHART.RUN_MODE_TEXT',
        'MACHINE_CHART.TARGET_SPEED_TEXT',
        'MACHINE_CHART.SETUP_MODE_TEXT',
        'MACHINE_CHART.DOWN_MODE_TEXT',
        'MACHINE_CHART.DOWNTIME_CODE_TEXT',
        'MACHINE_CHART.DOWNTIME_COMMENT_TEXT',
        'MACHINE_CHART.NO_COMMENT',
        'MACHINE_CHART.START',
        'MACHINE_CHART.REST',
        'MACHINE_CHART.SHT_UNIT',
        'MACHINE_CHART.PCS_UNIT',
        'MACHINE_CHART.SHIFT',
        'MACHINE_CHART.EXTERNAL_SHIFT_TYPE',
        'MACHINE_CHART.DATE',
        'MACHINE_CHART.PRODUCTION_ORDER_NUMBER',
        'MACHINE_CHART.FINISH_GOOD_ARTICLE_NUMBER',
        'MACHINE_CHART.FINISH_GOOD_ARTICLE_NAME',
        'MACHINE_CHART.CONFIGURATION_NUMBER_AND_NAME',
        'MACHINE_CHART.GOOD_QUANITY_UNIT',
        'MACHINE_CHART.WASTE_QUANTITY_MACULATURE_QUANTITY',
        'MACHINE_CHART.DATE_FORMAT',
        'MACHINE_CHART.SPEED2_UNIT',
        'MACHINE_CHART.SPEED2_SERIES',
        'MACHINE_CHART.WASH_SERIES',
        'MACHINE_CHART.DOWNTIME_TASK',
        'MACHINE_CHART.WASTE_QUANTITY',
        'MACHINE_CHART.MACULATURE_QUANTITY',
        'MACHINE_CHART.WASTE_REASON',
        'MACHINE_CHART.SETUP_KIND'
      ])
      .subscribe((values) => {
        Object.keys(values).map((key) => {
          switch (key) {
            case 'MACHINE_CHART.SPEED2_SERIES':
              this.speed2Series = values[key];
              break;
            case 'MACHINE_CHART.WASH_SERIES':
              this.washSeries = values[key];
              break;
            case 'MACHINE_CHART.RUN_MODE_TEXT':
              this.runModeText = values[key];
              break;
            case 'MACHINE_CHART.TARGET_SPEED_TEXT':
              this.targetSpeedText = values[key];
              break;
            case 'MACHINE_CHART.SETUP_MODE_TEXT':
              this.setupModeText = values[key];
              break;
            case 'MACHINE_CHART.DOWN_MODE_TEXT':
              this.downModeText = values[key];
              break;
            case 'MACHINE_CHART.DOWNTIME_CODE_TEXT':
              this.downtimeCodeText = values[key];
              break;
            case 'MACHINE_CHART.DOWNTIME_COMMENT_TEXT':
              this.downtimeCommentText = values[key];
              break;
            case 'MACHINE_CHART.NO_COMMENT':
              this.noComment = values[key];
              break;
            case 'MACHINE_CHART.START':
              this.start = values[key];
              break;
            case 'MACHINE_CHART.REST':
              this.rest = values[key];
              break;
            case 'MACHINE_CHART.SHT_UNIT':
              this.shtCounter = values[key];
              break;
            case 'MACHINE_CHART.PCS_UNIT':
              this.pcsCounter = values[key];
              break;
            case 'MACHINE_CHART.SHIFT':
              this.shift = values[key];
              break;
            case 'MACHINE_CHART.EXTERNAL_SHIFT_TYPE':
              this.externalShiftType = values[key];
              break;
            case 'MACHINE_CHART.DATE':
              this.date = values[key];
              break;
            case 'MACHINE_CHART.PRODUCTION_ORDER_NUMBER':
              this.productionOrderNumber = values[key];
              break;
            case 'MACHINE_CHART.FINISH_GOOD_ARTICLE_NUMBER':
              this.finishGoodArticleNumber = values[key];
              break;
            case 'MACHINE_CHART.FINISH_GOOD_ARTICLE_NAME':
              this.finishGoodArticleName = values[key];
              break;
            case 'MACHINE_CHART.CONFIGURATION_NUMBER_AND_NAME':
              this.confiNameNumber = values[key];
              break;
            case 'MACHINE_CHART.GOOD_QUANITY_UNIT':
              this.goodQuantityUnit = values[key];
              break;
            case 'MACHINE_CHART.WASTE_QUANTITY_MACULATURE_QUANTITY':
              this.wasteQuantMalQunt = values[key];
              break;
            case 'MACHINE_CHART.DATE_FORMAT':
              this.dateFormat = values[key];
              break;
            case 'MACHINE_CHART.SPEED2_UNIT':
              this.speed2Unit = values[key];
              break;
            case 'MACHINE_CHART.DOWNTIME_TASK':
              this.downtimeTask = values[key];
              break;
            case 'MACHINE_CHART.WASTE_QUANTITY':
              this.wasteQuantity = values[key];
              break;
            case 'MACHINE_CHART.MACULATURE_QUANTITY':
              this.maculatureQuantity = values[key];
              break;
            case 'MACHINE_CHART.WASTE_REASON':
              this.wasteReason = values[key];
              break;
            case 'MACHINE_CHART.SETUP_KIND':
              this.setupKind = values[key];
              break;
          }
        });
      });
  }

  public getSplitOrEditDowntimeConfiguration(
    viewAreaEndDate: Date,
    targetSpeed: number,
    counterUnitId: string,
    isSplitDowntime: boolean
  ): any {
    return {
      chart: {
        height: 400,
        marginTop: 20,
        animation: false,
        zooming: {
          type: 'x'
        }
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: ''
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: ' %k:%M <br> %e %b '
        },
        max: isSplitDowntime === true ? null : viewAreaEndDate.valueOf()
      },
      yAxis: {
        title: {
          text: counterUnitId === 'SHT' ? this.shtCounter : this.pcsCounter
        },
        max: targetSpeed
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          dragDrop: {
            draggableX: true,
            groupBy: 'x'
          }
        },
        line: {
          cursor: 'pointer'
        }
      },
      tooltip: {
        formatter() {
          if (!this.point.noTooltip) {
            return this.point.tooltipValue;
          }
          return false;
        }
      },
      time: {
        useUTC: false
      },
      series: [],
      exporting: {
        enabled: false
      }
    };
  }

  public getEditSetupConfigurationWithoutScroll(viewAreaEndDate: Date, targetSpeed: number, counterUnitId: string): any {
    return {
      chart: {
        height: 400,
        marginTop: 20,
        animation: false,
        zooming: {
          type: 'x'
        }
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: ''
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: ' %k:%M <br> %e %b '
        },
        max: viewAreaEndDate.valueOf()
      },
      yAxis: {
        title: {
          text: counterUnitId === 'SHT' ? this.shtCounter : this.pcsCounter
        },
        max: targetSpeed
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          dragDrop: {
            draggableX: true,
            groupBy: 'x'
          }
        },
        line: {
          cursor: 'pointer'
        }
      },
      tooltip: {
        formatter() {
          if (!this.point.noTooltip) {
            return this.point.tooltipValue;
          }
          return false;
        }
      },
      time: {
        useUTC: false
      },
      series: [],
      exporting: {
        enabled: false
      }
    };
  }

  public getEditSetupConfiguration(viewAreaEndDate: Date, targetSpeed: number, counterUnitId: string): any {
    return {
      chart: {
        height: 400,
        marginTop: 20,
        animation: false,
        zooming: {
          type: 'x'
        }
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: ''
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: ' %k:%M <br> %e %b '
        },
        max: viewAreaEndDate.valueOf(),
        scrollbar: {
          enabled: true,
          barBackgroundColor: 'gray',
          liveRedraw: false,
          barBorderRadius: 5,
          buttonBorderRadius: 5,
          barBorderWidth: 0,
          rifleColor: 'gray',
          trackBackgroundColor: 'white',
          trackBorderWidth: 1,
          trackBorderColor: 'gray',
          trackBorderRadius: 5
        }
      },
      yAxis: {
        title: {
          text: counterUnitId === 'SHT' ? this.shtCounter : this.pcsCounter
        },
        max: targetSpeed
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          dragDrop: {
            draggableX: true,
            groupBy: 'x'
          }
        },
        line: {
          cursor: 'pointer'
        }
      },
      tooltip: {
        formatter() {
          if (!this.point.noTooltip) {
            return this.point.tooltipValue;
          }
          return false;
        }
      },
      time: {
        useUTC: false
      },
      series: [],
      exporting: {
        enabled: false
      }
    };
  }

  public getFullViewConfiguration(
    workCenterName: string,
    externalWorkCenterID: string,
    targetSpeed: number,
    targetSpeed2: number,
    counterUnitId: string,
    chartHeight: number
  ): any {
    return {
      chart: {
        type: 'area',
        animation: false,
        borderColor: '#E6E6E6',
        borderWidth: 1,
        height: chartHeight,
        style: {
          fontFamily: 'Roboto,sans-serif;'
        },
        zooming: {
          type: 'x'
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: `${externalWorkCenterID}-${workCenterName}`
      },
      time: {
        useUTC: false
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: ' %k:%M <br> %e %b '
        },
        labels: {
          style: {
            font: '14px "Roboto", sans-serif;'
          }
        }
      },
      tooltip: {
        valueDecimals: 0,
        useHTML: true,
        formatter() {
          if (this.point.noTooltip) {
            return this.point.tooltipValue;
          }

          if (this.series.name === 'Waste') {
            return `<div class="wastetooltip"> ${this.point.tooltipValue} </div>`;
          } else {
            return this.point.tooltipValue;
          }
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false
          }
        }
      },
      yAxis: [
        {
          title: {
            text: counterUnitId === 'SHT' ? this.shtCounter : this.pcsCounter
          },
          max: targetSpeed,
          labels: {
            style: {
              font: '14px "Roboto", sans-serif;'
            }
          }
        },
        {
          title: {
            text: this.speed2Unit
          },
          opposite: true,
          max: targetSpeed2,
          min: 0,
          showEmpty: false,
          labels: {
            style: {
              font: '14px "Roboto", sans-serif;'
            }
          }
        }
      ],
      series: [],
      exporting: {
        enabled: true
      }
    };
  }

  public getFinishPhaseConfiguration(
    orderScheduleId: string,
    startTime: string,
    timeRemaining: string,
    counterUnitId: string,
    chartType: string
  ): any {
    return {
      chart: {
        type: 'area',
        animation: false,
        borderColor: '#E6E6E6',
        borderWidth: 1,
        height: 300,
        style: {
          fontFamily: '"Roboto", sans-serif;'
        },
        zooming: {
          type: 'x'
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      title: {
        text:
          chartType === ChartType.donwtimeTask
            ? this.downtimeTask
            : `<b>${orderScheduleId}</b> <span style=\\"font-size:15px\\">
              (${this.start}:${startTime} | ${this.rest}:${timeRemaining} min)  </span>`,
        align: 'left',
        margin: 20
      },
      time: {
        useUTC: false
      },
      xAxis: [
        {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%H:%M',
            day: ' %k:%M <br> %e %b '
          }
        }
      ],
      yAxis: {
        title: {
          text: counterUnitId === 'SHT' ? this.shtCounter : this.pcsCounter
        }
      },
      tooltip: {
        valueDecimals: 0,
        formatter() {
          if (!this.point.noTooltip) {
            return this.point.tooltipValue;
          }
          return false;
        }
      },
      series: [],
      exporting: {
        enabled: false
      }
    };
  }

  public getSeriesConfiguration(
    result: ProductionPeriodDetailData[],
    allDataSignal: TelemetryResultDto[],
    wasteReasons: WasteAssignmentInTime[],
    targetSpeed: number,
    setupStartTime: Date,
    setupEndTime: Date,
    chartMode: string,
    plotBandHeight: number,
    counterUnitId: string,
    isMarkerEnabled: boolean,
    downtimeResult: WorkCenterTask,
    excludedSignal: string
  ): any[] {
    this.fullviewSeriesdata = [];
    this.availableSignalSeries = [];
    const setupDownLimit = (15 / 100) * targetSpeed;
    const runLimit = (5 / 100) * targetSpeed;
    const setupSeries = {
      name: this.setupModeText,
      data: [],
      step: 'left',
      type: 'area',
      color: '#FAE3B3',
      inside: true,
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        enabled: isMarkerEnabled
      },
      fillColor: '#FAE3B3',
      showInLegend: false
    };
    const runSeries = {
      name: this.runModeText,
      data: [],
      step: 'left',
      type: 'area',
      color: '#00B7B9',
      inside: true,
      fillColor: '#00B7B9',
      lineColor: '#00A3A5',
      turboThreshold: 10000,
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        enabled: isMarkerEnabled
      },
      showInLegend: true,
      yAxis: 0
    };

    const downSeries = {
      name: this.downModeText,
      cursor: 'pointer',
      data: [],
      type: 'area',
      color: '#83D6F4',
      inside: true,
      step: 'left',
      states: {
        inactive: {
          opacity: 1
        }
      },
      showInLegend: false,
      allowPointSelect: true,
      marker: {
        enabled: isMarkerEnabled,
        states: {
          select: {
            fillColor: '#83D6F4'
          }
        }
      },
      dataLabels: [
        {
          enabled: true,
          align: 'center',
          style: {
            textShadow: 'none',
            fontSize: '13px'
          },
          x: 14,
          y: 20,
          useHTML: true,
          formatter() {
            return ` <div>${this.point.externalDowntimeLocalCode}</div>`;
          }
        }
      ]
    };
    const speedSeries = {
      name: counterUnitId === 'SHT' ? this.shtCounter : this.pcsCounter,
      data: [],
      type: 'area',
      step: 'left',
      color: '#689576',
      inside: true,
      fillColor: '#689576',
      lineColor: '#006E45',
      turboThreshold: 10000,
      states: {
        inactive: {
          opacity: 1
        }
      },
      yAxis: 0,
      marker: {
        enabled: isMarkerEnabled
      }
    };
    const targetSeries = {
      name: this.targetSpeedText,
      data: [],
      type: 'line',
      dashStyle: 'Dash',
      color: '#393d37',
      inside: true,
      step: 'left',
      showInLegend: true,
      marker: {
        enabled: isMarkerEnabled
      },
      states: {
        inactive: {
          opacity: 1
        }
      }
    };
    const plotBandSeries = {
      name: 'plotBandSeries',
      data: [],
      type: 'area',
      color: '#C8D4DF',
      inside: true,

      states: {
        inactive: {
          opacity: 1
        }
      },
      showInLegend: false,
      tooltip: {
        enabled: false
      },
      marker: {
        enabled: false
      },
      lineColor: '#C8D4DF',
      enableMouseTracking: false
    };
    const setupStartSeries = {
      type: 'line',
      name: 'setupStartSeries',
      stickyTracking: false,
      dragDrop: {
        draggableX: chartMode === ChartType.editDowntime ? false : true,
        groupBy: 'groupId',
        dragMaxX: moment(setupEndTime).add(1, 'hours').toDate().valueOf()
      },
      marker: {
        enabled: isMarkerEnabled
      },
      cursor: chartMode === ChartType.editDowntime ? 'none' : 'pointer',
      data: [
        {
          x: moment.utc(setupStartTime).valueOf(),
          y: targetSpeed,
          groupId: 'a',
          marker: {
            enabled: chartMode === ChartType.editDowntime ? false : true,
            fillColor: '#00325C',
            useHTML: true,
            cursor: 'Pointer',
            symbol: 'url(/assets/images/phase-setup_arrow_left.png)',
            className: 'plotline-left',
            radius: 10,
            align: 'left'
          },
          noTooltip: true,
          dataLabels: {
            align: 'left',
            enabled: true,
            useHTML: true,
            formatter() {
              return `<div class="plotline-left-label">${moment(this.x).format('HH:mm')}</div>`;
            }
          }
        },
        {
          x: moment.utc(setupStartTime).valueOf(),
          y: 0,
          noTooltip: true,
          groupId: 'a',
          marker: {
            enabled: true,
            radius: 0
          }
        }
      ],
      lineWidth: 3,
      lineColor: '#00325C',
      showInLegend: false,
      color: '#00325C',
      states: {
        inactive: {
          opacity: 1
        }
      }
    };
    const setupEndSeries = {
      name: 'setupEndSeries',
      type: 'line',
      stickyTracking: false,
      dragDrop: {
        draggableX: true,
        groupBy: 'groupId',
        dragMaxX: moment(setupEndTime).add(1, 'hours').toDate().valueOf()
      },
      marker: {
        enabled: isMarkerEnabled
      },
      cursor: 'Pointer',
      className: 'display-label',
      data: [
        {
          noTooltip: true,
          x: moment.utc(setupEndTime).valueOf(),
          y: targetSpeed,
          groupId: 'a',
          marker: {
            enabled: true,
            fillColor: '#00325C',
            useHTML: true,
            cursor: 'Pointer',
            symbol: 'url(/assets/images/phase-setup_arrow_right.png)',
            className: 'plotline-left',
            radius: 10,
            align: 'right'
          },
          dataLabels: {
            align: 'right',
            enabled: true,
            useHTML: true,
            formatter() {
              return `<div class="plotline-right-label">${moment(this.x).format('HH:mm')}</div>`;
            }
          }
        },
        {
          noTooltip: true,
          x: moment.utc(setupEndTime).valueOf(),
          y: 0,
          groupId: 'a',
          marker: {
            enabled: false,
            radius: 0
          }
        }
      ],
      lineWidth: 3,
      lineColor: '#00325C',
      showInLegend: false,
      color: '#00325C',
      states: {
        inactive: {
          opacity: 1
        }
      }
    };

    const editDowntimeEndSeries = {
      name: 'enditDowntimeEndSeries',
      type: 'line',
      stickyTracking: false,
      dragDrop: {
        draggableX: true,
        groupBy: 'groupId',
        dragMaxX: moment(setupEndTime).add(3, 'hours').toDate().valueOf(),
        dragMinX: moment(setupEndTime).valueOf()
      },
      marker: {
        enabled: isMarkerEnabled
      },
      cursor: 'Pointer',
      className: 'display-label',
      data: [
        {
          noTooltip: true,
          x: moment.utc(setupEndTime).valueOf(),
          y: targetSpeed,
          groupId: 'ed',
          marker: {
            enabled: true,
            fillColor: '#00325C',
            useHTML: true,
            cursor: 'Pointer',
            symbol: 'url(/assets/images/phase-setup_arrow_right.png)',
            className: 'plotline-left',
            radius: 10,
            align: 'right'
          },
          dataLabels: {
            align: 'right',
            enabled: true,
            useHTML: true,
            formatter() {
              return `<div class="plotline-right-label">${moment(this.x).format('HH:mm')}</div>`;
            }
          }
        },
        {
          noTooltip: true,
          x: moment.utc(setupEndTime).valueOf(),
          y: 0,
          groupId: 'ed',
          marker: {
            enabled: false,
            radius: 0
          }
        }
      ],
      lineWidth: 3,
      lineColor: '#00325C',
      showInLegend: false,
      color: '#00325C',
      states: {
        inactive: {
          opacity: 1
        }
      }
    };

    const splitDownTimeEndSeries = {
      name: 'splitDownTimeEndSeries',
      type: 'line',
      stickyTracking: false,
      dragDrop: {
        draggableX: true,
        groupBy: 'groupId',
        dragMaxX: moment(setupEndTime).valueOf()
      },
      data: [
        {
          x: moment.utc(setupEndTime).valueOf(),
          y: targetSpeed,
          groupId: 'sd',
          marker: {
            enabled: true,
            fillColor: '#00325C',
            radius: 0
          },
          noTooltip: true,
          dataLabels: {
            enabled: true,
            align: 'left',
            useHTML: true,
            formatter() {
              return (
                '<img src="/assets/images/phase-setup_indicator.png" class="flag-indicator"> </img>' +
                `<div class="down-time">${moment(this.x).format('HH:mm')}</div>`
              );
            }
          }
        },
        {
          x: moment.utc(setupEndTime).valueOf(),
          y: 0,
          noTooltip: true,
          groupId: 'sd',
          marker: {
            enabled: true,
            cursor: 'Pointer',
            symbol: 'url(/assets/images/phase-setup_circle.png)',
            radius: 10
          }
        }
      ],
      color: '#00325C',
      lineWidth: 3,
      lineColor: '#00325C',
      showInLegend: false,
      states: {
        inactive: {
          enabled: false,
          opacity: 1
        }
      }
    };

    const shiftSeries = {
      name: 'Shift Series',
      type: 'line',
      dashStyle: 'Solid',
      lineWidth: 2,
      showInLegend: false,
      color: '#393d37',
      lineColor: '#393d37',
      states: {
        inactive: {
          enabled: false,
          opacity: 1
        }
      },
      data: [],
      dataLabels: [
        {
          enabled: true,
          align: 'right',
          verticalAlign: 'bottom',
          format: '{point.shiftName}',
          style: {
            textShadow: 'none',
            fontSize: '14px'
          },
          rotation: -90,
          x: 10
        }
      ]
    };

    const productionOrderSeries = {
      name: 'ProductionOrderSeries',
      type: 'line',
      dashStyle: 'Solid',
      lineWidth: 2,
      showInLegend: false,
      color: '#393d37',
      lineColor: '#393d37',
      states: {
        inactive: {
          enabled: false,
          opacity: 1
        }
      },
      data: [],
      dataLabels: [
        {
          enabled: true,
          align: 'right',
          verticalAlign: 'bottom',
          format: '{point.productionOrderNumber}',
          style: {
            textShadow: 'none',
            fontSize: '14px'
          },
          rotation: -90,
          x: 10,
          y: 100
        }
      ]
    };
    const speed2Series = {
      name: this.speed2Series,
      data: [],
      type: 'line',
      lineColor: '#393d37',
      showInLegend: true,
      turboThreshold: 10000,
      step: 'left',
      color: '#393d37',
      inside: true,
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        enabled: isMarkerEnabled
      },
      yAxis: 1
    };

    const washSignalSeries = {
      name: this.washSeries,
      data: [],
      type: 'area',
      lineColor: '#334BFF',
      showInLegend: true,
      step: 'left',
      color: '#334BFF',
      fillColor: '#334BFF',
      turboThreshold: 10000,
      inside: true,
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        enabled: isMarkerEnabled
      },
      yAxis: 0
    };

    const downTimeSeries = {
      type: 'area',
      data: [],
      lineColor: '#00325C',
      showFirstLabel: false,
      showInLegend: false,
      enableMouseTracking: false,
      color: '#689576',
      fillColor: {
        pattern: {
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            strokeWidth: 1
          },
          width: 10,
          height: 10,
          opacity: 0.4,
          borderRadius: 0.9
        },
        borderColor: '#00325C'
      },
      fillOpacity: 0.8,
      marker: {
        enabled: true,
        fillColor: '#00325C',
        symbol: 'circle'
      },
      dataLabels: {
        enabled: true,
        backgroundColor: '#00325C',
        shape: 'flags',
        style: {
          color: 'white',
          fontWeight: 'bold',
          textShadow: 'none'
        },
        useHTML: true,
        formatter() {
          return `<span style="padding: 5px;">${moment(this.x).format('HH:mm')}</span>`;
        }
      },
      states: {
        inactive: {
          opacity: 1
        }
      }
    };

    const wasteAssignmentSeries = {
      color: 'red',
      name: 'Waste',
      yAxis: 0,
      type: 'line',
      data: [],
      lineWidth: 0,
      marker: {
        enabled: true,
        radius: 5,
        symbol: 'circle'
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      }
    };

    if (ChartType.fullView === chartMode) {
      let excludedSignalArray = excludedSignal.toLowerCase().split(',');
      excludedSignalArray = excludedSignalArray.map((data) => data.trim());
      allDataSignal = allDataSignal.filter((val) => !excludedSignalArray.includes(val.telemetryQuery.wellKnownSignal.trim().toLowerCase()));
    }
    const productionOrderData = result.reduce((rproductionResult, { externalProductionOrderId }) => {
      if (!rproductionResult.some((order) => order.externalProductionOrderId === externalProductionOrderId)) {
        rproductionResult.push({
          externalProductionOrderId,
          groupItem: result.filter((v) => v.externalProductionOrderId === externalProductionOrderId)
        });
      }
      return rproductionResult;
    }, []);

    productionOrderData.forEach((productionOrder) => {
      if (productionOrder.groupItem[0].externalProductionOrderId !== null) {
        productionOrderSeries.data.push({
          x: moment.utc(productionOrder.groupItem[0].periodStart).valueOf(),
          y: 0,
          productionOrderNumber: '',
          tooltipValue: `<br>
        ${this.productionOrderNumber}
          : <b>${productionOrder.groupItem[0].productionOrderData.productionOrderNumber}</b>
        <br> ${this.finishGoodArticleNumber}
          : <b>${productionOrder.groupItem[0].productionOrderData.finishedGoodArticleNumber}</b>
          <br> ${this.finishGoodArticleName} : <b>${productionOrder.groupItem[0].productionOrderData.finishedGoodArticleName} </b>
          <br> ${this.confiNameNumber}: <b> ${productionOrder.groupItem[0].productionOrderData.configurationNumber}
          ${productionOrder.groupItem[0].productionOrderData.configurationName} </b> <br> ${this.goodQuantityUnit} : <b>
          ${Math.max(productionOrder.groupItem[0].producedMaterialData.goodQuantity.value)} ${
            productionOrder.groupItem[0].producedMaterialData.goodQuantity.unitId
          } </b><br>
          ${this.wasteQuantMalQunt} :<b>  ${Math.max(productionOrder.groupItem[0].producedMaterialData.waste.value)} , ${Math.max(
            productionOrder.groupItem[0].producedMaterialData.maculature.value
          )} </b>
          `
        });
        productionOrderSeries.data.push({
          x: moment.utc(productionOrder.groupItem[0].periodStart).valueOf(),
          y: targetSpeed,
          productionOrderNumber: productionOrder.groupItem[0].productionOrderData.productionOrderNumber,
          tooltipValue: `<br>
        ${this.productionOrderNumber}
          : <b>${productionOrder.groupItem[0].productionOrderData.productionOrderNumber}</b>
        <br> ${this.finishGoodArticleNumber}
          : <b>${productionOrder.groupItem[0].productionOrderData.finishedGoodArticleNumber}</b>
          <br> ${this.finishGoodArticleName} : <b>${productionOrder.groupItem[0].productionOrderData.finishedGoodArticleName} </b>
          <br> ${this.confiNameNumber}: <b> ${productionOrder.groupItem[0].productionOrderData.configurationNumber}
          ${productionOrder.groupItem[0].productionOrderData.configurationName} </b> <br> ${this.goodQuantityUnit} : <b>
          ${Math.max(productionOrder.groupItem[0].producedMaterialData.goodQuantity.value)} ${
            productionOrder.groupItem[0].producedMaterialData.goodQuantity.unitId
          } </b><br>
          ${this.wasteQuantMalQunt} :<b>  ${Math.max(productionOrder.groupItem[0].producedMaterialData.waste.value)} , ${Math.max(
            productionOrder.groupItem[0].producedMaterialData.maculature.value
          )} </b>
          `
        });
        productionOrderSeries.data.push({
          x: moment.utc(productionOrder.groupItem[0].periodStart).valueOf(),
          y: null,
          productionOrderNumber: null,
          tooltipValue: ''
        });
      }
    });

    wasteReasons.forEach((wasteData) => {
      wasteAssignmentSeries.data.push({
        x: moment.utc(wasteData.timeStamp).valueOf(),
        y: 300, // waste series marker position on y-axis.
        tooltipValue: this.wasteTooltip(wasteData)
      });
    });

    result.forEach((status) => {
      if (status.workCenterStatus === 'Setup') {
        setupSeries.data.push({
          x: moment.utc(status.periodStart).valueOf(),
          y: 0,
          tooltipValue: `<div class='setuptooltip'> ● ${this.setupModeText} <br>  ${moment(status.periodStart).format('HH:mm:ss')}
          <br>${this.setupKind} : <b> ${status.externalSetupParameterKindId} ${status.setupParameterKindDescription}</b>
          ${this.getSetupWasteHTMLString(status)} </div>`
        });
        setupSeries.data.push({
          x: moment.utc(status.periodStart).valueOf(),
          y: setupDownLimit,
          tooltipValue: `<div class='setuptooltip'> ● ${this.setupModeText} <br>  ${moment(status.periodStart).format('HH:mm:ss')}
          <br>${this.setupKind} :<b> ${status.externalSetupParameterKindId} ${status.setupParameterKindDescription}</b>
          ${this.getSetupWasteHTMLString(status)} </div>`
        });

        setupSeries.data.push({
          x: status.periodEnd === null ? moment.utc().valueOf() : moment.utc(status.periodEnd).valueOf(),
          y: setupDownLimit,
          tooltipValue: `<div class='setuptooltip'> ● ${this.setupModeText} <br>  ${
            status.periodEnd === null ? moment().format('HH:mm:ss') : moment(status.periodEnd).format('HH:mm:ss')
          }<br>${this.setupKind} :<b> ${status.externalSetupParameterKindId} ${status.setupParameterKindDescription}</b>
            ${this.getSetupWasteHTMLString(status)} </div>`
        });
        setupSeries.data.push({
          x: status.periodEnd === null ? moment.utc().valueOf() : moment.utc(status.periodEnd).valueOf(),
          y: 0,
          tooltipValue: `<div class='setuptooltip'> ● ${this.setupModeText} <br> ${
            status.periodEnd === null ? moment().format('HH:mm:ss') : moment(status.periodEnd).format('HH:mm:ss')
          }
          <br>${this.setupKind} :<b> ${status.externalSetupParameterKindId} ${status.setupParameterKindDescription}</b>
          ${this.getSetupWasteHTMLString(status)} </div>`
        });
        setupSeries.data.push({
          x: status.periodEnd === null ? moment.utc().valueOf() : moment.utc(status.periodEnd).valueOf(),
          y: null,
          tooltipValue: ''
        });
      } else if (status.workCenterStatus === 'Interruption' || status.externalDowntimeLocalCode !== null) {
        downSeries.data.push({
          x: moment(status.periodStart).valueOf(),
          y: 0,
          externalDowntimeLocalCode: '',
          tooltipValue: `<div class='downtimetooltip'> ● ${this.downModeText} <br>${moment(status.periodStart).format('HH:mm:ss')}
          <br>${this.downtimeCodeText} :<b> ${status.externalDowntimeLocalCode}</b><br>${this.downtimeCommentText} :
          <b>${status.downtimeComment === null ? this.noComment : status.downtimeComment}</b>
          ${this.getDowntimeWasteHTMLString(status)} </div>`
        });

        downSeries.data.push({
          x: moment.utc(status.periodStart).valueOf(),
          y: setupDownLimit,
          externalDowntimeLocalCode: status.externalDowntimeLocalCode == null ? '' : status.externalDowntimeLocalCode,
          tooltipValue: `<div class='downtimetooltip'> ● ${this.downModeText} <br>${moment(status.periodStart).format('HH:mm:ss')}
          <br>${this.downtimeCodeText} : <b> ${status.externalDowntimeLocalCode}</b><br>${this.downtimeCommentText} :
          <b>${status.downtimeComment === null ? this.noComment : status.downtimeComment}</b>
          ${this.getDowntimeWasteHTMLString(status)} </div>`
        });

        downSeries.data.push({
          x: status.periodEnd === null ? moment().valueOf() : moment(status.periodEnd).valueOf(),
          y: setupDownLimit,
          externalDowntimeLocalCode: '',
          tooltipValue: `<div class='downtimetooltip'> ● ${this.downModeText} <br>${
            status.periodEnd === null ? moment().format('HH:mm:ss') : moment(status.periodEnd).format('HH:mm:ss')
          } <br>${this.downtimeCodeText} : <b> ${status.externalDowntimeLocalCode}</b><br>${this.downtimeCommentText} :
          <b>${status.downtimeComment === null ? this.noComment : status.downtimeComment}</b>
          ${this.getDowntimeWasteHTMLString(status)}</div>`
        });

        downSeries.data.push({
          x: status.periodEnd === null ? moment().valueOf() : moment(status.periodEnd).valueOf(),
          y: 0,
          externalDowntimeLocalCode: '',
          tooltipValue: `● ${this.targetSpeedText} <br>${
            status.periodEnd === null ? moment().format('HH:mm:ss') : moment(status.periodEnd).format('HH:mm:ss')
          } <br>${this.downtimeCodeText} : <b> ${status.externalDowntimeLocalCode}</b><br>${this.downtimeCommentText}
           : <b>${status.downtimeComment === null ? this.noComment : status.downtimeComment}</b>
          ${this.getDowntimeWasteHTMLString(status)}`
        });

        downSeries.data.push({
          x: status.periodEnd === null ? moment().valueOf() : moment(status.periodEnd).valueOf(),
          y: null,
          externalDowntimeLocalCode: '',
          tooltipValue: ''
        });
      }
      targetSeries.data.push({
        x: moment.utc(status.periodStart).valueOf(),
        y: status.targetSpeed.value,
        tooltipValue: `●  ${this.targetSpeedText} <br>${moment(status.periodStart).format('HH:mm:ss')} <br> ${status.targetSpeed.value}`
      });
      shiftSeries.data.push({
        x: moment.utc(status.shiftStartTime).valueOf(),
        y: 0,
        shiftName: '',
        tooltipValue: ` ● ${this.shift}: <b> ${status.shiftTypeName} </b> <br> ${this.externalShiftType}: <b>${
          status.externalShiftTypeId
        } </b> <br> ${this.date}: <b>${moment(status.shiftStartTime).format('MM/DD/YYYY')} </b>`
      });
      shiftSeries.data.push({
        x: moment.utc(status.shiftStartTime).valueOf(),
        y: targetSpeed,
        shiftName: status.shiftTypeName,
        tooltipValue: ` ● ${this.shift}: <b> ${status.shiftTypeName} </b> <br> ${this.externalShiftType}: <b>${
          status.externalShiftTypeId
        } </b> <br> ${this.date}: <b>${moment(status.shiftStartTime).format('MM/DD/YYYY')} </b>`
      });
      shiftSeries.data.push({
        x: moment.utc(status.shiftStartTime).valueOf(),
        y: null,
        shiftName: null,
        tooltipValue: ''
      });
    });

    plotBandSeries.data.push([moment.utc(setupStartTime).valueOf(), targetSpeed - plotBandHeight]);
    plotBandSeries.data.push([moment.utc(setupEndTime).valueOf(), targetSpeed - plotBandHeight]);

    plotBandSeries.data.push([moment.utc(setupEndTime).valueOf(), targetSpeed]);
    plotBandSeries.data.push([moment.utc(setupStartTime).valueOf(), targetSpeed]);

    this.fullviewSeriesdata.push(setupSeries);
    this.fullviewSeriesdata.push(downSeries);

    allDataSignal.forEach((signalData) => {
      if (signalData.telemetryQuery.wellKnownSignal === 'speed') {
        signalData.telemetryData.forEach((speedSignalData) => {
          speedSeries.data.push({
            x: moment(speedSignalData.timestamp).valueOf(),
            y: speedSignalData.value < 0 ? 0 : speedSignalData.value,
            tooltipValue: `● Speed <br>${moment(speedSignalData.timestamp).format('HH:mm:ss')} <br> ${speedSignalData.value.toFixed(0)}`
          });
        });
        this.availableSignalSeries.push(speedSeries);
      } else if (signalData.telemetryQuery.wellKnownSignal === 'machine_run') {
        if (signalData.telemetryData.length > 0) {
          signalData.telemetryData.forEach((runSignalData) => {
            if (runSignalData.value > 0) {
              runSeries.data.push({
                x: moment(runSignalData.timestamp).valueOf(),
                y: runLimit,
                tooltipValue: `● ${this.runModeText} <br>${moment(runSignalData.timestamp).format('HH:mm:ss')}`
              });
            }
          });
          this.availableSignalSeries.push(runSeries);
        }
      } else if (signalData.telemetryQuery.wellKnownSignal === 'speed2') {
        if (signalData.telemetryData.length > 0) {
          signalData.telemetryData.forEach((speed2Data) => {
            speed2Series.data.push({
              x: moment(speed2Data.timestamp).valueOf(),
              y: speed2Data.value < 0 ? 0 : speed2Data.value,
              tooltipValue: `● ${signalData.telemetryQuery.wellKnownSignal} <br>${moment(speed2Data.timestamp).format('HH:mm:ss')}`
            });
          });
          this.availableSignalSeries.push(speed2Series);
        }
      } else if (signalData.telemetryQuery.wellKnownSignal === 'wash') {
        if (signalData.telemetryData.length > 0) {
          signalData.telemetryData.forEach((washSignal) => {
            if (washSignal.value === 1) {
              washSignalSeries.data.push({
                x: moment(washSignal.timestamp).valueOf(),
                y: setupDownLimit,
                tooltipValue: `● ${signalData.telemetryQuery.wellKnownSignal} <br>${moment(washSignal.timestamp).format('HH:mm:ss')}`
              });
            } else {
              washSignalSeries.data.push({
                x: moment(washSignal.timestamp).valueOf(),
                y: 0,
                tooltipValue: `● ${signalData.telemetryQuery.wellKnownSignal} <br>${moment(washSignal.timestamp).format('HH:mm:ss')}`
              });
            }
          });
          this.availableSignalSeries.push(washSignalSeries);
        }
      } else if (signalData.telemetryQuery.wellKnownSignal !== 'counter_qty') {
        const newSeries = {
          name: this.getNewSeriesName(signalData.telemetryQuery.wellKnownSignal),
          data: [],
          type: 'line',
          showInLegend: true,
          lineColor: '#393d37',
          step: 'left',
          color: '#393d37',
          turboThreshold: 10000,
          inside: true,
          states: {
            inactive: {
              opacity: 1
            }
          }
        };
        if (signalData.telemetryData.length > 0) {
          signalData.telemetryData.forEach((newSeriesData) => {
            newSeries.data.push({
              x: moment(newSeriesData.timestamp).valueOf(),
              y: newSeriesData.value < 0 ? 0 : newSeriesData.value,
              tooltipValue: `● ${signalData.telemetryQuery.wellKnownSignal} <br>${moment(newSeriesData.timestamp).format('HH:mm:ss')}`
            });
          });
          this.availableSignalSeries.push(newSeries);
        }
      }
    });
    this.availableSignalSeries = this.availableSignalSeries.sort((a, b) => (a.name > b.name ? 1 : -1));
    this.fullviewSeriesdata = this.fullviewSeriesdata.concat(this.availableSignalSeries);
    this.fullviewSeriesdata.push(shiftSeries);
    this.fullviewSeriesdata.push(productionOrderSeries);
    this.fullviewSeriesdata.push(targetSeries);
    this.fullviewSeriesdata.push(wasteAssignmentSeries);

    if (ChartType.donwtimeTask && downtimeResult !== null && downtimeResult !== undefined) {
      downTimeSeries.data.push([moment.utc(downtimeResult.downtime.startTime).valueOf(), targetSpeed]);
      downTimeSeries.data.push([
        downtimeResult.downtime.endTime === null ? moment.utc().valueOf() : moment.utc(downtimeResult.downtime.endTime).valueOf(),
        targetSpeed
      ]);
    }
    switch (chartMode) {
      case ChartType.fullView:
        return this.fullviewSeriesdata;
      case ChartType.donwtimeTask:
        return [setupSeries, runSeries, speedSeries, downSeries, downTimeSeries];
      case ChartType.finishPhase:
        return [setupSeries, runSeries, speedSeries, downSeries];
      case ChartType.editSetup:
        return [setupSeries, runSeries, downSeries, speedSeries, plotBandSeries, setupStartSeries, setupEndSeries];
      case ChartType.splitDowntime:
        return [setupSeries, runSeries, downSeries, speedSeries, splitDownTimeEndSeries];
      case ChartType.editDowntime:
        return [setupSeries, runSeries, downSeries, speedSeries, plotBandSeries, setupStartSeries, editDowntimeEndSeries];
    }
  }

  public newSeriesNames = (): Observable<any> => this.translate.get(['MACHINE_CHART.PRODUCTION_CHANGE_SERIES']);

  private wasteTooltip(wasteAssignment: WasteAssignmentInTime): string {
    return (
      `${this.translate.instant('MACHINE_CHART.WASTE_ASSIGNMENT')}<br> ${moment(wasteAssignment.timeStamp).format('HH:mm:ss')}
      ${this.wasteReasons(wasteAssignment?.wastesPerReason)}
      <br>` + `${this.getMaculatureText(wasteAssignment)}`
    );
  }

  private getMaculatureText(wasteAssignment: WasteAssignmentInTime) {
    if (wasteAssignment?.maculature?.value > 0) {
      return `${this.translate.instant('MACHINE_CHART.MACULATURE')}: <b>
      ${wasteAssignment.maculature.value} ${wasteAssignment.maculature.unitId} </b>`;
    } else {
      return '';
    }
  }

  private wasteReasons(wastes: WastePerReason[]): string {
    let text = '';
    wastes.forEach((element) => {
      text += `<br> ${this.translate.instant('MACHINE_CHART.WASTE')}: <b>
      ${element.waste.value} ${element.waste.unitId} </b> <br> ${this.translate.instant('MACHINE_CHART.WASTE_REASON')}: <b>
      ${element.externalWasteReasonCode} ${element.description} </b>`;
    });

    return text;
  }

  public getNewSeriesName(seriesName: string): string {
    this.newSeriesNames().subscribe((result) => {
      Object.keys(result).map((key) => {
        switch (key) {
          case 'MACHINE_CHART.PRODUCTION_CHANGE_SERIES':
            this.productionChangeSeries = result[key];
            break;
          case 'MACHINE_CHART.NONE':
            this.productionChangeSeries = result[key];
            break;
        }
      });
      switch (seriesName) {
        case 'production_change':
          this.newSeriesName = this.productionChangeSeries;
          break;
        case 'none':
          this.newSeriesName = this.productionChangeSeries;
          break;
        default:
          this.newSeriesName = seriesName;
          break;
      }
    });
    return this.newSeriesName ?? seriesName;
  }

  public getSetupWasteHTMLString(status: ProductionPeriodDetailData): string {
    let setupWaste = '';
    if (status.setupPhaseWastesPerReason && status.setupPhaseWastesPerReason.length > 0) {
      status.setupPhaseWastesPerReason.map((item) => {
        if (item.waste && item.waste?.value > 0) {
          setupWaste += `<br>${this.wasteQuantity} :<b> ${item.waste?.value} ${item.waste?.unitId}</b>`;
        }
        if (item.externalWasteReasonCode && item.externalWasteReasonCode.trim().length > 0) {
          setupWaste += `<br>${this.wasteReason} :<b> ${item.externalWasteReasonCode} ${item.description}</b>`;
        }
      });
    }
    if (status.setupPhaseMaculature && status.setupPhaseMaculature?.value > 0) {
      setupWaste += `<br>${this.maculatureQuantity} :<b> ${status.setupPhaseMaculature?.value} ${status.setupPhaseMaculature?.unitId}</b>`;
    }
    return setupWaste;
  }

  public getDowntimeWasteHTMLString(status: ProductionPeriodDetailData): string {
    let downTimeWaste = '';
    if (status.downtimeWastesPerReason && status.downtimeWastesPerReason.length > 0) {
      status.downtimeWastesPerReason?.map((item) => {
        if (item.waste && item.waste?.value > 0) {
          downTimeWaste += `<br>${this.wasteQuantity} :<b> ${item.waste?.value} ${item.waste?.unitId}</b>`;
        }
        if (item.externalWasteReasonCode && item.externalWasteReasonCode.trim().length > 0) {
          downTimeWaste += `<br>${this.wasteReason} :<b> ${item.externalWasteReasonCode} ${item.description}</b>`;
        }
      });
    }
    if (status.downtimeMaculature && status.downtimeMaculature?.value > 0) {
      downTimeWaste += `<br>${this.maculatureQuantity} :<b> ${status.downtimeMaculature?.value} ${status.downtimeMaculature?.unitId}</b>`;
    }
    return downTimeWaste;
  }
}
