import { Component, EventEmitter, Input, Output } from '@angular/core';

interface FileSelectEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() public buttonLabel: string;
  @Input() public acceptedFileType: string;
  @Input() public url: string;
  @Input() public multiple? = false;
  @Input() public disable? = false;
  @Input() public maxFileSize? = '10000000'; // it's 10mb (given default value is in bytes).
  @Input() public mode? = 'basic';
  @Input() public autoUpload? = true;
  @Input() public showCancelButton = false;
  @Input() public showUploadButton = false;
  @Output() public selectFile? = new EventEmitter<any>();
  @Output() public removeFile? = new EventEmitter<any>();

  constructor() {}

  public onFileSelect($event: FileSelectEvent) {
    this.selectFile.emit($event);
  }

  public onFileRemove($event: FileSelectEvent) {
    this.removeFile.emit($event);
  }
}
