import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ActiveOrderData } from './active-order-data.model';

function initialState(): Partial<ActiveOrderData> {
  return {
    wasteAllocations: [],
    inputQuantityPool: { value: 0, unitId: null },
    outputQuantityPool: { value: 0, unitId: null },
    setupPhaseStatus: null,
    headerKpi: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'active-order-data' })
export class ActiveOrderDataStore extends Store<ActiveOrderData> {
  constructor() {
    super(initialState());
  }
}
