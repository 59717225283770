/* tslint:disable */
type LabelPrintResult = 'None' | 'Open' | 'Done' | 'Failed';
module LabelPrintResult {
  export const NONE: LabelPrintResult = 'None';
  export const OPEN: LabelPrintResult = 'Open';
  export const DONE: LabelPrintResult = 'Done';
  export const FAILED: LabelPrintResult = 'Failed';
  export function values(): LabelPrintResult[] {
    return [NONE, OPEN, DONE, FAILED];
  }
}

export { LabelPrintResult };
