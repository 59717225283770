<div class="cursor-clickable" (click)="openLogMessagesModal()">
  <div
    *ngIf="currentMessage; else noMessage"
    class="info-log"
    [ngClass]="{
      'info-log--info': currentMessageType === MESSAGE_TYPE.Info,
      'info-log--success': currentMessageType === MESSAGE_TYPE.Success,
      'info-log--warning': currentMessageType === MESSAGE_TYPE.Warning,
      'info-log--error': currentMessageType === MESSAGE_TYPE.Error
    }"
  >
    <i
      class="info-log__icon"
      [ngClass]="{
        'icon-info-outline': currentMessageType === MESSAGE_TYPE.Info,
        'icon-feedback-outline': currentMessageType === MESSAGE_TYPE.Success,
        'icon-warning-outline': currentMessageType === MESSAGE_TYPE.Warning,
        'icon-error-outline': currentMessageType === MESSAGE_TYPE.Error
      }"
    ></i>
    <div class="info-log__message-type">{{ currentMessageType | translate }}</div>
    <div class="info-log__message-time">({{ currentMessageTime }})</div>
    <div class="info-log__message">{{ currentMessage | translate: currentMessageParameters }}</div>
  </div>
  <ng-template #noMessage>
    <div class="info-log">{{ 'INFO_LOG.NO_MESSAGES' | translate }}</div>
  </ng-template>
</div>
