import { Component, OnDestroy, OnInit } from '@angular/core';
import { NextSetupSubphaseCommandService, PreviousSetupSubphaseCommandService } from '@app/modules/setup-phase/commands';
import { SetupPhaseService } from '@app/modules/setup-phase/services/setup-phase/setup-phase.service';
import { SetupFooterService } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import { PatternDetail, RunSubPhaseType } from 'chronos-core-client';
import { Subscription, combineLatest } from 'rxjs';
import { QaHistoryDsService, PatternInfoDsService, ActiveOrderDsService } from '@app/core/data-services';

@Component({
  selector: 'app-setup-pattern-info',
  templateUrl: './setup-pattern-info.component.html',
  styleUrls: ['./setup-pattern-info.component.scss']
})
export class PatternInfoComponent implements OnInit, OnDestroy {
  public patternDetail: PatternDetail;
  public backCommandService: PreviousSetupSubphaseCommandService;
  public nextCommandService: NextSetupSubphaseCommandService;

  private subscriptions = new Subscription();
  public isPaternInfoDataLoaded = false;

  constructor(
    private patternInfoDsService: PatternInfoDsService,
    private activeOrderDsService: ActiveOrderDsService,
    private qaHistoryDsService: QaHistoryDsService,
    private setupPhaseService: SetupPhaseService,
    private setupFooterService: SetupFooterService
  ) {}

  public ngOnInit(): void {
    const productionOrderId = this.activeOrderDsService.getActiveOrderId();
    this.subscriptions.add(
      this.patternInfoDsService.getProductionOrderPatternInfo(productionOrderId).subscribe((selectedNcModel) => {
        this.patternDetail = selectedNcModel;
        this.isPaternInfoDataLoaded = true;
      })
    );
    this.setCommands();
    this.subscriptions.add(
      combineLatest([
        this.qaHistoryDsService.isQaHistoryAvailableForSetup(),
        this.qaHistoryDsService.isLineClearanceEnableForSetup()
      ]).subscribe(([isQaHistoryAvailable, isLineClearanceEnable]) => {
        this.updateBackCommand({ isQaHistoryAvailable, isLineClearanceAvailable: isLineClearanceEnable });
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setCommands(): void {
    this.backCommandService = new PreviousSetupSubphaseCommandService(this.setupFooterService);
    this.nextCommandService = new NextSetupSubphaseCommandService(this.setupFooterService);
    this.nextCommandService.setNavigationParams(RunSubPhaseType.SETUP_PARAMETER, nav.routes.setupParameters);
    this.setupPhaseService.setSetupCommands(this.backCommandService, this.nextCommandService);
  }

  private updateBackCommand({
    isQaHistoryAvailable,
    isLineClearanceAvailable
  }: {
    isQaHistoryAvailable: boolean;
    isLineClearanceAvailable: boolean;
  }): void {
    if (isQaHistoryAvailable) {
      this.backCommandService.setNavigationParams(nav.routes.setupQaHistory);
      this.backCommandService.enabled = true;
    } else if (isLineClearanceAvailable) {
      this.backCommandService.setNavigationParams(nav.routes.setupLineClearance);
      this.backCommandService.enabled = true;
    } else {
      this.backCommandService.enabled = false;
    }
  }
}
