<section *ngIf="orderList$ | async as orderListData" class="order-list page-padding">
  <header class="order-list__header">
    <h1 class="page-header" [title]="orderListData.filteredList.length">{{ 'ORDER_LIST.TITLE' | translate }}</h1>
    <section class="order-list__filters">
      <div class="p-grid p-align-center">
        <div class="p-col-2">
          <p-checkbox
            binary="true"
            [(ngModel)]="isShowingAll"
            (onChange)="showOrders(isShowingAll)"
            [label]="'ORDER_LIST.SHOW_ALL' | translate"
          ></p-checkbox>
        </div>
        <div class="p-col">
          <lib-dropdown
            [options]="shiftFilters$ | async"
            [(ngModel)]="selectedShift"
            [inlineStyle]="{ width: '300px' }"
            (ngModelChange)="filterShift()"
          ></lib-dropdown>
        </div>
        <div class="p-col">
          <p-checkbox binary="true" [(ngModel)]="isExpandCollapseAll" [label]="'ORDER_LIST.EXPAND_COLLAPSE_ALL' | translate"></p-checkbox>
        </div>
        <div class="p-col">
          <lib-loading-indicator *ngIf="isLoading$ | async"></lib-loading-indicator>
        </div>
        <div class="p-col-3 p-col-align-right">
          <app-search-bar
            (searchBarValueChanged)="searchFilterOrderList($event)"
            [searchBarPlaceholder]="'ORDER_LIST.SEARCH_BAR_PLACEHOLDER' | translate"
            [selectInputOnEnter]="true"
          ></app-search-bar>
        </div>
      </div>
    </section>
  </header>
  <ng-container *ngFor="let orderItem of orderListData.filteredList; trackBy: trackByFunction">
    <app-order-item
      [activeOrderExists]="orderListData.hasActiveOrder"
      [orderItem]="orderItem"
      [isShowingAllOrderItems]="isShowingAll"
      [manualMachineMode]="manualMachineMode"
      [isExpandCollapseAll]="isExpandCollapseAll"
      [automaticPeriodSelectionOnProductionOrderStart]="automaticPeriodSelectionOnProductionOrderStart"
    ></app-order-item>
  </ng-container>
</section>
