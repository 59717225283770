import { Injectable } from '@angular/core';
import { PrimaryMaterialMounted } from 'chronos-core-client';
import { InputPalletsStore } from './input-pallets.store';

@Injectable()
export class InputPalletsService {
  constructor(private inputPalletsStore: InputPalletsStore) {}

  public setInputPallets(pallets: PrimaryMaterialMounted[]): void {
    this.inputPalletsStore.set(pallets);
  }
}
