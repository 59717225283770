<section class="overview-modal page-padding chronos-dialog--size-lg">
  <app-order-item content="mainInfoOnly" [shiftReportItem]="shiftReportItem"></app-order-item>
  <div class="overview-modal__kpis p-grid">
    <div class="p-col">
      <app-kpi-column
        [kpis]="kpis[KPI_CATEGORY.QUANTITY]"
        [title]="formatColumnTitle(KPI_CATEGORY.QUANTITY) | translate"
        [kpiColumn]="KPI_CATEGORY.QUANTITY"
      ></app-kpi-column>
    </div>
    <div class="p-col">
      <app-kpi-column
        [kpis]="kpis[KPI_CATEGORY.TIME]"
        [title]="formatColumnTitle(KPI_CATEGORY.TIME) | translate"
        [kpiColumn]="KPI_CATEGORY.TIME"
      ></app-kpi-column>
    </div>
    <div class="p-col">
      <app-kpi-column
        [kpis]="kpis[KPI_CATEGORY.SPEED]"
        [title]="formatColumnTitle(KPI_CATEGORY.SPEED) | translate"
        [kpiColumn]="KPI_CATEGORY.SPEED"
      ></app-kpi-column>
    </div>
    <div class="p-col">
      <app-kpi-column
        [kpis]="kpis[KPI_CATEGORY.PROJECT]"
        [title]="formatColumnTitle(KPI_CATEGORY.PROJECT) | translate"
        [kpiColumn]="KPI_CATEGORY.PROJECT"
      ></app-kpi-column>
    </div>
  </div>

  <lib-modal-footer [submitLabel]="'SHIFT_REPORT.CLOSE'" [showDeclineButton]="false" (submitClicked)="closeModal()"></lib-modal-footer>
</section>
