import { EmployeeRegistrationDetailsService } from '@app/modules/employee-registration/services';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { EmployeeRegistrationComponent } from './components/employee-registration/employee-registration.component';
import { EmployeeRegistrationHistoryComponent } from './components/history/employee-registration-history/employee-registration-history.component';
import { EmployeeRegistrationLoginComponent } from './components/login/employee-registration-login/employee-registration-login.component';
import { LoggedinEmployeeTableComponent } from './components/loggedin-employee-table/loggedin-employee-table.component';
import { EmployeeTableComponent } from './components/employee-table/employee-table.component';
import { EmployeeRegistrationHistoryTableComponent } from './components/employee-registration-history-table/employee-registration-history-table.component';
import { UtcToLocalPipe } from 'chronos-shared';
import { LoginWindowComponent } from './components/login-window/login-window.component';
@NgModule({
  declarations: [
    EmployeeRegistrationComponent,
    EmployeeRegistrationHistoryComponent,
    EmployeeRegistrationLoginComponent,
    LoggedinEmployeeTableComponent,
    EmployeeTableComponent,
    EmployeeRegistrationHistoryTableComponent,
    LoginWindowComponent
  ],
  imports: [SharedModule],
  providers: [EmployeeRegistrationDetailsService, UtcToLocalPipe]
})
export class EmployeeRegistrationModule {}
