import { ChangeDetectionStrategy, Component, Input, OnInit, Host, SkipSelf } from '@angular/core';
import { PhaseNavigationComponent } from '../phase-navigation/phase-navigation.component';

@Component({
  selector: 'lib-phase-navigation-item',
  templateUrl: './phase-navigation-item.component.html',
  styleUrls: ['./phase-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhaseNavigationItemComponent implements OnInit {
  @Input() public link = '';
  @Input() public title = '';
  @Input() public icon = '';
  @Input() public isActive = false;
  @Input() public isPhaseActive = false;
  @Input() public hasSubItems = false;
  @Input() public isReadOnly = false;

  constructor(
    @Host()
    @SkipSelf()
    private phaseNavigationComponent: PhaseNavigationComponent
  ) {}

  public ngOnInit(): void {
    this.isReadOnly = this.phaseNavigationComponent.isReadOnly || this.isReadOnly;
  }
}
