/* tslint:disable */
type OuterLabelType = 'Outer' | 'Core';
module OuterLabelType {
  export const OUTER: OuterLabelType = 'Outer';
  export const CORE: OuterLabelType = 'Core';
  export function values(): OuterLabelType[] {
    return [OUTER, CORE];
  }
}

export { OuterLabelType };
