import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-mini';

@Pipe({
  name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {
  public transform(value: string | Date): Date {
    if (!value) {
      return null;
    }

    return moment.utc(value).local().toDate();
  }
}
