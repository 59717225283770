import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthorizeService } from './authorize.service';
import { tap } from 'rxjs/operators';
import { applicationPaths, queryParameterNames } from './api-authorization.constants';

export const authorizeGuard = (_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authorize: AuthorizeService = inject(AuthorizeService);
  const router: Router = inject(Router);
  return authorize.isAuthenticated().pipe(
    tap((isAuthenticated: boolean) => {
      if (!isAuthenticated) {
        router.navigate(applicationPaths.LoginPathComponents, {
          queryParams: {
            [queryParameterNames.ReturnUrl]: state.url
          }
        });
      }
    })
  );
};
