import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValuePropertyBag, KPI, KPIValueBag } from '../../models/speed-monitor.model';
import { SpeedMonitorService } from '../../services/speed-monitor.service';

@Component({
  selector: 'lib-setup-mode',
  templateUrl: './setup-mode.component.html',
  styleUrls: ['./setup-mode.component.scss']
})
export class SetupModeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public valuePropertyBag: ValuePropertyBag;
  @Input() public kpiValueBag: KPIValueBag;
  @Input() public machineData: any;
  @Input() public deltaTime: number;
  @Input() public state: string;
  public lastDisplayTime: number;
  public timeoutHandlerOne: any;
  public kpis: KPI[];
  public currentKpiIndex = 0;
  public currentKpi: KPI;
  public durationHandler: any;

  constructor(private speedMonitorService: SpeedMonitorService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.kpiValueBag) {
      this.kpis = changes.kpiValueBag.currentValue.kpiValue;
      if (this.kpis && this.kpis.length > 0) {
        this.setKpiValues();
      }
    }
    if (changes.state) {
      clearTimeout(this.durationHandler);
    }
  }

  public ngOnInit() {
    if (history.state.flag) {
      this.kpis = history.state.kpiList;
    }
    this.kpis = this.kpiValueBag.kpiValue;
    if (this.kpis && this.kpis.length > 0) {
      this.setKpiValues();
    }
    this.updateDuration(this.deltaTime);
  }

  public updateDuration(deltaTime: number): void {
    this.valuePropertyBag.duration = this.speedMonitorService.getDuration(this.machineData.startTime, deltaTime);
    this.durationHandler = setTimeout(() => {
      this.updateDuration(deltaTime);
    }, 1000);
  }

  public setKpiValues() {
    this.currentKpi = this.kpis[this.currentKpiIndex];
    if (this.currentKpi.value) {
      if (this.currentKpi.value % 1 !== 0) {
        this.currentKpi.value = Number(this.currentKpi.value.toFixed(2));
      }
    }
  }
  public ngOnDestroy() {
    clearTimeout(this.durationHandler);
  }
}
