import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { DummyContainerDirective, EmptyContainerDirective, ItemContainerDirective } from '@app/modules/mounting/directives';
import { PrimaryMaterialMounted } from 'chronos-core-client';

@Component({
  selector: 'app-consumption-pallet-list',
  templateUrl: './consumption-pallet-list.component.html',
  styleUrls: ['./consumption-pallet-list.component.scss']
})
export class ConsumptionPalletListComponent {
  @Input() public consumedContainers: PrimaryMaterialMounted[];

  @ContentChild(ItemContainerDirective, { read: TemplateRef }) public itemTemplate;
  @ContentChild(DummyContainerDirective, { read: TemplateRef }) public dummyTemplate;
  @ContentChild(EmptyContainerDirective, { read: TemplateRef }) public emptyTemplate;

  public trackByFunction(index: number, consumedContainer: PrimaryMaterialMounted): number {
    return consumedContainer.mountedMaterialId;
  }
}
