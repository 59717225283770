import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-remark-container',
  templateUrl: './remark-container.component.html',
  styleUrls: ['./remark-container.component.scss']
})
export class RemarkContainerComponent {
  @Input() public sectionName: string;
}
