<p-carousel
  *ngIf="areDocumentsAvailable(); else noImagesAvailable"
  [value]="defectiveItems"
  (onPage)="updateSelectedDocument($event)"
  [contentClass]="isFullscreen ? 'defective-item-carousel-content' : ''"
>
  <ng-template let-document pTemplate="item">
    <app-nc-defective-item
      [documentItem]="document"
      [isActive]="isDocumentsSelected(document)"
      (imageClicked)="openDefectiveItemsModal()"
      [isFullscreen]="isFullscreen"
      [isImageClickable]="isImageClickable"
    ></app-nc-defective-item>
  </ng-template>
</p-carousel>

<ng-template #noImagesAvailable>
  <app-nc-defective-item [isFullscreen]="isFullscreen"></app-nc-defective-item>
</ng-template>
