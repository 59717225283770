import { Component, EventEmitter, forwardRef, Input, Output, Provider, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarDate } from '../../../models';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CalendarComponent),
  multi: true
};

@Component({
  selector: 'lib-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class CalendarComponent implements ControlValueAccessor {
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public showTime = true;
  @Input() public inputStyle: any;
  @Input() public styleClass = '';
  @Input() public dayTemplate: TemplateRef<any>;
  @Input() public selectOtherMonths = false;
  @Input() public keepInvalid = false;
  @Input() public dateFormat = 'dd.mm.yy';
  @Input() public placeholder = 'Date';
  @Input() public disabled = false;

  @Output() public monthChanged = new EventEmitter<CalendarDate>();
  @Output() public inputChanged = new EventEmitter<any>();

  public selectedValue: Date;
  private propagateChange: (value: Date) => void;
  private propagateTouched: () => void;

  public writeValue(value: Date): void {
    this.selectedValue = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public onModelChange(): void {
    this.propagateChange(this.selectedValue);
  }

  public monthChangeEmit(date: CalendarDate): void {
    this.monthChanged.emit(date);
  }

  public inputChangeEmit(value: any): void {
    this.inputChanged.emit(value);
  }

  public onInputBlur(): void {
    if (this.propagateTouched) {
      this.propagateTouched();
    }
  }
}
