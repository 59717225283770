import { ArticleDescription, PrimaryMaterialMounted } from 'chronos-core-client';

export interface MountedMaterial extends PrimaryMaterialMounted {
  id: number;
  internalBatchId: string;
  externalBatchId: string;
  isLabelPrinted: boolean;
  article: ArticleDescription;
  containerType: 'container' | 'info' | 'placeholder';
  materialBlockId: number;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface ConsumptionListSorting {
  palletSequence: SortDirection;
}

export interface ConsumptionListFilters {
  showOnlyDummy: boolean;
}
