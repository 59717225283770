import { Component, Output, EventEmitter, Input, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements AfterViewInit {
  @Input() public searchBarPlaceholder = '';
  @Input() public selectInputOnEnter = false;

  @Output() public searchBarValueChanged = new EventEmitter<string>();

  @ViewChild('searchInput') public searchInput: ElementRef;

  public containerSearchFilter: string;

  public ngAfterViewInit(): void {
    if (this.selectInputOnEnter) {
      this.searchInput.nativeElement.focus();
    }
  }

  @HostListener('window:keydown.enter')
  public focusToInputField() {
    if (this.selectInputOnEnter && this.isFocusAvailable()) {
      this.searchInput.nativeElement.focus();
    }
  }

  private isFocusAvailable(): boolean {
    const modal = document.querySelector('p-dynamicdialog');

    if (modal) {
      return false;
    }

    return document.activeElement?.tagName !== 'INPUT' && document.activeElement?.tagName !== 'SELECT';
  }

  public clearFilter(): void {
    this.containerSearchFilter = '';
    this.filterOutputContainersList();
  }

  public filterOutputContainersList(): void {
    this.searchBarValueChanged.emit(this.containerSearchFilter);
  }
}
