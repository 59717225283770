import { concat, Observable, of } from 'rxjs';
import {
  AddProductionOrderWasteAssignmentToOuterEntry,
  ChangeOuterQuantityEntry,
  ChangeProductionOrderOuterInfoEntry,
  DeleteProductionOrderWasteAssignmentFromOuterEntry,
  Outer,
  OuterLabelType,
  OuterPrintInfo,
  PrintLabelForOuterEntry,
  ProductionOrderService,
  WebSocketClientService
} from 'chronos-core-client';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { HubUrlsService } from '@app/core/utils';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable({ providedIn: 'root' })
export class OutputUnitDsService {
  constructor(
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private productionOrderService: ProductionOrderService
  ) {}

  public getOutputUnitPrintLabelInfo(printInfo: ProductionOrderService.GetPrintInfoParams): Observable<OuterPrintInfo> {
    return this.productionOrderService.getPrintInfo(printInfo);
  }

  public addWasteAssignmentToOuter(
    productionOrderId: number,
    waste: Quantity,
    maculature: Quantity,
    wasteReasonId?: number
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: AddProductionOrderWasteAssignmentToOuterEntry = {
      maculature,
      waste,
      wasteReasonId,
      workCenterId
    };
    const params: ProductionOrderService.AddProductionOrderWasteAssignmentToOuterParams = {
      productionOrderId,
      entry
    };

    return this.productionOrderService.addProductionOrderWasteAssignmentToOuter(params);
  }

  public deleteWasteAssignmentFromOuter(productionOrderId: number, wasteAssignmentId: number): Observable<null> {
    const workCenterId: number = this.activeWorkCenterService.getWorkCenterId();
    const entry: DeleteProductionOrderWasteAssignmentFromOuterEntry = {
      workCenterId,
      wasteAssignmentId
    };
    const params: ProductionOrderService.DeleteProductionOrderWasteAssignmentFromOuterParams = {
      productionOrderId,
      entry
    };

    return this.productionOrderService.deleteProductionOrderWasteAssignmentFromOuter(params);
  }

  public printOutputUnitLabel(productionOrderId: number, request: PrintLabelForOuterEntry): Observable<null> {
    const params: ProductionOrderService.PrintLabelForOuterParams = {
      productionOrderId,
      entry: request
    };

    return this.productionOrderService.printLabelForOuter(params);
  }

  public getOutputUnitWithNotifications(productionOrderId: number): Observable<Outer> {
    return this.getOutputUnit({ productionOrderId, outerLabelType: OuterLabelType.OUTER }).pipe(
      switchMap((outer) => concat(of(outer), this.getOutputUnitNotifications(productionOrderId)))
    );
  }

  public getOutputUnit(printInfo: ProductionOrderService.GetPrintInfoParams): Observable<Outer> {
    return this.productionOrderService.getOuter(printInfo);
  }

  private getOutputUnitNotifications(productionOrderId: number): Observable<Outer> {
    return this.webSocketClientService.getNotificationsForTopic<Outer>(this.hubUrlsService.getOutputUnit(productionOrderId));
  }

  public changeQuantityOfOutputUnit(productionOrderId: number, newQuantity: number): Observable<null> {
    const entry: ChangeOuterQuantityEntry = {
      newOuterQuantity: newQuantity,
      workCenterId: this.activeWorkCenterService.getWorkCenterId()
    };
    const params: ProductionOrderService.ChangeOuterQuantityParams = {
      productionOrderId,
      entry
    };
    return this.productionOrderService.changeOuterQuantity(params);
  }

  public saveQuantityPerOuterAndCopies(
    productionOrderId: number,
    outerLabelType: OuterLabelType,
    quantityPerOuter: number,
    numberOfCopies?: number
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ChangeProductionOrderOuterInfoEntry = {
      quantityPerOuter,
      outerLabelType,
      numberCopies: numberOfCopies,
      workCenterId
    };
    const params: ProductionOrderService.ChangeProductionOrderOuterInfoParams = {
      entry,
      productionOrderId
    };
    return this.productionOrderService.changeProductionOrderOuterInfo(params);
  }
}
