import { Injectable } from '@angular/core';
import { DowntimeFlatSelect } from '@app/shared/modals/task-assign-modal/models';
import { DowntimeReason, DowntimeSection } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class TaskAssignModalService {
  public getDowntimeFlatSelect(categoryGroups: DowntimeSection[], reason: DowntimeReason): DowntimeFlatSelect {
    let categoryGroupId = null;
    let categoryId = null;

    categoryGroups.find((categoryGroup) => {
      const selectedCategory = categoryGroup.categories.find((category) => {
        const selectedReason = category.reasons.find((downtimeReason) => downtimeReason.id === reason?.id);
        if (selectedReason) {
          categoryId = category.id;
          return category;
        }
      });
      if (selectedCategory) {
        categoryGroupId = categoryGroup.id;
        return categoryGroup;
      }
    });

    return {
      categoryGroupId,
      categoryId
    };
  }
}
