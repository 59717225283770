<lib-creating-dummy-form
  [reasonsOptions]="reasonsOptions$ | async"
  [dummy]="dummyTool"
  [articleOptions]="this.articleOptions"
  [showQuantity]="showQuantity"
  (createNewDummyEvent)="mountDummyTool($event)"
  (updateDummyInfoEvent)="updateDummyTool($event)"
  [inputPlaceholder]="'MOUNTING.PLEASE_ENTER_ID' | translate"
  [mountedLabel]="'MOUNTING.MOUNTED' | translate"
  [reasonsLabel]="'MOUNTING.REASON' | translate"
  [articleLabel]="'MOUNTING.ARTICLE' | translate"
  [infoLabel]="'MOUNTING.INFO' | translate"
  [mountButtonLabel]="'MOUNTING.MOUNT'"
  [updateButtonLabel]="'MOUNTING.SAVE'"
  [reasonsDefaultValue]="'DROPDOWN_DEFAULT_LABEL' | translate"
  [articleDefaultValue]="'DROPDOWN_DEFAULT_LABEL' | translate"
  [loadingTopic]="LOADING_TOPIC"
  (dummySsccScanEvent)="updateDummyQuantity($event)"
></lib-creating-dummy-form>
