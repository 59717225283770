import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TaskService } from '@app/core/global-state';
import { nav } from '@app/shared/utils';
import { KpiActionBase } from './kpi-action-base';

@Injectable({
  providedIn: 'root'
})
export class KpiActionSetupTimeService extends KpiActionBase {
  constructor(private taskService: TaskService, private router: Router) {
    super();
  }

  public execute(): void {
    this.router.navigate([nav.routes.wasteAssignment]);
  }
}
