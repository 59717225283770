/* tslint:disable */
type KpiStatus = 'Ok' | 'Warning';
module KpiStatus {
  export const OK: KpiStatus = 'Ok';
  export const WARNING: KpiStatus = 'Warning';
  export function values(): KpiStatus[] {
    return [OK, WARNING];
  }
}

export { KpiStatus };
