import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DocumentsModule } from '@app/modules/documents/documents.module';
import { FinishPhaseModule } from '@app/modules/finish-phase/finish-phase.module';
import { OrdersModule } from '@app/modules/orders/orders.module';
import { MachineChartModule } from '@app/modules/machine-chart/machine-chart.module';
import { MountingModule } from '@app/modules/mounting/mounting.module';
import { RunPhaseModule } from '@app/modules/run-phase/run-phase.module';
import { SetupPhaseModule } from '@app/modules/setup-phase/setup-phase.module';
import { ShiftReportModule } from '@app/modules/shift-report/shift-report.module';
import { ShutdownModule } from '@app/modules/shutdown/shutdown.module';
import { SpeedMonitorModule } from '@app/modules/speed-monitor/speed-monitor.module';
import { TaskListModule } from '@app/modules/task-list/task-list.module';
import { SharedModule } from '@app/shared/shared.module';
import {
  ActiveOrderPanelComponent,
  AsideComponent,
  HeaderComponent,
  MainMenuComponent,
  ShutdownModalComponent,
  TaskCategoryListComponent
} from '@app/shell/components';
import { ShellRoutingModule } from '@app/shell/shell-routing.module';
import {
  ApiAuthorizationModule,
  ApplicationHeaderModule,
  MainMenuNavigationModule,
  ModalsModule,
  PhaseNavigationModule
} from 'chronos-shared';
import { ShellComponent } from './containers/shell/shell.component';
import { QaLogModule } from '@app/modules/qa-log/qa-log.module';
import { ManualModeModule } from '@app/modules/manual-mode/manual-mode.module';
import { DatePickerModalComponent } from './components/modals/date-picker-modal/date-picker-modal.component';
import { ApproveModule } from '@app/modules/approve/approve.module';
import { EmployeeRegistrationModule } from '@app/modules/employee-registration/employee-registration.module';
import { OperationSupportModule } from '@app/modules/operation-support/operation-support.module';
import { ResourceConfigurationModule } from '@app/modules/resource-configuration/resource-configuration.module';

@NgModule({
  declarations: [
    ShellComponent,
    MainMenuComponent,
    ActiveOrderPanelComponent,
    TaskCategoryListComponent,
    HeaderComponent,
    AsideComponent,
    ShutdownModalComponent,
    DatePickerModalComponent
  ],
  imports: [
    SharedModule,
    ApiAuthorizationModule,

    // Routing import
    ShellRoutingModule,

    // Chronos imports
    OrdersModule,
    TaskListModule,
    SetupPhaseModule,
    RunPhaseModule,
    FinishPhaseModule,
    ShutdownModule,
    MountingModule,
    SpeedMonitorModule,
    DocumentsModule,
    MachineChartModule,
    ShiftReportModule,
    QaLogModule,
    ManualModeModule,
    ApproveModule,
    EmployeeRegistrationModule,
    OperationSupportModule,
    ResourceConfigurationModule,
    MainMenuNavigationModule,
    ApplicationHeaderModule,
    PhaseNavigationModule,
    ModalsModule
  ]
})
export class ShellModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ShellModule
  ) {
    if (parentModule) {
      throw new Error('ShellModule is already loaded. Import it in the AppModule only.');
    }
  }
}
