<section class="chronos-dialog--size-xs">
  <lib-material-status [title]="'MOUNTING.ACTIVE_MATERIAL' | translate" [article]="article"></lib-material-status>
  <form class="secondary-material-form" [libEnterKeyHandler]="'submit_modal'" [formGroup]="modalForm">
    <div class="row no-gutters secondary-material">
      <div class="col-4">
        <label for="currentNumberOfDuplicatedBoms">{{ 'MOUNTING.NUMBER_OF_PARALLEL_CONSUMPTION' | translate }}</label>
      </div>
      <div class="col">
        <input
          id="currentNumberOfDuplicatedBoms"
          class="secondary-material__input"
          type="text"
          [formControlName]="'currentNumberOfDuplicatedBoms'"
          libChronosInput
          pInputText
        />
      </div>
    </div>
  </form>
  <lib-modal-footer
    (submitClicked)="splitBillOfMaterial()"
    (declineClicked)="closeAssignModal(false)"
    [submitLabel]="'MOUNTING.SPLIT'"
    [submitDisabled]="modalForm.invalid || modalForm.disabled"
    [whenLoadingTopic]="LOADING_TOPIC"
  ></lib-modal-footer>
</section>
