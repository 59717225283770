import { Injectable } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services/active-order-ds/active-order-ds.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  CreateAndMountVirtualSecondaryContainerEntry,
  DismountSecondaryContainerEntry,
  MaterialAvailableAtLocation,
  MountAndConsumeSecondaryContainerEntry,
  MountSecondaryContainerEntry,
  ReplaceVirtualSecondaryContainerEntry,
  ScanContainerResponse,
  SecondaryMaterialService,
  SetPunchingPressureEntry
} from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolDsService {
  constructor(
    private secondaryMaterialService: SecondaryMaterialService,
    private activeOrderDsService: ActiveOrderDsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public setPunchingPressure(punchingPressure: number, articleId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: SetPunchingPressureEntry = {
      punchingPressure,
      workCenterId
    };
    const params: SecondaryMaterialService.SetPunchingPressureParams = {
      articleId,
      entry
    };
    return this.secondaryMaterialService.setPunchingPressure(params);
  }

  public mountToolForActiveOrder(identificationCode: string, containerId: number, materialBlockId: number): Observable<null> {
    const entry: MountSecondaryContainerEntry = {
      identificationCode,
      containerId,
      productionOrderId: this.activeOrderDsService.getActiveOrderId(),
      materialBlockId
    };
    const params: SecondaryMaterialService.MountSecondaryContainerParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      entry
    };
    return this.secondaryMaterialService.mountSecondaryContainer(params);
  }

  public getVirtualToolReasons(): Observable<ListValue[]> {
    return this.secondaryMaterialService.getVirtualSecondaryContainerReasons();
  }

  public createVirtualTool(entry: CreateAndMountVirtualSecondaryContainerEntry): Observable<ScanContainerResponse> {
    const params: SecondaryMaterialService.CreateAndMountVirtualSecondaryContainerParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      entry
    };
    return this.secondaryMaterialService.createAndMountVirtualSecondaryContainer(params);
  }

  public replaceVirtualTool(mountedMaterialId: number, virtualContainerId: number, newContainerId: number): Observable<null> {
    const entry: ReplaceVirtualSecondaryContainerEntry = {
      virtualContainerId,
      newContainerId,
      workCenterId: this.activeWorkCenterService.getWorkCenterId()
    };
    const params: SecondaryMaterialService.ReplaceVirtualSecondaryContainerParams = {
      mountedMaterialId,
      entry
    };
    return this.secondaryMaterialService.replaceVirtualSecondaryContainer(params);
  }

  public getAvailableForAtLocation(): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.secondaryMaterialService.getMaterialAvailable(workCenterId);
  }

  public getAvailableForAtLocationForArtice(articleId): Observable<MaterialAvailableAtLocation> {
    const params: SecondaryMaterialService.GetMaterialAvailableForArticleParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      articleId
    };
    return this.secondaryMaterialService.getMaterialAvailableForArticle(params);
  }

  public consumeMaterial(containerId: number, quantity: number): Observable<null> {
    const entry: MountAndConsumeSecondaryContainerEntry = {
      containerId,
      quantity
    };
    const params: SecondaryMaterialService.ConsumeParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      entry
    };
    return this.secondaryMaterialService.consume(params);
  }

  public dismountSecondaryContainer(
    mountedMaterialId: number,
    shouldPrintLabel: boolean,
    containerId: number,
    remainingQuantity?: number,
    runId?: number,
    workCenterId?: number
  ): Observable<ScanContainerResponse> {
    const entry: DismountSecondaryContainerEntry = {
      containerId,
      workCenterId: workCenterId ?? this.activeWorkCenterService.getWorkCenterId(),
      shouldPrintLabel,
      remainingQuantity,
      runId
    };
    const params: SecondaryMaterialService.DismountParams = {
      mountedMaterialId,
      entry
    };
    return this.secondaryMaterialService.dismount(params);
  }
}
