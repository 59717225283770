export enum MaterialStatus {
  ACTIVE = 'material-status__title',
  NEXT = 'material-status__title material-status__title--next',
  RUNNING = 'material-status__title material-status__title--running'
}

export enum MaterialLocationsMode {
  Ignore = 'Ignore',
  MachineLocation = 'MachineLocation',
  WarehouseLocation = 'WarehouseLocation'
}

export enum MaterialLocationsSelectedValueMode {
  CountOnly = 'CountOnly',
  CountAndSum = 'CountAndSum',
  CountAndSumAndContainers = 'CountAndSumAndContainers'
}

export enum MaterialFilterCriteria {
  None = 'None',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Trace = 'Trace',
  PrimaryAndSecondary = 'PrimaryAndSecondary',
  All = 'All'
}
