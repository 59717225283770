/* tslint:disable */
type QaCheckResult = 'Ok' | 'NotOk' | 'WarningOk' | 'NotApplicable';
module QaCheckResult {
  export const OK: QaCheckResult = 'Ok';
  export const NOT_OK: QaCheckResult = 'NotOk';
  export const WARNING_OK: QaCheckResult = 'WarningOk';
  export const NOT_APPLICABLE: QaCheckResult = 'NotApplicable';
  export function values(): QaCheckResult[] {
    return [OK, NOT_OK, WARNING_OK, NOT_APPLICABLE];
  }
}

export { QaCheckResult };
