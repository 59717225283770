/* tslint:disable */
type QaCheckFrequencyType = 'None' | 'Minutes' | 'Container' | 'ProcessingUnit' | 'Order';
module QaCheckFrequencyType {
  export const NONE: QaCheckFrequencyType = 'None';
  export const MINUTES: QaCheckFrequencyType = 'Minutes';
  export const CONTAINER: QaCheckFrequencyType = 'Container';
  export const PROCESSING_UNIT: QaCheckFrequencyType = 'ProcessingUnit';
  export const ORDER: QaCheckFrequencyType = 'Order';
  export function values(): QaCheckFrequencyType[] {
    return [NONE, MINUTES, CONTAINER, PROCESSING_UNIT, ORDER];
  }
}

export { QaCheckFrequencyType };
