import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { EmployeeRegistrationHistory } from 'chronos-core-client';
import { EmployeeRegistrationHistoryStore, HistoryState } from './employee-registration-history.store';
import { defaultDropdownValue, HistoryFilters } from './employee-registration-history.model';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmployeeRegistrationHistoryQuery extends QueryEntity<HistoryState, EmployeeRegistrationHistory> {
  public filters$: Observable<HistoryFilters> = this.select((state) => state.filters);

  public history$ = this.filters$.pipe(
    switchMap((filters) =>
      this.selectAll({
        filterBy: (employeeHistory: EmployeeRegistrationHistory) =>
          this.filterByShift(filters, employeeHistory) && this.filterBySearchKeyword(filters, employeeHistory)
      })
    )
  );

  private filterBySearchKeyword(filters: HistoryFilters, employee: EmployeeRegistrationHistory) {
    if (!filters.textQuery) {
      return true;
    } else {
      return (
        employee.name?.toLowerCase().includes(filters.textQuery.toLowerCase()) ||
        employee.externalEmployeeId?.toLowerCase().includes(filters.textQuery.toLowerCase())
      );
    }
  }

  private filterByShift(filters: HistoryFilters, employee: EmployeeRegistrationHistory): boolean {
    const shift = filters.shift;

    if (shift === defaultDropdownValue) {
      return true;
    }

    return employee.shiftId === shift;
  }

  constructor(protected store: EmployeeRegistrationHistoryStore) {
    super(store);
  }
}
