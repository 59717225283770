/* tslint:disable */
type WorkCenterStateType = 'Interruption' | 'Setup' | 'Production';
module WorkCenterStateType {
  export const INTERRUPTION: WorkCenterStateType = 'Interruption';
  export const SETUP: WorkCenterStateType = 'Setup';
  export const PRODUCTION: WorkCenterStateType = 'Production';
  export function values(): WorkCenterStateType[] {
    return [INTERRUPTION, SETUP, PRODUCTION];
  }
}

export { WorkCenterStateType };
