import { Injectable } from '@angular/core';
import { ScanContainerResponse, MountContainerResponse, MaterialType } from 'chronos-core-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { OverviewMaterialDsService, SecondaryMaterialDsService } from '@app/core/data-services';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { map } from 'rxjs/operators';

@Injectable()
export class TabOverviewService {
  public totalContainerCountSubject = new BehaviorSubject<number>(0);
  public totalContainerCountSubject$: Observable<number> = this.totalContainerCountSubject.asObservable();

  constructor(
    private overviewMaterialDsService: OverviewMaterialDsService,
    private secondaryMaterialDsService: SecondaryMaterialDsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public mountContainer(
    productionOrderId: number,
    materialType?: MaterialType,
    identificationCode?: string,
    containerId?: number
  ): Observable<MountContainerResponse> {
    // Note: As the mouting endpoints have been modified we dont have any means to distinguish between material and
    // common endpoint is called.
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.overviewMaterialDsService.mountContainer(workCenterId, productionOrderId, materialType, identificationCode, containerId);
  }

  public scanIdentificationCode(identificationCode: string): Observable<ScanContainerResponse> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.overviewMaterialDsService.scanIdentificationCode(workCenterId, identificationCode);
  }

  public getSecondaryToDismount(): Observable<number> {
    return this.secondaryMaterialDsService.getSecondaryToDismount().pipe(map((kpi) => kpi.containerCount));
  }
}
