import { Injectable } from '@angular/core';
import { MountingCardViewOptions } from '@app/modules/mounting/models';
import { Query } from '@datorama/akita';
import { UiState } from './ui-state.model';
import { UiStateStore } from './ui-state.store';

@Injectable({ providedIn: 'root' })
export class UiStateQuery extends Query<UiState> {
  constructor(protected uiStateStore: UiStateStore) {
    super(uiStateStore);
  }

  public getShiftReportStateSelectedShift(): number {
    return this.getValue().shiftReportSelectedShiftId;
  }

  public getApproveStateSelectedTechnology(): number {
    return this.getValue().approveSelectedTechnologyId;
  }

  public getApproveStateSelectedDate(): Date {
    return this.getValue().approveSelectedDate;
  }

  public getApproveStateSelectedWorkCenter(): number {
    return this.getValue().approveSelectedWorkCenterId;
  }

  public getMountingSelectedRoute(): string {
    return this.getValue().mountingSelectedRoute;
  }

  public getMountingOrderId(): number {
    return this.getValue().mountingSelectedOrderId;
  }

  public getMountingPrimaryListView(): MountingCardViewOptions {
    return this.getValue().mountingPrimaryListview;
  }

  public getMountingSecondaryExpandedArticles(): number[] {
    return this.getValue().mountingSecondaryExpandedArticles;
  }

  public getMountingSecondaryMountedExpandedArticles(): number[] {
    return this.getValue().mountingSecondaryMountedExpandedArticles;
  }

  public getMountingTraceExpandedArticles(): number[] {
    return this.getValue().mountingTraceExpandedArticles;
  }

  public getOrderListExpandedOrders(): number[] {
    return this.getValue().orderListExpandedOrders;
  }

  public getOrderListExpandedOrder(productionOrderId: number): boolean {
    return this.getValue().orderListExpandedOrders.find((x) => x === productionOrderId) === undefined;
  }

  public getDocumentSelectedRoute(): string {
    return this.getValue().documentSelectedRoute;
  }

  public getDocumentOrderId(): number {
    return this.getValue().documentSelectedOrderId;
  }

  public getRunPhaseProducedMaterialExpanded(producedMaterialId: number): boolean {
    return this.getValue().runPhaseExpandedProducedMaterials.find((x) => x === producedMaterialId) === producedMaterialId;
  }
}
