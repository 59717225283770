import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import moment from 'moment-mini';
import * as R from 'ramda';
import { ActiveProductionOrder } from 'chronos-core-client';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import { Router } from '@angular/router';
import { nav, notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService, LogService, UtcToLocalPipe } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { OutputFinish, OutputFinishState } from '@app/modules/run-phase/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-order-finish-modal',
  templateUrl: './order-finish-modal.component.html',
  styleUrls: ['./order-finish-modal.component.scss']
})
export class OrderFinishModalComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalForManualMode;

  public modalOpenTime: Date;
  public minSelectableStartDate: Date;
  public selectedStartDate: Date;
  public selectedFinishDate: Date;
  public activeProductionOrder: ActiveProductionOrder;
  public outputFinish: OutputFinish;

  private readonly DAYS_AVAILABLE = 5;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private manualModeService: ManualModeService,
    private router: Router,
    private utcToLocal: UtcToLocalPipe
  ) {}

  public ngOnInit(): void {
    this.activeProductionOrder = this.config.data?.activeProductionOrder;
    this.outputFinish = this.config.data?.outputFinish;
    this.modalOpenTime = new Date();
    this.selectedStartDate = this.utcToLocal.transform(this.activeProductionOrder?.productionStartTime);
    this.selectedFinishDate = this.modalOpenTime;
    this.minSelectableStartDate = moment(this.selectedStartDate).subtract(this.DAYS_AVAILABLE, 'days').toDate();
  }

  public cancelButton(): void {
    this.ref.close();
  }

  public submitButton(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    let action: Observable<any>;

    if (this.outputFinish.finishState === OutputFinishState.Switch) {
      action = this.manualModeService.switchManualOrder(
        this.activeProductionOrder.productionOrderId,
        this.outputFinish.producedMaterialId,
        this.selectedFinishDate,
        this.selectedStartDate
      );
    } else {
      action = this.manualModeService.endManualCuttingOrder(
        this.activeProductionOrder.productionOrderId,
        this.selectedStartDate,
        this.selectedFinishDate,
        this.outputFinish.finishState === OutputFinishState.Interrupt
      );
    }

    action
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.finishOrder();
      });
  }

  private finishOrder(): void {
    LogService.success('SUCCESS_MESSAGE.JOB_FINISHED');
    this.navigateToFinishPhase();
  }

  public getMaxStartDate(): Date {
    return this.selectedFinishDate ? this.selectedFinishDate : this.modalOpenTime;
  }

  public getMinFinishDate(): Date {
    return this.selectedStartDate ? this.selectedStartDate : this.minSelectableStartDate;
  }

  public isModalValid(): boolean {
    return !R.isNil(this.selectedStartDate) && !R.isNil(this.selectedFinishDate) && this.selectedStartDate <= this.selectedFinishDate;
  }

  private navigateToFinishPhase(): void {
    this.router.navigate([nav.finish]).then(() => {
      this.ref.close();
    });
  }
}
