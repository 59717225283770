import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputSwitchComponent),
  multi: true
};

@Component({
  selector: 'lib-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class InputSwitchComponent implements ControlValueAccessor {
  @Input() public loadingTopic = '';
  @Input() public loadingIndicator = false;
  @Input() public disabled = false;
  @Input() public label: string;
  @Input() public required = false;
  @Input() public isMarginPaddingRequired = false;

  public loading = false;
  public value: boolean;

  private propagateChanged: (value: boolean) => void;
  private propagateTouched: () => void;

  public registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: boolean): void {
    this.value = value;
  }

  public onChanged(event: any): void {
    this.value = event.checked;
    this.propagateChanged(this.value);
  }
}
