/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TIEPUniversalMessageResponse } from '../models/tiepuniversal-message-response';
import { CollectionParameters } from '../models/collection-parameters';
import { TIEPUniversalMessage } from '../models/tiepuniversal-message';
@Injectable({
  providedIn: 'root'
})
class UniversalMessageService extends __BaseService {
  static readonly getUniversalMessagesPath = '/api/UniversalMessage/getUniversalMessages';
  static readonly addUniversalMessagePath = '/api/UniversalMessage/addUniversalMessage';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  getUniversalMessagesResponse(body?: CollectionParameters): __Observable<__StrictHttpResponse<TIEPUniversalMessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/UniversalMessage/getUniversalMessages`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TIEPUniversalMessageResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  getUniversalMessages(body?: CollectionParameters): __Observable<TIEPUniversalMessageResponse> {
    return this.getUniversalMessagesResponse(body).pipe(__map((_r) => _r.body as TIEPUniversalMessageResponse));
  }

  /**
   * @param body undefined
   */
  addUniversalMessageResponse(body?: TIEPUniversalMessage): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/UniversalMessage/addUniversalMessage`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  addUniversalMessage(body?: TIEPUniversalMessage): __Observable<null> {
    return this.addUniversalMessageResponse(body).pipe(__map((_r) => _r.body as null));
  }
}

module UniversalMessageService {}

export { UniversalMessageService };
