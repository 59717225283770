/* tslint:disable */
type ExternalProductionOrderStatus = 'Created' | 'Estimated' | 'Planned' | 'Released' | 'Started' | 'ReportAsFinished' | 'Ended';
module ExternalProductionOrderStatus {
  export const CREATED: ExternalProductionOrderStatus = 'Created';
  export const ESTIMATED: ExternalProductionOrderStatus = 'Estimated';
  export const PLANNED: ExternalProductionOrderStatus = 'Planned';
  export const RELEASED: ExternalProductionOrderStatus = 'Released';
  export const STARTED: ExternalProductionOrderStatus = 'Started';
  export const REPORT_AS_FINISHED: ExternalProductionOrderStatus = 'ReportAsFinished';
  export const ENDED: ExternalProductionOrderStatus = 'Ended';
  export function values(): ExternalProductionOrderStatus[] {
    return [CREATED, ESTIMATED, PLANNED, RELEASED, STARTED, REPORT_AS_FINISHED, ENDED];
  }
}

export { ExternalProductionOrderStatus };
