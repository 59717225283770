import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ShutdownEntry, ShutdownReason, WorkCenterService, WorkCenterState } from 'chronos-core-client';
import { switchMap, tap } from 'rxjs/operators';
import { AppSettingsService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class ShutdownService {
  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private appSettingsService: AppSettingsService,
    private workCenterService: WorkCenterService
  ) {}

  public getShutdownReasons(): Observable<ShutdownReason[]> {
    return this.workCenterService.getShutdownReasons();
  }

  public startMachine(): Observable<WorkCenterState> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.workCenterService.startup(workCenterId).pipe(switchMap(() => this.setMachineState()));
  }

  public shutdownMachine(shutdownEntry: ShutdownEntry): Observable<WorkCenterState> {
    const params: WorkCenterService.ShutdownParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      shutdownEntry
    };
    return this.workCenterService.shutdown(params).pipe(switchMap(() => this.setMachineState()));
  }

  private setMachineState(): Observable<WorkCenterState> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.workCenterService.getCurrentState(workCenterId).pipe(
      tap((workCenterState) => {
        this.appSettingsService.setWorkCenterState(workCenterState);
      })
    );
  }
}
