import { Injectable } from '@angular/core';
import { ConsumptionListFilters, ConsumptionListSorting } from 'chronos-shared';
import { PrimaryMaterialMounted } from 'chronos-core-client';
import { ConsumedMaterialStore } from './consumed-material.store';

@Injectable()
export class ConsumedMaterialService {
  constructor(private consumedMaterialStore: ConsumedMaterialStore) {}

  public set(consumedMaterial: PrimaryMaterialMounted[]): void {
    this.consumedMaterialStore.set(consumedMaterial);
  }

  public updateFilters(filters: Partial<ConsumptionListFilters>): void {
    this.consumedMaterialStore.updateFilters(filters);
  }

  public updateSorting(sorting: Partial<ConsumptionListSorting>): void {
    this.consumedMaterialStore.updateSorting(sorting);
  }

  public reset(): void {
    this.consumedMaterialStore.reset();
  }
}
