<p-calendar
  [(ngModel)]="selectedValue"
  (ngModelChange)="onModelChange()"
  [disabled]="disabled"
  [showTime]="showTime"
  [placeholder]="placeholder"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [inputStyle]="inputStyle"
  [keepInvalid]="keepInvalid"
  [dateFormat]="dateFormat"
  [styleClass]="styleClass"
  [selectOtherMonths]="selectOtherMonths"
  (onBlur)="onInputBlur()"
  (onSelect)="onInputBlur()"
  (onInput)="inputChangeEmit($event)"
  (onMonthChange)="monthChangeEmit($event)"
  [appendTo]="'body'"
>
  <ng-template *ngIf="dayTemplate" pTemplate="date" let-date>
    <ng-container *ngTemplateOutlet="dayTemplate; context: { $implicit: date }"></ng-container>
  </ng-template>
</p-calendar>
