import { TraceabilityDsService } from '../../../../core/data-services/traceability-ds/traceability-ds.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TraceabilityMappingService } from '../Traceability-Mapping/traceability-mapping.service';
import { MountedMaterialForwardSearchTrace, ProductionOrderBackwardSearchTrace } from 'chronos-core-client';
import { TreeNode } from 'primeng/api';

@Injectable()
export class TraceabilityDetailsService {
  constructor(private traceabilityDsService: TraceabilityDsService, private traceabilityMappingService: TraceabilityMappingService) {}

  public getMountedMaterialsForForwardSearch(startDate: string, searchText: null | string, endDate: string) {
    return this.traceabilityDsService
      .getMountedMaterialsForForwardSearch(startDate, searchText, endDate)
      .pipe(map((forwardSearch) => this.traceabilityMappingService.mapForwardSearchtoData(forwardSearch)));
  }

  public getProductionOrdersForBackwardSearch(startDate: string, searchText: null | string, endDate: string) {
    return this.traceabilityDsService
      .getProductionOrdersForBackwardSearch(startDate, searchText, endDate)
      .pipe(map((backwardSearch) => this.traceabilityMappingService.mapBackwardSearchtoData(backwardSearch)));
  }

  public getMountedMaterialTraceForForwardSearch(productionOrderId: number, viewMode: string) {
    return this.traceabilityDsService.getMountedMaterialTraceForForwardSearch(productionOrderId, viewMode);
  }

  public getProductionOrderTraceForBackwardSearch(productionOrderId: number, viewMode: string) {
    return this.traceabilityDsService.getProductionOrderTraceForBackwardSearch(productionOrderId, viewMode);
  }

  public mapForwardDetailsDataToTraceabilityData(
    treeData: TreeNode[],
    data: MountedMaterialForwardSearchTrace[],
    productionOrderId: number
  ) {
    return this.traceabilityMappingService.mapForwardDetailsDataToTraceabilityData(treeData, data, productionOrderId);
  }

  public mapBackwardDetailsDataToTraceabilityData(
    treeData: TreeNode[],
    data: ProductionOrderBackwardSearchTrace[],
    prodDuctionOrderID: number
  ) {
    return this.traceabilityMappingService.mapBackwardDetailsDataToTraceabilityData(treeData, data, prodDuctionOrderID);
  }
}
