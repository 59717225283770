<div *ngIf="workCenterState$ | async as state" class="shutdown-modal chronos-dialog--size-sm">
  <img src="/assets/images/machine.svg" />

  <div class="shutdown-modal__message">{{ 'SHUTDOWN.MODAL_MSG' | translate }} {{ state?.eventTime | utcToLocal | date: 'HH:mm' }}</div>

  <div *ngIf="currentReason$ | async as reason" class="shutdown-modal__reason">({{ reason.label }})</div>

  <lib-button
    (clicked)="onStartUp()"
    [label]="'SHUTDOWN.EXIT_SHUTDOWN'"
    [buttonId]="'shutdown_exit_button'"
    [loadingTopic]="LOADING_TOPIC"
    [sizeClass]="'button--small'"
  ></lib-button>
</div>
