export const environment = {
  production: true,
  apiUrl: '/core',
  hubUrl: '/core/ChangeNotificationHub',
  workCenterId: -455,
  defaultLanguage: 'en',
  coreApiUrl: '/core',
  signalrBaseUrl: '/core/ChangeNotificationHub',
  liveApiUrl: '/live',
  pandaApiUrl: '/panda'
};
