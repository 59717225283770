import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ForwardSearchResultChildItems } from '@app/modules/operation-support/models/traceability-searchmode-option';
import { TreeNode } from 'primeng/api';
import { TraceabilityTableColumn } from './traceability-table-column';

@Component({
  selector: 'app-traceability-table',
  templateUrl: './traceability-table.component.html',
  styleUrls: ['./traceability-table.component.scss']
})
export class TraceabilityTableComponent {
  constructor() {}
  @Input() public tableData: TreeNode[] = [];
  @Input() public isNodeLoading: boolean;
  @Output() public loadNodeClicked = new EventEmitter<any>();

  public readonly ITEM_TYPE = ForwardSearchResultChildItems;

  public columns: TraceabilityTableColumn[] = [
    { label: 'TRACEABILITY.ORDER', styleClass: 'medium-cell', highlighted: false },
    { label: 'TRACEABILITY.SALES_ORDER', styleClass: 'qty-cell', highlighted: false },
    { label: 'TRACEABILITY.POSITION_ID', styleClass: 'tiny-cell', highlighted: false },
    { label: 'TRACEABILITY.ARTICLE', styleClass: 'small-cell', highlighted: false },
    { label: 'TRACEABILITY.PRODUCTION_TIME', styleClass: 'small-cell', highlighted: false },
    { label: 'TRACEABILITY.CLASSIFICATION', styleClass: 'small-cell', highlighted: false },
    { label: 'TRACEABILITY.ORDER', styleClass: 'small-cell', highlighted: false },
    { label: 'TRACEABILITY.WAREHOUSE', styleClass: 'small-cell', highlighted: false }
  ];

  public loadNode(event: any): void {
    this.loadNodeClicked.emit(event);
  }
}
