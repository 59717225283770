<section class="chronos-dialog--size-lg">
  <div class="consumed-material__actions">
    <p-checkbox
      name="onlyDummyPallets"
      [label]="'MOUNTING.SHOW_ONLY_DUMMY' | translate"
      (onChange)="onDummyPalletShowChange($event)"
      [binary]="true"
    ></p-checkbox>
  </div>

  <app-mount-pallet-list-template
    [activeMaterial]="activeMaterial"
    [externalWorkCenterId]="externalWorkCenterId$ | async"
    [canCreateDummyPallets]="false"
  >
    <ng-container *appOrderByItems>
      <lib-order-by-button [order]="palletSequence$ | async" (orderChanged)="onPalletOrderChange($event)">
        {{ 'MOUNTING.SEQUENCE_NUMBER' | translate }}
      </lib-order-by-button>
      <div>{{ 'MOUNTING.SSCC' | translate }}</div>
      <div>{{ 'MOUNTING.CONSUMED_QUANTITY' | translate }}</div>
      <div>{{ 'MOUNTING.WHITE_WASTE' | translate }}</div>
      <div>{{ 'MOUNTING.ARTICLE' | translate }}</div>
    </ng-container>

    <app-consumption-pallet-list [consumedContainers]="consumedMaterial$ | async">
      <app-consumption-pallet-item
        *appItemContainer="let consumedContainer"
        [consumedContainer]="consumedContainer"
        (dismountIconClicked)="onPalletDismountIconClick($event)"
      ></app-consumption-pallet-item>

      <app-consumption-pallet-dummy
        *appDummyContainer="let consumedContainer"
        [consumedContainer]="consumedContainer"
        (infoButtonClicked)="onInfoButtonClick(consumedContainer)"
        (replaceButtonClicked)="onReplaceButtonClick(consumedContainer)"
        (dismountIconClicked)="onDummyDismountIconClick($event)"
      ></app-consumption-pallet-dummy>

      <app-consumption-pallet-empty *appEmptyContainer></app-consumption-pallet-empty>
    </app-consumption-pallet-list>
  </app-mount-pallet-list-template>
  <lib-modal-footer [submitLabel]="'MOUNTING.CLOSE'" (submitClicked)="close()" [showDeclineButton]="false"></lib-modal-footer>
</section>
