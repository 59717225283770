import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { UiState } from './ui-state.model';
import { nav } from '@app/shared/utils/nav';
import { MountingCardViewOptions } from '@app/modules/mounting/models';

function createInitialUiState(): Partial<UiState> {
  return {
    mountingSelectedRoute: nav.routes.mountingPrimary,
    mountingSelectedOrderId: undefined,
    mountingPrimaryListview: MountingCardViewOptions.Card,
    mountingSecondaryExpandedArticles: [],
    mountingSecondaryMountedExpandedArticles: [],
    mountingTraceExpandedArticles: [],
    documentSelectedRoute: nav.routes.documents,
    documentSelectedOrderId: undefined,
    orderListExpandedOrders: [],
    runPhaseExpandedProducedMaterials: [],
    employeeSelectedRoute: nav.routes.employeeLogin
  };
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui-state' })
export class UiStateStore extends Store<UiState> {
  constructor() {
    super(createInitialUiState());
  }
}
