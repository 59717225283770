/* tslint:disable */
type ProducedMaterialLoadingMode = 'Open' | 'Finished' | 'All' | 'Update';
module ProducedMaterialLoadingMode {
  export const OPEN: ProducedMaterialLoadingMode = 'Open';
  export const FINISHED: ProducedMaterialLoadingMode = 'Finished';
  export const ALL: ProducedMaterialLoadingMode = 'All';
  export const UPDATE: ProducedMaterialLoadingMode = 'Update';
  export function values(): ProducedMaterialLoadingMode[] {
    return [OPEN, FINISHED, ALL, UPDATE];
  }
}

export { ProducedMaterialLoadingMode };
