import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PrimaryMaterialMounted } from 'chronos-core-client';

export interface InputPalletsState extends EntityState<PrimaryMaterialMounted>, ActiveState {}

@Injectable()
@StoreConfig({ name: 'input-pallets', idKey: 'mountedMaterialId', resettable: true })
export class InputPalletsStore extends EntityStore<InputPalletsState, PrimaryMaterialMounted> {
  constructor() {
    super();
  }
}
