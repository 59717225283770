import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ShiftReportItem } from '@app/core/global-state';
import { GroupedKpiValue } from '@app/shared/models';
import { OverviewModalService } from '@app/modules/shift-report/services/overview-modal';
import { KpiCategory } from 'chronos-core-client';

@Component({
  selector: 'app-overview-modal',
  templateUrl: './overview-modal.component.html',
  styleUrls: ['./overview-modal.component.scss']
})
export class OverviewModalComponent implements OnInit {
  public kpis: GroupedKpiValue;
  public shiftReportItem: ShiftReportItem;
  public readonly KPI_CATEGORY = KpiCategory;
  constructor(private overviewModalService: OverviewModalService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.kpis = this.overviewModalService.groupKpis(this.config.data.shiftReportItem.productionOrder.kpiValues);
    this.shiftReportItem = this.config.data.shiftReportItem;
  }

  public closeModal() {
    this.ref.close();
  }

  public formatColumnTitle(title: string): string {
    return `FINISH_PHASE.${title.toUpperCase()}`;
  }
}
