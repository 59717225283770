import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'lib-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  public question: string = this.config.data?.question;
  public subQuestion: string = this.config.data?.subQuestion;
  public isFooterButtonSwapped = this.config.data?.swapButtons;
  public hideFooter = this.config.data?.hideFooter;
  public acceptLabel = 'MODAL_FOOTER.OK';
  public rejectLabel = 'MODAL_FOOTER.CANCEL';

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    const isAcceptable: boolean = this.config.data?.acceptable;

    if (isAcceptable) {
      this.acceptLabel = 'MODAL_FOOTER.YES';
      this.rejectLabel = 'MODAL_FOOTER.NO';
    }
  }

  public acceptModal(): void {
    this.ref.close(true);
  }

  public rejectModal(): void {
    this.ref.close(false);
  }
}
