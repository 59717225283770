<div [ngClass]="getKpiStatusStyleClass()" class="no-pallets">
  <div>
    <div class="no-pallets__label">{{ title }}</div>
    <div class="no-pallets__valuecontainer">
      <span class="no-pallets__valuecontainer--value">{{ value }}</span>
      <span [ngClass]="isKpiNegative() ? 'no-pallets__valuecontainer--unit--negative' : 'no-pallets__valuecontainer--unit'">{{ unit }}</span>
    </div>
  </div>
  <div>
    <i class="no-pallets__icon" [ngClass]="getKpiStatusStyleIconClass()"></i>
  </div>
</div>
