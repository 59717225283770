import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-multi-language',
  templateUrl: './multi-language.component.html',
  styleUrls: ['./multi-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiLanguageComponent {
  @Input() public languages: { label: string; value: string | number }[];
  @Input() public selectedLanguage: string;

  @Output() public languageSelected = new EventEmitter<string>();

  public onLanguageChange(lang: string): void {
    this.languageSelected.emit(lang);
  }
}
