import { ArticleDescription, FlushingPrinciple, MountedMaterialStatus, Quantity, WarehouseDimension } from 'chronos-core-client';

export interface MaterialTableTreeNode {
  data: MaterialTableDataRow;
  children?: MaterialTableTreeNode[];
  expanded?: boolean;
}

export enum MaterialTableDataRowDisplayMode {
  BillOfMaterial,
  Container
}

export interface MaterialTableDataRow {
  displayMode: MaterialTableDataRowDisplayMode;
  batchInternalTitle?: string;
  batchExternalTitle?: string;
  identificationCode?: string;
  internalBatchId?: string;
  externalBatchId?: string;
  externalSerialId?: string;
  externalProductionOrderId?: string;
  consumedQuantity?: Quantity;
  mountedQuantity?: Quantity;
  article: ArticleDescription;
  mountedMaterialId?: number;
  containerId?: number;
  initialQuantity?: Quantity;
  inputQuantityPool?: Quantity;
  mountedUser?: string;
  mountedTime?: Date;
  containerCount?: number;
  openQuantity?: Quantity;
  onHandQuantity?: Quantity;
  mountedMaterialStatus?: MountedMaterialStatus;
  materialSequence?: number;
  mountedWorkCenterId?: number;
  virtualContainerInfo?: string;
  infoTitle?: string;
  materialBlockId?: number;
  warehouseDimension?: WarehouseDimension;
  bomUnitFactor?: number;
  inventoryUnitId?: string;
  isVirtualContainer?: boolean;
  virtualContainerReason?: string;
  alternativeArticles?: ArticleDescription[];
  flushingPrinciple?: FlushingPrinciple;
  mountedTo?: string;
  billOfMaterialIdForSplitting?: number;
}
