import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { OperationSupportComponent } from './containers/operation-support/operation-support.component';
import { TraceabilityComponent } from './containers/components/traceability/traceability.component';
import { TraceabilityDetailsService } from './services/Traceability/Traceability.service';
import { TraceabilityTableComponent } from './containers/components/traceability-table/traceability-table.component';

@NgModule({
  declarations: [OperationSupportComponent, TraceabilityComponent, TraceabilityTableComponent],
  imports: [SharedModule],
  providers: [TraceabilityDetailsService]
})
export class OperationSupportModule {}
