import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Quantity } from 'chronos-core-client';
import { knownUnits } from '../../models/knownUnits.model';

@Pipe({
  name: 'formatQuantity'
})
export class FormatQuantityPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  public transform(quantity?: Quantity, showUnit: boolean = true, decimalPlaces?: number): string {
    const value = quantity?.value;
    const unitId = quantity?.unitId;

    if (typeof value !== 'number') {
      return '‒';
    }

    if (value === 0 && !unitId) {
      return '‒';
    }

    const unitStartsWith = (unitName: string) => unitId?.startsWith(unitName);
    const hasWholeUnits = () => knownUnits.WholeUnits.some((unit) => unitStartsWith(unit));

    if (!decimalPlaces && !hasWholeUnits()) {
      decimalPlaces = 2;
    }

    const formattedNumber = this.formatNumber(value, decimalPlaces);

    if (unitId && showUnit) {
      return `${formattedNumber} ${unitId}`;
    } else {
      return formattedNumber;
    }
  }

  private formatNumber(value: number, decimalPlaces?: number): string {
    const digits = decimalPlaces ?? 0;
    return this.decimalPipe.transform(value, `1.${digits}-${digits}`);
  }
}
