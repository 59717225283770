import { Injectable } from '@angular/core';
import { MountingMaterialServiceDsService } from '@app/core/data-services/mounting-material-ds/mounting-material-service-ds.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import {
  DismountSecondaryContainerEntry,
  MaterialAvailableAtLocation,
  MaterialService,
  MaterialType,
  MountAndConsumeSecondaryContainerEntry,
  MountContainerEntry,
  MountContainerResponse,
  MountingKpiValue,
  Quantity,
  SecondaryMaterial,
  SecondaryMaterialMounted,
  SecondaryMaterialService
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SecondaryMaterialDsService {
  constructor(
    private secondaryMaterialService: SecondaryMaterialService,
    private mountingMaterialServiceDsService: MountingMaterialServiceDsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private matrialService: MaterialService
  ) {}

  public getSecondaryMaterialOverviewData(): Observable<MaterialTableTreeNode[]> {
    return this.getSecondaryMaterialBlocks().pipe(
      map((secondaryMaterial) => this.mountingMaterialServiceDsService.mapSecondaryMaterial(secondaryMaterial.materialBlocks))
    );
  }

  public getSecondaryMountedMaterialData(): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    return this.secondaryMaterialService
      .getMountedMaterials2(workCenterId)
      .pipe(map((mountedMaterial) => this.mountingMaterialServiceDsService.mapSecondaryMaterial(mountedMaterial)));
  }

  public getSecondaryMaterialBlocks(): Observable<SecondaryMaterial> {
    return this.secondaryMaterialService.getSecondaryMaterial(this.activeWorkCenterService.getWorkCenterId());
  }

  public dismount(containerId: number, remainingQuantity: number, shouldPrintLabel: boolean, mountedMaterialId: number): Observable<null> {
    const entry: DismountSecondaryContainerEntry = {
      containerId,
      remainingQuantity,
      shouldPrintLabel,
      workCenterId: this.activeWorkCenterService.getWorkCenterId()
    };
    const params: SecondaryMaterialService.DismountParams = {
      mountedMaterialId,
      entry
    };
    return this.secondaryMaterialService.dismount(params);
  }

  public mountSecondary(
    identificationCode: string,
    containerId: number,
    productionOrderId: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MountContainerResponse> {
    const entry: MountContainerEntry = {
      containerId,
      identificationCode,
      materialType: MaterialType.SECONDARY,
      productionOrderId,
      whiteWasteQuantity
    };
    const params: MaterialService.MountContainerParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      entry
    };
    return this.matrialService.mountContainer(params);
  }

  public getNextContainerForDismount(): Observable<SecondaryMaterialMounted> {
    return this.secondaryMaterialService.getNextSecondaryContainerForDismount(this.activeWorkCenterService.getWorkCenterId());
  }

  public consumeMaterial(
    containerId: number,
    quantity: number,
    selectedWorkCenterId?: number,
    runId?: number,
    billOfMaterialId?: number
  ): Observable<null> {
    const workCenterId = selectedWorkCenterId || this.activeWorkCenterService.getWorkCenterId();
    const entry: MountAndConsumeSecondaryContainerEntry = {
      containerId,
      quantity,
      runId,
      billOfMaterialId
    };
    const params: SecondaryMaterialService.ConsumeParams = {
      workCenterId,
      entry
    };
    return this.secondaryMaterialService.consume(params);
  }

  public getMaterialList(): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.secondaryMaterialService.getMaterialAvailable(workCenterId);
  }

  public getMaterialListForProductionOrder(workCenterId: number, productionOrderId: number): Observable<MaterialAvailableAtLocation> {
    const params: SecondaryMaterialService.GetMaterialAvailableForProductionOrder2Params = {
      workCenterId,
      productionOrderId
    };
    return this.secondaryMaterialService.getMaterialAvailableForProductionOrder2(params);
  }

  public getMaterialListForProductionOrderAndArticle(
    workCenterId: number,
    productionOrderId: number,
    articleId: number
  ): Observable<MaterialAvailableAtLocation> {
    const params: SecondaryMaterialService.GetMaterialAvailableForProductionOrderAndArticleParams = {
      workCenterId,
      productionOrderId,
      articleId
    };
    return this.secondaryMaterialService.getMaterialAvailableForProductionOrderAndArticle(params);
  }

  public getSecondaryToDismount(): Observable<MountingKpiValue> {
    return this.secondaryMaterialService.toBeDismountedKpi(this.activeWorkCenterService.getWorkCenterId());
  }
}
