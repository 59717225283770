import { Injectable } from '@angular/core';
import { ActiveOrderDataService } from '@app/core/global-state';
import { HubUrlsService } from '@app/core/utils';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { WasteAssignmentFormEvent } from '@app/shared/models';
import {
  AddProductionOrderSetupWasteEntry,
  DeleteProductionOrderSetupWasteEntry,
  ProductionOrderService,
  WebSocketClientService,
  WorkCenterService
} from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class QuantityAllocationService {
  constructor(
    private activeOrderService: ActiveOrderDataService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private workCenterService: WorkCenterService,
    private webSocketClientService: WebSocketClientService,
    private productionOrderService: ProductionOrderService,
    private hubUrlsService: HubUrlsService
  ) {}

  public getReasonOptions(): Observable<ListValue[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    return this.workCenterService.getWasteReasons(workCenterId);
  }

  public getSetupWasteAssignments(productionOrderId: number): void {
    this.activeOrderService.renewWasteAssignment(productionOrderId).subscribe();
  }

  public getSetupPhaseStatus(productionOrderId: number): void {
    this.activeOrderService.getSetupPhaseStatus(productionOrderId).subscribe();
  }

  public addWasteAssignment(productionOrderId: number, fieldValues: WasteAssignmentFormEvent): Observable<any> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    const entry: AddProductionOrderSetupWasteEntry = {
      workCenterId,
      wasteReasonId: fieldValues?.reason,
      maculature: fieldValues.maculature,
      waste: fieldValues.waste
    };
    const params: ProductionOrderService.AddProductionOrderSetupWasteParams = {
      productionOrderId,
      entry
    };
    return this.productionOrderService.addProductionOrderSetupWaste(params).pipe(
      switchMap(() => this.activeOrderService.addWasteAssignment(productionOrderId)),
      take(1)
    );
  }

  public deleteWasteAssignment(productionOrderId: number, wasteAssignmentId: number): Observable<any> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    const entry: DeleteProductionOrderSetupWasteEntry = {
      workCenterId,
      wasteAssignmentId
    };
    const params: ProductionOrderService.DeleteProductionOrderSetupWasteParams = {
      productionOrderId,
      entry
    };
    return this.productionOrderService.deleteProductionOrderSetupWaste(params).pipe(
      switchMap(() => this.activeOrderService.deleteWasteAssignment(productionOrderId)),
      take(1)
    );
  }

  public getSetupStateNotifications(productionOrderId: number): Observable<any> {
    return this.webSocketClientService.getNotificationsForTopic(this.hubUrlsService.getProductionOrdersetupInfo(productionOrderId)).pipe(
      tap((data) => {
        this.activeOrderService.updateSetupPhaseStatus(data);
      })
    );
  }
}
