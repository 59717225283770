/* tslint:disable */
type MaterialType = 'None' | 'Primary' | 'Secondary' | 'Trace';
module MaterialType {
  export const NONE: MaterialType = 'None';
  export const PRIMARY: MaterialType = 'Primary';
  export const SECONDARY: MaterialType = 'Secondary';
  export const TRACE: MaterialType = 'Trace';
  export function values(): MaterialType[] {
    return [NONE, PRIMARY, SECONDARY, TRACE];
  }
}

export { MaterialType };
