import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductionOrder } from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormGroup } from '@angular/forms';
import { ParametersFactoryService } from '@app/core/services/parameters-factory';
import { getCombinationIcon } from '@app/modules/orders/utils';

@Component({
  selector: 'app-presetup-modal',
  templateUrl: './presetup-modal.component.html',
  styleUrls: ['./presetup-modal.component.scss']
})
export class PresetupModalComponent implements OnInit, OnDestroy {
  public productionOrder: ProductionOrder;
  public form = new UntypedFormGroup({});

  public itemCollapsed = true;
  public combinationIcon: string;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private parametersFactoryService: ParametersFactoryService
  ) {}

  public ngOnInit(): void {
    this.productionOrder = this.config.data.orderItem;
    this.parametersFactoryService.setValuesForSetup(this.productionOrder);
    this.combinationIcon = getCombinationIcon(this.productionOrder);
  }

  public toggleContent(): void {
    this.itemCollapsed = !this.itemCollapsed;
  }

  public ngOnDestroy(): void {
    this.parametersFactoryService.cleanParametersFactoryValues();
  }

  public onSubmitClick(): void {
    this.ref.close();
  }
}
