<app-modal-main-material
  *ngIf="secondaryMaterial$ | async as secondaryMaterial"
  [availableMountingMaterial]="secondaryMaterial.materials"
  [billOfMaterialArticleList]="secondaryMaterial.articles"
  [submitButtonLabel]="'MOUNTING.MOUNT'"
  [whenLoadingTopic]="LOADING_TOPIC"
  (mountingMaterialSubmit)="onConsumeClick($event)"
  [tabName]="tabName"
  [isDropDownOptionDisabled]="isDropDownOptionDisabled"
></app-modal-main-material>
