import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { WorkCenterTasksByCategory } from 'chronos-core-client';

export type TaskCategoryState = EntityState<WorkCenterTasksByCategory>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'task-categories', idKey: 'type' })
export class TaskCategoryStore extends EntityStore<TaskCategoryState, WorkCenterTasksByCategory> {
  constructor() {
    super();
  }
}
