/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SetInfoScreenDataEntry } from '../models/set-info-screen-data-entry';
import { MachineData } from '../models/machine-data';
@Injectable({
  providedIn: 'root'
})
class MachineService extends __BaseService {
  static readonly updateInfoDataPath = '/api/v1/Machine/{workCenterId}/InfoData';
  static readonly getStatusPath = '/api/v1/Machine/{workCenterId}/GetStatus';
  static readonly getStatiPath = '/api/v1/Machine/GetStati';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `MachineService.UpdateInfoDataParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  updateInfoDataResponse(params: MachineService.UpdateInfoDataParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/Machine/${encodeURIComponent(String(params.workCenterId))}/InfoData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MachineService.UpdateInfoDataParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  updateInfoData(params: MachineService.UpdateInfoDataParams): __Observable<null> {
    return this.updateInfoDataResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  getStatusResponse(workCenterId: number): __Observable<__StrictHttpResponse<MachineData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Machine/${encodeURIComponent(String(workCenterId))}/GetStatus`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MachineData>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getStatus(workCenterId: number): __Observable<MachineData> {
    return this.getStatusResponse(workCenterId).pipe(__map((_r) => _r.body as MachineData));
  }

  /**
   * @param workCenterIds undefined
   */
  getStatiResponse(workCenterIds: Array<number>): __Observable<__StrictHttpResponse<Array<MachineData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = workCenterIds;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Machine/GetStati`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MachineData>>;
      })
    );
  }
  /**
   * @param workCenterIds undefined
   */
  getStati(workCenterIds: Array<number>): __Observable<Array<MachineData>> {
    return this.getStatiResponse(workCenterIds).pipe(__map((_r) => _r.body as Array<MachineData>));
  }
}

module MachineService {
  /**
   * Parameters for UpdateInfoData
   */
  export interface UpdateInfoDataParams {
    workCenterId: number;
    entry: SetInfoScreenDataEntry;
  }
}

export { MachineService };
