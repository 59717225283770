import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LogEntry, LogLevel } from '../../../services/logging';

@Component({
  selector: 'lib-log-messages-modal',
  templateUrl: './log-messages-modal.component.html',
  styleUrls: ['./log-messages-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogMessagesModalComponent implements OnInit {
  public logMessages: LogEntry[];
  public readonly LOG_LEVEL = LogLevel;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.logMessages = this.config.data?.logMessages;
  }

  public closeModal(): void {
    this.ref.close();
  }
}
