import { Component, OnInit } from '@angular/core';
import { FinishPhaseService } from '@app/modules/finish-phase/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DismountingPalletInformation, LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-dismount-dummy-pallet-modal',
  templateUrl: './dismount-dummy-pallet-modal.component.html',
  styleUrls: ['./dismount-dummy-pallet-modal.component.scss']
})
export class DismountDummyPalletModalComponent implements OnInit {
  public dismountingPalletInformation: DismountingPalletInformation;
  public printLabel: any = false;
  public returnSheets: any;

  public readonly LOADING_TOPIC = notificationTopic.modalDismountContainer;
  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef, private finishPhaseService: FinishPhaseService) {}

  public ngOnInit(): void {
    this.dismountingPalletInformation = this.config.data?.dismountingPalletInformation;
    this.returnSheets = this.config.data?.returnSheets;
    this.printLabel = this.config.data?.printLabel === true;
  }

  public dismountContainer(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.finishPhaseService
      .dismountContainer(
        this.config.data.containerId,
        this.dismountingPalletInformation.mountedMaterialId,
        this.returnSheets,
        this.printLabel,
        this.dismountingPalletInformation.runId,
        this.dismountingPalletInformation.workCenterId
      )
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.MATERIAL_DISMOUNTED');
        this.ref.close(true);
      });
  }

  public onCloseModal(): void {
    this.ref.close(false);
  }
}
