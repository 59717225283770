/* tslint:disable */
type ShiftReportStatus = 'Open' | 'Closed';
module ShiftReportStatus {
  export const OPEN: ShiftReportStatus = 'Open';
  export const CLOSED: ShiftReportStatus = 'Closed';
  export function values(): ShiftReportStatus[] {
    return [OPEN, CLOSED];
  }
}

export { ShiftReportStatus };
