import { Component } from '@angular/core';

@Component({
  selector: 'app-time-edit-template',
  templateUrl: './time-edit-template.component.html',
  styleUrls: ['./time-edit-template.component.scss']
})
export class TimeEditTemplateComponent {
  constructor() {}
}
