import { SettingValueType } from 'chronos-core-client';
import { ListValue } from 'chronos-shared';

export interface SettingModel {
  settingId: number;
  settingName: string;
  description: string;
  defaultValue: string | string[];
  settingValueType: SettingValueType;
  boolValue?: boolean;
  dateTimeValue?: string;
  enumValue?: string;
  intValue?: number;
  stringValue?: string;
  timeSpanValue?: string;
  enumerationValues?: ListValue[];
  enumValueMultiselect?: any;
  settingEditEnabled?: boolean;
  controlName?: string;
}

export enum SettingFieldType {
  BooleanType = 'Boolean',
  TimeSpanType = 'TimeSpan',
  IntegerType = 'Integer',
  StringType = 'String',
  DateTimeType = 'DateTime',
  EnumType = 'Enum',
  EnumMultiSelectType = 'EnumMultiSelect'
}

export interface HierarchyLevel {
  isActive: boolean;
  name: string;
}

export interface HierarchySetting {
  name: string;
  type: string;
  parentKey: string;
  isParentObjectClick: boolean;
  isActive: boolean;
}

export interface FormStatus {
  status: boolean;
  settingId: number;
}
