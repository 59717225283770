import { Injectable } from '@angular/core';
import { ActiveOrderDsService, ConsumedMaterialDsService, ProductionOrderDsService, ShiftReportDsService } from '@app/core/data-services';
import { ProductionOrderListService } from '@app/core/global-state';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MachineSchedule } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  constructor(
    private productionOrderListService: ProductionOrderListService,
    private productionOrderDsService: ProductionOrderDsService,
    private activeOrderDsService: ActiveOrderDsService,
    private shiftReportDsService: ShiftReportDsService,
    private consumedMaterialDsService: ConsumedMaterialDsService
  ) {}

  public resetToInitialAppState(): Observable<MachineSchedule[]> {
    return this.productionOrderDsService.resetProductionOrders().pipe(
      tap(() => {
        this.resetStores();
      }),
      switchMap(() => this.productionOrderDsService.getProductionOrderListByShift(false))
    );
  }

  private resetStores(): void {
    this.productionOrderListService.resetProductionOrdersStore();
    this.activeOrderDsService.resetActiveOrder();
    this.consumedMaterialDsService.resetConsumedMaterial();
    this.shiftReportDsService.resetShiftReportStore();
  }
}
