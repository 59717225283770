import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ShiftReportHeaderKpisState, ShiftReportHeaderKpisStore } from './shift-report-header-kpis.store';
import { KpiValue } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportHeaderKpisQuery extends QueryEntity<ShiftReportHeaderKpisState, KpiValue> {
  constructor(protected store: ShiftReportHeaderKpisStore) {
    super(store);
  }

  public shiftReportHeaderKpis$ = this.selectAll();
}
