import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { MaterialAvailableAtLocation, PrimaryMaterial, MountPrimaryContainerResponse } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { PrimaryMountedMaterialStore } from './primary-mounted-material.store';
import { PrimaryMaterialDsService } from '@app/core/data-services/primary-material-ds';
import { PrimaryMountedMaterialQuery } from './primary-mounted-material.query';

@Injectable()
export class PrimaryMountedMaterialService {
  constructor(
    private primaryMountedMaterialStore: PrimaryMountedMaterialStore,
    private primaryMaterialDsService: PrimaryMaterialDsService,
    private primaryMountedMaterialQuery: PrimaryMountedMaterialQuery
  ) {}

  private activeMaterialBlockId: number;

  public getPrimaryMountedMaterial(): void {
    this.primaryMaterialDsService.getPrimaryMaterialBlocks().subscribe((primaryMaterial) => {
      this.updatePrimaryMountedMaterial(primaryMaterial);
    });
  }

  public getAvailableContainers(productionOrderId?: number, isOverviewTab?: boolean): Observable<MaterialAvailableAtLocation> {
    return this.primaryMaterialDsService.getMaterialListAtMachine(productionOrderId, isOverviewTab);
  }

  public getWarehouseContainers(): Observable<MaterialAvailableAtLocation> {
    return this.primaryMaterialDsService.getMaterialListAtWarehouse();
  }

  @transaction()
  public updatePrimaryMountedMaterial(primaryMountedMaterial: PrimaryMaterial): void {
    this.primaryMountedMaterialStore.setLoading(true);
    this.primaryMountedMaterialStore.update(primaryMountedMaterial);
    this.primaryMountedMaterialStore.setLoading(false);
  }

  public mountContainerBySscc(sscc: string, productionOrderId: number): Observable<any> {
    this.primaryMountedMaterialQuery.getActiveMaterialBlock().subscribe((materialBlock) => {
      this.activeMaterialBlockId = materialBlock.id;
    });
    return this.primaryMaterialDsService.mountContainerBySSCC(sscc, productionOrderId, this.activeMaterialBlockId);
  }

  public mountPrimaryContainerBySsccAndProductionOrder(
    identificationCode: string,
    productionOrderId: number
  ): Observable<MountPrimaryContainerResponse> {
    return this.primaryMaterialDsService.mountPrimaryContainerByContainerAndProductionOrder(identificationCode, productionOrderId);
  }

  public mountPrimaryContainerByIdAndProductionOrder(
    containerId: number,
    productionOrderId: number
  ): Observable<MountPrimaryContainerResponse> {
    return this.primaryMaterialDsService.mountPrimaryContainerByIdAndProductionOrder(containerId, productionOrderId);
  }

  public replaceContainer(mountedMaterialId: number, virtualContainerId: number, newContainerId: number): Observable<null> {
    return this.primaryMaterialDsService.replaceContainer(mountedMaterialId, virtualContainerId, newContainerId);
  }
}
