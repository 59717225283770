import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ActiveEmployeeRegistrationData } from 'chronos-core-client';
import { EmployeeFilters } from './employee.model';

export interface EmployeeState extends ActiveEmployeeRegistrationData {
  filters: EmployeeFilters;
}

const initialState = {
  filters: {
    textQuery: ''
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'employees' })
export class EmployeeStore extends Store<EmployeeState> {
  constructor() {
    super(initialState);
  }

  public updateFilters(filters: EmployeeFilters): void {
    this.update({ filters });
  }

  public resetFilters(): void {
    this.update(initialState);
  }
}
