<div class="overview page-padding">
  <header>
    <h1 class="page-header">
      {{ 'FINISH_PHASE.OVERVIEW_HEADER' | translate }}
    </h1>
  </header>
  <div class="overview-columns">
    <div class="p-grid">
      <div class="p-col">
        <app-kpi-column
          [kpis]="kpiGroups[KPI_CATEGORY.QUANTITY]"
          [title]="formatColumnTitle(KPI_CATEGORY.QUANTITY) | translate"
          [kpiColumn]="KPI_CATEGORY.QUANTITY"
        ></app-kpi-column>
      </div>
      <div class="p-col">
        <app-kpi-column
          [kpis]="kpiGroups[KPI_CATEGORY.TIME]"
          [title]="formatColumnTitle(KPI_CATEGORY.TIME) | translate"
          [kpiColumn]="KPI_CATEGORY.TIME"
        ></app-kpi-column>
      </div>
      <div class="p-col">
        <app-kpi-column
          [kpis]="kpiGroups[KPI_CATEGORY.SPEED]"
          [title]="formatColumnTitle(KPI_CATEGORY.SPEED) | translate"
          [kpiColumn]="KPI_CATEGORY.SPEED"
        ></app-kpi-column>
      </div>
      <div class="p-col">
        <app-kpi-column
          [kpis]="kpiGroups[KPI_CATEGORY.PROJECT]"
          [title]="formatColumnTitle(KPI_CATEGORY.PROJECT) | translate"
          [kpiColumn]="KPI_CATEGORY.PROJECT"
        ></app-kpi-column>
      </div>
    </div>
  </div>
  <div *ngIf="isAutomatedMode$ | async">
    <lib-machine-chart
      [workCenterId]="workCenterId$ | async"
      [start]="'MACHINE_CHART.START' | translate"
      [rest]="'MACHINE_CHART.REST' | translate"
      [chartMode]="chartMode"
    ></lib-machine-chart>
  </div>
</div>
