import { Component, OnDestroy, OnInit } from '@angular/core';
import { DismountContainerModalComponent } from '@app/modules/finish-phase/containers/overview/components/dismount-container-modal';
import { DummyPalletCreatingFormComponent } from '@app/modules/mounting/containers/tab-primary/components/dummy-pallet-creating-form/dummy-pallet-creating-form.component';
import { PrimaryMaterialConsumptionModalService } from '@app/modules/mounting/containers/tab-primary/components/primary-material-consumption-modal/primary-material-consumption-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ArticleDescription, PrimaryMaterialMounted } from 'chronos-core-client';
import { DismountingPalletInformationMapper, DummyMapper, MountedMaterial, SortDirection } from 'chronos-shared';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { MountPalletItemService } from '@app/modules/mounting/services';
import { DismountDummyPalletModalComponent } from '@app/shared/components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';
import { ModalMainMaterialComponent } from '@app/shared/components/modal-main-material/modal-main-material.component';

@Component({
  selector: 'app-primary-material-consumption-modal',
  templateUrl: './primary-material-consumption-modal.component.html',
  styleUrls: ['./primary-material-consumption-modal.component.scss'],
  providers: [DialogService]
})
export class PrimaryMaterialConsumptionModalComponent implements OnInit, OnDestroy {
  public palletSequence$: Observable<SortDirection>;
  public consumedMaterial$: Observable<PrimaryMaterialMounted[]>;
  public externalWorkCenterId$: Observable<string>;
  public activeMaterial: ArticleDescription;

  private subscriptions = new Subscription();

  constructor(
    private primaryMaterialConsumptionModalService: PrimaryMaterialConsumptionModalService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private mountPalletItemService: MountPalletItemService
  ) {}

  public ngOnInit(): void {
    this.palletSequence$ = this.primaryMaterialConsumptionModalService.getPalletSequence();
    this.consumedMaterial$ = this.primaryMaterialConsumptionModalService.getConsumedMaterial();
    this.externalWorkCenterId$ = this.primaryMaterialConsumptionModalService.getExternalWorkCenterId();

    this.subscriptions.add(
      this.primaryMaterialConsumptionModalService.getActiveMaterialArticle().subscribe((material) => (this.activeMaterial = material))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public close(): void {
    this.ref.close();
  }

  public onInfoButtonClick(consumedContainer: MountedMaterial): void {
    const dummyContainer = {
      ...consumedContainer,
      article: this.activeMaterial
    };

    this.dialogService.open(DummyPalletCreatingFormComponent, {
      data: {
        dummyPallet: DummyMapper.fromMountedMaterial(dummyContainer),
        workCenterId: consumedContainer.mountedWorkCenterId
      },
      header: this.translateService.instant('MOUNTING.CHANGE_DUMMY_PALLET')
    });
  }

  public onReplaceButtonClick(consumedContainer: PrimaryMaterialMounted): void {
    const tabName = 'Primary';
    const submitLabel = 'MOUNTING.MOUNT';
    const machineLocationDialog = this.dialogService.open(ModalMainMaterialComponent, {
      header: this.translateService.instant('MOUNTING.MACHINE_LOCATION'),
      data: {
        tabName,
        mountedMaterialId: consumedContainer.mountedMaterialId,
        dummyContainerId: consumedContainer.container.containerId,
        submitLabel
      }
    });

    machineLocationDialog.onClose.subscribe((mounted: boolean) => {
      if (mounted) {
        this.primaryMaterialConsumptionModalService.renewConsumedMaterial().subscribe();
      }
    });
  }

  public onDummyPalletShowChange(showOnlyDummy: { checked: boolean }): void {
    this.primaryMaterialConsumptionModalService.updateFilters(showOnlyDummy.checked);
  }

  public onPalletOrderChange(palletSequence: SortDirection): void {
    this.primaryMaterialConsumptionModalService.updateSorting(palletSequence);
  }

  public onDummyDismountIconClick(material: PrimaryMaterialMounted): void {
    this.dialogService
      .open(DismountDummyPalletModalComponent, {
        header: this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY_PALLET'),
        data: {
          containerId: material.container.containerId,
          dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(material, this.activeMaterial)
        }
      })
      .onClose.pipe(
        filter((isSubmit) => !!isSubmit),
        tap(() => {
          this.mountPalletItemService.onEndAnimation(material.mountedMaterialId);
        }),
        switchMap(() => this.consumedMaterial$)
      )
      .subscribe();
  }

  public onPalletDismountIconClick(material: PrimaryMaterialMounted) {
    this.dialogService
      .open(DismountContainerModalComponent, {
        header: this.translateService.instant('MOUNTING.DISMOUNTING'),
        data: {
          containerId: material.container.containerId,
          articleId: this.activeMaterial.id,
          dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(material, this.activeMaterial)
        }
      })
      .onClose.pipe(
        filter((isSubmit) => !!isSubmit),
        tap(() => {
          this.mountPalletItemService.onEndAnimation(material.mountedMaterialId);
        }),
        switchMap(() => this.consumedMaterial$)
      )
      .subscribe();
  }
}
