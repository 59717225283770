import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment-mini';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { isNil } from 'ramda';

export const TIME_INPUT_PROVIDERS: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimeInputComponent),
  multi: true
};

export const TIME_INPUT_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => TimeInputComponent),
  multi: true
};

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [TIME_INPUT_PROVIDERS, TIME_INPUT_VALIDATORS]
})
export class TimeInputComponent implements OnInit, ControlValueAccessor, Validator {
  private _startTime: Date;

  @Input()
  public get startTime(): Date {
    return this._startTime;
  }

  public set startTime(value: Date) {
    this._startTime = value;
    this.splitTimeSubject.next(value);
  }

  @Input()
  public endTime: Date;

  @Input()
  public showControls = true;

  @Input()
  public bypassDayCorrection = true;

  @Input()
  public disabled = false;

  @Output()
  public inputChanged: EventEmitter<Date> = new EventEmitter();

  @ViewChild('hours', { read: ElementRef }) public hourRef: ElementRef;
  @ViewChild('minutes', { read: ElementRef }) public minuteRef: ElementRef;

  public timeDefineFieldStyle = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invalid: { color: '#c70000', 'border-color': '#c70000' }
  };

  private onChange: (result: Date) => void;
  private onTouched: () => void;

  private splitTimeSubject = new BehaviorSubject<Date>(null);
  public splitTime$ = this.splitTimeSubject.asObservable();

  public ngOnInit(): void {
    this.splitTime$.subscribe((splitTime) => {
      this.emitInputChange(splitTime);
      if (!isNil(splitTime) && this.onChange) {
        this.onChange(splitTime);
      }
    });
  }

  private emitInputChange(splitTime) {
    this.inputChanged.emit(splitTime);
  }

  public increaseSplitTimeByMinute(splitTime: Date): void {
    this.changeSplitTimeByMinutes(splitTime, 1);
  }

  public decreaseSplitTimeByMinute(splitTime: Date): void {
    this.changeSplitTimeByMinutes(splitTime, -1);
  }

  public isTimeCorrect(splitTime: Date): boolean {
    return this.getTimeDifferenceInMinutes(splitTime, this.startTime) > 0 && this.getTimeDifferenceInMinutes(this.endTime, splitTime) > 0;
  }

  public changeSplitTimeByMinutes(splitTime: Date, minuteChange: number): void {
    const changedTime = moment(splitTime).add(minuteChange, 'minutes').toDate();
    this.splitTimeSubject.next(changedTime);
  }

  public onInputBlur(): void {
    setTimeout(() => {
      this.setInputValue(this.hourRef);
      this.setInputValue(this.minuteRef);
    }, 0);

    if (this.onTouched) {
      this.onTouched();
    }
  }

  private setInputValue(elementRef: ElementRef): void {
    if (elementRef) {
      const input = elementRef.nativeElement.querySelector('input');
      if (Number(input.value) < 10 && String(input.value).length < 2) {
        input.value = `0${input.value}`;
      }
    }
  }

  public setSplitTime(splitTime: Date, timeValue: number, unitId: 'm' | 'h'): void {
    const changedSplitTime = this.setTimeUnit(splitTime, timeValue, unitId);
    this.splitTimeSubject.next(changedSplitTime);

    if (this.bypassDayCorrection) {
      return;
    }

    const splitTimeWithCorrectDay = this.getDateDay(changedSplitTime);
    this.splitTimeSubject.next(splitTimeWithCorrectDay);
  }

  // Util functions
  public getTimeDifferenceInMinutes(greaterTime: Date, lowerTime: Date): number {
    return moment(greaterTime).diff(lowerTime, 'minutes');
  }

  public getDateDay(splitTime: Date): Date {
    if (!this.boundaryTimeExists()) {
      return this.setTimeUnit(splitTime, moment().days(), 'd');
    }

    return this.getDateFromBoundaries(splitTime);
  }

  private boundaryTimeExists(): boolean {
    return !!this.startTime && !!this.endTime;
  }

  private getDateFromBoundaries(splitTime: Date): Date {
    const timeFormat = 'HH:mm';
    if (moment(splitTime).format(timeFormat) > moment(this.endTime).format(timeFormat)) {
      return this.setTimeUnit(splitTime, moment(this.startTime).days(), 'd');
    }

    return this.setTimeUnit(splitTime, moment(this.endTime).days(), 'd');
  }

  public setTimeUnit(splitTime: Date, timeValue: number, unitId: 'm' | 'h' | 'd'): Date {
    return moment(splitTime).set(unitId, timeValue).toDate();
  }

  // Custom component interfaces implementation
  public writeValue(value: any): void {
    if (!isNil(value)) {
      this.splitTimeSubject.next(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate({ value }: UntypedFormControl): ValidationErrors | null {
    const invalid = !this.isTimeCorrect(value);
    return (
      invalid && {
        timeIsNotCorrect: true
      }
    );
  }

  public getTimeInputStyles(splitTime: Date): { [key: string]: string } {
    if (this.boundaryTimeExists() && !this.isTimeCorrect(splitTime)) {
      return this.timeDefineFieldStyle.invalid;
    }
  }
}
