<ng-container *ngIf="informationData$ | async as documentInfo">
  <section class="information-section">
    <app-remark-container [sectionName]="'DOCUMENTS.PRODUCTION_ORDER'">
      <app-remark-list
        [remarkList]="documentInfo.productionOrder.remarks"
        [externalOperationType]="documentInfo.productionOrder.externalOperationType"
      >
        <ng-container
          *appRemarkAction="let remark"
          [ngTemplateOutlet]="remarkAction"
          [ngTemplateOutletContext]="{ $implicit: remark, model: documentInfo.productionOrder }"
        ></ng-container>
      </app-remark-list>
    </app-remark-container>

    <app-remark-container [sectionName]="'DOCUMENTS.PRODUCTION_STEPS'">
      <app-remark-list
        *ngFor="let step of documentInfo.productionSteps"
        [productionType]="step.operationTypeName"
        [externalOperationType]="step.externalOperationType"
        [remarkList]="step.remarks"
      >
        <ng-container
          *appRemarkAction="let remark"
          [ngTemplateOutlet]="remarkAction"
          [ngTemplateOutletContext]="{ $implicit: remark, model: step }"
        ></ng-container>
      </app-remark-list>
    </app-remark-container>
  </section>
</ng-container>

<ng-template #remarkAction let-remark let-step="model">
  <ng-container *ngIf="isRemarkEditable(remark, step)">
    <lib-button (clicked)="editRemarkInfo(remark, step)" [label]="'DOCUMENTS.EDIT'" class="edit-button"></lib-button>
  </ng-container>
</ng-template>
