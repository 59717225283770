import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ShiftReportOpenTask } from '@app/core/global-state';

export type ShiftReportOpenTasksState = EntityState<ShiftReportOpenTask>;

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'shift-report-open-tasks', idKey: 'type', resettable: true })
export class ShiftReportOpenTasksStore extends EntityStore<ShiftReportOpenTasksState> {
  constructor() {
    super();
  }
}
