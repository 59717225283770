import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormComponentsModule } from '../form-components';
import { TraceabilityComponent } from './containers/traceability/traceability.component';
import { ConsumptionbatchlistComponent } from './containers/consumption-batch-list/consumption-batch-list.component';
import { OrderbatchlistComponent } from './containers/order-batch-list/order-batch-list.component';
import { ProductionorderlistComponent } from './containers/production-order-list/production-order-list.component';
import { ProductionmateriallistComponent } from './containers/production-material-list/production-material-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { TableModule } from 'primeng/table';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    TraceabilityComponent,
    ConsumptionbatchlistComponent,
    OrderbatchlistComponent,
    ProductionmateriallistComponent,
    ProductionorderlistComponent
  ],
  exports: [
    TraceabilityComponent,
    ConsumptionbatchlistComponent,
    OrderbatchlistComponent,
    ProductionmateriallistComponent,
    ProductionorderlistComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    FormComponentsModule,
    MatTableModule,
    MatExpansionModule,
    TableModule,
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TraceabilityModule {}
