<label class="container-search-bar">
  <input
    #searchInput
    type="text"
    class="container-search-bar__input"
    [(ngModel)]="containerSearchFilter"
    (input)="filterOutputContainersList()"
    [placeholder]="searchBarPlaceholder | translate"
    pInputText
  />
  <i class="container-search-bar__search icon icon-search"></i>
  <i *ngIf="containerSearchFilter" (click)="clearFilter()" class="container-search-bar__clear icon icon-close"></i>
</label>
