import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DocumentItem } from 'chronos-core-client';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-nc-defective-items-modal',
  templateUrl: './nc-defective-items-modal.component.html',
  styleUrls: ['./nc-defective-items-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NcDefectiveItemsModalComponent implements OnInit {
  public defectiveItems: DocumentItem[];

  constructor(private config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.defectiveItems = this.config.data.defectiveItems;
  }
}
