export interface TaskFilters {
  downtimeCodeAssignation?: string;
  taskType?: string;
  productionOrder?: string;
  textQuery?: string;
}

export const defaultDropdownValue = 'TASK_LIST.ALL';

export enum DowntimeCodeOptions {
  All = 'all',
  Open = 'open',
  Ok = 'ok'
}
