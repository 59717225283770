import { Component } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { DowntimeCodeOptions, TaskCategoryQuery, TaskService } from '@app/core/global-state';
import { NavigationRouteService } from '@app/core/services';
import { MainMenuService } from '@app/core/services/main-menu/main-menu.service';
import { nav } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { TaskType } from '@app/shared/models';
import { AppSettingsQuery, LiveUrlConfig, ModalConfirmComponent } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';

export interface MenuItem {
  id?: string;
  icon: string;
  tooltip: string;
  link?: (() => string) | string;
  showBadge?: boolean;
  counter?: Observable<number>;
  onClick?: () => void;
  isActiveMatchOptions?: IsActiveMatchOptions;
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  private readonly DB_RESET_ITEM_ID = 'DB_RESET_ITEM_ID';

  private menuItems: MenuItem[] = [
    {
      id: this.liveUrlConfig.id,
      tooltip: 'MAIN_MENU.DASHBOARD',
      icon: 'icon-dashboard',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => window.open(this.liveUrlConfig?.url, '_blank')
    },
    {
      link: nav.routes.ordersList,
      tooltip: 'MAIN_MENU.ORDER_LIST',
      icon: 'icon-order-list',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([nav.routes.ordersList])
    },
    {
      link: () => this.navigationRouteService.getNavigationRoute(),
      tooltip: 'MAIN_MENU.ACTIVE_ORDER',
      icon: 'icon-active-order',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([this.navigationRouteService.getNavigationRoute()])
    },
    {
      link: nav.routes.mounting,
      tooltip: 'MAIN_MENU.MOUNTING',
      icon: 'icon-mounting',
      isActiveMatchOptions: { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([this.navigationRouteService.getActiveMountingRoute()])
    },
    {
      link: nav.routes.tasks,
      tooltip: 'MAIN_MENU.OPEN_TASKS',
      icon: 'icon-open-tasks',
      showBadge: true,
      counter: this.taskCategoryQuery.taskCounter$,
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => {
        this.navigateToTasks();
      }
    },
    {
      link: nav.routes.documents,
      tooltip: 'MAIN_MENU.DOCUMENTS',
      icon: 'icon-documents',
      isActiveMatchOptions: { paths: 'subset', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([this.navigationRouteService.getActiveDocumentRoute()])
    },
    {
      link: nav.routes.shiftReport,
      tooltip: 'MAIN_MENU.SHIFT_REPORT',
      icon: 'icon-shift-report',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([nav.routes.shiftReport])
    },
    {
      link: nav.routes.qaLog,
      tooltip: 'MAIN_MENU.QA_LOG',
      icon: 'icon-qa-log',
      isActiveMatchOptions: { paths: 'subset', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([nav.routes.qaLog])
    },
    {
      link: nav.routes.approve,
      tooltip: 'MAIN_MENU.APPROVE',
      icon: 'icon-checked-solid',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([nav.routes.approve])
    },
    {
      link: nav.routes.operationSupport,
      tooltip: 'MAIN_MENU.OPERATION_SUPPORT',
      icon: 'pi pi-sitemap icon',
      isActiveMatchOptions: { paths: 'subset', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([nav.routes.operationSupport])
    },
    {
      link: nav.routes.resourceConfiguration,
      tooltip: 'MAIN_MENU.SETTINGS',
      icon: 'icon-settings',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([nav.routes.resourceConfiguration])
    },
    {
      tooltip: 'MAIN_MENU.HELP',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      icon: 'icon-info-solid'
    },
    {
      id: this.DB_RESET_ITEM_ID,
      tooltip: 'MAIN_MENU.RESET_DB',
      icon: 'icon-power-off',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => {
        this.onResetButtonClick();
      }
    }
  ];

  public isLoading = false;
  public menuList$ = combineLatest([
    this.appSettingsQuery.isQaHistoryTabEnabled$,
    this.appSettingsQuery.isApprovalReportEnabled$,
    this.appSettingsQuery.isDbResetEnabled$,
    this.appSettingsQuery.isEnableResourceConfigurationReadOnly$
  ]).pipe(
    map(([isQaHistoryEnabled, isApprovalReportEnabled, isDbResetEnabled, isResourceSettingEnabled]) =>
      this.manageMenuItemList(isQaHistoryEnabled, isApprovalReportEnabled, isDbResetEnabled, isResourceSettingEnabled)
    )
  );

  constructor(
    private mainMenuService: MainMenuService,
    public navigationRouteService: NavigationRouteService,
    public taskCategoryQuery: TaskCategoryQuery,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private taskService: TaskService,
    private appSettingsQuery: AppSettingsQuery,
    private liveUrlConfig: LiveUrlConfig,
    private router: Router
  ) {}

  public onResetButtonClick(): void {
    this.dialogService
      .open(ModalConfirmComponent, {
        header: this.translateService.instant('MAIN_MENU.CONFIRMATION_MODAL_HEADER'),
        data: {
          question: this.translateService.instant('MAIN_MENU.CONFIRMATION_MODAL_MESSAGE'),
          acceptable: true
        }
      })
      .onClose.pipe(filter((accepted) => !!accepted))
      .subscribe(() => {
        this.resetAppState();
      });
  }

  private resetAppState(): void {
    this.isLoading = true;
    this.mainMenuService
      .resetToInitialAppState()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => this.router.navigate([nav.routes.ordersList]));
  }

  public navigateToTasks(): void {
    this.taskService.updateFilters({
      downtimeCodeAssignation: DowntimeCodeOptions.Open,
      taskType: TaskType.All,
      productionOrder: 'All',
      textQuery: ''
    });

    this.router.navigate([nav.routes.tasks]);
  }

  public isMenuItemActive(menuItem: MenuItem): boolean {
    if (!menuItem.link) {
      return false;
    }

    const link = typeof menuItem.link === 'function' ? menuItem.link() : menuItem.link;

    return this.router.isActive(link, menuItem.isActiveMatchOptions);
  }

  private manageMenuItemList(
    isQaHistoryEnabled: boolean,
    isApprovalReportEnabled: boolean,
    isDbResetEnabled: boolean,
    isResourceSettingEnabled: boolean
  ): MenuItem[] {
    let menuItemList = this.menuItems;
    if (!isQaHistoryEnabled) {
      menuItemList = menuItemList.filter((item) => item.link !== nav.routes.qaLog);
    }
    if (!isApprovalReportEnabled) {
      menuItemList = menuItemList.filter((item) => item.link !== nav.routes.approve);
    }
    if (!this.liveUrlConfig?.url) {
      menuItemList = menuItemList.filter((item) => item.id !== this.liveUrlConfig.id);
    }
    if (!isDbResetEnabled) {
      menuItemList = menuItemList.filter((item) => item.id !== this.DB_RESET_ITEM_ID);
    }
    if (!this.appSettingsQuery.isTraceabilityDisplayEnabled()) {
      menuItemList = menuItemList.filter((item) => item.link !== nav.routes.operationSupport);
    }
    if (!isResourceSettingEnabled) {
      menuItemList = menuItemList.filter((item) => item.link !== nav.routes.resourceConfiguration);
    }
    return menuItemList;
  }
}
