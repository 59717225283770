<ng-container *ngIf="toolSetupInfo">
  <div
    class="p-grid p-nogutter parameter-row"
    [ngClass]="{
      'error-row': punchingPressureState === state.Incorrect,
      'warning-row': punchingPressureState === state.NotSet
    }"
  >
    <label class="p-col-3 parameter-label">{{ 'SETUP_PARAMS.PUNCHING_PRESSURE' | translate }}</label>
    <span class="p-col-8">
      <lib-input-number
        [inputStyle]="{ 'min-width': '350px' }"
        [(ngModel)]="toolSetupInfo.punchingPressure"
        (ngModelChange)="savePunchingPressure(toolSetupInfo.punchingPressure)"
        [required]="true"
      ></lib-input-number>
    </span>
    <span class="p-col-1 parameter-row__icon">
      <i [ngClass]="punchingPressureState === state.Incorrect ? 'icon-error-solid error-icon' : 'icon-info-outline info-icon'"></i>
    </span>
  </div>

  <div
    class="p-grid p-nogutter parameter-row"
    [ngClass]="{
      'error-row': serialNumberState === state.Incorrect,
      'warning-row': serialNumberState === state.NotSet,
      'dummy-row': serialNumberState === state.Dummy
    }"
  >
    <span class="p-col-3 parameter-label">
      <label>{{ 'SETUP_PARAMS.SERIAL_NUMBER' | translate }}</label>
      <i class="icon-mounting parameter-row__icon"></i>
    </span>
    <span class="p-col-9 parameter-row__container">
      <input
        [(ngModel)]="toolSetupInfo.identificationCode"
        [disabled]="serialNumberState === state.Correct || serialNumberState === state.Dummy"
        class="parameter-row__container_input"
        [placeholder]="'SETUP_PARAMS.TOOL_SCAN_PLACEHOLDER' | translate"
        type="text"
        pInputText
      />
      <i
        *ngIf="serialNumberState === state.NotSet || serialNumberState === state.Incorrect"
        class="icon-container parameter-row__container_icon"
        (click)="openToolStockModal()"
      ></i>
      <i
        *ngIf="serialNumberState === state.Correct"
        class="icon-container parameter-row__container_icon parameter-row__container_icon--disabled"
      ></i>
      <app-edit-button
        *ngIf="serialNumberState === state.Dummy"
        class="parameter-row__edit-button"
        (buttonClicked)="updateDummyTool()"
      ></app-edit-button>
      <lib-button
        [label]="'MOUNTING.MOUNT'"
        (clicked)="mountTool()"
        [disabled]="serialNumberState === state.Correct || serialNumberState === state.Dummy"
      ></lib-button>
    </span>
    <span class="p-col-3"></span>
    <span class="p-col-6">
      <div *ngIf="serialNumberState === state.Dummy; else notDummyTool" class="dummy_label">
        <i class="icon-warning-solid dummy_label__icon"></i>
        {{ 'MOUNTING.DUMMY' | translate }}
      </div>
      <ng-template #notDummyTool>
        <div
          class="dummy_link"
          [ngClass]="{ 'dummy_link--disabled': serialNumberState === state.Correct }"
          (click)="serialNumberState === state.Correct || createDummyTool()"
        >
          <i class="pi pi-plus-circle dummy_link__icon"></i>
          {{ 'MOUNTING.DUMMY' | translate }}
        </div>
      </ng-template>
    </span>
    <span class="p-col-3"></span>
  </div>
</ng-container>
