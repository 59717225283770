import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NonConformanceModel } from 'chronos-core-client';

@Component({
  selector: 'app-nc-info',
  templateUrl: './nc-info.component.html',
  styleUrls: ['./nc-info.component.scss']
})
export class NcInfoComponent {
  @Input() public nonConformanceModel: NonConformanceModel;

  @Input() public isCreateNCMode: boolean;

  @Output() public internalMemoUpdated = new EventEmitter<string>();

  public internalMemoInfo: string;

  public onInfoUpdated(value: string): void {
    this.internalMemoUpdated.emit(value);
  }
}
