/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MachineSchedule } from '../models/machine-schedule';
import { ActiveProductionOrder } from '../models/active-production-order';
import { ShiftFilter } from '../models/shift-filter';
@Injectable({
  providedIn: 'root'
})
class MachineScheduleService extends __BaseService {
  static readonly getMachineSchedulesPath = '/api/v1/MachineSchedule/{workCenterId}';
  static readonly getAllPath = '/api/v1/MachineSchedule/{workCenterId}/ShowAll';
  static readonly getFilteredPath = '/api/v1/MachineSchedule/{workCenterId}/Filtered';
  static readonly getActiveOrderPath = '/api/v1/MachineSchedule/{workCenterId}/ActiveOrder';
  static readonly getShiftFiltersPath = '/api/v1/MachineSchedule/{workCenterId}/ShiftFilters';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param workCenterId undefined
   */
  getMachineSchedulesResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<MachineSchedule>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/MachineSchedule/${encodeURIComponent(String(workCenterId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MachineSchedule>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getMachineSchedules(workCenterId: number): __Observable<Array<MachineSchedule>> {
    return this.getMachineSchedulesResponse(workCenterId).pipe(__map((_r) => _r.body as Array<MachineSchedule>));
  }

  /**
   * @param workCenterId undefined
   */
  getAllResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<MachineSchedule>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/MachineSchedule/${encodeURIComponent(String(workCenterId))}/ShowAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MachineSchedule>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getAll(workCenterId: number): __Observable<Array<MachineSchedule>> {
    return this.getAllResponse(workCenterId).pipe(__map((_r) => _r.body as Array<MachineSchedule>));
  }

  /**
   * @param params The `MachineScheduleService.GetFilteredParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `ShowFinishedOrders`:
   *
   * - `ShiftFilterId`:
   *
   * - `CalculateSecondaryMachineLocationKpi`:
   *
   * - `CalculatePrimaryMachineLocationKpi`:
   */
  getFilteredResponse(params: MachineScheduleService.GetFilteredParams): __Observable<__StrictHttpResponse<Array<MachineSchedule>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ShowFinishedOrders != null) __params = __params.set('ShowFinishedOrders', params.ShowFinishedOrders.toString());
    if (params.ShiftFilterId != null) __params = __params.set('ShiftFilterId', params.ShiftFilterId.toString());
    if (params.CalculateSecondaryMachineLocationKpi != null)
      __params = __params.set('CalculateSecondaryMachineLocationKpi', params.CalculateSecondaryMachineLocationKpi.toString());
    if (params.CalculatePrimaryMachineLocationKpi != null)
      __params = __params.set('CalculatePrimaryMachineLocationKpi', params.CalculatePrimaryMachineLocationKpi.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/MachineSchedule/${encodeURIComponent(String(params.workCenterId))}/Filtered`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MachineSchedule>>;
      })
    );
  }
  /**
   * @param params The `MachineScheduleService.GetFilteredParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `ShowFinishedOrders`:
   *
   * - `ShiftFilterId`:
   *
   * - `CalculateSecondaryMachineLocationKpi`:
   *
   * - `CalculatePrimaryMachineLocationKpi`:
   */
  getFiltered(params: MachineScheduleService.GetFilteredParams): __Observable<Array<MachineSchedule>> {
    return this.getFilteredResponse(params).pipe(__map((_r) => _r.body as Array<MachineSchedule>));
  }

  /**
   * @param workCenterId undefined
   */
  getActiveOrderResponse(workCenterId: number): __Observable<__StrictHttpResponse<ActiveProductionOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/MachineSchedule/${encodeURIComponent(String(workCenterId))}/ActiveOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveProductionOrder>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getActiveOrder(workCenterId: number): __Observable<ActiveProductionOrder> {
    return this.getActiveOrderResponse(workCenterId).pipe(__map((_r) => _r.body as ActiveProductionOrder));
  }

  /**
   * @param workCenterId undefined
   */
  getShiftFiltersResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<ShiftFilter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/MachineSchedule/${encodeURIComponent(String(workCenterId))}/ShiftFilters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShiftFilter>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getShiftFilters(workCenterId: number): __Observable<Array<ShiftFilter>> {
    return this.getShiftFiltersResponse(workCenterId).pipe(__map((_r) => _r.body as Array<ShiftFilter>));
  }
}

module MachineScheduleService {
  /**
   * Parameters for GetFiltered
   */
  export interface GetFilteredParams {
    workCenterId: number;
    ShowFinishedOrders?: boolean;
    ShiftFilterId?: null | number;
    CalculateSecondaryMachineLocationKpi?: null | boolean;
    CalculatePrimaryMachineLocationKpi?: null | boolean;
  }
}

export { MachineScheduleService };
