<aside class="right-sidebar p-col-fixed">
  <div class="right-sidebar__wrapper p-grid p-dir-col p-nogutter">
    <div class="p-col">
      <app-active-order></app-active-order>
    </div>
    <div class="p-col">
      <app-task-category-list></app-task-category-list>
    </div>
  </div>
</aside>
