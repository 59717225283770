<div class="waste">
  <div class="waste__wrapper">
    <span class="waste__icon"><i class="icon-trash-outline"></i></span>
    <div class="waste__col">
      <div class="waste__label">{{ 'PHASE_HEADER.WASTE' | translate }}</div>
      <div class="waste__value">{{ waste | formatQuantity:false }}</div>
    </div>
  </div>
  <div class="waste__wrapper">
    <span class="waste__icon"><i class="icon-wastepaper-outline"></i></span>
    <div class="waste__col">
      <div class="waste__label">{{ 'PHASE_HEADER.MACULATURE' | translate }}</div>
      <div class="waste__value">{{ maculature | formatQuantity:false }}</div>
    </div>
  </div>
</div>
