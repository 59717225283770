import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { LogService } from '../../../services';

@Component({
  selector: 'lib-pallet-kpi',
  templateUrl: './pallet-kpi.component.html',
  styleUrls: ['./pallet-kpi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalletKpiComponent {
  @Input() public kpiLabel = '';
  @Input() public kpiValue = '';
  @Input() public unit = '';
  @Input() public showInfoIcon = false;
  @Input() public emphasizeText = false;
  @Input() public compact = false;
  @Input() public leftAligned = false;
  @Input() public isSsccCodeShort = false;
  @Input() public isSsccCodeLong = false;
  @Input() public isEditable = false;
  @Input() public textAligned = '';

  @Output() public infoButtonClicked: EventEmitter<null> = new EventEmitter();
  @Output() public kpiValueChanged = new EventEmitter<number>();

  public kpiInfo = 0;

  constructor(private clipboard: Clipboard) {}

  public onInfoButtonClick(): void {
    this.infoButtonClicked.emit();
  }

  public copyToClipboard(): void {
    this.clipboard.copy(this.kpiValue);
    LogService.success('SUCCESS_MESSAGE.SSCC_CODE_TO_CLIPBOARD', { ssccCode: this.kpiValue });
  }

  public onQuantityChange(value: number): void {
    this.kpiValueChanged.emit(value);
  }
}
