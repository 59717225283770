<div class="container-pallet">
  <div class="container-pallet__column">
    <div class="pallet__material">
      <ng-content select="lib-material-status"></ng-content>
    </div>
    <div *ngIf="palletKpi.length" class="pallet__kpi">
      <div class="pallet__kpi-flex">
        <div *ngFor="let kpi of palletKpi" class="pallet__kpi-item">
          <ng-container [ngTemplateOutlet]="kpi"></ng-container>
        </div>
      </div>
      <div class="pallet__kpi-details">
        <ng-content select="lib-dismounting-details"></ng-content>
      </div>
    </div>
    <div *ngFor="let form of palletForm" class="pallet__form">
      <ng-container [ngTemplateOutlet]="form"></ng-container>
    </div>
  </div>
  <ng-container *ngIf="showLabel">
    <div class="container-pallet__column">
      <div class="pallet__label">
        <div class="label__title">{{ textLabel }}</div>
        <div class="label__placeholder"></div>
      </div>
    </div>
  </ng-container>
</div>
