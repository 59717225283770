<div class="details">
  <div (click)="toggleContent()" [ngClass]="{ 'expandable-details-header': isExpandable }" class="details-header">
    <div class="details-header__icon">
      <i
        *ngIf="isExpandable"
        [ngClass]="isExpanded ? 'icon-arrows-right-filled-close' : 'icon-arrow-right-filled-open'"
        class="expandable-details-header__arrow"
      ></i>
    </div>
    <span class="details-header__title">{{ title }}</span>
  </div>
  <div *ngIf="isContentVisible" class="details-container">
    <ng-content></ng-content>
  </div>
</div>
