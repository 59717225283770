import { Quantity } from 'chronos-core-client';

export class MachineData {
  public activeProductionOrderCode?: string;
  public customerName?: string;
  public finishedGoodArticleName?: string;
  public goodQuantity?: Quantity;
  public waste?: Quantity;
  public maculature?: Quantity;
  public salesOrderAndPosition?: string;
  public numberOfStoppers?: Quantity;
  public externalFinishedGoodArticleId?: string;
  public finishedGoodArticleConfigurationId?: string;
  public plannedQuantity?: Quantity;
  public quantityPredecessor?: Quantity;
}

export enum SignalType {
  speed = 'speed',
  machine_run = 'machine_run',
  speed2 = 'speed2',
  wash = 'wash'
}

export enum MetadataProperties {
  workcenterHideSignal = 'WorkcenterHideSignal',
  operationTypeId = 'OperationTypeId',
  workCenterChartYAxisSpace = 'WorkCenterChartYAxisSpace(%)'
}
