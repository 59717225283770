import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToolDsService } from '@app/core/data-services';
import { ScanContainerResponse } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ReduceConsumptionModalService {
  constructor(private toolDsService: ToolDsService) {}

  public dismountSecondaryContainer(
    materialId: number,
    returnQuantity: number,
    shouldPrintLabel: boolean,
    containerId: number,
    runId: number,
    workCenterId?: number
  ): Observable<ScanContainerResponse | null> {
    return this.toolDsService.dismountSecondaryContainer(materialId, shouldPrintLabel, containerId, returnQuantity, runId, workCenterId);
  }
}
