import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import {
  ResourceHeaderComponent,
  ResourceSettingComponent,
  SettingCategoryComponent,
  HierarchyComponent
} from './containers/resource-configuration/components';
import { ConfigurationHistoryComponent } from './components/configuration-history/configuration-history.component';
import { ResourceConfigurationComponent } from './containers/resource-configuration/resource-configuration.component';
import { ResourceConfigurationService } from './services';
import { ImportSettingModalComponent } from './components';

@NgModule({
  declarations: [
    ConfigurationHistoryComponent,
    ResourceSettingComponent,
    ResourceHeaderComponent,
    ResourceConfigurationComponent,
    SettingCategoryComponent,
    HierarchyComponent,
    ImportSettingModalComponent
  ],
  imports: [SharedModule],
  providers: [ResourceConfigurationService]
})
export class ResourceConfigurationModule {}
