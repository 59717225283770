/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WorkCenterGroup } from '../models/work-center-group';
@Injectable({
  providedIn: 'root'
})
class WorkCenterGroupService extends __BaseService {
  static readonly getWorkCenterGroupsPath = '/api/v1/WorkCenterGroup';
  static readonly getWorkCenterGroupsForActiveMachinesPath = '/api/v1/WorkCenterGroup/WorkCenterGroupsForActiveMachines';
  static readonly getWorkCenterGroupPath = '/api/v1/WorkCenterGroup/{workCenterGroupId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }
  getWorkCenterGroupsResponse(): __Observable<__StrictHttpResponse<Array<WorkCenterGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/WorkCenterGroup`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkCenterGroup>>;
      })
    );
  }
  getWorkCenterGroups(): __Observable<Array<WorkCenterGroup>> {
    return this.getWorkCenterGroupsResponse().pipe(__map((_r) => _r.body as Array<WorkCenterGroup>));
  }
  getWorkCenterGroupsForActiveMachinesResponse(): __Observable<__StrictHttpResponse<Array<WorkCenterGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/WorkCenterGroup/WorkCenterGroupsForActiveMachines`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkCenterGroup>>;
      })
    );
  }
  getWorkCenterGroupsForActiveMachines(): __Observable<Array<WorkCenterGroup>> {
    return this.getWorkCenterGroupsForActiveMachinesResponse().pipe(__map((_r) => _r.body as Array<WorkCenterGroup>));
  }

  /**
   * @param workCenterGroupId undefined
   */
  getWorkCenterGroupResponse(workCenterGroupId: number): __Observable<__StrictHttpResponse<WorkCenterGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WorkCenterGroup/${encodeURIComponent(String(workCenterGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkCenterGroup>;
      })
    );
  }
  /**
   * @param workCenterGroupId undefined
   */
  getWorkCenterGroup(workCenterGroupId: number): __Observable<WorkCenterGroup> {
    return this.getWorkCenterGroupResponse(workCenterGroupId).pipe(__map((_r) => _r.body as WorkCenterGroup));
  }
}

module WorkCenterGroupService {}

export { WorkCenterGroupService };
