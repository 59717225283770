/* tslint:disable */
type WasteQuantityMode = 'ReduceGoodQuantity' | 'IncreaseGrossQuantity';
module WasteQuantityMode {
  export const REDUCE_GOOD_QUANTITY: WasteQuantityMode = 'ReduceGoodQuantity';
  export const INCREASE_GROSS_QUANTITY: WasteQuantityMode = 'IncreaseGrossQuantity';
  export function values(): WasteQuantityMode[] {
    return [REDUCE_GOOD_QUANTITY, INCREASE_GROSS_QUANTITY];
  }
}

export { WasteQuantityMode };
