import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { splitEvery } from 'ramda';
import { ListItemDirective } from '@app/shared/directives/list-item/list-item.directive';
import { ActiveOrderDsService } from '@app/core/data-services';
import { QaHistoryService } from '@app/modules/qa-history/services/qa-history/qa-history.service';

@Component({
  selector: 'app-scrollable-sidebar',
  templateUrl: './scrollable-sidebar.component.html',
  styleUrls: ['./scrollable-sidebar.component.scss']
})
export class ScrollableSidebarComponent implements OnChanges {
  private readonly PAGE_SIZE = 4;
  private activeOrderID: number;

  constructor(private activeOrderDsService: ActiveOrderDsService, private qaHistoryService: QaHistoryService) {}

  public slicedList;
  public scrollIndex = 0;
  public createNCId = 'CreateNC_Id';
  // public listSize = 0;

  @Input() public scrollTo = 0;
  @Input() public set itemList(items: any[]) {
    if (items?.length >= 0) {
      this.slicedList = splitEvery(this.PAGE_SIZE, items);
    }
    // this.listSize = items.length;
  }
  @Input() public enableCreateNC = false;

  @ContentChild(ListItemDirective, { read: TemplateRef }) public itemTemplate;

  @Output() public searchBarValueChanged = new EventEmitter<string>();

  @Output() public createNCButtonClicked = new EventEmitter<null>();

  public ngOnChanges(): void {
    this.calculateScroll();
  }

  public isArrowDownVisible(): boolean {
    if (this.slicedList.length !== 0) {
      return !this.isLastPage();
    }
  }

  public isArrowUpVisible(): boolean {
    if (this.slicedList.length !== 0) {
      return !this.isFirstPage();
    }
  }

  public scrollDown() {
    if (!this.isLastPage()) {
      this.scrollIndex += 1;
    }
  }

  public scrollUp() {
    if (!this.isFirstPage()) {
      this.scrollIndex -= 1;
    }
  }

  public searchBarFilterList(enteredValue: string): void {
    this.searchBarValueChanged.emit(enteredValue);
  }

  private isFirstPage(): boolean {
    return this.scrollIndex === 0;
  }

  private isLastPage(): boolean {
    return this.scrollIndex === this.slicedList.length - 1;
  }

  private calculateScroll(): void {
    if (typeof this.scrollTo === 'number' && this.scrollTo >= 0) {
      this.scrollIndex = Math.floor(this.scrollTo / this.PAGE_SIZE);
    }
  }

  public onClickCreateNC(): void {
    this.createNCButtonClicked.emit();
  }

  public checkCreateNCDisabled(): boolean {
    this.activeOrderID = this.activeOrderDsService.getActiveOrderId();
    return this.activeOrderID === undefined;
  }
}
