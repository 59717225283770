import { Injectable } from '@angular/core';
import { InputContainersDsService } from '@app/core/data-services';
import { PrimaryMaterialMounted } from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable()
export class InputService {
  constructor(private inputContainersDsService: InputContainersDsService) {}

  public activePallets$ = this.inputContainersDsService.getActiveInputPallets();
  public mountedPallets$ = this.inputContainersDsService.getMountedInputPallets();
  public consumedPallets$ = this.inputContainersDsService.getConsumedInputPallets();
  public mountedPalletsCount$ = this.inputContainersDsService.getMountedInputPalletsCount();
  public consumedPalletsCount$ = this.inputContainersDsService.getConsumedInputPalletsCount();

  public getInputListWithNotifications(): Observable<PrimaryMaterialMounted[]> {
    return this.inputContainersDsService.getInputListWithNotifications();
  }
}
