import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
  public transform(value: string = '', args: number = 40): string {
    if (value !== null) {
      return value.length > args ? `${value.substring(0, args)}...` : value;
    }
    return '';
  }
}
