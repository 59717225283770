import { Injectable } from '@angular/core';
import { MachineData, MachineService } from 'chronos-core-client';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MachineDsService {
  constructor(private machineService: MachineService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public getStatus(): Observable<MachineData> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.machineService.getStatus(workCenterId);
  }
}
