import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleComponent implements OnInit {
  @Input() public applicationName: string;
  @Input() public workCenterName: string;

  public logoClass: string;

  public ngOnInit(): void {
    this.logoClass =
      location.origin === 'https://demo1.chronos.tietoevry.com' || location.origin === 'https://pile-turner.demo1.chronos.tietoevry.com'
        ? 'TE'
        : 'MM';
  }
}
