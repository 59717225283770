import { Injectable } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services';
import { UiStateQuery } from '@app/core/global-state';
import { nav, PhaseRoute, phasesRoutes, SubPhaseRoute } from '@app/shared/utils';
import { RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root'
})
export class NavigationRouteService {
  constructor(private activeOrderDsService: ActiveOrderDsService, private uiStateQuery: UiStateQuery) {}

  public getActiveMountingRoute(): string {
    const uiStateRoute = this.uiStateQuery.getMountingSelectedRoute();
    return uiStateRoute || nav.routes.mountingOverview;
  }

  public getActiveDocumentRoute(): string {
    const uiStateRoute = this.uiStateQuery.getDocumentSelectedRoute();
    return uiStateRoute || nav.routes.documents;
  }

  public getNavigationRoute(): string {
    const activeOrder = this.activeOrderDsService.getActiveOrder();

    if (!activeOrder) {
      return nav.setup;
    }

    return this.getRouteByPhase(activeOrder.runPhaseType, activeOrder.runSubPhaseType, phasesRoutes);
  }

  public getRouteByPhase(phase: RunPhaseType, subPhase: RunSubPhaseType, phaseRoutes: PhaseRoute[]): string {
    const isPropEqual = (expectedValue: RunPhaseType, propName: keyof PhaseRoute) => (obj: PhaseRoute) => obj[propName] === expectedValue;
    const isSubPhasePropEqual = (expectedValue: RunSubPhaseType, propName: keyof SubPhaseRoute) => (obj: SubPhaseRoute) =>
      obj[propName] === expectedValue;

    const activePhase = R.find<PhaseRoute>(isPropEqual(phase, 'phase'), phaseRoutes);

    const activeSubPhase = R.compose(
      R.find<SubPhaseRoute>(isSubPhasePropEqual(subPhase, 'subPhase')),
      R.propOr([], 'subPhases')
    )(activePhase);

    return R.prop('route', activeSubPhase || activePhase);
  }
}
