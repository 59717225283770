import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';
import {
  FinishedGoodArticleItem,
  NonConformanceItem,
  NonConformanceModel,
  QualityHistoryService,
  QaHistoryCheckEntry,
  CreateNonConformance,
  CreateNonConformanceEntry,
  SetQaToolCheckoutConfirmEntry
} from 'chronos-core-client';
import { AppSettingsQuery, AppSettingsService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class QaHistoryDsService {
  constructor(
    private appSettingsQuery: AppSettingsQuery,
    private appSettingsService: AppSettingsService,
    private qualityHistoryService: QualityHistoryService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public isQaHistoryAvailableForSetup(): Observable<boolean> {
    return this.appSettingsQuery.isQaHistoryEnabledForSetup$;
  }

  public isQaChecksAvailableForSetup(): Observable<boolean> {
    return this.appSettingsQuery.isQaChecksEnabledForSetup$;
  }

  public isLineClearanceEnableForSetup(): Observable<boolean> {
    return this.appSettingsQuery.isLineClearanceEnabledForSetup$;
  }

  public setQaHistoryStatusForSetup(isAvailable: boolean): void {
    this.appSettingsService.setQaHistorySetup(isAvailable);
  }

  public setQaChecksStatusForSetup(isAvailable: boolean): void {
    this.appSettingsService.setQaChecksSetup(isAvailable);
  }

  public getFinishedGoodArticles(loadOnlyForActiveOrder: boolean): Observable<FinishedGoodArticleItem[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const params: QualityHistoryService.GetFinishedGoodArticlesParams = {
      workCenterId,
      loadOnlyForActiveOrder
    };
    return this.qualityHistoryService.getFinishedGoodArticles(params);
  }

  public getNonConformanceList(articleId: number): Observable<NonConformanceItem[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const params: QualityHistoryService.GetNonConformanceOverviewParams = {
      workCenterId,
      articleId
    };
    return this.qualityHistoryService.getNonConformanceOverview(params);
  }

  public getNonConformance(nonConformanceId: number): Observable<NonConformanceModel> {
    return this.qualityHistoryService.getNonConformance(nonConformanceId);
  }

  public setQaHistoryCheck(productionOrderId: number, isChecked: boolean): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const qaHistoryCheckEntry: QaHistoryCheckEntry = {
      isChecked,
      workCenterId
    };
    const params: QualityHistoryService.CheckQaHistoryParams = {
      productionOrderId,
      entry: qaHistoryCheckEntry
    };
    return this.qualityHistoryService.checkQaHistory(params);
  }

  public createNonConformancePath(productionOrderId: number): Observable<CreateNonConformance> {
    return this.qualityHistoryService.getNonConformance2(productionOrderId);
  }

  public createNonConformance(productionOrderId: number, sortingWasteQuantity: number, internalMemo: string): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const createNonConformanceEntry: CreateNonConformanceEntry = {
      workCenterId,
      sortingWasteQuantity,
      internalMemo
    };
    const params: QualityHistoryService.CreateNonConformanceParams = {
      productionOrderId,
      entry: createNonConformanceEntry
    };
    return this.qualityHistoryService.createNonConformance(params);
  }

  public setQaToolCheckoutConfirm(productionOrderId: number, isChecked: boolean): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const qaToolCheckoutConfirmEntry: SetQaToolCheckoutConfirmEntry = {
      isChecked,
      workCenterId
    };
    const params: QualityHistoryService.SetQaToolCheckoutConfirmParams = {
      productionOrderId,
      entry: qaToolCheckoutConfirmEntry
    };
    return this.qualityHistoryService.setQaToolCheckoutConfirm(params);
  }
}
