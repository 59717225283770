import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductionQaCheck, QaCheckService } from 'chronos-core-client';

@Injectable()
export class QualityAssuranceService {
  constructor(private qaCheckSerice: QaCheckService) {}

  public getQACheckFields(productionOrderId: number): Observable<ProductionQaCheck[]> {
    return this.qaCheckSerice.getSetupProductionQaChecks(productionOrderId);
  }
}
