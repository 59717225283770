import { NgModule } from '@angular/core';
import { EmptyTextPlaceholderPipe } from './empty-text-placeholder/empty-text-placeholder.pipe';
import { FormatQuantityPipe } from './format-quantity/format-quantity.pipe';
import { ShortSsccCodePipe } from './short-sscc-code/short-sscc-code.pipe';
import { UtcToLocalPipe } from './utc-to-local/utc-to-local.pipe';
import { SsccPipe } from './sscc/sscc.pipe';

@NgModule({
  declarations: [FormatQuantityPipe, EmptyTextPlaceholderPipe, ShortSsccCodePipe, UtcToLocalPipe, SsccPipe],
  exports: [FormatQuantityPipe, EmptyTextPlaceholderPipe, ShortSsccCodePipe, UtcToLocalPipe, SsccPipe]
})
export class PipesModule {}
