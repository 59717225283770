<div class="nc-reason">
  <div class="nc-reason__item">
    <div class="nc-reason__text nc-reason__text--medium">{{ 'QA_HISTORY.REASON' | translate }}</div>
  </div>
  <div class="nc-reason__item">
    <div class="nc-reason__text">{{ 'QA_HISTORY.CATEGORY' | translate }}</div>
    <div *ngIf="isCreateNCMode; else notCreateCategory"></div>
    <ng-template #notCreateCategory>
      <div class="nc-reason__text nc-reason__text--gray nc-reason__text--grow">{{ nonConformanceModel?.sortingErrorCauseName }}</div>
    </ng-template>
  </div>
  <div class="nc-reason__item">
    <div class="nc-reason__text">{{ 'QA_HISTORY.CAUSE' | translate }}</div>
    <div *ngIf="isCreateNCMode; else notCreateCause"></div>
    <ng-template #notCreateCause>
      <div class="nc-reason__text nc-reason__text--gray nc-reason__text--grow">{{ nonConformanceModel?.sortingWasteCategory }}</div>
    </ng-template>
  </div>
</div>
