import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ContainerListColumnsComponent } from './container-list-columns/container-list-columns.component';
import { ContainerListItemDummyComponent } from './container-list-item-dummy/container-list-item-dummy.component';
import { ContainerListItemEmptyComponent } from './container-list-item-empty/container-list-item-empty.component';
import { ContainerListItemWhiteWasteComponent } from './container-list-item-white-waste/container-list-item-white-waste.component';
import { ContainerListItemComponent } from './container-list-item/container-list-item.component';
import { ContainerListMachineInfoComponent } from './container-list-machine-info/container-list-machine-info.component';
import { ContainerListMaterialsComponent } from './container-list-materials/container-list-materials.component';
import { ContainerListTemplateComponent } from './container-list-template/container-list-template.component';
import { ContainerListComponent } from './container-list/container-list.component';
import { OrderByButtonComponent } from './order-by-button/order-by-button.component';
import { ContainerComponentsModule } from '../container-components/container-components.module';
import { ButtonModule } from 'primeng/button';
import { FormComponentsModule } from '../form-components';

@NgModule({
  declarations: [
    ContainerListTemplateComponent,
    ContainerListColumnsComponent,
    ContainerListMachineInfoComponent,
    ContainerListComponent,
    ContainerListMaterialsComponent,
    ContainerListItemComponent,
    ContainerListItemDummyComponent,
    ContainerListItemEmptyComponent,
    OrderByButtonComponent,
    ContainerListItemWhiteWasteComponent
  ],
  exports: [
    ContainerListTemplateComponent,
    ContainerListColumnsComponent,
    ContainerListMachineInfoComponent,
    ContainerListComponent,
    ContainerListMaterialsComponent,
    ContainerListItemComponent,
    ContainerListItemDummyComponent,
    ContainerListItemEmptyComponent,
    OrderByButtonComponent,
    ContainerListItemWhiteWasteComponent
  ],
  imports: [CommonModule, DragDropModule, ButtonModule, FormsModule, ContainerComponentsModule, TranslateModule, FormComponentsModule]
})
export class ContainerMountListModule {}
