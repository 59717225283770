/* tslint:disable */
type PeriodCheckErrorCheckType = 'SinglePeriod' | 'MultiPeriod' | 'SplitConsistency' | 'Synchronization';
module PeriodCheckErrorCheckType {
  export const SINGLE_PERIOD: PeriodCheckErrorCheckType = 'SinglePeriod';
  export const MULTI_PERIOD: PeriodCheckErrorCheckType = 'MultiPeriod';
  export const SPLIT_CONSISTENCY: PeriodCheckErrorCheckType = 'SplitConsistency';
  export const SYNCHRONIZATION: PeriodCheckErrorCheckType = 'Synchronization';
  export function values(): PeriodCheckErrorCheckType[] {
    return [SINGLE_PERIOD, MULTI_PERIOD, SPLIT_CONSISTENCY, SYNCHRONIZATION];
  }
}

export { PeriodCheckErrorCheckType };
