<div class="primary-material">
  <span class="primary-material__icon">
    <i class="icon icon-pallet"></i>
    <span class="value pallet-value">{{ machineSchedule.primaryMachineLocationKpi?.containerCount }}</span>
  </span>
  <div class="primary-material__quantity">{{ machineSchedule.primaryMachineLocationKpi?.quantity | formatQuantity }}</div>
  <div class="primary-material__label">{{ 'ORDER_ITEM.PRIMARY_MATERIAL' | translate }}</div>
  <div [ngClass]="isPredecesorStatusNone ? 'predecessor-status-details--hidden' : 'predecessor-status-details'">
    <i Class="icon-info-outline info-icon predecessor-status-icon"></i>
    <span>{{ 'ORDER_ITEM.PREDECESSOR' | translate }}: {{ STATUS_FLAGS[machineSchedule.predecessorStatus].text | translate }}</span>
  </div>
</div>
