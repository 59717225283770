import { Injectable } from '@angular/core';
import { ActiveOrderDsService, ActiveOrderPhaseDsService, ContainersDsService } from '@app/core/data-services';
import {
  ActiveProductionOrder,
  ArticleDescription,
  CreateManualPalletData,
  KpiValue,
  ProductionOrderManualModeSortingValues,
  ProductionOrderService,
  Quantity
} from 'chronos-core-client';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManualModeService {
  constructor(
    private activeOrderPhaseDsService: ActiveOrderPhaseDsService,
    private containersDsService: ContainersDsService,
    private productionOrderService: ProductionOrderService,
    private activeOrderDsService: ActiveOrderDsService
  ) {}

  public startOrderInManualMode(
    productionOrderId: number,
    orderStartTime: Date,
    quantityPerPallet?: number
  ): Observable<ActiveProductionOrder> {
    let quantity = null;
    if (quantityPerPallet) {
      quantity = { value: quantityPerPallet };
    }
    return this.activeOrderPhaseDsService.startOrderInManualMode(productionOrderId, orderStartTime, quantity);
  }

  public createOutputPallet(productionOrderId: number, quantity: number): Observable<null> {
    return this.containersDsService.createOutputPalletFromPool(productionOrderId, quantity);
  }

  public changeOutputPalletQuantity(producedMaterialId: number, quantity: Quantity): Observable<null> {
    return this.containersDsService.changeContainerQuantity(producedMaterialId, quantity);
  }

  public endManualCuttingOrder(
    productionOrderId: number,
    orderStartTime: Date,
    orderEndTime: Date,
    isInterrupted: boolean
  ): Observable<ActiveProductionOrder> {
    return this.activeOrderPhaseDsService.endOrderInManualMode(
      productionOrderId,
      orderStartTime.toISOString(),
      orderEndTime.toISOString(),
      isInterrupted
    );
  }

  public endManualSortingOrder(productionOrderId: number, orderEndTime: Date, isInterrupted: boolean): Observable<ActiveProductionOrder> {
    return this.activeOrderPhaseDsService.endOrderInManualMode(productionOrderId, null, orderEndTime.toISOString(), isInterrupted);
  }

  public switchManualOrder(
    productionOrderId: number,
    producedMaterialId: number,
    orderEndTime: Date,
    orderStartTime?: Date,
    numberOfWorkers?: number,
    duration?: string
  ): Observable<null> {
    return this.containersDsService
      .switchManualOrder(productionOrderId, producedMaterialId, orderEndTime, orderStartTime, numberOfWorkers, duration)
      .pipe(switchMap(() => this.activeOrderPhaseDsService.enterFinishingPhase()));
  }

  public getManualSortingValues(productionOrderId: number): Observable<ProductionOrderManualModeSortingValues> {
    return this.activeOrderPhaseDsService.getManualSortingValues(productionOrderId);
  }

  public setManualSortingValues(productionOrderId: number, numberOfWorkers: number, duration: string): Observable<null> {
    return this.activeOrderPhaseDsService.setManualSortingValues(productionOrderId, numberOfWorkers, duration);
  }

  public getPalletCreationData(productionOrderId: number): Observable<CreateManualPalletData> {
    return this.containersDsService.getManualContainerCreationData(productionOrderId);
  }

  public getCombinedSortingData(productionOrderId: number): Observable<[CreateManualPalletData, ProductionOrderManualModeSortingValues]> {
    return combineLatest([this.getPalletCreationData(productionOrderId), this.getManualSortingValues(productionOrderId)]);
  }

  public mapArticleFromManualData(articleData: CreateManualPalletData): ArticleDescription {
    return {
      id: undefined,
      thickness: articleData.thickness,
      grammage: articleData.grammage,
      articleName: articleData.articleName,
      externalArticleId: articleData.externalArticleId,
      externalConfigurationId: articleData.externalConfigurationId,
      bomUnitId: articleData.bomUnitId,
      inventoryUnitId: articleData.inventoryUnitId,
      configurationName: articleData.configurationName,
      productType: undefined,
      classification: articleData.classification,
      labelAutoPrint: undefined,
      bomUnitFactor: articleData.bomUnitFactor,
      warehouseDimension: articleData.warehouseDimension
    };
  }

  public getAllKpis(): Observable<KpiValue[]> {
    const activeOrderId = this.activeOrderDsService.getActiveOrderId();
    return this.productionOrderService.getAllKpis(activeOrderId);
  }
}
