import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'lib-scan-container',
  templateUrl: './scan-container.component.html',
  styleUrls: ['./scan-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('ssccInput', { static: true }) public ssccInput: ElementRef;

  @Input() public validationChanges$: Observable<boolean>;
  @Input() public scanPlaceholderText = '';
  @Input() public buttonLabel = '';
  @Input() public selectInputOnEnter = false;
  @Input() public capsLockWarningMessage = '';

  @Output() public mountButtonClicked = new EventEmitter<string>();

  public sscc: string;
  public isValid = true;
  public isCapsLockOn = false;

  private subscriptions = new Subscription();

  @HostListener('window:keydown.enter')
  public focusToScanField() {
    if (this.ssccInput.nativeElement === document.activeElement) {
      this.onMountButtonClick();
      return;
    }

    if (this.selectInputOnEnter && this.isFocusAvailable()) {
      this.selectSsccInputText();
    }
  }

  private isFocusAvailable(): boolean {
    const modal = document.querySelector('p-dynamicdialog');

    if (modal) {
      return false;
    }

    return document.activeElement?.tagName !== 'INPUT' && document.activeElement?.tagName !== 'SELECT';
  }

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.validationChanges$.subscribe((isValid) => {
        this.isValid = isValid;
        if (!isValid) {
          this.changeDetector.detectChanges();
          this.selectSsccInputText();
        }
      })
    );
  }

  public ngAfterViewInit(): void {
    this.selectSsccInputText();
    const eventHandler = (event: KeyboardEvent | MouseEvent) => {
      if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
        return;
      }
      if (event.getModifierState('CapsLock')) {
        this.isCapsLockOn = true;
      } else {
        this.isCapsLockOn = false;
      }
      this.changeDetector.markForCheck();
    };

    this.ssccInput?.nativeElement?.addEventListener('keyup', eventHandler);
    this.ssccInput?.nativeElement?.addEventListener('mousedown', eventHandler);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.ssccInput?.nativeElement?.removeAllListeners?.('keyup');
    this.ssccInput?.nativeElement?.removeAllListeners?.('mousedown');
  }

  public onSsccContainerBlur(): void {
    this.isCapsLockOn = false;
  }

  public onSsccChange(): void {
    this.isValid = true;
  }

  private clearSscc(): void {
    this.sscc = '';
  }

  private selectSsccInputText(): void {
    this.ssccInput.nativeElement.focus();
  }

  public onMountButtonClick(): void {
    this.mountButtonClicked.emit(this.sscc);
    this.clearSscc();
    this.selectSsccInputText();
  }
}
