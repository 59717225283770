import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { RemarkType, RemarkView } from 'chronos-core-client';
import { remarkStatusFlags, shopFloorRemarkFlag } from '@app/shared/models';
import { RemarkActionDirective } from '@app/modules/documents/directives';

@Component({
  selector: 'app-remark-list',
  templateUrl: './remark-list.component.html',
  styleUrls: ['./remark-list.component.scss']
})
export class RemarkListComponent {
  @Input() public externalOperationType: string;
  @Input() public productionType: string;
  @Input() public remarkList: RemarkView[];

  @ContentChild(RemarkActionDirective, { read: TemplateRef }) public actions;

  public readonly REMARK_STATUS_FLAGS = remarkStatusFlags;
  public readonly SHOP_FLOOR_REMARK_FLAG = shopFloorRemarkFlag;
  public readonly REMARK_TYPE = RemarkType;
}
