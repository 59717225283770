<div
  class="animation-frame"
  [ngClass]="{
    'play-animation': isAnimationPlay,
    'stop-animation': !isAnimationPlay
  }"
>
  <span [ngClass]="{ 'left-border__animation': isAnimationPlay }" class="left-border"></span>
  <span [ngClass]="{ 'top-border__animation': isAnimationPlay }" class="top-border"></span>
  <span [ngClass]="{ 'right-border__animation': isAnimationPlay }" class="right-border"></span>
  <span [ngClass]="{ 'bottom-border__animation': isAnimationPlay }" class="bottom-border"></span>
</div>
