import { Component } from '@angular/core';

@Component({
  selector: 'app-time-edit-header',
  templateUrl: './time-edit-header.component.html',
  styleUrls: ['./time-edit-header.component.scss']
})
export class TimeEditHeaderComponent {
  constructor() {}
}
