<section class="overview-section">
  <div *ngIf="productionOrders$ | async as productionOrders" class="row">
    <div class="col-2 overview-section__sidebar">
      <app-scrollable-sidebar
        [itemList]="productionOrders"
        [scrollTo]="initialProductionOrderIndex"
        (searchBarValueChanged)="searchBarValueChanged($event)"
      >
        <app-order-sidebar-item
          *appListItem="let item"
          [item]="item"
          [isItemActive]="isItemActive(item)"
          (itemSelected)="onOrderItemSelect($event)"
        ></app-order-sidebar-item>
      </app-scrollable-sidebar>
    </div>

    <div class="col-10">
      <app-order-details
        [orderItem]="productionOrder"
        [billOfMaterials]="billOfMaterials$ | async"
        (refreshSidebar)="refreshSidebarList($event)"
        (scanCompleted)="scanCompletedForOrder(productionOrders, $event)"
      ></app-order-details>
    </div>
  </div>
</section>
