import { Injectable } from '@angular/core';
import { ProductionOrderDsService } from '@app/core/data-services';
import { Observable } from 'rxjs';
import { MachineSchedule, ProductionOrderDeviationEntry } from 'chronos-core-client';
import { switchMap } from 'rxjs/operators';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable()
export class ModalProductionLoyaltyService {
  constructor(private productionOrderDsService: ProductionOrderDsService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public setNextProductionOrder(productionOrderId: number, reasonId, comment: string, showAll: boolean): Observable<MachineSchedule[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ProductionOrderDeviationEntry = {
      workCenterId,
      additionalInfo: comment,
      deviationReasonId: reasonId
    };
    return this.productionOrderDsService
      .setAsNextOrder(productionOrderId, entry)
      .pipe(switchMap(() => this.productionOrderDsService.getProductionOrderListByShift(showAll)));
  }
}
