<p-table [value]="value" [columns]="COLUMNS" [scrollable]="true" [scrollHeight]="scrollHeight" styleClass="chronos-pallet-table">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let column of columns">
        <th [style.width]="column.width">{{ column.label | translate }}</th>
      </ng-container>
      <th>
        <ng-container *ngIf="allItemAction" [ngTemplateOutlet]="allItemAction"></ng-container>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    <tr>
      <td [style.width]="'140px'">
        <lib-status-flag [statusText]="row.producedMaterialStatus" [size]="'tiny-plain'"></lib-status-flag>
      </td>
      <td [style.width]="'120px'">
        <lib-pallet-number
          [palletNumber]="row.containerSequence"
          [classification]="row.articleDescription.classification"
        ></lib-pallet-number>
      </td>
      <td [style.width]="'120px'">
        <div class="table-text">{{ row.goodQuantity | formatQuantity : false }}</div>
      </td>
      <td [style.width]="'250px'">
        <div class="table-icons">
          <container-element [ngSwitch]="row.labelPrintResult">
            <ng-container *ngSwitchCase="labelPrintResult.FAILED">
              <div class="table-icons__block table-icons--brown">
                <i class="icon-print-outline icon icon-printer"></i>
                <i class="icon-warning-solid icon icon-warning"></i>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="labelPrintResult.DONE">
              <div class="table-icons__block table-icons--brown">
                <i class="icon-print-outline icon icon-printer"></i>
                <i class="icon-checked-solid icon icon-check"></i>
                <i class="pi pi-copy icon" *ngIf="row.reprintCount > 0"></i>
                <span class="icon--text" *ngIf="row.reprintCount > 0">{{ 'LABELS.COPIES' | translate : { count: row.reprintCount } }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="table-icons__block table-icons--brown">
                <i class="icon-print-outline icon icon-printer"></i>
                <i class="icon-checked-solid icon icon--none"></i>
              </div>
            </ng-container>
          </container-element>
        </div>
      </td>
      <td [style.width]="'270px'">
        <lib-pallet-kpi
          [kpiLabel]="'LABELS.KPI_SSCC' | translate"
          [compact]="true"
          [showInfoIcon]="true"
          [kpiValue]="row.ssccCode"
          [isSsccCodeLong]="true"
        ></lib-pallet-kpi>
      </td>
      <td>
        <ng-container
          *ngIf="perItemAction"
          [ngTemplateOutlet]="perItemAction"
          [ngTemplateOutletContext]="{ $implicit: row }"
        ></ng-container>
      </td>
    </tr>
    <tr *ngIf="isBobbin">
      <td colspan="6">
        <app-print-label
          [printerName]="row.printInfo.printerName"
          [labelsTotal]="row.printInfo.totalOutputUnits"
          [printedLabels]="row.printInfo.printedOutputUnits"
          [quantityPerOuter]="row.printInfo.quantityPerOuter"
          [labelType]="row.printInfo.outerLabelType"
          [numberOfCopies]="row.printInfo.numberCopies"
          [isQuantityPerOuterValidationRequired]="true"
          [defaultQuantityPerOuter]="row.printInfo.defaultQuantityPerOuter"
          (labelPrintBobbinButtonClicked)="corePrintBobbinLabel($event)"
          (quantityPerOuterChanged)="quantityPerOuterOrNumberofCopiesChange($event)"
          (numberOfCopiesChanged)="quantityPerOuterOrNumberofCopiesChange($event)"
          [isBobbin]="isBobbin"
          [producedMaterialId]="row.producedMaterialId"
          [slitCount]="slitCount"
          [palletQuantity]="row.goodQuantity.value"
        ></app-print-label>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [colSpan]="columns.length">
        {{ 'DOCUMENTS.NO_INFORMATION' | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>
