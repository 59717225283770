import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ActiveProductionOrder } from 'chronos-core-client';

function createInitialState(): Partial<ActiveProductionOrder> {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'active-order', idKey: 'productionOrderId', resettable: true })
export class ActiveOrderStore extends Store<ActiveProductionOrder> {
  constructor() {
    super(createInitialState());
  }
}
