import { NgModule } from '@angular/core';
import { ApproveComponent } from './containers/approve/approve.component';
import { SharedModule } from '@app/shared/shared.module';
import { ApproveReportTableComponent } from './containers/approve/components/approve-report-table/approve-report-table.component';
import { ParametersModule } from '../parameters/parameters.module';
import {
  ModalAdditionalInfoComponent,
  ModalChangeQuantityBetweenOrdersComponent,
  ModalChangeQuantityComponent,
  ModalMaterialStockComponent,
  ModalSetupParametersComponent
} from './containers/approve/components';

@NgModule({
  declarations: [
    ApproveComponent,
    ApproveReportTableComponent,
    ModalSetupParametersComponent,
    ModalChangeQuantityComponent,
    ModalAdditionalInfoComponent,
    ModalMaterialStockComponent,
    ModalChangeQuantityBetweenOrdersComponent
  ],
  imports: [SharedModule, ParametersModule]
})
export class ApproveModule {}
