<section class="finish-phase-section">
  <router-outlet></router-outlet>
  <footer class="finish-phase-section__footer" *ngIf="isFinishingPhase$ | async">
    <app-setup-footer
      [backCommand]="finishPhaseService.backCommand"
      [nextCommand]="finishPhaseService.nextCommand"
      [confirmFirstCheckboxCommand]="finishPhaseService.confirmCheckboxCommand"
    ></app-setup-footer>
  </footer>
</section>
