import { Component, Input } from '@angular/core';
import { Quantity } from 'chronos-core-client';

@Component({
  selector: 'app-consumption-indicator',
  templateUrl: './consumption-indicator.component.html',
  styleUrls: ['./consumption-indicator.component.scss']
})
export class ConsumptionIndicatorComponent {
  private readonly MINIMUM_NUMBER_OF_EVENTS = 1;

  @Input() public events: Quantity;

  public showConsumptionWarning(): boolean {
    return this.events?.value >= this.MINIMUM_NUMBER_OF_EVENTS;
  }
}
