/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WorkflowWf } from '../models/workflow-wf';
import { WfnodeWn } from '../models/wfnode-wn';
import { WflinkWl } from '../models/wflink-wl';
import { NodeparamNp } from '../models/nodeparam-np';
import { WfnodedataWd } from '../models/wfnodedata-wd';
@Injectable({
  providedIn: 'root'
})
class WorkflowsService extends __BaseService {
  static readonly getWorkflowPath = '/api/Workflows/{wfid}';
  static readonly getOrganizationWorkflowsPath = '/api/Workflows/org/{orid}';
  static readonly saveWorkflowPath = '/api/Workflows/saveworkflow/{orid}';
  static readonly deleteWorkflowPath = '/api/Workflows/deleteworkflow/{orid}/{wfid}';
  static readonly addNodePath = '/api/Workflows/addnode/{orid}';
  static readonly updateNodePath = '/api/Workflows/updatenode/{orid}/{wnid}';
  static readonly removeNodePath = '/api/Workflows/removenode/{orid}/{wnid}';
  static readonly addLinkPath = '/api/Workflows/connect/{orid}';
  static readonly removeLinkPath = '/api/Workflows/disconnect/{orid}/{wlid}';
  static readonly listNodeVariablesPath = '/api/Workflows/variables/{orid}/{wnid}';
  static readonly preConditionParametersPath = '/api/Workflows/preparams/{orid}/{wnid}';
  static readonly allPreConditionParametersPath = '/api/Workflows/allpreparams/{orid}/{wnid}';
  static readonly saveNodedataPath = '/api/Workflows/savenodedata/{orid}';
  static readonly setDebugPath = '/api/Workflows/debug/{orid}/{wfid}/{wfdebug}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param wfid undefined
   * @return Success
   */
  getWorkflowResponse(wfid: number): __Observable<__StrictHttpResponse<WorkflowWf>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Workflows/${encodeURIComponent(String(wfid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowWf>;
      })
    );
  }
  /**
   * @param wfid undefined
   * @return Success
   */
  getWorkflow(wfid: number): __Observable<WorkflowWf> {
    return this.getWorkflowResponse(wfid).pipe(__map((_r) => _r.body as WorkflowWf));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationWorkflowsResponse(orid: number): __Observable<__StrictHttpResponse<Array<WorkflowWf>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Workflows/org/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkflowWf>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationWorkflows(orid: number): __Observable<Array<WorkflowWf>> {
    return this.getOrganizationWorkflowsResponse(orid).pipe(__map((_r) => _r.body as Array<WorkflowWf>));
  }

  /**
   * @param params The `WorkflowsService.SaveWorkflowParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  saveWorkflowResponse(params: WorkflowsService.SaveWorkflowParams): __Observable<__StrictHttpResponse<WorkflowWf>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Workflows/saveworkflow/${encodeURIComponent(String(params.orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowWf>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.SaveWorkflowParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  saveWorkflow(params: WorkflowsService.SaveWorkflowParams): __Observable<WorkflowWf> {
    return this.saveWorkflowResponse(params).pipe(__map((_r) => _r.body as WorkflowWf));
  }

  /**
   * @param params The `WorkflowsService.DeleteWorkflowParams` containing the following parameters:
   *
   * - `wfid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteWorkflowResponse(params: WorkflowsService.DeleteWorkflowParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Workflows/deleteworkflow/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.wfid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.DeleteWorkflowParams` containing the following parameters:
   *
   * - `wfid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteWorkflow(params: WorkflowsService.DeleteWorkflowParams): __Observable<number> {
    return this.deleteWorkflowResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `WorkflowsService.AddNodeParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addNodeResponse(params: WorkflowsService.AddNodeParams): __Observable<__StrictHttpResponse<WfnodeWn>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Workflows/addnode/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WfnodeWn>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.AddNodeParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addNode(params: WorkflowsService.AddNodeParams): __Observable<WfnodeWn> {
    return this.addNodeResponse(params).pipe(__map((_r) => _r.body as WfnodeWn));
  }

  /**
   * @param params The `WorkflowsService.UpdateNodeParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateNodeResponse(params: WorkflowsService.UpdateNodeParams): __Observable<__StrictHttpResponse<WfnodeWn>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Workflows/updatenode/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.wnid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WfnodeWn>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.UpdateNodeParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateNode(params: WorkflowsService.UpdateNodeParams): __Observable<WfnodeWn> {
    return this.updateNodeResponse(params).pipe(__map((_r) => _r.body as WfnodeWn));
  }

  /**
   * @param params The `WorkflowsService.RemoveNodeParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  removeNodeResponse(params: WorkflowsService.RemoveNodeParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Workflows/removenode/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.wnid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.RemoveNodeParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  removeNode(params: WorkflowsService.RemoveNodeParams): __Observable<number> {
    return this.removeNodeResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `WorkflowsService.AddLinkParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addLinkResponse(params: WorkflowsService.AddLinkParams): __Observable<__StrictHttpResponse<WflinkWl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Workflows/connect/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WflinkWl>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.AddLinkParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addLink(params: WorkflowsService.AddLinkParams): __Observable<WflinkWl> {
    return this.addLinkResponse(params).pipe(__map((_r) => _r.body as WflinkWl));
  }

  /**
   * @param params The `WorkflowsService.RemoveLinkParams` containing the following parameters:
   *
   * - `wlid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  removeLinkResponse(params: WorkflowsService.RemoveLinkParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Workflows/disconnect/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.wlid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.RemoveLinkParams` containing the following parameters:
   *
   * - `wlid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  removeLink(params: WorkflowsService.RemoveLinkParams): __Observable<number> {
    return this.removeLinkResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `WorkflowsService.ListNodeVariablesParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  listNodeVariablesResponse(
    params: WorkflowsService.ListNodeVariablesParams
  ): __Observable<__StrictHttpResponse<{ [key: string]: string }>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workflows/variables/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.wnid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{ [key: string]: string }>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.ListNodeVariablesParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  listNodeVariables(params: WorkflowsService.ListNodeVariablesParams): __Observable<{ [key: string]: string }> {
    return this.listNodeVariablesResponse(params).pipe(__map((_r) => _r.body as { [key: string]: string }));
  }

  /**
   * @param params The `WorkflowsService.PreConditionParametersParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  preConditionParametersResponse(
    params: WorkflowsService.PreConditionParametersParams
  ): __Observable<__StrictHttpResponse<Array<NodeparamNp>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workflows/preparams/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.wnid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NodeparamNp>>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.PreConditionParametersParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  preConditionParameters(params: WorkflowsService.PreConditionParametersParams): __Observable<Array<NodeparamNp>> {
    return this.preConditionParametersResponse(params).pipe(__map((_r) => _r.body as Array<NodeparamNp>));
  }

  /**
   * @param params The `WorkflowsService.AllPreConditionParametersParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  allPreConditionParametersResponse(
    params: WorkflowsService.AllPreConditionParametersParams
  ): __Observable<__StrictHttpResponse<Array<NodeparamNp>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workflows/allpreparams/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.wnid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NodeparamNp>>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.AllPreConditionParametersParams` containing the following parameters:
   *
   * - `wnid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  allPreConditionParameters(params: WorkflowsService.AllPreConditionParametersParams): __Observable<Array<NodeparamNp>> {
    return this.allPreConditionParametersResponse(params).pipe(__map((_r) => _r.body as Array<NodeparamNp>));
  }

  /**
   * @param params The `WorkflowsService.SaveNodedataParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  saveNodedataResponse(params: WorkflowsService.SaveNodedataParams): __Observable<__StrictHttpResponse<WfnodedataWd>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Workflows/savenodedata/${encodeURIComponent(String(params.orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WfnodedataWd>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.SaveNodedataParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  saveNodedata(params: WorkflowsService.SaveNodedataParams): __Observable<WfnodedataWd> {
    return this.saveNodedataResponse(params).pipe(__map((_r) => _r.body as WfnodedataWd));
  }

  /**
   * @param params The `WorkflowsService.SetDebugParams` containing the following parameters:
   *
   * - `wfid`:
   *
   * - `wfdebug`:
   *
   * - `orid`:
   *
   * @return Success
   */
  setDebugResponse(params: WorkflowsService.SetDebugParams): __Observable<__StrictHttpResponse<WorkflowWf>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/Workflows/debug/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.wfid))}/${encodeURIComponent(
          String(params.wfdebug)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowWf>;
      })
    );
  }
  /**
   * @param params The `WorkflowsService.SetDebugParams` containing the following parameters:
   *
   * - `wfid`:
   *
   * - `wfdebug`:
   *
   * - `orid`:
   *
   * @return Success
   */
  setDebug(params: WorkflowsService.SetDebugParams): __Observable<WorkflowWf> {
    return this.setDebugResponse(params).pipe(__map((_r) => _r.body as WorkflowWf));
  }
}

module WorkflowsService {
  /**
   * Parameters for SaveWorkflow
   */
  export interface SaveWorkflowParams {
    orid: number;
    body?: WorkflowWf;
  }

  /**
   * Parameters for DeleteWorkflow
   */
  export interface DeleteWorkflowParams {
    wfid: number;
    orid: number;
  }

  /**
   * Parameters for AddNode
   */
  export interface AddNodeParams {
    orid: number;
    body?: WfnodeWn;
  }

  /**
   * Parameters for UpdateNode
   */
  export interface UpdateNodeParams {
    wnid: number;
    orid: number;
    body?: WfnodeWn;
  }

  /**
   * Parameters for RemoveNode
   */
  export interface RemoveNodeParams {
    wnid: number;
    orid: number;
  }

  /**
   * Parameters for AddLink
   */
  export interface AddLinkParams {
    orid: number;
    body?: WflinkWl;
  }

  /**
   * Parameters for RemoveLink
   */
  export interface RemoveLinkParams {
    wlid: number;
    orid: number;
  }

  /**
   * Parameters for ListNodeVariables
   */
  export interface ListNodeVariablesParams {
    wnid: number;
    orid: number;
  }

  /**
   * Parameters for PreConditionParameters
   */
  export interface PreConditionParametersParams {
    wnid: number;
    orid: number;
  }

  /**
   * Parameters for AllPreConditionParameters
   */
  export interface AllPreConditionParametersParams {
    wnid: number;
    orid: number;
  }

  /**
   * Parameters for SaveNodedata
   */
  export interface SaveNodedataParams {
    orid: number;
    body?: WfnodedataWd;
  }

  /**
   * Parameters for SetDebug
   */
  export interface SetDebugParams {
    wfid: number;
    wfdebug: string;
    orid: number;
  }
}

export { WorkflowsService };
