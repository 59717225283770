<div class="machine-output">
  <div [ngClass]="{ 'pallet-card--active': isPalletActive }" class="pallet-card">
    <div class="pallet-card__body cursor-clickable">
      <div class="card-start-controls" (click)="toggleContainer()">
        <div class="pallet-card__labels">
          <ng-container *ngIf="containerStatus">
            <lib-status-flag
              class="status__item"
              [statusText]="containerStatus.text | translate"
              [styleClass]="containerStatus.labelClass"
              [isAutomated]="outputPallet.isLastContainerAutomaticOrderChange"
            ></lib-status-flag>
          </ng-container>

          <div class="collapse-button">
            <span>
              <i [ngClass]="isExpanded ? 'icon-arrows-right-filled-close' : 'icon-arrow-right-filled-open'"></i>
            </span>
          </div>
        </div>
        <div *ngIf="outputPallet.containerCheckResult && this.showStatus" class="pallet-card__labels">
          <ng-container>
            <lib-quality-status
              [checkClass]="palletQualityStatus.labelClass"
              [checkText]="palletQualityStatus.text | translate"
            ></lib-quality-status>
          </ng-container>
        </div>
        <lib-pallet-number
          class="pallet-card__number"
          [palletNumber]="outputPallet.sequence"
          [classification]="outputPallet.article.classification"
        ></lib-pallet-number>
        <div class="quantity">
          <div class="quantity__wrapper">
            <div class="quantity__label">
              {{ 'PALLET_CARD.GOOD_QUANTITY' | translate }}
            </div>
            <div class="quantity__value">
              {{ outputPallet.goodQuantity | formatQuantity : false }}
            </div>
          </div>
        </div>
        <div class="quantity">
          <div class="quantity__wrapper">
            <div class="quantity__label">&nbsp;</div>
            <div class="quantity__value">/</div>
          </div>
        </div>
        <div class="quantity quantity--right">
          <div class="quantity__wrapper">
            <div class="quantity__label">
              {{ 'PALLET_CARD.TARGET_QUANTITY' | translate }}
            </div>
            <div class="quantity__value">
              {{ outputPallet.containerQuantity | formatQuantity : false }}
            </div>
          </div>
        </div>
        <div class="quantity">
          <div class="quantity__wrapper">
            <div class="quantity__label">&nbsp;</div>
            <div class="quantity__value">
              <span class="quantity__units">{{ outputPallet.containerQuantity.unitId }}</span>
            </div>
          </div>
        </div>
        <!-- it is not in use on production, hence time being commented to utilize the pallet header space. -->
        <!-- <ng-container *ngIf="isShowGrossQuantity">
          <div class="quantity">
            <div class="quantity__wrapper">
              <div class="quantity__label">&nbsp;</div>
              <div class="quantity__value">/</div>
            </div>
          </div>
          <div class="quantity quantity--right">
            <div class="quantity__wrapper">
              <div class="quantity__label">GrossQuantity</div>
              <div class="quantity__value">
                {{ outputPallet.grossQuantity | formatQuantity : false }}
              </div>
            </div>
          </div>
        </ng-container> -->
        <ng-container *ngIf="isShowBobbinQuantity">
          <div class="empty"></div>
          <div class="quantity quantity--right">
            <div class="quantity__wrapper">
              <div class="quantity__label">bobbin cnt good/ target</div>
              <div class="quantity__bobbinvalue">{{ currentBobbinCount }} / {{ totalBobbinCount }}</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!isExpanded" class="card-end-controls" (click)="toggleContainer()">
        <div class="quantity">
          <div class="quantity__wrapper quantity--right">
            <app-status-bar
              *ngIf="outputPallet"
              [printStatus]="printStatus"
              [qaStatus]="checkStatus"
              [forwardWaste]="containForwardWaste"
              [wasteStatus]="wasteStatus"
            ></app-status-bar>
          </div>
        </div>
      </div>
      <div *ngIf="!isExpanded">
        <app-split-button [collapsed]="true" [items]="palletButtons"></app-split-button>
      </div>
    </div>
    <div *ngIf="isExpanded">
      <div class="pallet-card__table">
        <div class="icons icons__info">
          <i class="icon-trash-small"></i>
        </div>
        <div class="table-container">
          <app-waste-assignment-table
            (rowAdded)="onRowAdded(outputPallet.producedMaterialId, $event)"
            (rowRemoved)="onRowRemoved(outputPallet.producedMaterialId, $event)"
            (wasteAssigned)="onWasteAssign($event)"
            [counter]="outputPallet.goodQuantity"
            [reasonOptions]="reasonOptions"
            [wasteAndMaculatureValue]="outputPallet.wasteAssignments"
            [producedMaterialWasteStatus]="wasteStatus"
            [palletNumber]="outputPallet.sequence"
          ></app-waste-assignment-table>
        </div>
        <div class="icons icons__status">
          <i [ngClass]="{ 'icon-checked-solid': isWasteDone }"></i>
        </div>
      </div>
      <div *ngIf="forwardedWasteState !== 'Disabled' && forwardedWasteState !== 'None'">
        <div class="pallet-card__table">
          <div class="icons icons__info">
            <i class="icon-recycling"></i>
          </div>
          <div class="table-container">
            <div
              class="table-container__item"
              [ngClass]="{ 'table-container__item--required': forwardedWasteState === 'Mandatory' && isForwardedWaste === null }"
            >
              <div>{{ 'PALLET_CARD.WASTE_FORWARD_CONTINUE' | translate }}</div>
              <div class="table-container__item--end">
                <ng-container *ngTemplateOutlet="forwardWasteButton"></ng-container>
              </div>
            </div>
          </div>
          <div class="icons icons__status">
            <i [ngClass]="{ 'icon-checked-solid': forwardedWasteResult === 'Done' }"></i>
          </div>
        </div>
      </div>
      <div class="pallet-card__table">
        <div class="icons icons__info">
          <i class="icon-quality-assurance"></i>
        </div>
        <ng-container *ngIf="isQaCheckListReduced(); else qaCheckLink">
          <div class="pallet-card__reduced-qa-check" [ngClass]="{ required: !isPalletQaCheckDone() }">
            <app-pallet-qa-check
              [qaFields]="outputPallet.qaChecks"
              [qaCheckForm]="qaCheckForm"
              [allOkCheck]="allOkCheck"
            ></app-pallet-qa-check>
          </div>
          <div class="pallet-card__all-ok">
            <div class="table-container__check">
              <p-checkbox [formControl]="allOkCheck" [label]="'SETUP_QUALITY_ASSURANCE.ALL_OK' | translate" binary="true"></p-checkbox>
            </div>
            <div class="table-container--inline-end">
              <a class="table-container__link" (click)="openQaChecksModal()">
                {{ 'PALLET_CARD.MORE' | translate }}
              </a>
            </div>
          </div>
        </ng-container>
        <div class="icons icons__status">
          <i [ngClass]="{ 'icon-checked-solid': isCheckDone }"></i>
        </div>
      </div>
      <div class="pallet-card__table">
        <div class="icons icons__info">
          <i class="icon-print-outline"></i>
        </div>
        <div class="table-container">
          <div class="table-container__item">
            <div>{{ 'PALLET_CARD.PRINTER' | translate : { printer: outputPallet?.printerName || '–' } }}</div>
            <div class="table-container__item--end">
              <label for="print-label-input">{{ 'PALLET_CARD.INFO' | translate }}</label>
              <input
                (focus)="onPrintLabelInfoFocus()"
                (blur)="onPrintLabelInfoBlur()"
                [(ngModel)]="currentLabelInfo"
                id="print-label-input"
                pInputText
                type="text"
                [maxLength]="120"
              />
            </div>
          </div>
        </div>
        <div class="icons icons__status" [ngClass]="{ 'icons__status--warning': isPrintFailed }">
          <i [ngClass]="{ 'icon-checked-solid': isPrintDone, 'icon-warning-solid': isPrintFailed }"></i>
        </div>
      </div>
      <div *ngIf="isShowBobbinQuantity && outputPallet.printInfo" class="pallet-card__table">
        <div class="icons icons__info"></div>
        <div class="table-container">
          <app-print-label
            [printerName]="outputPallet.printInfo.printerName"
            [labelsTotal]="outputPallet.printInfo.totalOutputUnits"
            [printedLabels]="outputPallet.printInfo.printedOutputUnits"
            [quantityPerOuter]="outputPallet.printInfo.quantityPerOuter"
            [numberOfCopies]="outputPallet.printInfo.numberCopies"
            [labelType]="outputPallet.printInfo.outerLabelType"
            [isQuantityPerOuterValidationRequired]="true"
            [defaultQuantityPerOuter]="outputPallet.printInfo.defaultQuantityPerOuter"
            [isPalletFinished]="isPalletFinished"
            [palletQuantity]="this.outputPallet.goodQuantity.value"
            [isBobbin]="true"
            [producedMaterialId]="outputPallet.producedMaterialId"
            [slitCount]="slitCount"
            (labelPrintBobbinButtonClicked)="corePrintBobbinLabel($event)"
            (quantityPerOuterChanged)="quantityPerOuterAndCopiesChange($event)"
            (numberOfCopiesChanged)="quantityPerOuterAndCopiesChange($event)"
          ></app-print-label>
        </div>
        <div class="icons icons__status"></div>
      </div>
      <div class="pallet-card__actions">
        <lib-input-switch
          [loadingTopic]="loadingTopic"
          [required]="isFinishedRequired()"
          [(ngModel)]="isFinishedChecked"
          [label]="'PALLET_CARD.FINISHED' | translate"
          [disabled]="!isContainerDone()"
          (ngModelChange)="onFinishedCheckChange()"
        ></lib-input-switch>
        <app-split-button class="pallet-card__button" [loadingTopic]="loadingTopic" [items]="palletButtons"></app-split-button>
      </div>
    </div>
  </div>
</div>

<ng-template #qaCheckLink>
  <div class="table-container">
    <div [ngClass]="{ required: !isPalletQaCheckDone() }" class="table-container__item">
      <ng-container *ngIf="isQaCheckListAvailable(); else emptyQaChecks">
        <a class="table-container__link" (click)="openQaChecksModal()">
          {{ 'PALLET_CARD.QA_CHECKS' | translate }}
        </a>
      </ng-container>
    </div>
  </div>
  <ng-template #emptyQaChecks>
    <div>{{ 'PALLET_CARD.NO_QA_CHECKS' | translate }}</div>
  </ng-template>
</ng-template>

<ng-template #forwardWasteButton>
  <div class="result-select-button ng-star-inserted">
    <div class="ng-star-inserted" (click)="containsForwardedWaste(true)">
      <input type="radio" name="waste" [checked]="isForwardedWaste" [value]="isForwardedWaste" />
      <div [ngClass]="forwardWasteButtonYes" class="result-select-button__item">
        {{ 'MODAL_FOOTER.YES' | translate }}
      </div>
    </div>
    <div class="ng-star-inserted" (click)="containsForwardedWaste(false)">
      <input type="radio" name="waste" [value]="isForwardedWaste" />
      <div [ngClass]="forwardWasteButtonNo" class="result-select-button__item">
        {{ 'MODAL_FOOTER.NO' | translate }}
      </div>
    </div>
  </div>
</ng-template>
