/* tslint:disable */
type SystemParameterType = 'Parameter' | 'ContainerQuantity' | 'ManualTransport' | 'BobbinQuantity' | 'CoreLabelCopies';
module SystemParameterType {
  export const PARAMETER: SystemParameterType = 'Parameter';
  export const CONTAINER_QUANTITY: SystemParameterType = 'ContainerQuantity';
  export const MANUAL_TRANSPORT: SystemParameterType = 'ManualTransport';
  export const BOBBIN_QUANTITY: SystemParameterType = 'BobbinQuantity';
  export const CORE_LABEL_COPIES: SystemParameterType = 'CoreLabelCopies';
  export function values(): SystemParameterType[] {
    return [PARAMETER, CONTAINER_QUANTITY, MANUAL_TRANSPORT, BOBBIN_QUANTITY, CORE_LABEL_COPIES];
  }
}

export { SystemParameterType };
