/* tslint:disable */
type KpiCategory = 'Quantity' | 'Time' | 'Speed' | 'Project' | 'ShiftHeader' | 'ApprovalReport';
module KpiCategory {
  export const QUANTITY: KpiCategory = 'Quantity';
  export const TIME: KpiCategory = 'Time';
  export const SPEED: KpiCategory = 'Speed';
  export const PROJECT: KpiCategory = 'Project';
  export const SHIFT_HEADER: KpiCategory = 'ShiftHeader';
  export const APPROVAL_REPORT: KpiCategory = 'ApprovalReport';
  export function values(): KpiCategory[] {
    return [QUANTITY, TIME, SPEED, PROJECT, SHIFT_HEADER, APPROVAL_REPORT];
  }
}

export { KpiCategory };
