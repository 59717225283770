<div class="loyalty-reasons" [ngClass]="{ 'loyalty-reasons--warning': !selectedLoyaltyReason?.deviationReasonId }">
  <div class="loyalty-reasons__label">{{ 'LOYALTY_REASONS.DEVIATION_REASON' | translate }}</div>
  <p-listbox
    class="loyalty-reasons__input"
    [options]="loyaltyReasons"
    [(ngModel)]="selectedLoyaltyReason"
    optionLabel="description"
    (onChange)="onLoyaltyReasonSelect()"
  ></p-listbox>
</div>
