<section class="phase">
  <header>
    <app-phase-header></app-phase-header>
  </header>
  <div class="content page-padding">
    <router-outlet></router-outlet>
  </div>
  <footer class="setup-footer" *ngIf="isSetupPhase$ | async">
    <app-setup-footer
      [cancelCommand]="cancelCommand"
      [nextCommand]="setupPhaseService.nextButtonCommand"
      [backCommand]="setupPhaseService.backButtonCommand"
      [confirmFirstCheckboxCommand]="setupPhaseService.firstCheckboxCommand"
    ></app-setup-footer>
  </footer>
</section>
