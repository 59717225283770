<p-blockUI [blocked]="blocked">
  <div class="loading-panel">
    <div class="loading-content">
      <div class="loading-content__spinner">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div class="loading-content__text">
        {{ text | translate }}
      </div>
    </div>
  </div>
</p-blockUI>
