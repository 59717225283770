/* tslint:disable */
type WorkCenterType = 'Machine' | 'Tool' | 'Vendor' | 'Chronos';
module WorkCenterType {
  export const MACHINE: WorkCenterType = 'Machine';
  export const TOOL: WorkCenterType = 'Tool';
  export const VENDOR: WorkCenterType = 'Vendor';
  export const CHRONOS: WorkCenterType = 'Chronos';
  export function values(): WorkCenterType[] {
    return [MACHINE, TOOL, VENDOR, CHRONOS];
  }
}

export { WorkCenterType };
