/* tslint:disable */
type OutputContainerCreationMode = 'Manual' | 'Automatic';
module OutputContainerCreationMode {
  export const MANUAL: OutputContainerCreationMode = 'Manual';
  export const AUTOMATIC: OutputContainerCreationMode = 'Automatic';
  export function values(): OutputContainerCreationMode[] {
    return [MANUAL, AUTOMATIC];
  }
}

export { OutputContainerCreationMode };
