import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PrimaryMaterialDsService } from '@app/core/data-services';
import { DismountContainerModalComponent } from '@app/modules/finish-phase/containers/overview/components/dismount-container-modal';
import { DismountDummyPalletModalComponent } from '@app/shared/components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';
import { nav, notificationTopic } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { ArticleDescription, PrimaryMaterialMounted } from 'chronos-core-client';
import { DismountingPalletInformationMapper } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { KpiActionBase } from './kpi-action-base';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class KpiActionConsumptionPrimaryService extends KpiActionBase {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private primaryMaterialDsService: PrimaryMaterialDsService
  ) {
    super();
    this.loadingDismountTopic = notificationTopic.kpiConsumptionPrimaryDismount;
  }

  public execute(): void {
    this.router.navigate([nav.routes.mountingPrimary]);
  }

  public dismount(): void {
    this.publishLoadingDismountTopic(true);
    this.primaryMaterialDsService
      .getNextContainerForDismount()
      .pipe(
        finalize(() => {
          this.publishLoadingDismountTopic(false);
        })
      )
      .subscribe((mountedContainer) => {
        if (mountedContainer.container.isVirtualContainer) {
          this.openDummyPalletDismountingModal(mountedContainer, mountedContainer.article);
        } else {
          this.openDismountContainerModal(mountedContainer, mountedContainer.article);
        }
      });
  }

  private openDismountContainerModal(mountedContainer: PrimaryMaterialMounted, article: ArticleDescription): void {
    this.dialogService.open(DismountContainerModalComponent, {
      header: this.translateService.instant('MOUNTING.DISMOUNTING'),
      data: {
        containerId: mountedContainer.container.containerId,
        dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(mountedContainer, article)
      }
    });
  }

  private openDummyPalletDismountingModal(material: PrimaryMaterialMounted, article: ArticleDescription): void {
    this.dialogService.open(DismountDummyPalletModalComponent, {
      header: this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY_PALLET'),
      data: {
        containerId: material.container.containerId,
        dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(material, article)
      }
    });
  }
}
