import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { nav } from '@app/shared/utils';
import { Quantity } from 'chronos-core-client';

@Component({
  selector: 'app-input-quantity-pool',
  templateUrl: './input-quantity-pool.component.html',
  styleUrls: ['./input-quantity-pool.component.scss']
})
export class InputQuantityPoolComponent {
  @Input() public amount: Quantity;

  constructor(private router: Router) {}

  public getKpiStatus(): 'warning' | 'negative' {
    return this.amount?.value >= 0 ? 'warning' : 'negative';
  }

  public goToPrimaryTab(): void {
    this.router.navigate([nav.routes.mountingPrimary]);
  }
}
