import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  ConfirmCheckboxCommand,
  NextFinishingSubphaseCommandService,
  PreviousFinishingSubphaseCommandService
} from '@app/modules/finish-phase/commands';
import { KpiQuery } from '@app/modules/finish-phase/state/kpi';
import { SetupFooterService } from '@app/shared/components';
import { GroupedKpiValue } from '@app/shared/models';
import { nav } from '@app/shared/utils';
import { ChartType } from 'chronos-shared';
import { FinishPhaseService } from '@app/modules/finish-phase/services';
import { KpiCategory, RunSubPhaseType } from 'chronos-core-client';
import { Subscription } from 'rxjs';
import { ActiveOrderService } from '@app/core/global-state';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy {
  public workCenterId$ = this.activeWorkCenterService.workCenterId$;
  public isAutomatedMode$ = this.finishPhaseService.isAutomatedMode();

  public chartMode = ChartType.finishPhase;
  public kpiGroups: GroupedKpiValue;

  private backCommand: PreviousFinishingSubphaseCommandService;
  private nextCommand: NextFinishingSubphaseCommandService;
  private confirmCheckboxCommand: ConfirmCheckboxCommand;

  private subscriptions = new Subscription();
  public readonly KPI_CATEGORY = KpiCategory;

  constructor(
    private kpiQuery: KpiQuery,
    private finishPhaseService: FinishPhaseService,
    private setupFooterService: SetupFooterService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private activeOrderService?: ActiveOrderService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.finishPhaseService.isToolCheckoutEnable().subscribe((isToolcheckout) => {
        this.setCommands(isToolcheckout);
      })
    );

    this.subscriptions.add(
      this.finishPhaseService.getKpisConfirmation().subscribe((value) => {
        this.nextCommand.enabled = value;
        this.confirmCheckboxCommand.enabled = value;
        this.confirmCheckboxCommand.required = !value;
      })
    );

    this.subscriptions.add(this.kpiQuery.kpisByGroup$.subscribe((kpiGroups) => (this.kpiGroups = kpiGroups)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setCommands(isToolcheckout: boolean): void {
    this.backCommand = new PreviousFinishingSubphaseCommandService(
      this.setupFooterService,
      this.finishPhaseService,
      this.activeOrderService
    );

    this.backCommand.setNavigationParams(nav.routes.run);
    this.backCommand.cancelEndProduction = true;

    if (isToolcheckout) {
      this.nextCommand = new NextFinishingSubphaseCommandService(this.setupFooterService);
      this.nextCommand.setNavigationParams(RunSubPhaseType.FINISHING_TOOL_CHECKOUT, nav.routes.finishToolcheckout);
    } else {
      this.nextCommand = new NextFinishingSubphaseCommandService(this.setupFooterService);
      this.nextCommand.setNavigationParams(RunSubPhaseType.FINISHING_END, nav.routes.finishEnd);
    }

    this.confirmCheckboxCommand = new ConfirmCheckboxCommand(this.finishPhaseService);
    this.confirmCheckboxCommand.buttonText = 'FINISH_PHASE.KPI_CONFIRMATION';
    this.finishPhaseService.setCommands(this.backCommand, this.nextCommand, this.confirmCheckboxCommand);
  }

  public formatColumnTitle(title: string): string {
    return `FINISH_PHASE.${title.toUpperCase()}`;
  }
}
