/* tslint:disable */
type TechnologyType =
  | 'Default'
  | 'Cutting'
  | 'DieCutting'
  | 'Gluing'
  | 'Manual'
  | 'Packing'
  | 'OffsetPrinting'
  | 'Laminating'
  | 'Winding'
  | 'Flexography'
  | 'Wrapping';
module TechnologyType {
  export const DEFAULT: TechnologyType = 'Default';
  export const CUTTING: TechnologyType = 'Cutting';
  export const DIE_CUTTING: TechnologyType = 'DieCutting';
  export const GLUING: TechnologyType = 'Gluing';
  export const MANUAL: TechnologyType = 'Manual';
  export const PACKING: TechnologyType = 'Packing';
  export const OFFSET_PRINTING: TechnologyType = 'OffsetPrinting';
  export const LAMINATING: TechnologyType = 'Laminating';
  export const WINDING: TechnologyType = 'Winding';
  export const FLEXOGRAPHY: TechnologyType = 'Flexography';
  export const WRAPPING: TechnologyType = 'Wrapping';
  export function values(): TechnologyType[] {
    return [DEFAULT, CUTTING, DIE_CUTTING, GLUING, MANUAL, PACKING, OFFSET_PRINTING, LAMINATING, WINDING, FLEXOGRAPHY, WRAPPING];
  }
}

export { TechnologyType };
