import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LogMessagesModalComponent } from './log-messages-modal/log-messages-modal.component';
import { FormComponentsModule } from '../form-components';
import { LogMessageComponent } from './log-messages-modal/log-message/log-message.component';
import { TooltipModule } from 'primeng/tooltip';
import { ModalConfirmComponent } from './modal-confirm';

@NgModule({
  declarations: [LogMessagesModalComponent, LogMessageComponent, ModalConfirmComponent],
  exports: [LogMessagesModalComponent, ModalConfirmComponent],
  imports: [CommonModule, TranslateModule.forChild(), FormComponentsModule, TooltipModule]
})
export class ModalsModule {}
