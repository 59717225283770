import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ShiftReportHeaderKpisQuery,
  ShiftReportHeaderKpisService,
  ShiftReportItem,
  ShiftReportOpenTask,
  ShiftReportOpenTasksQuery,
  ShiftReportOpenTasksService,
  ShiftReportOrdersQuery,
  ShiftReportOrdersService
} from '@app/core/global-state';
import {
  KpiValue,
  SendShiftReportEntry,
  Shift,
  ShiftReport,
  ShiftReportItemType,
  ShiftReportService,
  ShiftReportStatus
} from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportDsService {
  constructor(
    private shiftReportService: ShiftReportService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private shiftReportOrdersService: ShiftReportOrdersService,
    private shiftReportOrdersQuery: ShiftReportOrdersQuery,
    private shiftReportHeaderKpisService: ShiftReportHeaderKpisService,
    private shiftReportHeaderKpisQuery: ShiftReportHeaderKpisQuery,
    private shiftReportOpenTasksService: ShiftReportOpenTasksService,
    private shiftReportOpenTasksQuery: ShiftReportOpenTasksQuery
  ) {}

  public getShiftReportItems(shiftId: number): Observable<ShiftReportItem[]> {
    const params: ShiftReportService.GetShiftReportParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      shiftId
    };
    return this.shiftReportService.getShiftReport(params).pipe(
      tap((shiftReport: ShiftReport) => {
        this.shiftReportOrdersService.setShiftReportOrders(shiftReport, shiftReport.items.map(this.createItemId));
      }),
      switchMap(() => this.shiftReportOrdersQuery.shiftReportOrders$)
    );
  }

  public getShiftReportStatus(): Observable<ShiftReportStatus> {
    return this.shiftReportOrdersQuery.status$;
  }

  public getShiftReportRemarkId(): number {
    return this.shiftReportOrdersQuery.getRemarkId();
  }

  public filterShiftReportItemsByProductionOrder(externalProductionOrderId: string): void {
    this.shiftReportOrdersService.updateFilters({ productionOrder: externalProductionOrderId });
  }

  public updateShiftReportItems(shiftReportItem: ShiftReportItem): void {
    this.shiftReportOrdersService.updateShiftReportOrder(this.createItemId(shiftReportItem));
  }

  public resetShiftReportStore(): void {
    this.shiftReportOrdersService.resetShiftReportOrders();
  }

  public getShifts(): Observable<Shift[]> {
    return this.shiftReportService.getShifts(this.activeWorkCenterService.getWorkCenterId());
  }

  public isShiftEnded(shift: Shift): boolean {
    return shift.shiftReportStatus === ShiftReportStatus.CLOSED;
  }

  public getHeaderShiftKpis(shiftId: number): Observable<KpiValue[]> {
    const params: ShiftReportService.GetHeaderShiftKpisParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      shiftId
    };
    return this.shiftReportService.getHeaderShiftKpis(params).pipe(
      tap((data) => {
        this.shiftReportHeaderKpisService.setShiftReportHeaderKpis(data);
      }),
      switchMap(() => this.shiftReportHeaderKpisQuery.shiftReportHeaderKpis$)
    );
  }

  public updateHeaderShiftKpis(kpis: KpiValue[]): void {
    this.shiftReportHeaderKpisService.updateShiftReportHeaderKpis(kpis);
  }

  public getShiftOpenTasks(shiftId: number): Observable<ShiftReportOpenTask[]> {
    const params: ShiftReportService.GetOpenTasksParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      shiftId
    };
    return this.shiftReportService.getOpenTasks(params).pipe(
      tap((data) => {
        this.shiftReportOpenTasksService.setShiftReportOpenTasks(data);
      }),
      switchMap(() => this.shiftReportOpenTasksQuery.shiftReportOpenTasks$)
    );
  }

  public updateShiftOpenTasks(openTasks: ShiftReportOpenTask[]): void {
    this.shiftReportOpenTasksService.updateShiftReportOpenTasks(openTasks);
  }

  public sendShiftReport(shiftId: number, openTaskInfo: string, printReport: boolean): Observable<any> {
    const entry: SendShiftReportEntry = {
      shiftId,
      openTaskInfo,
      printReport
    };
    const params: ShiftReportService.SendShiftReportParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      entry
    };
    return this.shiftReportService.sendShiftReport(params);
  }

  private createItemId = (item) => {
    let id = '';
    if (item.type === ShiftReportItemType.PRODUCTION_ORDER) {
      id = item.productionOrder.runId;
    } else if (item.type === ShiftReportItemType.DOWNTIME) {
      id = item.downtime.id;
    }
    return { ...item, id: item.type + id };
  };
}
