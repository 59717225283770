import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ParametersFactoryService } from '@app/core/services/parameters-factory';

@Component({
  selector: 'app-modal-setup-parameters',
  templateUrl: './modal-setup-parameters.component.html',
  styleUrls: ['./modal-setup-parameters.component.scss']
})
export class ModalSetupParametersComponent implements OnInit, OnDestroy {
  public form = new UntypedFormGroup({});

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private parametersFactoryService: ParametersFactoryService
  ) {}

  public ngOnInit(): void {
    this.parametersFactoryService.setValuesForApprove(
      this.config.data?.workCenterId,
      this.config.data?.approvalReportLineId,
      this.config.data?.approvalReportDate,
      this.config.data?.orderItem
    );
  }

  public ngOnDestroy(): void {
    this.parametersFactoryService.cleanParametersFactoryValues();
  }

  public onSubmitClick(): void {
    this.ref.close();
  }
}
