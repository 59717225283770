<section *ngIf="documents$ | async as documents" class="document-section">
  <div class="document-section__title">
    {{ 'DOCUMENTS.GENERAL' | translate }}
  </div>

  <div class="document-list">
    <div class="document-header">
      <div class="document-header__info-field">
        {{ 'DOCUMENTS.DOCUMENT' | translate }}
      </div>
      <div class="document-header__info-field">
        {{ 'DOCUMENTS.CATEGORY' | translate }}
      </div>
      <div class="document-header__date-field">
        {{ 'DOCUMENTS.MODIFIED' | translate }}
      </div>
    </div>
    <div *ngFor="let document of documents.generalDocuments" class="document">
      <div
        class="document__info-field cursor-clickable"
        (click)="openDocument(document.downloadLink, document.id)"
        title="{{ document.name }}.{{ document.extension }}"
      >
        {{ document.name }}.{{ document.extension }}
      </div>
      <div class="document__info-field">
        {{ 'DOCUMENTS.DOCUMENTTYPE.' + document.documentType | uppercase | translate }}
      </div>
      <div class="document__date-field cursor-clickable" (click)="openDocument(document.downloadLink, document.id)">
        {{ document.documentTimeStamp | utcToLocal | date: 'dd.MM.yyyy HH:mm' }}
      </div>
      <div class="document__edit-button">
        <app-split-button [items]="createOpenButton(document.downloadLink, document.id)"></app-split-button>
      </div>
      <div class="document__read-icon">
        <i
          class="icon"
          [ngClass]="document.wasDisplayed ? 'icon-checked-solid' : document.isDisplayMandatory ? 'icon-warning-solid icon--warning' : ''"
        ></i>
      </div>
    </div>
  </div>
</section>
