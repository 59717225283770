import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentsService } from '@app/modules/documents/services';

@Component({
  selector: 'app-document-edit-modal',
  templateUrl: './document-edit-modal.component.html',
  styleUrls: ['./document-edit-modal.component.scss']
})
export class DocumentEditModalComponent implements OnInit {
  public remarkInfo: string;
  public isSubmitEnabled = false;
  private productionOrderId: number;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig, private documentsService: DocumentsService) {}

  public ngOnInit(): void {
    this.remarkInfo = this.config.data.remarkInfo;
    this.productionOrderId = this.config.data.productionOrderId;
    this.isRemarkInfoFilled();
  }

  public isRemarkInfoFilled(): void {
    this.isSubmitEnabled = this.remarkInfo?.length > 0;
  }

  public saveEditedRemarkInfo(): void {
    this.documentsService.saveEditedRemarkInfo(this.productionOrderId, this.remarkInfo).subscribe(() => {
      this.ref.close(true);
    });
  }

  public cancelRemarkInfoEditing(): void {
    this.ref.close();
  }
}
