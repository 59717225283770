/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DocumentInfoEntry } from '../models/document-info-entry';
import { MarkAsDisplayedEntry } from '../models/mark-as-displayed-entry';
import { ProductionOrderDocumentInfo } from '../models/production-order-document-info';
import { ProductionOrderDocuments } from '../models/production-order-documents';
import { ProductionOrderPrintedLabelInfo } from '../models/production-order-printed-label-info';
@Injectable({
  providedIn: 'root'
})
class DocumentManagementService extends __BaseService {
  static readonly editInformationPath = '/api/v1/DocumentManagement/{productionOrderId}/EditInfo';
  static readonly markAsDisplayedPath = '/api/v1/DocumentManagement/{productionOrderId}/MarkAsDisplayed';
  static readonly getProductionOrderInformationPath = '/api/v1/DocumentManagement/{productionOrderId}/GetProductionOrderInformation';
  static readonly getDocumentsPath = '/api/v1/DocumentManagement/{productionOrderId}/GetDocuments';
  static readonly getPrintedLabelInfoPath = '/api/v1/DocumentManagement/{productionOrderId}/GetPrintedLabelInfo';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `DocumentManagementService.EditInformationParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  editInformationResponse(params: DocumentManagementService.EditInformationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/DocumentManagement/${encodeURIComponent(String(params.productionOrderId))}/EditInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DocumentManagementService.EditInformationParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  editInformation(params: DocumentManagementService.EditInformationParams): __Observable<null> {
    return this.editInformationResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `DocumentManagementService.MarkAsDisplayedParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  markAsDisplayedResponse(params: DocumentManagementService.MarkAsDisplayedParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/DocumentManagement/${encodeURIComponent(String(params.productionOrderId))}/MarkAsDisplayed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DocumentManagementService.MarkAsDisplayedParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  markAsDisplayed(params: DocumentManagementService.MarkAsDisplayedParams): __Observable<null> {
    return this.markAsDisplayedResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param productionOrderId undefined
   */
  getProductionOrderInformationResponse(productionOrderId: number): __Observable<__StrictHttpResponse<ProductionOrderDocumentInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/DocumentManagement/${encodeURIComponent(String(productionOrderId))}/GetProductionOrderInformation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderDocumentInfo>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getProductionOrderInformation(productionOrderId: number): __Observable<ProductionOrderDocumentInfo> {
    return this.getProductionOrderInformationResponse(productionOrderId).pipe(__map((_r) => _r.body as ProductionOrderDocumentInfo));
  }

  /**
   * @param productionOrderId undefined
   */
  getDocumentsResponse(productionOrderId: number): __Observable<__StrictHttpResponse<ProductionOrderDocuments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/DocumentManagement/${encodeURIComponent(String(productionOrderId))}/GetDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderDocuments>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getDocuments(productionOrderId: number): __Observable<ProductionOrderDocuments> {
    return this.getDocumentsResponse(productionOrderId).pipe(__map((_r) => _r.body as ProductionOrderDocuments));
  }

  /**
   * @param productionOrderId undefined
   */
  getPrintedLabelInfoResponse(productionOrderId: number): __Observable<__StrictHttpResponse<ProductionOrderPrintedLabelInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/DocumentManagement/${encodeURIComponent(String(productionOrderId))}/GetPrintedLabelInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductionOrderPrintedLabelInfo>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getPrintedLabelInfo(productionOrderId: number): __Observable<ProductionOrderPrintedLabelInfo> {
    return this.getPrintedLabelInfoResponse(productionOrderId).pipe(__map((_r) => _r.body as ProductionOrderPrintedLabelInfo));
  }
}

module DocumentManagementService {
  /**
   * Parameters for EditInformation
   */
  export interface EditInformationParams {
    productionOrderId: number;
    entry: DocumentInfoEntry;
  }

  /**
   * Parameters for MarkAsDisplayed
   */
  export interface MarkAsDisplayedParams {
    productionOrderId: number;
    entry: MarkAsDisplayedEntry;
  }
}

export { DocumentManagementService };
