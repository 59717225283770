import { Component, OnInit } from '@angular/core';
import { WarehouseStockModalService } from '@app/modules/mounting/services/warehouse-stock-modal/warehouse-stock-modal.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { MaterialAvailableAtLocation, MaterialAvailableAtLocationItem, Quantity } from 'chronos-core-client';
import { finalize } from 'rxjs/operators';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-warehouse-stock-modal',
  templateUrl: './warehouse-stock-modal.component.html',
  styleUrls: ['./warehouse-stock-modal.component.scss']
})
export class WarehouseStockModalComponent implements OnInit {
  public secondaryMaterial$: Observable<MaterialAvailableAtLocation>;
  public suggestedQuantity: Quantity;
  public submitButtonLabel = 'MOUNTING.CONSUME';
  public tabName = '';
  public isDropDownOptionDisabled = false;
  public readonly LOADING_TOPIC = notificationTopic.modalWarehouseStock;
  constructor(
    private warehouseStockModalService: WarehouseStockModalService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.secondaryMaterial$ = this.warehouseStockModalService.getAvailableForConsumption();
    this.suggestedQuantity = this.config.data?.suggestedQuantity;
    this.tabName = this.config.data.tabName;
    this.isDropDownOptionDisabled = this.config.data?.isDropDownOptionDisabled;
  }

  public onConsumeClick([selectedMaterial, selectedMaterialQuantity]: [MaterialAvailableAtLocationItem, number, number]): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    let consumeObservable;
    let successLogMessage;
    if (this.warehouseStockModalService.isMaterialConsumable(selectedMaterial.article.classification)) {
      consumeObservable = this.warehouseStockModalService.consumeSecondary(selectedMaterial.containerId, selectedMaterialQuantity);
      successLogMessage = 'SUCCESS_MESSAGE.MATERIAL_CONSUMED';
    } else {
      consumeObservable = this.warehouseStockModalService.mountSecondary(selectedMaterial.containerId, selectedMaterial.identificationCode);
      successLogMessage = 'SUCCESS_MESSAGE.MATERIAL_MOUNTED';
    }

    consumeObservable
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        LogService.success(successLogMessage);
        this.ref.close({ materialDataChanged: true });
      });
  }

  public onSelectRow(material: MaterialAvailableAtLocationItem): void {
    if (material && !this.warehouseStockModalService.isMaterialConsumable(material.article.classification)) {
      this.submitButtonLabel = 'MOUNTING.MOUNT';
      this.config.header = this.translateService.instant('MOUNTING.MOUNTING');
    } else {
      this.submitButtonLabel = 'MOUNTING.CONSUME';
      this.config.header = this.translateService.instant('MOUNTING.WAREHOUSE_STOCK_TITLE');
    }
  }
}
