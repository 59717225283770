import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveOrderDsService, SecondaryMaterialDsService } from '@app/core/data-services';
import { ArticleClassification, MaterialAvailableAtLocation, MountContainerResponse } from 'chronos-core-client';

@Injectable()
export class WarehouseStockModalService {
  constructor(private activeOrderDsService: ActiveOrderDsService, private secondaryMaterialDsService: SecondaryMaterialDsService) {}

  public getAvailableForConsumption(): Observable<MaterialAvailableAtLocation> {
    return this.secondaryMaterialDsService.getMaterialList();
  }

  public consumeSecondary(containerId: number, consumedQuantity: number): Observable<null> {
    return this.secondaryMaterialDsService.consumeMaterial(containerId, consumedQuantity);
  }

  public mountSecondary(containerId: number, identificationCode: string): Observable<MountContainerResponse> {
    return this.secondaryMaterialDsService.mountSecondary(identificationCode, containerId, this.activeOrderDsService.getActiveOrderId());
  }

  public isMaterialConsumable(articleClassification: ArticleClassification): boolean {
    return articleClassification !== ArticleClassification.TOOL && articleClassification !== ArticleClassification.REEL;
  }
}
