/* tslint:disable */
type WorkCenterTaskStatus = 'Ok' | 'NotOk' | 'AutoOk';
module WorkCenterTaskStatus {
  export const OK: WorkCenterTaskStatus = 'Ok';
  export const NOT_OK: WorkCenterTaskStatus = 'NotOk';
  export const AUTO_OK: WorkCenterTaskStatus = 'AutoOk';
  export function values(): WorkCenterTaskStatus[] {
    return [OK, NOT_OK, AUTO_OK];
  }
}

export { WorkCenterTaskStatus };
