<div class="presetup-modal chronos-dialog--size-md">
  <div class="presetup-modal__container p-d-flex">
    <span *ngIf="combinationIcon" class="combination-icon"><i [ngClass]="combinationIcon"></i></span>
    <app-product-order (click)="toggleContent()" [orderItem]="productionOrder" [isItemCollapsed]="itemCollapsed"></app-product-order>
  </div>
  <form [formGroup]="form" libEnterKeyHandler="submit_modal" class="presetup-modal__container presetup-modal__container--grow">
    <app-parameters [form]="form"></app-parameters>
  </form>
  <lib-modal-footer
    [submitLabel]="'ORDER_ITEM.CONFIRM_DATA'"
    (submitClicked)="onSubmitClick()"
    [showDeclineButton]="false"
  ></lib-modal-footer>
</div>
