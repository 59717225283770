import { AfterContentChecked, AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { FormControlExtension, FormElement } from '../models';

@Directive({
  selector: 'form [libEnterKeyHandler]'
})
export class EnterKeyHandlerDirective implements AfterContentChecked, AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('libEnterKeyHandler') public submitButtonId: string;

  private elements: FormElement[] = [];
  private elementCount = 0;
  private isExpanded = false;

  constructor(private element: ElementRef, private control: FormGroupDirective) {}

  public ngAfterViewInit(): void {
    this.manageView();
  }

  public ngAfterContentChecked(): void {
    const elementCount = this.element.nativeElement.elements.length;

    if (this.elementCount !== elementCount) {
      this.elementCount = elementCount;
      this.manageView();
    }
  }

  private manageView(): void {
    this.elements = this.getFormElements();

    if (document.activeElement !== this.elements[0] && this.elements.length > 0) {
      this.manageElementFocus(this.elements[0]);
    }
  }

  @HostListener('keydown.alt.arrowdown', ['$event.target'])
  public onKeyAltArrowDown(element: FormElement): void {
    this.isExpanded = this.isElementExpanded(element);
  }

  @HostListener('click')
  public onClickCorrectElement(): void {
    if (document.hasFocus()) {
      this.isExpanded = this.isElementExpanded(document.activeElement);
    }
  }

  @HostListener('keyup.Enter', ['$event.target'])
  public onKeyEnter(element: FormElement): void {
    if (this.isExpanded) {
      this.isExpanded = false;
      return;
    }

    const root = this.control.form as FormControlExtension;
    const control = this.getControlFromInput(element, root);
    const isValid = control ? control.valid : element.validity.valid;

    if (isValid) {
      const index = this.elements.indexOf(element);
      const nextIndex = index + 1;

      if (nextIndex >= this.elements.length) {
        this.manageElementBlur(element);
      } else {
        this.manageElementFocus(this.elements[nextIndex]);
      }
    }
  }

  private getFormElements(): FormElement[] {
    const { elements } = this.element.nativeElement;
    const formElements = [];

    for (const element of elements) {
      if (this.isElementSupported(element) && this.isElementAvailable(element)) {
        formElements.push(element);
      }
    }

    return formElements;
  }

  private manageElementBlur(element: FormElement): void {
    element.blur();

    if (this.submitButtonId) {
      document.getElementById(this.submitButtonId)?.focus();
    }
  }

  private manageElementFocus(element: FormElement): void {
    element.focus();
  }

  private isElementExpanded(element: FormElement | Element): boolean {
    return element.getAttribute('aria-expanded') === 'true';
  }

  private hasElementListBox(element: FormElement): boolean {
    return element.getAttribute('aria-haspopup') === 'listbox';
  }

  private isElementAvailable(element: FormElement): boolean {
    return (!element.disabled && !element.readOnly) || (!element.disabled && this.hasElementListBox(element));
  }

  private isElementSupported(element: FormElement): boolean {
    return element.tagName === 'INPUT' || element.tagName === 'TEXTAREA';
  }

  private getControlFromInput(element: FormElement, root: FormControlExtension): FormControlExtension | null {
    const { controls } = root;

    for (const control of Object.values(controls)) {
      const nextControl = control as FormControlExtension;

      if (nextControl?.controls) {
        const foundControl = this.getControlFromInput(element, nextControl);

        if (foundControl) {
          return foundControl;
        }
      } else if (nextControl?.element === element) {
        return nextControl;
      }
    }
    return null;
  }
}
