import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AnimationState } from '@app/modules/mounting/models';
import { MountPalletItemService } from '@app/modules/mounting/services';
import { MountedMaterialStatus, PrimaryMaterialMounted } from 'chronos-core-client';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { palletQualtityStatusFlags, StatusParameter } from '@app/shared/models';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { MountedContainerQaCheckComponent } from '@app/modules/run-phase/containers/input/components/mounted-container-qa-check/mounted-container-qa-check.component';
import { AppSettingsQuery } from 'chronos-shared';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Component({
  selector: 'app-mount-pallet-list-item',
  templateUrl: './mount-pallet-list-item.component.html',
  styleUrls: ['./mount-pallet-list-item.component.scss']
})
export class MountPalletListItemComponent implements OnInit, OnDestroy {
  public readonly ANIMATION_STATES = AnimationState;
  public readonly INPUT_STYLE = { width: '60px', textAlign: 'end' };
  public currentMountedMaterial: PrimaryMaterialMounted;
  public palletQualityStatus: StatusParameter;
  public whiteWasteUnitId = '';
  public whiteWaste: Quantity = { value: 0, unitId: '' };

  @Input() public set mountedMaterial(mountedMaterial: PrimaryMaterialMounted) {
    this.currentMountedMaterial = mountedMaterial;
    this.whiteWasteUnitId = mountedMaterial.whiteWasteQuantity.unitId;
    this.whiteWaste.unitId = this.whiteWasteUnitId;
    this.palletQualityStatus = this.getPalletQualityStatus();
    if (this.animationState === this.ANIMATION_STATES.NONE) {
      this.startAnimation();
    }
  }
  @Input() public allowDismount = false;

  public animationState = this.ANIMATION_STATES.NONE;

  // Duration must be synced with component css
  private readonly ANIMATION_DURATION = 10000;
  private animationTimeoutId: number;
  private subscriptions = new Subscription();
  private isManual = false;
  private qaText = 'NotApplicable';
  public showStatus = true;

  constructor(
    private mountPalletItemService: MountPalletItemService,
    private appSettingsQuery: AppSettingsQuery,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.isManual = this.appSettingsQuery.isWorkCenterModeManual();
    this.subscriptions.add(
      this.mountPalletItemService.endAnimation$
        .pipe(
          filter((mountedMaterialId) => this.isMaterialIdEqual(mountedMaterialId)),
          tap(() => {
            this.clearAnimationTime();
          }),
          tap(() => {
            this.endAnimation();
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.stopAnimation(this.ANIMATION_STATES.NONE);
    this.subscriptions.unsubscribe();
  }

  public isDismountingAllowed(): boolean {
    return this.currentMountedMaterial.mountedMaterialStatus === MountedMaterialStatus.MOUNTED || this.allowDismount || this.isManual;
  }

  public onDismountClick(mountedMaterial: PrimaryMaterialMounted): void {
    if (this.isDismountingAllowed()) {
      this.mountPalletItemService.onDismountClick(mountedMaterial);
    }
  }

  public onReplaceClick(materialId: number, dummyContainerId: number): void {
    this.mountPalletItemService.onReplaceClicked(materialId, dummyContainerId);
  }

  public onInfoButtonClick(): void {
    this.mountPalletItemService.onInfoButtonClick(this.currentMountedMaterial);
  }

  public addContainerWhiteWaste(mountedMaterialId: number): void {
    if (this.hasWhiteWaste()) {
      this.stopAnimation(this.ANIMATION_STATES.NONE);
      this.mountPalletItemService.onAddContainerWaste(mountedMaterialId, this.whiteWaste);
    }
  }

  public resetContainerWhiteWaste(mountedMaterialId: number): void {
    if (this.hasMountedWhiteWaste()) {
      this.stopAnimation(this.ANIMATION_STATES.NONE);
      this.mountPalletItemService.onResetContainerWhiteWaste(mountedMaterialId);
      this.whiteWaste.value = 0;
    }
  }

  private stopAnimation(state: AnimationState): void {
    if (this.animationState === this.ANIMATION_STATES.PLAYING) {
      clearTimeout(this.animationTimeoutId);
      this.animationTimeoutId = null;
    }

    this.animationState = state;
  }

  private startAnimation(): void {
    if (this.shouldShowAnimation()) {
      this.animationTimeoutId = window.setTimeout(() => {
        this.endAnimation();
      }, this.ANIMATION_DURATION);
      this.animationState = this.ANIMATION_STATES.PLAYING;
    }
  }

  private shouldShowAnimation(): boolean {
    const isPalletConsumed = this.currentMountedMaterial.mountedMaterialStatus === MountedMaterialStatus.CONSUMED;
    const isPalletReal = !this.currentMountedMaterial.container.isVirtualContainer;

    return isPalletConsumed && isPalletReal;
  }

  private endAnimation(): void {
    this.mountPalletItemService.onTimeoutEnded(this.currentMountedMaterial.mountedMaterialId);
    this.animationState = this.ANIMATION_STATES.NONE;
  }

  private hasWhiteWaste(): boolean {
    return !!this.whiteWaste;
  }

  private hasMountedWhiteWaste(): boolean {
    return this.currentMountedMaterial.whiteWasteQuantity.value > 0;
  }

  public startAnimationOnBlur(): void {
    if (!this.hasWhiteWaste()) {
      this.startAnimation();
    }
  }

  public stopAnimationOnFocus(): void {
    this.stopAnimation(this.ANIMATION_STATES.STOPPED);
  }

  private clearAnimationTime(): void {
    clearTimeout(this.animationTimeoutId);
    this.animationTimeoutId = null;
  }

  private isMaterialIdEqual(mountedMaterialId: number): boolean {
    return mountedMaterialId === this.currentMountedMaterial.mountedMaterialId;
  }

  public openQACheckModalDialog(): void {
    this.dialogService.open(MountedContainerQaCheckComponent, {
      header: this.translateService.instant('QA_LOG.QA_CHECKS'),
      data: {
        container: this.currentMountedMaterial.container,
        sequenceNumber: this.currentMountedMaterial.materialSequence
      }
    });
  }

  private getPalletQualityStatus(): StatusParameter {
    if (this.currentMountedMaterial.qaCheckResult) {
      if (this.currentMountedMaterial.qaCheckResult === this.qaText) {
        this.showStatus = false;
      }

      return palletQualtityStatusFlags[this.currentMountedMaterial.qaCheckResult];
    }
  }
}
