import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-block-content',
  templateUrl: './block-content.component.html',
  styleUrls: ['./block-content.component.scss']
})
export class BlockContentComponent {
  @Input() public blocked = false;
  @Input() public text = 'BLOCK_UI.DEFAULT_TEXT';
}
