import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[libChronosInput]'
})
export class ChronosInputDirective {
  @HostBinding('attr.maxlength')
  @Input()
  public maxInputLength = '60';

  constructor() {}
}
