import { knownUnits } from '../../models/knownUnits.model';

export class UnitConverter {
  public static minimalQuantity(unitId: string): number {
    if (knownUnits.WholeUnits.includes(unitId)) {
      return 1;
    } else {
      return 0.01;
    }
  }
}
