import { NgModule } from '@angular/core';
import { OverviewModalService, ShiftReportModalService, ShiftReportService } from './services';
import { SharedModule } from '@app/shared/shared.module';
import { OrderItemComponent } from './containers/shift-report/components/order-item/order-item.component';
import { TaskItemComponent } from './containers/shift-report/components/task-item/task-item.component';
import { ShiftReportComponent } from './containers/shift-report/shift-report.component';
import { OverviewModalComponent, SendReportModalComponent } from './modals';
import { KpiPipe } from './pipes';

@NgModule({
  declarations: [ShiftReportComponent, OrderItemComponent, OverviewModalComponent, TaskItemComponent, SendReportModalComponent, KpiPipe],
  imports: [SharedModule],
  providers: [ShiftReportService, ShiftReportModalService, OverviewModalService]
})
export class ShiftReportModule {}
