import { Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { EmployeeQuery } from '@app/core/global-state/employees/employee.query';
import { EmployeeService } from '@app/core/global-state/employees/employee.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { EmployeeRegistrationDetailsService } from '@app/modules/employee-registration/services/employee-registration.service';
import { TranslateService } from '@ngx-translate/core';
import { ActiveEmployeeRegistration, AvailableEmployee } from 'chronos-core-client';
import { AppSettingsQuery, LogService } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginWindowComponent } from '../../login-window';
@Component({
  selector: 'app-employee-registration-login',
  templateUrl: './employee-registration-login.component.html',
  styleUrls: ['./employee-registration-login.component.scss']
})
export class EmployeeRegistrationLoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchBox', { static: false }) private searchBox: ElementRef;
  @ViewChild('rfidBox', { static: false }) private rfidBox: ElementRef;

  public activeEmployeeRegistrations: ActiveEmployeeRegistration[];
  public availableEmployees: AvailableEmployee[];
  public textQuery? = '';
  public rfid? = '';
  private subscriptions = new Subscription();
  private workCenterId: number;
  private isRFIDEnabled: boolean;

  constructor(
    private employeeRegistrationDetailsService: EmployeeRegistrationDetailsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    public employeeQuery: EmployeeQuery,
    public employeeService: EmployeeService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    public appSettingsQuery: AppSettingsQuery
  ) {}

  public ngOnInit(): void {
    this.workCenterId = this.activeWorkCenterService.getWorkCenterId();
    this.employeeService.getEmployeeRegistrationData();
    this.subscriptions.add(this.employeeRegistrationDetailsService.getEmployeeListNotifications(this.workCenterId).subscribe());
    this.fillEmployee();
    this.isRFIDEnabled = this.appSettingsQuery.enableLoginWithBadgeId();
    this.employeeService.updateFilters({
      isRFIDEnabled: this.isRFIDEnabled
    });
  }

  private fillEmployee(): void {
    this.employeeQuery.loggedInEmployees$
      .pipe(
        tap((loggedIn) => {
          this.activeEmployeeRegistrations = loggedIn;
        })
      )
      .subscribe();
  }

  public onFilterChange(): void {
    this.employeeService.updateFilters({
      textQuery: this.textQuery,
      rfid: this.rfid,
      isRFIDEnabled: this.isRFIDEnabled
    });
  }

  public onLoggedInEmpoyeeBlur(loggedInEmployee: ActiveEmployeeRegistration): void {
    this.employeeRegistrationDetailsService.updateRegistration(loggedInEmployee, this.workCenterId).subscribe(
      () => null,
      () => {
        this.employeeService.getEmployeeRegistrationData();
      }
    );
  }

  public onLogOut(loggedInEmployee: ActiveEmployeeRegistration): void {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    this.employeeRegistrationDetailsService.logOut(loggedInEmployee.employeeId, workCenterId).subscribe(() => {
      LogService.success('SUCCESS_MESSAGE.LOGGEDOUT_SUCCESSFULLY');
    });
  }

  public onLogIn(emp: AvailableEmployee): void {
    const modal = this.dialogService.open(LoginWindowComponent, {
      header: this.translateService.instant('EMPLOYEEREGISTRATION.EMPLOYEE_LOGIN'),
      data: {
        employee: emp
      }
    });
    modal.onClose.subscribe((result) => {
      if (result) {
        this.rfid = '';
        this.onFilterChange();
        return;
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.subscriptions.add(
      this.employeeRegistrationDetailsService.setFocusSubject$.subscribe(() => {
        let element;
        if (this.appSettingsQuery.enableLoginWithBadgeId()) {
          element = this.rfidBox.nativeElement;
        } else {
          element = this.searchBox.nativeElement;
        }
        setTimeout(() => {
          element.focus();
        }, 10);
      })
    );
  }
}
