<ng-container *ngIf="fields" [formGroup]="form">
  <div class="qa-parameters__table">
    <div class="qa-parameters__header row no-gutters">
      <div class="col-4 first-column">
        <span>{{ 'SETUP_QUALITY_ASSURANCE.CHECK' | translate }}</span>
      </div>
      <div class="col-3">
        <div class="qa-parameters__header row">
          <div class="col-9">
            <span>{{ 'SETUP_QUALITY_ASSURANCE.VALUE' | translate }}</span>
          </div>
          <div class="col-3" style="margin-right: 0px">
            <span>{{ 'SETUP_QUALITY_ASSURANCE.NC_RELEVANT' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-auto">
        <span>{{ 'SETUP_QUALITY_ASSURANCE.RESULT' | translate }}</span>
      </div>
      <div class="col-3">
        <span>{{ 'SETUP_QUALITY_ASSURANCE.INFO' | translate }}</span>
      </div>
    </div>
    <div class="qa-parameters__body" [style.maxHeight]="scrollHeight">
      <ng-container *ngFor="let qaForm of qaCheckService.getQaCheckFormControls(form)">
        <div class="qa-parameters__element row no-gutters">
          <div class="col">
            <app-qa-check-element
              [field]="qaCheckService.getQaCheckDescriptionData(qaForm.get('id').value, fields)"
              [parentForm]="qaForm"
              [isReadOnly]="isReadOnly"
            ></app-qa-check-element>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="qa-parameters__footer row no-gutters" *ngIf="showAllQACheckButton">
      <div class="all-ok-checkbox col-4">
        <p-checkbox
          (onChange)="setFormFieldsToOk()"
          [(ngModel)]="allOk"
          [label]="'SETUP_QUALITY_ASSURANCE.ALL_OK' | translate"
          [ngModelOptions]="{ standalone: true }"
          binary="true"
        ></p-checkbox>
      </div>
    </div>
  </div>
</ng-container>
