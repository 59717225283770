<div
  [ngClass]="{ 'pallet-card--active': inputPallet.mountedMaterialStatus === MOUNTED_MATERIAL_STATUS.ACTIVE }"
  class="pallet-card"
  *ngIf="inputPallet"
>
  <div class="pallet-card__body cursor-clickable" (click)="toggleContainer()">
    <div class="pallet-card__labels"></div>
    <div class="collapse-button">
      <span>
        <i [ngClass]="isExpanded ? 'icon-arrows-right-filled-close' : 'icon-arrow-right-filled-open'"></i>
      </span>
    </div>
    <lib-pallet-number
      class="pallet-card__number"
      [palletNumber]="inputPallet.materialSequence"
      [classification]="inputPallet.article.classification"
    ></lib-pallet-number>

    <div>
      <div *ngIf="inputPallet.qaCheckResult && showStatus" class="quality_checkResult">
        <ng-container>
          <lib-quality-status
            (click)="openQACheckModalDialog()"
            [checkClass]="palletQualityStatus.labelClass"
            [checkText]="palletQualityStatus.text | translate"
          ></lib-quality-status>
        </ng-container>
      </div>
      <div class="sscc sscc_quality">
        <div class="sscc__wrapper sscc_padding">
          <span class="sscc__label">{{ 'MOUNTING.SSCC' | translate }}</span>
          <span class="sscc__value">
            {{ inputPallet.identificationCode | shortSsccCode }}
          </span>
        </div>
      </div>
    </div>
    <div class="quantity quantity--last">
      <div class="quantity__wrapper">
        <ng-container *ngIf="inputPallet.mountedMaterialStatus === MOUNTED_MATERIAL_STATUS.CONSUMED; else notConsumedPallet">
          <div class="quantity__label">{{ 'PALLET_CARD.CONSUMED_QUANTITY' | translate }}</div>
          <div [title]="inputPallet.consumedQuantity.value | number" class="quantity__value">
            <span class="remaining-quantity">{{ inputPallet.consumedQuantity | formatQuantity : false }}</span>
            <span class="quantity__units">{{ inputPallet.consumedQuantity.unitId }}</span>
          </div>
        </ng-container>
        <ng-template #notConsumedPallet>
          <div class="quantity__label">{{ 'PALLET_CARD.REMAINING_QUANTITY' | translate }}</div>
          <div [title]="inputPallet.mountedQuantity.value | number" class="quantity__value">
            <span [ngClass]="{ 'quantity__value--negative': inputPallet.mountedQuantity.value < 0 }" class="remaining-quantity">
              {{ inputPallet.mountedQuantity | formatQuantity : false }}
            </span>
            <span class="quantity__units">{{ inputPallet.mountedQuantity.unitId }}</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <app-additional-data *ngIf="isExpanded" [title]="'MOUNTING.DETAILS' | translate">
    <div class="detail-info-table__info">
      <div class="detail-info-table__info-titles">
        <div>{{ 'MOUNTING.ARTICLE' | translate }}</div>
        <div>{{ 'MOUNTING.NAME' | translate }}</div>
        <div>{{ 'MOUNTING.MOUNTED' | translate }}</div>
        <div>{{ 'MOUNTING.CONSUMED' | translate }}</div>
      </div>

      <div class="detail-info-table__info-values">
        <div>{{ inputPallet.article.externalArticleId }} / {{ inputPallet.article.externalConfigurationId | emptyTextPlaceholder }}</div>
        <div>{{ inputPallet.article.articleName }}</div>
        <div>{{ inputPallet.mountedQuantity | formatQuantity : true }}</div>
        <div>{{ inputPallet.consumedQuantity | formatQuantity : true }}</div>
      </div>
    </div>
  </app-additional-data>
</div>
