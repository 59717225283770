<div class="mount-pallet-list mount-pallet-list--dummy">
  <div class="mount-pallet-list__pallet-info">
    <lib-pallet-number
      class="mount-pallet-list__kpi"
      [palletNumber]="consumedContainer.materialSequence"
      [classification]="consumedContainer.article.classification"
    ></lib-pallet-number>

    <lib-pallet-kpi
      class="mount-pallet-list__kpi"
      [compact]="true"
      [showInfoIcon]="true"
      (infoButtonClicked)="onInfoButtonClick()"
      [kpiLabel]="'MOUNTING.SSCC' | translate"
      [kpiValue]="consumedContainer.identificationCode"
      [isSsccCodeShort]="true"
    ></lib-pallet-kpi>
  </div>

  <div class="mount-pallet-list__actions">
    <div class="waste-actions">
      <span>{{ consumedContainer.consumedQuantity | formatQuantity : false }}</span>
      <span class="waste-actions-dummy__unit">{{ consumedContainer.consumedQuantity.unitId }}</span>
    </div>
  </div>

  <div class="mount-pallet-list__article">
    <div class="article-action">
      <div>
        {{ consumedContainer.article?.externalArticleId }}
      </div>
      <div>
        {{ consumedContainer.article?.externalConfigurationId }}
      </div>
    </div>
  </div>

  <div class="mount-pallet-list__other-actions" style="margin-left: -20px">
    <div>
      <i class="icon-warning-solid input-icon"></i>
    </div>
    <div class="label">
      {{ 'MOUNTING.DUMMY' | translate }}
    </div>
    <div class="waste-actions">
      <div class="waste-actions__input">
        <lib-button class="waste-actions__button" [label]="'MOUNTING.REPLACE'" (clicked)="onReplaceClick()"></lib-button>
      </div>
    </div>
    <div>
      <i class="icon-unmount-pallet input-icon input-icon--dismount" (click)="onDismountClick(consumedContainer)"></i>
    </div>
  </div>
</div>
