import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { KPI, ValuePropertyBag, KPIValueBag } from '../../models/speed-monitor.model';
import { SpeedMonitorService } from '../../services/speed-monitor.service';

@Component({
  selector: 'lib-downtime-mode',
  templateUrl: './downtime-mode.component.html',
  styleUrls: ['./downtime-mode.component.scss']
})
export class DowntimeModeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public valuePropertyBag: ValuePropertyBag;
  @Input() public kpiValueBag: KPIValueBag;
  @Input() public machineData: any;
  @Input() public deltaTime: number;
  @Input() public state: string;
  public reasontext = '';
  public targetDowntime = '00:00:00';
  public targetText = 'Target Downtime';
  public lastDisplayTime: number;
  public timeoutHandler: any;
  public kpis: KPI[] = [];
  public currentKpiIndex = 0;
  public currentKpi: KPI;
  public currentKpiName: string;
  public currentKpiValue: number;
  public durationHandler: any;

  constructor(private speedMonitorService: SpeedMonitorService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.kpiValueBag) {
      this.kpis = changes.kpiValueBag.currentValue.kpiValue;
      if (this.kpis && this.kpis.length > 0) {
        this.setKpiValues();
      }
    }

    if (changes.state) {
      clearTimeout(this.durationHandler);
    }
  }

  public ngOnInit() {
    if (history.state.flag) {
      this.kpis = history.state.kpiList;
    }

    this.kpis = this.valuePropertyBag.kpiValueList;
    if (this.kpis && this.kpis.length > 0) {
      this.setKpiValues();
    }
    this.updateDuration(this.deltaTime);
  }

  public updateDuration(deltaTime: number): void {
    this.valuePropertyBag.duration = this.speedMonitorService.getDuration(this.machineData.startTime, deltaTime);
    this.durationHandler = setTimeout(() => {
      this.updateDuration(deltaTime);
    }, 1000);
  }

  public setKpiValues() {
    this.currentKpi = this.kpis[this.currentKpiIndex];
    if (this.currentKpi.value) {
      if (this.currentKpi.value % 1 !== 0) {
        this.currentKpi.value = Number(this.currentKpi.value.toFixed(2));
      }
    }
  }

  public ngOnDestroy() {
    clearTimeout(this.durationHandler);
  }
}
