/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PandaApiConfiguration } from '../panda-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TelemetryQueryDto } from '../models/telemetry-query-dto';
import { TelemetryResultDto } from '../models/telemetry-result-dto';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService extends BaseService {
  constructor(config: PandaApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiTelemetryPost
   */
  static readonly ApiTelemetryPostPath = '/api/Telemetry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTelemetryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTelemetryPost$Plain$Response(params?: { body?: TelemetryQueryDto }): Observable<StrictHttpResponse<TelemetryResultDto>> {
    const rb = new RequestBuilder(this.rootUrl, TelemetryService.ApiTelemetryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TelemetryResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTelemetryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTelemetryPost$Plain(params?: { body?: TelemetryQueryDto }): Observable<TelemetryResultDto> {
    return this.apiTelemetryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TelemetryResultDto>) => r.body as TelemetryResultDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTelemetryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTelemetryPost$Response(params?: { body?: TelemetryQueryDto }): Observable<StrictHttpResponse<TelemetryResultDto>> {
    const rb = new RequestBuilder(this.rootUrl, TelemetryService.ApiTelemetryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TelemetryResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTelemetryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTelemetryPost(params?: { body?: TelemetryQueryDto }): Observable<TelemetryResultDto> {
    return this.apiTelemetryPost$Response(params).pipe(map((r: StrictHttpResponse<TelemetryResultDto>) => r.body as TelemetryResultDto));
  }

  /**
   * Path part for operation apiTelemetryBulkGetPost
   */
  static readonly ApiTelemetryBulkGetPostPath = '/api/Telemetry/BulkGet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTelemetryBulkGetPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTelemetryBulkGetPost$Plain$Response(params?: {
    body?: Array<TelemetryQueryDto>;
  }): Observable<StrictHttpResponse<Array<TelemetryResultDto>>> {
    const rb = new RequestBuilder(this.rootUrl, TelemetryService.ApiTelemetryBulkGetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TelemetryResultDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTelemetryBulkGetPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTelemetryBulkGetPost$Plain(params?: { body?: Array<TelemetryQueryDto> }): Observable<Array<TelemetryResultDto>> {
    return this.apiTelemetryBulkGetPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TelemetryResultDto>>) => r.body as Array<TelemetryResultDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTelemetryBulkGetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTelemetryBulkGetPost$Response(params?: {
    body?: Array<TelemetryQueryDto>;
  }): Observable<StrictHttpResponse<Array<TelemetryResultDto>>> {
    const rb = new RequestBuilder(this.rootUrl, TelemetryService.ApiTelemetryBulkGetPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TelemetryResultDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTelemetryBulkGetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTelemetryBulkGetPost(params?: { body?: Array<TelemetryQueryDto> }): Observable<Array<TelemetryResultDto>> {
    return this.apiTelemetryBulkGetPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TelemetryResultDto>>) => r.body as Array<TelemetryResultDto>)
    );
  }
}
