import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  DocumentInfoEntry,
  DocumentManagementService,
  MarkAsDisplayedEntry,
  ProductionOrderDocumentInfo,
  ProductionOrderDocuments,
  ProductionOrderPrintedLabelInfo
} from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DocumentsDsService {
  constructor(private documentManagementService: DocumentManagementService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public getDocumentList(productionOrderId: number): Observable<ProductionOrderDocuments> {
    return this.documentManagementService.getDocuments(productionOrderId);
  }

  public getProductionOrderInfo(productionOrderId: number): Observable<ProductionOrderDocumentInfo> {
    return this.documentManagementService.getProductionOrderInformation(productionOrderId);
  }

  public getProductionOrderLabelInfo(productionOrderId: number): Observable<ProductionOrderPrintedLabelInfo> {
    return this.documentManagementService.getPrintedLabelInfo(productionOrderId);
  }

  public saveRemark(productionOrderId: number, documentInfo: string): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: DocumentInfoEntry = {
      documentInfo,
      workCenterId
    };

    const params: DocumentManagementService.EditInformationParams = {
      productionOrderId,
      entry
    };

    return this.documentManagementService.editInformation(params);
  }

  public markAsDisplayed(productionOrderId: number, documentId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: MarkAsDisplayedEntry = {
      documentId,
      workCenterId,
      isAuthorized: true /* will be overwritten by the backend */
    };

    const params: DocumentManagementService.MarkAsDisplayedParams = {
      productionOrderId,
      entry
    };

    return this.documentManagementService.markAsDisplayed(params);
  }
}
