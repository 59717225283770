/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AclRoleList } from '../models/acl-role-list';
import { AclroleAr } from '../models/aclrole-ar';
@Injectable({
  providedIn: 'root'
})
class RoleService extends __BaseService {
  static readonly getRoleNamesByOrgPath = '/api/Role/{orid}';
  static readonly addRolePath = '/api/Role';
  static readonly updateRolePath = '/api/Role';
  static readonly removeRolePath = '/removerole/{arid}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getRoleNamesByOrgResponse(orid: number): __Observable<__StrictHttpResponse<Array<AclRoleList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Role/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AclRoleList>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getRoleNamesByOrg(orid: number): __Observable<Array<AclRoleList>> {
    return this.getRoleNamesByOrgResponse(orid).pipe(__map((_r) => _r.body as Array<AclRoleList>));
  }

  /**
   * @param body undefined
   * @return Success
   */
  addRoleResponse(body?: AclroleAr): __Observable<__StrictHttpResponse<AclroleAr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Role`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AclroleAr>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  addRole(body?: AclroleAr): __Observable<AclroleAr> {
    return this.addRoleResponse(body).pipe(__map((_r) => _r.body as AclroleAr));
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateRoleResponse(body?: AclroleAr): __Observable<__StrictHttpResponse<AclroleAr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Role`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AclroleAr>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateRole(body?: AclroleAr): __Observable<AclroleAr> {
    return this.updateRoleResponse(body).pipe(__map((_r) => _r.body as AclroleAr));
  }

  /**
   * @param arid undefined
   * @return Success
   */
  removeRoleResponse(arid: number): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/removerole/${encodeURIComponent(String(arid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param arid undefined
   * @return Success
   */
  removeRole(arid: number): __Observable<number> {
    return this.removeRoleResponse(arid).pipe(__map((_r) => _r.body as number));
  }
}

module RoleService {}

export { RoleService };
