<section class="chronos-dialog--size-xs">
  <lib-material-status [title]="'MOUNTING.ACTIVE_MATERIAL' | translate" [article]="article"></lib-material-status>
  <form [libEnterKeyHandler]="'submit_modal'" [formGroup]="modalForm">
    <div class="row no-gutters trace-material">
      <div class="col-4">
        <label for="internalBatchId">{{ 'MOUNTING.MANUAL_BATCH_ID' | translate }}</label>
      </div>
      <div class="col">
        <input
          id="internalBatchId"
          class="trace-material__input"
          type="text"
          [formControlName]="'internalBatchId'"
          libChronosInput
          pInputText
        />
      </div>
    </div>
    <ng-container *ngIf="showInactive">
      <div class="row no-gutters trace-material">
        <div class="col-4">
          <label for="externalBatchId">{{ 'MOUNTING.ID_EXTERNAL' | translate }}</label>
        </div>
        <div class="col">
          <input
            id="externalBatchId"
            class="trace-material__input"
            type="text"
            [formControlName]="'externalBatchId'"
            libChronosInput
            pInputText
          />
        </div>
      </div>
      <div class="row no-gutters trace-material">
        <div class="col-4">
          <label for="batchInfo">{{ 'MOUNTING.INFO' | translate }}</label>
        </div>
        <div class="col">
          <textarea
            id="batchInfo"
            class="trace-material__input"
            [formControlName]="'batchInfo'"
            [rows]="5"
            libChronosTextarea
            pInputTextarea
          ></textarea>
        </div>
      </div>
    </ng-container>
  </form>
  <lib-modal-footer
    (submitClicked)="createAndAssignTraceMaterial()"
    (declineClicked)="closeAssignModal(false)"
    [submitLabel]="'MOUNTING.ASSIGN'"
    [submitDisabled]="modalForm.invalid || modalForm.disabled"
    [whenLoadingTopic]="LOADING_TOPIC"
  ></lib-modal-footer>
</section>
