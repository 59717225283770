/* tslint:disable */
type NonConformanceType = 'Debitor' | 'Kreditor' | 'Production';
module NonConformanceType {
  export const DEBITOR: NonConformanceType = 'Debitor';
  export const KREDITOR: NonConformanceType = 'Kreditor';
  export const PRODUCTION: NonConformanceType = 'Production';
  export function values(): NonConformanceType[] {
    return [DEBITOR, KREDITOR, PRODUCTION];
  }
}

export { NonConformanceType };
