<div class="phase-header">
  <div *ngIf="headerKpis$ | async as headerKpis" class="p-grid p-align-stretch p-nogutter">
    <div class="p-col-5">
      <div class="p-grid p-nogutter">
        <div class="p-col-4">
          <lib-speed-monitor [mode]="screenMode" [workCenterId]="workCenterId"></lib-speed-monitor>
        </div>
        <ng-container *ngIf="showInactive">
          <div class="p-col-4">
            <div class="deviation">
              <span class="deviation__label">{{ 'PHASE_HEADER.DEVIATION_FROM_PLAN' | translate }}</span>
              <span class="deviation__value">{{ headerKpis.deviationFromPlan | elapsedTime }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="p-col-7">
      <div class="p-grid p-nogutter">
        <div class="p-col-5">
          <div class="quantity">
            <span class="quantity__label">{{ 'PHASE_HEADER.GOOD_QUANTITY' | translate }}</span>
            <span class="quantity__value">{{ headerKpis.goodQuantity | formatQuantity: false }}</span>
          </div>
        </div>

        <div class="p-col-2">
          <ng-container>
            <app-header-waste-display [waste]="headerKpis.waste" [maculature]="headerKpis.maculature"></app-header-waste-display>
          </ng-container>
        </div>

        <div class="p-col-5">
          <div class="quantity">
            <span class="quantity__label">{{ 'PHASE_HEADER.REMAINING_QUANTITY' | translate }}</span>
            <span
              class="quantity__value"
              *ngIf="headerKpis.remainingQuantity.value; else noRemainingQuantity"
              [ngClass]="{ 'quantity__value--negative': headerKpis.remainingQuantity.value < 0 }"
            >
              {{ headerKpis.remainingQuantity | formatQuantity: false }}
            </span>
            <ng-template #noRemainingQuantity>
              <span class="quantity__value">0</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
