import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface Version {
  versionNumber: string;
  buildNumber: string;
}

@Component({
  selector: 'lib-version-number',
  templateUrl: './version-number.component.html',
  styleUrls: ['./version-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionNumberComponent {
  @Input() public version: Version;
}
