/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApprovalEntry } from '../models/approval-entry';
import { ApprovalReport } from '../models/approval-report';
import { SetSetupParameterKindEntry } from '../models/set-setup-parameter-kind-entry';
import { SetSetupParameterEntry } from '../models/set-setup-parameter-entry';
import { SetProductionQuantitiesEntry } from '../models/set-production-quantities-entry';
import { EditProductionOrderSetupPhaseEntry } from '../models/edit-production-order-setup-phase-entry';
import { ChangeApprovalReportQuantityEntry } from '../models/change-approval-report-quantity-entry';
import { InterruptProductionOrderEntry } from '../models/interrupt-production-order-entry';
import { ReloadApprovalReportDataEntry } from '../models/reload-approval-report-data-entry';
import { ApprovalWorkCenter } from '../models/approval-work-center';
import { ShiftInfoList } from '../models/shift-info-list';
import { InfoList } from '../models/info-list';
import { ApprovalReportDates } from '../models/approval-report-dates';
import { SetupParameterKind } from '../models/setup-parameter-kind';
import { SetupParameters } from '../models/setup-parameters';
import { ApprovalReportConsumption } from '../models/approval-report-consumption';
@Injectable({
  providedIn: 'root'
})
class ApprovalReportService extends __BaseService {
  static readonly confirmApprovalReportPath = '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}';
  static readonly getApprovalReportPath = '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}';
  static readonly putApprovalReportSetupParameterKindPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/setupParameterKind';
  static readonly putApprovalReportSetupParameterPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/setupParameters/{setupParameterId}';
  static readonly putApprovalReportProductionQuantitiesPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/productionQuantities';
  static readonly editApprovalReportSetupPhasePath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/EditSetupPhase';
  static readonly changeQuantityPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/changeQuantity';
  static readonly interruptProductionOrderPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/interruptProductionOrder';
  static readonly reloadApprovalReportDataPath = '/api/v1/workCenter/{workCenterId}/approvals/reloadApprovalReportData';
  static readonly getApprovalWorkCentersPath = '/api/v1/workCenterGroups/{workCenterGroupId}/approvals/{approvalReportDate}/workCenters';
  static readonly getShiftInfosPath = '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/shifts/info';
  static readonly getProductionOrderInfosPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/productionOrders/{productionOrderId}/info';
  static readonly getDowntimeInfosPath = '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/downtimes/{downtimeId}/info';
  static readonly getProducedMaterialInfosPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/producedMaterials/{producedMaterialId}/info';
  static readonly getOpenApprovalReportDatesPath = '/api/v1/workCenterGroups/{workCenterId}/approvals/approvalReportDates';
  static readonly getApprovalSetupParameterKindsPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/setupParameterKinds';
  static readonly getApprovalSetupParametersPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/setupParameters';
  static readonly getProductionOrderConsumptionPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/consumption';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `ApprovalReportService.ConfirmApprovalReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportDate`:
   *
   * - `approvalEntry`:
   */
  confirmApprovalReportResponse(params: ApprovalReportService.ConfirmApprovalReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.approvalEntry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.ConfirmApprovalReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportDate`:
   *
   * - `approvalEntry`:
   */
  confirmApprovalReport(params: ApprovalReportService.ConfirmApprovalReportParams): __Observable<null> {
    return this.confirmApprovalReportResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ApprovalReportService.GetApprovalReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportDate`:
   */
  getApprovalReportResponse(params: ApprovalReportService.GetApprovalReportParams): __Observable<__StrictHttpResponse<ApprovalReport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApprovalReport>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetApprovalReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportDate`:
   */
  getApprovalReport(params: ApprovalReportService.GetApprovalReportParams): __Observable<ApprovalReport> {
    return this.getApprovalReportResponse(params).pipe(__map((_r) => _r.body as ApprovalReport));
  }

  /**
   * @param params The `ApprovalReportService.PutApprovalReportSetupParameterKindParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  putApprovalReportSetupParameterKindResponse(
    params: ApprovalReportService.PutApprovalReportSetupParameterKindParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/setupParameterKind`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.PutApprovalReportSetupParameterKindParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  putApprovalReportSetupParameterKind(params: ApprovalReportService.PutApprovalReportSetupParameterKindParams): __Observable<null> {
    return this.putApprovalReportSetupParameterKindResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ApprovalReportService.PutApprovalReportSetupParameterParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `setupParameterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  putApprovalReportSetupParameterResponse(
    params: ApprovalReportService.PutApprovalReportSetupParameterParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/setupParameters/${encodeURIComponent(
          String(params.setupParameterId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.PutApprovalReportSetupParameterParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `setupParameterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  putApprovalReportSetupParameter(params: ApprovalReportService.PutApprovalReportSetupParameterParams): __Observable<null> {
    return this.putApprovalReportSetupParameterResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ApprovalReportService.PutApprovalReportProductionQuantitiesParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  putApprovalReportProductionQuantitiesResponse(
    params: ApprovalReportService.PutApprovalReportProductionQuantitiesParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/productionQuantities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.PutApprovalReportProductionQuantitiesParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  putApprovalReportProductionQuantities(params: ApprovalReportService.PutApprovalReportProductionQuantitiesParams): __Observable<null> {
    return this.putApprovalReportProductionQuantitiesResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ApprovalReportService.EditApprovalReportSetupPhaseParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `editSetupPhaseEntry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  editApprovalReportSetupPhaseResponse(
    params: ApprovalReportService.EditApprovalReportSetupPhaseParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.editSetupPhaseEntry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/EditSetupPhase`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.EditApprovalReportSetupPhaseParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `editSetupPhaseEntry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  editApprovalReportSetupPhase(params: ApprovalReportService.EditApprovalReportSetupPhaseParams): __Observable<null> {
    return this.editApprovalReportSetupPhaseResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ApprovalReportService.ChangeQuantityParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  changeQuantityResponse(params: ApprovalReportService.ChangeQuantityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/changeQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.ChangeQuantityParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  changeQuantity(params: ApprovalReportService.ChangeQuantityParams): __Observable<null> {
    return this.changeQuantityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ApprovalReportService.InterruptProductionOrderParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  interruptProductionOrderResponse(params: ApprovalReportService.InterruptProductionOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/interruptProductionOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.InterruptProductionOrderParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  interruptProductionOrder(params: ApprovalReportService.InterruptProductionOrderParams): __Observable<null> {
    return this.interruptProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ApprovalReportService.ReloadApprovalReportDataParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  reloadApprovalReportDataResponse(params: ApprovalReportService.ReloadApprovalReportDataParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/reloadApprovalReportData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.ReloadApprovalReportDataParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  reloadApprovalReportData(params: ApprovalReportService.ReloadApprovalReportDataParams): __Observable<null> {
    return this.reloadApprovalReportDataResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ApprovalReportService.GetApprovalWorkCentersParams` containing the following parameters:
   *
   * - `workCenterGroupId`:
   *
   * - `approvalReportDate`:
   */
  getApprovalWorkCentersResponse(
    params: ApprovalReportService.GetApprovalWorkCentersParams
  ): __Observable<__StrictHttpResponse<Array<ApprovalWorkCenter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenterGroups/${encodeURIComponent(String(params.workCenterGroupId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/workCenters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ApprovalWorkCenter>>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetApprovalWorkCentersParams` containing the following parameters:
   *
   * - `workCenterGroupId`:
   *
   * - `approvalReportDate`:
   */
  getApprovalWorkCenters(params: ApprovalReportService.GetApprovalWorkCentersParams): __Observable<Array<ApprovalWorkCenter>> {
    return this.getApprovalWorkCentersResponse(params).pipe(__map((_r) => _r.body as Array<ApprovalWorkCenter>));
  }

  /**
   * @param params The `ApprovalReportService.GetShiftInfosParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportDate`:
   */
  getShiftInfosResponse(params: ApprovalReportService.GetShiftInfosParams): __Observable<__StrictHttpResponse<ShiftInfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/shifts/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShiftInfoList>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetShiftInfosParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportDate`:
   */
  getShiftInfos(params: ApprovalReportService.GetShiftInfosParams): __Observable<ShiftInfoList> {
    return this.getShiftInfosResponse(params).pipe(__map((_r) => _r.body as ShiftInfoList));
  }

  /**
   * @param params The `ApprovalReportService.GetProductionOrderInfosParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   *
   * - `approvalReportDate`:
   */
  getProductionOrderInfosResponse(
    params: ApprovalReportService.GetProductionOrderInfosParams
  ): __Observable<__StrictHttpResponse<InfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/productionOrders/${encodeURIComponent(String(params.productionOrderId))}/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InfoList>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetProductionOrderInfosParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   *
   * - `approvalReportDate`:
   */
  getProductionOrderInfos(params: ApprovalReportService.GetProductionOrderInfosParams): __Observable<InfoList> {
    return this.getProductionOrderInfosResponse(params).pipe(__map((_r) => _r.body as InfoList));
  }

  /**
   * @param params The `ApprovalReportService.GetDowntimeInfosParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `downtimeId`:
   *
   * - `approvalReportDate`:
   */
  getDowntimeInfosResponse(params: ApprovalReportService.GetDowntimeInfosParams): __Observable<__StrictHttpResponse<InfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/downtimes/${encodeURIComponent(String(params.downtimeId))}/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InfoList>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetDowntimeInfosParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `downtimeId`:
   *
   * - `approvalReportDate`:
   */
  getDowntimeInfos(params: ApprovalReportService.GetDowntimeInfosParams): __Observable<InfoList> {
    return this.getDowntimeInfosResponse(params).pipe(__map((_r) => _r.body as InfoList));
  }

  /**
   * @param params The `ApprovalReportService.GetProducedMaterialInfosParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `producedMaterialId`:
   *
   * - `approvalReportDate`:
   */
  getProducedMaterialInfosResponse(
    params: ApprovalReportService.GetProducedMaterialInfosParams
  ): __Observable<__StrictHttpResponse<InfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/producedMaterials/${encodeURIComponent(String(params.producedMaterialId))}/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InfoList>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetProducedMaterialInfosParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `producedMaterialId`:
   *
   * - `approvalReportDate`:
   */
  getProducedMaterialInfos(params: ApprovalReportService.GetProducedMaterialInfosParams): __Observable<InfoList> {
    return this.getProducedMaterialInfosResponse(params).pipe(__map((_r) => _r.body as InfoList));
  }

  /**
   * @param workCenterId undefined
   */
  getOpenApprovalReportDatesResponse(workCenterId: number): __Observable<__StrictHttpResponse<ApprovalReportDates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/workCenterGroups/${encodeURIComponent(String(workCenterId))}/approvals/approvalReportDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApprovalReportDates>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getOpenApprovalReportDates(workCenterId: number): __Observable<ApprovalReportDates> {
    return this.getOpenApprovalReportDatesResponse(workCenterId).pipe(__map((_r) => _r.body as ApprovalReportDates));
  }

  /**
   * @param params The `ApprovalReportService.GetApprovalSetupParameterKindsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  getApprovalSetupParameterKindsResponse(
    params: ApprovalReportService.GetApprovalSetupParameterKindsParams
  ): __Observable<__StrictHttpResponse<SetupParameterKind>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/setupParameterKinds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetupParameterKind>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetApprovalSetupParameterKindsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  getApprovalSetupParameterKinds(params: ApprovalReportService.GetApprovalSetupParameterKindsParams): __Observable<SetupParameterKind> {
    return this.getApprovalSetupParameterKindsResponse(params).pipe(__map((_r) => _r.body as SetupParameterKind));
  }

  /**
   * @param params The `ApprovalReportService.GetApprovalSetupParametersParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  getApprovalSetupParametersResponse(
    params: ApprovalReportService.GetApprovalSetupParametersParams
  ): __Observable<__StrictHttpResponse<SetupParameters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/setupParameters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetupParameters>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetApprovalSetupParametersParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  getApprovalSetupParameters(params: ApprovalReportService.GetApprovalSetupParametersParams): __Observable<SetupParameters> {
    return this.getApprovalSetupParametersResponse(params).pipe(__map((_r) => _r.body as SetupParameters));
  }

  /**
   * @param params The `ApprovalReportService.GetProductionOrderConsumptionParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  getProductionOrderConsumptionResponse(
    params: ApprovalReportService.GetProductionOrderConsumptionParams
  ): __Observable<__StrictHttpResponse<ApprovalReportConsumption>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/workCenter/${encodeURIComponent(String(params.workCenterId))}/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}/items/${encodeURIComponent(String(params.approvalReportLineId))}/consumption`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApprovalReportConsumption>;
      })
    );
  }
  /**
   * @param params The `ApprovalReportService.GetProductionOrderConsumptionParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportLineId`:
   *
   * - `approvalReportDate`:
   */
  getProductionOrderConsumption(
    params: ApprovalReportService.GetProductionOrderConsumptionParams
  ): __Observable<ApprovalReportConsumption> {
    return this.getProductionOrderConsumptionResponse(params).pipe(__map((_r) => _r.body as ApprovalReportConsumption));
  }
}

module ApprovalReportService {
  /**
   * Parameters for ConfirmApprovalReport
   */
  export interface ConfirmApprovalReportParams {
    workCenterId: number;
    approvalReportDate: string;
    approvalEntry: ApprovalEntry;
  }

  /**
   * Parameters for GetApprovalReport
   */
  export interface GetApprovalReportParams {
    workCenterId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for PutApprovalReportSetupParameterKind
   */
  export interface PutApprovalReportSetupParameterKindParams {
    workCenterId: number;
    entry: SetSetupParameterKindEntry;
    approvalReportLineId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for PutApprovalReportSetupParameter
   */
  export interface PutApprovalReportSetupParameterParams {
    workCenterId: number;
    setupParameterId: number;
    entry: SetSetupParameterEntry;
    approvalReportLineId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for PutApprovalReportProductionQuantities
   */
  export interface PutApprovalReportProductionQuantitiesParams {
    workCenterId: number;
    entry: SetProductionQuantitiesEntry;
    approvalReportLineId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for EditApprovalReportSetupPhase
   */
  export interface EditApprovalReportSetupPhaseParams {
    workCenterId: number;
    editSetupPhaseEntry: EditProductionOrderSetupPhaseEntry;
    approvalReportLineId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for ChangeQuantity
   */
  export interface ChangeQuantityParams {
    workCenterId: number;
    entry: ChangeApprovalReportQuantityEntry;
    approvalReportLineId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for InterruptProductionOrder
   */
  export interface InterruptProductionOrderParams {
    workCenterId: number;
    entry: InterruptProductionOrderEntry;
    approvalReportLineId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for ReloadApprovalReportData
   */
  export interface ReloadApprovalReportDataParams {
    workCenterId: number;
    entry: ReloadApprovalReportDataEntry;
  }

  /**
   * Parameters for GetApprovalWorkCenters
   */
  export interface GetApprovalWorkCentersParams {
    workCenterGroupId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for GetShiftInfos
   */
  export interface GetShiftInfosParams {
    workCenterId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for GetProductionOrderInfos
   */
  export interface GetProductionOrderInfosParams {
    workCenterId: number;
    productionOrderId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for GetDowntimeInfos
   */
  export interface GetDowntimeInfosParams {
    workCenterId: number;
    downtimeId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for GetProducedMaterialInfos
   */
  export interface GetProducedMaterialInfosParams {
    workCenterId: number;
    producedMaterialId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for GetApprovalSetupParameterKinds
   */
  export interface GetApprovalSetupParameterKindsParams {
    workCenterId: number;
    approvalReportLineId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for GetApprovalSetupParameters
   */
  export interface GetApprovalSetupParametersParams {
    workCenterId: number;
    approvalReportLineId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for GetProductionOrderConsumption
   */
  export interface GetProductionOrderConsumptionParams {
    workCenterId: number;
    approvalReportLineId: number;
    approvalReportDate: string;
  }
}

export { ApprovalReportService };
