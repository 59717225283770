import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TreeNode } from 'primeng/api';
import {
  ApprovalReportItem,
  ApprovalReportItemType,
  ApprovalReportProductionDowntimeItem,
  ApprovalReportProductionOrderPhaseType,
  KpiStatus
} from 'chronos-core-client';
import { ApproveReportTableColumn } from './approve-report-table-column';

@Component({
  selector: 'app-approve-report-table',
  templateUrl: './approve-report-table.component.html',
  styleUrls: ['./approve-report-table.component.scss']
})
export class ApproveReportTableComponent {
  @Input() public tableData: TreeNode[] = [];
  @Input() public buttonTemplate: TemplateRef<any>;

  @Output() public infoLinkClicked = new EventEmitter<ApprovalReportItem | ApprovalReportProductionDowntimeItem>();
  @Output() public loadNodeClicked = new EventEmitter<any>();
  @Output() public tableExpandClicked = new EventEmitter<boolean>();
  @Output() public consumePalletLinkClicked = new EventEmitter<any>();

  public tableExpanded = false;

  public readonly KPI_STATUS = KpiStatus;
  public readonly ITEM_TYPE = ApprovalReportItemType;
  public readonly PHASE_TYPE = ApprovalReportProductionOrderPhaseType;

  public columns: ApproveReportTableColumn[] = [
    { label: 'APPROVE.STATUS', styleClass: 'tiny-cell', highlighted: false },
    { label: 'APPROVE.INFO', styleClass: 'tiny-cell', highlighted: false },
    { label: 'APPROVE.ORDER_TYPE', styleClass: '', highlighted: false },
    { label: 'APPROVE.CUSTOMER_CODE', styleClass: '', highlighted: false },
    { label: 'APPROVE.ABG_FINISHED', styleClass: 'small-cell', highlighted: false },
    { label: 'APPROVE.START_TIME', styleClass: 'small-cell', highlighted: false },
    { label: 'APPROVE.END_TIME', styleClass: 'small-cell', highlighted: false },
    { label: 'APPROVE.SETUP_TIME', styleClass: 'small-cell', highlighted: false },
    { label: 'APPROVE.RUN_TIME', styleClass: 'small-cell', highlighted: false },
    { label: 'APPROVE.DOWN_TIME', styleClass: 'small-cell', highlighted: false },
    { label: 'APPROVE.GOOD_QTY', styleClass: '', highlighted: false },
    { label: 'APPROVE.WASTE', styleClass: '', highlighted: false },
    { label: 'APPROVE.MACULATURE', styleClass: '', highlighted: false },
    { label: 'APPROVE.PLANNED_GOOD_QTY', styleClass: '', highlighted: false },
    { label: 'APPROVE.OVERALL_GOOD_QTY', styleClass: '', highlighted: false },
    { label: 'APPROVE.GOOD_QUANTITY_PREDECESSOR', styleClass: '', highlighted: false },
    { label: 'APPROVE.CONSUMPTION', styleClass: '', highlighted: false },
    { label: 'APPROVE.ORDER_GROSS_QUANTITY', styleClass: 'small-cell', highlighted: false },
    { label: 'APPROVE.OUTPUT1', styleClass: 'medium-cell', highlighted: false },
    { label: 'APPROVE.OUTPUT2', styleClass: 'medium-cell', highlighted: false }
  ];

  public onHighlight(column: ApproveReportTableColumn): void {
    column.highlighted = !column.highlighted;
  }

  public emitInfoEvent(item: ApprovalReportItem | ApprovalReportProductionDowntimeItem): void {
    this.infoLinkClicked.emit(item);
  }

  public loadNode(event: any): void {
    this.loadNodeClicked.emit(event);
  }

  public expandCollapseTable(): void {
    this.tableExpanded = !this.tableExpanded;
    this.tableExpandClicked.emit(this.tableExpanded);
  }
}
