<div class="items">
  <ng-container *ngIf="consumedContainers?.length > 0; else emptyList">
    <div class="item" *ngFor="let consumedContainer of consumedContainers; trackBy: trackByFunction">
      <ng-container
        [ngTemplateOutlet]="consumedContainer.container.isVirtualContainer ? dummyTemplate : itemTemplate"
        [ngTemplateOutletContext]="{ $implicit: consumedContainer }"
      ></ng-container>
    </div>
  </ng-container>
  <ng-template #emptyList>
    <div class="item">
      <ng-template [ngTemplateOutlet]="emptyTemplate"></ng-template>
    </div>
  </ng-template>
</div>
