import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ResourceConfigurationService } from '../../services';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-import-setting-modal',
  templateUrl: './import-setting-modal.component.html',
  styleUrls: ['./import-setting-modal.component.scss']
})
export class ImportSettingModalComponent {
  public disableUpload = false;
  public files: File[] = [];
  public readonly LOADING_TOPIC = notificationTopic.importResourceSetting;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private resourceConfigurationService: ResourceConfigurationService
  ) {}

  public onClose(): void {
    this.ref.close(false);
  }

  public onUpload(): void {
    this.onUploadSettng();
  }

  public onSelect(event: any): void {
    if (event.files?.length > 0) {
      this.files.push(event.files[0]);
      this.disableUpload = true;
    }
  }

  public onRemove(event: any): void {
    this.disableUpload = event.files?.length > 0;
  }

  private onUploadSettng(): void {
    const file = this.files[0];
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const data: string = e.target.result;
      LoadingNotificationService.publish(this.LOADING_TOPIC, true);
      this.resourceConfigurationService
        .importSetting(data, 'sample user')
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          })
        )
        .subscribe(() => {
          LogService.success('SUCCESS_MESSAGE.IMPORTED_SUCCESSFULLY');
          this.ref.close(true);
        });
    };
    reader.readAsText(file);
  }
}
