import { Component, Input } from '@angular/core';
import { ArticleDescription } from 'chronos-core-client';
import { MaterialStatus } from '../../../models';

@Component({
  selector: 'lib-material-status',
  templateUrl: './material-status.component.html',
  styleUrls: ['./material-status.component.scss']
})
export class MaterialStatusComponent {
  @Input() public condensed = false;
  @Input() public alignRight = false;
  @Input() public title: string;
  @Input() public article: ArticleDescription;
  @Input() public showPlannedStartTime = false;
  @Input() public plannedStartTime: Date;
  @Input() public styleClass = MaterialStatus.ACTIVE;
}
