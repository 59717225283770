import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { PrimaryMaterial } from 'chronos-core-client';

function initialState(): Partial<PrimaryMaterial> {
  return {
    materialBlocks: []
  };
}

@Injectable()
@StoreConfig({ name: 'primary-mounted-material' })
export class PrimaryMountedMaterialStore extends Store<PrimaryMaterial> {
  constructor() {
    super(initialState());
  }
}
