import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-mini';

@Pipe({
  name: 'millisecondsToDuration'
})
export class MillisecondsToDurationPipe implements PipeTransform {
  public transform(milliseconds: number): any {
    if (milliseconds === null || milliseconds === undefined) {
      return null;
    }
    const duration = moment.duration(milliseconds);
    let minutes = duration.minutes().toString().padStart(2, '0');
    let seconds = duration.seconds().toString().padStart(2, '0');
    const totalHours = Math.floor(duration.asHours());
    let hours;
    if (totalHours <= 99) {
      hours = totalHours.toString().padStart(2, '0');
    } else {
      hours = '--';
      minutes = '--';
      seconds = '--';
    }
    return `${hours}:${minutes}:${seconds}`;
  }
}
