import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-status-flag',
  templateUrl: './status-flag.component.html',
  styleUrls: ['./status-flag.component.scss']
})
export class StatusFlagComponent {
  @Input() public statusText: string;
  @Input() public styleClass: string;
  @Input() public isAutomated = false;
  @Input() public size: 'normal' | 'small' | 'tiny' | 'tiny-plain' = 'normal';
}
