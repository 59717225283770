/* tslint:disable */
type WasteAssignmentType = 'SetupWaste' | 'ProductionOrderWaste' | 'ContainerWaste';
module WasteAssignmentType {
  export const SETUP_WASTE: WasteAssignmentType = 'SetupWaste';
  export const PRODUCTION_ORDER_WASTE: WasteAssignmentType = 'ProductionOrderWaste';
  export const CONTAINER_WASTE: WasteAssignmentType = 'ContainerWaste';
  export function values(): WasteAssignmentType[] {
    return [SETUP_WASTE, PRODUCTION_ORDER_WASTE, CONTAINER_WASTE];
  }
}

export { WasteAssignmentType };
