import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { KpiValue } from 'chronos-core-client';

export type ShiftReportHeaderKpisState = EntityState<KpiValue>;

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'shift-report-header-kpis', idKey: 'kpiIdentifier', resettable: true })
export class ShiftReportHeaderKpisStore extends EntityStore<ShiftReportHeaderKpisState> {
  constructor() {
    super();
  }
}
