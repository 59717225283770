import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { KpiValue } from 'chronos-core-client';

export type KpiState = EntityState<KpiValue>;

@Injectable()
@StoreConfig({ name: 'kpi', idKey: 'kpiIdentifier' })
export class KpiStore extends EntityStore<KpiState, KpiValue> {
  constructor() {
    super();
  }
}
