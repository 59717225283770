/* tslint:disable */
type ProductionOrderType = 'Standard' | 'Combination' | 'CombinationOrigin';
module ProductionOrderType {
  export const STANDARD: ProductionOrderType = 'Standard';
  export const COMBINATION: ProductionOrderType = 'Combination';
  export const COMBINATION_ORIGIN: ProductionOrderType = 'CombinationOrigin';
  export function values(): ProductionOrderType[] {
    return [STANDARD, COMBINATION, COMBINATION_ORIGIN];
  }
}

export { ProductionOrderType };
