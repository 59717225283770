import { Injectable } from '@angular/core';
import { KpiActionBase } from './kpi-action-base';
import { DialogService } from 'primeng/dynamicdialog';
import { OrderFinishSortingModalComponent } from '@app/modules/manual-mode/components/order-finish-sorting-modal/order-finish-sorting-modal.component';
import { ActiveOrderDsService } from '@app/core/data-services';
import { TranslateService } from '@ngx-translate/core';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import { finalize, map, switchMap } from 'rxjs/operators';
import { notificationTopic } from '@app/shared/utils';
import { KpiIdentifier, KpiValue } from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KpiActionDurationService extends KpiActionBase {
  constructor(
    private dialogService: DialogService,
    private activeOrderDsService: ActiveOrderDsService,
    private translateService: TranslateService,
    private manualModeService: ManualModeService
  ) {
    super();
    this.loadingExecuteTopic = notificationTopic.kpiDurationExecute;
  }

  public execute() {
    const activeOrder = this.activeOrderDsService.getActiveOrder();

    this.publishLoadingExecuteTopic(true);
    this.manualModeService
      .getCombinedSortingData(activeOrder.productionOrderId)
      .pipe(
        switchMap(([articleData, sortingData]) =>
          this.getDurationKpi().pipe(
            map((kpiData) => ({
              header: this.translateService.instant('MANUAL_MODE.ADJUST_DURATION_EMPLOYEES'),
              data: {
                activeProductionOrder: activeOrder,
                article: this.manualModeService.mapArticleFromManualData(articleData),
                submitLabel: 'MANUAL_MODE.SAVE',
                sortingValues: sortingData,
                shiftDuration: kpiData.find((kpi) => kpi.kpiIdentifier === KpiIdentifier.DURATION).targetTime,
                isFromPallet: false
              }
            }))
          )
        ),
        finalize(() => {
          this.publishLoadingExecuteTopic(false);
        })
      )
      .subscribe((modalData) => {
        this.dialogService.open(OrderFinishSortingModalComponent, modalData);
      });
  }

  private getDurationKpi(): Observable<KpiValue[]> {
    return this.manualModeService.getAllKpis();
  }
}
