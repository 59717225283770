import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dismount-panel',
  templateUrl: './dismount-panel.component.html',
  styleUrls: ['./dismount-panel.component.scss']
})
export class DismountPanelComponent {
  @Input() public count = 0;
  @Input() public label = '';
  @Input() public loadingTopic = '';

  @Output() public clicked = new EventEmitter<void>();

  public onClick(): void {
    this.clicked.emit();
  }
}
