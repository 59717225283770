/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ContainerForProductionOrderResponse } from '../models/container-for-production-order-response';
import { FinishContainerEntry } from '../models/finish-container-entry';
import { ContainerForProductionOrder } from '../models/container-for-production-order';
@Injectable({
  providedIn: 'root'
})
class PackLineService extends __BaseService {
  static readonly createDefaultContainersForProductionOrderPath =
    '/api/v1/PackLine/productionOrders/{externalProductionOrderId}/createDefaultContainers';
  static readonly createExtraContainerForProductionOrderPath =
    '/api/v1/PackLine/productionOrders/{externalProductionOrderId}/createExtraContainers';
  static readonly finishContainerPath = '/api/v1/PackLine/productionOrders/{externalProductionOrderId}/finishContainer';
  static readonly containerForProductionOrderPath = '/api/v1/PackLine/productionOrders/{externalProductionOrderId}/containers';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `PackLineService.CreateDefaultContainersForProductionOrderParams` containing the following parameters:
   *
   * - `externalProductionOrderId`:
   *
   * - `numberOfAdditionalPallets`:
   */
  createDefaultContainersForProductionOrderResponse(
    params: PackLineService.CreateDefaultContainersForProductionOrderParams
  ): __Observable<__StrictHttpResponse<ContainerForProductionOrderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.numberOfAdditionalPallets != null)
      __params = __params.set('numberOfAdditionalPallets', params.numberOfAdditionalPallets.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/PackLine/productionOrders/${encodeURIComponent(String(params.externalProductionOrderId))}/createDefaultContainers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContainerForProductionOrderResponse>;
      })
    );
  }
  /**
   * @param params The `PackLineService.CreateDefaultContainersForProductionOrderParams` containing the following parameters:
   *
   * - `externalProductionOrderId`:
   *
   * - `numberOfAdditionalPallets`:
   */
  createDefaultContainersForProductionOrder(
    params: PackLineService.CreateDefaultContainersForProductionOrderParams
  ): __Observable<ContainerForProductionOrderResponse> {
    return this.createDefaultContainersForProductionOrderResponse(params).pipe(
      __map((_r) => _r.body as ContainerForProductionOrderResponse)
    );
  }

  /**
   * @param params The `PackLineService.CreateExtraContainerForProductionOrderParams` containing the following parameters:
   *
   * - `externalProductionOrderId`:
   *
   * - `numberOfAdditionalPallets`:
   */
  createExtraContainerForProductionOrderResponse(
    params: PackLineService.CreateExtraContainerForProductionOrderParams
  ): __Observable<__StrictHttpResponse<ContainerForProductionOrderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.numberOfAdditionalPallets != null)
      __params = __params.set('numberOfAdditionalPallets', params.numberOfAdditionalPallets.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/PackLine/productionOrders/${encodeURIComponent(String(params.externalProductionOrderId))}/createExtraContainers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContainerForProductionOrderResponse>;
      })
    );
  }
  /**
   * @param params The `PackLineService.CreateExtraContainerForProductionOrderParams` containing the following parameters:
   *
   * - `externalProductionOrderId`:
   *
   * - `numberOfAdditionalPallets`:
   */
  createExtraContainerForProductionOrder(
    params: PackLineService.CreateExtraContainerForProductionOrderParams
  ): __Observable<ContainerForProductionOrderResponse> {
    return this.createExtraContainerForProductionOrderResponse(params).pipe(__map((_r) => _r.body as ContainerForProductionOrderResponse));
  }

  /**
   * @param params The `PackLineService.FinishContainerParams` containing the following parameters:
   *
   * - `externalProductionOrderId`:
   *
   * - `entry`:
   */
  finishContainerResponse(params: PackLineService.FinishContainerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PackLine/productionOrders/${encodeURIComponent(String(params.externalProductionOrderId))}/finishContainer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PackLineService.FinishContainerParams` containing the following parameters:
   *
   * - `externalProductionOrderId`:
   *
   * - `entry`:
   */
  finishContainer(params: PackLineService.FinishContainerParams): __Observable<null> {
    return this.finishContainerResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param externalProductionOrderId undefined
   */
  containerForProductionOrderResponse(externalProductionOrderId: string): __Observable<__StrictHttpResponse<ContainerForProductionOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PackLine/productionOrders/${encodeURIComponent(String(externalProductionOrderId))}/containers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContainerForProductionOrder>;
      })
    );
  }
  /**
   * @param externalProductionOrderId undefined
   */
  containerForProductionOrder(externalProductionOrderId: string): __Observable<ContainerForProductionOrder> {
    return this.containerForProductionOrderResponse(externalProductionOrderId).pipe(__map((_r) => _r.body as ContainerForProductionOrder));
  }
}

module PackLineService {
  /**
   * Parameters for CreateDefaultContainersForProductionOrder
   */
  export interface CreateDefaultContainersForProductionOrderParams {
    externalProductionOrderId: string;
    numberOfAdditionalPallets?: null | number;
  }

  /**
   * Parameters for CreateExtraContainerForProductionOrder
   */
  export interface CreateExtraContainerForProductionOrderParams {
    externalProductionOrderId: string;
    numberOfAdditionalPallets?: number;
  }

  /**
   * Parameters for FinishContainer
   */
  export interface FinishContainerParams {
    externalProductionOrderId: string;
    entry: FinishContainerEntry;
  }
}

export { PackLineService };
