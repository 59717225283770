import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {
  @Input() public tooltip: any;
  @Input() public icon: string;
  @Input() public isActive: boolean;
}
