import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ParameterBase,
  ParameterBobbinQuantityPerPallet,
  ParameterFormField,
  ParameterQuantityPerPallet
} from '@app/shared/models/parameter';
import { FormConstructionService } from '@app/modules/parameters/services';
import { ParametersFactoryService } from '@app/core/services/parameters-factory';

@Component({
  selector: 'app-params-table',
  templateUrl: './params-table.component.html',
  styleUrls: ['./params-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParamsTableComponent implements OnInit, OnDestroy {
  @Input() public fields: ParameterBase[];
  @Input() public form: UntypedFormGroup;
  @Input() public fieldName: string;

  public subForm: UntypedFormGroup;
  public subFormFields: ParameterFormField[];

  constructor(private paramsFieldService: FormConstructionService, private parametersFactoryService: ParametersFactoryService) {}

  public ngOnInit(): void {
    this.subForm = this.paramsFieldService.toFormGroup(this.fields);
    this.subFormFields = this.getSubFormFields();
    this.form.addControl(this.fieldName, this.subForm);

    this.parametersFactoryService.palletQuantitySubject$.subscribe((palletQty) => {
      this.updatePalletObj(palletQty);
    });

    this.parametersFactoryService.bobbinQuantitySubject$.subscribe(() => {
      this.updateForm();
    });
  }

  public ngOnDestroy(): void {
    this.form.removeControl(this.fieldName);
  }

  public getSubFormFields(): ParameterFormField[] {
    return Object.entries(this.subForm.controls)
      .map<ParameterFormField>(([key, control]) => {
        const parameter = this.fields.find((field) => String(field.id) === key);
        return {
          parameter,
          control: control as UntypedFormControl
        };
      })
      .sort((a, b) => a.parameter.order - b.parameter.order);
  }

  public trackByFunction(index: number, field: ParameterFormField): number {
    return field.parameter.id;
  }

  private updateForm() {
    const pallet = this.fields[3] instanceof ParameterQuantityPerPallet ? this.fields[3] : undefined;
    const bobbin = this.fields[4] instanceof ParameterBobbinQuantityPerPallet ? this.fields[4] : undefined;

    if (!(pallet && bobbin)) return;

    this.updateBobbinMaxLimit();
  }

  private updatePalletObj(palletQty?: number) {
    if (!palletQty) return;

    const pallet = this.fields[3] instanceof ParameterQuantityPerPallet ? this.fields[3] : undefined;

    if (!pallet) return;

    pallet.value = palletQty.toString();

    this.updateBobbinMaxLimit();
  }

  private updateBobbinMaxLimit(): void {
    const validators = [];
    validators.push(Validators.required);
    validators.push(Validators.min(1));
    validators.push(Validators.max(this.subFormFields[3].control.value));

    const controlToUpdate = this.subForm.get('1'); // bobbin qty control
    if (controlToUpdate) {
      controlToUpdate.setValidators(validators);
      controlToUpdate.updateValueAndValidity();
    }
  }
}
