import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FinishPhaseService } from './services/finish-phase.service';

@Component({
  selector: 'app-finish-phase',
  templateUrl: './finish-phase.component.html',
  styleUrls: ['./finish-phase.component.scss']
})
export class FinishPhaseComponent implements OnInit, OnDestroy {
  public isFinishingPhase$: Observable<boolean>;

  private subscriptions = new Subscription();

  constructor(public finishPhaseService: FinishPhaseService) {}

  public ngOnInit(): void {
    this.finishPhaseService.initKpis();
    this.isFinishingPhase$ = this.finishPhaseService.isFinishingPhase();
    this.subscriptions.add(this.finishPhaseService.getKpiNotifications().subscribe());
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
