<section class="chronos-dialog--size-sm">
  <div class="chronos-file-updload">
    <lib-file-upload
      [buttonLabel]="'RESOURCE_CONFIGURATION.IMPORT' | translate"
      [acceptedFileType]="'.csv,.xls,.xlsx'"
      [disable]="false"
      [autoUpload]="false"
      [mode]="'advanced'"
      [showCancelButton]="false"
      [showUploadButton]="false"
      (selectFile)="onSelect($event)"
      (removeFile)="onRemove($event)"
    ></lib-file-upload>
  </div>
  <lib-modal-footer
    [submitLabel]="'MODAL_FOOTER.UPLOAD'"
    [submitDisabled]="!disableUpload"
    [whenLoadingTopic]="LOADING_TOPIC"
    (submitClicked)="onUpload()"
    (declineClicked)="onClose()"
  ></lib-modal-footer>
</section>
