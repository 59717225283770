/* tslint:disable */
type ProductionOrderStatus = 'None' | 'Open' | 'Checked' | 'Active' | 'Finished' | 'Reopened' | 'Interrupted';
module ProductionOrderStatus {
  export const NONE: ProductionOrderStatus = 'None';
  export const OPEN: ProductionOrderStatus = 'Open';
  export const CHECKED: ProductionOrderStatus = 'Checked';
  export const ACTIVE: ProductionOrderStatus = 'Active';
  export const FINISHED: ProductionOrderStatus = 'Finished';
  export const REOPENED: ProductionOrderStatus = 'Reopened';
  export const INTERRUPTED: ProductionOrderStatus = 'Interrupted';
  export function values(): ProductionOrderStatus[] {
    return [NONE, OPEN, CHECKED, ACTIVE, FINISHED, REOPENED, INTERRUPTED];
  }
}

export { ProductionOrderStatus };
