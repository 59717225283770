import { Component, Input, TemplateRef } from '@angular/core';
import { UiStateQuery } from '@app/core/global-state/ui-state/ui-state.query';
import { UiStateService } from '@app/core/global-state/ui-state/ui-state.service';
import {
  MaterialTableDataRow,
  MaterialTableDataRowDisplayMode,
  MaterialTableTreeNode
} from '@app/modules/mounting/models/material-table.model';
import { MountingService } from '@app/modules/mounting/services';
import { ArticleClassification, Quantity, FlushingPrinciple } from 'chronos-core-client';
import { ArticleClassificationSize } from 'chronos-shared';

@Component({
  selector: 'app-material-overview-table',
  templateUrl: './material-overview-table.component.html',
  styleUrls: ['./material-overview-table.component.scss', '../../../../assets/styles/material-table.scss']
})
export class MaterialOverviewTableComponent {
  MaterialTableDataRowDisplayMode = MaterialTableDataRowDisplayMode;

  @Input() public set tableData(tableData: MaterialTableTreeNode[]) {
    this.secondaryMaterialData = this.mountingService.setTableRowsExpandingStatus(tableData, this.secondaryMaterialData);
    this.uiStateQuery.getMountingSecondaryExpandedArticles().forEach((article) => {
      const tableRow = this.secondaryMaterialData.find((x) => x.data.materialBlockId === article);
      if (tableRow) {
        tableRow.expanded = true;
      }
    });
  }
  @Input() public buttonTemplate: TemplateRef<any>;
  @Input() public buttonTemplateStyle: string | object | null = null;

  public tableExpanded = false;
  public secondaryMaterialData: MaterialTableTreeNode[];

  ArticleClassificationSize = ArticleClassificationSize;

  constructor(private mountingService: MountingService, private uiStateService: UiStateService, private uiStateQuery: UiStateQuery) {}

  public expandCollapseTable(): void {
    const { tableData, tableExpandingStatus } = this.mountingService.expandCollapseTable(this.secondaryMaterialData, this.tableExpanded);

    this.tableExpanded = tableExpandingStatus;
    this.secondaryMaterialData = tableData;
  }

  public trackByFunction(index: number, item: MaterialTableTreeNode): number {
    return item.data?.displayMode === MaterialTableDataRowDisplayMode.Container ? item.data?.mountedMaterialId : item.data?.materialBlockId;
  }

  public onNodeExpand(data: any): void {
    this.uiStateService.setMountingSecondaryExpandedArticleVisible(data.node.data.materialBlockId);
  }
  public onNodeCollapse(data: any): void {
    this.uiStateService.setMountingSecondaryExpandedArticleHide(data.node.data.materialBlockId);
  }

  public isToolType(rowData: MaterialTableDataRow): boolean {
    return rowData.article.classification === ArticleClassification.TOOL;
  }

  public isAlternativeMaterial(rowData: any, data: any): boolean {
    return rowData.article.id !== data.article.id;
  }

  public isAlternativeAvailable(data: any): boolean {
    return data.alternativeArticles.length > 0;
  }

  public isFlushingPrincipleManual(rowData: MaterialTableDataRow): boolean {
    return rowData.flushingPrinciple === FlushingPrinciple.MANUAL;
  }

  public calculateMountedQuantity(rowData: MaterialTableDataRow): Quantity {
    return {
      value: rowData.mountedQuantity.value + rowData.inputQuantityPool.value,
      unitId: rowData.mountedQuantity.unitId
    };
  }
}
