<div *ngIf="documentItem; else noDocumentAvailable" class="defective-item">
  <div *ngIf="isLoading" class="defective-item__loader" [ngClass]="{ 'defective-item__loader--fullscreen': isFullscreen }">
    <app-progress-spinner></app-progress-spinner>
  </div>
  <div *ngIf="shouldLoad">
    <img
      *ngIf="isPreviewAvailable(); else noPreviewAvailable"
      class="defective-item__image"
      [ngClass]="{
        'defective-item__image--hidden': isLoading,
        'defective-item__image--fullscreen': isFullscreen,
        'defective-item__image--clickable': isImageClickable
      }"
      [src]="documentItem.downloadLink"
      (load)="hideLoader()"
      (error)="setError()"
      (click)="onImageClick()"
    />
    <ng-template #noPreviewAvailable>
      <div class="defective-item__no-preview" [ngClass]="{ 'defective-item__no-preview--fullscreen': isFullscreen }">
        {{ 'QA_HISTORY.NO_PREVIEW' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<ng-template #noDocumentAvailable>
  <div class="defective-item">
    <div class="defective-item__no-preview" [ngClass]="{ 'defective-item__no-preview--fullscreen': isFullscreen }">
      {{ 'QA_HISTORY.NO_DOCUMENTS' | translate }}
    </div>
  </div>
</ng-template>
