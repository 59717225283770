import { Injectable } from '@angular/core';
import { KpiIdentifier } from 'chronos-core-client';
import {
  KpiActionBase,
  KpiActionConsumptionPrimaryService,
  KpiActionConsumptionSecondaryService,
  KpiActionDowntimeService,
  KpiActionEmptyService,
  KpiActionGoodQuantityService,
  KpiActionSetupTimeService,
  KpiActionDurationService,
  KpiActionConsumptionSecondaryLaminationService
} from './actions';

@Injectable({
  providedIn: 'root'
})
export class KpiActionFactoryService {
  constructor(
    private kpiActionDowntimeService: KpiActionDowntimeService,
    private kpiActionSetupTimeService: KpiActionSetupTimeService,
    private kpiActionGoodQuantityService: KpiActionGoodQuantityService,
    private kpiActionEmptyService: KpiActionEmptyService,
    private kpiActionConsumptionPrimaryService: KpiActionConsumptionPrimaryService,
    private kpiActionConsumptionSecondaryService: KpiActionConsumptionSecondaryService,
    private kpiActionDurationService: KpiActionDurationService,
    private kpiActionConsumptionSecondaryLaminationService: KpiActionConsumptionSecondaryLaminationService
  ) {}

  public createKpiAction(kpiType: KpiIdentifier): KpiActionBase {
    switch (kpiType) {
      case KpiIdentifier.DOWNTIME: {
        return this.kpiActionDowntimeService;
      }
      case KpiIdentifier.SETUP_TIME: {
        return this.kpiActionSetupTimeService;
      }
      case KpiIdentifier.GOOD_QUANTITY: {
        return this.kpiActionGoodQuantityService;
      }
      case KpiIdentifier.CONSUMPTION_PRIMARY: {
        return this.kpiActionConsumptionPrimaryService;
      }
      case KpiIdentifier.CONSUMPTION_SECONDARY: {
        return this.kpiActionConsumptionSecondaryService;
      }
      case KpiIdentifier.CONSUMPTION_SECONDARY_LAMINATION: {
        return this.kpiActionConsumptionSecondaryLaminationService;
      }
      case KpiIdentifier.NUMBER_OF_EMPLOYEES: {
        return this.kpiActionDurationService;
      }
      case KpiIdentifier.DURATION: {
        return this.kpiActionDurationService;
      }

      default: {
        return this.kpiActionEmptyService;
      }
    }
  }
}
