/* tslint:disable */
type ProducedMaterialCheckResult = 'None' | 'Open' | 'Done';
module ProducedMaterialCheckResult {
  export const NONE: ProducedMaterialCheckResult = 'None';
  export const OPEN: ProducedMaterialCheckResult = 'Open';
  export const DONE: ProducedMaterialCheckResult = 'Done';
  export function values(): ProducedMaterialCheckResult[] {
    return [NONE, OPEN, DONE];
  }
}

export { ProducedMaterialCheckResult };
