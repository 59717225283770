import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { nav } from '@app/shared/utils';
import { AppSettingsQuery } from 'chronos-shared';
import { of } from 'rxjs';

export const qaLogGuard = () => {
  const appSettingsQuery: AppSettingsQuery = inject(AppSettingsQuery);
  return appSettingsQuery.isQaHistoryTabEnabled$;
};

export const qaLogGuardChild = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const appSettingsQuery: AppSettingsQuery = inject(AppSettingsQuery);
  if (state.url === nav.routes.qaLogHistory) {
    return appSettingsQuery.isQaHistoryTabEnabled$;
  } else {
    return of(false);
  }
};
