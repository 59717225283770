import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as R from 'ramda';
import { LogEntry, LogLevel } from '../../../../services/logging';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'lib-log-message',
  templateUrl: './log-message.component.html',
  styleUrls: ['./log-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogMessageComponent implements OnInit {
  @Input() public message: LogEntry;

  public hasTranslation = false;
  public messageType: MessageType;
  public readonly MESSAGE_TYPE = MessageType;

  constructor(private translateService: TranslateService, private clipboard: Clipboard) {}

  public ngOnInit(): void {
    this.messageType = this.identifyMessageType();
    this.hasTranslation = this.checkTranslation();
  }

  public formatMessage(message: any): string {
    return `Extra info: ${JSON.stringify(message, null, 2)}`;
  }

  private identifyMessageType(): MessageType {
    if (this.isSerializedBackendWarning()) {
      return MessageType.SerializedBackendWarning;
    } else if (this.isSerializedBackendError()) {
      return MessageType.SerializedBackendError;
    } else if (this.message?.level === LogLevel.Error) {
      return MessageType.OtherError;
    } else if (this.message?.level === LogLevel.Success) {
      return MessageType.Success;
    } else {
      return MessageType.DefaultMessage;
    }
  }

  private isSerializedBackendWarning(): boolean {
    return this.message?.level === LogLevel.Warn && this.message?.serializedBackendWarning?.isFailure;
  }

  private isSerializedBackendError(): boolean {
    return this.message?.level === LogLevel.Error && R.not(R.isNil(this.message?.serializedBackendError));
  }

  private checkTranslation(): boolean {
    if (this.messageType === MessageType.SerializedBackendWarning) {
      return this.message?.serializedBackendWarning.serializedWarning.every(
        (error) => error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode
      );
    }
    return false;
  }

  public copyToClipboard(): void {
    const messages = [];
    messages.push(this.message?.entryDate);
    if (this.message?.internalPileTurnerId) {
      messages.push(`${this.translateService.instant('HEADER_MESSAGE.PILE_TURNER_ID')}: ${this.message?.internalPileTurnerId}`);
    }
    if (this.message?.internalWorkCenterId) {
      messages.push(`${this.translateService.instant('HEADER_MESSAGE.WORK_CENTER_ID')}: ${this.message?.internalWorkCenterId}`);
    }
    if (this.message?.apiUrl) {
      messages.push(`${this.translateService.instant('HEADER_MESSAGE.API_URL')}: ${this.message?.apiUrl}`);
    }
    switch (this.messageType) {
      case MessageType.Success: {
        messages.push(this.translateService.instant(this.message?.message));
        break;
      }
      case MessageType.SerializedBackendWarning: {
        this.message?.serializedBackendWarning.serializedWarning.forEach((warning) => {
          if (this.hasTranslation) {
            messages.push(this.translateService.instant(warning.errorCode, warning.parameters));
            messages.push(warning.value);
            messages.push(warning.errorCode);
          } else {
            messages.push(warning.value);
            messages.push(warning.errorCode);
          }
        });
        break;
      }
      case MessageType.SerializedBackendError: {
        const error = this.message?.serializedBackendError;
        messages.push(this.translateService.instant('HEADER_MESSAGE.DEFAULT_BACKEND_ERROR'));
        messages.push(error.type);
        messages.push(error.message);
        messages.push(error.details);
        messages.push(error.stackTrace);
        messages.push(error.source);
        messages.push(JSON.stringify(error.innerException, null, 2));
        break;
      }
      case MessageType.OtherError: {
        messages.push(this.translateService.instant('HEADER_MESSAGE.DEFAULT_BACKEND_ERROR'));
        messages.push(this.message?.message);
        if (this.message?.extraInfo?.length > 0) {
          messages.push(this.formatMessage(this.message?.extraInfo));
        }
        break;
      }
      default: {
        messages.push(this.message?.message);
        if (this.message?.extraInfo?.length > 0) {
          messages.push(this.formatMessage(this.message?.extraInfo));
        }
        break;
      }
    }
    this.clipboard.copy(messages.join('\n'));
  }
}

export enum MessageType {
  SerializedBackendWarning = 'SerializedBackendWarning',
  SerializedBackendError = 'SerializedBackendError',
  OtherError = 'OtherError',
  Success = 'Success',
  DefaultMessage = 'DefaultMessage'
}
