/* tslint:disable */
type WarehouseDimension = 'Item' | 'Pallet' | 'Batch' | 'Serial';
module WarehouseDimension {
  export const ITEM: WarehouseDimension = 'Item';
  export const PALLET: WarehouseDimension = 'Pallet';
  export const BATCH: WarehouseDimension = 'Batch';
  export const SERIAL: WarehouseDimension = 'Serial';
  export function values(): WarehouseDimension[] {
    return [ITEM, PALLET, BATCH, SERIAL];
  }
}

export { WarehouseDimension };
