<section class="chronos-dialog--size-sm additional-info page-padding">
  <div *ngIf="additionalInfo$ | async as info" class="additional-info__container">
    <div class="info" *ngFor="let infoField of info?.infos">
      <ng-container [ngSwitch]="infoField.infoEntryType">
        <div *ngSwitchCase="INFO_TYPE.PRODUCTION_ORDER" class="info__label">{{ 'APPROVE.PRODUCTION_ORDER_INFO' | translate }}</div>
        <div *ngSwitchCase="INFO_TYPE.PRODUCTION_ORDER_KPI" class="info__label">{{ 'APPROVE.PRODUCTION_ORDER_KPI_INFO' | translate }}</div>
        <div *ngSwitchCase="INFO_TYPE.DOWNTIME" class="info__label">{{ 'APPROVE.DOWNTIME_INFO' | translate }}</div>
      </ng-container>
      <div>{{ infoField.text }}</div>
    </div>
    <div class="info" *ngFor="let infoField of info?.shiftInfos">
      <ng-container [ngSwitch]="infoField.infoEntry.infoEntryType">
        <div *ngSwitchCase="INFO_TYPE.SHIFT" class="info__label">{{ 'APPROVE.SHIFT_INFO' | translate }} - {{ infoField.shiftName }}</div>
      </ng-container>
      <div>{{ infoField.infoEntry.text }}</div>
    </div>
    <div class="kpis">
      <div *ngFor="let kpi of info?.kpis">
        <app-kpi-tile [kpi]="kpi" [isActionEnabled]="false"></app-kpi-tile>
      </div>
    </div>
  </div>
  <lib-modal-footer [submitLabel]="'MODAL_FOOTER.OK'" (submitClicked)="onSubmitClick()" [showDeclineButton]="false"></lib-modal-footer>
</section>
