import { Component, EventEmitter, Input, Output } from '@angular/core';
import { productionOrderStatusFlags, Status } from '@app/shared/models';
import { MachineSchedule } from 'chronos-core-client';

@Component({
  selector: 'app-order-sidebar-item',
  templateUrl: './order-sidebar-item.component.html',
  styleUrls: ['./order-sidebar-item.component.scss']
})
export class OrderSidebarItemComponent {
  @Input() public item: MachineSchedule;
  @Input() public isItemActive: boolean;

  @Output() public itemSelected = new EventEmitter<MachineSchedule>();

  public readonly PRODUCTION_JOB_STATUS_FLAGS = productionOrderStatusFlags;
  public readonly STATUS = Status;

  public changeSelectedItem(): void {
    this.itemSelected.emit(this.item);
  }
}
