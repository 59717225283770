import { Component, Input } from '@angular/core';
import { EmployeeRegistrationHistory } from 'chronos-core-client';

@Component({
  selector: 'app-employee-registration-history-table',
  templateUrl: './employee-registration-history-table.component.html',
  styleUrls: ['./employee-registration-history-table.component.scss']
})
export class EmployeeRegistrationHistoryTableComponent {
  constructor() {}
  @Input() public employeeHistory: EmployeeRegistrationHistory[];
}
