import { Injectable } from '@angular/core';
import { HubUrlsService } from '@app/core/utils';
import { WorkCenterDsService } from '@app/core/workcenter';
import { ActiveEmployeeRegistration, WebSocketClientService } from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HeaderService {

  private enableWorkCenterSubject = new BehaviorSubject<boolean>(false);
  enableWorkCenter$: Observable<boolean> = this.enableWorkCenterSubject.asObservable();

  constructor(
    private workCenterDsService: WorkCenterDsService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService
  ) {}

  public getWorkCenters(): Observable<ListValue[]> {
    return this.workCenterDsService
      .getWorkCenters()
      .pipe(
        map((workCenters) =>
          workCenters.map(({ id, externalWorkCenterId, name }) => ({ label: `${externalWorkCenterId} ${name}`, value: id }))
        )
      );
  }

  public getWorkCenterLoginChangedNotifications(workCenterId: number): Observable<ActiveEmployeeRegistration> {
    return this.webSocketClientService.getNotificationsForTopic(this.hubUrlsService.getWorkCenterLoginChanged(workCenterId));
  }

  public enableDisbaledWorkCenter(isEnabled: boolean): void {
    this.enableWorkCenterSubject.next(isEnabled);
  }
}
