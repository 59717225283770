import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ActiveWorkCenterService, WorkCenterDsService } from '@app/core/workcenter';
import { nav } from '@app/shared/utils';
import { WorkCenterData } from 'chronos-core-client';
import { map } from 'rxjs/operators';

export const workCenterGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const WORK_CENTER_QUERY = 'wcid';
  const externalWorkCenterId = route.queryParamMap.get(WORK_CENTER_QUERY);
  const workCenterDsService: WorkCenterDsService = inject(WorkCenterDsService);
  const activeWorkCenterService: ActiveWorkCenterService = inject(ActiveWorkCenterService);
  const router: Router = inject(Router);

  if (externalWorkCenterId) {
    return workCenterDsService.getWorkCenters().pipe(
      map((workCenters) => isWorkCenterAvailable(workCenters, externalWorkCenterId)),
      map((workCenter) => redirectToWorkCenter(workCenter, state, activeWorkCenterService, router))
    );
  } else {
    return true;
  }
};

const redirectToWorkCenter = (
  workCenter: WorkCenterData,
  state: RouterStateSnapshot,
  activeWorkCenterService: ActiveWorkCenterService,
  router: Router
): UrlTree => {
  if (workCenter) {
    const path = state.url.split('?')[0];
    activeWorkCenterService.setWorkCenterId(workCenter.id);

    return router.createUrlTree([path]);
  } else {
    return router.createUrlTree([nav.workCenterSelection]);
  }
};

const isWorkCenterAvailable = (workCenters: WorkCenterData[], externalId: string): WorkCenterData =>
  workCenters.find((workCenter) => workCenter.externalWorkCenterId === externalId);
