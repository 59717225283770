<div class="material-status" [ngClass]="{ 'material-status--right': alignRight }" *ngIf="article">
  <div class="material-status__title" [class]="styleClass">
    <span>{{ title }}</span>
    <span *ngIf="showPlannedStartTime">: {{ plannedStartTime | utcToLocal | date: 'HH:mm' }}</span>
  </div>
  <ng-content></ng-content>
  <div [ngClass]="{ 'material-status--right': alignRight }">
    <div>{{ article.articleName }}</div>
    <div>{{ article.configurationName }}</div>
    <ng-container *ngIf="condensed; else notCondensed">
      <div>{{ article.externalArticleId }} / {{ article.externalConfigurationId | emptyTextPlaceholder }}</div>
    </ng-container>
    <ng-template #notCondensed>
      <div>{{ article.externalArticleId }}</div>
      <div>{{ article.externalConfigurationId | emptyTextPlaceholder }}</div>
    </ng-template>
  </div>
</div>
