/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MountPrimaryContainerResponse } from '../models/mount-primary-container-response';
import { MountPrimaryContainerEntry } from '../models/mount-primary-container-entry';
import { MountAndConsumePrimaryContainerEntry } from '../models/mount-and-consume-primary-container-entry';
import { MountAndConsumePrimaryContainersEntry } from '../models/mount-and-consume-primary-containers-entry';
import { DismountPrimaryContainerEntry } from '../models/dismount-primary-container-entry';
import { ChangeMountedMaterialSequenceEntry } from '../models/change-mounted-material-sequence-entry';
import { CreateAndMountVirtualPrimaryContainerEntry } from '../models/create-and-mount-virtual-primary-container-entry';
import { ReplaceVirtualPrimaryContainerEntity } from '../models/replace-virtual-primary-container-entity';
import { WhiteWasteEntry } from '../models/white-waste-entry';
import { WorkCenterIdEntry } from '../models/work-center-id-entry';
import { PrimaryMaterial } from '../models/primary-material';
import { PrimaryMaterialBlock } from '../models/primary-material-block';
import { PrimaryMaterialBlockConsumed } from '../models/primary-material-block-consumed';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { PrimaryMaterialMounted } from '../models/primary-material-mounted';
import { CreatePrimaryVirtualContainerData } from '../models/create-primary-virtual-container-data';
@Injectable({
  providedIn: 'root'
})
class PrimaryMaterialService extends __BaseService {
  static readonly mountPrimaryContainerPath = '/api/v1/PrimaryMaterial/{workCenterId}/Mount';
  static readonly consumePrimaryContainerPath = '/api/v1/PrimaryMaterial/{workCenterId}/Consume';
  static readonly consumePrimaryContainer2Path = '/api/v1/PrimaryMaterial/{workCenterId}/ConsumeContainers';
  static readonly dismountContainerPath = '/api/v1/PrimaryMaterial/{containerId}/Dismount';
  static readonly changeMountedMaterialSequencePath = '/api/v1/PrimaryMaterial/{materialBlockId}/ChangeMountedMaterialSequence';
  static readonly createAndMountVirtualPrimaryContainerPath =
    '/api/v1/PrimaryMaterial/{workCenterId}/CreateAndMountVirtualPrimaryContainer';
  static readonly replaceVirtualPrimaryContainerPath = '/api/v1/PrimaryMaterial/{mountedMaterialId}/ReplaceVirtualContainer';
  static readonly addWhiteWasteToMountedMaterialPath = '/api/v1/PrimaryMaterial/{mountedMaterialId}/AddWhiteWasteToMountedMaterial';
  static readonly deleteWhiteWasteAssignmentPath = '/api/v1/PrimaryMaterial/{mountedMaterialId}/DeleteWhiteWasteFromMountedMaterial';
  static readonly getCardViewGroupByMaterialPath = '/api/v1/PrimaryMaterial/{workCenterId}/CardView/GroupByMaterial';
  static readonly getListViewGroupByMaterialPath = '/api/v1/PrimaryMaterial/{workCenterId}/ListView/GroupByMaterial';
  static readonly getMountedMaterialsPath = '/api/v1/PrimaryMaterial/{workCenterId}/MountedMaterials';
  static readonly getConsumptionPath = '/api/v1/PrimaryMaterial/{workCenterId}/Consumption';
  static readonly getMaterialAvailableAtMachinePath = '/api/v1/PrimaryMaterial/{workCenterId}/materialAvailableAtMachine';
  static readonly getMaterialAvailableAtMachineForProductionOrderPath =
    '/api/v1/PrimaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailableAtMachine';
  static readonly getMaterialAvailableAtWarehousePath = '/api/v1/PrimaryMaterial/{workCenterId}/materialAvailableAtWarehouse';
  static readonly getMaterialAvailableAtWarehouseForProductionOrderPath =
    '/api/v1/PrimaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailableAtWarehouse';
  static readonly getMaterialAvailableForProductionOrderPath =
    '/api/v1/PrimaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailable';
  static readonly getNextPrimaryContainerForDismountPath = '/api/v1/PrimaryMaterial/{workCenterId}/NextPrimaryContainerForDismount';
  static readonly getPrimaryVirtualContainerCreationDataPath =
    '/api/v1/PrimaryMaterial/{productionOrderId}/GetPrimaryVirtualContainerCreationData';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `PrimaryMaterialService.MountPrimaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  mountPrimaryContainerResponse(
    params: PrimaryMaterialService.MountPrimaryContainerParams
  ): __Observable<__StrictHttpResponse<MountPrimaryContainerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.workCenterId))}/Mount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MountPrimaryContainerResponse>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.MountPrimaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  mountPrimaryContainer(params: PrimaryMaterialService.MountPrimaryContainerParams): __Observable<MountPrimaryContainerResponse> {
    return this.mountPrimaryContainerResponse(params).pipe(__map((_r) => _r.body as MountPrimaryContainerResponse));
  }

  /**
   * @param params The `PrimaryMaterialService.ConsumePrimaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  consumePrimaryContainerResponse(params: PrimaryMaterialService.ConsumePrimaryContainerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.workCenterId))}/Consume`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.ConsumePrimaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  consumePrimaryContainer(params: PrimaryMaterialService.ConsumePrimaryContainerParams): __Observable<null> {
    return this.consumePrimaryContainerResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `PrimaryMaterialService.ConsumePrimaryContainer2Params` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  consumePrimaryContainer2Response(
    params: PrimaryMaterialService.ConsumePrimaryContainer2Params
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.workCenterId))}/ConsumeContainers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.ConsumePrimaryContainer2Params` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  consumePrimaryContainer2(params: PrimaryMaterialService.ConsumePrimaryContainer2Params): __Observable<null> {
    return this.consumePrimaryContainer2Response(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `PrimaryMaterialService.DismountContainerParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `containerId`:
   */
  dismountContainerResponse(params: PrimaryMaterialService.DismountContainerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.containerId))}/Dismount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.DismountContainerParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `containerId`:
   */
  dismountContainer(params: PrimaryMaterialService.DismountContainerParams): __Observable<null> {
    return this.dismountContainerResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `PrimaryMaterialService.ChangeMountedMaterialSequenceParams` containing the following parameters:
   *
   * - `materialBlockId`:
   *
   * - `entry`:
   */
  changeMountedMaterialSequenceResponse(
    params: PrimaryMaterialService.ChangeMountedMaterialSequenceParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.materialBlockId))}/ChangeMountedMaterialSequence`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.ChangeMountedMaterialSequenceParams` containing the following parameters:
   *
   * - `materialBlockId`:
   *
   * - `entry`:
   */
  changeMountedMaterialSequence(params: PrimaryMaterialService.ChangeMountedMaterialSequenceParams): __Observable<null> {
    return this.changeMountedMaterialSequenceResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `PrimaryMaterialService.CreateAndMountVirtualPrimaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  createAndMountVirtualPrimaryContainerResponse(
    params: PrimaryMaterialService.CreateAndMountVirtualPrimaryContainerParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.workCenterId))}/CreateAndMountVirtualPrimaryContainer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.CreateAndMountVirtualPrimaryContainerParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  createAndMountVirtualPrimaryContainer(params: PrimaryMaterialService.CreateAndMountVirtualPrimaryContainerParams): __Observable<null> {
    return this.createAndMountVirtualPrimaryContainerResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `PrimaryMaterialService.ReplaceVirtualPrimaryContainerParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  replaceVirtualPrimaryContainerResponse(
    params: PrimaryMaterialService.ReplaceVirtualPrimaryContainerParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.mountedMaterialId))}/ReplaceVirtualContainer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.ReplaceVirtualPrimaryContainerParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  replaceVirtualPrimaryContainer(params: PrimaryMaterialService.ReplaceVirtualPrimaryContainerParams): __Observable<null> {
    return this.replaceVirtualPrimaryContainerResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `PrimaryMaterialService.AddWhiteWasteToMountedMaterialParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  addWhiteWasteToMountedMaterialResponse(
    params: PrimaryMaterialService.AddWhiteWasteToMountedMaterialParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.mountedMaterialId))}/AddWhiteWasteToMountedMaterial`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.AddWhiteWasteToMountedMaterialParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  addWhiteWasteToMountedMaterial(params: PrimaryMaterialService.AddWhiteWasteToMountedMaterialParams): __Observable<null> {
    return this.addWhiteWasteToMountedMaterialResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `PrimaryMaterialService.DeleteWhiteWasteAssignmentParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  deleteWhiteWasteAssignmentResponse(
    params: PrimaryMaterialService.DeleteWhiteWasteAssignmentParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.mountedMaterialId))}/DeleteWhiteWasteFromMountedMaterial`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.DeleteWhiteWasteAssignmentParams` containing the following parameters:
   *
   * - `mountedMaterialId`:
   *
   * - `entry`:
   */
  deleteWhiteWasteAssignment(params: PrimaryMaterialService.DeleteWhiteWasteAssignmentParams): __Observable<null> {
    return this.deleteWhiteWasteAssignmentResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  getCardViewGroupByMaterialResponse(workCenterId: number): __Observable<__StrictHttpResponse<PrimaryMaterial>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(workCenterId))}/CardView/GroupByMaterial`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrimaryMaterial>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getCardViewGroupByMaterial(workCenterId: number): __Observable<PrimaryMaterial> {
    return this.getCardViewGroupByMaterialResponse(workCenterId).pipe(__map((_r) => _r.body as PrimaryMaterial));
  }

  /**
   * @param workCenterId undefined
   */
  getListViewGroupByMaterialResponse(workCenterId: number): __Observable<__StrictHttpResponse<PrimaryMaterial>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(workCenterId))}/ListView/GroupByMaterial`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrimaryMaterial>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getListViewGroupByMaterial(workCenterId: number): __Observable<PrimaryMaterial> {
    return this.getListViewGroupByMaterialResponse(workCenterId).pipe(__map((_r) => _r.body as PrimaryMaterial));
  }

  /**
   * @param workCenterId undefined
   */
  getMountedMaterialsResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<PrimaryMaterialBlock>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(workCenterId))}/MountedMaterials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PrimaryMaterialBlock>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getMountedMaterials(workCenterId: number): __Observable<Array<PrimaryMaterialBlock>> {
    return this.getMountedMaterialsResponse(workCenterId).pipe(__map((_r) => _r.body as Array<PrimaryMaterialBlock>));
  }

  /**
   * @param workCenterId undefined
   */
  getConsumptionResponse(workCenterId: number): __Observable<__StrictHttpResponse<PrimaryMaterialBlockConsumed>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(workCenterId))}/Consumption`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrimaryMaterialBlockConsumed>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getConsumption(workCenterId: number): __Observable<PrimaryMaterialBlockConsumed> {
    return this.getConsumptionResponse(workCenterId).pipe(__map((_r) => _r.body as PrimaryMaterialBlockConsumed));
  }

  /**
   * @param workCenterId undefined
   */
  getMaterialAvailableAtMachineResponse(workCenterId: number): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(workCenterId))}/materialAvailableAtMachine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getMaterialAvailableAtMachine(workCenterId: number): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtMachineResponse(workCenterId).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param params The `PrimaryMaterialService.GetMaterialAvailableAtMachineForProductionOrderParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   */
  getMaterialAvailableAtMachineForProductionOrderResponse(
    params: PrimaryMaterialService.GetMaterialAvailableAtMachineForProductionOrderParams
  ): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.workCenterId))}/productionOrders/${encodeURIComponent(
          String(params.productionOrderId)
        )}/materialAvailableAtMachine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.GetMaterialAvailableAtMachineForProductionOrderParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   */
  getMaterialAvailableAtMachineForProductionOrder(
    params: PrimaryMaterialService.GetMaterialAvailableAtMachineForProductionOrderParams
  ): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtMachineForProductionOrderResponse(params).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param workCenterId undefined
   */
  getMaterialAvailableAtWarehouseResponse(workCenterId: number): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(workCenterId))}/materialAvailableAtWarehouse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getMaterialAvailableAtWarehouse(workCenterId: number): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtWarehouseResponse(workCenterId).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param params The `PrimaryMaterialService.GetMaterialAvailableAtWarehouseForProductionOrderParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   */
  getMaterialAvailableAtWarehouseForProductionOrderResponse(
    params: PrimaryMaterialService.GetMaterialAvailableAtWarehouseForProductionOrderParams
  ): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.workCenterId))}/productionOrders/${encodeURIComponent(
          String(params.productionOrderId)
        )}/materialAvailableAtWarehouse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.GetMaterialAvailableAtWarehouseForProductionOrderParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   */
  getMaterialAvailableAtWarehouseForProductionOrder(
    params: PrimaryMaterialService.GetMaterialAvailableAtWarehouseForProductionOrderParams
  ): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtWarehouseForProductionOrderResponse(params).pipe(
      __map((_r) => _r.body as MaterialAvailableAtLocation)
    );
  }

  /**
   * @param params The `PrimaryMaterialService.GetMaterialAvailableForProductionOrderParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   */
  getMaterialAvailableForProductionOrderResponse(
    params: PrimaryMaterialService.GetMaterialAvailableForProductionOrderParams
  ): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/PrimaryMaterial/${encodeURIComponent(String(params.workCenterId))}/productionOrders/${encodeURIComponent(
          String(params.productionOrderId)
        )}/materialAvailable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param params The `PrimaryMaterialService.GetMaterialAvailableForProductionOrderParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   */
  getMaterialAvailableForProductionOrder(
    params: PrimaryMaterialService.GetMaterialAvailableForProductionOrderParams
  ): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForProductionOrderResponse(params).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param workCenterId undefined
   */
  getNextPrimaryContainerForDismountResponse(workCenterId: number): __Observable<__StrictHttpResponse<PrimaryMaterialMounted>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(workCenterId))}/NextPrimaryContainerForDismount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrimaryMaterialMounted>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getNextPrimaryContainerForDismount(workCenterId: number): __Observable<PrimaryMaterialMounted> {
    return this.getNextPrimaryContainerForDismountResponse(workCenterId).pipe(__map((_r) => _r.body as PrimaryMaterialMounted));
  }

  /**
   * @param productionOrderId undefined
   */
  getPrimaryVirtualContainerCreationDataResponse(
    productionOrderId: number
  ): __Observable<__StrictHttpResponse<CreatePrimaryVirtualContainerData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PrimaryMaterial/${encodeURIComponent(String(productionOrderId))}/GetPrimaryVirtualContainerCreationData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatePrimaryVirtualContainerData>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getPrimaryVirtualContainerCreationData(productionOrderId: number): __Observable<CreatePrimaryVirtualContainerData> {
    return this.getPrimaryVirtualContainerCreationDataResponse(productionOrderId).pipe(
      __map((_r) => _r.body as CreatePrimaryVirtualContainerData)
    );
  }
}

module PrimaryMaterialService {
  /**
   * Parameters for MountPrimaryContainer
   */
  export interface MountPrimaryContainerParams {
    workCenterId: number;
    entry: MountPrimaryContainerEntry;
  }

  /**
   * Parameters for ConsumePrimaryContainer
   */
  export interface ConsumePrimaryContainerParams {
    workCenterId: number;
    entry: MountAndConsumePrimaryContainerEntry;
  }

  /**
   * Parameters for ConsumePrimaryContainer2
   */
  export interface ConsumePrimaryContainer2Params {
    workCenterId: number;
    entry: MountAndConsumePrimaryContainersEntry;
  }

  /**
   * Parameters for DismountContainer
   */
  export interface DismountContainerParams {
    entry: DismountPrimaryContainerEntry;
    containerId: number;
  }

  /**
   * Parameters for ChangeMountedMaterialSequence
   */
  export interface ChangeMountedMaterialSequenceParams {
    materialBlockId: number;
    entry: ChangeMountedMaterialSequenceEntry;
  }

  /**
   * Parameters for CreateAndMountVirtualPrimaryContainer
   */
  export interface CreateAndMountVirtualPrimaryContainerParams {
    workCenterId: number;
    entry: CreateAndMountVirtualPrimaryContainerEntry;
  }

  /**
   * Parameters for ReplaceVirtualPrimaryContainer
   */
  export interface ReplaceVirtualPrimaryContainerParams {
    mountedMaterialId: number;
    entry: ReplaceVirtualPrimaryContainerEntity;
  }

  /**
   * Parameters for AddWhiteWasteToMountedMaterial
   */
  export interface AddWhiteWasteToMountedMaterialParams {
    mountedMaterialId: number;
    entry: WhiteWasteEntry;
  }

  /**
   * Parameters for DeleteWhiteWasteAssignment
   */
  export interface DeleteWhiteWasteAssignmentParams {
    mountedMaterialId: number;
    entry: WorkCenterIdEntry;
  }

  /**
   * Parameters for GetMaterialAvailableAtMachineForProductionOrder
   */
  export interface GetMaterialAvailableAtMachineForProductionOrderParams {
    workCenterId: number;
    productionOrderId: number;
  }

  /**
   * Parameters for GetMaterialAvailableAtWarehouseForProductionOrder
   */
  export interface GetMaterialAvailableAtWarehouseForProductionOrderParams {
    workCenterId: number;
    productionOrderId: number;
  }

  /**
   * Parameters for GetMaterialAvailableForProductionOrder
   */
  export interface GetMaterialAvailableForProductionOrderParams {
    workCenterId: number;
    productionOrderId: number;
  }
}

export { PrimaryMaterialService };
