import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppInitService } from '@app/app-init.service';
import { HeaderService } from '@app/core/services';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { nav } from '@app/shared/utils';
import { ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-work-center-selection',
  templateUrl: './work-center-selection.component.html',
  styleUrls: ['./work-center-selection.component.scss']
})
export class WorkCenterSelectionComponent implements OnInit {
  public workCenterOptions$: Observable<ListValue[]>;
  public currentWorkCenter: number | null;
  public loading = true;

  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private headerService: HeaderService,
    private router: Router,
    private appInitService: AppInitService,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.appInitService.loadingIndicatorSubject$.subscribe((val) => {
      const element = document.getElementById('loader');
      if (val) {
        this.renderer.setStyle(element, 'visibility', 'visible');
      } else {
        this.renderer.setStyle(element, 'display', 'none');
      }
    });

    this.workCenterOptions$ = this.headerService.getWorkCenters().pipe(finalize(() => (this.loading = false)));
  }

  public onWorkCenterChange(newWorkCenterId: number): void {
    this.appInitService.loadingIndicatorSubject.next(true);
    this.activeWorkCenterService.setWorkCenterId(newWorkCenterId);
    this.router.navigate([nav.root]).then(() => {
      location.reload();
      this.appInitService.loadingIndicatorSubject.next(false);
    });
  }
}
