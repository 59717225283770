<ng-container *ngIf="fields" [formGroup]="form">
  <div class="line-clearance__table">
    <div class="line-clearance__header row no-gutters">
      <div class="col-5">
        {{ 'SETUP_LINE_CLEARANCE.CHECK' | translate }}
      </div>
      <div class="line-clearance__header--result">
        {{ 'SETUP_LINE_CLEARANCE.RESULT' | translate }}
      </div>
      <div class="col">
        {{ 'SETUP_LINE_CLEARANCE.INFO' | translate }}
      </div>
    </div>
    <div class="line-clearance__body" [style.maxHeight]="scrollHeight">
      <ng-container *ngFor="let qaForm of qaCheckService.getQaCheckFormControls(form); let i = index">
        <div class="line-clearance__element row no-gutters">
          <div class="col">
            <app-line-clearance-item
              [field]="qaCheckService.getQaCheckDescriptionData(qaForm.get('id').value, fields)"
              [parentForm]="qaForm"
              [index]="i"
            ></app-line-clearance-item>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
