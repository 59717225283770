<div class="nc-defective-quantity">
  <div class="nc-defective-quantity__title">{{ 'QA_HISTORY.DEFECTIVE' | translate }}</div>
  <div *ngIf="nonConformanceModel" class="nc-defective-quantity__container">
    <i class="icon-pallet-4-4 nc-defective-quantity__icon"></i>
    <lib-pallet-kpi
      [kpiLabel]="'QA_HISTORY.KPI_QUANTITY' | translate"
      [kpiValue]="nonConformanceModel.sortingWasteQuantity?.toString()"
      [compact]="true"
      [leftAligned]="true"
      [isEditable]="isCreateNCMode"
      (kpiValueChanged)="kpiValueChanged($event)"
    ></lib-pallet-kpi>
    <div class="nc-defective-quantity__info">
      <div class="info__label">{{ 'QA_HISTORY.PROCESS_STEP' | translate }}</div>
      <div class="info__value">{{ nonConformanceModel.sortingWasteOrigin }}</div>
      <div class="info__label">{{ 'QA_HISTORY.WORK_ORDER' | translate }}</div>
      <div class="info__value">{{ nonConformanceModel.externalWorkCenterId }}</div>
    </div>
  </div>
</div>
