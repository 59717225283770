/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrganizationOr } from '../models/organization-or';
import { MetaAttribute } from '../models/meta-attribute';
import { OrgnizationList } from '../models/orgnization-list';
import { VerticalTreeNode } from '../models/vertical-tree-node';
import { MetamodelMe } from '../models/metamodel-me';
import { OrganizationSettings } from '../models/organization-settings';
@Injectable({
  providedIn: 'root'
})
class OrganizationsService extends __BaseService {
  static readonly getOrganizationPath = '/api/Organizations/{orid}';
  static readonly updatePath = '/api/Organizations/{orid}';
  static readonly getOrganizationByNamePath = '/api/Organizations/getOrganizationByName';
  static readonly loadMetaAttributesWithValuePath = '/api/Organizations/loadMetaAttributesWithValue/{orid}/{meid}';
  static readonly loadMetaAttributesPath = '/api/Organizations/loadMetaAttributes/{orid}/{meid}';
  static readonly saveGeneralInformationMetaAttributesAsyncPath = '/api/Organizations/saveGeneralInformation/{orid}';
  static readonly addPath = '/api/Organizations';
  static readonly activeInactiveOrgnizationPath = '/api/Organizations';
  static readonly deleteAsyncPath = '/api/Organizations/delete/{orid}';
  static readonly getActiveOrganizationsPath = '/api/Organizations/activeOrganizations/{orid}';
  static readonly getActiveOrganizationsForUserPath = '/api/Organizations/activeOrganizationsForUser/{orid}';
  static readonly getActiveOrganizationForSpeedMonitorPath = '/api/Organizations/activeOrganizationForSpeedMonitor/{orid}';
  static readonly getTreePath = '/api/Organizations/tree/{orid}';
  static readonly getTreeStructurePath = '/api/Organizations/verticaltree';
  static readonly getTenantListPath = '/api/Organizations/tenants/{orid}';
  static readonly getParentOrganizationDetailsPath = '/api/Organizations/parentstree/{orid}';
  static readonly getReverseTreePath = '/api/Organizations/reversetree/{orid}';
  static readonly getReverseTreeOfTenantPath = '/api/Organizations/reversetreeoftenant/{orid}/{meid}';
  static readonly getTenantPath = '/api/Organizations/tenant/{orid}';
  static readonly getTreeDetailsPath = '/api/Organizations/treeDetails/{orid}';
  static readonly getDetailsPath = '/api/Organizations/details/{orid}';
  static readonly getNodeTypeListPath = '/api/Organizations/nodetypes/{orid}';
  static readonly getOrganizationSettingsPath = '/api/Organizations/orgSettings';
  static readonly getOrganizationByExternalIdPath = '/api/Organizations/org/byexternalid';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationResponse(orid: number): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getOrganization(orid: number): __Observable<OrganizationOr> {
    return this.getOrganizationResponse(orid).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param params The `OrganizationsService.UpdateParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `meid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateResponse(params: OrganizationsService.UpdateParams): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.meid != null) __params = __params.set('meid', params.meid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Organizations/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.UpdateParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `meid`:
   *
   * - `body`:
   *
   * @return Success
   */
  update(params: OrganizationsService.UpdateParams): __Observable<OrganizationOr> {
    return this.updateResponse(params).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param params The `OrganizationsService.GetOrganizationByNameParams` containing the following parameters:
   *
   * - `orname`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getOrganizationByNameResponse(
    params: OrganizationsService.GetOrganizationByNameParams
  ): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orname != null) __params = __params.set('orname', params.orname.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/getOrganizationByName`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.GetOrganizationByNameParams` containing the following parameters:
   *
   * - `orname`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getOrganizationByName(params: OrganizationsService.GetOrganizationByNameParams): __Observable<OrganizationOr> {
    return this.getOrganizationByNameResponse(params).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param params The `OrganizationsService.LoadMetaAttributesWithValueParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `meid`:
   *
   * @return Success
   */
  loadMetaAttributesWithValueResponse(
    params: OrganizationsService.LoadMetaAttributesWithValueParams
  ): __Observable<__StrictHttpResponse<Array<MetaAttribute>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/Organizations/loadMetaAttributesWithValue/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(
          String(params.meid)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetaAttribute>>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.LoadMetaAttributesWithValueParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `meid`:
   *
   * @return Success
   */
  loadMetaAttributesWithValue(params: OrganizationsService.LoadMetaAttributesWithValueParams): __Observable<Array<MetaAttribute>> {
    return this.loadMetaAttributesWithValueResponse(params).pipe(__map((_r) => _r.body as Array<MetaAttribute>));
  }

  /**
   * @param params The `OrganizationsService.LoadMetaAttributesParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `meid`:
   *
   * @return Success
   */
  loadMetaAttributesResponse(
    params: OrganizationsService.LoadMetaAttributesParams
  ): __Observable<__StrictHttpResponse<Array<MetaAttribute>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/Organizations/loadMetaAttributes/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.meid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetaAttribute>>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.LoadMetaAttributesParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `meid`:
   *
   * @return Success
   */
  loadMetaAttributes(params: OrganizationsService.LoadMetaAttributesParams): __Observable<Array<MetaAttribute>> {
    return this.loadMetaAttributesResponse(params).pipe(__map((_r) => _r.body as Array<MetaAttribute>));
  }

  /**
   * @param params The `OrganizationsService.SaveGeneralInformationMetaAttributesAsyncParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  saveGeneralInformationMetaAttributesAsyncResponse(
    params: OrganizationsService.SaveGeneralInformationMetaAttributesAsyncParams
  ): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Organizations/saveGeneralInformation/${encodeURIComponent(String(params.orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.SaveGeneralInformationMetaAttributesAsyncParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  saveGeneralInformationMetaAttributesAsync(
    params: OrganizationsService.SaveGeneralInformationMetaAttributesAsyncParams
  ): __Observable<number> {
    return this.saveGeneralInformationMetaAttributesAsyncResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `OrganizationsService.AddParams` containing the following parameters:
   *
   * - `meid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addResponse(params: OrganizationsService.AddParams): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.meid != null) __params = __params.set('meid', params.meid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Organizations`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.AddParams` containing the following parameters:
   *
   * - `meid`:
   *
   * - `body`:
   *
   * @return Success
   */
  add(params: OrganizationsService.AddParams): __Observable<OrganizationOr> {
    return this.addResponse(params).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param params The `OrganizationsService.ActiveInactiveOrgnizationParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  activeInactiveOrgnizationResponse(
    params: OrganizationsService.ActiveInactiveOrgnizationParams
  ): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Organizations`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.ActiveInactiveOrgnizationParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  activeInactiveOrgnization(params: OrganizationsService.ActiveInactiveOrgnizationParams): __Observable<number> {
    return this.activeInactiveOrgnizationResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  deleteAsyncResponse(orid: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/Organizations/delete/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  deleteAsync(orid: number): __Observable<boolean> {
    return this.deleteAsyncResponse(orid).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getActiveOrganizationsResponse(orid: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Organizations/activeOrganizations/${encodeURIComponent(String(orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getActiveOrganizations(orid: number): __Observable<Array<OrganizationOr>> {
    return this.getActiveOrganizationsResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getActiveOrganizationsForUserResponse(orid: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Organizations/activeOrganizationsForUser/${encodeURIComponent(String(orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getActiveOrganizationsForUser(orid: number): __Observable<Array<OrganizationOr>> {
    return this.getActiveOrganizationsForUserResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getActiveOrganizationForSpeedMonitorResponse(orid: number): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Organizations/activeOrganizationForSpeedMonitor/${encodeURIComponent(String(orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getActiveOrganizationForSpeedMonitor(orid: number): __Observable<OrganizationOr> {
    return this.getActiveOrganizationForSpeedMonitorResponse(orid).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getTreeResponse(orid: number): __Observable<__StrictHttpResponse<Array<OrgnizationList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/tree/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrgnizationList>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getTree(orid: number): __Observable<Array<OrgnizationList>> {
    return this.getTreeResponse(orid).pipe(__map((_r) => _r.body as Array<OrgnizationList>));
  }

  /**
   * @return Success
   */
  getTreeStructureResponse(): __Observable<__StrictHttpResponse<Array<VerticalTreeNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/verticaltree`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VerticalTreeNode>>;
      })
    );
  }
  /**
   * @return Success
   */
  getTreeStructure(): __Observable<Array<VerticalTreeNode>> {
    return this.getTreeStructureResponse().pipe(__map((_r) => _r.body as Array<VerticalTreeNode>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getTenantListResponse(orid: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/tenants/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getTenantList(orid: number): __Observable<Array<OrganizationOr>> {
    return this.getTenantListResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getParentOrganizationDetailsResponse(orid: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/parentstree/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getParentOrganizationDetails(orid: number): __Observable<Array<OrganizationOr>> {
    return this.getParentOrganizationDetailsResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getReverseTreeResponse(orid: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/reversetree/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getReverseTree(orid: number): __Observable<Array<OrganizationOr>> {
    return this.getReverseTreeResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param params The `OrganizationsService.GetReverseTreeOfTenantParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `meid`:
   *
   * @return Success
   */
  getReverseTreeOfTenantResponse(
    params: OrganizationsService.GetReverseTreeOfTenantParams
  ): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/Organizations/reversetreeoftenant/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.meid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.GetReverseTreeOfTenantParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `meid`:
   *
   * @return Success
   */
  getReverseTreeOfTenant(params: OrganizationsService.GetReverseTreeOfTenantParams): __Observable<Array<OrganizationOr>> {
    return this.getReverseTreeOfTenantResponse(params).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getTenantResponse(orid: number): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/tenant/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getTenant(orid: number): __Observable<OrganizationOr> {
    return this.getTenantResponse(orid).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getTreeDetailsResponse(orid: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/treeDetails/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getTreeDetails(orid: number): __Observable<Array<OrganizationOr>> {
    return this.getTreeDetailsResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getDetailsResponse(orid: number): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/details/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getDetails(orid: number): __Observable<OrganizationOr> {
    return this.getDetailsResponse(orid).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getNodeTypeListResponse(orid: number): __Observable<__StrictHttpResponse<Array<MetamodelMe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/nodetypes/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetamodelMe>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getNodeTypeList(orid: number): __Observable<Array<MetamodelMe>> {
    return this.getNodeTypeListResponse(orid).pipe(__map((_r) => _r.body as Array<MetamodelMe>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationSettingsResponse(orid?: number): __Observable<__StrictHttpResponse<OrganizationSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/orgSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationSettings>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationSettings(orid?: number): __Observable<OrganizationSettings> {
    return this.getOrganizationSettingsResponse(orid).pipe(__map((_r) => _r.body as OrganizationSettings));
  }

  /**
   * @param externalId undefined
   * @return Success
   */
  getOrganizationByExternalIdResponse(externalId?: string): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (externalId != null) __params = __params.set('externalId', externalId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Organizations/org/byexternalid`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param externalId undefined
   * @return Success
   */
  getOrganizationByExternalId(externalId?: string): __Observable<OrganizationOr> {
    return this.getOrganizationByExternalIdResponse(externalId).pipe(__map((_r) => _r.body as OrganizationOr));
  }
}

module OrganizationsService {
  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    orid: number;
    meid?: number;
    body?: OrganizationOr;
  }

  /**
   * Parameters for GetOrganizationByName
   */
  export interface GetOrganizationByNameParams {
    orname?: string;
    orid?: number;
  }

  /**
   * Parameters for LoadMetaAttributesWithValue
   */
  export interface LoadMetaAttributesWithValueParams {
    orid: number;
    meid: number;
  }

  /**
   * Parameters for LoadMetaAttributes
   */
  export interface LoadMetaAttributesParams {
    orid: number;
    meid: number;
  }

  /**
   * Parameters for SaveGeneralInformationMetaAttributesAsync
   */
  export interface SaveGeneralInformationMetaAttributesAsyncParams {
    orid: number;
    body?: { [key: string]: string };
  }

  /**
   * Parameters for Add
   */
  export interface AddParams {
    meid?: number;
    body?: OrganizationOr;
  }

  /**
   * Parameters for ActiveInactiveOrgnization
   */
  export interface ActiveInactiveOrgnizationParams {
    orid?: number;
    body?: OrganizationOr;
  }

  /**
   * Parameters for GetReverseTreeOfTenant
   */
  export interface GetReverseTreeOfTenantParams {
    orid: number;
    meid: number;
  }
}

export { OrganizationsService };
