/* tslint:disable */
type RunPhaseType = 'None' | 'Setup' | 'Run' | 'Finishing';
module RunPhaseType {
  export const NONE: RunPhaseType = 'None';
  export const SETUP: RunPhaseType = 'Setup';
  export const RUN: RunPhaseType = 'Run';
  export const FINISHING: RunPhaseType = 'Finishing';
  export function values(): RunPhaseType[] {
    return [NONE, SETUP, RUN, FINISHING];
  }
}

export { RunPhaseType };
