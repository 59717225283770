/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SendShiftReportEntry } from '../models/send-shift-report-entry';
import { CancelSendShiftReportEntry } from '../models/cancel-send-shift-report-entry';
import { Shift } from '../models/shift';
import { ShiftReport } from '../models/shift-report';
import { OpenTaskItem } from '../models/open-task-item';
import { KpiValue } from '../models/kpi-value';
import { ShiftReportItem } from '../models/shift-report-item';
@Injectable({
  providedIn: 'root'
})
class ShiftReportService extends __BaseService {
  static readonly sendShiftReportPath = '/api/v1/ShiftReport/{workCenterId}/SendShiftReport';
  static readonly cancelSendShiftReportPath = '/api/v1/ShiftReport/{workCenterId}/CancelSendShiftReport';
  static readonly getShiftsPath = '/api/v1/ShiftReport/{workCenterId}';
  static readonly getShiftReportPath = '/api/v1/ShiftReport/{workCenterId}/GetShiftReport/{shiftId}';
  static readonly getOpenTasksPath = '/api/v1/ShiftReport/{workCenterId}/OpenTasks/{shiftId}';
  static readonly getHeaderShiftKpisPath = '/api/v1/ShiftReport/{workCenterId}/GetHeaderShiftKpis/{shiftId}';
  static readonly getProductionOrderReportForShiftPath =
    '/api/v1/ShiftReport/{productionOrderId}/GetProductionOrderReportForShift/{shiftId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `ShiftReportService.SendShiftReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  sendShiftReportResponse(params: ShiftReportService.SendShiftReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ShiftReport/${encodeURIComponent(String(params.workCenterId))}/SendShiftReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShiftReportService.SendShiftReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  sendShiftReport(params: ShiftReportService.SendShiftReportParams): __Observable<null> {
    return this.sendShiftReportResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ShiftReportService.CancelSendShiftReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  cancelSendShiftReportResponse(params: ShiftReportService.CancelSendShiftReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ShiftReport/${encodeURIComponent(String(params.workCenterId))}/CancelSendShiftReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShiftReportService.CancelSendShiftReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  cancelSendShiftReport(params: ShiftReportService.CancelSendShiftReportParams): __Observable<null> {
    return this.cancelSendShiftReportResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  getShiftsResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<Shift>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/ShiftReport/${encodeURIComponent(String(workCenterId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Shift>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getShifts(workCenterId: number): __Observable<Array<Shift>> {
    return this.getShiftsResponse(workCenterId).pipe(__map((_r) => _r.body as Array<Shift>));
  }

  /**
   * @param params The `ShiftReportService.GetShiftReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftId`:
   */
  getShiftReportResponse(params: ShiftReportService.GetShiftReportParams): __Observable<__StrictHttpResponse<ShiftReport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/ShiftReport/${encodeURIComponent(String(params.workCenterId))}/GetShiftReport/${encodeURIComponent(
          String(params.shiftId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShiftReport>;
      })
    );
  }
  /**
   * @param params The `ShiftReportService.GetShiftReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftId`:
   */
  getShiftReport(params: ShiftReportService.GetShiftReportParams): __Observable<ShiftReport> {
    return this.getShiftReportResponse(params).pipe(__map((_r) => _r.body as ShiftReport));
  }

  /**
   * @param params The `ShiftReportService.GetOpenTasksParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftId`:
   */
  getOpenTasksResponse(params: ShiftReportService.GetOpenTasksParams): __Observable<__StrictHttpResponse<Array<OpenTaskItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/ShiftReport/${encodeURIComponent(String(params.workCenterId))}/OpenTasks/${encodeURIComponent(String(params.shiftId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OpenTaskItem>>;
      })
    );
  }
  /**
   * @param params The `ShiftReportService.GetOpenTasksParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftId`:
   */
  getOpenTasks(params: ShiftReportService.GetOpenTasksParams): __Observable<Array<OpenTaskItem>> {
    return this.getOpenTasksResponse(params).pipe(__map((_r) => _r.body as Array<OpenTaskItem>));
  }

  /**
   * @param params The `ShiftReportService.GetHeaderShiftKpisParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftId`:
   */
  getHeaderShiftKpisResponse(params: ShiftReportService.GetHeaderShiftKpisParams): __Observable<__StrictHttpResponse<Array<KpiValue>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/ShiftReport/${encodeURIComponent(String(params.workCenterId))}/GetHeaderShiftKpis/${encodeURIComponent(
          String(params.shiftId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<KpiValue>>;
      })
    );
  }
  /**
   * @param params The `ShiftReportService.GetHeaderShiftKpisParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftId`:
   */
  getHeaderShiftKpis(params: ShiftReportService.GetHeaderShiftKpisParams): __Observable<Array<KpiValue>> {
    return this.getHeaderShiftKpisResponse(params).pipe(__map((_r) => _r.body as Array<KpiValue>));
  }

  /**
   * @param params The `ShiftReportService.GetProductionOrderReportForShiftParams` containing the following parameters:
   *
   * - `shiftId`:
   *
   * - `productionOrderId`:
   */
  getProductionOrderReportForShiftResponse(
    params: ShiftReportService.GetProductionOrderReportForShiftParams
  ): __Observable<__StrictHttpResponse<ShiftReportItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/ShiftReport/${encodeURIComponent(String(params.productionOrderId))}/GetProductionOrderReportForShift/${encodeURIComponent(
          String(params.shiftId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShiftReportItem>;
      })
    );
  }
  /**
   * @param params The `ShiftReportService.GetProductionOrderReportForShiftParams` containing the following parameters:
   *
   * - `shiftId`:
   *
   * - `productionOrderId`:
   */
  getProductionOrderReportForShift(params: ShiftReportService.GetProductionOrderReportForShiftParams): __Observable<ShiftReportItem> {
    return this.getProductionOrderReportForShiftResponse(params).pipe(__map((_r) => _r.body as ShiftReportItem));
  }
}

module ShiftReportService {
  /**
   * Parameters for SendShiftReport
   */
  export interface SendShiftReportParams {
    workCenterId: number;
    entry: SendShiftReportEntry;
  }

  /**
   * Parameters for CancelSendShiftReport
   */
  export interface CancelSendShiftReportParams {
    workCenterId: number;
    entry: CancelSendShiftReportEntry;
  }

  /**
   * Parameters for GetShiftReport
   */
  export interface GetShiftReportParams {
    workCenterId: number;
    shiftId: number;
  }

  /**
   * Parameters for GetOpenTasks
   */
  export interface GetOpenTasksParams {
    workCenterId: number;
    shiftId: number;
  }

  /**
   * Parameters for GetHeaderShiftKpis
   */
  export interface GetHeaderShiftKpisParams {
    workCenterId: number;
    shiftId: number;
  }

  /**
   * Parameters for GetProductionOrderReportForShift
   */
  export interface GetProductionOrderReportForShiftParams {
    shiftId: number;
    productionOrderId: number;
  }
}

export { ShiftReportService };
