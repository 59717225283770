import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Dummy, ListValue } from '../../../models';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'lib-creating-dummy-form',
  templateUrl: './creating-dummy-form.component.html',
  styleUrls: ['./creating-dummy-form.component.scss']
})
export class CreatingDummyFormComponent implements OnInit, OnChanges {
  @Input() public reasonsOptions: ListValue[];

  @Input() public dummy: Dummy;
  @Input() public showQuantity = true;
  @Input() public inputPlaceholder = '';
  @Input() public mountedLabel = '';

  @Input() public reasonsLabel = '';
  @Input() public reasonsDefaultValue = '';

  @Input() public infoLabel = '';
  @Input() public updateButtonLabel = '';
  @Input() public mountButtonLabel = '';
  @Input() public loadingTopic = '';

  @Input() public articleOptions?: ListValue[];
  @Input() public articleLabel = '';
  @Input() public articleDefaultValue = '';
  @Input() public askForProductionOrder = false;
  @Input() public productionOrderCodeLabel = '';

  @Output() public createNewDummyEvent = new EventEmitter<Dummy>();
  @Output() public updateDummyInfoEvent = new EventEmitter<Dummy>();
  @Output() public dummySsccScanEvent = new EventEmitter<string>();

  public readonly INPUT_STYLE = { width: '200px' };
  public dummyForm: UntypedFormGroup;
  public updateInfoOnly: boolean;
  public submitButtonLabel: string;
  private productionOrderValidationFlag: boolean;
  constructor(private fb: UntypedFormBuilder, private ref: DynamicDialogRef) {}

  public ngOnInit(): void {
    this.updateInfoOnly = !!this.dummy.containerId;
    this.submitButtonLabel = this.updateInfoOnly ? this.updateButtonLabel : this.mountButtonLabel;
    this.initModalForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dummy && this.dummyForm) {
      this.dummyForm.controls.mounted.setValue(this.dummy.mounted);
      this.dummyForm.controls.reasonInfo.setValue(this.dummy.reasonInfo);
    }
  }

  public get palletCode() {
    return this.dummyForm.get('code');
  }

  public get mounted() {
    return this.dummyForm.get('mounted');
  }

  public get reasons() {
    return this.dummyForm.get('reasonId');
  }

  public get reasonInfo() {
    return this.dummyForm.get('reasonInfo');
  }

  public get articleInfo() {
    return this.dummyForm.get('articleId');
  }

  public get productionOrderString() {
    return this.dummyForm.get('productionOrderString');
  }

  public onSubmitModal(): void {
    if (this.updateInfoOnly) {
      this.updateVirtualContainerReasonInfo();
    } else {
      this.addNewVirtualInputContainer();
    }
  }

  private addNewVirtualInputContainer(): void {
    this.createNewDummyEvent.emit(this.getMappedDummy());
  }

  private updateVirtualContainerReasonInfo(): void {
    this.updateDummyInfoEvent.emit(this.getMappedDummy());
  }

  private initModalForm(): void {
    const mountedValidators: ValidatorFn[] = [Validators.required];
    const codeModel = { value: this.dummy.code || '', disabled: this.updateInfoOnly };
    const mountedModel = { value: this.dummy.mounted || '', disabled: this.updateInfoOnly };
    const reasonModel = { value: this.dummy.reasonId, disabled: this.updateInfoOnly };
    const articleModel = { value: this.dummy.articleId, disabled: this.updateInfoOnly };
    const productionOrderStringModel = { value: this.dummy.productionOrderString, disabled: this.updateInfoOnly };

    if (!this.updateInfoOnly) {
      mountedValidators.push(Validators.min(this.dummy.lowestMountingQuantity));
      mountedValidators.push(Validators.max(this.dummy.highestMountingQuantity));
    }

    this.dummyForm = this.fb.group({
      code: [codeModel, Validators.required],
      mounted: [mountedModel, mountedValidators],
      reasonId: [reasonModel, Validators.required],
      reasonInfo: [this.dummy.reasonInfo],
      articleId: [articleModel]
    });

    if (this.askForProductionOrder) {
      this.dummyForm.addControl('productionOrderString', this.fb.control(productionOrderStringModel, [Validators.required]));
    }
  }

  public changeArticle(articleId): void {
    this.dummyForm.value.articleId = articleId;
  }

  private getMappedDummy(): Dummy {
    if (this.articleOptions?.length > 0) {
      this.dummy.articleId = this.dummyForm.value.articleId;
    }

    return {
      ...this.dummy,
      ...this.dummyForm.value
    };
  }

  public preventLineBreak(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onCloseModal(): void {
    this.ref.close(false);
  }

  public checkSsccCode(): void {
    this.dummySsccScanEvent.emit(this.dummyForm.controls.code.value);
  }
}
