import { Observable } from 'rxjs';
import { MountedMaterialForwardSearch, TraceabilityService } from 'chronos-core-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TraceabilityDsService {
  constructor(private traceabilityService: TraceabilityService) {}

  public getMountedMaterialsForForwardSearch(
    startDate: string,
    searchText: null | string,
    endDate: string
  ): Observable<MountedMaterialForwardSearch[]> {
    const params: TraceabilityService.GetMountedMaterialsForForwardSearchParams = {
      startDate,
      searchText,
      endDate
    };
    return this.traceabilityService.getMountedMaterialsForForwardSearch(params);
  }

  public getProductionOrdersForBackwardSearch(startDate: string, searchText: null | string, endDate: string) {
    const params: TraceabilityService.GetProductionOrdersForBackwardSearchParams = {
      startDate,
      searchText,
      endDate
    };
    return this.traceabilityService.getProductionOrdersForBackwardSearch(params);
  }

  public getMountedMaterialTraceForForwardSearch(productionOrderId: number, viewMode: string) {
    const params: TraceabilityService.GetMountedMaterialTraceForForwardSearchParams = {
      productionOrderId,
      viewMode
    };
    return this.traceabilityService.getMountedMaterialTraceForForwardSearch(params);
  }

  public getProductionOrderTraceForBackwardSearch(productionOrderId: number, viewMode: string) {
    const params: TraceabilityService.GetProductionOrderTraceForBackwardSearchParams = {
      productionOrderId,
      viewMode
    };
    return this.traceabilityService.getProductionOrderTraceForBackwardSearch(params);
  }
}
