<ng-container *ngIf="badgeNumber > 0; else noBadgeTemplate">
  <div class="task-item task-item--attention">
    <span class="task-item__badge">{{ badgeNumber | number }}</span>
    <span class="task-item__title">{{ title }}</span>
    <span class="task-item__value">{{ value }}</span>
    <span class="task-item__check-icon"></span>
  </div>
</ng-container>

<ng-template #noBadgeTemplate>
  <div class="task-item">
    <span class="task-item__no-badge"></span>
    <span class="task-item__title">{{ title }}</span>
    <span class="task-item__value">{{ value }}</span>
    <i class="icon-checked-solid task-item__check-icon"></i>
  </div>
</ng-template>
