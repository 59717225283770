import { ApproveCommandCallback, SetupFooterCommandBase } from '@app/shared/components';
import { ApproveService } from '@app/modules/approve/services';

export class SendApproveReportCommand implements SetupFooterCommandBase {
  public enabled = false;
  public buttonText: string;
  public required = false;

  constructor(private approveService: ApproveService) {}

  public commandCallback: ApproveCommandCallback;
  public execute(): void {
    this.approveService.confirmApprovalReport(false, this.commandCallback);
  }
}
