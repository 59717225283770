import { knownUnits } from '../../models/knownUnits.model';

export class CounterHelper {
  public static GetCounterCoefficient(unitId: string): number {
    if (knownUnits.WholeUnits.includes(unitId)) {
      return 1;
    } else {
      return 100;
    }
  }
}
