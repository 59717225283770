import { Component, OnInit } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';
import { ChartType } from 'chronos-shared';

@Component({
  selector: 'app-machine-chart',
  templateUrl: './machine-chart.component.html',
  styleUrls: ['./machine-chart.component.scss']
})
export class MachineChartComponent implements OnInit {
  public workCenterId$: Observable<number>;
  public chartMode: string;
  constructor(private activeWorkCenterService: ActiveWorkCenterService) {}

  public ngOnInit(): void {
    this.workCenterId$ = this.activeWorkCenterService.workCenterId$;
    this.chartMode = ChartType.fullView;
  }
}
