<p-fileUpload
  [chooseLabel]="buttonLabel"
  [url]="url"
  [accept]="acceptedFileType"
  [maxFileSize]="maxFileSize"
  [auto]="autoUpload"
  [ngClass]="['input']"
  [showCancelButton]="showCancelButton"
  [showUploadButton]="showUploadButton"
  [mode]="mode"
  [multiple]="false"
  (onRemove)="onFileRemove($event)"
  (onSelect)="onFileSelect($event)"
></p-fileUpload>
