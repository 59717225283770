import { Component, Input } from '@angular/core';
import { NonConformanceModel } from 'chronos-core-client';

@Component({
  selector: 'app-nc-measure',
  templateUrl: './nc-measure.component.html',
  styleUrls: ['./nc-measure.component.scss']
})
export class NcMeasureComponent {
  @Input() public nonConformanceModel: NonConformanceModel;

  @Input() public isCreateNCMode: boolean;
}
