import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PrimaryMaterialMounted } from 'chronos-core-client';

export type RecentlyConsumedMaterialState = EntityState<PrimaryMaterialMounted>;

@Injectable()
@StoreConfig({ name: 'recently-consumed-material', idKey: 'primaryMaterialId', resettable: true })
export class RecentlyConsumedMaterialStore extends EntityStore<RecentlyConsumedMaterialState, PrimaryMaterialMounted> {
  constructor() {
    super();
  }
}
