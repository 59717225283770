import { Injectable } from '@angular/core';
import { ToolDsService } from '@app/core/data-services';
import { Observable } from 'rxjs';
import { MaterialAvailableAtLocation } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ToolStockModalService {
  constructor(private toolDsService: ToolDsService) {}

  public mountToolForActiveOrder(identificationCode: string, containerId: number, materialBlockId: number): Observable<null> {
    return this.toolDsService.mountToolForActiveOrder(identificationCode, containerId, materialBlockId);
  }

  public replaceVirtualTool(mountedMaterialId: number, virtualContainerId: number, newContainerId: number): Observable<null> {
    return this.toolDsService.replaceVirtualTool(mountedMaterialId, virtualContainerId, newContainerId);
  }

  public getAvailableSecondaryMaterials(): Observable<MaterialAvailableAtLocation> {
    return this.toolDsService.getAvailableForAtLocation();
  }

  public getAvailableSecondaryMaterialsWithAlternate(articleId): Observable<MaterialAvailableAtLocation> {
    return this.toolDsService.getAvailableForAtLocationForArtice(articleId);
  }
}
