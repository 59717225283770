<ng-container *ngIf="pallet; then palletQaCheck; else periodicQaCheck"></ng-container>

<ng-template #palletQaCheck>
  <app-qa-check-list [fields]="fields" [form]="form" [materialId]="pallet.producedMaterialId"></app-qa-check-list>
</ng-template>

<ng-template #periodicQaCheck>
  <app-qa-check-list
    [fields]="fields"
    [form]="form"
    [isManualPeriodicQaCheck]="isManualPeriodicQaCheck"
    [isAutomaticPeriodicQaCheck]="isAutomaticPeriodicQaCheck"
    [periodicQaCheckId]="periodicQaCheckId"
  ></app-qa-check-list>
</ng-template>
