import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveOrderQuery } from '@app/core/global-state';
import { PrimaryMountedMaterialQuery, PrimaryMountedMaterialService, RecentlyConsumedMaterialService } from '@app/modules/mounting/state';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsQuery, AppSettingsService, LogService } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { TabPrimaryService } from '@app/modules/mounting/services';
import { PrimaryMaterialConsumptionModalComponent } from './components/primary-material-consumption-modal/primary-material-consumption-modal.component';
import { filter, map, tap } from 'rxjs/operators';
import moment from 'moment-mini';
import { TimeLeftIcon, WorkCenter } from 'chronos-core-client';
import { MaterialKpiView } from '@app/modules/mounting/models';
import { StatusPanelTextStyle, StatusPanelType } from '@app/shared/components';
import { ModalMainMaterialComponent } from '@app/shared/components/modal-main-material/modal-main-material.component';
import { ActiveWorkCenterService, WorkCenterDsService } from '@app/core/workcenter';

@Component({
  selector: 'app-tab-primary',
  templateUrl: './tab-primary.component.html',
  styleUrls: ['./tab-primary.component.scss']
})
export class TabPrimaryComponent implements OnInit, OnDestroy {
  private scanValidationSubject = new Subject<boolean>();
  public scanValidationChanges$: Observable<boolean> = this.scanValidationSubject.asObservable();
  public kpiMountedView$: Observable<MaterialKpiView>;
  public showInactive: boolean;
  public workCenter: WorkCenter;

  private subscriptions = new Subscription();

  constructor(
    private dialogService: DialogService,
    private primaryMountedMaterialService: PrimaryMountedMaterialService,
    public primaryMountedMaterialQuery: PrimaryMountedMaterialQuery,
    private tabPrimaryService: TabPrimaryService,
    private consumedMaterialService: RecentlyConsumedMaterialService,
    public appSettingsQuery: AppSettingsQuery,
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
    private activeOrderQuery: ActiveOrderQuery,
    private workCenterDsService: WorkCenterDsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public ngOnInit(): void {
    this.showInactive = this.appSettingsQuery.showInactiveElement();
    this.primaryMountedMaterialService.getPrimaryMountedMaterial();
    this.subscriptions.add(this.tabPrimaryService.getPrimaryMounterMaterialNotifications().subscribe());
    this.subscriptions.add(this.tabPrimaryService.getConsumedMaterialNotifications().subscribe());
    this.subscriptions.add(
      this.workCenterDsService
        .getWorkCenter(this.activeWorkCenterService.getWorkCenterId())
        .pipe(
          tap((data) => {
            this.workCenter = data;
            this.appSettingsService.setWorkCenter(data);
          })
        )
        .subscribe()
    );
    this.kpiMountedView$ = this.primaryMountedMaterialQuery.mountedMaterialKpis$.pipe(
      filter((primaryMaterial) => !!primaryMaterial),
      map((primaryMaterial) => ({
        material: primaryMaterial,
        mountedWarningStatus: primaryMaterial.mounted.quantity.value < 0 ? 'negative' : null,
        timeLeftTextStyle: this.getTextStyleBasedOnDays(primaryMaterial.timeLeft),
        timeLeftWarningStatus: this.getTimeLeftWarningType(primaryMaterial.timeLeftIcon)
      }))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.consumedMaterialService.resetStore();
  }

  public openMachineLocationModal(): void {
    const tabName = 'Primary';
    const productionOrderId = this.activeOrderQuery.getActiveOrderId();
    const submitLabel = 'MOUNTING.MOUNT';
    this.dialogService.open(ModalMainMaterialComponent, {
      header: this.translateService.instant('MOUNTING.PRIMARY_MACHINE_LOCATION_MODAL', {
        externalWorkcenterId: this.workCenter.externalWorkCenterId,
        externalWarehouseId: this.workCenter.inputExternalWarehouseId,
        externalWarehouseLocationId: this.workCenter.inputExternalWarehouseLocationId,
        workCenter: this.appSettingsQuery.getWorkCenterName()
      }),
      data: {
        productionOrderId,
        submitLabel,
        tabName
      }
    });
  }

  public openWarehouseLocationModal(): void {
    const tabName = 'Primary';
    this.dialogService.open(ModalMainMaterialComponent, {
      header: this.translateService.instant('MOUNTING.WAREHOUSE'),
      data: {
        asWarehouse: true,
        tabName
      }
    });
  }

  public openConsumptionModal(): void {
    this.dialogService.open(PrimaryMaterialConsumptionModalComponent, {
      header: this.translateService.instant('MOUNTING.CONSUMPTION')
    });
  }

  public mountContainer(sscc: string): void {
    const productionOrderId = this.activeOrderQuery.getActiveOrderId();
    this.primaryMountedMaterialService.mountContainerBySscc(sscc, productionOrderId).subscribe(
      () => {
        LogService.success('SUCCESS_MESSAGE.MATERIAL_MOUNTED');
        this.scanValidationSubject.next(true);
      },
      () => {
        this.scanValidationSubject.next(false);
      }
    );
  }

  private getTextStyleBasedOnDays(timeSpan: string): StatusPanelTextStyle {
    return moment.duration(timeSpan).days() > 0 ? 'small' : 'large';
  }

  private getTimeLeftWarningType(timeLeftWarning: TimeLeftIcon): StatusPanelType {
    switch (timeLeftWarning) {
      case TimeLeftIcon.WARNING:
        return 'warning';
      case TimeLeftIcon.ERROR:
        return 'negative';
      default:
        return null;
    }
  }
}
