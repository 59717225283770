<section class="setup-info">
  <h2 class="setup-info__title">{{ 'SETUP_INFO.TITLE' | translate }}</h2>

  <div class="p-grid">
    <div class="p-col-4">
      <div class="document">
        <i class="icon-pdf-outline document__icon"></i>
        <span class="document__title">Infodoc-1.pdf</span>
      </div>
    </div>

    <div class="p-col-4">
      <div class="document">
        <i class="icon-pdf-outline document__icon"></i>
        <span class="document__title">Infodoc-1.pdf</span>
      </div>
    </div>

    <div class="p-col-4">
      <div class="document">
        <i class="icon-pdf-outline document__icon"></i>
        <span class="document__title">Infodoc-1.pdf</span>
      </div>
    </div>

    <div class="p-col-4">
      <div class="document">
        <i class="icon-pdf-outline document__icon"></i>
        <span class="document__title">Document.txt</span>
      </div>
    </div>

    <div class="p-col-4">
      <div class="document">
        <i class="icon-pdf-outline document__icon"></i>
        <span class="document__title">Document.txt</span>
      </div>
    </div>

    <div class="p-col-4">
      <div class="document">
        <i class="icon-pdf-outline document__icon"></i>
        <span class="document__title">Document.txt</span>
      </div>
    </div>
  </div>
</section>
