/* tslint:disable */
type QaCheckProcessingType = 'Manual' | 'Automatic';
module QaCheckProcessingType {
  export const MANUAL: QaCheckProcessingType = 'Manual';
  export const AUTOMATIC: QaCheckProcessingType = 'Automatic';
  export function values(): QaCheckProcessingType[] {
    return [MANUAL, AUTOMATIC];
  }
}

export { QaCheckProcessingType };
