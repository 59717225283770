// TODO: OR maybe Renaming to notificationTopic Discussion for Constants to be ALL UPPER
export const notificationTopic = {
  modalProductionLoyalty: 'modalProductionLoyalty',
  modalProductionPeriods: 'modalProductionPeriods',
  modalMachineLocation: 'modalMachineLocation',
  modalWarehouseStock: 'modalWarehouseStock',
  modalDatePicker: 'modalDatePicker',
  modalToolStock: 'modalToolStock',
  modalTraceAssign: 'modalTraceAssign',
  modalExistingTraceAssign: 'modalExistingTraceAssign',
  modalDismountContainer: 'modalDismountContainer',
  kpiConsumptionPrimaryDismount: 'kpiConsumptionPrimaryDismount',
  kpiConsumptionSecondaryDismount: 'kpiConsumptionSecondaryDismount',
  kpiDurationExecute: 'kpiDurationExecute',
  modalCreateDummy: 'modalCreateDummy',
  modalPrintLabel: 'modalPrintLabel',
  modalCreatePallet: 'modalCreatePallet',
  modalReduceConsumption: 'modalReduceConsumption',
  orderItemPrimaryButton: 'orderItemPrimaryButton',
  modalChangeOutputQuantity: 'modalChangeOutputQuantity',
  modalForManualMode: 'modalForManualMode',
  outputContainerAction: 'outputContainerAction',
  outputUnitAction: 'outputUnitAction',
  modalApproveChangeQuantity: 'modalApproveChangeQuantity',
  printLabelAction: 'printLabelAction',
  outerPrintLabelAction: 'outerPrintLabelAction',
  outerPreprintLabelAction: 'outerPreprintLabelAction',
  quantityPoolAction: 'quantityPoolAction',
  wasteAssignmentAddAction: 'wasteAssignmentAddAction',
  approveMaterialModalAction: 'approveMaterialModalAction',
  footerNextSetupPhase: 'footerNextSetupPhase',
  footerFinishSetupPhase: 'footerFinishSetupPhase',
  footerCancelSetupPhase: 'footerCancelSetupPhase',
  footerNextFinishPhase: 'footerNextFinishPhase',
  footerFinishOrderPhase: 'footerFinishOrderPhase',
  footerShutdownAction: 'footerShutdownAction',
  modalShutdownExit: 'modalShutdownExit',
  modalAssignDowntime: 'modalAssignDowntime',
  modalEditSetup: 'modalEditSetup',
  modalSendShiftReport: 'modalSendShiftReport',
  convertToProductionAction: 'convertToProductionAction',
  unassignTraceAction: 'unassignTraceAction',
  modalCancelProductionOrder: 'modalCancelProductionOrder',
  importResourceSetting: 'importResourceSetting',
  updateResourceSettings: 'updateResourceSettings',
  editDowntime: 'editDowntime',
  cancelFinishPhase: 'cancelFinishPhase',
  suggestQuantity: 'suggestQuantity'
};
