import { Component, forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCheckComponent),
  multi: true
};

@Component({
  selector: 'lib-input-check',
  templateUrl: './input-check.component.html',
  styleUrls: ['./input-check.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class InputCheckComponent implements ControlValueAccessor {
  @Input() public loadingTopic = '';
  @Input() public label = '';

  public loading = false;
  public checked = false;
  public disabled = false;

  private propagateChanged: (isChecked: boolean) => void;
  private propagateTouched: () => void;

  public writeValue(checked: boolean): void {
    this.checked = checked;
  }

  public registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  public onCheckChange(): void {
    if (this.loading || this.disabled) {
      return;
    }

    if (this.propagateChanged) {
      this.propagateChanged(this.checked);
    }
  }
}
