<label class="quantity">
  <span class="quantity__label">{{ goodQuantitLabel }}</span>
  <lib-input-number
    [ngModel]="goodQuantity.value"
    [size]="5"
    [format]="true"
    [step]="1"
    [min]="0"
    [max]="maxQuantity"
    [required]="required"
    [inputId]="'goodQuantity'"
    (ngModelChange)="onQuantityChange($event)"
    [inputStyle]="INPUT_STYLE"
  ></lib-input-number>
  <span class="quanity-unit-span">{{ goodQuantity.unitId }}</span>
</label>
<label class="quantity">
  <span class="quantity__label">{{ wasteLabel }}</span>
  <lib-input-number
    [ngModel]="waste.value"
    [size]="5"
    [format]="true"
    [step]="1"
    [min]="0"
    [max]="maxQuantity - maculature.value"
    [required]="required"
    [inputId]="'waste'"
    (ngModelChange)="onWasteChange($event)"
    [inputStyle]="INPUT_STYLE"
    [maxFractionDigits]="maxFractionDigits"
    [minFractionDigits]="minFractionDigits"
  ></lib-input-number>
  <span class="quanity-unit-span">{{ waste.unitId }}</span>
</label>
<label class="quanity">
  <span class="quantity__label">{{ maculatureLabel }}</span>
  <lib-input-number
    [ngModel]="maculature.value"
    [step]="1"
    [size]="5"
    [format]="true"
    [min]="0"
    [max]="maxQuantity - waste.value"
    [required]="required"
    [inputId]="'maculature'"
    (ngModelChange)="onMaculatureChange($event)"
    [inputStyle]="INPUT_STYLE"
    [maxFractionDigits]="maxFractionDigits"
    [minFractionDigits]="minFractionDigits"
  ></lib-input-number>
  <span class="quanity-unit-span">{{ maculature.unitId }}</span>
</label>
