/* tslint:disable */
type MachineState = 'Unknown' | 'Idle' | 'Setup' | 'Run' | 'Downtime';
module MachineState {
  export const UNKNOWN: MachineState = 'Unknown';
  export const IDLE: MachineState = 'Idle';
  export const SETUP: MachineState = 'Setup';
  export const RUN: MachineState = 'Run';
  export const DOWNTIME: MachineState = 'Downtime';
  export function values(): MachineState[] {
    return [UNKNOWN, IDLE, SETUP, RUN, DOWNTIME];
  }
}

export { MachineState };
