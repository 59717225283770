import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PhaseNavigationComponent } from './phase-navigation/phase-navigation.component';
import { PhaseNavigationItemComponent } from './phase-navigation-item/phase-navigation-item.component';
import { SubPhaseNavigationItemComponent } from './sub-phase-navigation-item/sub-phase-navigation-item.component';

@NgModule({
  declarations: [PhaseNavigationComponent, PhaseNavigationItemComponent, SubPhaseNavigationItemComponent],
  exports: [PhaseNavigationComponent, PhaseNavigationItemComponent, SubPhaseNavigationItemComponent],
  imports: [CommonModule, RouterModule]
})
export class PhaseNavigationModule {}
