import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-dismount-button',
  templateUrl: './dismount-button.component.html',
  styleUrls: ['./dismount-button.component.scss']
})
export class DismountButtonComponent {
  @Input() public dismountableCount = 0;
  @Input() public disabled = false;
  @Input() public loadingTopic = '';

  @Output() public buttonClicked = new EventEmitter<null>();

  public loading = false;

  public onClick(): void {
    if (this.disabled || this.loading) {
      return;
    }

    this.buttonClicked.emit();
  }
}
