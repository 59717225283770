/* tslint:disable */
type ManualMachineMode = 'None' | 'ManualWorkingPlace' | 'Cutting' | 'Packing' | 'Sorting';
module ManualMachineMode {
  export const NONE: ManualMachineMode = 'None';
  export const MANUAL_WORKING_PLACE: ManualMachineMode = 'ManualWorkingPlace';
  export const CUTTING: ManualMachineMode = 'Cutting';
  export const PACKING: ManualMachineMode = 'Packing';
  export const SORTING: ManualMachineMode = 'Sorting';
  export function values(): ManualMachineMode[] {
    return [NONE, MANUAL_WORKING_PLACE, CUTTING, PACKING, SORTING];
  }
}

export { ManualMachineMode };
