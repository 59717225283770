<div class="work-center-selection">
  <h1>{{ 'WORK_CENTER_SELECTION.SELECT_WORK_CENTER' | translate }}</h1>
  <lib-dropdown
    [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
    [options]="workCenterOptions$ | async"
    [(ngModel)]="currentWorkCenter"
    (ngModelChange)="onWorkCenterChange($event)"
    [loading]="loading"
    [panelWidth]="'300px'"
  ></lib-dropdown>
</div>
