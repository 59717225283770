<div class="user">
  <ng-container *ngIf="isEmployeeRegistrationEnabled; else nonEmployeeRegistration">
    <div class="user__menu-link" (click)="onUserClick()">
      <span class="icon icon-user"></span>
      <ng-container *ngIf="employeeName?.name; else noActiveUser">
        <span class="user__text">{{ employeeName.name }}</span>
      </ng-container>
      <ng-template #noActiveUser>{{ 'USER.NO_ACTIVE_USER' | translate }}</ng-template>
    </div>
  </ng-container>
  <ng-template #nonEmployeeRegistration>
    <a (click)="accountMenu.toggle($event)" class="user__menu-link">
      <span class="icon icon-user"></span>
      <span class="user__text">{{ menuName$ | async }}</span>
      <span class="icon icon-arrow-select"></span>
    </a>
  </ng-template>
  <p-menu #accountMenu [popup]="true" [model]="userMenuItems$ | async" appendTo="body"></p-menu>
  <div class="user__additional-content">
    <ng-content></ng-content>
  </div>
</div>
