import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ShiftReportOpenTasksState, ShiftReportOpenTasksStore } from './shift-report-open-tasks.store';
import { ShiftReportOpenTask } from '@app/core/global-state';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportOpenTasksQuery extends QueryEntity<ShiftReportOpenTasksState, ShiftReportOpenTask> {
  constructor(protected store: ShiftReportOpenTasksStore) {
    super(store);
  }

  public shiftReportOpenTasks$ = this.selectAll();
}
