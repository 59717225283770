export const returnUrlType = 'returnUrl';

export const queryParameterNames = {
  ReturnUrl: returnUrlType,
  Message: 'message'
};

export const logoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export const loginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed'
};

interface ApplicationPathsType {
  readonly DefaultLoginRedirectPath: string;
  readonly Login: string;
  readonly LoginFailed: string;
  readonly LoginCallback: string;
  readonly LogOut: string;
  readonly LoggedOut: string;
  readonly LogOutCallback: string;
  readonly LoginPathComponents: string[];
  readonly LoginFailedPathComponents: string[];
  readonly LoginCallbackPathComponents: string[];
  readonly LogOutPathComponents: string[];
  readonly LoggedOutPathComponents: string[];
  readonly LogOutCallbackPathComponents: string[];
}

export const applicationPaths: ApplicationPathsType = {
  DefaultLoginRedirectPath: '/',
  Login: `authentication/${loginActions.Login}`,
  LoginFailed: `authentication/${loginActions.LoginFailed}`,
  LoginCallback: `authentication/${loginActions.LoginCallback}`,
  LogOut: `authentication/${logoutActions.Logout}`,
  LoggedOut: `authentication/${logoutActions.LoggedOut}`,
  LogOutCallback: `authentication/${logoutActions.LogoutCallback}`,
  get LoginPathComponents() {
    return this.Login.split('/');
  },
  get LoginFailedPathComponents() {
    return this.LoginFailed.split('/');
  },
  get LoginCallbackPathComponents() {
    return this.LoginCallback.split('/');
  },
  get LogOutPathComponents() {
    return this.LogOut.split('/');
  },
  get LoggedOutPathComponents() {
    return this.LoggedOut.split('/');
  },
  get LogOutCallbackPathComponents() {
    return this.LogOutCallback.split('/');
  }
};
