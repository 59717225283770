<div *ngIf="isCapsLockOn">
  <p class="scan-container-warning">{{ capsLockWarningMessage }}</p>
</div>
<div class="scan-container">
  <div>
    <i class="icon-mounting icon"></i>
  </div>
  <input
    #ssccInput
    type="text"
    class="scan-container__input"
    [placeholder]="scanPlaceholderText"
    [(ngModel)]="sscc"
    [ngClass]="{ 'scan-container__input--invalid': !isValid }"
    (ngModelChange)="onSsccChange()"
    (blur)="onSsccContainerBlur()"
    pInputText
  />
  <button [label]="buttonLabel" class="scan-container__button" (click)="onMountButtonClick()" pButton></button>
</div>
