import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({ providedIn: 'root' })
export class HubUrlsService {
  constructor(private activeWorkCenterService: ActiveWorkCenterService) {}

  public getCounterValue = () => `/WorkCenterCounter/${this.activeWorkCenterService.getWorkCenterId()}`;
  public getProducedMaterialList = (activeOrderId) => `/ProducedContainerList/${activeOrderId}`;
  public getTaskList = () => `/WorkCenterTaskList/${this.activeWorkCenterService.getWorkCenterId()}`;
  public getTaskCountByCategory = (workCenterId) => `/WorkCenterTaskListByCategory/${workCenterId}`;
  public getHeaderKpi = (activeOrderId) => `/HeaderKpi/${activeOrderId}`;
  public getQuantityPool = () => `/QuantityPool/${this.activeWorkCenterService.getWorkCenterId()}`;
  public getInputPallets = () => `/InputContainerList/${this.activeWorkCenterService.getWorkCenterId()}`;
  public getPrimaryMountedMaterial = () => `/PrimaryMountedMaterial/${this.activeWorkCenterService.getWorkCenterId()}`;
  public getConsumedMaterial = () => `/MountedMaterialConsumed/${this.activeWorkCenterService.getWorkCenterId()}`;
  public getProductionOrderKpiList = (activeOrderId) => `/ProductionOrderKpiList/${activeOrderId}`;
  public getProductionOrdersetupInfo = (productionOrderId) => `/ProductionOrderSetupState/${productionOrderId}`;
  public getState = () => `/WorkCenterState/${this.activeWorkCenterService.getWorkCenterId()}`;
  public getActiveProductionOrder = () => `/ActiveProductionOrder/${this.activeWorkCenterService.getWorkCenterId()}`;
  public getActiveOrderKpis = (productionOrderId, shiftId) => `/ActiveProductionOrderShiftKpiList/${productionOrderId}/${shiftId}`;
  public getShiftReportHeaderKpis = (shiftId) => `/ShiftKpiList/${this.activeWorkCenterService.getWorkCenterId()}/${shiftId}`;
  public getShiftReportOpenTasks = (shiftId) => `/ShiftTaskListByCategory/${this.activeWorkCenterService.getWorkCenterId()}/${shiftId}`;
  public getOutputUnit = (producedMaterialId) => `/Outer/${producedMaterialId}`;
  public getApprovalReport = (workCenterId, approvalReportDate) => `/ApprovalReport/${workCenterId}/${approvalReportDate}`;
  public getAutomaticQACheckForProductionOrder = (productionOrderId) => `/AutomaticQaCheckForProductionOrder/${productionOrderId}`;
  public getWorkCenterLoginChanged = (workCenterId) => `/WorkCenterLoginChanged/${workCenterId}`;
  public getEmployeeRegistrationDetails = (workCenterId) => `/WorkCenterEmployeeRegistrationChanged/${workCenterId}`;
  public getEmployeeRegistrationHistory = (workCenterId) => `/ActiveEmployeeRegistrationData/${workCenterId}`;
}
