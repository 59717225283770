/* tslint:disable */
type LabelPrintMode = 'None' | 'PrePrint' | 'Print' | 'RePrint';
module LabelPrintMode {
  export const NONE: LabelPrintMode = 'None';
  export const PRE_PRINT: LabelPrintMode = 'PrePrint';
  export const PRINT: LabelPrintMode = 'Print';
  export const RE_PRINT: LabelPrintMode = 'RePrint';
  export function values(): LabelPrintMode[] {
    return [NONE, PRE_PRINT, PRINT, RE_PRINT];
  }
}

export { LabelPrintMode };
