/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingNotificationService {
  private static notificationSubject = new Subject<[string, boolean]>();
  private static _notifications$: Observable<any>;

  public static get notifications$(): Observable<any> {
    if (!this._notifications$) {
      this._notifications$ = this.notificationSubject.asObservable();
    }
    return this._notifications$;
  }

  public static publish(topic: string, loading: boolean): void {
    if (topic) {
      this.notificationSubject.next([topic, loading]);
    }
  }

  public getNotifications(topic: string): Observable<boolean> {
    return LoadingNotificationService.notifications$.pipe(
      filter(([notificationTopic, notification]: [string, boolean]) => notificationTopic === topic),
      map(([notificationTopic, notification]) => notification)
    );
  }
}
