import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { DismountingDetailsComponent } from './dismounting-details/dismounting-details.component';
import { ContainerComponentsModule } from '../container-components/container-components.module';
import { DismountContainerFormComponent } from '../dismounting/dismount-container-form/dismount-container-form.component';
import { ContainerPalletModule } from '../container-pallet';
import { FormComponentsModule } from '../form-components';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [DismountContainerFormComponent, DismountingDetailsComponent],
  exports: [DismountContainerFormComponent, DismountingDetailsComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ContainerComponentsModule,
    ReactiveFormsModule,
    CheckboxModule,
    PipesModule,
    FormComponentsModule,
    ContainerPalletModule
  ]
})
export class DismountingModule {}
