import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { MountPalletListService } from '@app/modules/mounting/services/mount-pallet-list/mount-pallet-list.service';
import { MountingService } from '@app/modules/mounting/services/mounting/mounting.service';
import { MachineState, MountedMaterialStatus } from 'chronos-core-client';
import { MountedMaterial } from 'chronos-shared';

@Component({
  selector: 'app-mount-pallet-list',
  templateUrl: './mount-pallet-list.component.html',
  styleUrls: ['./mount-pallet-list.component.scss']
})
export class MountPalletListComponent {
  @Input() public mountedMaterials: MountedMaterial[];
  @Input() public machineState: MachineState;

  public readonly MOUNTED_MATERIAL_STATUS = MountedMaterialStatus;
  public readonly MACHINE_STATE = MachineState;

  private readonly MAX_VISIBLE_CONTAINERS = 30;
  private readonly ROWS_VISIBLE_IN_VIEW = this.MAX_VISIBLE_CONTAINERS;

  constructor(private mountingService: MountingService, private mountPalletListService: MountPalletListService) {}

  public getMountedContainersForListView(): MountedMaterial[] {
    return this.mountingService.getMountedContainers(this.ROWS_VISIBLE_IN_VIEW, this.mountedMaterials);
  }

  public trackByFunction(index: number, mountedMaterial: MountedMaterial): number {
    return mountedMaterial.mountedMaterialId;
  }

  public changeListViewContainerListSequence(event: CdkDragDrop<string[]>): void {
    this.mountedMaterials = this.mountPalletListService.changeListViewContainerListSequence(
      { currentIndex: event.currentIndex, previousIndex: event.previousIndex },
      this.ROWS_VISIBLE_IN_VIEW,
      this.mountedMaterials
    );
  }
}
