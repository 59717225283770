/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrDetailsDTO } from '../models/or-details-dto';
import { ImportMetaDataResponse } from '../models/import-meta-data-response';
import { AttributeGroupingData } from '../models/attribute-grouping-data';
import { MetamodelMe } from '../models/metamodel-me';
import { DatatypeDe } from '../models/datatype-de';
import { SystabcatSc } from '../models/systabcat-sc';
import { AttributeGroupsAg } from '../models/attribute-groups-ag';
import { MetadataDm } from '../models/metadata-dm';
@Injectable({
  providedIn: 'root'
})
class MetaModelService extends __BaseService {
  static readonly getMetaModelPath = '/api/MetaModel/{orid}';
  static readonly importMasterDataPath = '/api/MetaModel/masterdata/import';
  static readonly getAttributesGroupingDataPath = '/api/MetaModel/getAttributeGroupingData';
  static readonly getHierarchyMetaModelPath = '/api/MetaModel/getHierarchyMetaModel';
  static readonly getNodeTypeListPath = '/api/MetaModel/masterdata/{orid}';
  static readonly getMetaModelListPath = '/api/MetaModel/metamodels/{orid}';
  static readonly getAllMetaModelPath = '/api/MetaModel/allmetamodels/{orid}';
  static readonly getMetaModelListTypePath = '/api/MetaModel/metamodelstype/{orid}';
  static readonly getMetaModelTypePath = '/api/MetaModel/metamodeltype/{orid}';
  static readonly getDatatypePath = '/api/MetaModel/datatype/{orid}';
  static readonly getActiveNodeTypePath = '/api/MetaModel/nodetype/{orid}';
  static readonly getDropdownMasterDataPath = '/api/MetaModel/dropdown/{orid}';
  static readonly getAttributeTypeMasterDataPath = '/api/MetaModel/attributeTypeDropdown/{orid}';
  static readonly createNodeTypePath = '/api/MetaModel';
  static readonly editNodeTypePath = '/api/MetaModel';
  static readonly deleteAttributePath = '/api/MetaModel';
  static readonly deleteMetaModelPath = '/removemetamodel/{meid}';
  static readonly getMetaModelLevelPath = '/api/MetaModel/bylevel';
  static readonly getMetaDataByExternalIdPath = '/api/MetaModel/byexternalid';
  static readonly isAttributeNameExistsPath = '/api/MetaModel/IsAttributeNameExists';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Get meta data list for selected organization
   * @param params The `MetaModelService.GetMetaModelParams` containing the following parameters:
   *
   * - `orid`: organization id
   *
   * - `attributeNames`: list of attribute names
   *
   * @return Success
   */
  getMetaModelResponse(params: MetaModelService.GetMetaModelParams): __Observable<__StrictHttpResponse<Array<OrDetailsDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.attributeNames || []).forEach((val) => {
      if (val != null) __params = __params.append('attributeNames', val.toString());
    });
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/${encodeURIComponent(String(params.orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrDetailsDTO>>;
      })
    );
  }
  /**
   * Get meta data list for selected organization
   * @param params The `MetaModelService.GetMetaModelParams` containing the following parameters:
   *
   * - `orid`: organization id
   *
   * - `attributeNames`: list of attribute names
   *
   * @return Success
   */
  getMetaModel(params: MetaModelService.GetMetaModelParams): __Observable<Array<OrDetailsDTO>> {
    return this.getMetaModelResponse(params).pipe(__map((_r) => _r.body as Array<OrDetailsDTO>));
  }

  /**
   * Import Master data
   * @param orid Organization Id
   * @return Success
   */
  importMasterDataResponse(orid?: number): __Observable<__StrictHttpResponse<ImportMetaDataResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/MetaModel/masterdata/import`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportMetaDataResponse>;
      })
    );
  }
  /**
   * Import Master data
   * @param orid Organization Id
   * @return Success
   */
  importMasterData(orid?: number): __Observable<ImportMetaDataResponse> {
    return this.importMasterDataResponse(orid).pipe(__map((_r) => _r.body as ImportMetaDataResponse));
  }

  /**
   * @param params The `MetaModelService.GetAttributesGroupingDataParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `attributeNames`:
   *
   * @return Success
   */
  getAttributesGroupingDataResponse(
    params: MetaModelService.GetAttributesGroupingDataParams
  ): __Observable<__StrictHttpResponse<Array<AttributeGroupingData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    (params.attributeNames || []).forEach((val) => {
      if (val != null) __params = __params.append('attributeNames', val.toString());
    });
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/getAttributeGroupingData`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AttributeGroupingData>>;
      })
    );
  }
  /**
   * @param params The `MetaModelService.GetAttributesGroupingDataParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `attributeNames`:
   *
   * @return Success
   */
  getAttributesGroupingData(params: MetaModelService.GetAttributesGroupingDataParams): __Observable<Array<AttributeGroupingData>> {
    return this.getAttributesGroupingDataResponse(params).pipe(__map((_r) => _r.body as Array<AttributeGroupingData>));
  }

  /**
   * Get meta data list for all selected and hierarchy child nodes
   * @param params The `MetaModelService.GetHierarchyMetaModelParams` containing the following parameters:
   *
   * - `searchTerm`: search term
   *
   * - `orid`: organization id
   *
   * - `attributeNames`: list of attribute names
   *
   * @return Success
   */
  getHierarchyMetaModelResponse(
    params: MetaModelService.GetHierarchyMetaModelParams
  ): __Observable<__StrictHttpResponse<Array<OrDetailsDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchTerm != null) __params = __params.set('searchTerm', params.searchTerm.toString());
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    (params.attributeNames || []).forEach((val) => {
      if (val != null) __params = __params.append('attributeNames', val.toString());
    });
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/getHierarchyMetaModel`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrDetailsDTO>>;
      })
    );
  }
  /**
   * Get meta data list for all selected and hierarchy child nodes
   * @param params The `MetaModelService.GetHierarchyMetaModelParams` containing the following parameters:
   *
   * - `searchTerm`: search term
   *
   * - `orid`: organization id
   *
   * - `attributeNames`: list of attribute names
   *
   * @return Success
   */
  getHierarchyMetaModel(params: MetaModelService.GetHierarchyMetaModelParams): __Observable<Array<OrDetailsDTO>> {
    return this.getHierarchyMetaModelResponse(params).pipe(__map((_r) => _r.body as Array<OrDetailsDTO>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getNodeTypeListResponse(orid: number): __Observable<__StrictHttpResponse<Array<MetamodelMe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/masterdata/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetamodelMe>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getNodeTypeList(orid: number): __Observable<Array<MetamodelMe>> {
    return this.getNodeTypeListResponse(orid).pipe(__map((_r) => _r.body as Array<MetamodelMe>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getMetaModelListResponse(orid?: number): __Observable<__StrictHttpResponse<Array<MetamodelMe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/metamodels/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetamodelMe>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getMetaModelList(orid?: number): __Observable<Array<MetamodelMe>> {
    return this.getMetaModelListResponse(orid).pipe(__map((_r) => _r.body as Array<MetamodelMe>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getAllMetaModelResponse(orid?: number): __Observable<__StrictHttpResponse<Array<MetamodelMe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/allmetamodels/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetamodelMe>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getAllMetaModel(orid?: number): __Observable<Array<MetamodelMe>> {
    return this.getAllMetaModelResponse(orid).pipe(__map((_r) => _r.body as Array<MetamodelMe>));
  }

  /**
   * @param params The `MetaModelService.GetMetaModelListTypeParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `level`:
   *
   * @return Success
   */
  getMetaModelListTypeResponse(
    params: MetaModelService.GetMetaModelListTypeParams
  ): __Observable<__StrictHttpResponse<Array<MetamodelMe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.level != null) __params = __params.set('level', params.level.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MetaModel/metamodelstype/${encodeURIComponent(String(params.orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetamodelMe>>;
      })
    );
  }
  /**
   * @param params The `MetaModelService.GetMetaModelListTypeParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `level`:
   *
   * @return Success
   */
  getMetaModelListType(params: MetaModelService.GetMetaModelListTypeParams): __Observable<Array<MetamodelMe>> {
    return this.getMetaModelListTypeResponse(params).pipe(__map((_r) => _r.body as Array<MetamodelMe>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getMetaModelTypeResponse(orid: number): __Observable<__StrictHttpResponse<Array<MetamodelMe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/metamodeltype/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetamodelMe>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getMetaModelType(orid: number): __Observable<Array<MetamodelMe>> {
    return this.getMetaModelTypeResponse(orid).pipe(__map((_r) => _r.body as Array<MetamodelMe>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getDatatypeResponse(orid: number): __Observable<__StrictHttpResponse<Array<DatatypeDe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/datatype/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DatatypeDe>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getDatatype(orid: number): __Observable<Array<DatatypeDe>> {
    return this.getDatatypeResponse(orid).pipe(__map((_r) => _r.body as Array<DatatypeDe>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getActiveNodeTypeResponse(orid: number): __Observable<__StrictHttpResponse<Array<MetamodelMe>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/nodetype/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MetamodelMe>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getActiveNodeType(orid: number): __Observable<Array<MetamodelMe>> {
    return this.getActiveNodeTypeResponse(orid).pipe(__map((_r) => _r.body as Array<MetamodelMe>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getDropdownMasterDataResponse(orid: number): __Observable<__StrictHttpResponse<Array<SystabcatSc>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/dropdown/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SystabcatSc>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getDropdownMasterData(orid: number): __Observable<Array<SystabcatSc>> {
    return this.getDropdownMasterDataResponse(orid).pipe(__map((_r) => _r.body as Array<SystabcatSc>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getAttributeTypeMasterDataResponse(orid: number): __Observable<__StrictHttpResponse<Array<AttributeGroupsAg>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MetaModel/attributeTypeDropdown/${encodeURIComponent(String(orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AttributeGroupsAg>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getAttributeTypeMasterData(orid: number): __Observable<Array<AttributeGroupsAg>> {
    return this.getAttributeTypeMasterDataResponse(orid).pipe(__map((_r) => _r.body as Array<AttributeGroupsAg>));
  }

  /**
   * @param body undefined
   * @return Success
   */
  createNodeTypeResponse(body?: MetamodelMe): __Observable<__StrictHttpResponse<MetamodelMe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/MetaModel`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MetamodelMe>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  createNodeType(body?: MetamodelMe): __Observable<MetamodelMe> {
    return this.createNodeTypeResponse(body).pipe(__map((_r) => _r.body as MetamodelMe));
  }

  /**
   * @param body undefined
   * @return Success
   */
  editNodeTypeResponse(body?: MetamodelMe): __Observable<__StrictHttpResponse<MetamodelMe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/MetaModel`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MetamodelMe>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  editNodeType(body?: MetamodelMe): __Observable<MetamodelMe> {
    return this.editNodeTypeResponse(body).pipe(__map((_r) => _r.body as MetamodelMe));
  }

  /**
   * @param params The `MetaModelService.DeleteAttributeParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  deleteAttributeResponse(params: MetaModelService.DeleteAttributeParams): __Observable<__StrictHttpResponse<MetadataDm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/MetaModel`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MetadataDm>;
      })
    );
  }
  /**
   * @param params The `MetaModelService.DeleteAttributeParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  deleteAttribute(params: MetaModelService.DeleteAttributeParams): __Observable<MetadataDm> {
    return this.deleteAttributeResponse(params).pipe(__map((_r) => _r.body as MetadataDm));
  }

  /**
   * @param meid undefined
   * @return Success
   */
  deleteMetaModelResponse(meid: number): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/removemetamodel/${encodeURIComponent(String(meid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param meid undefined
   * @return Success
   */
  deleteMetaModel(meid: number): __Observable<number> {
    return this.deleteMetaModelResponse(meid).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `MetaModelService.GetMetaModelLevelParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `level`:
   *
   * @return Success
   */
  getMetaModelLevelResponse(params: MetaModelService.GetMetaModelLevelParams): __Observable<__StrictHttpResponse<MetamodelMe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    if (params.level != null) __params = __params.set('level', params.level.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/bylevel`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MetamodelMe>;
      })
    );
  }
  /**
   * @param params The `MetaModelService.GetMetaModelLevelParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `level`:
   *
   * @return Success
   */
  getMetaModelLevel(params: MetaModelService.GetMetaModelLevelParams): __Observable<MetamodelMe> {
    return this.getMetaModelLevelResponse(params).pipe(__map((_r) => _r.body as MetamodelMe));
  }

  /**
   * @param externalId undefined
   * @return Success
   */
  getMetaDataByExternalIdResponse(externalId?: string): __Observable<__StrictHttpResponse<Array<OrDetailsDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (externalId != null) __params = __params.set('externalId', externalId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/byexternalid`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrDetailsDTO>>;
      })
    );
  }
  /**
   * @param externalId undefined
   * @return Success
   */
  getMetaDataByExternalId(externalId?: string): __Observable<Array<OrDetailsDTO>> {
    return this.getMetaDataByExternalIdResponse(externalId).pipe(__map((_r) => _r.body as Array<OrDetailsDTO>));
  }

  /**
   * @param attributeName undefined
   * @return Success
   */
  isAttributeNameExistsResponse(attributeName?: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (attributeName != null) __params = __params.set('attributeName', attributeName.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MetaModel/IsAttributeNameExists`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param attributeName undefined
   * @return Success
   */
  isAttributeNameExists(attributeName?: string): __Observable<boolean> {
    return this.isAttributeNameExistsResponse(attributeName).pipe(__map((_r) => _r.body as boolean));
  }
}

module MetaModelService {
  /**
   * Parameters for GetMetaModel
   */
  export interface GetMetaModelParams {
    /**
     * organization id
     */
    orid: number;

    /**
     * list of attribute names
     */
    attributeNames?: Array<string>;
  }

  /**
   * Parameters for GetAttributesGroupingData
   */
  export interface GetAttributesGroupingDataParams {
    orid?: number;
    attributeNames?: Array<string>;
  }

  /**
   * Parameters for GetHierarchyMetaModel
   */
  export interface GetHierarchyMetaModelParams {
    /**
     * search term
     */
    searchTerm?: string;

    /**
     * organization id
     */
    orid?: number;

    /**
     * list of attribute names
     */
    attributeNames?: Array<string>;
  }

  /**
   * Parameters for GetMetaModelListType
   */
  export interface GetMetaModelListTypeParams {
    orid: number;
    level?: number;
  }

  /**
   * Parameters for DeleteAttribute
   */
  export interface DeleteAttributeParams {
    orid?: number;
    body?: MetadataDm;
  }

  /**
   * Parameters for GetMetaModelLevel
   */
  export interface GetMetaModelLevelParams {
    orid?: number;
    level?: number;
  }
}

export { MetaModelService };
