import { Injectable } from '@angular/core';
import { MachineCounterStore } from './machine-counter.store';
import { CounterInfo } from 'chronos-core-client';

@Injectable({ providedIn: 'root' })
export class MachineCounterService {
  constructor(private machineCounterStore: MachineCounterStore) {}

  public updateMachineCounter(machineCounter: Partial<CounterInfo>): void {
    this.machineCounterStore.update({ ...machineCounter });
  }
}
