/* tslint:disable */
type ShiftReportOpenTaskItemType = 'Downtime' | 'QaCheck' | 'EmployeeRegistration';
module ShiftReportOpenTaskItemType {
  export const DOWNTIME: ShiftReportOpenTaskItemType = 'Downtime';
  export const QA_CHECK: ShiftReportOpenTaskItemType = 'QaCheck';
  export const EMPLOYEE_REGISTRATION: ShiftReportOpenTaskItemType = 'EmployeeRegistration';
  export function values(): ShiftReportOpenTaskItemType[] {
    return [DOWNTIME, QA_CHECK, EMPLOYEE_REGISTRATION];
  }
}

export { ShiftReportOpenTaskItemType };
