<section class="chronos-dialog--size-xs">
  <div class="print-label">
    <div class="print-label__title">{{ 'LABELS.AVAILABLE' | translate: { count: availableCount } }}</div>
    <div [formGroup]="modalForm" class="print-label__form">
      <div class="form__item">{{ 'LABELS.PRINT' | translate }} / {{ 'LABELS.REPRINT' | translate }}</div>
      <div class="form__item">
        <span class="form__label">{{ 'LABELS.FROM' | translate }}</span>
        <lib-input-number [inputStyle]="INPUT_STYLE" formControlName="fromRange"></lib-input-number>
      </div>
      <div class="form__item">
        <span class="form__label">{{ 'LABELS.TO' | translate }}</span>
        <lib-input-number [inputStyle]="INPUT_STYLE" formControlName="toRange"></lib-input-number>
      </div>
    </div>
  </div>
  <lib-modal-footer
    [whenLoadingTopic]="LOADING_TOPIC"
    [submitLabel]="'MODAL_FOOTER.OK'"
    [submitDisabled]="modalForm.invalid"
    (submitClicked)="submitModal()"
    (declineClicked)="closeModal()"
  ></lib-modal-footer>
</section>
