<div class="machine-output">
  <ng-container [ngSwitch]="activeProductionOrder.producedMaterialType">
    <ng-container *ngSwitchCase="ORDER_TYPE.PRODUCED_MATERIAL">
      <app-output-container-list
        [reasonOptions]="reasonOptions$ | async"
        [grossQuantity]="grossQuantity"
        [activeProductionOrder]="activeProductionOrder"
        (finishClicked)="navigateToFinishPhase($event)"
      ></app-output-container-list>
    </ng-container>
    <ng-container *ngSwitchCase="ORDER_TYPE.OUTPUT_UNIT">
      <app-output-unit-list
        [reasonOptions]="reasonOptions$ | async"
        [grossQuantity]="grossQuantity"
        [activeProductionOrder]="activeProductionOrder"
        (finishClicked)="navigateToFinishPhase($event)"
      ></app-output-unit-list>
    </ng-container>
  </ng-container>
</div>
