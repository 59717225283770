import { Injectable } from '@angular/core';
import { WorkCenterDsService } from '@app/core/workcenter/data-services/work-center-ds/work-center-ds.service';
import { environment } from '@environments/environment';
import * as R from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { StorageKey, StorageService } from 'chronos-shared';

@Injectable({ providedIn: 'root' })
export class ActiveWorkCenterService {
  private currentWorkCenter = new BehaviorSubject<number | null>(null);

  public workCenterId$: Observable<number> = this.currentWorkCenter.asObservable();

  private readonly STORAGE_KEY = StorageKey.workCenterId;

  constructor(private storageService: StorageService, private workCenterDsService: WorkCenterDsService) {}

  public getWorkCenterId(): number | null {
    return this.currentWorkCenter.getValue();
  }

  public setWorkCenterId(workCenterId: number): void {
    this.storageService.setItem(this.STORAGE_KEY, workCenterId.toString());
    this.currentWorkCenter.next(workCenterId);
  }

  public initializeWorkCenterId(): Observable<boolean> {
    const workCenterId: number | null = this.getWorkCenterIdFromStorage() || environment.workCenterId;

    return this.workCenterIdExists(workCenterId).pipe(
      tap((isValid) => {
        isValid ? this.currentWorkCenter.next(workCenterId) : this.currentWorkCenter.error('Default work center does not exist');
      })
    );
  }

  private getWorkCenterIdFromStorage(): number | null {
    const storageWorkCenterId = this.storageService.getItem(this.STORAGE_KEY);

    return !R.isNil(storageWorkCenterId) ? Number(storageWorkCenterId) : null;
  }

  private workCenterIdExists(workCenterId: number): Observable<boolean> {
    const containsWorkCenterId = R.any(R.compose(R.equals(workCenterId), R.prop('id')));

    return this.workCenterDsService.getWorkCenters().pipe(map(containsWorkCenterId));
  }
}
