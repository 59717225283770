/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SetSetupParameterKindEntry } from '../models/set-setup-parameter-kind-entry';
import { SimpleValueEntry } from '../models/simple-value-entry';
import { SetupParameterKind } from '../models/setup-parameter-kind';
import { SetupParameters } from '../models/setup-parameters';
import { QuantityPerPalletViewModel } from '../models/quantity-per-pallet-view-model';
import { BobbinQuantityViewModel } from '../models/bobbin-quantity-view-model';
@Injectable({
  providedIn: 'root'
})
class SetupParameterService extends __BaseService {
  static readonly putSetupParameterKindPath = '/api/v1/SetupParameter/SetupParameterKind/{productionOrderId}';
  static readonly putSetupParameterPath = '/api/v1/SetupParameter/SetupParameter/{productionOrderId}';
  static readonly getSetupParameterKindsPath = '/api/v1/SetupParameter/GetSetupParameterKinds/{productionOrderId}';
  static readonly getSetupParametersPath = '/api/v1/SetupParameter/GetSetupParameters/{productionOrderId}';
  static readonly getQuantityPerPalletPath = '/api/v1/SetupParameter/GetQuantityPerPallet/{productionOrderId}';
  static readonly getBobbinQuantityPath = '/api/v1/SetupParameter/GetBobbinQuantity/{productionOrderId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `SetupParameterService.PutSetupParameterKindParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  putSetupParameterKindResponse(params: SetupParameterService.PutSetupParameterKindParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SetupParameter/SetupParameterKind/${encodeURIComponent(String(params.productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SetupParameterService.PutSetupParameterKindParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  putSetupParameterKind(params: SetupParameterService.PutSetupParameterKindParams): __Observable<null> {
    return this.putSetupParameterKindResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SetupParameterService.PutSetupParameterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  putSetupParameterResponse(params: SetupParameterService.PutSetupParameterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/SetupParameter/SetupParameter/${encodeURIComponent(String(params.productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SetupParameterService.PutSetupParameterParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  putSetupParameter(params: SetupParameterService.PutSetupParameterParams): __Observable<null> {
    return this.putSetupParameterResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param productionOrderId undefined
   */
  getSetupParameterKindsResponse(productionOrderId: number): __Observable<__StrictHttpResponse<SetupParameterKind>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SetupParameter/GetSetupParameterKinds/${encodeURIComponent(String(productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetupParameterKind>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getSetupParameterKinds(productionOrderId: number): __Observable<SetupParameterKind> {
    return this.getSetupParameterKindsResponse(productionOrderId).pipe(__map((_r) => _r.body as SetupParameterKind));
  }

  /**
   * @param productionOrderId undefined
   */
  getSetupParametersResponse(productionOrderId: number): __Observable<__StrictHttpResponse<SetupParameters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SetupParameter/GetSetupParameters/${encodeURIComponent(String(productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetupParameters>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getSetupParameters(productionOrderId: number): __Observable<SetupParameters> {
    return this.getSetupParametersResponse(productionOrderId).pipe(__map((_r) => _r.body as SetupParameters));
  }

  /**
   * @param productionOrderId undefined
   */
  getQuantityPerPalletResponse(productionOrderId: number): __Observable<__StrictHttpResponse<QuantityPerPalletViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SetupParameter/GetQuantityPerPallet/${encodeURIComponent(String(productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuantityPerPalletViewModel>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getQuantityPerPallet(productionOrderId: number): __Observable<QuantityPerPalletViewModel> {
    return this.getQuantityPerPalletResponse(productionOrderId).pipe(__map((_r) => _r.body as QuantityPerPalletViewModel));
  }

  /**
   * @param productionOrderId undefined
   */
  getBobbinQuantityResponse(productionOrderId: number): __Observable<__StrictHttpResponse<BobbinQuantityViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/SetupParameter/GetBobbinQuantity/${encodeURIComponent(String(productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BobbinQuantityViewModel>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getBobbinQuantity(productionOrderId: number): __Observable<BobbinQuantityViewModel> {
    return this.getBobbinQuantityResponse(productionOrderId).pipe(__map((_r) => _r.body as BobbinQuantityViewModel));
  }
}

module SetupParameterService {
  /**
   * Parameters for PutSetupParameterKind
   */
  export interface PutSetupParameterKindParams {
    productionOrderId: number;
    entry: SetSetupParameterKindEntry;
  }

  /**
   * Parameters for PutSetupParameter
   */
  export interface PutSetupParameterParams {
    productionOrderId: number;
    entry: SimpleValueEntry;
  }
}

export { SetupParameterService };
