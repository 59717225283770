import { Component, OnInit } from '@angular/core';
import { CancelSetupCommandService } from '@app/modules/setup-phase/commands';
import { SetupPhaseService } from '@app/modules/setup-phase/services/setup-phase/setup-phase.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-setup-phase',
  templateUrl: './setup-phase.component.html',
  styleUrls: ['./setup-phase.component.scss']
})
export class SetupPhaseComponent implements OnInit {
  public isSetupPhase$: Observable<boolean>;

  public cancelCommand: CancelSetupCommandService;

  constructor(public setupPhaseService: SetupPhaseService) {}

  public ngOnInit(): void {
    this.isSetupPhase$ = this.setupPhaseService.isSetupPhase();

    this.cancelCommand = new CancelSetupCommandService(this.setupPhaseService);
  }
}
