/* tslint:disable */
type ApprovalReportItemType =
  | 'None'
  | 'Downtime'
  | 'ProductionOrder'
  | 'ProductionOrderRunPhase'
  | 'DowntimeDetail'
  | 'ConsumptionArticle'
  | 'ConsumptionMaterial'
  | 'RunDetail'
  | 'SetupDetail';
module ApprovalReportItemType {
  export const NONE: ApprovalReportItemType = 'None';
  export const DOWNTIME: ApprovalReportItemType = 'Downtime';
  export const PRODUCTION_ORDER: ApprovalReportItemType = 'ProductionOrder';
  export const PRODUCTION_ORDER_RUN_PHASE: ApprovalReportItemType = 'ProductionOrderRunPhase';
  export const DOWNTIME_DETAIL: ApprovalReportItemType = 'DowntimeDetail';
  export const CONSUMPTION_ARTICLE: ApprovalReportItemType = 'ConsumptionArticle';
  export const CONSUMPTION_MATERIAL: ApprovalReportItemType = 'ConsumptionMaterial';
  export const RUN_DETAIL: ApprovalReportItemType = 'RunDetail';
  export const SETUP_DETAIL: ApprovalReportItemType = 'SetupDetail';
  export function values(): ApprovalReportItemType[] {
    return [
      NONE,
      DOWNTIME,
      PRODUCTION_ORDER,
      PRODUCTION_ORDER_RUN_PHASE,
      DOWNTIME_DETAIL,
      CONSUMPTION_ARTICLE,
      CONSUMPTION_MATERIAL,
      RUN_DETAIL,
      SETUP_DETAIL
    ];
  }
}

export { ApprovalReportItemType };
