import { Injectable } from '@angular/core';
import { ConsumedMaterialQuery, ConsumedMaterialService } from '@app/modules/mounting/state';
import { PrimaryMaterialMounted } from 'chronos-core-client';
import * as R from 'ramda';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SortDirection } from 'chronos-shared';
import { PrimaryMaterialDsService } from '../primary-material-ds';

@Injectable({
  providedIn: 'root'
})
export class ConsumedMaterialDsService {
  constructor(
    private primaryMaterialDsService: PrimaryMaterialDsService,
    private consumedMaterialQuery: ConsumedMaterialQuery,
    private consumedMaterialService: ConsumedMaterialService
  ) {}

  public getConsumedMaterial(): Observable<PrimaryMaterialMounted[]> {
    return this.renewConsumedMaterial().pipe(switchMap(() => this.consumedMaterialQuery.consumedMaterial$));
  }

  public renewConsumedMaterial(): Observable<PrimaryMaterialMounted[]> {
    return this.primaryMaterialDsService.getConsumption().pipe(
      map(R.prop('activeMaterialBlock')),
      map(R.prop('mountedMaterials')),
      tap((consumedMaterial: PrimaryMaterialMounted[]) => {
        this.consumedMaterialService.set(consumedMaterial);
      })
    );
  }

  public getPalletSequence(): Observable<SortDirection> {
    return this.consumedMaterialQuery.palletSequence$;
  }

  public updateFilters(showOnlyDummy: boolean): void {
    this.consumedMaterialService.updateFilters({ showOnlyDummy });
  }

  public updateSorting(palletSequence: SortDirection): void {
    this.consumedMaterialService.updateSorting({ palletSequence });
  }

  public resetConsumedMaterial(): void {
    this.consumedMaterialService.reset();
  }
}
