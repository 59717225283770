import { Injectable } from '@angular/core';
import { KpiActionBase } from './kpi-action-base';

@Injectable({
  providedIn: 'root'
})
export class KpiActionEmptyService extends KpiActionBase {
  public shouldShowEdit = false;

  constructor() {
    super();
  }
}
