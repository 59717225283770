import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimaryMaterialMounted } from 'chronos-core-client';

@Component({
  selector: 'app-consumption-pallet-item',
  templateUrl: './consumption-pallet-item.component.html',
  styleUrls: ['./consumption-pallet-item.component.scss']
})
export class ConsumptionPalletItemComponent {
  @Input() public consumedContainer: PrimaryMaterialMounted;
  @Output() public dismountIconClicked = new EventEmitter<PrimaryMaterialMounted>();

  public onDismountClick(consumed: PrimaryMaterialMounted) {
    this.dismountIconClicked.emit(consumed);
  }
}
