<header class="traceability__header">
  <h1 class="traceability__title">{{ 'TRACEABILITY.TITLE' | translate }}</h1>
</header>
<section class="traceability__filters">
  <div class="filters__input">
    <p-dropdown [options]="traceabilitySearchModeOption" [(ngModel)]="selectedSearchMode" (onChange)="onFilterChange()"></p-dropdown>
  </div>
  <div class="text-search filters__search">
    <input
      id="textSearch"
      type="text"
      class="search-bar"
      pInputText
      [(ngModel)]="textQuery"
      [placeholder]="searchPlaceHolder | translate"
      (input)="onFilterChange()"
    />
  </div>
  <div>
    <span class="col-form-label filters__label">
      {{ 'TRACEABILITY.PRODUCTION_TIME' | translate }}
    </span>
    <lib-calendar
      [showTime]="false"
      [(ngModel)]="setupStartTime"
      [maxDate]="maxDate"
      [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      [inputStyle]="{ width: '100px' }"
      (ngModelChange)="onStartDateChange()"
    ></lib-calendar>
    <span class="col-form-label">-</span>
    <lib-calendar
      [showTime]="false"
      [(ngModel)]="setupEndTime"
      [maxDate]="maxDate"
      [inputStyle]="{ width: '100px' }"
      [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      (ngModelChange)="onEndDateChange()"
    ></lib-calendar>
  </div>
  <div class="form-control filters__submit">
    <lib-button [label]="'TRACEABILITY.SUBMIT'" (clicked)="submitFilters()"></lib-button>
  </div>
</section>
<section>
  <div *ngIf="isNotLoading; else Loading">
    <div *ngIf="treeTableData?.length > 0; else NoDataLoaded">
      <app-traceability-table
        [tableData]="treeTableData"
        [isNodeLoading]="isNodeLoading$ | async"
        (loadNodeClicked)="loadNode($event)"
      ></app-traceability-table>
    </div>
  </div>
  <ng-template #NoDataLoaded>
    <div class="spinner">
      {{ 'TRACEABILITY.NO_DATA_LOADED' | translate }}
    </div>
  </ng-template>
  <ng-template #Loading>
    <div class="spinner">
      {{ 'TRACEABILITY.LOADING' | translate }}&nbsp;
      <i class="pi pi-spin pi-spinner"></i>
    </div>
  </ng-template>
</section>
