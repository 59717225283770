import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent {
  @Input() public disabled = false;

  @Output() public buttonClicked = new EventEmitter<null>();

  public onClick(): void {
    if (!this.disabled) {
      this.buttonClicked.emit();
    }
  }
}
