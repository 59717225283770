<div class="pallet-indicator">
  <div class="pallet-indicator__title">{{ title }}</div>
  <div
    class="pallet-indicator__wrapper"
    [ngClass]="{
      'pallet-indicator__wrapper--large': large,
      'pallet-indicator__wrapper--borderless': borderless
    }"
    (click)="onClick()"
  >
    <div>
      <i class="icon-pallet-4-4 pallet-indicator__icon"></i>
    </div>
    <div class="pallet-indicator__value">{{ palletCount | number }}</div>
  </div>
</div>
