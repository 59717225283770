<div
  class="mounting-pallet-kpi__info-wrapper"
  [ngClass]="{ 'mounting-pallet-kpi__info-wrapper--compact': compact, 'mounting-pallet-kpi__info-wrapper--left-aligned': leftAligned }"
>
  <i *ngIf="showInfoIcon" (click)="onInfoButtonClick()" class="mounting-pallet-kpi__info-icon icon-info-solid"></i>
  <div class="mounting-pallet-kpi__info-label" [ngClass]="{ 'mounting-pallet-kpi__info-label--compact': compact }">
    {{ kpiLabel }}
    <i *ngIf="isSsccCodeShort" class="pi pi-copy cursor-clickable" (click)="copyToClipboard()"></i>
  </div>
  <div
    class="mounting-pallet-kpi__info-value"
    [ngClass]="{
      'mounting-pallet-kpi__info-value--bold': emphasizeText,
      'mounting-pallet-kpi__info-value--compact': compact
    }"
    [style.text-align]="textAligned"
  >
    <div *ngIf="isSsccCodeShort; else ssccLongKpi" [title]="kpiValue">
      {{ kpiValue | shortSsccCode }}
    </div>
    <ng-template #ssccLongKpi>
      <div *ngIf="isSsccCodeLong; else editable">
        <span [innerHTML]="kpiValue | sscc"></span>
      </div>
    </ng-template>
    <ng-template #editable>
      <div *ngIf="isEditable; else otherKpi">
        <lib-input-number
          [size]="5"
          [format]="true"
          [step]="1"
          [min]="0"
          [ngModel]="kpiInfo"
          (ngModelChange)="onQuantityChange($event)"
        ></lib-input-number>
      </div>
    </ng-template>
    <ng-template #otherKpi>
      <span [innerHTML]="kpiValue"></span>
      <span class="mounting-pallet-kpi__info-value--unit" [innerHTML]="unit"></span>
    </ng-template>
  </div>
</div>
