<div class="gluing-section">
  <div class="icons icons__info">
    <i class="icon-info-outline"></i>
  </div>

  <div class="content">
    <div class="row no-gutters">
      <div (click)="toggleDocuments()" class="content__header" [ngClass]="{ 'content__header--disabled': disabled }">
        <div class="content__header-toggle">
          <i [ngClass]="isExpanded ? 'icon-arrows-right-filled-close' : 'icon-arrow-right-filled-open'"></i>
        </div>
        <div class="content__data-documents-title">{{ title }}</div>
      </div>
    </div>
    <div *ngIf="isExpanded" class="row no-gutters">
      <div class="content__data">
        <div class="content__data-aside"></div>
        <div class="content__data-documents">
          <div class="content__data-documents-item">
            <i class="content__data-documents-icon icon-pdf-outline"></i>
            <span class="content__data-documents-title">{{ 'Infodoc1' }}</span>
          </div>
          <div class="content__data-documents-item">
            <i class="content__data-documents-icon icon-pdf-outline"></i>
            <span class="content__data-documents-title">{{ 'Infodoc10' }}</span>
          </div>
          <div class="content__data-documents-item">
            <i class="content__data-documents-icon icon-pdf-outline"></i>
            <span class="content__data-documents-title">{{ 'Infodoc11220' }}</span>
          </div>
          <div class="content__data-documents-item">
            <i class="content__data-documents-icon icon-pdf-outline"></i>
            <span class="content__data-documents-title">{{ 'Infodoc19902122' }}</span>
          </div>
          <div class="content__data-documents-item">
            <i class="content__data-documents-icon icon-pdf-outline"></i>
            <span class="content__data-documents-title">{{ 'Infodoc122' }}</span>
          </div>
          <div class="content__data-documents-item">
            <i class="content__data-documents-icon icon-pdf-outline"></i>
            <span class="content__data-documents-title">{{ 'Infodoc11' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="icons icons__status"></div>
</div>
