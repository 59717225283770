import { apm } from '@elastic/apm-rum';

// Note: https://www.elastic.co/guide/en/apm/get-started/current/transactions.html
export const elastic = {
  transactionType: 'chronos-ui',

  traceUiActivity(activityName: string, transactionType?: string) {
    const transaction = apm.startTransaction(activityName, transactionType ?? elastic.transactionType, { managed: true });
    const span = transaction.startSpan(activityName);
    return {
      end: () => {
        span.end();
      }
    };
  }
};
