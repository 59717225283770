import { Injectable } from '@angular/core';
import { ActiveOrderDsService, ContainersDsService, ParametersDsService, ProducedMaterialDsService } from '@app/core/data-services';
import { HubUrlsService } from '@app/core/utils';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { OutputPalletsService } from '@app/modules/run-phase/state';
import { WasteAssignmentFormEvent } from '@app/shared/models';
import {
  BobbinQuantityViewModel,
  ChangeContainerTargetQuantityEntry,
  ContainersService,
  OuterLabelType,
  ProducedMaterialsViewModel,
  ProductionOrderService,
  Quantity,
  QuantityPerPalletViewModel,
  SetPrintLabelInfoEntry,
  SetupParameterService,
  WebSocketClientService,
  WorkCenterIdEntry
} from 'chronos-core-client';
import { ProducedMaterialLoadingMode } from 'projects/chronos-core-client/src/public-api';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable()
export class OutputContainerService {
  constructor(
    private activeOrderDsService: ActiveOrderDsService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private outputPalletsService: OutputPalletsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private containerService: ContainersService,
    private containersDsService: ContainersDsService,
    private setupParameterService: SetupParameterService,
    private productionOrderService: ProductionOrderService,
    private parametersDsService: ParametersDsService,
    private producedMaterialDsService: ProducedMaterialDsService
  ) {}

  public createOutputPallet(productionOrderId: number): Observable<null> {
    return this.containersDsService.createOutputPalletFromPool(productionOrderId);
  }

  public addProducedMaterialWasteAssignment(producedMaterialId: number, wasteAssignment: WasteAssignmentFormEvent): Observable<null> {
    return this.containersDsService.addProducedMaterialWasteAssignment(
      producedMaterialId,
      wasteAssignment.maculature,
      wasteAssignment.waste,
      wasteAssignment?.reason
    );
  }

  public removePalletWasteAssignment(producedMaterialId: number, wasteAssignmentId: number): Observable<any> {
    return this.containersDsService.deletePalletWasteAssignment(producedMaterialId, wasteAssignmentId);
  }

  public printLabel(containerId: number, isReprint: boolean): Observable<null> {
    return this.containersDsService.printLabel(containerId, isReprint);
  }

  public setPalletAsFinished(producedMaterialId: number, workCenterId: number): Observable<any> {
    const entry: WorkCenterIdEntry = {
      workCenterId
    };
    const params: ContainersService.FinishProducedMaterialParams = {
      producedMaterialId,
      entry
    };
    return this.containerService.finishProducedMaterial(params);
  }

  public setPalletAsOpened(producedMaterialId: number, workCenterId: number): Observable<any> {
    const entry: WorkCenterIdEntry = {
      workCenterId
    };
    const params: ContainersService.OpenProducedMaterialParams = {
      producedMaterialId,
      entry
    };
    return this.containerService.openProducedMaterial(params);
  }

  public markPalletAsNotLast(producedMaterialId: number, workCenterId: number): Observable<any> {
    const entry: WorkCenterIdEntry = {
      workCenterId
    };
    const params: ContainersService.RemoveLastMarkParams = {
      producedMaterialId,
      entry
    };
    return this.containerService.removeLastMark(params).pipe(take(1));
  }

  public markPalletAsLast(producedMaterialId: number, workCenterId: number): Observable<any> {
    const entry: WorkCenterIdEntry = {
      workCenterId
    };
    const params: ContainersService.SetLastMarkParams = {
      producedMaterialId,
      entry
    };
    return this.containerService.setLastMark(params).pipe(take(1));
  }

  public getQuantityPerPallet(productionOrderId: number): Observable<QuantityPerPalletViewModel> {
    return this.setupParameterService.getQuantityPerPallet(productionOrderId).pipe(take(1));
  }

  public getOutputPallets(productionOrderId: number, loadingMode: ProducedMaterialLoadingMode): Observable<ProducedMaterialsViewModel> {
    return this.containerService.getProducedMaterialList({ productionOrderId, loadingMode }).pipe(
      tap((producedMaterialsViewModel) => {
        this.outputPalletsService.setOutputPallets(producedMaterialsViewModel);
      })
    );
  }

  public setOutputPoolContainerQuantity(productionOrderId: number, palletQuantity: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ChangeContainerTargetQuantityEntry = {
      newAmount: palletQuantity,
      workCenterId
    };
    const params: ProductionOrderService.ChangeContainerTargetQuantityParams = {
      productionOrderId,
      entry
    };
    return this.productionOrderService.changeContainerTargetQuantity(params);
  }

  public changeContainerQuantity(producedMaterialId: number, newQuantity: Quantity, changeFollowingPallets: boolean): Observable<null> {
    return this.containersDsService.changeContainerQuantity(producedMaterialId, newQuantity, changeFollowingPallets);
  }

  public setPrintLabelInfo(containerId: number, printLabelInfo: string): Observable<any> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    const entry: SetPrintLabelInfoEntry = {
      workCenterId,
      printLabelInfo
    };
    const params: ContainersService.SetPrintLabelInfoParams = {
      containerId,
      entry
    };
    return this.containerService.setPrintLabelInfo(params).pipe(take(1));
  }

  public getProducedMaterialNotifications(): Observable<any> {
    const hubUrl = this.hubUrlsService.getProducedMaterialList(this.activeOrderDsService.getActiveOrderId());
    return this.webSocketClientService.getNotificationsForTopic(hubUrl).pipe(
      tap((data) => {
        if (data.loadingMode === ProducedMaterialLoadingMode.ALL) {
          this.outputPalletsService.setOutputPallets(data);
        } else {
          this.outputPalletsService.updateOutputPallets(data);
        }
      })
    );
  }

  public getBobbinQuantity(productionOrderId: number): Observable<BobbinQuantityViewModel> {
    return this.parametersDsService.getBobbinQuantity(productionOrderId);
  }

  // this is from container change quantity dialog
  public changeContainerBobbinQuantity(
    producedMaterialId: number,
    bobbinQuantity: number,
    bobbinQuantityUnit: string,
    changeFollowingPallets: boolean
  ): Observable<null> {
    return this.containersDsService.changeContainerBobbinQuantity(
      producedMaterialId,
      bobbinQuantity,
      bobbinQuantityUnit,
      changeFollowingPallets
    );
  }

  // changes the pool quantity
  public setOutputPoolBobbinQuantity(productionOrderId: number, bobbinQuantity: number): Observable<null> {
    return this.containersDsService.setOutputPoolBobbinQuantity(productionOrderId, bobbinQuantity);
  }

  // manage pallet bobbin quatity and number of copies.
  public changeProducedMaterialPrintInfo(
    producedMaterialId: number,
    outerLabelType: OuterLabelType,
    quantityPerOuter: number,
    numberOfCopies: number
  ): Observable<null> {
    return this.producedMaterialDsService.changeProducedMaterialPrintInfo(
      producedMaterialId,
      outerLabelType,
      quantityPerOuter,
      numberOfCopies
    );
  }
}
