import { SetupFooterCommandBase } from '@app/shared/components';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { QaHistoryService } from '@app/modules/qa-history/services/qa-history/qa-history.service';
import { notificationTopic } from '@app/shared/utils';

export class ConfirmCheckboxQaHistoryCommandService implements SetupFooterCommandBase {
  public enabled = false;
  public buttonText = 'SETUP_PHASE_FOOTER.CONFIRM_CHECK';
  public required = true;

  public loadingTopic = notificationTopic.footerNextSetupPhase;

  constructor(private qaHistoryService: QaHistoryService) {}

  public execute(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.qaHistoryService
      .setQaHistoryCheck(this.enabled)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe({
        error: () => (this.enabled = !this.enabled)
      });
  }
}
