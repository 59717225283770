/* tslint:disable */
type ResponseType = 'Success' | 'Failure';
module ResponseType {
  export const SUCCESS: ResponseType = 'Success';
  export const FAILURE: ResponseType = 'Failure';
  export function values(): ResponseType[] {
    return [SUCCESS, FAILURE];
  }
}

export { ResponseType };
