import { Injectable } from '@angular/core';
import { ActiveProductionOrder, RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import { ActiveOrderStore } from './active-order.store';

@Injectable({ providedIn: 'root' })
export class ActiveOrderService {
  constructor(private activeOrderStore: ActiveOrderStore) {}

  public setActiveProductionOrder(activeProductionOrder: Partial<ActiveProductionOrder>): void {
    if (activeProductionOrder) {
      this.activeOrderStore.update({ ...activeProductionOrder });
    }
  }

  public reset() {
    this.activeOrderStore.reset();
  }

  public setAreKpisConfirmed(areKpisConfirmed: boolean): void {
    this.activeOrderStore.update({ areKpisConfirmed });
  }

  public setActiveOrderPhase(runPhaseType: RunPhaseType, runSubPhaseType: RunSubPhaseType): void {
    this.activeOrderStore.update({ runPhaseType, runSubPhaseType });
  }

  public setQaHistoryConfirmed(isQaHistoryConfirmed): void {
    this.activeOrderStore.update({ isQaHistoryConfirmed });
  }

  public setQaToolCheckoutConfirm(isQaToolCheckoutConfirmed): void {
    this.activeOrderStore.update({ isQaToolCheckoutConfirmed });
  }
}
