/* tslint:disable */
type MountedMaterialStatus = 'None' | 'Mounted' | 'Active' | 'Consumed';
module MountedMaterialStatus {
  export const NONE: MountedMaterialStatus = 'None';
  export const MOUNTED: MountedMaterialStatus = 'Mounted';
  export const ACTIVE: MountedMaterialStatus = 'Active';
  export const CONSUMED: MountedMaterialStatus = 'Consumed';
  export function values(): MountedMaterialStatus[] {
    return [NONE, MOUNTED, ACTIVE, CONSUMED];
  }
}

export { MountedMaterialStatus };
