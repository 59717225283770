import { KpiValue, Quantity } from 'chronos-core-client';
import { TreeNode } from 'primeng/api';

export interface ApprovalReportData {
  isConfirmedByUser: boolean;
  items: TreeNode[];
  kpiItems: Array<KpiValue>;
  hasInfo: boolean;
  enableSendingApprovalReport: boolean;
  confirmSendingApprovalReport: boolean;
}

export interface ApprovalReportParams {
  workCenterId: number;
  date: string;
}

export enum ApproveReportTableRowStyleClass {
  FIRST_LEVEL = 'first-level-row',
  SECOND_LEVEL = 'second-level-row',
  THIRD_LEVEL = 'third-level-row',
  FOURTH_LEVEL = 'fourth-level-row',
  DUMMY_ARTICLE = 'dummy-article-row'
}

export interface ModalChangeQuantityComponentData {
  workCenterId: number;
  approvalReportDate: string;
  approvalReportLineId: number;
  goodQuantity: Quantity;
  waste: Quantity;
  maculature: Quantity;
}
export interface ModalChangeQuantityBetweenOrdersComponentData {
  workCenterId: number;
  approvalReportDate: string;
  approvalReportLineId: number;
  firstProductionOrder: string;
  goodQuantityFirstProductionOrder: Quantity;
  goodQuantitySecondProductionOrder: Quantity;
  secondProductionOrder: string;
  secondApproveReportLineId: number;
}
