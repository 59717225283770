import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ParameterBase, ParameterFieldType } from '@app/shared/models';

@Injectable()
export class FormConstructionService {
  public toFormGroup(fields: ParameterBase[]): UntypedFormGroup {
    const parameters = {};

    fields
      .filter((field) => field.controlType !== ParameterFieldType.Check)
      .forEach((field: ParameterBase) => {
        const validators = [];

        if (field.required) {
          validators.push(Validators.required);
        }

        if (this.isDefined(field.minValue)) {
          validators.push(Validators.min(field.minValue));
        }

        if (this.isDefined(field.maxValue)) {
          validators.push(Validators.max(field.maxValue));
        }

        if (this.isDefined(field.minLength)) {
          validators.push(Validators.minLength(field.minLength));
        }

        if (this.isDefined(field.maxLength)) {
          validators.push(Validators.maxLength(field.maxLength));
        }

        let defaultValue: string | number = field.defaultValue;

        if (this.isDefined(field.value)) {
          defaultValue = this.convertToFieldValue(field);
        }

        parameters[field.id] = new UntypedFormControl({ value: defaultValue, disabled: field.readonly }, validators);
      });

    return new UntypedFormGroup(parameters);
  }

  private convertToFieldValue(field: ParameterBase): number | string {
    let defaultValue: number | string = Number(field.value);

    if (field.controlType === ParameterFieldType.Text && field.type !== ParameterFieldType.Number) {
      defaultValue = field.value;
    }

    return defaultValue;
  }

  private isDefined(property: any): boolean {
    return property !== null && property !== undefined && property !== '';
  }
}
