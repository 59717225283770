<textarea
  class="info-field"
  [rows]="5"
  pInputTextarea
  autoResize="autoResize"
  [(ngModel)]="remarkInfo"
  (ngModelChange)="isRemarkInfoFilled()"
></textarea>
<lib-modal-footer
  (submitClicked)="saveEditedRemarkInfo()"
  (declineClicked)="cancelRemarkInfoEditing()"
  [submitDisabled]="!isSubmitEnabled"
></lib-modal-footer>
