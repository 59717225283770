import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FinishedGoodArticleItem } from 'chronos-core-client';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-article-dropdown',
  templateUrl: './article-dropdown.component.html',
  styleUrls: ['./article-dropdown.component.scss']
})
export class ArticleDropdownComponent implements OnInit {
  @Input()
  public set articleItemsSubjectInput(items: BehaviorSubject<FinishedGoodArticleItem[]>) {
    this.articleItemsSubject = items;
  }

  private readonly numberInput$ = new BehaviorSubject<number>(null);
  @Input() public selectedArticleItem: FinishedGoodArticleItem;

  @Input() public isDisabled = false;

  @Output() public articleChanged = new EventEmitter<FinishedGoodArticleItem>();

  public articleItemsSubject: BehaviorSubject<FinishedGoodArticleItem[]>;
  public mappedArticleItems: SelectItem[];

  public ngOnInit(): void {
    this.articleItemsSubject.subscribe((items) => (this.mappedArticleItems = this.mapArticleItems(items)));
  }

  public mapArticleItems(articleItems: FinishedGoodArticleItem[]): SelectItem[] {
    return articleItems.map((item) => ({ label: item.articleName, value: { ...item } }));
  }

  public changeSelectedArticle(): void {
    this.articleChanged.emit(this.selectedArticleItem);
  }
}
