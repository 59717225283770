/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for WebSocketClient services
 */
@Injectable({
  providedIn: 'root'
})
export class WebSocketClientConfiguration {
  hubUrl: string = '';
}

export interface WebSocketClientConfigurationInterface {
  hubUrl?: string;
}
