import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lib-pallet-number',
  templateUrl: './pallet-number.component.html',
  styleUrls: ['./pallet-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalletNumberComponent {
  @Input() public palletNumber: number;
  @Input() public classification: string;
  @Input() public useContainer = false;
}
