<section class="chronos-dialog--size-xs">
  <div class="question">{{ question }}</div>
  <div class="sub-question" *ngIf="subQuestion">{{ subQuestion }}</div>
  <div *ngIf="!hideFooter">
    <lib-modal-footer
      [submitLabel]="acceptLabel"
      [declineLabel]="rejectLabel"
      (submitClicked)="acceptModal()"
      (declineClicked)="rejectModal()"
      [isFooterButtonSwapped]="isFooterButtonSwapped"
    ></lib-modal-footer>
  </div>
</section>
