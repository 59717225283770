/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { WebSocketClientService } from './web-socket-client.service';
import { WebSocketClientConfigurationInterface, WebSocketClientConfiguration } from './web-socket-client-configuration';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [WebSocketClientService]
})
export class WebSocketClientModule {
  static forRoot(customParams: WebSocketClientConfigurationInterface): ModuleWithProviders<WebSocketClientModule> {
    return {
      ngModule: WebSocketClientModule,
      providers: [
        {
          provide: WebSocketClientConfiguration,
          useValue: { hubUrl: customParams.hubUrl }
        }
      ]
    };
  }
}
