import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ActiveEmployeeRegistration,
  ActiveEmployeeRegistrationData,
  EmployeeRegistrationLoginDetails,
  EmployeeRole,
  WebSocketClientService
} from 'chronos-core-client';
import { EmployeeRegistrationDsService } from '@app/core/data-services';
import { HubUrlsService } from '@app/core/utils';
import { map, tap } from 'rxjs/operators';
import { ListValue } from 'chronos-shared';
import { EmployeeService } from '@app/core/global-state/employees/employee.service';
import moment from 'moment-mini';

@Injectable()
export class EmployeeRegistrationDetailsService {
  public enableCancelSubject = new BehaviorSubject<boolean>(true);
  public enableCancelSubject$: Observable<boolean> = this.enableCancelSubject.asObservable();

  public setFocusSubject = new BehaviorSubject<boolean>(true);
  public setFocusSubject$: Observable<boolean> = this.setFocusSubject.asObservable();

  constructor(
    private employeeRegistrationDsService: EmployeeRegistrationDsService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private employeeService: EmployeeService
  ) {}

  public getActiveLogin(workCenterId: number): Observable<ActiveEmployeeRegistration> {
    return this.employeeRegistrationDsService.getActiveLogin(workCenterId);
  }

  public getLoginDetails(workCenterId: number, employeeId: number): Observable<EmployeeRegistrationLoginDetails> {
    return this.employeeRegistrationDsService.getLoginDetails(workCenterId, employeeId);
  }

  public getEmployeeListNotifications(workCenterId: number): Observable<ActiveEmployeeRegistrationData> {
    return this.webSocketClientService.getNotificationsForTopic(this.hubUrlsService.getEmployeeRegistrationDetails(workCenterId)).pipe(
      tap((data: ActiveEmployeeRegistrationData) => {
        this.employeeService.updateEmployees(data);
      })
    );
  }

  public getEmployeeHistoryNotifications(workCenterId: number): Observable<any> {
    return this.webSocketClientService.getNotificationsForTopic(this.hubUrlsService.getWorkCenterLoginChanged(workCenterId));
  }

  public employeeLogin(
    employeeId: number,
    loginTime: string,
    note?: string,
    registrationPurpose?: string,
    role?: EmployeeRole,
    workCenterId?: number
  ): Observable<null> {
    return this.employeeRegistrationDsService.employeeLogin(employeeId, loginTime, note, registrationPurpose, role, workCenterId);
  }

  public logOut(employeeId: number, workCenterId: number): Observable<null> {
    return this.employeeRegistrationDsService.employeeLogout(employeeId, workCenterId);
  }

  public updateRegistration(loggedInEmployeeRegistration: ActiveEmployeeRegistration, workCenterId: number): Observable<null> {
    return this.employeeRegistrationDsService.updateRegistration(
      loggedInEmployeeRegistration.id,
      loggedInEmployeeRegistration.note,
      loggedInEmployeeRegistration.role,
      workCenterId
    );
  }

  public getShiftsForFilter(workCenterId: number, inputShiftId: number, approvalReportDate: Date): Observable<ListValue[]> {
    if (inputShiftId > 0) {
      return this.employeeRegistrationDsService
        .getShiftsForFilterShiftReport(workCenterId, inputShiftId)
        .pipe(map((filter) => filter.map(({ id, shiftTypeName }) => ({ label: `${shiftTypeName}`, value: id }))));
    } else if (approvalReportDate != null) {
      return this.employeeRegistrationDsService
        .getShiftsForFilterApproval(workCenterId, moment(approvalReportDate).format('YYYY-MM-DD'))
        .pipe(map((filter) => filter.map(({ id, shiftTypeName }) => ({ label: `${shiftTypeName}`, value: id }))));
    } else {
      return this.employeeRegistrationDsService
        .getShiftsForFilter(workCenterId)
        .pipe(map((filter) => filter.map(({ id, shiftTypeName }) => ({ label: `${shiftTypeName}`, value: id }))));
    }
  }
}
