import { AfterContentInit, Component, forwardRef, Input, OnInit, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SecondaryPalletQuantityCalculationComponent),
  multi: true
};

@Component({
  selector: 'app-secondary-pallet-quantity-calculation',
  templateUrl: './secondary-pallet-quantity-calculation.component.html',
  styleUrls: ['./secondary-pallet-quantity-calculation.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class SecondaryPalletQuantityCalculationComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  @Input() public formUnitLabel = '';
  @Input() public bomUnitFactor = 0;
  @Input() public inventoryUnitId = '';
  @Input() public containerUnit = '';
  @Input() public hasWeightEnabled = false;
  @Input() public allowQuantityChange = true;

  constructor() {}

  public readonly INPUT_STYLE = {
    width: '100px',
    textAlign: 'right'
  };

  public formUnitKg = 0;
  public formUnitM = 0;

  private initialValue = 0;
  private propagateChanged?: (value: number) => void;
  private propagateTouched?: () => void;

  public ngOnInit(): void {
    this.hasWeightEnabled = this.isWeightEnabled();
  }

  public ngAfterContentInit(): void {
    this.initialValue = this.formUnitM;
  }

  public registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public writeValue(value: number): void {
    this.countMeters(value);
  }

  public countMeters(value = 0): void {
    if (this.hasWeightEnabled) {
      const kilograms = value / this.bomUnitFactor;

      this.setQuantities(value, kilograms);
    } else {
      this.setQuantities(value, 0);
    }
  }

  public countKilograms(value = 0): void {
    const meters = value * this.bomUnitFactor;

    this.setQuantities(meters, value);
  }

  private roundTo2DecimalPlaces(numberToRound: number): number {
    return Math.round(numberToRound * 100) / 100;
  }

  private setQuantities(meters: number, kilograms: number): void {
    this.formUnitM = this.roundTo2DecimalPlaces(meters);
    this.formUnitKg = this.roundTo2DecimalPlaces(kilograms);

    if (this.propagateChanged) {
      this.propagateChanged(this.formUnitM);
    }
  }

  public resetQuantities(): void {
    this.countMeters(this.initialValue);
  }

  public isWeightEnabled(): boolean {
    return this.hasWeightEnabled && this.bomUnitFactor && !!this.inventoryUnitId;
  }
}
