import { Component, Input } from '@angular/core';
import { DismountingPalletInformation } from '../../dismounting/models/dismouting-pallet-information.model';

@Component({
  selector: 'lib-dismounting-details',
  templateUrl: './dismounting-details.component.html',
  styleUrls: ['./dismounting-details.component.scss']
})
export class DismountingDetailsComponent {
  @Input() public dismountingInfo: DismountingPalletInformation;
}
