/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PandaApiConfiguration, PandaApiConfigurationParams } from './panda-api-configuration';

import { MeasurementService } from './services/measurement.service';
import { SignalService } from './services/signal.service';
import { TelemetryService } from './services/telemetry.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [MeasurementService, SignalService, TelemetryService, PandaApiConfiguration]
})
export class PandaApiModule {
  static forRoot(params: PandaApiConfigurationParams): ModuleWithProviders<PandaApiModule> {
    return {
      ngModule: PandaApiModule,
      providers: [
        {
          provide: PandaApiConfiguration,
          useValue: params
        }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: PandaApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('PandaApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
