import { Quantity } from 'chronos-core-client';

export enum StateTypes {
  Run = 'Run',
  Setup = 'Setup',
  Downtime = 'Downtime',
  Idle = 'Idle'
}

export enum StatusValues {
  OK = 'OK',
  NOK = 'NOK',
  WOK = 'WOK'
}

export enum SpeedMonitorViewMode {
  FullScreen = 'FullScreen',
  Small = 'Small',
  Medium = 'Medium'
}

export interface WorkCenterDetails {
  workCenterId?: string;
  workCenterName?: string;
  state?: string;
  status?: string;
  targetText?: string;
  downtimeData?: {
    code?: string;
    description?: string;
    startTime?: string;
    estimatedDuration?: string;
  };
  runData?: {
    unit?: string;
    speed?: number;
    targetSpeed?: number;
  };
  setupData?: {
    startTime?: string;
    estimatedDuration?: string;
  };
  idleData?: {
    startTime?: string;
  };
  infoData?: {
    message?: string;
    displayIntervalSeconds?: number;
    displayDurationSeconds?: number;
  };
  serverUtcTime?: string;
  deltaTime?: number;
  numberOfStoppersFromCurrentShift?: number;
  tasksByCategory?: TasksByCategory[];
}

export class ValuePropertyBag {
  public speed?: Quantity;
  public targetSpeed?: Quantity;
  public unit?: string;
  public reasonCode?: string;
  public reasonText?: string;
  public duration?: number;
  public estimatedDuration?: string;
  public mode?: SpeedMonitorViewMode;
  public kpiValueList?: KPI[];
  public displayTime?: number;
}

export class KPIValueBag {
  public kpiValue?: KPI[];
}

export class TasksByCategory {
  public type?: string;
  public count?: number;
}

export class WorkCenter {
  public id: number;
  public externalWorkCenterId: string;
  public name: string;
}

export interface KPIInfo {
  id?: string;
  name?: string;
}

export interface KPIInfoWithOrder extends KPIInfo {
  hidden?: boolean;
  order?: number;
}

export interface KPI {
  id?: string;
  name?: string;
  value?: number;
  valueTime?: string;
  order?: number;
}
