/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SetForwardedWasteEntry } from '../models/set-forwarded-waste-entry';
import { PrintBobbinCoreLabelEntry } from '../models/print-bobbin-core-label-entry';
import { ChangeProducedMaterialPrintInfoEntry } from '../models/change-produced-material-print-info-entry';
@Injectable({
  providedIn: 'root'
})
class ProducedMaterialService extends __BaseService {
  static readonly setForwardedWastePath = '/api/v1/ProducedMaterial/{producedMaterialId}/SetForwardedWaste';
  static readonly printBobbinCoreLabelPath = '/api/v1/ProducedMaterial/{producedMaterialId}/PrintBobbinCoreLabel';
  static readonly changeProducedMaterialPrintInfoPath = '/api/v1/ProducedMaterial/{producedMaterialId}/ChangeProducedMaterialPrintInfo';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `ProducedMaterialService.SetForwardedWasteParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  setForwardedWasteResponse(params: ProducedMaterialService.SetForwardedWasteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProducedMaterial/${encodeURIComponent(String(params.producedMaterialId))}/SetForwardedWaste`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProducedMaterialService.SetForwardedWasteParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  setForwardedWaste(params: ProducedMaterialService.SetForwardedWasteParams): __Observable<null> {
    return this.setForwardedWasteResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProducedMaterialService.PrintBobbinCoreLabelParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  printBobbinCoreLabelResponse(params: ProducedMaterialService.PrintBobbinCoreLabelParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProducedMaterial/${encodeURIComponent(String(params.producedMaterialId))}/PrintBobbinCoreLabel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProducedMaterialService.PrintBobbinCoreLabelParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  printBobbinCoreLabel(params: ProducedMaterialService.PrintBobbinCoreLabelParams): __Observable<null> {
    return this.printBobbinCoreLabelResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ProducedMaterialService.ChangeProducedMaterialPrintInfoParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  changeProducedMaterialPrintInfoResponse(
    params: ProducedMaterialService.ChangeProducedMaterialPrintInfoParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ProducedMaterial/${encodeURIComponent(String(params.producedMaterialId))}/ChangeProducedMaterialPrintInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProducedMaterialService.ChangeProducedMaterialPrintInfoParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  changeProducedMaterialPrintInfo(params: ProducedMaterialService.ChangeProducedMaterialPrintInfoParams): __Observable<null> {
    return this.changeProducedMaterialPrintInfoResponse(params).pipe(__map((_r) => _r.body as null));
  }
}

module ProducedMaterialService {
  /**
   * Parameters for SetForwardedWaste
   */
  export interface SetForwardedWasteParams {
    producedMaterialId: number;
    entry: SetForwardedWasteEntry;
  }

  /**
   * Parameters for PrintBobbinCoreLabel
   */
  export interface PrintBobbinCoreLabelParams {
    producedMaterialId: number;
    entry: PrintBobbinCoreLabelEntry;
  }

  /**
   * Parameters for ChangeProducedMaterialPrintInfo
   */
  export interface ChangeProducedMaterialPrintInfoParams {
    producedMaterialId: number;
    entry: ChangeProducedMaterialPrintInfoEntry;
  }
}

export { ProducedMaterialService };
