/* tslint:disable */
type NonConformanceStatus = 'Accepted' | 'Preliminary';
module NonConformanceStatus {
  export const ACCEPTED: NonConformanceStatus = 'Accepted';
  export const PRELIMINARY: NonConformanceStatus = 'Preliminary';
  export function values(): NonConformanceStatus[] {
    return [ACCEPTED, PRELIMINARY];
  }
}

export { NonConformanceStatus };
