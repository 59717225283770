import { Injectable } from '@angular/core';
import { GroupedKpiValue } from '@app/shared/models';
import * as R from 'ramda';
import { KpiValue } from 'chronos-core-client';

@Injectable()
export class OverviewModalService {
  public groupKpis(kpis: KpiValue[]): GroupedKpiValue {
    return R.groupBy((kpi: KpiValue) => kpi.category)(kpis);
  }
}
