<div class="log-messages-modal">
  <ng-container *ngIf="logMessages?.length > 0; else noMessages">
    <div
      *ngFor="let message of logMessages.slice().reverse()"
      class="log-message"
      [ngClass]="{
        'log-message--success': message.level === LOG_LEVEL.Success,
        'log-message--warning': message.level === LOG_LEVEL.Warn,
        'log-message--error': message.level === LOG_LEVEL.Error
      }"
    >
      <div class="log-message__time">{{ message.entryDate | date: 'dd.MM.yyyy HH:mm' }}</div>
      <div class="log-message__container">
        <lib-log-message [message]="message"></lib-log-message>
      </div>
    </div>
  </ng-container>
  <ng-template #noMessages>
    <div class="log-message log-message__container">{{ 'INFO_LOG.NO_MESSAGES' | translate }}</div>
  </ng-template>
</div>
<lib-modal-footer (submitClicked)="closeModal()" [showDeclineButton]="false" [submitLabel]="'MODAL_FOOTER.CONFIRM'"></lib-modal-footer>
