import { LogLevel, SerializedBackendError, SerializedBackendWarning } from './models';
import * as R from 'ramda';

export class LogEntry {
  public entryDate: Date = new Date();
  public message = '';
  public level: LogLevel = LogLevel.Debug;
  public extraInfo: any[] = [];
  public logWithDate = true;
  public serializedBackendWarning: SerializedBackendWarning;
  public serializedBackendError: SerializedBackendError;
  public internalWorkCenterId: number;
  public internalPileTurnerId: number;
  public apiUrl: string;

  private readonly BACKEND_WARNING_CODE = 400;
  private readonly BACKEND_ERROR_CODE = 500;

  public buildLogString(): string {
    let logString = '';

    if (this.logWithDate) {
      logString = `${new Date().toISOString()}\n`;
    }
    logString += `Level: ${LogLevel[this.level]}`;
    logString += `\nMessage: ${this.message}`;
    if (this.extraInfo.length) {
      logString += `\nExtra Info: ${this.formatParams(this.extraInfo)}`;
    }

    return logString;
  }

  public serializeBackendWarning(params: any[]): SerializedBackendWarning {
    if (params[0]?.status !== this.BACKEND_WARNING_CODE) {
      return null;
    } else {
      try {
        return new SerializedBackendWarning({ ...params[0].error });
      } catch (e) {
        return null;
      }
    }
  }

  public serializeBackendError(params: any[]): SerializedBackendError {
    if (params[0]?.status !== this.BACKEND_ERROR_CODE) {
      return null;
    } else {
      try {
        return new SerializedBackendError({ ...params[0].error });
      } catch (e) {
        return null;
      }
    }
  }

  public extractParameter(params: any[], parameterName: string): string {
    return R.prop(parameterName)(params.find((param) => R.prop(parameterName, param)));
  }

  private formatParams(params: any[]): string {
    let paramsString: string = params.join(',');

    if (params.some((p) => typeof p === 'object')) {
      paramsString = '';
      for (const item of params) {
        paramsString += `${JSON.stringify(item)},`;
      }
    }

    return paramsString;
  }
}
