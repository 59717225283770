/* tslint:disable */
type DowntimeType = 'Downtime' | 'Stopper' | 'Wash' | 'SetupTime';
module DowntimeType {
  export const DOWNTIME: DowntimeType = 'Downtime';
  export const STOPPER: DowntimeType = 'Stopper';
  export const WASH: DowntimeType = 'Wash';
  export const SETUP_TIME: DowntimeType = 'SetupTime';
  export function values(): DowntimeType[] {
    return [DOWNTIME, STOPPER, WASH, SETUP_TIME];
  }
}

export { DowntimeType };
