import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { ToolStockModalService } from '@app/modules/tools/services/tool-stock-modal/tool-stock-modal.service';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { finalize } from 'rxjs/operators';
import { MaterialAvailableAtLocation, MaterialAvailableAtLocationItem } from 'chronos-core-client';

@Component({
  selector: 'app-tool-stock-modal',
  templateUrl: './tool-stock-modal.component.html',
  styleUrls: ['./tool-stock-modal.component.scss']
})
export class ToolStockModalComponent implements OnInit {
  public secondaryMaterial$: Observable<MaterialAvailableAtLocation>;

  private readonly REPLACE_TOOL: boolean;
  public readonly LOADING_TOPIC: string = notificationTopic.modalToolStock;
  public tabName = '';
  public isDropDownOptionDisabled: boolean;

  constructor(private toolStockModalService: ToolStockModalService, private ref: DynamicDialogRef, private conf: DynamicDialogConfig) {
    this.REPLACE_TOOL = this.conf.data.replaceTool || false;
  }

  public ngOnInit(): void {
    this.tabName = this.conf.data?.tabName;
    this.isDropDownOptionDisabled = this.conf.data?.isDropDownOptionDisabled;
    this.secondaryMaterial$ = this.toolStockModalService.getAvailableSecondaryMaterialsWithAlternate(this.conf.data.articleId);
  }

  public onConsumeClick([selectedMaterial]: [MaterialAvailableAtLocationItem, number, number]): void {
    let action;

    if (this.REPLACE_TOOL) {
      const mountedMaterialId = this.conf.data.mountedMaterialId;
      const virtualContainerId = this.conf.data.containerId;

      action = this.toolStockModalService.replaceVirtualTool(mountedMaterialId, virtualContainerId, selectedMaterial.containerId);
    } else {
      const materialBlockId = this.conf.data.materialBlockId;
      action = this.toolStockModalService.mountToolForActiveOrder(
        selectedMaterial.identificationCode,
        selectedMaterial.containerId,
        materialBlockId
      );
    }

    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    action
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.MATERIAL_MOUNTED');
        this.ref.close({ materialDataChanged: true });
      });
  }
}
