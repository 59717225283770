/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EditRemarkEntry } from '../models/edit-remark-entry';
import { Remark } from '../models/remark';
@Injectable({
  providedIn: 'root'
})
class RemarkService extends __BaseService {
  static readonly editRemarkPath = '/api/v1/Remark/{remarkId}';
  static readonly getRemarkPath = '/api/v1/Remark/{remarkId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `RemarkService.EditRemarkParams` containing the following parameters:
   *
   * - `remarkId`:
   *
   * - `entry`:
   */
  editRemarkResponse(params: RemarkService.EditRemarkParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/v1/Remark/${encodeURIComponent(String(params.remarkId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RemarkService.EditRemarkParams` containing the following parameters:
   *
   * - `remarkId`:
   *
   * - `entry`:
   */
  editRemark(params: RemarkService.EditRemarkParams): __Observable<null> {
    return this.editRemarkResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param remarkId undefined
   */
  getRemarkResponse(remarkId: number): __Observable<__StrictHttpResponse<Remark>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Remark/${encodeURIComponent(String(remarkId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Remark>;
      })
    );
  }
  /**
   * @param remarkId undefined
   */
  getRemark(remarkId: number): __Observable<Remark> {
    return this.getRemarkResponse(remarkId).pipe(__map((_r) => _r.body as Remark));
  }
}

module RemarkService {
  /**
   * Parameters for EditRemark
   */
  export interface EditRemarkParams {
    remarkId: number;
    entry: EditRemarkEntry;
  }
}

export { RemarkService };
