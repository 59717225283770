import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { OuterInfo } from '@app/shared/models';
import { BobbinPrintLabel } from '@app/shared/models/bobbin-print-label';
import { PrintLabelForOuterEntry, OuterLabelType } from 'chronos-core-client';

@Component({
  selector: 'app-print-label',
  templateUrl: './print-label.component.html',
  styleUrls: ['./print-label.component.scss']
})
export class PrintLabelComponent implements OnInit, OnChanges {
  @Input() public printerName: string;
  @Input() public printedLabels: number;
  @Input() public labelsTotal: number;
  @Input() public defaultQuantityPerOuter: number;
  @Input() public quantityPerOuter: number;
  @Input() public collapsible = false;
  @Input() public numberOfCopies?: number;
  @Input() public labelType?: OuterLabelType;
  @Input() public isQuantityPerOuterValidationRequired: boolean;
  @Input() public isBobbin? = false;
  @Input() public producedMaterialId?: number;
  @Input() public isPalletFinished?: boolean;
  @Input() public slitCount?: number;
  @Input() public palletQuantity?: number;
  @Output() public labelPrintButtonClicked = new EventEmitter<PrintLabelForOuterEntry>();
  @Output() public labelPrintBobbinButtonClicked = new EventEmitter<BobbinPrintLabel>();
  @Output() public quantityPerOuterChanged = new EventEmitter<OuterInfo>();
  @Output() public numberOfCopiesChanged = new EventEmitter<OuterInfo>();

  public isCollapsed: boolean;
  public isQuantityPerOuterValid = false;

  public ngOnInit(): void {
    this.isCollapsed = this.collapsible;
    this.checkQuantityPerOuterValidation();

    if (this.isBobbin) {
      this.printCalculationForBobbin();
    }
  }

  public checkQuantityPerOuterValidation(info?: OuterInfo) {
    if (this.isQuantityPerOuterValidationRequired) {
      const isInfoQuantityValid = info?.quantityPerOuter ?? 0 > 0 ? true : false;
      const isThisQuantityValid = this.quantityPerOuter ?? 0 > 0 ? true : false;
      this.isQuantityPerOuterValid = isInfoQuantityValid || isThisQuantityValid;
    } else this.isQuantityPerOuterValid = true;
  }

  public ngOnChanges(): void {
    this.checkQuantityPerOuterValidation();

    if (this.isBobbin) this.printCalculationForBobbin();
  }

  public toggleContent(isCollapsed: boolean): void {
    this.isCollapsed = isCollapsed;
  }

  public printLabel(printLabel: PrintLabelForOuterEntry): void {
    this.labelPrintButtonClicked.emit(printLabel);
  }

  public printBobbinLabel(printLabel: BobbinPrintLabel): void {
    this.labelPrintBobbinButtonClicked.emit(printLabel);
  }

  public onQuantityPerOuterChange(info: OuterInfo): void {
    this.quantityPerOuterChanged.emit({
      quantityPerOuter: info.quantityPerOuter,
      numberCopies: info.numberCopies,
      producedMaterialId: this.producedMaterialId
    });
    this.checkQuantityPerOuterValidation(info);
  }

  public onNumberOfCopiesChanged(info: OuterInfo): void {
    this.numberOfCopiesChanged.emit({
      quantityPerOuter: info.quantityPerOuter,
      numberCopies: info.numberCopies,
      producedMaterialId: this.producedMaterialId
    });
  }

  private printCalculationForBobbin() {
    this.labelsTotal = this.getBobbinEstimation();
    this.numberOfCopies = this.numberOfCopies;
    this.defaultQuantityPerOuter = this.defaultQuantityPerOuter;
  }

  public getBobbinEstimation(): number {
    const palletQty = this.palletQuantity;
    const bobbinQty = this.defaultQuantityPerOuter;
    if (typeof palletQty === 'number' && typeof bobbinQty === 'number'
      && typeof this.slitCount === 'number' && bobbinQty !== 0 && this.slitCount !== 0) {
       return Math.ceil(palletQty / bobbinQty) * this.slitCount;
    } else {
        return 0;
    }
  }
}
