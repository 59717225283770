<div class="order-details" *ngIf="orderItem">
  <section class="order-details__section">
    <div class="row">
      <div class="col-3">
        <div class="order-status">
          <div class="order-status__order-code">{{ orderItem.externalProductionOrderId }}</div>
          <div class="order-status__start-time">
            {{ 'MOUNTING.START_TIME' | translate }}: {{ orderItem.plannedStartTime | utcToLocal | date: 'HH:mm' }}
          </div>
        </div>
      </div>

      <div class="col-3">
        <app-status-panel
          [label]="'MOUNTING.ESTIMATED' | translate"
          [value]="orderItem.estimatedQuantity | formatQuantity: false"
          [unitId]="orderItem.estimatedQuantity.unitId"
        ></app-status-panel>
      </div>

      <ng-container *ngIf="isSecondaryMaterialDisplayEnabled; else emptyIndicator">
        <div class="col-3">
          <app-dismount-panel
            [count]="secondaryMaterialToDismountCount$ | async"
            [label]="'MOUNTING.SECONDARY_MATERIAL'"
            (clicked)="onDismountSecondaryMaterial()"
          ></app-dismount-panel>
        </div>
      </ng-container>

      <ng-container *ngIf="showInactive; else emptyIndicator">
        <div class="col-3">
          <app-status-panel [label]="'MOUNTING.INDICATOR_B' | translate" [value]="3" [unitId]="'min'"></app-status-panel>
        </div>
      </ng-container>

      <ng-template #emptyIndicator>
        <div class="col-3">
          <app-status-panel></app-status-panel>
        </div>
      </ng-template>
    </div>
  </section>

  <section class="order-details__section">
    <div class="row row--end">
      <div class="col-7">
        <lib-scan-container
          [validationChanges$]="scanValidationChanges$"
          (mountButtonClicked)="mountContainer($event)"
          [scanPlaceholderText]="'MOUNTING.SCAN_INPUT_PLACEHOLDER' | translate"
          [buttonLabel]="'MOUNTING.MOUNT' | translate"
          [selectInputOnEnter]="true"
          [capsLockWarningMessage]="'MOUNTING.CAPSLOCK_ON_WARNING' | translate"
        ></lib-scan-container>
      </div>
      <div class="col-5">
        <lib-pallet-indicator
          [title]="'MOUNTING.MACHINE_LOCATION' | translate"
          [palletCount]="totalContainers"
          (clicked)="openMachineLocationModal()"
        ></lib-pallet-indicator>
      </div>
    </div>
  </section>

  <section class="order-details__section">
    <app-bill-of-material [showInactive]="showInactive" [billOfMaterials]="billOfMaterials"></app-bill-of-material>
  </section>

  <ng-container *ngIf="showInactive">
    <section class="order-details__section document-grid">
      <div class="row">
        <div class="col-2">
          <div class="document-grid__item">
            <i class="document-grid__item-icon icon-pdf-outline"></i>
            <div class="document-grid__item-title">Infodoc-1.pdf</div>
          </div>
        </div>

        <div class="col-2">
          <div class="document-grid__item">
            <i class="document-grid__item-icon icon-document-outline"></i>
            <div class="document-grid__item-title">Infodoc-1.pdf</div>
          </div>
        </div>

        <div class="col-2">
          <div class="document-grid__item">
            <i class="document-grid__item-icon icon-document-outline"></i>
            <div class="document-grid__item-title">Infodoc-1.pdf</div>
          </div>
        </div>

        <div class="col-2">
          <div class="document-grid__item">
            <i class="document-grid__item-icon icon-pdf-outline"></i>
            <div class="document-grid__item-title">Infodoc-1.pdf</div>
          </div>
        </div>

        <div class="col-2">
          <div class="document-grid__item">
            <i class="document-grid__item-icon icon-document-outline"></i>
            <div class="document-grid__item-title">Infodoc-1.pdf</div>
          </div>
        </div>

        <div class="col-2">
          <div class="document-grid__item">
            <i class="document-grid__item-icon icon-document-outline"></i>
            <div class="document-grid__item-title">Infodoc-1.pdf</div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
