export enum TraceabilitySearchModeOption {
  ForwardSearch = 'forwardSearch',
  BackwardSearch = 'backwardSearch'
}

export enum TraceabilityTableRowStyleClass {
  FIRST_LEVEL = 'first-level-row',
  SECOND_LEVEL = 'second-level-row',
  THIRD_LEVEL = 'third-level-row',
  FOURTH_LEVEL = 'fourth-level-row'
}

export enum ForwardSearchResultChildItems {
  ProductionOrder = 'productionOrder',
  Input = 'input',
  InputDetail = 'inputDetail',
  FinishedGood = 'finishedGood',
  FinishedGoodDetail = 'finishedGoodDetail',
  ProductionOrderDetails = 'productionOrderDetails',
  ProductionOrderDetailsTrace = 'productionOrderDetailsTrace'
}
