<section class="chronos-dialog--size-sm">
  <div [formGroup]="reportForm" class="alert-modal">
    <ng-container *ngIf="isAlert">
      <div class="alert-modal__warning-label">
        <i class="icon-warning-solid"></i>
        <span>{{ 'SHIFT_REPORT.ALERT_MESSAGE' | translate }}</span>
      </div>
    </ng-container>
    <div class="alert-modal__comment" [ngClass]="{ 'alert-modal__comment--attention': reportForm.controls.comment.invalid }">
      <span>{{ commentLabel | translate }}</span>
      <textarea
        formControlName="comment"
        maxlength="250"
        class="alert-modal__comment-text"
        [rows]="5"
        [cols]="55"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <lib-modal-footer
    [submitLabel]="submitLabel"
    [submitDisabled]="reportForm.invalid"
    (submitClicked)="openConfirmModal()"
    (declineClicked)="closeModal(true)"
  ></lib-modal-footer>
</section>
