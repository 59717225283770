import { Injectable } from '@angular/core';
import { ShiftReportService } from '../shift-report';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ShiftReportItem } from '@app/core/global-state';
import { OverviewModalComponent, SendReportModalComponent } from '@app/modules/shift-report/modals';
import { ApproveCommandCallback } from '@app/shared/components';

@Injectable()
export class ShiftReportModalService {
  constructor(
    private shiftReportService: ShiftReportService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  public openSendReportModal(isAlert: boolean, commandCallback: ApproveCommandCallback) {
    const header = isAlert ? 'SHIFT_REPORT.ALERT' : 'SHIFT_REPORT.SHIFT_COMMENT';
    const shiftId = this.shiftReportService.getSelectedShiftId();

    this.dialogService.open(SendReportModalComponent, {
      header: this.translateService.instant(header),
      data: {
        shiftId,
        alert: isAlert,
        printReport: false,
        commandCallback
      }
    });
  }

  public viewSendReportModal(): void {
    const remarkId = this.shiftReportService.getShiftRemarkId();
    const shiftId = this.shiftReportService.getSelectedShiftId();

    this.shiftReportService.getShiftRemark(remarkId).subscribe((remark) => {
      this.dialogService.open(SendReportModalComponent, {
        header: this.translateService.instant('SHIFT_REPORT.SHIFT_COMMENT'),
        data: {
          shiftId,
          comment: remark.remarkText,
          remarkId: remark.id,
          printReport: false
        }
      });
    });
  }

  public openOverviewModal(shiftReportItem: ShiftReportItem): void {
    this.dialogService.open(OverviewModalComponent, {
      header: this.translateService.instant('SHIFT_REPORT.OVERVIEW'),
      data: { shiftReportItem }
    });
  }
}
