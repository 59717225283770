import { Injectable } from '@angular/core';
import { Observable as __Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { OrganizationsService } from '../api/services/organizations.service';
import { OrganizationOr } from '../api/models/organization-or';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsCachedService {
  private organizations: { [orid: number]: __Observable<Array<OrganizationOr>> } = {};
  private tenantList: { [orid: number]: __Observable<Array<OrganizationOr>> } = {};

  constructor(private organizationsService: OrganizationsService) {}

  public getActiveOrganizations(orid: number): __Observable<Array<OrganizationOr>> {
    if (!this.organizations[orid]) {
      this.organizations[orid] = this.organizationsService.getActiveOrganizations(orid).pipe(shareReplay(1));
    }
    return this.organizations[orid];
  }

  public getTenantList(orid: number): __Observable<Array<OrganizationOr>> {
    if (!this.tenantList[orid]) {
      this.tenantList[orid] = this.organizationsService.getTenantList(orid).pipe(shareReplay(1));
    }
    return this.tenantList[orid];
  }

  public clearOrganizationsCache() {
    this.organizations = {};
  }

  public clearTenantListCache() {
    this.tenantList = {};
  }

  public clearAllCache() {
    this.clearOrganizationsCache();
    this.clearTenantListCache();
  }

  public getActiveOrganizationsForUser(orid: number): __Observable<Array<OrganizationOr>> {
    if (!this.organizations[orid]) {
      this.organizations[orid] = this.organizationsService.getActiveOrganizationsForUser(orid).pipe(shareReplay(1));
    }
    return this.organizations[orid];
  }
}
