import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EmployeeRegistrationHistoryQuery } from '@app/core/global-state/employee-registration-history/employee-registration-history.query';
import { EmployeeRegistrationHistoryService } from '@app/core/global-state/employee-registration-history/employee-registration-history.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { EmployeeRegistrationDetailsService } from '@app/modules/employee-registration/services';
import { EmployeeRegistrationHistory } from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-employee-registration-history',
  templateUrl: './employee-registration-history.component.html',
  styleUrls: ['./employee-registration-history.component.scss']
})
export class EmployeeRegistrationHistoryComponent implements OnInit, OnDestroy {
  public textQuery? = '';
  public selectedShift?;
  private subscriptions = new Subscription();
  private workCenterId: number;
  public shifts$: Observable<ListValue[]>;
  public employeeRegistrationHistory: EmployeeRegistrationHistory[];
  public defaultShift: ListValue = { value: 0, label: 'All' };

  public shiftList$ = new Subject<ListValue[]>();
  public shiftIdList: number[];

  @Input() public inputShiftId: number;

  @Input() public approvalReportDate: Date;

  @Input() public approvalWorkCenterId: number;

  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private employeeRegistrationHistoryService: EmployeeRegistrationHistoryService,
    private employeeRegistrationDetailsService: EmployeeRegistrationDetailsService,
    public employeeRegistrationHistoryQuery: EmployeeRegistrationHistoryQuery
  ) {}

  public ngOnInit(): void {
    this.workCenterId = this.approvalWorkCenterId != null ? this.approvalWorkCenterId : this.activeWorkCenterService.getWorkCenterId();

    this.employeeRegistrationDetailsService
      .getShiftsForFilter(this.workCenterId, this.inputShiftId, this.approvalReportDate)
      .pipe(
        tap((list) => {
          list.splice(0, 0, this.defaultShift);
          this.shiftList$.next(list);
        }),
        tap((list) => {
          this.shiftIdList = list.map((a) => Number(a.value));
          this.employeeRegistrationHistoryService.getHistory(this.workCenterId, this.shiftIdList);
        })
      )
      .subscribe();

    this.subscriptions.add(
      this.employeeRegistrationDetailsService
        .getEmployeeHistoryNotifications(this.workCenterId)
        .pipe(
          tap(() => {
            this.employeeRegistrationHistoryService.getHistory(this.workCenterId, this.shiftIdList);
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.employeeRegistrationHistoryQuery.filters$
        .pipe(
          tap((filters) => {
            this.selectedShift = filters.shift;
            this.textQuery = filters.textQuery;
          })
        )
        .subscribe()
    );
  }

  public onFilterChange(): void {
    this.employeeRegistrationHistoryService.updateFilters({
      shift: this.selectedShift,
      textQuery: this.textQuery
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.employeeRegistrationHistoryService.resetFilters();
  }
}
