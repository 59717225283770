<lib-dismount-container-form
  [dismountingPalletInformation]="dismountingPalletInformation"
  [article]="null"
  [allowQuantityChange]="allowQuantityChange$ | async"
  (returnSheetChanged)="onReturnSheetsChange($event)"
  (printLabelChanged)="onPrintLabelChange($event)"
  (isValid)="onValidityChange($event)"
></lib-dismount-container-form>
<lib-modal-footer
  [submitLabel]="'DISMOUNTING.DISMOUNT'"
  (submitClicked)="dismountContainer()"
  (declineClicked)="onCloseModal()"
  [submitDisabled]="!isFormValid"
  [whenLoadingTopic]="LOADING_TOPIC"
></lib-modal-footer>
