/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for LiveApi services
 */
@Injectable({
  providedIn: 'root'
})
export class LiveApiConfiguration {
  rootUrl: string = '';
}

export interface LiveApiConfigurationInterface {
  rootUrl?: string;
}
