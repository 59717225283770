/* tslint:disable */
type SettingHierarchyType = 'Article' | 'WorkCenter' | 'User';
module SettingHierarchyType {
  export const ARTICLE: SettingHierarchyType = 'Article';
  export const WORK_CENTER: SettingHierarchyType = 'WorkCenter';
  export const USER: SettingHierarchyType = 'User';
  export function values(): SettingHierarchyType[] {
    return [ARTICLE, WORK_CENTER, USER];
  }
}

export { SettingHierarchyType };
