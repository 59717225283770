import { Injectable } from '@angular/core';
import { ShiftReportOpenTasksStore } from './shift-report-open-tasks.store';
import { ShiftReportOpenTask } from '@app/core/global-state';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportOpenTasksService {
  constructor(private shiftReportOpenTasksStore: ShiftReportOpenTasksStore) {}

  public setShiftReportOpenTasks(shiftReportOpenTasks: ShiftReportOpenTask[]): void {
    this.shiftReportOpenTasksStore.set(shiftReportOpenTasks);
  }

  public updateShiftReportOpenTasks(shiftReportOpenTasks: ShiftReportOpenTask[]): void {
    this.shiftReportOpenTasksStore.upsertMany(shiftReportOpenTasks);
  }

  public resetShiftReportOpenTasks(): void {
    this.shiftReportOpenTasksStore.reset();
  }
}
