import { Component, Input } from '@angular/core';
import { KpiValue } from 'chronos-core-client';

@Component({
  selector: 'app-kpi-column',
  templateUrl: './kpi-column.component.html',
  styleUrls: ['./kpi-column.component.scss']
})
export class KpiColumnComponent {
  @Input() public title: string;
  @Input() public kpis: KpiValue[];
  @Input() public kpiColumn: string;
  @Input() public areKpiActionsEnabled = true;

  public trackByFunction(index: number): number {
    return index;
  }
}
