import { Injectable } from '@angular/core';
import { urls } from '@app/shared/utils';
import { ApmService } from '@elastic/apm-rum-angular';
import { ChronosSharedConfiguration, ApiAuthorizationConfig, LiveUrlConfig } from 'chronos-shared';
import { WebSocketClientService } from 'chronos-core-client';
import { BehaviorSubject, Observable } from 'rxjs';

interface AuthConfig {
  authority?: string;
  clientId?: string;
  scope?: string;
  responseType?: string;
}

interface FrontendConfig {
  apm: any;
  pandaRootUrl?: string;
  authentication?: AuthConfig;
  chronosLiveFrontendUrl?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  public loadingIndicatorSubject = new BehaviorSubject<boolean>(false);
  public loadingIndicatorSubject$: Observable<boolean> = this.loadingIndicatorSubject.asObservable();

  constructor(
    private chronosSharedConfiguration: ChronosSharedConfiguration,
    private authConfig: ApiAuthorizationConfig,
    private liveUrlConfig: LiveUrlConfig,
    private webSocketClientService: WebSocketClientService,
    private apmService: ApmService
  ) {}

  public async Init(): Promise<any> {
    // deliberately avoid using Angular HTTP here to not trigger any implicit default configurations
    const frontendConfigResponse = await fetch(urls.getFrontendConfig());

    // when the core service is down, the frontend configuration cannot be fetched
    // give a message to the user to inform about the issue
    if (frontendConfigResponse.status !== 200) {
      document.children[0].innerHTML =
        '<div style="display: grid">' +
        '  <span>Failed to load frontend configuration</span>' +
        '  <span>Please reload the page later!</span>' +
        '  <span>&nbsp;</span>' +
        `  <span>${frontendConfigResponse.status} ${frontendConfigResponse.statusText}</span>` +
        `  <span>URL: ${frontendConfigResponse.url}'</span>` +
        '</div>';

      // reload page every minute to check if the service is back online
      setTimeout(() => {
        window.location.reload();
      }, 60000);
    }

    const frontendConfig: FrontendConfig = await frontendConfigResponse.json();

    // initialize APM first to ensure all errors are properly reported
    this.initializeApm(frontendConfig.apm);

    if (frontendConfig.authentication) {
      this.initializeAuthentication(frontendConfig.authentication);
    }

    this.initializeUrls(frontendConfig);

    await this.webSocketClientService.startConnection();
  }

  private initializeUrls(frontendConfig: FrontendConfig): void {
    if (frontendConfig.pandaRootUrl) {
      this.chronosSharedConfiguration.pandaRootUrl = frontendConfig.pandaRootUrl.replace(/\/$/, '');
    }

    if (frontendConfig?.chronosLiveFrontendUrl) {
      this.liveUrlConfig.url = frontendConfig.chronosLiveFrontendUrl.replace(/\/$/, '');
    }
  }

  private initializeApm(apmConfig: any): void {
    const defaults = {
      enabled: false,
      serviceName: 'chronos-cockpit',
      logLevel: 'warn'
    };
    const config = { ...defaults, ...apmConfig };

    if (config.enabled) {
      this.apmService.init(config);
    }
  }

  private initializeAuthentication(fetchedAuthConfig: AuthConfig): void {
    this.authConfig.authority = fetchedAuthConfig.authority ?? this.authConfig.authority;
    this.authConfig.clientId = fetchedAuthConfig.clientId ?? this.authConfig.clientId;
    this.authConfig.responseType = fetchedAuthConfig.responseType ?? this.authConfig.responseType;
    this.authConfig.scope = fetchedAuthConfig.scope ?? this.authConfig.scope;
  }
}
