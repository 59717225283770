import { Injectable } from '@angular/core';
import { ShiftReportOrdersStore } from './shift-report-orders.store';
import { ShiftReportItem, ShiftReportItemFilters } from './shift-report-orders.model';
import { ShiftReport } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportOrdersService {
  constructor(private shiftReportOrdersStore: ShiftReportOrdersStore) {}

  public setShiftReportOrders(shiftReport: ShiftReport, shiftReportItems: ShiftReportItem[]): void {
    this.shiftReportOrdersStore.set(shiftReportItems);
    this.shiftReportOrdersStore.updateShiftReport(shiftReport);
  }

  public updateShiftReportOrder(shiftReportItem: ShiftReportItem): void {
    this.shiftReportOrdersStore.update(shiftReportItem.id, shiftReportItem);
  }

  public resetShiftReportOrders(): void {
    this.shiftReportOrdersStore.reset();
  }

  public updateFilters(filters: ShiftReportItemFilters): void {
    this.shiftReportOrdersStore.updateFilters(filters);
  }

  public resetFilters(): void {
    this.shiftReportOrdersStore.resetFilters();
  }
}
