import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AvailableEmployee, EmployeeRegistrationLoginDetails, EmployeeRegistrationLoginEntry, EmployeeRole } from 'chronos-core-client';
import { TranslateService } from '@ngx-translate/core';
import { ListValue, LogService, StorageKey, StorageService } from 'chronos-shared';
import { EmployeeRegistrationDetailsService } from '../../services/employee-registration.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-window',
  templateUrl: './login-window.component.html',
  styleUrls: ['./login-window.component.scss']
})
export class LoginWindowComponent implements OnInit, OnDestroy {
  constructor(
    private fb: UntypedFormBuilder,
    private ref: DynamicDialogRef,
    private conf: DynamicDialogConfig,
    private translateService: TranslateService,
    private storageService: StorageService,
    private employeeRegistrationDetailsService: EmployeeRegistrationDetailsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public readonly INPUT_STYLE = { width: '200px' };
  public employeeId: number;
  public workCenterId: number;
  public employeeLoginForm: UntypedFormGroup;
  public name: string;
  public loginTime: string;
  public externalEmployeeId: string;
  public registrationPurpose: string;
  public defaultLanguage: string;
  private subscriptions = new Subscription();
  private readonly STORAGE_KEY = StorageKey.defaultLanguage;

  public roles: ListValue[] = [
    { label: this.translateService.instant('DROPDOWN_DEFAULT_LABEL'), value: null },
    { label: this.translateService.instant('EMPLOYEE_ROLE.OPERATOR'), value: EmployeeRole.OPERATOR },
    { label: this.translateService.instant('EMPLOYEE_ROLE.HELPER'), value: EmployeeRole.HELPER }
  ];

  public ngOnInit(): void {
    const availableEmployee = this.conf.data.employee as AvailableEmployee;
    this.workCenterId = this.activeWorkCenterService.getWorkCenterId();
    this.employeeId = availableEmployee.id;

    this.subscriptions.add(
      this.employeeRegistrationDetailsService.getLoginDetails(this.workCenterId, this.employeeId).subscribe((loginDetails) => {
        this.initModalForm(loginDetails);
      })
    );

    this.defaultLanguage = this.storageService.getItem(this.STORAGE_KEY);

    this.employeeLoginForm = this.fb.group({
      role: [null, Validators.required],
      note: [null]
    });
  }

  public onCloseModal(): void {
    this.ref.close(false);
  }

  public onSubmitModal(): void {
    const emp: EmployeeRegistrationLoginEntry = this.getMappedEmployee();

    this.subscriptions.add(
      this.employeeRegistrationDetailsService
        .employeeLogin(emp.employeeId, emp.loginTime, emp.note, emp.registrationPurpose, emp.role, this.workCenterId)
        .subscribe(() => {
          LogService.success('SUCCESS_MESSAGE.LOGGEDIN_SUCCESSFULLY');
          this.ref.close(true);
        })
    );
  }

  public get role() {
    return this.employeeLoginForm.get('role');
  }

  public get note() {
    return this.employeeLoginForm.get('note');
  }

  private initModalForm(loginDetails: EmployeeRegistrationLoginDetails): void {
    this.externalEmployeeId = loginDetails.externalEmployeeId;
    this.name = loginDetails.name;
    this.loginTime = loginDetails.loginTime;
    this.registrationPurpose = loginDetails.registrationPurpose;
  }

  private getMappedEmployee(): EmployeeRegistrationLoginEntry {
    return {
      employeeId: this.employeeId,
      loginTime: this.loginTime,
      workCenterId: this.workCenterId,
      registrationPurpose: this.registrationPurpose,
      role: this.employeeLoginForm.value.role,
      note: this.employeeLoginForm.value.note
    };
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
