/* tslint:disable */
type ArticleClassification = 'Other' | 'Product' | 'Reel' | 'Sheet' | 'Liquid' | 'Tool';
module ArticleClassification {
  export const OTHER: ArticleClassification = 'Other';
  export const PRODUCT: ArticleClassification = 'Product';
  export const REEL: ArticleClassification = 'Reel';
  export const SHEET: ArticleClassification = 'Sheet';
  export const LIQUID: ArticleClassification = 'Liquid';
  export const TOOL: ArticleClassification = 'Tool';
  export function values(): ArticleClassification[] {
    return [OTHER, PRODUCT, REEL, SHEET, LIQUID, TOOL];
  }
}

export { ArticleClassification };
