import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ActiveOrderDsService } from '../active-order-ds/active-order-ds.service';
import { MachineCounterQuery, MachineCounterService } from '@app/core/global-state';
import * as R from 'ramda';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { CounterInfo, WebSocketClientService } from 'chronos-core-client';
import { MachineCounterSignalR } from '@app/shared/models';
import { HubUrlsService } from '@app/core/utils';
import { WorkCenterService } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class MachineCounterDsService {
  constructor(
    private workCenterService: WorkCenterService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private machineCounterService: MachineCounterService,
    private machineCounterQuery: MachineCounterQuery,
    private activeOrderDsService: ActiveOrderDsService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService
  ) {}

  private getCurrentCounter(): Observable<CounterInfo> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    return this.workCenterService.workCenterGetCurrentCounter(workCenterId);
  }

  public renewMachineSpeed(): Observable<number> {
    return this.getCurrentCounter().pipe(
      tap((machineCounter) => {
        this.updateMachineCounter({ itemsPerHour: machineCounter.itemsPerHour });
      }),
      map((machineCounter) => machineCounter.itemsPerHour)
    );
  }

  public renewMachineCounter(): void {
    this.getCurrentCounter().subscribe((machineCounter) => {
      this.updateMachineCounter({ counter: machineCounter.counter });
    });
  }

  public getMachineSpeedFromStore(): Observable<number> {
    return this.machineCounterQuery.getMachineCounter().pipe(map(R.prop('itemsPerHour')), distinctUntilChanged());
  }

  public getMachineCounterFromStore(): Observable<number> {
    return combineLatest([this.machineCounterQuery.getMachineCounter(), this.activeOrderDsService.selectRunPhaseStartCounter()]).pipe(
      map(([machineCounter, runPhaseStartCounter]) => {
        if (machineCounter?.counter === null || runPhaseStartCounter === null) {
          return null;
        }

        return machineCounter.counter - runPhaseStartCounter;
      })
    );
  }

  private updateMachineCounter(machineCounter: Partial<CounterInfo>) {
    this.machineCounterService.updateMachineCounter(machineCounter);
  }

  public getCounterNotifications(): Observable<CounterInfo> {
    return this.webSocketClientService.getNotificationsForTopic<MachineCounterSignalR>(this.hubUrlsService.getCounterValue()).pipe(
      map((response) => ({ counter: response.counter, itemsPerHour: response.speed } as CounterInfo)),
      tap((machineCounter: CounterInfo) => {
        this.updateMachineCounter(machineCounter);
      })
    );
  }
}
