/* tslint:disable */
type ShiftReportItemType = 'None' | 'Downtime' | 'ProductionOrder';
module ShiftReportItemType {
  export const NONE: ShiftReportItemType = 'None';
  export const DOWNTIME: ShiftReportItemType = 'Downtime';
  export const PRODUCTION_ORDER: ShiftReportItemType = 'ProductionOrder';
  export function values(): ShiftReportItemType[] {
    return [NONE, DOWNTIME, PRODUCTION_ORDER];
  }
}

export { ShiftReportItemType };
