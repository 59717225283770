/* tslint:disable */
type ProductType = 'Other' | 'WIP' | 'RawMaterial' | 'FinishedGood';
module ProductType {
  export const OTHER: ProductType = 'Other';
  export const WIP: ProductType = 'WIP';
  export const RAW_MATERIAL: ProductType = 'RawMaterial';
  export const FINISHED_GOOD: ProductType = 'FinishedGood';
  export function values(): ProductType[] {
    return [OTHER, WIP, RAW_MATERIAL, FINISHED_GOOD];
  }
}

export { ProductType };
