import { Component, OnDestroy, OnInit } from '@angular/core';
import { PeriodicQaCheck, CreateManualQaChecksEntry } from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QaCheckService } from '@app/core/services';
import { Subscription } from 'rxjs';
import { QaCheckFrequencyTypes } from '@app/modules/run-phase/models/qa-check-frequencytype';
import { StorageKey, StorageService } from 'chronos-shared';

@Component({
  selector: 'app-automatic-periodic-qa-check',
  templateUrl: './automatic-periodic-qa-check.component.html',
  styleUrls: ['./automatic-periodic-qa-check.component.scss']
})
export class AutomaticPeriodicQaCheckComponent implements OnInit, OnDestroy {
  public readonly QACHECKFREQUENCY_TYPES = QaCheckFrequencyTypes;
  public automaticPeriodicQaChecks: PeriodicQaCheck = null;
  public isConfirmButtonEnabled = false;
  public manualQaChecksEntry: CreateManualQaChecksEntry;
  public frequencyInMinutes = false;
  public frequencyInProcessingUnit = false;
  public defaultLanguage: string;

  private subscriptions = new Subscription();
  private readonly STORAGE_KEY = StorageKey.defaultLanguage;

  constructor(
    private ref: DynamicDialogRef,
    private conf: DynamicDialogConfig,
    private qaCheckService: QaCheckService,
    private storageService: StorageService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.qaCheckService.getActivePeriodicCheck(this.conf.data.productionOrderId).subscribe((qaChecks) => {
        this.automaticPeriodicQaChecks = qaChecks;
        if (qaChecks.frequencyType === this.QACHECKFREQUENCY_TYPES.Minutes) {
          this.frequencyInMinutes = true;
        }
        if (qaChecks.frequencyType === this.QACHECKFREQUENCY_TYPES.ProcessingUnit) {
          this.frequencyInProcessingUnit = true;
        }
      })
    );

    this.defaultLanguage = this.storageService.getItem(this.STORAGE_KEY);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public submitModal(): void {
    this.ref.close(true);
  }

  public later(): void {
    this.ref.close(false);
  }

  public setConfirmButtonStatus(status: boolean): void {
    this.isConfirmButtonEnabled = status;
  }

  public formatFrequencyType(data: string): string {
    return `FREQUENCY_TYPE.${data.toUpperCase()}`;
  }
}
