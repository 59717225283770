import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-mini';

@Pipe({
  name: 'elapsedTime'
})
export class ElapsedTimePipe implements PipeTransform {
  private readonly DAY_HOUR = 24;

  public transform(value: string, duration = false, showSeconds?: boolean): string {
    if (!value) {
      return '';
    }

    const elapsedTime = moment.duration(value).add(30, 'second'); // Rounding to minutes
    const elapsedTimeWithSeconds = moment.duration(value);
    const days = Math.floor(elapsedTime.asDays());
    const hours = elapsedTime.hours();
    const minutes = elapsedTime.minutes();

    if (duration) {
      return this.calculateDurationTime(days, hours, minutes);
    } else if (showSeconds) {
      return this.calculateElapsedTime(elapsedTimeWithSeconds, showSeconds);
    } else {
      return this.calculateElapsedTime(elapsedTime, false);
    }
  }

  private calculateDurationTime(days: number, hours: number, minutes: number): string {
    const totalHours = days * this.DAY_HOUR + hours;
    const totalMinutes = minutes;

    let displayHours = totalHours.toString();
    let displayMinutes = totalMinutes.toString();

    if (totalHours < 10) {
      displayHours = `0${totalHours}`;
    }

    if (totalMinutes < 10) {
      displayMinutes = `0${totalMinutes}`;
    }

    return `${displayHours}:${displayMinutes}`;
  }

  private calculateElapsedTime(time: moment.Duration, showSeconds: boolean): string {
    const days = Math.floor(time.asDays());
    const hours = time.hours();
    const minutes = time.minutes();
    const seconds = time.seconds();

    let elapsedTimeText = '';

    if (days) {
      elapsedTimeText += `${days} d `;
    }

    if (hours) {
      elapsedTimeText += `${hours} h `;
    }

    if (showSeconds) {
      elapsedTimeText += `${minutes} min `;
      return `${elapsedTimeText}${seconds} s`;
    } else {
      return `${elapsedTimeText}${minutes} min`;
    }
  }
}
