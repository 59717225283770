import { Injectable } from '@angular/core';
import { Observable, Observable as __Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import { WorkCenterService } from '../api/services/work-center.service';
import { WorkCenter } from '../api/models/work-center';
import { WorkCenterMachineData } from '../api/models/work-center-machine-data';
import { WorkCenterData } from '../api/models/work-center-data';

@Injectable({
  providedIn: 'root'
})
export class WorkCenterCachedService {
  private workCenterList: __Observable<Array<WorkCenterData>>;
  private workCenterMachineDataList: __Observable<Array<WorkCenterMachineData>>;
  private workCenters: { [key: number]: __Observable<WorkCenter> } = {};

  constructor(private workCenterService: WorkCenterService) {}

  public workCenterGetWorkCenter(workCenterId: number): __Observable<WorkCenter> {
    if (!this.workCenters[workCenterId]) {
      this.workCenters[workCenterId] = this.workCenterService.getWorkCenter(workCenterId).pipe(shareReplay(1));
    }
    return this.workCenters[workCenterId];
  }

  public preloadWorkCenters(workCenterIds: Array<number>): Observable<any> {
    const keys = Object.keys(this.workCenters).map(Number);
    const difference = workCenterIds.filter((x) => !keys.includes(x));
    console.info('Already preloaded WorkCenters: ', keys);
    console.info('Preloading WorkCenters for following ids: ', difference);

    return this.workCenterService.getWorkCenters(difference).pipe(
      tap((workCenters) => {
        this.setWorkCenters(workCenters);
      })
    );
  }

  private setWorkCenters(workCenters: Array<WorkCenter>) {
    workCenters.forEach((wc) => (this.workCenters[wc.id] = of(wc)));
  }

  public workCenterGetWorkCenterList(): __Observable<Array<WorkCenterData>> {
    if (!this.workCenterList) {
      this.workCenterList = this.workCenterService.getWorkCenterList().pipe(shareReplay(1));
    }
    return this.workCenterList;
  }

  public getWorkCenterMachineDataList(): __Observable<Array<WorkCenterMachineData>> {
    if (!this.workCenterMachineDataList) {
      this.workCenterMachineDataList = this.workCenterService.getWorkCenterMachineDataList().pipe(shareReplay(1));
    }
    return this.workCenterMachineDataList;
  }

  public clearWorkCenterListCache() {
    this.workCenterList = null;
  }

  public clearWorkCentersCache() {
    this.workCenters = {};
  }

  public clearWorkCenterMachineDataListCache() {
    this.workCenterMachineDataList = null;
  }

  public clearAllCache() {
    this.clearWorkCenterListCache();
    this.clearWorkCentersCache();
    this.clearWorkCenterMachineDataListCache();
  }
}
