/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PileTurnerScanContainerResponse } from '../models/pile-turner-scan-container-response';
import { PileTurnerScanEntry } from '../models/pile-turner-scan-entry';
import { RelatedWorkCenter } from '../models/related-work-center';
import { PileTurner } from '../models/pile-turner';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { PileTurnerScanResult } from '../models/pile-turner-scan-result';
import { PileTurnerPalletList } from '../models/pile-turner-pallet-list';
@Injectable({
  providedIn: 'root'
})
class PileTurnerService extends __BaseService {
  static readonly pileTurnerScanContainerPath = '/api/v1/PileTurner/{pileTurnerWorkCenterId}/ScanContainer';
  static readonly getPileTurnerWorkCenterListPath = '/api/v1/PileTurner/GetPileTurnerWorkCenterList';
  static readonly getPileTurnerPath = '/api/v1/PileTurner/{pileTurnerWorkCenterId}';
  static readonly getMaterialAvailableAtPileTurnerPath = '/api/v1/PileTurner/{workCenterId}/MaterialAvailableAtPileTurner';
  static readonly scanPrimaryContainerPath = '/api/v1/PileTurner/{pileTurnerWorkCenterId}/Scan';
  static readonly getPalletListPath = '/api/v1/PileTurner/{workCenterId}/PalletList';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `PileTurnerService.PileTurnerScanContainerParams` containing the following parameters:
   *
   * - `pileTurnerWorkCenterId`:
   *
   * - `entry`:
   */
  pileTurnerScanContainerResponse(
    params: PileTurnerService.PileTurnerScanContainerParams
  ): __Observable<__StrictHttpResponse<PileTurnerScanContainerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PileTurner/${encodeURIComponent(String(params.pileTurnerWorkCenterId))}/ScanContainer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PileTurnerScanContainerResponse>;
      })
    );
  }
  /**
   * @param params The `PileTurnerService.PileTurnerScanContainerParams` containing the following parameters:
   *
   * - `pileTurnerWorkCenterId`:
   *
   * - `entry`:
   */
  pileTurnerScanContainer(params: PileTurnerService.PileTurnerScanContainerParams): __Observable<PileTurnerScanContainerResponse> {
    return this.pileTurnerScanContainerResponse(params).pipe(__map((_r) => _r.body as PileTurnerScanContainerResponse));
  }
  getPileTurnerWorkCenterListResponse(): __Observable<__StrictHttpResponse<Array<RelatedWorkCenter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/PileTurner/GetPileTurnerWorkCenterList`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RelatedWorkCenter>>;
      })
    );
  }
  getPileTurnerWorkCenterList(): __Observable<Array<RelatedWorkCenter>> {
    return this.getPileTurnerWorkCenterListResponse().pipe(__map((_r) => _r.body as Array<RelatedWorkCenter>));
  }

  /**
   * @param pileTurnerWorkCenterId undefined
   */
  getPileTurnerResponse(pileTurnerWorkCenterId: number): __Observable<__StrictHttpResponse<PileTurner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PileTurner/${encodeURIComponent(String(pileTurnerWorkCenterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PileTurner>;
      })
    );
  }
  /**
   * @param pileTurnerWorkCenterId undefined
   */
  getPileTurner(pileTurnerWorkCenterId: number): __Observable<PileTurner> {
    return this.getPileTurnerResponse(pileTurnerWorkCenterId).pipe(__map((_r) => _r.body as PileTurner));
  }

  /**
   * @param workCenterId undefined
   */
  getMaterialAvailableAtPileTurnerResponse(workCenterId: number): __Observable<__StrictHttpResponse<MaterialAvailableAtLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PileTurner/${encodeURIComponent(String(workCenterId))}/MaterialAvailableAtPileTurner`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MaterialAvailableAtLocation>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getMaterialAvailableAtPileTurner(workCenterId: number): __Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtPileTurnerResponse(workCenterId).pipe(__map((_r) => _r.body as MaterialAvailableAtLocation));
  }

  /**
   * @param params The `PileTurnerService.ScanPrimaryContainerParams` containing the following parameters:
   *
   * - `pileTurnerWorkCenterId`:
   *
   * - `entry`:
   */
  scanPrimaryContainerResponse(
    params: PileTurnerService.ScanPrimaryContainerParams
  ): __Observable<__StrictHttpResponse<PileTurnerScanResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/PileTurner/${encodeURIComponent(String(params.pileTurnerWorkCenterId))}/Scan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PileTurnerScanResult>;
      })
    );
  }
  /**
   * @param params The `PileTurnerService.ScanPrimaryContainerParams` containing the following parameters:
   *
   * - `pileTurnerWorkCenterId`:
   *
   * - `entry`:
   */
  scanPrimaryContainer(params: PileTurnerService.ScanPrimaryContainerParams): __Observable<PileTurnerScanResult> {
    return this.scanPrimaryContainerResponse(params).pipe(__map((_r) => _r.body as PileTurnerScanResult));
  }

  /**
   * @param workCenterId undefined
   */
  getPalletListResponse(workCenterId: number): __Observable<__StrictHttpResponse<PileTurnerPalletList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PileTurner/${encodeURIComponent(String(workCenterId))}/PalletList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PileTurnerPalletList>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getPalletList(workCenterId: number): __Observable<PileTurnerPalletList> {
    return this.getPalletListResponse(workCenterId).pipe(__map((_r) => _r.body as PileTurnerPalletList));
  }
}

module PileTurnerService {
  /**
   * Parameters for PileTurnerScanContainer
   */
  export interface PileTurnerScanContainerParams {
    pileTurnerWorkCenterId: number;
    entry: PileTurnerScanEntry;
  }

  /**
   * Parameters for ScanPrimaryContainer
   */
  export interface ScanPrimaryContainerParams {
    pileTurnerWorkCenterId: number;
    entry: PileTurnerScanEntry;
  }
}

export { PileTurnerService };
