import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { KpiActionBase, KpiActionFactoryService } from '@app/core/services';
import { KpiValue } from 'chronos-core-client';
import { LoadingNotificationService } from 'chronos-shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-kpi-tile',
  templateUrl: './kpi-tile.component.html',
  styleUrls: ['./kpi-tile.component.scss']
})
export class KpiTileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public isActionEnabled = true;
  @Input() public kpi: KpiValue;

  public action: KpiActionBase;
  public kpiTitle: string;
  public isQuantityNumber: boolean;
  public dismountDisabled = false;
  public executeDisabled = false;
  public isActionButtonVisible = false;
  public isDismountButtonVisible = false;

  private subscriptions = new Subscription();

  constructor(private kpiActionFactoryService: KpiActionFactoryService, public loadingService: LoadingNotificationService) {}

  public ngOnInit(): void {
    this.action = this.kpiActionFactoryService.createKpiAction(this.kpi.kpiIdentifier);

    if (this.action?.loadingDismountTopic) {
      this.subscriptions.add(
        this.loadingService
          .getNotifications(this.action?.loadingDismountTopic)
          .subscribe((isLoading) => (this.dismountDisabled = isLoading))
      );
    }

    if (this.action?.loadingExecuteTopic) {
      this.subscriptions.add(
        this.loadingService.getNotifications(this.action?.loadingExecuteTopic).subscribe((isLoading) => (this.executeDisabled = isLoading))
      );
    }

    this.setIsActionButtonVisible();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.kpi) {
      this.isQuantityNumber = this.isNumber();
      this.kpiTitle = this.formatKpiTitle();
      this.setIsActionButtonVisible();
      this.setIsDismountButtonVisible();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private isNumber(): boolean {
    return !this.kpi?.value?.unitId && !this.kpi?.target?.unitId;
  }

  private formatKpiTitle(): string {
    return `KPI_TILE.${this.kpi.kpiIdentifier.toUpperCase()}`;
  }

  private setIsActionButtonVisible(): void {
    this.isActionButtonVisible = this.isActionEnabled && this.kpi.isActionAllowed && this.action?.shouldShowEdit;
  }

  private setIsDismountButtonVisible(): void {
    this.isDismountButtonVisible = this.kpi.toBeDismountedKpi?.containerCount > 0;
  }

  public onClickDismount(): void {
    if (!this.action.pendingDismount) {
      this.action.dismount();
    }
  }

  public onClickExecute(): void {
    if (!this.action.pendingExecute) {
      this.action.execute();
    }
  }
}
