/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UpdateSettingEntry } from '../models/update-setting-entry';
import { UpdateSettingsBatchEntry } from '../models/update-settings-batch-entry';
import { ExportSettingsResponse } from '../models/export-settings-response';
import { ImportSettingsEntry } from '../models/import-settings-entry';
import { Setting } from '../models/setting';
import { SettingValuesViewModel } from '../models/setting-values-view-model';
import { SettingHierarchyViewModel } from '../models/setting-hierarchy-view-model';
import { SettingHistoryViewModel } from '../models/setting-history-view-model';
import { SettingEntityInfoViewModel } from '../models/setting-entity-info-view-model';
@Injectable({
  providedIn: 'root'
})
class SettingsService extends __BaseService {
  static readonly refreshSettingsPath = '/api/v1/Settings/{externalWorkCenterId}/RefreshSettings';
  static readonly updateSettingPath = '/api/v1/Settings/{settingId}/{hierarchyLevel}/{externalId}/UpdateSetting';
  static readonly updateSettingsBatchPath = '/api/v1/Settings/{hierarchyLevel}/{externalId}/UpdateSettingsBatch';
  static readonly exportSettingsPath = '/api/v1/Settings/ExportSettings';
  static readonly importSettingsPath = '/api/v1/Settings/ImportSettings';
  static readonly getSettingsPath = '/api/v1/Settings';
  static readonly getSettingValuesPath = '/api/v1/Settings/GetSettingValues/{hierarchyType}/{hierarchyLevel}/{entityId}';
  static readonly getSettingHierarchyPath = '/api/v1/Settings/GetSettingHierarchy/{hierarchyType}';
  static readonly getSettingHistoryPath = '/api/v1/Settings/GetSettingHistory/{settingId}/{hierarchyType}/{hierarchyLevel}/{entityId}';
  static readonly getSettingEntityInfoPath = '/api/v1/Settings/GetSettingEntityInfo/{hierarchyType}/{hierarchyLevel}/{entityId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param externalWorkCenterId undefined
   */
  refreshSettingsResponse(externalWorkCenterId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Settings/${encodeURIComponent(String(externalWorkCenterId))}/RefreshSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param externalWorkCenterId undefined
   */
  refreshSettings(externalWorkCenterId: string): __Observable<null> {
    return this.refreshSettingsResponse(externalWorkCenterId).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SettingsService.UpdateSettingParams` containing the following parameters:
   *
   * - `settingId`:
   *
   * - `hierarchyLevel`:
   *
   * - `externalId`:
   *
   * - `entry`:
   */
  updateSettingResponse(params: SettingsService.UpdateSettingParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/Settings/${encodeURIComponent(String(params.settingId))}/${encodeURIComponent(
          String(params.hierarchyLevel)
        )}/${encodeURIComponent(String(params.externalId))}/UpdateSetting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SettingsService.UpdateSettingParams` containing the following parameters:
   *
   * - `settingId`:
   *
   * - `hierarchyLevel`:
   *
   * - `externalId`:
   *
   * - `entry`:
   */
  updateSetting(params: SettingsService.UpdateSettingParams): __Observable<null> {
    return this.updateSettingResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SettingsService.UpdateSettingsBatchParams` containing the following parameters:
   *
   * - `hierarchyLevel`:
   *
   * - `externalId`:
   *
   * - `entry`:
   */
  updateSettingsBatchResponse(params: SettingsService.UpdateSettingsBatchParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/Settings/${encodeURIComponent(String(params.hierarchyLevel))}/${encodeURIComponent(
          String(params.externalId)
        )}/UpdateSettingsBatch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SettingsService.UpdateSettingsBatchParams` containing the following parameters:
   *
   * - `hierarchyLevel`:
   *
   * - `externalId`:
   *
   * - `entry`:
   */
  updateSettingsBatch(params: SettingsService.UpdateSettingsBatchParams): __Observable<null> {
    return this.updateSettingsBatchResponse(params).pipe(__map((_r) => _r.body as null));
  }
  exportSettingsResponse(): __Observable<__StrictHttpResponse<ExportSettingsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Settings/ExportSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExportSettingsResponse>;
      })
    );
  }
  exportSettings(): __Observable<ExportSettingsResponse> {
    return this.exportSettingsResponse().pipe(__map((_r) => _r.body as ExportSettingsResponse));
  }

  /**
   * @param entry undefined
   */
  importSettingsResponse(entry: ImportSettingsEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Settings/ImportSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  importSettings(entry: ImportSettingsEntry): __Observable<null> {
    return this.importSettingsResponse(entry).pipe(__map((_r) => _r.body as null));
  }
  getSettingsResponse(): __Observable<__StrictHttpResponse<Array<Setting>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Settings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Setting>>;
      })
    );
  }
  getSettings(): __Observable<Array<Setting>> {
    return this.getSettingsResponse().pipe(__map((_r) => _r.body as Array<Setting>));
  }

  /**
   * @param params The `SettingsService.GetSettingValuesParams` containing the following parameters:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingValuesResponse(
    params: SettingsService.GetSettingValuesParams
  ): __Observable<__StrictHttpResponse<Array<SettingValuesViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/Settings/GetSettingValues/${encodeURIComponent(String(params.hierarchyType))}/${encodeURIComponent(
          String(params.hierarchyLevel)
        )}/${encodeURIComponent(String(params.entityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SettingValuesViewModel>>;
      })
    );
  }
  /**
   * @param params The `SettingsService.GetSettingValuesParams` containing the following parameters:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingValues(params: SettingsService.GetSettingValuesParams): __Observable<Array<SettingValuesViewModel>> {
    return this.getSettingValuesResponse(params).pipe(__map((_r) => _r.body as Array<SettingValuesViewModel>));
  }

  /**
   * @param hierarchyType undefined
   */
  getSettingHierarchyResponse(hierarchyType: string): __Observable<__StrictHttpResponse<SettingHierarchyViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Settings/GetSettingHierarchy/${encodeURIComponent(String(hierarchyType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SettingHierarchyViewModel>;
      })
    );
  }
  /**
   * @param hierarchyType undefined
   */
  getSettingHierarchy(hierarchyType: string): __Observable<SettingHierarchyViewModel> {
    return this.getSettingHierarchyResponse(hierarchyType).pipe(__map((_r) => _r.body as SettingHierarchyViewModel));
  }

  /**
   * @param params The `SettingsService.GetSettingHistoryParams` containing the following parameters:
   *
   * - `settingId`:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingHistoryResponse(
    params: SettingsService.GetSettingHistoryParams
  ): __Observable<__StrictHttpResponse<Array<SettingHistoryViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/Settings/GetSettingHistory/${encodeURIComponent(String(params.settingId))}/${encodeURIComponent(
          String(params.hierarchyType)
        )}/${encodeURIComponent(String(params.hierarchyLevel))}/${encodeURIComponent(String(params.entityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SettingHistoryViewModel>>;
      })
    );
  }
  /**
   * @param params The `SettingsService.GetSettingHistoryParams` containing the following parameters:
   *
   * - `settingId`:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingHistory(params: SettingsService.GetSettingHistoryParams): __Observable<Array<SettingHistoryViewModel>> {
    return this.getSettingHistoryResponse(params).pipe(__map((_r) => _r.body as Array<SettingHistoryViewModel>));
  }

  /**
   * @param params The `SettingsService.GetSettingEntityInfoParams` containing the following parameters:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingEntityInfoResponse(
    params: SettingsService.GetSettingEntityInfoParams
  ): __Observable<__StrictHttpResponse<SettingEntityInfoViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/Settings/GetSettingEntityInfo/${encodeURIComponent(String(params.hierarchyType))}/${encodeURIComponent(
          String(params.hierarchyLevel)
        )}/${encodeURIComponent(String(params.entityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SettingEntityInfoViewModel>;
      })
    );
  }
  /**
   * @param params The `SettingsService.GetSettingEntityInfoParams` containing the following parameters:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingEntityInfo(params: SettingsService.GetSettingEntityInfoParams): __Observable<SettingEntityInfoViewModel> {
    return this.getSettingEntityInfoResponse(params).pipe(__map((_r) => _r.body as SettingEntityInfoViewModel));
  }
}

module SettingsService {
  /**
   * Parameters for UpdateSetting
   */
  export interface UpdateSettingParams {
    settingId: number;
    hierarchyLevel: string;
    externalId: string;
    entry: UpdateSettingEntry;
  }

  /**
   * Parameters for UpdateSettingsBatch
   */
  export interface UpdateSettingsBatchParams {
    hierarchyLevel: string;
    externalId: string;
    entry: UpdateSettingsBatchEntry;
  }

  /**
   * Parameters for GetSettingValues
   */
  export interface GetSettingValuesParams {
    hierarchyType: string;
    hierarchyLevel: string;
    entityId: string;
  }

  /**
   * Parameters for GetSettingHistory
   */
  export interface GetSettingHistoryParams {
    settingId: number;
    hierarchyType: string;
    hierarchyLevel: string;
    entityId: string;
  }

  /**
   * Parameters for GetSettingEntityInfo
   */
  export interface GetSettingEntityInfoParams {
    hierarchyType: string;
    hierarchyLevel: string;
    entityId: string;
  }
}

export { SettingsService };
