/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root'
})
export class PandaApiConfiguration {
  rootUrl: string = '';
}

/**
 * Parameters for `PandaApiModule.forRoot()`
 */
export interface PandaApiConfigurationParams {
  rootUrl?: string;
}
