/* tslint:disable */
type ToolCheckStatus = 'None' | 'Ok' | 'Nok';
module ToolCheckStatus {
  export const NONE: ToolCheckStatus = 'None';
  export const OK: ToolCheckStatus = 'Ok';
  export const NOK: ToolCheckStatus = 'Nok';
  export function values(): ToolCheckStatus[] {
    return [NONE, OK, NOK];
  }
}

export { ToolCheckStatus };
