/* tslint:disable */
type PeriodCheckErrorPeriodType = 'InterpretationLogicPeriod' | 'ProductionPeriod';
module PeriodCheckErrorPeriodType {
  export const INTERPRETATION_LOGIC_PERIOD: PeriodCheckErrorPeriodType = 'InterpretationLogicPeriod';
  export const PRODUCTION_PERIOD: PeriodCheckErrorPeriodType = 'ProductionPeriod';
  export function values(): PeriodCheckErrorPeriodType[] {
    return [INTERPRETATION_LOGIC_PERIOD, PRODUCTION_PERIOD];
  }
}

export { PeriodCheckErrorPeriodType };
