export enum Status {
  Active = 'Active',
  Checked = 'Checked',
  Open = 'Open',
  Next = 'Next',
  Finished = 'Finished',
  Interrupted = 'Interrupted',
  Downtime = 'Downtime',
  Reopened = 'Reopened',
  None = 'None'
}

// TODO FIXME: Should be removed and generated Enum ProductionOrderStatus should be used
/* CHECK WITH BE WHY THESE STATUS ARE NOT THE SAME
  Check with backend if there is maybe another Status for ShiftReports

  declare module ProductionOrderStatus {
    const NONE: ProductionOrderStatus;

    const OPEN: ProductionOrderStatus;
    const CHECKED: ProductionOrderStatus;
    const ACTIVE: ProductionOrderStatus;
    const FINISHED: ProductionOrderStatus;
    const REOPENED: ProductionOrderStatus;
  }
*/
