import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShutdownService } from '@app/modules/shutdown/shutdown.service';
import { nav, notificationTopic } from '@app/shared/utils';
import { finalize, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ShutdownReason, WorkCenterState } from 'chronos-core-client';
import { combineLatest } from 'rxjs';
import { AppSettingsQuery, LoadingNotificationService } from 'chronos-shared';

@Component({
  selector: 'app-shutdown-modal',
  templateUrl: './shutdown-modal.component.html',
  styleUrls: ['./shutdown-modal.component.scss']
})
export class ShutdownModalComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalShutdownExit;

  public workCenterState$: Observable<WorkCenterState>;
  public currentReason$: Observable<ShutdownReason>;

  constructor(private shutdownService: ShutdownService, private appSettingsQuery: AppSettingsQuery, private router: Router) {}

  public ngOnInit(): void {
    this.workCenterState$ = this.appSettingsQuery.workCenterState$;
    this.currentReason$ = combineLatest([this.workCenterState$, this.shutdownService.getShutdownReasons()]).pipe(
      map(([state, reasons]) => reasons.find((reason) => reason.id === state.reasonId))
    );
  }

  public onStartUp(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.shutdownService
      .startMachine()
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => this.router.navigate([nav.routes.ordersList]));
  }
}
