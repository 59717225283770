import { Injectable } from '@angular/core';
import { DraggableContainerIndices } from '@app/modules/mounting/models/draggable-container-indices.model';
import { MountingService } from '@app/modules/mounting/services/mounting/mounting.service';
import { MountedMaterial } from 'chronos-shared';

@Injectable()
export class MountPalletCardsService {
  constructor(private mountingService: MountingService) {}

  public changeCardViewContainerListSequence(
    draggableContainerIndices: DraggableContainerIndices,
    cardsVisibleInView: number,
    containers: MountedMaterial[]
  ): MountedMaterial[] {
    const reversedDraggableContainerIndices = this.reverseContainersListIndices(draggableContainerIndices, cardsVisibleInView, containers);

    return this.mountingService.changeContainerListSequence(reversedDraggableContainerIndices, containers);
  }

  private reverseContainersListIndices(
    draggableContainerIndices: DraggableContainerIndices,
    cardsVisibleInView: number,
    containers: MountedMaterial[]
  ): DraggableContainerIndices {
    const emptyContainerCount = this.mountingService
      .getMountedContainers(cardsVisibleInView, containers)
      .filter((item) => ['info', 'placeholder'].includes(item.containerType)).length;

    const reverseArray = Array.from(Array(cardsVisibleInView).keys()).reverse();

    const previousIndex = reverseArray[draggableContainerIndices.previousIndex] - emptyContainerCount;
    const currentIndex = reverseArray[draggableContainerIndices.currentIndex] - emptyContainerCount;

    return { currentIndex, previousIndex } as DraggableContainerIndices;
  }
}
