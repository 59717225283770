import { Component, Input } from '@angular/core';
import { StatusPanelType } from './status-panel-type';
import { StatusPanelTextStyle } from './status-panel-text-style';
import { StatusPanelHeight } from './status-panel-height';

@Component({
  selector: 'app-status-panel',
  templateUrl: './status-panel.component.html',
  styleUrls: ['./status-panel.component.scss']
})
export class StatusPanelComponent {
  @Input() public label: string;
  @Input() public value: string;
  @Input() public unitId: string;
  @Input() public containers: string;
  @Input() public status: StatusPanelType;
  @Input() public textStyle: StatusPanelTextStyle = 'large';
  @Input() public height: StatusPanelHeight = 'normal';
}
