import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ShiftReportService } from '@app/modules/shift-report/services/shift-report';
import { filter, finalize, tap } from 'rxjs/operators';
import { LoadingNotificationService, LogService, ModalConfirmComponent } from 'chronos-shared';
import { elastic } from '@app/shared/utils/elastic';
import { notificationTopic } from '@app/shared/utils';
import { ApproveCommandCallback } from '@app/shared/components';

@Component({
  selector: 'app-send-report-modal',
  templateUrl: './send-report-modal.component.html',
  styleUrls: ['./send-report-modal.component.scss'],
  providers: [DialogService]
})
export class SendReportModalComponent implements OnInit {
  public isAlert: boolean = this.config.data.alert;
  public reportForm: UntypedFormGroup;

  public commentLabel = 'SHIFT_REPORT.ALERT_COMMENT_LABEL';
  public submitLabel = 'SHIFT_REPORT.SEND';

  private remarkId: number = this.config.data.remarkId;
  private shiftReportCommandCallback: ApproveCommandCallback = this.config.data.commandCallback;
  private readonly LOADING_TOPIC = notificationTopic.modalSendShiftReport;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private shiftReportService: ShiftReportService
  ) {}

  public ngOnInit(): void {
    this.initModalForm();
    this.initModalTranslations();
  }

  public openConfirmModal(): void {
    this.closeModal(false);
    this.dialogService
      .open(ModalConfirmComponent, {
        header: this.translateService.instant('SHIFT_REPORT.SEND_REPORT'),
        data: {
          question: this.translateService.instant('SHIFT_REPORT.CONFIRM_REPORT_TEXT'),
          acceptable: true
        }
      })
      .onClose.pipe(
        filter((accepted) => {
          this.shiftReportCommandCallback(true);
          return !!accepted;
        })
      )
      .subscribe(() => {
        this.sendShiftReport();
      });
  }

  private initModalForm(): void {
    const commentValidators: ValidatorFn[] = [];
    const printReport: boolean = this.config.data.printReport || false;
    const comment: string = this.config.data.comment || '';

    if (this.isAlert) {
      commentValidators.push(Validators.required);
    }

    this.reportForm = new UntypedFormGroup({
      comment: new UntypedFormControl(comment, commentValidators),
      printReport: new UntypedFormControl(printReport)
    });
  }

  private initModalTranslations(): void {
    if (!this.isAlert) {
      this.commentLabel = 'SHIFT_REPORT.SHIFT_COMMENT_LABEL';

      if (this.remarkId) {
        this.submitLabel = 'SHIFT_REPORT.SAVE';
      }
    }
  }

  private sendShiftReport(): void {
    const { comment, printReport } = this.reportForm.value;
    const shiftId: number = this.config.data.shiftId;
    const trace = elastic.traceUiActivity('SendReportModalComponent.sendShiftReport');

    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    if (this.remarkId) {
      this.shiftReportService
        .saveShiftRemark(this.remarkId, comment)
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
            trace.end();
          })
        )
        .subscribe(() => {
          LogService.success('SUCCESS_MESSAGE.SHIFT_NOTE_SAVED');
        });
    } else {
      this.shiftReportService
        .sendShiftReport(shiftId, comment, printReport)
        .pipe(
          tap(() => {
            this.shiftReportService.refreshShifts();
          }),
          finalize(() => {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
            trace.end();
          })
        )
        .subscribe(() => {
          LogService.success('SUCCESS_MESSAGE.SHIFT_REPORT_SENT');
        });
    }
  }

  public closeModal(uncheckCheckbox: boolean): void {
    this.ref.close();
    this.shiftReportCommandCallback(uncheckCheckbox);
  }
}
