<div class="mount-material-status">
  <div *ngIf="title" class="mount-material-status__item">
    <span [class]="styleClass">{{ title | translate }}</span>
  </div>
  <div class="mount-material-status__article">
    <ng-content></ng-content>
  </div>
  <div *ngIf="plannedStartTime" class="mount-material-status__item">
    <span [class]="styleClass">{{ 'MOUNTING.START_TIME' | translate }}: {{ plannedStartTime | utcToLocal | date: 'HH:mm' }}</span>
  </div>
</div>
