/* tslint:disable */
type RemarkReferenceType = 'ProductionOrder' | 'Container' | 'ShiftReport';
module RemarkReferenceType {
  export const PRODUCTION_ORDER: RemarkReferenceType = 'ProductionOrder';
  export const CONTAINER: RemarkReferenceType = 'Container';
  export const SHIFT_REPORT: RemarkReferenceType = 'ShiftReport';
  export function values(): RemarkReferenceType[] {
    return [PRODUCTION_ORDER, CONTAINER, SHIFT_REPORT];
  }
}

export { RemarkReferenceType };
