import { NgModule } from '@angular/core';
import { QaLogComponent } from './containers/qa-log/qa-log.component';
import { SharedModule } from '@app/shared/shared.module';
import { QaHistoryModule } from '@app/modules/qa-history/qa-history.module';
import { TabQaCheckComponent } from './containers/tab-qa-check/tab-qa-check.component';
import { TabQaNcComponent } from './containers/tab-qa-nc/tab-qa-nc.component';
import { TabQaHistoryComponent } from './containers/tab-qa-history/tab-qa-history.component';

@NgModule({
  declarations: [QaLogComponent, TabQaCheckComponent, TabQaNcComponent, TabQaHistoryComponent],
  imports: [SharedModule, QaHistoryModule]
})
export class QaLogModule {}
