import { SetupFooterCommandBase } from '@app/shared/components';
import { FinishPhaseService } from '@app/modules/finish-phase/services';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { notificationTopic } from '@app/shared/utils';

export class ConfirmToolCheckoutCommand implements SetupFooterCommandBase {
  public enabled = true;
  public buttonText: string;
  public required = true;
  public route: string;
  public loadingTopic = notificationTopic.footerNextFinishPhase;

  constructor(private finishPhaseService: FinishPhaseService) {}

  public execute(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.finishPhaseService
      .setQaToolCheckoutConfirm(this.enabled)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe({
        error: () => (this.enabled = !this.enabled)
      });
  }

  public setNavigationParams(route: string): void {
    this.route = route;
  }
}
