<div class="scrollable-order-item" [ngClass]="{ 'scrollable-order-item--active': isItemActive }" (click)="changeSelectedItem()">
  <div class="scrollable-order-item__labels">
    <ng-container *ngIf="!item.isNextProductionOrder; else nextProductionOrder">
      <lib-status-flag
        *ngIf="PRODUCTION_JOB_STATUS_FLAGS[item.status] as orderStatus"
        class="status__item"
        [statusText]="orderStatus.text | translate"
        [styleClass]="orderStatus.labelClass"
        size="small"
      ></lib-status-flag>
    </ng-container>

    <ng-template #nextProductionOrder>
      <lib-status-flag
        *ngIf="PRODUCTION_JOB_STATUS_FLAGS[STATUS.Next] as nextStatus"
        class="status__item"
        [statusText]="nextStatus.text | translate"
        [styleClass]="nextStatus.labelClass"
        size="small"
      ></lib-status-flag>
    </ng-template>
  </div>

  <div class="scrollable-order-item__order-code">{{ item.externalProductionOrderId }}</div>

  <div class="scrollable-order-item__product_number">{{ item?.finishedGoodArticles[0]?.articleName }}</div>

  <div class="scrollable-order-item__start-time">
    {{ 'MOUNTING.START_TIME' | translate }}: {{ item.plannedStartTime | utcToLocal | date : 'HH:mm' }}
  </div>
  <div class="scrollable-order-item__quantity">
    {{ item.estimatedQuantity | formatQuantity }}
  </div>
</div>
