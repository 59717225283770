<div class="quality-allocation">
  <div class="p-grid p-nogutter">
    <div class="p-col-2">
      <div class="counter">
        <div class="counter__label">
          {{ 'SETUP_WASTE_ASSIGNMENT.COUNTER' | translate }}
        </div>
        <div class="counter__value">
          {{ counter.value | number }}
        </div>
      </div>
    </div>

    <div class="p-col-10 waste-table">
      <div class="p-col-10 setup-phase-duration">
        <div class="p-grid setup-phase-duration__area">
          <span class="p-col-1 setup-phase-duration__col-form-label">
            {{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }}
          </span>
          <span *ngIf="setupPhaseStatus" class="p-col-3 setup-phase-duration__col-form-value">
            {{ setupPhaseStatus.setupStartTime | utcToLocal | date: 'dd.MM.yyyy HH:mm' }}
          </span>
          <span class="p-col-1 setup-phase-duration__col-form-label">
            {{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }}
          </span>
          <span *ngIf="setupPhaseStatus" class="p-col-3 setup-phase-duration__col-form-value">
            {{ setupPhaseStatus.setupEndTime | utcToLocal | date: 'dd.MM.yyyy HH:mm' }}
          </span>
          <div class="p-col-1"></div>
          <div class="p-col-2 control-button">
            <div>
              <app-split-button class="control-button__action" [items]="taskButtons" [loadingTopic]="loadingTopic"></app-split-button>
            </div>
          </div>
          <div class="icons setup-phase-duration__icons-status p-col-1">
            <i *ngIf="setupPhaseStatus" [ngClass]="{ 'icon-checked-solid': setupPhaseStatus.setupEndTime }"></i>
          </div>
        </div>
      </div>
      <app-waste-assignment-table
        [counter]="counter"
        [reasonOptions]="reasonOptions$ | async"
        [wasteAndMaculatureValue]="wasteAllocations"
        [wasteValue]="counter"
        [maculatureValue]="{ value: 0 }"
        (rowAdded)="onRowAdded($event)"
        (rowRemoved)="onRowRemoved($event)"
        [isSetupWastePhase]="true"
      ></app-waste-assignment-table>
    </div>
  </div>
</div>
