import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PrintLabelForOuterEntry, OuterLabelType } from 'chronos-core-client';
import { notificationTopic } from '@app/shared/utils';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { BobbinPrintLabel } from '@app/shared/models/bobbin-print-label';

@Component({
  selector: 'app-print-label-range',
  templateUrl: './print-label-range.component.html',
  styleUrls: ['./print-label-range.component.scss']
})
export class PrintLabelRangeComponent implements OnInit, OnChanges {
  @Input() public printedLabels: number;
  @Input() public labelsTotal: number;
  @Input() public collapsible = false;
  @Input() public isReprint = false;
  @Input() public title: string;
  @Output() public okButtonClicked = new EventEmitter<PrintLabelForOuterEntry>();
  @Output() public okBobbinButtonClicked = new EventEmitter<BobbinPrintLabel>();
  @Input() public labelType: OuterLabelType;
  @Input() public isQuantityPerOuterValid? = false;
  @Input() public isBobbin? = false;
  @Input() public producedMaterialId?: number;
  @Input() public isPalletFinished?: boolean;

  public readonly MODEL_KEY = {
    FROM: 'fromRange',
    TO: 'toRange'
  };

  public model = {
    [this.MODEL_KEY.FROM]: 0,
    [this.MODEL_KEY.TO]: 0
  };

  private readonly OUTER_PRINT_LOADING_TOPIC = notificationTopic.outerPrintLabelAction;
  private readonly OUTER_PREPRINT_LOADING_TOPIC = notificationTopic.outerPreprintLabelAction;
  private readonly INPUT_MAX_QUANTITY_BUFFER = 9999;
  public readonly INPUT_MIN_QUANTITY = 1;
  public readonly INPUT_STYLE = {
    width: '60px',
    textAlign: 'end',
  };
  public maxQuantity = this.INPUT_MAX_QUANTITY_BUFFER;
  public isModelInvalid = false;
  public isModelDisabled = false;
  public isFromDisabled = true;
  public loadingTopic: string;
  public disablePrintButton = false;

  constructor(private activeWorkCenterService: ActiveWorkCenterService) {}

  public ngOnInit(): void {
    this.loadingTopic = this.isReprint ? this.OUTER_PRINT_LOADING_TOPIC : this.OUTER_PREPRINT_LOADING_TOPIC;
    this.loadingTopic = this.labelType + this.loadingTopic;
  }

  public ngOnChanges(): void {
    this.setMaxQuantity();
    this.mapInputModel();
    this.validateInput(this.MODEL_KEY.FROM);
    this.validateInput(this.MODEL_KEY.TO);
    this.disableModel();
    this.disablePrintButton = this.isPalletFinished === true;
  }

  public validateInput(key: string) {
    let range = this.model[key];

    if (range <= this.INPUT_MIN_QUANTITY) {
      range = this.INPUT_MIN_QUANTITY;
    }

    if (range >= this.maxQuantity) {
      range = this.maxQuantity;
    }

    this.model[key] = range;
    this.validateModel();
  }

  public printLabel(): void {
    if (this.isModelInvalid || this.isModelDisabled) {
      return;
    }

    this.okButtonClicked.emit({
      fromNumber: this.model[this.MODEL_KEY.FROM],
      outerLabelType: this.labelType,
      toNumber: this.model[this.MODEL_KEY.TO],
      reprint: this.isReprint,
      workCenterId: this.activeWorkCenterService.getWorkCenterId()
    });
  }

  public printBobbinLabel(): void {
    if (this.isModelInvalid || this.isModelDisabled) {
      return;
    }

    this.okBobbinButtonClicked.emit({
      producedMaterialId: this.producedMaterialId,
      entry: {
        fromNumber: this.model[this.MODEL_KEY.FROM],
        outerLabelType: this.labelType,
        toNumber: this.model[this.MODEL_KEY.TO],
        reprint: this.isReprint,
        workCenterId: this.activeWorkCenterService.getWorkCenterId()
      }
    });
  }

  private validateModel(): void {
    const toNumber = this.model[this.MODEL_KEY.TO];
    const fromNumber = this.model[this.MODEL_KEY.FROM];

    this.isModelInvalid = fromNumber > toNumber || (!this.isReprint && toNumber === this.printedLabels);
  }

  private disableModel(): void {
    this.isModelDisabled = this.isReprint && !this.printedLabels;
    this.isFromDisabled = !this.isReprint;
  }

  private mapInputModel(): void {
    if (this.isReprint) {
      this.model[this.MODEL_KEY.FROM] = this.INPUT_MIN_QUANTITY;
      this.model[this.MODEL_KEY.TO] = this.printedLabels;
    } else {
      this.model[this.MODEL_KEY.FROM] = this.modelKeyFromQty();
      this.model[this.MODEL_KEY.TO] = this.modelKeyToQty();
    }
  }

  private modelKeyFromQty() {
    if (this.isBobbin) {
      if (this.printedLabels > 0 && this.printedLabels < this.labelsTotal) return this.printedLabels + 1;

      if (this.printedLabels === 0) return this.INPUT_MIN_QUANTITY;

      return this.labelsTotal;
    }

    if (!this.isBobbin) return this.printedLabels + 1;
  }

  private modelKeyToQty() {
    if (this.isBobbin) return this.labelsTotal;

    if (!this.isBobbin) return (this.printedLabels === this.labelsTotal && this.labelsTotal + 1) || this.labelsTotal;
  }

  private setMaxQuantity(): void {
    if (this.isReprint) {
      this.maxQuantity = this.printedLabels || this.INPUT_MIN_QUANTITY;
    } else {
      this.maxQuantity = this.labelsTotal + this.INPUT_MAX_QUANTITY_BUFFER;
    }
  }
}
